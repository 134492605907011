import isNumber from 'lodash/isNumber'
import padStart from 'lodash/padStart'

var he = require("he");
angular.module('ngApp')
.filter('orderObjectBy', function () {
  return function (items, field, reverse) {
    var filtered = []
    angular.forEach(items, function (item) {
      filtered.push(item)
    })
    filtered.sort(function (a, b) {
      return (a[field] > b[field] ? 1 : -1)
    })
    if (reverse) filtered.reverse()
    return filtered
  }
})
.filter('propsFilter', function () {
  return function (items, props) {
    var out = []

    if (angular.isArray(items)) {
      items.forEach(function (item) {
        var itemMatches = false
        var keys = Object.keys(props)

        for (var i = 0; i < keys.length; i++) {
          var prop = keys[i]
          var text = props[prop].toLowerCase()
          if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
            itemMatches = true
            break
          }
        }

        if (itemMatches) out.push(item)
      })
    } else {
      out = items
    }

    return out
  }
})
.filter('isSafe', ['$sce', function ($sce) { return $sce.trustAsHtml }])
.filter('raw', ['$sce', function ($sce) { return $sce.trustAsHtml }])
.filter('path', ['$routing', function ($routing) {
  return function (input, parameters) {
    var origin = document.location.origin
    var route = parameters
      ? $routing.generate(input, parameters)
      : $routing.generate(input)
    return origin + route
  }
}])
.filter('asset', ['$routing', '$location', '$http', '$rootScope', function ($routing, $location, $http, $rootScope) {
  return function (input) {
    return !input ? $rootScope.baseUrlAssets : $rootScope.baseUrlAssets + input
  }
}])
.filter('currency', function () {
  return function (input, decimals = 2) {
    const val = Number(input)
    if (val.toLocaleString) return `€ ${val.toLocaleString(document.documentElement.lang, {
      // style: 'currency',
      // currency: 'EUR', // € is append for italian
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    })}`
    var thousandsDelimiter = document.documentElement.lang === 'en' ? ',' : '.'
    var decimalDelimiter = document.documentElement.lang === 'en' ? '.' : ','
    var currency = '€ '

    var inputString = input + ''
    let [ units, rest = "" ] = inputString.split('.')

    rest = rest.length >= 2
      ? rest.substring(0, 2)
      : rest.length
        ? rest + '0'
        : rest

    units = units.split('').reverse().map((c, i) => {
      if (i && !(i % 3)) return c + thousandsDelimiter
      return c
    }).reverse().join('')

    var ret = units + (rest ? decimalDelimiter + rest : '')
    return currency + ret
  }
})
.filter('s3', ['$rootScope', function ($rootScope) {
  return function (input, fraction) {
    if (!input) return ""
    if (input.toString().match(/^http/)) {
      return input.toString();
    }
    if (typeof fraction === 'undefined') fraction = 'medium'
    // if (window.treedomEnv === 'DEV') $rootScope.public_bucket = 'www-treedom-net'
    // return 'https://s3-eu-west-1.amazonaws.com/' + $rootScope.public_bucket + '/images/' + fraction + '_' + input
    return `/api/image/${input}?size=${fraction}`
  }
}])
.filter('media', ['$rootScope', '$http', function ($rootScope, $http) {
  return function (input, fraction) {
    if (!input) return ''
    if (input.toString().match(/^http/)) {
      return input.toString()
    }
    if (typeof fraction === 'undefined') fraction = 'medium'
    // if (window.treedomEnv === 'DEV') {
    //   // return 'https://www.treedom.net/images/' + fraction + '_' + input
    // }
    return `/api/image/${input}?size=${fraction}`
  }
}])
.filter('s3docs', function() {
  return function(filepath) {
    return `${window.docsUrl || "/"}${filepath.replace(/^\//,)}`
  }
})
.filter('invoice', ['$rootScope', '$http', function ($rootScope, $http) {
  return function (input) {
    return 'https://s3-eu-west-1.amazonaws.com/' + $rootScope.public_bucket + '/invoices/invoice_' + input + '.pdf'
  }
}])
.filter('weight', ['$filter', function ($filter) {
  return function (input) {
    var thousandsDelimiter = document.documentElement.lang === 'en' ? ',' : '.'
    var decimalDelimiter = document.documentElement.lang === 'en' ? '.' : ','
    var unit = ' kg'

    var inputString = input + ''
    let [ units, rest = "" ] = inputString.split('.')

    rest = rest.length >= 2
            ? rest.substring(0, 2)
            : rest.length
              ? rest + '0'
              : rest

    units = units.split('').reverse().map((c, i) => {
      if (i && !(i % 3)) return c + thousandsDelimiter
      return c
    }).reverse().join('')

    var ret = units + (rest ? decimalDelimiter + rest : '')
    return ret + unit
  }
}])
.filter('weightNoUnit', ['$filter', function ($filter) {
  return function (input) {
    if (!input || input === 'null' || input === null ||
      input === 'undefined' || input === undefined) {
      input = 0
    }

    var thousandsDelimiter = document.documentElement.lang === 'en' ? ',' : '.'
    var decimalDelimiter = document.documentElement.lang === 'en' ? '.' : ','

    var inputString = input + ''
    let [ units, rest = "" ] = inputString.split('.')

    rest = rest.length >= 2
            ? rest.substring(0, 2)
            : rest.length
              ? rest + '0'
              : rest

    units = units.split('').reverse().map((c, i) => {
      if (i && !(i % 3)) return c + thousandsDelimiter
      return c
    }).reverse().join('')

    var ret = units + (rest ? decimalDelimiter + rest : '')
    return ret
  }
}])
.filter('numberFormat', ['$filter', function ($filter) {
  return function (input) {
    if (!input || input === 'null' || input === null ||
      input === 'undefined' || input === undefined) {
      input = 0
    }

    var thousandsDelimiter = document.documentElement.lang === 'en' ? ',' : '.'
    var decimalDelimiter = document.documentElement.lang === 'en' ? '.' : ','

    var inputString = input + ''
    let [ units, rest = "" ] = inputString.split('.')

    rest = rest.length >= 2
            ? rest.substring(0, 2)
            : rest.length
              ? rest + '0'
              : rest

    units = units.split('').reverse().map((c, i) => {
      if (i && !(i % 3)) return c + thousandsDelimiter
      return c
    }).reverse().join('')

    var ret = units + (rest ? decimalDelimiter + rest : '')
    return ret
  }
}])
.filter('toDegree', function () {
    return function (coordinate, latlng) {
        var values = {};
        values.initValue = coordinate;
        values.degrees = Math.abs(coordinate);
        values.degreesInt = Math.floor(values.degrees);
        values.degreesFrac = values.degrees - values.degreesInt;
        values.secondsTotal = 3600 * values.degreesFrac;
        values.minutes = values.secondsTotal / 60;
        values.minutesInt = Math.floor(values.minutes);
        values.seconds = values.secondsTotal - (values.minutesInt * 60);
        var isLat = latlng.toLowerCase() === 'lat'
        return padStart(values.degreesInt, 2, '0') + '° ' +  padStart(values.minutesInt, 2, '0') + '′ ' + values.seconds.toFixed(2) + '″ ' + (isLat ? (coordinate>0 ? 'N' : 'S') : (coordinate>0 ? 'E' : 'W') );

    }
})
.filter('datetime', ['$filter', '$rootScope', function ($filter, $rootScope) {
  return function (timestamp, extra) {
    if(!timestamp) return timestamp;
    if (!isNumber(timestamp)) {
      var moment = require('moment')
      timestamp = moment(timestamp).unix() * 1000
    }

    var date = new Date(timestamp)
    var day = date.getDate()
    var month = date.getMonth() + 1
    var year = date.getFullYear()

    // console.log(date)

    if (String(day).length < 2) day = '0' + day
    if (String(month).length < 2) month = '0' + month

    var dateToString = $rootScope.ln === 'en'
      ? month + '/' + day + '/' + year
      : day + '/' + month + '/' + year

    if (extra && extra === 'minutes') {
      var hours = date.getHours()
      var minutes = date.getMinutes()

      if (String(hours).length < 2) hours = '0' + hours
      if (String(minutes).length < 2) minutes = '0' + minutes

      dateToString += ' ' + hours + ':' + minutes
    }

    return dateToString
  }
}])
.filter('trusted', ['$sce', function ($sce) {
  return function (url) {
    return $sce.trustAsResourceUrl(url)
  }
}])
.filter('hedecode', [function () {
  return function (input) {
    return (input) ? he.decode(input) : null
  }
}])
.filter('heencode', [function () {
    return function (input) {
        return (input) ? he.encode(input) : null
    }
}])
