// Composition API mixin enabling Options API components use of composable useCountries
import { defineComponent, ref, computed } from '@vue/composition-api'
import { searchCountry } from '@f/composables/useCountries'

export default defineComponent({
  name: 'UseCountries',
  setup() {
    const {
      search: countrySearch,
      filteredCountries: countries,
      countryName,
      findCountry
    } = searchCountry({ minSearchLength: 1 })

    const countryCode = ref<string | null>(null)
    const country = ref<string | null>(null)

    const selectedCountry = computed({
      get: () => findCountry(countryCode.value || country.value || undefined),
      set: val => {
        countryCode.value = val?.alpha2Code || null
        country.value = val?.name || null
        countrySearch.value = ''
      }
    })

    const selectedCountryLabel = computed(() => {
      return selectedCountry.value && countryName(selectedCountry.value) || ''
    })

    function selectCountryAtIndex(index: number) {
      selectedCountry.value = !!~index && index < countries.value.length
        ? countries.value?.[index]
        : undefined
    }

    return {
      countries,
      country,
      countryCode,
      countryName,
      countrySearch,
      selectedCountry,
      selectCountryAtIndex,
      selectedCountryLabel,
    }
  }


})
