import { createMachine } from 'xstate'
import config from './config'
import options from './options'
import { OpenForestRedeemContext, OpenForestRedeemEvent, OpenForestRedeemState } from './types'

const fsm = createMachine<
    OpenForestRedeemContext,
    OpenForestRedeemEvent,
    OpenForestRedeemState
  >(config, options)

export default fsm
