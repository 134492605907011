export { default as GAME_COMPLETE } from './TreecodeGameCompleted.treedom-next.gql'
export { default as SHARE_GAME_REWARD_BY_MAIL } from './TreeCodeGameRewardEmail.gql'
export { default as NOTIFICATION_SET_ALL_AS_READ } from './NotificationSetAllAsRead.gql'
export { default as TREE_DIARY_COMMENT_ADD } from './TreeDiaryCommentAdd.gql'
export { default as TREE_DIARY_COMMENT_UPDATE } from './TreeDiaryCommentUpdate.gql'
export { default as TREE_DIARY_COMMENT_DELETE } from './TreeDiaryCommentDelete.gql'
export { default as TREE_DIARY_COMMENT_REPLY_ADD } from './TreeDiaryCommentReplyAdd.gql'
export { default as TREE_DIARY_COMMENT_REPLY_UPDATE } from './TreeDiaryCommentReplyUpdate.gql'
export { default as TREE_DIARY_COMMENT_REPLY_DELETE } from './TreeDiaryCommentReplyDelete.gql'
export { default as TREE_MESSAGE_ADD } from './TreeMessageAdd.gql'
export { default as TREE_MESSAGE_UPDATE } from './TreeMessageUpdate.gql'
export { default as TREE_NAME_UPDATE } from './TreeNameUpdate.gql'
export { default as TREE_NAME_UPDATE_NEXT } from './TreeNameUpdate.treedom-next.gql'
export { default as TREE_LIKE } from './TreeLike.gql'
export { default as IMPORT_USER } from './ImportUser.gql'
export { default as CONFIRM_EMAIL_REQUEST } from './ConfirmEmailRequest.gql'
export { default as FOLLOW_THE_FOREST } from  './FollowTheForest.gql'

