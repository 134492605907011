import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
.controller('neutralizeController', ['$compile', '$scope', '$filter', '$timeout', '$rootScope', 'User', 'Emission', 'TranslationService', 'Utility', 'Modal', function ($compile, $scope, $filter, $timeout, $rootScope, User, Emission, TranslationService, Utility, Modal) {
  var $ = window.$

  $rootScope.hasNeutralizableEmissions = false
  $scope.asset = $filter('asset')('')
  $scope.width = document.getElementById('contenBalls').offsetWidth
  $scope.emissionToNeutralizing = false
  $scope.co2ToNeutralize = 0

  let tableCeil
  var totalRow = 12
  var totalLine = 24
  $scope.maxHeight = 0
  var dim = $scope.width / totalRow
  var emissions = {}
  var height = dim * totalLine
  var dimensionBall = {
    small: dim,
    medium: dim * 2,
    big: dim * 3
  }

  var ctl = this
  ctl.totalPage = 0
  ctl.page = 0

  $scope.getUserEmissions = function () {
    User.emissions($rootScope.user || undefined)
    .then(function(res) {
      var data = res.data || res
      for (var key in data.emissions) {
        var emission = data.emissions[key]
        emission.position = []
        emission.page = false
        emission.positioned = false
        emission.picture = emission.subtype
        emissions[emission.id] = emission
      }

      $scope.totalNumberEmission = 1 * data.totalNumeber
      $scope.neutralizedKilos = data.neutralizedKilos
      $rootScope.availableTank = data.tank

      calculateTotalToNeutralize(emissions)
      $('#contenBalls').html('')
      createTableCeil(true)
      positionEmission('create')
      calculateMaxHeight()
    })
  }

  $scope.getUserEmissions()

  $scope.$on('NewEmissionSavedBroadcast', function (event, data) {
    $scope.getUserEmissions()
  })

  /**
   * Se l'utente si logga dentro la pagina aggiorno i dati
   */
  $scope.$on('UserLoggedBroadcast', function (event, data) {
    $scope.getUserEmissions()
  })

  /**
   * Calculate total of emissions to neutralize ed assegna le emissioni da calcolare
   * ad una variabile per renderizzare la parte mobile.
   */
  function calculateTotalToNeutralize (emissions) {
    if (!emissions || !Object.keys(emissions).length) {
      $scope.co2ToNeutralize = 0
      $scope.emissionToNeutralizeLength = 0
      $scope.emissionCannotBeNeutralized = 0
      $scope.emissionCannotBeNeutralized = 0
      return
    }

    // Co2 totale da neutralizzare
    $scope.co2ToNeutralize = Object.keys(emissions).reduce((iterator, emissionKey) => {
      iterator += emissions[emissionKey].kg
      return iterator
    }, 0)

    // Numero di emissioni che non posso calcolare per mancanza di co2
    $scope.emissionCannotBeNeutralized = Object.keys(emissions).reduce((iterator, emissionKey) => {
      if (emissions[emissionKey].kg > $scope.availableTank) {
        iterator++
        return iterator
      }
      return iterator
    }, 0)

    // Numero di emissioni da neutralizzare (include quelle neutralizzabili e
    // quelle non neutralizzabili)
    $scope.emissionToNeutralizeLength = Object.keys(emissions).length

    // Emissioni da neutralizzare con info complete
    $scope.emissionsToNeutralize = emissions

    moveProgressBar()
    $(window).resize(function () {
      moveProgressBar()
    })
  }

  /**
   * Calculate width of graphs
   */
  $scope.calculateGraphSize = function (id, availableTank, co2ToNeutralize) {
    if (availableTank === co2ToNeutralize && availableTank === 0) return '0%'

    if (id === 'co2TankSize') {
      return availableTank > co2ToNeutralize || availableTank === co2ToNeutralize
        ? '100%'
        : parseInt((100 * availableTank) / co2ToNeutralize) + '%'
    } else if (id === 'emissionToNeutralize') {
      return availableTank < co2ToNeutralize || availableTank === co2ToNeutralize
        ? '100%'
        : parseInt((100 * co2ToNeutralize) / availableTank) + '%'
    }
  }

  /**
   * trying another graph
   */

  // SIGNATURE PROGRESS
  function moveProgressBar () {
    var getPercent = ($scope.getPercentage($scope.availableTank, $scope.co2ToNeutralize) / 100)
    var getProgressWrapWidth = $('.progress-wrap').width()
    var progressTotal = getPercent * getProgressWrapWidth
    var animationLength = 2500
    // on page load, animate percentage bar to data percentage length
    // .stop() used to prevent animation queueing
    $('.progress-bar').stop().animate({
      left: progressTotal + 'px'
    }, animationLength)
  }

  $scope.getPercentage = function (total, myEmissions) {
    if (isUndefined(total) || isUndefined(myEmissions)) return 0
    var totalRounded = Math.round((myEmissions * 100) / total)
    if (totalRounded > 100) totalRounded = 100
    return totalRounded
  }

  // +20 #FIXME non so perché!
  // $('#contenBalls').css({'height': height + 20})

  function createTableCeil (initialize) {
    var count = 0

    if (initialize) {
      tableCeil = {}
    } else {
      for (var k in tableCeil) if (tableCeil.hasOwnProperty(k)) ++count
    }

    var start = count
    var end = count + totalLine

    for (var l = start; l < end; l++) {
      var newLine = {}
      for (var r = 0; r < totalRow; r++) {
        var newObject = {
          status: true,
          line: l,
          raw: r,
          right: r * dim,
          bottom: l * dim
        }
        newLine[r] = newObject
      }
      tableCeil[l] = newLine
    }

    // mi segno il numero totale di pagine in modo da nascondere lo slider in
    // alto -1 perché parto da zero
    ctl.totalPage = (end / totalLine) - 1

    if (ctl.totalPage > 0 && $('#neutralizeControl').css('right') === '300px') {
      $('#neutralizeControl').css('right', '28px')
    }

    ctl.totalPage > ctl.page ? $('#goUpEmission').show() : $('#goUpEmission').hide()
    ctl.page > 0 ? $('#goDownEmission').show() : $('#goDownEmission').hide()
  }
  createTableCeil(true)

  function positionEmission (type) {
    if (jQuery.isEmptyObject(emissions)) {
      $('#contenBalls').append($("#contentMyMessage").html())
    } else {
      for (var key in emissions) {
        var obj = emissions[key]

        // Per ogni emissione cerco dove metterla se è false vuol dire che non ho più spazio e devo crearne di nuovi
        if (!checkWhere(key, obj.type, type)) {
          // se non ci sono più posti disponibili richiamo la funzione per creare nuove righe
          createTableCeil()
          // richiamo la funzione stessa.
          checkWhere(key, obj.type, type)
        }
      }
    }

    hasNeutralizableEmissions()
  }

  function calculateMaxHeight () {
    $scope.maxHeight = 0
    for (var line in tableCeil) {
      // per ogni cella
      for (var row in tableCeil[line]) {
        // se lo status é true ovvero è libera //#FIXME
        if (tableCeil[line][row].status === false) {
          $scope.maxHeight = 1*line + 1
          continue
        }
      }
    }
  }

  // Passo la key(id) dell'emissione e il tipo (big, medium, small)
  function checkWhere (key, type, actionType) {
    // per ogni riga
    for (var line in tableCeil) {
      // per ogni cella
      for (var row in tableCeil[line]) {
        // se lo status é true ovvero è libera //#FIXME
        if (tableCeil[line][row].status === true) {
          // chiamo la funzione per vedere se attorno è tutto disponibile o no
          var haveTocontinue = tryFromHere(line, row, type)

          // se risponde false vuol dire che ci sono
          if (haveTocontinue !== true) {
            // segno le posizione connesse all'emissione in modo da liberarle in un seocndo momento
            emissions[key].position = haveTocontinue

            // segno tutte le celle come occupate
            haveTocontinue.forEach(function (entry) {
              tableCeil[entry[0]][entry[1]].status = false
            })

            actionType === 'create'
              ? createDivBalls(type, key, line, row)
              : repositionDivBalls(type, key, line, row)

            return true
          }
        }
      }
    }
    return false
  }

  // passo riga e colonna da controllare  e tipologia
  function tryFromHere (line, row, type) {
    line = parseInt(line)
    row = parseInt(row)
    var toCheck

    if (type === 'big') {
      toCheck = [
        [line, row], [line, row + 1], [line, row + 2],
        [line + 1, row], [line + 1, row + 1], [line + 1, row + 2],
        [line + 2, row], [line + 2, row + 1], [line + 2, row + 2]
      ]
    } else if (type === 'medium') {
      toCheck = [
        [line, row], [line, row + 1],
        [line + 1, row], [line + 1, row + 1]
      ]
    } else if (type === 'small') {
      toCheck = [
        [line, row]
      ]
    }

    var toStop = false

    toCheck.forEach(function (entry) {
      if (toStop) return

      if (!isUndefined(tableCeil[entry[0]])) {
        if (!isUndefined(tableCeil[entry[0]][entry[1]])) {
          if (!tableCeil[entry[0]][entry[1]].status) {
            toStop = true
          }
        } else {
          toStop = true
        }
      } else {
        toStop = true
      }
    })

    if (toStop) return true
    return toCheck
  }

  function myEmission (key) {
    var emission = {}

    emission.info = function () {
      return emissions[key]
    }

    emission.get = function (something) {
      return this.info()[something]
    }

    emission.picture = function () {
      return $scope.asset + 'bundles/treedomnewfrontend/images/calculator/' + this.get('picture') + '.png'
    }

    emission.compensate = function (idElement) {
      // Se sto già neutralizzando un'emissione, blocco l'utente.
      if ($scope.emissionToNeutralizing) return

      var selectedEmission = emissions[this.getIdFromDiv(idElement)]

      if(!selectedEmission) return

      var emissionId = selectedEmission.emissionId
      // Se non ho abbastanza co2 blocco l'utente per evitare la chiamata.
      if (selectedEmission.kg > $rootScope.availableTank) return

      // Dico all'applicazione quale emissione sto compensando
      $scope.emissionToNeutralizing = idElement

      // Procedo con la neutralizzazione
      Emission.neutralize(emissionId)
      .then(function(res) {
        var data = res.data || res
        $rootScope.availableTank = data.tank
        $scope.neutralizedKilos = data.neutralizedKilos

        // Avvio l'animazione di neutralizzazione, riposiziono gli elementi e
        // dico all'applicazione che ho terminato di neutralizzare l'emissione
        neutralizeEffetc(idElement)

        // Riposiziono gli elementi nella griglia.
        $scope.emissionToNeutralizing = false
        createTableCeil(true)

        // Cancello l'emissione
        delete emissions[emission.getIdFromDiv(idElement)]
        $scope.totalNumberEmission--
        calculateTotalToNeutralize(emissions)
        positionEmission('reposition')
        hasNeutralizableEmissions()
        return emissionId
      })
      .catch(function() {
        // In caso di errore dico all'applicazione che ho finito di neutralizzare.
        $scope.emissionToNeutralizing = false
      })
    }

    emission.createDivBalls = function (line, row) {
      return true
    }

    emission.getDiv = function () {
      return $('#neutralize_' + key)
    }

    emission.getIdFromDiv = function (idDiv) {
      var arrayId = idDiv.split('_')
      return arrayId[arrayId.length - 1]
    }

    emission.setDiv = function (line, row) {
      var text = (emissions[key].kg < $rootScope.availableTank)
        ? $filter('weight')(emissions[key].kg) + TranslationService.trans('emissions.tooltip.ok')
        : $filter('weight')(emissions[key].kg) + TranslationService.trans('emissions.tooltip.ko')

      // #FIXME vedere come è meglio
      var position = (row <= totalRow / 2) ? 'top' : 'top'

      var newDiv = '<div angular-tip data-text="' + text + '" data-position="' + position + '" class="neutralizeBalls-item innerball innerball-' + this.get('type') + ' pointer" id="neutralize_' + key + '" style="bottom:' + tableCeil[line][row].bottom + 'px; right:' + tableCeil[line][row].right + 'px; width:' + dimensionBall[this.get('type')] + 'px; height:' + dimensionBall[this.get('type')] + 'px; background-image:url(' + myEmission(key).picture() + ')"></div>'

      $('#contenBalls').append($compile(newDiv)($scope))

      if (key === $rootScope.userdata.lastEmission) {
        $('#neutralize_' + key).addClass('highlight')

        $(function () {
          function bounce () {
            var timer = setInterval(function () {
              if (startEmissionBounce === 1) {
                $(".highlight").addClass('tada')
                startEmissionBounce = -1
              } else {
                $(".highlight").removeClass('tada')
                startEmissionBounce = 1
              }
            }, 1000)
          }

          if (typeof startEmissionBounce === 'undefined') {
            startEmissionBounce = 1
            bounce()
          }
        })
      } else {
        if ($('#neutralize_' + key).hasClass('highlight')) {
          $('#neutralize_' + key).removeClass('highlight')
        }
      }

      return true
    }

    emission.repositionDiv = function (line, row) {
      return $("#neutralize_" + key).animate({'bottom': tableCeil[line][row].bottom - (ctl.page * height), 'right': tableCeil[line][row].right})
    }

    emission.setTooltip = function (row) {
      var ok = $filter('weight')(emissions[key].kg) + TranslationService.trans('emissions.tooltip.ok')
      var ko = $filter('weight')(emissions[key].kg) + TranslationService.trans('emissions.tooltip.ko')

      // se parte sinistra
      if (row <= totalRow / 2) {
        // se non posso neutralizzare
        if (emissions[key].kg > $rootScope.availableTank) {
          $('#neutralize_' + key).attr({'data-text': ko})
          // se  posso neutralizzare
        } else {
          $('#neutralize_' + key).attr({'data-text': ok})
        }
      } else {
        // se non posso neutralizzare
        if (emissions[key].kg > $rootScope.availableTank) {
          $('#neutralize_' + key).attr({'data-text': ko})
          // se  posso neutralizzare
        } else {
          $('#neutralize_' + key).attr({'data-text': ok})
        }
      }
    }

    emission.isNeutralizable = function () {
      var value =  this.get('kg') > $rootScope.availableTank ?  false : true
      return value
    }

    emission.enable = function () {
      return this.getDiv().draggable({containment: 'window', helper: 'clone', revert: 'invalid', appendTo: 'body'})
    }

    emission.disable = function () {
      var div = this.getDiv()
      if (div.hasClass('ui-draggable')) {
        div.draggable('destroy')
      }
      return div.addClass('innerball-grey')
    }

    return emission
  }

  /**
   * Al click sull'emissione la neutralizzo
   */
  $('body').on('click', '.neutralizeBalls-item', function (e) {
    // Se l'utente non è loggato apro la modale di login
    if (!$rootScope.user) {
      $timeout(Modal.open({templateUrl: 'login.html', easyClose: false, fitcontent: true}), 100)
      return
    }

    // Se c'è una neutralizzazione in attesa di risposta del server mi fermo.
    if ($scope.emissionToNeutralizing) return
    var elemId = this.id
    myEmission().compensate(elemId)
  })

  /**
   * Apre la modale di login
   */
  $scope.openMenuLogin = function () {
    Modal.open({templateUrl: 'login.html', easyClose: false, fitcontent: true})
  }

  /**
   * Vai in cima alla pagina
   */
  $scope.goToTop = function () {
    var body = $('html, body')
    body.stop().animate({ scrollTop: 0 }, '500', 'swing')
  }

  /**
   * Neutralizza tutto con un click
   */
  $scope.neutralizeAll = function () {
    var emissionsIds = Object.keys($scope.emissionsToNeutralize)
    if (!emissionsIds.length) return

    Utility.disableButton()

    Emission.neutralizeAll()
    .then(function(res) {
      var data = res.data || res
      $rootScope.availableTank = data.tank
      $scope.neutralizedKilos = data.neutralizedKilos

      // Avvio l'animazione di neutralizzazione, riposiziono gli elementi e
      // dico all'applicazione che ho terminato di neutralizzare le emissioni
      emissionsIds.forEach(emissionId => {
        var id = 'neutralize_' + emissionId
        neutralizeEffetc(id)
      })

      // Riposiziono gli elementi nella griglia.
      $scope.emissionToNeutralizing = false
      createTableCeil(true)

      // Cancello l'emissione
      emissionsIds.forEach(emissionId => {
        delete emissions[emissionId]
        $scope.totalNumberEmission--
      })
      calculateTotalToNeutralize()
      positionEmission('reposition')
      hasNeutralizableEmissions()
      Utility.enableButton()
    })
    .catch(function() {
      // In caso di errore dico all'applicazione che ho finito di neutralizzare.
      $scope.emissionToNeutralizing = false
      Utility.enableButton()
    })
  }

  /**
   * Animazione della neutralizzazione
   */
  function neutralizeEffetc (elemId) {
    var elem = document.getElementById(elemId)

    if (elemId.includes('mobile')) {
      var containerMobile = document.getElementById('contenBallsMobile')
      containerMobile.removeChild(elem)
      return
    }

    var elemClass = 'cloned_' + elemId
    var container = document.getElementById('contenBalls')

    // Creo i 16 quadrati per creare l'effetto 'vetro rotto'.
    for (let i = 0; i < 16; i++) {
      var clonedElem = elem.cloneNode(true)
      var height = parseInt(clonedElem.style.height) / 4
      var width = parseInt(clonedElem.style.width) / 4
      clonedElem.id = 'cloned_' + i
      clonedElem.classList.add(elemClass)
      clonedElem.style.clip = calculateClipProperty(height, width, i)
      container.appendChild(clonedElem)
    }

    // Nascondo l'emissione
    elem.style.opacity = '0'

    // Aggiungo agli elementi le classi per le animazioni.
    var elements = document.getElementsByClassName(elemClass)
    elements.forEach((_e, i) => {
      elements[i].classList.add('randomUpDown' + (i+1))
      elements[i].addEventListener('animationend', AnimationEndListener, false)
    })

    // Rimuovo dal DOM l'emissione.
    container.removeChild(elem)
  }

  /**
   * Listener di fine animazione delle emissioni neutralizzate.
   * Rimuove gli elementi dal DOM.
   */
  function AnimationEndListener (e) {
    var container = document.getElementById('contenBalls')
    var elem = e.target
    container.removeChild(elem)
  }

  /**
   * Calcola la proprietà CSS 'clip' dell'emissione da neutralizzare.
   */
  function calculateClipProperty (height, width, idx) {
    var index = idx + 1

    switch (index) {
      // Prima fila
      case 1:
        return 'rect(0px, ' + height + 'px, ' + width + 'px, 0px)'
      case 2:
        return 'rect(0px, ' + (height * 2) + 'px, ' + width + 'px, ' + width + 'px)'
      case 3:
        return 'rect(0px, ' + (height * 3) + 'px, ' + width + 'px, ' + (width * 2) + 'px)'
      case 4:
        return 'rect(0px, ' + (height * 4) + 'px, ' + width + 'px, ' + (width * 3) + 'px)'
      // Seconda fila
      case 5:
        return 'rect(' + height + 'px, ' + height + 'px, ' + (width * 2) + 'px, 0px)'
      case 6:
        return 'rect(' + height + 'px, ' + (height * 2) + 'px, ' + (width * 2) + 'px, ' + width + 'px)'
      case 7:
        return 'rect(' + height + 'px, ' + (height * 3) + 'px, ' + (width * 2) + 'px, ' + (width * 2) + 'px)'
      case 8:
        return 'rect(' + height + 'px, ' + (height * 4) + 'px, ' + (width * 2) + 'px, ' + (width * 3) + 'px)'
      // Terza fila
      case 9:
        return 'rect(' + (height * 2) + 'px, ' + height + 'px, ' + (width * 3) + 'px, 0px)'
      case 10:
        return 'rect(' + (height * 2) + 'px, ' + (height * 2) + 'px, ' + (width * 3) + 'px, ' + width + 'px)'
      case 11:
        return 'rect(' + (height * 2) + 'px, ' + (height * 3) + 'px, ' + (width * 3) + 'px, ' + (width * 2) + 'px)'
      case 12:
        return 'rect(' + (height * 2) + 'px, ' + (height * 4) + 'px, ' + (width * 3) + 'px, ' + (width * 3) + 'px)'
      // Quarta fila
      case 13:
        return 'rect(' + (height * 3) + 'px, ' + height + 'px, ' + (width * 4) + 'px, 0px)'
      case 14:
        return 'rect(' + (height * 3) + 'px, ' + (height * 2) + 'px, ' + (width * 4) + 'px, ' + width + 'px)'
      case 15:
        return 'rect(' + (height * 3) + 'px, ' + (height * 3) + 'px, ' + (width * 4) + 'px, ' + (width * 2) + 'px)'
      case 16:
        return 'rect(' + (height * 3) + 'px, ' + (height * 4) + 'px, ' + (width * 4) + 'px, ' + (width * 3) + 'px)'
      default:
        return 'rect(0px, ' + height + 'px, ' + width + 'px, 0px)'
    }
  }

  function hasNeutralizableEmissions () {
    for (var key in emissions) {
      if (myEmission(key).isNeutralizable()) {
        $rootScope.hasNeutralizableEmissions = true
        return $rootScope.hasNeutralizableEmissions
      }
    }
    $rootScope.hasNeutralizableEmissions = false
    return $rootScope.hasNeutralizableEmissions
  }

  function createDivBalls (type, key, line, row) {
    myEmission(key).setDiv(line, row)

    if ($rootScope.logged) {
      myEmission(key).isNeutralizable() ? myEmission(key).enable() : myEmission(key).disable()
    } else {
      myEmission(key).enable()
    }

    myEmission(key).setTooltip(row)
  }

  function repositionDivBalls (type, key, line, row) {
    myEmission(key).repositionDiv(line, row)
    myEmission(key).isNeutralizable() ? myEmission(key).enable() : myEmission(key).disable()
    myEmission(key).setTooltip(row)
  }

  ctl.goToPage = function (move) {
    if (move === 'up') {
      ctl.page = ctl.page + 1
      $('body').find('#contenBalls>div').each(function () {
        var bottom = parseInt($(this).css('bottom')) - (height)
        $(this).animate({'bottom': bottom})
      })
    } else {
      ctl.page = ctl.page - 1
      $('body').find('#contenBalls>div').each(function () {
        var bottom = parseInt($(this).css('bottom')) + (height)
        $(this).animate({'bottom': bottom})
      })
    }

    if (ctl.page === ctl.totalPage) {
      $('#goUpEmission').hide()
    } else {
      $('#goUpEmission').show()
    }

    if (ctl.page === 0) {
      $('#goDownEmission').hide()
    } else {
      $('#goDownEmission').show()
    }
  }
}])
