




























import {Component, Mixins, Prop} from "vue-property-decorator";
import Accordion from '@f/components/Accordion.vue'
import Overlay from '@f/components/Overlay.vue'
import Icon from '@f/components/Icon.vue'
import {navbar as navbarMenu} from '@f/configs/menus'
import ShowMenu from '@f/mixins/ShowMenu'

@Component({
  name: "Sidebar",
  components: {
    Accordion,
    Overlay,
    Icon,
  }
})
export default class Sidebar extends Mixins(ShowMenu) {
  @Prop({ type: Boolean, required: false, default: false }) open!: boolean;
  menuItems = navbarMenu
}
