import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
  .directive('widgetmobilespecie', ['$rootScope', '$compile', '$document', '$interval', 'Utility', '$state', '$timeout', function ($rootScope, $compile, $document, $interval, Utility, $state, $timeout) {

    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {
        // Se non siamo mobile, controllo la dimensione dello schermo,
        // Se è maggiore di un tablet, return
        if (!$rootScope.isMobile) {
          if (window.innerWidth > 768) return
        }

        var _activeWidget = false

        // Rimuove il widget.
        function removeWidget(widget) {
          widget.remove()
          // Controllo per sicurezza se esistono altri widget e li cancello.
          if (_activeWidget) {
            var otherWidgets = $document.find('.wmt_' + _activeWidget.key)
            if (otherWidgets.length) {
              otherWidgets.each(function (index) {
                otherWidgets[index].remove()
              })
            }
          }
          _activeWidget = false
        }

        // Funzione per renderizzare il widget con i dati richiesti.
        function renderWidget(widgetKey, _coords) {
          _activeWidget = $rootScope.trees.find(tree => tree.id === widgetKey)

          // Creo il template del widget.
          var template = '../../bundles/treedomnewfrontend/html-templates/widget/mobile/specie.html'
          var widgetId = 'wmt_' + widgetKey
          var html = '<div id="' + widgetId + '" class="widgetMobileContainer ' + widgetId + '"><div ng-include="\'' + template + '\'"></div></div>'

          $scope.widgetMobileClose = function () {
            var widget = $document.find('#' + widgetId)
            widget[0].style.transform = 'scale(0.1)'
            Utility.allowScroll()
            $timeout(function () { removeWidget(widget) }, 250)
          }
          $scope.widgetMobileSpecie = _activeWidget
          $scope.widgetShowThisTree = function (isGift) {
            if (isGift) $scope.widgetGiftFromPage()

            /*Utility.gaAdd('addProduct', _activeWidget)
            Utility.gaAction('click', {})
            Utility.gaSend()*/

            $state.go('species.tree', { treeId: _activeWidget.key })
            $rootScope.activeTree = _activeWidget
            Utility.blockScroll()
          }
          $scope.widgetGiftFromPage = function () {
            // nel caso ci fosse un evento segnato
            $rootScope.plantIn = false
            $rootScope.giftToUser = $rootScope.showUser.info
          }

          // Appendo il widget in fondo al body.
          var body = $document.find('body').eq(0)
          body.append($compile(angular.element(html))($scope))

          // Animazione a tutta pagina.
          $timeout(function () {
            var widgetElem = document.getElementById(widgetId)
            widgetElem.style.left = '0px'
            widgetElem.style.top = '0px'
            widgetElem.style.height = window.innerHeight + 'px'
            widgetElem.style.width = window.innerWidth + 'px'
            widgetElem.style.position = 'fixed'
            widgetElem.style.transform = 'scale(1)'
            Utility.blockScroll()
          }, 1)

          if (!$scope.$$phase) $scope.$apply()
        }

        // Mouseenter per creare il widget.
        element.on('click', function (e) {
          e.stopPropagation()

          // Coordinate del punto in cui clicco.
          var scrollTop = document.documentElement ? document.documentElement.scrollTop : document.body.scrollTop
          var scrollLeft = document.documentElement ? document.documentElement.scrollLeft : document.body.scrollLeft
          var coords = {
            left: !isUndefined(e.pageX) ? e.pageX : e.clientX + scrollLeft,
            top: !isUndefined(e.pageY) ? e.pageY : e.clientY + scrollTop
          }

          // Variabili del frontend
          var widgetKey = parseInt(attrs.widgetMobileProductId);//attrs.widgetMobileKey

          // Se il widget esiste, non lo ricreo.
          var oldWidget = document.getElementById(widgetKey)
          if (oldWidget) return

          // Lo cerco nella cache.
          if ($rootScope.trees.find(tree => tree.id === widgetKey)) {
            renderWidget(widgetKey, coords)
            return
          }
        })
      }
    }
  }])
