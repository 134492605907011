import {
  treeInfoQuery,
  treeMessagesQuery,
  treeLikesQuery,
  adjacentUserTreesQuery,
  adjacentEventTreesQuery,
  treesNeighborsQuery,
  treesWidgetQuery,

  treeLikeMutation,
  treeNameMutation
} from "./GraphQLQueries/TreeQueries";

import TreeMessageAddMutation from '@f/graphql/mutations/TreeMessageAdd.gql'
import TreeMessageUpdateMutation from '@f/graphql/mutations/TreeMessageUpdate.gql'
import { DataEventTreeName } from '@f/services/TagManager/events';

angular.module('treeService', [])
.factory('Tree', function ($http, $graph, $rootScope, $cookies, Routing, apollo) {
  var routing = Routing;
  var routeJs = function (route, params) {
    var url = routing.route(route, params);
    // Funzione per pulire l'url dal' bug di fos_user che mantiene i parameters nelle varie chiamate
    if (url.indexOf('?') > -1) url = url.substring(0, url.indexOf('?'));
    return url
  };

  var treeFactory = {};

  treeFactory.treeInfo = function (id) {
    return $http.get(routeJs('treedom_get_tree', {id: id}))
    .then(function(response) {
      return response.data || response
    })
  };

  treeFactory.treeEmissionNeutralized = function (id) {
    return $http.get(routeJs('treedom_tree_co2_new', {treeId: id}))
    .then(function(response) {
      return response.data || response
    })
  };


  treeFactory.setTreeMessage = function (id, message) {
    return $http.post(routeJs('treedom_set_message', {id: id}), message)
    .then(function(response) {
      return response.data || response
    })
  };

  treeFactory.markerTail = function (id, data) {
    return $http.post(routeJs('treedom_get_marker_tail', {id: id}), data)
    .then(function(response) {
      return response.data || response
    })
  };


  // NEW TREE SERVICE.

    var cookieName = document.querySelector('html').getAttribute('data-cjname');

    var treeHeaders = function(){
        return {
            "Authorization": "Bearer "+$cookies.get(cookieName)
        }
    };

    /**
     * @param {string} id - Tree ID.
     */
    treeFactory.getTreeMessages = function (id) {
        return $graph.query(treeMessagesQuery(id))()
            .then(function (data) {
                return data.tree;
            })
            .catch(function(_error){
                return _error;
            })
    };

    /**
     * @param {string} id - Tree ID.
     */
    treeFactory.getTreeLikes = function (id) {
        return $graph.query(treeLikesQuery(id))()
            .then(function (data) {
                return data.tree;
            })
            .catch(function(_error){
                return _error;
            })
    };

    /**
     * Find tree data by id into graphQL.
     * @param {string} id - Tree ID.
     */
    treeFactory.getTreeData = function (id) {
        return $graph.query(treeInfoQuery(id, $rootScope.ln))()
            .then(function (data) {
                return data.tree;
            })
            .catch(function(_error){
                return _error;
            })
    };

    /**
     * Find tree data (widget selection) by id into graphQL.
     * @param {string} id - Tree ID.
     */
    treeFactory.getTreeWidgetData = function (id) {
        return $graph.query(treesWidgetQuery(id, $rootScope.ln))()
            .then(function (data) {
                return data.tree;
            })
            .catch(function(_error){
                return _error;
            })
    };

    /**
     * Find tree data on adjacents trees by tree's id into graphQL.
     * @param {string} treeId - Tree ID.
     * @param {integer} userId - User ID.
     * @param {boolean} mine - Trees owned.
     * @param {boolean} gifted - Trees gifted.
     * @param {boolean} liked - Trees liked.
     */
    treeFactory.getAdjacentUserTreeData = function (treeId, userId, mine, gifted, liked) {
        return $graph.query(adjacentUserTreesQuery(treeId, userId, $rootScope.ln, mine, gifted, liked))()
            .then(function (data) {
                return data.adjacentTrees;
            })
            .catch(function(_error){
                return _error;
            })
    };
    /**
     * Find tree data on adjacents trees by tree's id into graphQL.
     * @param {string} treeId - Tree ID.
     * @param {integer} eventId - Event ID.
     */
    treeFactory.getAdjacentEventTreeData = function (treeId, eventId) {
        return $graph.query(adjacentEventTreesQuery(treeId, eventId, $rootScope.ln))()
            .then(function (data) {
                return data.adjacentTrees;
            })
            .catch(function(_error){
                return _error;
            })
    };

    /**
     * Find tree data on adjacents trees by tree's id into graphQL.
     * @param {string} treeId - Tree ID.
     * @param {integer} eventId - Event ID.
     */
    treeFactory.getTreesNeighborsData = function (treeId, after) {
        return $graph.query(treesNeighborsQuery(treeId, after, $rootScope.ln))()
            .then(function (data) {
                return data.treesNeighbors;
            })
            .catch(function(_error){
                return _error;
            })
    };

    /**
     * Open tree message by id.
     * @param {string} id - Tree ID.
     */
    treeFactory.openTree = function (id) {
        return $http.get(routeJs('treedom_open_tree', {id: id}), {headers: treeHeaders()})
            .then(function(response) {
              return response.data || response
            })
            .catch(function(err) {
              throw err.data || err
            })
    };

  /**
   * Find tree info by id.
   * @param {string} id - Tree ID.
   */
  treeFactory.getInfo = function (id) {
    return $http.get(routeJs('new_tree_id', {id: id}))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };

  /**
   * Find likes by tree ID.
   * @param {string} _id - Tree ID.
   */
  treeFactory.toggleLike = _id => {
    return $graph.mutate(`${treeLikeMutation(_id)}`)()
        .then(data => {
          return data.likes;
        })
        .catch(_error => {
          return _error;
        })
  }


  /**
   * Find name by tree ID.
   * @param {string} id - Tree ID.
   * @param {string} treeData.name - Tree name.
   */
  /*treeFactory.setName = function (id, treeData) {
    return $http.post(routeJs('new_tree_set_name', {id: id}), treeData)
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };
  */
  treeFactory.setName = function (_treeId, _name) {
    return $graph.mutate(treeNameMutation(_treeId, _name))()
        .then( data => {
          DataEventTreeName(data.name)
          return data.name;
        })
        .catch( _error => {
          return _error;
        })
  };

  /**
   * Set a tree Message
   * @param {object} message - Tree message.
   */
  treeFactory.setMessage = message => {
    const mutation = (message.id) ? TreeMessageUpdateMutation : TreeMessageAddMutation
    const variables = { message }
    return apollo.mutate({ mutation, variables })
  };


  /**
   * Find location by tree ID.
   * @param {string} id - Tree ID.
   * @param {string} type - One of ['Originator', 'Tree'].
   * @param {string} lat - Tree latitude.
   * @param {string} lng - Tree longitude.
   */
  treeFactory.getLocation = function (id, type, lat, lng) {
    type = (type === 'Originator' || type === 'Tree') ? type : 'Tree';
    return $http.get(routeJs('new_tree_get_location', {id: id, type: type, lat: lat, lng: lng}))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };

  /**
   * Find name by tree ID.
   * @param {string} type - User type ['Business', 'Private'].
   * @param {string} id - User ID.
   */
  treeFactory.getUserTrees = function (type, id) {
    type = (type === 'Business' || type === 'Private' || type === 'Event') ? type : 'Private';
    return $http.get(routeJs('new_tree_trees_id', {type: type, id: id}))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };

  return treeFactory
});
