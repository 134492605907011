import { render, staticRenderFns } from "./OrganizationInfo.vue?vue&type=template&id=40eb9f7c&scoped=true&"
import script from "./OrganizationInfo.vue?vue&type=script&lang=ts&"
export * from "./OrganizationInfo.vue?vue&type=script&lang=ts&"
import style0 from "./OrganizationInfo.vue?vue&type=style&index=0&id=40eb9f7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40eb9f7c",
  null
  
)

export default component.exports