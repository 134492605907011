import orderBy from 'lodash/orderBy'

angular.module('ngApp')
.controller('modalImageController', ['$scope', '$rootScope', '$stateParams', 'User', 'Modal', function ($scope, $rootScope, $stateParams, User, Modal) {

  $scope.imagesList = []
  $scope.activeImage = {}
  $scope.activeIndex = 0
  $scope.parentId = false
  $scope.hideComments = false

  $scope.openReplicasBox = {}
  $scope.userMessage = {}

  $scope.initializeCarousel = function (modalImageInfo) {
    modalImageInfo = modalImageInfo || $rootScope.modalImageInfo
    $scope.imagesList = modalImageInfo.images || []

    if ($scope.imagesList.length) {
      $scope.imagesList = $scope.imagesList.filter(tree => tree.image_id)
    }

    $scope.activeImage = modalImageInfo.images[modalImageInfo.activeIndex]
    $scope.activeIndex = modalImageInfo.activeIndex

    if ($scope.activeImage.comments) {
      $scope.activeImage.comments = orderLevelComments($scope.activeImage.comments)
    }

    if (modalImageInfo.hideComments) $scope.hideComments = true
  }

  var orderLevelComments = function (comments) {
    // Cambia la data in timestamp.
    comments = comments.map(function (comment) {
      var date = new Date(comment.created_on)
      comment.created_on = date.getTime()
      return comment
    })

    var firstLevelComments = comments.filter(function (comment) {
      return !comment.parent_id
    })

    var secondLevelComments = comments.filter(function (comment) {
      return comment.parent_id
    })

    var parsedComments = secondLevelComments.reduce((comments, reply) => {
      var index = comments.findIndex(comment => comment.id === reply.parent_id)
      if (index < 0) return firstLevelComments

      var parentComment = firstLevelComments[index]

      parentComment.replicas
        ? parentComment.replicas.push(reply)
        : parentComment.replicas = [reply]

      return firstLevelComments
    }, firstLevelComments)

    return orderBy(parsedComments, ['created_on'], ['desc'])
  }

  /**
   * Check if string contain only blank spaces
   * @param {string} string
   * @return {boolean}
   */
  var containOnlyBlankSpaces = function (string) {
    if (!string) return true

    var splittedString = string.split('')
    var lettersButBlankSpaces = splittedString.filter(function (letter) { return letter !== ' ' })
    if (!lettersButBlankSpaces.length) return true

    return false
  }

  /**
   * Remove special characters like whatsapp emoji
   * @param {string} string
   */
  var removeSpecialCharacters = function (string) {
    return string
      .replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, '')
  }

  $scope.showNextImage = function () {
    var maxIndex = $scope.imagesList.length ? $scope.imagesList.length - 1 : 0

    if ($scope.activeIndex < maxIndex) {
      $scope.activeIndex++
    } else {
      $scope.activeIndex = 0
    }

    $scope.activeImage = $scope.imagesList[$scope.activeIndex]
    if ($scope.activeImage.comments) {
      $scope.activeImage.comments = orderLevelComments($scope.activeImage.comments)
    }
  }

  $scope.showPrevImage = function () {
    var maxIndex = $scope.imagesList.length ? $scope.imagesList.length - 1 : 0

    if ($scope.activeIndex > 0) {
      $scope.activeIndex--
    } else {
      $scope.activeIndex = maxIndex
    }

    $scope.activeImage = $scope.imagesList[$scope.activeIndex]
    if ($scope.activeImage.comments) {
      $scope.activeImage.comments = orderLevelComments($scope.activeImage.comments)
    }
  }

  $scope.getTimestamp = function (date) {
    return new Date(date).getTime()
  }

  $scope.postMessage = function (message, type, postId, parentId, idx, replyIndex) {
    if (!type || !postId) return
    if (!$rootScope.user) Modal.open({templateUrl: 'login.html', easyClose: false, fitcontent: true})

    message = removeSpecialCharacters(message)
    if (containOnlyBlankSpaces(message)) return

    var commentData = {
      message: message,
      type: type,
      id: postId,
      parent: parentId
    }

    User.postComment(commentData)
    .then(function(res) {
      var response = res.data || res
      // Avviso il wall che ho aggiunto un commento.
      $rootScope.$broadcast('treeWallUpdated', $stateParams.item)

      // Creo un commento finto per pusharlo immediatamente.
      var newComment = {
        id: response.id,
        created_on: new Date(),
        message: message,
        parent_id: parentId,
        user_avatarId: $rootScope.userdata.info.picture,
        user_firstname: $rootScope.userdata.info.firstName,
        user_id: $rootScope.userdata.info.id,
        user_lastname: $rootScope.userdata.info.lastName,
        user_slug: $rootScope.userdata.info.slug,
        user_title: $rootScope.userdata.info.title
      }

      // Se ha un parentId, quindi è una risposta, lo pusho sotto il suo commento parente.
      if (parentId) {
        var index = findIndex($scope.activeImage.comments, ['id', parentId])
        var actualParent = $scope.activeImage.comments[index]
        actualParent.replicas
          ? actualParent.replicas.push(newComment)
          : actualParent.replicas = [newComment]
        $scope.openReplicasBox[idx] = true
        $scope.userMessage[replyIndex] = ''
        return
      }

      // Aggiungi il commento alla lista.
      $scope.activeImage.comments
        ? $scope.activeImage.comments.unshift(newComment)
        : $scope.activeImage.comments = [newComment]

      // Faccio in modo che i box delle risposte rimangano aperti dopo aver inserito
      // il nuovo commento
      if (Object.keys($scope.openReplicasBox).length) {
        $scope.openReplicasBox.forEach((value, index) => {
          delete $scope.openReplicasBox[index]
          var newIndex = ((index * 1) + 1).toString()
          $scope.openReplicasBox[newIndex] = true
        })
      }

      // Reset input.
      $scope.userMessage.text = ''
    })
  }

  /**
   * Cancella un commento.
   * @param {string|number} id - ID del commento da rimuovere.
   */
  $scope.deleteComment = function (id, parentId) {
    if (!parentId) {
      var commentIndex = findIndex($scope.activeImage.comments, {'id': id})
      var actualComment = $scope.activeImage.comments[commentIndex]

      // Se il post non è tuo, non lo elimino
      if (actualComment.user_slug !== $rootScope.user) return
    } else {
      var parentIndex = findIndex($scope.activeImage.comments, {'id': parentId})
      var actualParent = $scope.activeImage.comments[parentIndex]
      var replyIndex = findIndex(actualParent.replicas, {'id': id})
      var actualReply = actualParent.replicas[replyIndex]

      // Se il post non è tuo, non lo elimino
      if (actualReply.user_slug !== $rootScope.user) return
    }

    User.deleteComment(id)
    .then(function() {
      if (parentId) {
        actualParent.replicas = actualParent.replicas
          .filter((_comment, index) => replyIndex !== index)
        return
      }

      $scope.activeImage.comments = $scope.activeImage.comments
        .filter((_comment, index) => commentIndex !== index)
    })
  }
}])
