























import {
  defineComponent,
  ref,
  watch,
  onMounted,
  PropType,
  Ref, provide, readonly, computed
} from '@vue/composition-api'
import { GeoJSONSource, Map as Mapbox, MapboxGeoJSONFeature, NavigationControl } from 'mapbox-gl'
import { mapBox } from '@f/configs/app'
import { FlyToOptions, MAP_INSTANCE } from '@f/services/Map'
import { Feature, FeatureCollection, Point } from 'geojson'
import { onResize } from '@f/composables/hooks/resize'
import SingleMarker, { Properties as SinglePinProperties } from '@f/components/Map/Markers/SingleMarker'
import ClusterMarker from '@f/components/Map/Markers/ClusterMarker'
import { DataEventMap } from '@f/services/TagManager/events'


export type MapboxControlPosition = Parameters<Mapbox['addControl']>[1]

const DEFAULT_NAVIGATION_POSITION: MapboxControlPosition = 'top-right'

const getStyle = (styleName: string) => mapBox.styles[styleName as keyof typeof mapBox.styles]

export type Property = SinglePinProperties
export type Data = FeatureCollection<Point, Property>

const defaultData: Data = {
  type: 'FeatureCollection',
  features: [],
}

type MapOptions = {
  data?: Data,
  navigation?: boolean | MapboxControlPosition,
  onRender?: () => void,
  onInitCompleted?: () => void,
  disabled?: boolean,
}
const createMap = (container: HTMLElement, options: MapOptions) => {
  const {
    data = defaultData,
    navigation,
    onRender,
    onInitCompleted,
    disabled = false,
  } = options
  const map = new Mapbox({
    accessToken: mapBox.accessToken,
    container: container,
    style: getStyle(mapBox.defaultStyle),
    center: [0, 0],
    zoom: 10,
  })
  if (navigation) {
    const position = typeof navigation === 'boolean' ? DEFAULT_NAVIGATION_POSITION : navigation
    map.addControl(new NavigationControl({ showCompass: false }), position)
  }
  map.dragRotate.disable()
  if (disabled) {
    map.scrollZoom.disable()
    map.dragPan.disable()
    map.doubleClickZoom.disable()
    map.touchPitch.disable()
  }
  map.once('load', () => {
    map.addSource('data', ({
      type: "geojson",
      data: data || defaultData,
      cluster: true,
      clusterRadius: 20,
      clusterProperties: {
        ids: ['concat', ['concat', ['get', 'id'], ',']],
        variants: ['concat', ['concat', ['get', 'variant'], ',']],
        icons: ['concat', ['concat', ['get', 'icon'], ',']],
      },
    } as any))
    // this is needed to use clusters
    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'data',
      filter: ['!=', 'cluster', true],
      paint: {
        'circle-color': 'rgba(0, 0, 0, 0)'
      },
    });
    map.on('render', () => {
      map.resize()
      onRender?.()
    })
    onInitCompleted?.()
  })
  return map;
}

const updateData = (map: Mapbox, data: Data) => {
  const source = map.getSource('data') as GeoJSONSource
  if (source) {
    source.setData(data as FeatureCollection<Point, Property>)
  }
}

const defaultTarget: FlyToOptions = {
  zoom: 0,
  lng: -30,
  lat: 0,
}

// @ts-ignore
export default defineComponent({
  name: 'Map',
  components: {
    ClusterMarker,
    SingleMarker,
  },
  props: {
    navigation: {
      type: [Boolean, String] as PropType < boolean | MapboxControlPosition >,
      default: false
    },
    data: {
      type: Object as PropType<Data>,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Object as PropType<FlyToOptions>,
      required: false,
      default: () => defaultTarget,
    },
    animate: {
      type: Boolean,
      default: false
    }
  },
  emits: ['init-completed'],
  setup(props, { emit }) {
    const root = ref<HTMLElement>()
    const mapRef = ref<Mapbox>()
    const mapInitCompleted = ref(false)
    const dataLoaded = computed(() => !!props.data)
    provide(MAP_INSTANCE, readonly(mapRef))
    const zoomToSplit = (feature: Feature<Point, any>) => {
      if(props.disabled) return;
      const source = mapRef.value!.getSource('data')! as GeoJSONSource
      source.getClusterExpansionZoom(Number(feature.id), (_, zoom) => {
        mapRef.value!.easeTo({
          zoom,
          center: feature.geometry.coordinates as [number, number],
        })
      })
    }
    const features = ref<MapboxGeoJSONFeature[]>()
    const updateFeatures = () => {
      if (!mapRef.value!.isSourceLoaded('data')) return;
      features.value = mapRef.value!.querySourceFeatures('data')
    }
    const featuresLength= computed( () => features.value ? features.value?.length : 0)

    const onMarkerClick = (feature: Feature<Point, any>, zoom: boolean ) => {
      if(feature.properties.cluster) zoomToSplit(feature)
      const [lat, lon] = feature.geometry.coordinates
      DataEventMap({ countrySelected: feature.properties.country, cluster: { lat, lon }, treeSelected: feature.properties?.type === 'tree' ? feature.properties.id : undefined})
    }

    onMounted(() => {
      const onInitCompleted = () => {
        mapInitCompleted.value = true
        emit('init-completed', mapRef.value)
      }
      mapRef.value = createMap(root.value!, {
        disabled: props.disabled,
        data: props.data,
        navigation: props.navigation,
        onInitCompleted,
        onRender: updateFeatures,
      })

    })
    onResize(() => mapRef.value?.resize())
    watch([() => props.data, mapRef, mapInitCompleted]  as [() => Data, Ref<Mapbox>, Ref<boolean>], ([data, map, fullyLoaded]) => {
      if (!fullyLoaded) return
      if (!data) return
      if (!map) return
      updateData(map, data)
    })
    watch([() => props.target, mapRef] as [() => FlyToOptions, Ref<Mapbox>], ([target, map]) => {
      if (!map) return
      if (!target) {
        map.easeTo({
          zoom: defaultTarget.zoom,
          center: [defaultTarget.lng, defaultTarget.lat],
        })
        return
      }
      if (target.animationType === 'fly') {
        map.flyTo({
          zoom: target.preserveZoom ? Math.max(target.zoom!, map.getZoom()) : target.zoom,
          center: [target.lng, target.lat],
          maxDuration: target.maxDuration,
        })
        return
      }
      map.easeTo({
        zoom: target.zoom,
        center: [target.lng, target.lat],
      })
    })
    return {
      root,
      features,
      mapRef,
      zoomToSplit,
      mapInitCompleted,
      onMarkerClick,
      featuresLength,
      dataLoaded
    }
  },
})
