angular.module('ngApp')
.controller('newMapBusinessController', ['$scope', '$window', '$rootScope', 'Utility', 'Cart', 'Tree', 'Map', 'User', '$timeout', '$filter', '$document', '$compile', '$stateParams', '$state', function ($scope, $window, $rootScope, Utility, Cart, Tree, Map, User, $timeout, $filter, $document, $compile, $stateParams,$state) {
  var _ = window._
  var mapboxgl = window.mapboxgl
  mapboxgl.accessToken = 'pk.eyJ1IjoidG9tbWFzb3NwZXJvbmkiLCJhIjoiY2tnOTE3eW12MDJqazMybXNzOWV1YjloOSJ9.wtGsuDU7XIKjcv2cq8CiXw'

  $scope.userId = undefined
  $scope.type = ''
  $scope.map = false
  $scope.satelliteMode = true
  $scope.markers = []
  $scope.nurseryMarkers = []
  $scope.activeMapType = 'satellite'
  $scope.markersPimp = []
  $scope.modalMarker = false
  $scope.modalType = false
  $scope.modalIsLoading = false
  $scope.hideHeader = false
  $scope.hideFilters = false
  $scope.treeWeatherPreview = false
  $scope.treeWeatherToShow = undefined
  $scope.mapStyle = {
    street: 'mapbox://styles/tommasosperoni/cj78p16077drf2sshtkd6gjjf',
    satellite: 'mapbox://styles/mapbox/satellite-v9'
  }
  $scope.debounce = 1000
  $scope.queryHandler = null

  /**
   * Create map and store it as $scope.map
   */
  var createMap = function () {
    $scope.map = new window.mapboxgl.Map({
      container: 'map',
      center: $scope.coords || [-20.663872148719975, 18.607476500170065],
      zoom: 2.32,
      minZoom: 2,
      style: $scope.mapStyle.satellite
    })

    $scope.map.addControl(new window.mapboxgl.NavigationControl(), 'top-right')

    // disable map zoom when using scroll
    $scope.map.scrollZoom.disable();
  }

  /**
   * Transform lat and lng into a numbers, return same array.
   * @param {Array} rawMarkers
   */
  var parseMarkers = function (rawMarkers) {
    // var filteredRawMarkers = rawMarkers.filter(function (m) { return m.tile })
    return rawMarkers.map(function (rw) {
      if (rw.lat) rw.lat = Number(rw.lat)
      if (rw.lng) rw.lng = Number(rw.lng)
      // if (rw.maxLat) rw.maxLat = Number(rw.maxLat)
      // if (rw.minLat) rw.minLat = Number(rw.minLat)
      // if (rw.maxLng) rw.maxLng = Number(rw.maxLng)
      // if (rw.minLng) rw.minLng = Number(rw.minLng)
      if (rw.count) rw.count = Number(rw.count)
      return rw
    })
  }

  /**
   * Render markers or cluser in a map
   * @param {Array} markers - Parsed markers
   */
  var renderMarkers = function (markers) {
    markers.forEach(function (marker) {
      if (marker.count > 1) createCluster(marker)
      if (marker.count === 1) createTreeMarker(marker)
    })
  }

  /**
   * Render nurseries or cluser in a map
   * @param {Array} nurseries - Parsed markers
   */
  var renderNurseries = function (nurseries) {
    nurseries.forEach(function (nursery) {
      createNurseryMarker(nursery)
    })
  }

  /**
   * Remove all active markers
   */
  var removeMarkers = function () {
    var markersNumber = $scope.markers.length
    $scope.markers.forEach(function (marker, index) {
      if (marker) marker.remove()
      if (index + 1 === markersNumber) $scope.markers = []
    })

    var nurseryMarkersNumber = $scope.nurseryMarkers.length
    $scope.nurseryMarkers.forEach(function (marker, index) {
      if (marker) marker.remove()
      if (index + 1 === nurseryMarkersNumber) $scope.nurseryMarkers = []
    })
  }

  const fetchMarkers = function() {
    const zoom = Math.round(Number($scope.map.getZoom()))
    const bounds = $scope.map.getBounds()
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    const nursery = true
    const id = Number($scope.userId)
    const variables = {
      zoom,
      NE: { lat: Number(ne.lat.toFixed(5)), lng: Number(ne.lng.toFixed(5)) },
      SW: { lat: Number(sw.lat.toFixed(5)), lng: Number(sw.lng.toFixed(5)) },
      nursery,
    }
    if ($scope.type === 'event') {
      variables.eventId = id
    } else {
      variables.userId = id
    }

    return Map.getCluster(variables)
  }

  const recalculateMarkers = function (init = false) {
    return new Promise((resolve) => {
      if ($scope.queryHandler) clearTimeout($scope.queryHandler)
      $scope.queryHandler = setTimeout(() => {
        fetchMarkers()
          .then(cluster => {
            if (!init) removeMarkers() // FIXME: always remove markers. Check existence inside method
            const parsedData = cluster.reduce((data, child) => {
              return child.type === 'nursery'
                ? {
                  ...data,
                  nurseries: data.nurseries
                    ? [...data.nurseries, child ]
                    : [ child ]
                }
                : {
                  ...data,
                  trees: data.trees
                    ? [ ...data.trees, child ]
                    : [ child ]
                }
            }, {})
            mapPimp()
            if (parsedData.nurseries && parsedData.nurseries.length)
              renderNurseries(parsedData.nurseries)
            if (parsedData.trees && parsedData.trees.length)
              renderMarkers(parsedData.trees)
            resolve(parsedData)
          })
      }, $scope.debounce)
    })
  }

  /**
   * Create a marker of type 'nursery'
   * @param {Object} nursery
   */
  var createNurseryMarker = function (nursery) {
    var el = document.createElement('div')
    el.className = 'markerGLNursery'
    el.style.backgroundImage = 'url("' + $filter('asset')('bundles/treedomnewfrontend/images/map/nursery.svg') + '")'
    el.onclick = openModal.bind(this, nursery, 'nursery')

    if (nursery.trees) {
      var elCount = document.createElement('div')
      elCount.className = 'markerGLNumber markerGLNumberBusiness'
      elCount.textContent = getNurseryCount(nursery.count)
      el.append(elCount)
    }

    var createdMarker = new mapboxgl.Marker(el, {offset: [-45 / 2, -45 / 2]})
    .setLngLat([nursery.lng, nursery.lat])
    .addTo($scope.map)

    $scope.nurseryMarkers.push(createdMarker)
  }

  /**
   * Get parsed number of trees nursery
   * @param {number} num - Number
   */
  var getNurseryCount = function (num) {
    var str = num.toString()

    if (str.length <= 3) return num

    return str.substring(str.length - 2, 0)
      .split('')
      .reduce(function (finalNumber, n, index, array) {
        if (index === array.length - 1) {
          var stringToAdd = Number(n) > 0 ? ('.' + n + 'k') : 'k'
          finalNumber += stringToAdd
        } else {
          finalNumber += n
        }

        return finalNumber
      }, '')
  }

  /**
   * Create a marker of type 'tree'
   * @param {Object} marker
   */
  var createTreeMarker = function (marker) {
    var el = document.createElement('div')
    const tree = marker.trees[0]
    el.className = 'markerGL' + (marker.type === 'neighbor' ? ' markerGLneighbors' : ' markerGLmine') + ($scope.satelliteMode ? '' : ' markerGLTree')
    el.style.backgroundImage = $scope.satelliteMode
      ? 'url("'+tree.picture.small+'")'
      : 'url("'+tree.specie.icon.small+'")'
    el.onclick = openModal.bind(this, marker, 'tree')

    if (tree.user && tree.user.id !== Number($scope.userId)) {
      var elUser = document.createElement('div')
      elUser.className = 'markerGLUser'
      elUser.style.backgroundImage = 'url("'+tree.user.avatar.small+'")'
      el.append(elUser)
    }

    var createdMarker = new mapboxgl.Marker(el, {offset: [-45 / 2, -45 / 2]})
    .setLngLat([marker.lng, marker.lat])
    .addTo($scope.map)

    $scope.markers.push(createdMarker)
  }

  /**
   * Create a marker of type 'cluster'
   * @param {Object} marker
   */
  var createCluster = function (marker) {
    var el = document.createElement('div')
    el.className = getClusterClassnames(marker.count)
    el.onclick = zoomToCluster.bind(this, marker)

    var elNum = document.createElement('div')
    elNum.textContent = getNurseryCount(marker.count)
    elNum.className = 'markerGLClusterNumber treeMobileLikeCounter'

    el.append(elNum)

    var size = getClusterSize(marker.count)
    var createdMarker = new mapboxgl.Marker(el, {offset: [-size / 2, -size / 2]})
    .setLngLat([marker.lng, marker.lat])
    .addTo($scope.map)

    $scope.markers.push(createdMarker)
  }

  /**
   * Get className of cluster point in a map
   * @param {number} num - Number of trees that marker represents
   */
  var getClusterClassnames = function (num) {
    if (num > 999) {
      return 'mapClusterPoint mapClusterPointThousands markerGLNumberBusiness'
    } else if (num <= 999 && num > 99) {
      return 'mapClusterPoint mapClusterPointHundreds markerGLNumberBusiness'
    } else {
      return 'mapClusterPoint mapClusterPointDecimals markerGLNumberBusiness'
    }
  }

  /**
   * Get name size of cluster point in a map
   * @param {number} num - Number of trees that marker represents
   */
  var getClusterSize = function (num) {
    if (num > 999) {
      return 60
    } else if (num <= 999 && num > 99) {
      return 50
    } else {
      return 40
    }
  }

  /**
   * Zoom to cluster
   * @param {Object} cluster - Marker of type cluster
   */
  var zoomToCluster = function (cluster) {
    $scope.map.flyTo({
      center: { lat: cluster.lat, lng: cluster.lng },
      zoom: Math.max(0, Math.min(24, $scope.map.getZoom() + 1)),
      maxDuration: 1000,
    })
    // var bounds = [[cluster.maxLng, cluster.minLat], [cluster.minLng, cluster.maxLat]]
    // var padding = window.innerWidth > 768 ? 200 : 50

    // $scope.map.fitBounds(bounds, {
    //   padding: { top: padding, bottom: padding, left: padding, right: padding },
    //   duration: 300
    // })
  }

  /**
   * Open info modal
   * @param {Object} marker
   * @param {string} type - One of ['tree']
   */
  var openModal = function (marker, type) {
    $scope.modalMarker = true
    $scope.modalType = type

    if (type !== 'tree') {
      $scope.markerInfo = {
        count: marker.count,
        species: marker.species.map(s => ({ ...s }))
      }
      return
    }

    $scope.modalIsLoading = true

    Tree.getTreeData(marker.trees[0].id)
    .then(tree => {
      $scope.modalIsLoading = false

      const treeIconSmall = (tree.limited && tree.limited.icon && tree.limited.icon.small)
        || (tree.specie && tree.specie.icon && tree.specie.icon.small)
      const treePictureSmall = tree.picture && tree.picture.small

      $scope.markerInfo = {
        properties: {
          id: tree.id,
          treeId: tree.treeId,
          iconPicture: treeIconSmall,
          specie: tree.specie.name[$rootScope.ln],
          treePicture: tree.status > 3 && treePictureSmall || treeIconSmall,
          initialUserAvatar: tree.initialUser.avatar && tree.initialUser.avatar.small,
          initialUserTitle: tree.initialUser.title,
          initialUserSlug: tree.initialUser.slug,
          initialUserType: tree.initialUser.userType,
          userAvatar: tree.user.avatar && tree.user.avatar.small,
          userSlug: tree.user.slug,
          userTitle: tree.user.title,
          userType: tree.user.userType,
          event: tree.event.name || false,
          eventSlug: tree.event.slug,
          eventUserType: tree.event.user.userType
        }
      }
    })
  }

  /**
   * Center map on trees group
   * @param {Array} trees - Array of tree markers
   * @param {boolean} firstZoom - If is first time start automatic zoom
   */
  var fitBounds = function (trees, firstZoom) {
    var bounds = [[0, 0], [0, 0]]

    trees.forEach(function (entry) {
      if (bounds[0][0] > entry.lng || bounds[0][0] === 0) bounds[0][0] = entry.lng
      if (bounds[1][0] < entry.lng || bounds[1][0] === 0) bounds[1][0] = entry.lng
      if (bounds[0][1] > entry.lat || bounds[0][1] === 0) bounds[0][1] = entry.lat
      if (bounds[1][1] < entry.lat || bounds[1][1] === 0) bounds[1][1] = entry.lat
    })

    var options = { padding: { top: 50, bottom: 50, left: 50, right: 50 } }
    if (firstZoom) options.duration = 0

    $scope.map.fitBounds(bounds, options)

    if (firstZoom) {
      $timeout(function () {
        $scope.map.setZoom(2.1)
      }, 1000)
    }
  }

  /**
   * Add illustrations icon on map
   */
  var mapPimp = function () {
    if ($rootScope.mapPimp) {
      $scope.markersPimp = $rootScope.mapPimp($scope.map, $scope.satelliteMode, $scope.markersPimp)
    }
  }

  /**
   * Toggle map satellite mode
   */
  $scope.toggleSatelliteMap = function () {
    var newMapStyle = $scope.satelliteMode
      ? $scope.mapStyle.street
      : $scope.mapStyle.satellite

    mapPimp()
    $scope.map.setStyle(newMapStyle)
    $scope.satelliteMode = !$scope.satelliteMode
    recalculateMarkers()
  }

  /**
   * allo switch del tipo di mappa cambia lo stile della mappa, i marker e li ricarica
   */
  $scope.changeMapStatus = function (type) {
    // Se la mappa ha già il filtro cliccato non lo cambio
    if (type === 'satellite' && $scope.satelliteMode) return null
    if (type === 'street' && !$scope.satelliteMode) return null

    var newMapStyle = type === 'street'
      ? $scope.mapStyle.street
      : $scope.mapStyle.satellite

    mapPimp()
    $scope.map.setStyle(newMapStyle)
    $scope.satelliteMode = !$scope.satelliteMode
    recalculateMarkers()
  }

  /**
   * Close opened modal and reset modal variables
   */
  $scope.closeModal = function () {
    if ($scope.swiper) {
      $scope.swiper.destroy()
      $scope.swiper = false
    }

    $scope.modalMarker = false
    $scope.modalType = false
    $scope.markerInfo = false
  }

  /**
   * Open tree weather preview
   * @param {string|number} treeId - Tree ID.
   */
  $scope.openWeatherPreview = function (treeId) {
    if (!treeId) return

    $scope.closeModal()
    $scope.treeWeatherToShow = treeId

    if (!$rootScope.singleTree || ($rootScope.singleTree && $rootScope.singleTree.id !== treeId)) {
      Tree.getTreeData(treeId)
      .then(tree => {
        $rootScope.singleTree = tree
      })
    }
  }

  $scope.closeWeatherPreview = function () {
    $scope.treeWeatherToShow = undefined
    $rootScope.singleTree = undefined
  }

  /**
   * Inizialize map with markers
   * @param {string|number} id - User, organization or event ID
   */
  $scope.initMap = function (id, type) {
    createMap()

    $scope.userId = id
    $scope.type = type
    recalculateMarkers(true)
      .then(({ trees, nurseries }) => {
        var markersToCenter = trees && trees.length ? trees : nurseries
        if (markersToCenter && markersToCenter.length) fitBounds(markersToCenter, true)
        $scope.map.once('moveend', function () {
          $scope.map.on('moveend', function () {
            recalculateMarkers();
          });
        });
        $scope.map.once('idle', () => {
          if(!$scope.$$phase) $scope.$apply()
          $timeout(() => {
            $scope.map.resize();
          });
      })
    })
  }
  
  if ($state.current.name === 'iframe') {
    $scope.initMap($stateParams.user, $rootScope.iframeMode)
    $scope.hideHeader = true
    $scope.mapIsExpanded = true
    $scope.hideFilters = true
    $scope.treeWeatherPreview = true;
  }

  var ListenerStateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
    if (fromState.name === toState.name && toParams.slug !== fromParams.slug) {
      removeMarkers()
      $scope.map.remove()
    }
  })

  /**
   * Ascoltatore per inizializzare la mappa da altri controller
   * @param {string} data.id - User, Organization or Event ID
   * @param {string} data.type - One of ['User', 'Organization', 'event']
   * @param {boolean} data.expanded - Map is expanded
   * @param {boolean} data.preview - Tree weather preview
   */
  var ListenerLoadOrganizationMap = $rootScope.$on('LoadOrganizationMap', function (event, data) {
    if (!data || !data.id) return
    $scope.initMap(data.id, data.type, data.lat, data.lng)
    $scope.mapIsExpanded = data.expanded || false
    $scope.hideHeader = data.hideHeader || false
    $scope.hideFilters = data.hideFilters || false
    $scope.treeWeatherPreview = data.preview || false
  })

  /**
   * Ascoltatore per eseguire il resize della mappa da altri controller.
   */
  var ListenerResizeOrganizationMap = $rootScope.$on('ResizeOrganizationMap', function () {
    if ($scope.map) $scope.map.resize()
  })

  /**
   * Ascoltatore per visualizzare i comandi della mappa.
   */
  var ListenerExpandOrganizationMap = $rootScope.$on('ExpandOrganizationMap', function (event, expand) {
    $scope.mapIsExpanded = expand || false
  })

  // Destroy listeners when controller has been detached
  $scope.$on('$destroy', ListenerStateChangeStart)
  $scope.$on('$destroy', ListenerLoadOrganizationMap)
  $scope.$on('$destroy', ListenerResizeOrganizationMap)
  $scope.$on('$destroy', ListenerExpandOrganizationMap)
}])
