






























































































































































































import {computed, defineComponent, ref, watch} from '@vue/composition-api'
import PrivateInfo from '@f/components/User/PrivateInfo.vue'
import Map from '@f/components/Map/Map.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import {fetchPrivateData} from '@f/composables/fetchUserData'
import {fetchPrivateMap} from '@f/composables/fetchMaps'
import useRoutes, {ExactMatchOption} from '@f/composables/useRoutes'
import {RawParams} from '@uirouter/angularjs'
import {getImageAsset} from '@f/utils'
import {RestUserMapTree} from '@f/@types/rest'
import useAuthentication from '@f/composables/useAuthentication'
import {localeStorageKeys} from '@f/configs/app'
import UserGiftUpselling from '@f/pages/User/partials/UserGiftUpselling.vue'
import {maxWidth, useMediaQuery} from '@f/utils/breakpoints'
import UserSubscriberBadge from './partials/UserSubscriberBadge.vue'
import TreedomIconOutlined from '@f/images/user/treedom-icon-outlined.svg'
import DownloadAppSnackBanner from '@f/components/DownloadAppSnackBanner.vue'
import { isLoggedInAs } from '@f/services/Auth'

// @ts-ignore
export default defineComponent({
  name: 'UserPage',
  methods: { isLoggedInAs },
  components: {
    DownloadAppSnackBanner,
    UserGiftUpselling,
    Map,
    PrivateInfo,
    Treecon,
    UserSubscriberBadge,
    TreedomIconOutlined,
    // Pages
    UserJournal: () =>
      import(
        /* webpackChunkName: "UserJournal" */ '@f/pages/User/partials/UserJournal.vue'
      ),
    UserSubscription: () =>
      import(
        /* webpackChunkName: "UserSubscription" */ '@f/pages/User/partials/UserSubscription.vue'
      ),
    UserTrees: () =>
      import(
        /* webpackChunkName: "UserTrees" */ '@f/pages/User/partials/UserTrees.vue'
      ),
    UserBadges: () =>
      import(
        /* webpackChunkName: "UserBadges" */ '@f/pages/User/partials/UserBadges.vue'
      ),
    UserForests: () =>
      import(
        /* webpackChunkName: "UserForests" */ '@f/pages/User/partials/UserForests.vue'
      ),
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    mine: Boolean,
    gifted: Boolean,
    liked: Boolean,
  },
  setup(props) {
    const mapCanLoad = ref(false)
    const { currentState, currentParams, href, go, isCurrentState } = useRoutes()

    const {user, loading} = fetchPrivateData(props.slug)

    const userType = computed(() => user.value?.info?.user_type)
    const userId = computed(
      () => (!!user.value?.info.id && Number(user.value.info.id)) || undefined
    )
    const showOnboarding = ref(false)
    const { isLoggedAs } = useAuthentication()
    const isMobile = useMediaQuery(maxWidth('md'))
    const subscriberBadge = ref<{ onDiscoverMoreClick: () => void } | null>(
      null
    )
    const isOwner = computed(() => isLoggedAs(user.value?.info))
    const hasSubscriptions = computed(() => {
      if (!user.value || !user.value.subscriptions) return false
      return user.value.subscriptions.totalCount > 0
    })
    const showSubscriptionTab = computed(() => isOwner.value && hasSubscriptions.value)

    const routeComponent = computed(() => {
      if (isCurrentState('user', undefined, { state: true }))
        return 'UserJournal'
      if (isCurrentState('user.subscription')) return 'UserSubscription'
      if (isCurrentState('user.trees')) return 'UserTrees'
      if (isCurrentState('user.badges')) return 'UserBadges'
      if (isCurrentState('user.forests')) return 'UserForests'
      return null
    })


    function routeIcon(
      icon: string,
      routeName: string,
      routeParams?: RawParams,
      exact?: ExactMatchOption
    ) {
      return (
        icon +
        '-' +
        (isCurrentState(routeName, routeParams, exact) ? 'fill' : 'line')
      )
    }

    const isOnBoarding = computed(() => {
      if (user.value?.info && isLoggedAs(user.value?.info))
        return !localStorage.getItem(localeStorageKeys.onBoardingProfileKey)
    })

    const { trees } = fetchPrivateMap(userId)

    const mapData = computed(() => {
      if (!trees.value) {
        return {
          type: 'FeatureCollection',
          features: [],
        }
      }
      return {
        type: 'FeatureCollection',
        features: (trees.value as RestUserMapTree[])
          ?.filter((tree) => tree.type.includes('mine'))
          ?.map((tree) => ({
            type: 'Feature',
            id: tree.id,
            geometry: {
              type: 'Point',
              coordinates: [tree.lng, tree.lat],
            },
            properties: {
              country: tree.country,
              type: 'tree',
              id: `tree-${tree.id}`,
              icon: tree.isPlanted
                ? getImageAsset(
                    String(tree.treePicture || tree.specieIcon),
                    'small'
                  )
                : undefined,
              link: href('user.map.tree', { treeId: tree.id }),
            },
          })),
      }
    })

    const goToMap = () => {
      if (!mapData.value.features.length) return go('user.map.plant')
      return go('user.map')
    }

    const onSubscriberClick = () => {
      subscriberBadge.value?.onDiscoverMoreClick()
    }

    const show_subscriber = computed(() => {
      if (!user.value) return false
      return user.value.hasActivePlan
    })

    watch(
      () => isOnBoarding.value,
      () => {
        if (isOnBoarding.value)
          setTimeout(() => {
            showOnboarding.value = true
            localStorage.setItem(localeStorageKeys.onBoardingProfileKey, '1')
          }, 2000)
      }
    )

    watch(
      [routeComponent, loading, showSubscriptionTab],
      ([routeComponent, loading, showSubscriptionTab]) => {
        if (routeComponent === 'UserSubscription') {
          // user check is needed because we need to be certain that the query has been loaded
          if ((!loading && !showSubscriptionTab)) {
            return go('user')
          }
        }
      }
    )

    return {
      currentParams,
      currentState,
      href,
      isCurrentState,
      routeComponent,
      routeIcon,
      user,
      userType,
      userId,
      mapCanLoad,
      scrollTo,
      mapData,
      goToMap,
      showOnboarding,
      isOnBoarding,
      isMobile,
      show_subscriber,
      subscriberBadge,
      onSubscriberClick,
      showSubscriptionTab,
      loading,
      isOwner,
    }
  },
})
