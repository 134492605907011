import { JSONSchemaType } from 'ajv'
import { IUserIntrestInEvent } from '@f/validation/types/dataLayer'

export const UserIntrestInEventSchema: JSONSchemaType<IUserIntrestInEvent> = {
  type: 'object',
  required: ['event', 'eventName'],
  properties: {
    event: {type: 'string'},
    eventName: {type: 'string'}
  }
}

