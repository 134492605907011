import {
  CompleteGameMutation,
  CompleteGameMutationVariables,
} from '@/public_src/@types/treedom-next'
import { apiClient } from '@f/apollo'
import treedomNextClient from '@f/apollo/clients/api/treedom-next'
import { GAME_COMPLETE, SHARE_GAME_REWARD_BY_MAIL } from '@f/graphql/mutations'
import { GAME_INFO, TREECODE_BY } from '@f/graphql/queries'

export function fetchGameData(treecode: string, userId: string) {
  return treedomNextClient
    .query({
      query: GAME_INFO,
      variables: {
        where: { code: treecode },
        userId,
      },
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      const treecodeData = data.treecode
      if (!treecodeData || !treecodeData.game) {
        return null
      }

      const game = treecodeData.game

      // Map the new response structure to match the old one
      const result = {
        gameExpireDate: game.expireDate,
        // reward is deprecated
        triggerReward: false,
        gameCompleted: !!game.completionResult,
        gameResult: game.completionResult?.result,
      }

      return result
    })
}

export function completeGame(treecode: string, correctAnswers: number) {
  return treedomNextClient
    .mutate<CompleteGameMutation, CompleteGameMutationVariables>({
      mutation: GAME_COMPLETE,
      variables: {
        input: {
          code: treecode,
          result: correctAnswers,
        },
      },
    })
    .then(({ data }) => {
      const result = data?.treecode?.game?.complete

      if (!result) {
        // Handle the case where the result is not returned
        throw new Error('Game completion failed.')
      }

      if ('message' in result) {
        // Handle error returned by the mutation
        throw new Error(result.message)
      }

      // Map the response to match the old structure if necessary
      const mappedResult = {
        completed: !!result.id,
        reward: null, // Rewards are deprecated
        rewardUuid: null, // Rewards are deprecated
      }

      return mappedResult
    })
}

export async function getTreecodeInfo(variables: {
  field: string
  value: string
}) {
  const pollingLimit = 10
  let pollingTries = 0
  while (true) {
    const result = await treedomNextClient.query({
      query: TREECODE_BY,
      variables: {
        where: { code: variables.value },
      },
      fetchPolicy: 'network-only',
    })

    const treecode = result.data?.treecode
    if (treecode) {
      // Map the new data structure to match the old one
      const forest = treecode.forest
      const forestOwner = forest?.owner || treecode.owner

      const mappedTreecode = {
        id: treecode.uuid, // Assuming 'uuid' maps to 'id'
        code: treecode.code,
        uuid: treecode.uuid,
        isGift: null, // Set to null or appropriate default
        isPicker: null,
        isOpen: null,
        isVirgin: null,
        isMultiple: null,
        howMany: null,
        enabled: null,
        exhaust: null,
        expireDate: null,
        owner: {
          id: forestOwner?.id || null,
          title: forestOwner?.profile?.displayName || null,
        },
        trees: [
          {
            treeId: null, // Not available in new data
            event: forest
              ? {
                  slug: forest.slug,
                  user: {
                    id: forestOwner?.id || null,
                    slug: forestOwner?.profile?.slug || null,
                  },
                }
              : null,
          },
        ],
        event: forest
          ? {
              slug: forest.slug,
              user: {
                id: forestOwner?.id || null,
                slug: forestOwner?.profile?.slug || null,
              },
            }
          : null,
      }

      return mappedTreecode
    }

    pollingTries++
    if (pollingTries === pollingLimit) {
      return null
    }
    await sleep(500)
  }
}

export function shareRewardByMail(treecodeUuid: string, email: string) {
  return apiClient.mutate({
    mutation: SHARE_GAME_REWARD_BY_MAIL,
    variables: {
      input: {
        uuid: treecodeUuid,
        email: email,
      },
    },
  })
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))
