


























import { computed, defineComponent, ref } from '@vue/composition-api'
import Treecon, { TreeconIcon } from '@f/components/Treecons/Treecon.vue'


type Status = 'idle' | 'loading' | 'success' | 'error'
// @ts-ignore
export default defineComponent({
  name: 'AddToCartIconButton',
  components: {
    Treecon,
  },
  setup(_, { emit }) {
    const status = ref<Status>('idle')
    const icon = computed<TreeconIcon>(() => {
      switch (status.value) {
        default:
        case 'idle':
          return 'cart-line'
        case 'loading':
          break;
        case 'success':
          return 'check-fill-line';
        case 'error':
          return 'close-line';
      }
      return 'cart-line'
    })
    const onClick = () => {
      if (status.value !== 'idle') return
      status.value = 'loading'
      const callback = (result: boolean) => {
        if (!result) {
          status.value = 'error'
        } else {
          status.value = 'success'
        }
        setTimeout(() => {
          status.value = 'idle'
        }, 2000)
      }
      emit('click', callback)
    }
    return {
      onClick,
      status,
      icon,
    }
  },
})
