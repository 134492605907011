




























import { Vue, Component, Prop } from 'vue-property-decorator'
import BaseInput from '@f/components/Form/BaseInput.vue'
import HomonymyItem from '@f/components/Auth/HomonymyItem.vue'
import SlideDownTransition from '@f/transitions/SlideDown'
import { HomonymyUser, HomonymyLoginPayload } from '@f/@types'

@Component({
  components: { BaseInput, HomonymyItem, SlideDownTransition }
})
export default class HomonymyList extends Vue {
  @Prop({ type: Array, required: true, default: [] }) readonly homonymies!: any[]

  selectedUser: HomonymyUser | null = null
  password: string | null = ''

  // Refs
  $refs!: Vue["$refs"] & {
    homonymyPassword: BaseInput
  }

  // Computed
  get listHomonymies() {
    return this.homonymies.filter(user => !this.selectedUser || this.selectedUser === user)
  }

  get selectedUserAvatar(): string {
    return this.selectedUser?.avatar?.small || ""
  }

  get selectedUserEmail(): string {
    return this.selectedUser?.email || ""
  }

  // Methods
  // TODO: Implement code generation from GraphQL
  pick(user: HomonymyUser) {
    this.selectedUser = user
    // this.$nextTick(() => {
    //   this.$refs.homonymyPassword && this.$refs.homonymyPassword.focus()
    // })
  }

  back() {
    this.selectedUser = null
    this.password = ''
  }

  submit(password: string) {
    const id: number | null = this.selectedUser && this.selectedUser.id || null
    this.$emit('submit', <HomonymyLoginPayload>{ id, password })
  }
}
