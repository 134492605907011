var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"treeDiaryContainer"},[_c('tree-diary-item-header',{attrs:{"picture":_vm.icon,"title":_vm.authorName,"subtitle":_vm._f("datetime")(_vm.post.createdAt),"from-tree":true}}),_vm._v(" "),_c('div',{staticClass:"treeItemContainer no-margin"},[_c('p',{staticClass:"small",class:{ 'no-margin': _vm.isReadable }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_vm._v(" "),(_vm.visibilityDisclaimerNeeded)?_c('p',{staticClass:"textLight smallest no-margin"},[_c('em',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageVisibilityText)}})])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"treeItemContainer no-margin",attrs:{"ng-if":"attrs.postType === 'stream'"}},[_c('hr',{staticClass:"no-margin-top"}),_vm._v(" "),_c('p',{staticClass:"textLight smallest no-margin"},[_c('em',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.visibilityText)}})])])]),_vm._v(" "),_c('tree-diary-item-share',{attrs:{"url":_vm.shareUrl},nativeOn:{"click":function($event){return _vm.$emit('triggerDataEvent', {
        action: 'share',
        element: 'messagePost',
      })}}}),_vm._v(" "),_c('comment-list',{attrs:{"post-id":_vm.post.id,"comments-count":_vm.post.commentsCount},on:{"saved":function($event){return _vm.$emit('triggerDataEvent', {
        action: 'saveComment',
        element: 'messagePost',
      })},"deletedComment":function($event){return _vm.$emit('triggerDataEvent', {
        action: 'deleteComment',
        element: 'messagePost',
      })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }