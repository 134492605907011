import { Vue, Component } from 'vue-property-decorator'
import { getIubendaService } from '@f/services/Iubenda'

@Component({
  name: 'UseIubendaService'
})
export default class UseIubendaService extends Vue {

  /*--- COMPUTED ------*/
  get policyLinks() {
    return {
      privacy: getIubendaService()?.urls?.privacy || { href: this.$state.href('page.privacy') },
      cookie: getIubendaService()?.urls?.cookie || { href: this.$state.href('page.cookie') }
    }
  }

  /*--- EVENTS --------*/
  mounted() {
    getIubendaService()?.mountScript('iubenda_policy')
  }
}

