
export type BreakpointName = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
export type Breakpoints = {
    [key in BreakpointName]: number
}

export const breakpoints: Breakpoints = {
    xxs: 320,
    xs: 480,
    sm: 680,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1440,
} as const
