var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tr-nav tr-nav--sticky",class:("tr-nav--" + _vm.template),style:({ height: _vm.navbarHeight }),attrs:{"id":"navbar"}},[_c('div',{ref:"navbar",staticClass:"tr-navbar"},[(_vm.template === 'default')?_c('nav',{staticClass:"tr-navbar-top tr-visible-sm"},[_c('ul',{staticClass:"tr-flex-nav"},[_c('li',[_c('a',{attrs:{"href":_vm.blogUrl},domProps:{"innerHTML":_vm._s(_vm.$t('nav.treeBlog.title'))}})]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"#"},domProps:{"innerHTML":_vm._s(_vm.$t('page.linktitle.customerCare'))},on:{"click":function($event){return _vm.$state.go('customercare', {}, { location: false })}}})]),_vm._v(" "),_c('li',[_c('LanguageSelector')],1),_vm._v(" "),_c('li',[_c('CurrencySelector')],1)])]):_vm._e(),_vm._v(" "),_c('header',{staticClass:"tr-navbar-header"},[_c('div',{staticClass:"tr-navbar-header__left"},[_c('a',{staticClass:"tr-logo",attrs:{"href":_vm.$state.href('index')}},[_c('Logo',{attrs:{"alt":"Treedom | Let's green the planet!","title":"Treedom | Let's green the planet!"}})],1),_vm._v(" "),_c('button',{staticClass:" tr-button--icon button-hamburger",on:{"click":function($event){_vm.sidebarOpen = !_vm.sidebarOpen}}},[_c('Treecon',{attrs:{"icon":"ham-menu-line"}})],1),_vm._v(" "),(_vm.sidebarOpen)?_c('Overlay',{staticClass:"tr-toolbar-sidenav",attrs:{"left":"","white":""}},[_c('MobileSidebar',{attrs:{"user-info":_vm.userInfo},on:{"close":function($event){_vm.sidebarOpen = false}}})],1):_vm._e(),_vm._v(" "),_c('button',{staticClass:"tr-button--icon search-icon",attrs:{"aria-label":_vm.$t('generic.search')},on:{"click":function($event){_vm.searchModalOpen = !_vm.searchModalOpen}}},[_c('Icon',{staticClass:"resized-icon",attrs:{"name":"search"}})],1),_vm._v(" "),(_vm.template === 'default')?_c('nav',{staticClass:"tr-navbar-main"},[_c('ul',{staticClass:"tr-navbar-nav tr-flex-nav"},[_c('li',{key:'sm-0',staticClass:"tr-navbar-item",class:{
                'has-submenu': false,
                active: _vm.activeMenu === 0,
              },attrs:{"id":0},on:{"&mouseenter":function($event){_vm.activeMenu = 0},"&mouseleave":function($event){_vm.activeMenu = null}}},[_c('span',[_vm._v("\n                "+_vm._s(_vm.$t('nav.plant.title'))+"\n              ")]),_vm._v(" "),(_vm.activeMenu === 0)?_c('Submenu',{attrs:{"items":[]}},[_c('PlantMenu')],1):_vm._e()],1),_vm._v(" "),_c('li',{key:'sm-1',staticClass:"tr-navbar-item",class:{
                'has-submenu': true,
                active: _vm.activeMenu === 1,
              },attrs:{"id":1},on:{"&mouseenter":function($event){_vm.activeMenu = 1},"&mouseleave":function($event){_vm.activeMenu = null}}},[_c('span',[_vm._v("\n                "+_vm._s(_vm.$t('nav.business.title'))+"\n              ")]),_vm._v(" "),(_vm.activeMenu === 1)?_c('Submenu',{attrs:{"items":[]}},[_c('BusinessMenu')],1):_vm._e()],1),_vm._v(" "),_c('li',{key:'sm-2',staticClass:"tr-navbar-item",class:{
                'has-submenu': true,
                active: _vm.activeMenu === 2,
              },attrs:{"id":2},on:{"&mouseenter":function($event){_vm.activeMenu = 2},"&mouseleave":function($event){_vm.activeMenu = null}}},[_c('span',[_vm._v("\n                "+_vm._s(_vm.$t('nav.whoWeAre.title'))+"\n              ")]),_vm._v(" "),(_vm.activeMenu === 2)?_c('Submenu',{attrs:{"items":[]}},[_c('WhoWeAreMenu')],1):_vm._e()],1)])]):_vm._e(),_vm._v(" "),(_vm.template === 'default')?_c('TreecodeWidget',{staticClass:"tr-navbar-item tr-navbar-item--treecode tr-visible-sm"}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"tr-navbar-header__center"},[_c('a',{staticClass:"tr-logo",attrs:{"href":_vm.$state.href('index')}},[_c('Logo',{attrs:{"alt":"Treedom | Let's green the planet!","title":"Treedom | Let's green the planet!"}})],1)]),_vm._v(" "),_c('div',{staticClass:"tr-navbar-header__right"},[(_vm.template === 'default')?_c('UserWidget'):_vm._e(),_vm._v(" "),_c('button',{staticClass:"tr-button--icon search-icon",attrs:{"aria-label":_vm.$t('generic.search')},on:{"click":function($event){_vm.searchModalOpen = !_vm.searchModalOpen}}},[_c('Icon',{staticClass:"resized-icon",attrs:{"name":"search"}})],1),_vm._v(" "),(_vm.template !== 'empty')?_c('CartWidgetNew',{attrs:{"closeDelay":12000}}):_vm._e()],1)]),_vm._v(" "),(_vm.template === 'default')?_c('div',{staticClass:"tr-navbar__alert",attrs:{"id":"navbar-alert"}},[_c('EmailConfirm',{on:{"update":_vm.updateNavbarHeight}})],1):_vm._e()]),_vm._v(" "),_c('SearchModal',{model:{value:(_vm.searchModalOpen),callback:function ($$v) {_vm.searchModalOpen=$$v},expression:"searchModalOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }