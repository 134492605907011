





























import { Component, Mixins } from 'vue-property-decorator'
import TreeDiaryName from '../post_types/TreeDiaryName.vue'

@Component({
  name: 'TreeDiaryNameModal',
})
export default class TreeDiaryNameModal extends Mixins(TreeDiaryName) {}
