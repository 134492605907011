angular.module('ngApp')
.controller('iframeNumbersController', ['$scope', '$state', '$rootScope', 'Tree', '$filter', '$timeout', function ($scope, $state, $rootScope, Tree, $filter, $timeout) {
  $scope.asset = $filter('asset')

  $scope.iframeInit = function (variables, baseUrl) {
    $rootScope.baseUrlAssets = baseUrl
    $rootScope.iframeOption = variables
    // console.log(variables)
  }

}])
