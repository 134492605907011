<template>
  <div class="flex">
    <LinkList :title="data.plant.label" :items="data.plant.items" />
    <LinkList :title="data.gift.label" :items="data.gift.items" />
    <LinkList :title="data.business.label" :items="data.plant.items" />
    <div class="banner">
      <div class="container">
        <h5 class="title">{{ $t('nav.plant.banner.title') }}</h5>
        <a :href="'/landing/treedom-airc'" class="airc">
          <div class="text">
            <h5 class="cardTitle">Treedom x AIRC</h5>
            <h5 class="description">Pianta un albero e coltiva la ricerca oncologica</h5>
          </div>
          <img class="backgroundImage" :src="require('@f/images/navbar/airc-back.png')"  alt="blog"/>
          <img class="backgroundTree" :src="require('@f/images/navbar/airc-tree.png')"  alt="blog"/>
        </a>
        <a class="link" :href="'/landing/treedom-airc'">{{ $t('common.lear-more') }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from '@vue/composition-api'
import {getStateService} from "@f/services/Angular";

export default defineComponent({
   name: 'PlantMenu',
   components: { LinkList: () => import('./LinkList.vue') },
   data() {
     const state = getStateService()
     return {
       data: {
         plant: {
           label: 'nav.plant.title',
           items: [
             {
               link: state.href('species'),
               label: 'nav.plant.plant.title',
             },
             {
               link: state.href('subscriptions'),
               label: 'nav.plant.subscription.title',
             },
             {
               link: state.href('create-forest'),
               label: 'nav.plant.forest.title',
             }
           ]
         },
         gift: {
           label: 'nav.plant.giftAtree.title',
           items: [
             {
               link: state.href('gift'),
               label: 'nav.plant.giftAtree.gift',
             },
             {
               link: state.href('how-to-gift'),
               label: 'nav.plant.giftAtree.howToGift',
             }
           ]
         },
         business: {
           label: 'nav.business.title',
           items: [
             {
               link: state.href('species'),
               label: 'nav.business.plant.title',
             },
             {
               link: state.href('organization-subscriptions'),
               label: 'nav.business.subscription.title',
             },
             {
               link: state.href('business.landing', { slug: 'coffee-dona-lucero' }),
               label: 'nav.plant.business.coffeDonaLucero',
             }
           ]
         },
     },
   }
   }
})

</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: stretch;
  width: min(100vw, 1364px);
  justify-content: center;
  & > * {
    flex: 1;
    padding: 21px;
    position: relative;
    &:not(:last-child):before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left:0;
      border-right: 1px solid var(--Neutral-dark, #D1D7E0);
      pointer-events: none;
    }
  }
  .banner {

    .container {
      padding: var(--spacing-5);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: var(--spacing-3);
      height: 100%;
      box-sizing: border-box;
      flex: 0 1 auto;
      background: var(--color-neutral-main);
      max-width: 400px;
      .title {
        padding: var(--spacing-5) var(--spacing-5) 0 0;
        text-transform: uppercase;
        font-weight: 700;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        line-height: 20px; /* 142.857% */
        letter-spacing: 1px;
      }

    }

    .link {
      text-decoration: none;
      position: relative;
      color: #30A853;
      width: fit-content;
      &:before {
        content: '>';
        color: inherit;
        position: absolute;
        right: 0;
      }
      * {
        padding: 0 !important;
      }
    }

    .airc {
      border-radius: 10px;
      height: 217px;
      flex-shrink: 0;
      min-width: max(297px, 100%);
      width: 100%;
      min-height: 217px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;

      .cardTitle {
        color: #006892;
        margin: 0;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Desktop/Button */
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        margin: 0;
      }

      .description {
        color: var(--Font-100, #182D3A);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Desktop/Heading5 */
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 120% */
        margin: 0;
      }
      .text {
        padding-left: var(--spacing-4);
        max-width: 53%;
      }

      .backgroundImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: -1;
        inset: 0;
      }
      .backgroundTree {
        position: absolute;
        max-height: 82%;
        width: auto;
        right: -35px;
      }
    }

  }
}
</style>
