export function isObject(item: unknown): item is Object {
  return typeof item === "object"
}

export function isArray(item: unknown): item is Array<any> {
  return item instanceof Array
}

export function isArrayOf<T = any | unknown>(item: unknown): item is Array<T> {
  return isArray(item) && (!item.length || isDataObject<T>(item[0]))
}



export function isFunction(item: unknown): item is Function {
  return typeof item === "function"
}

export function isDataObject<T>(item: unknown): item is Record<string, T> {
  return typeof item === "object" && !!item && !!Object.keys(item).every(key => typeof key === "string")
}
