























































import { Component, Mixins } from 'vue-property-decorator'
import TreeDiaryItem from '../mixins/TreeDiaryItem'

@Component({
  name: 'TreeDiaryMessage',
})
export default class TreeDiaryMessage extends Mixins(TreeDiaryItem) {
  // Computed
  get text(): string {
    if (!this.isReadable)
      return this.translate('pageTree.diary.message.notReadable.text')
    const prefix = this.translate('pageTree.diary.message.text', {
      user: this.post.message.user.firstName,
      userSlug: this.post.message.user.slug,
      // translation with ng-click fix
      message: '',
      link: this.parseState(
        this.post.message.user.userType === 'Private' ? 'user' : 'organization',
        { slug: this.post.message.user.slug }
      ),
    })
    const messagesUrl = this.parseState('user.trees.item.messages', {
      slug: this.post.message.user.slug,
      item: this.tree.treeId,
    })
    return `${prefix}<a href="${messagesUrl}">${this.post.message.message}</a>`
  }

  get isReadable(): boolean {
    return (
      !this.post.message.isPrivate ||
      [
        // You wrote the message
        this.post.message.user.slug,
        // You own the tree
        this.tree.user.slug,
      ].includes(this.$rootScope.user)
    )
  }

  get visibilityDisclaimerNeeded(): boolean {
    return this.isReadable && this.post.message.isPrivate
  }

  get messageVisibilityText(): string {
    if (this.tree.initialUser.id === this.tree.user.id)
      return this.translate('pageTree.diary.message.readableOne.footnote')
    const otherUser =
      this.$rootScope.user === this.tree.user.slug
        ? this.tree.initialUser
        : this.tree.user
    return this.translate('pageTree.diary.message.readableTwo.footnote', {
      user: otherUser.firstName,
      userSlug: otherUser.slug,
      link: this.parseState(
        otherUser.userType === 'Private' ? 'user' : 'organization',
        { slug: otherUser.slug }
      ),
    })
  }
}
