angular.module('ngApp')
.controller('streamController', ['$rootScope', '$scope', '$timeout', '$interval', 'User', 'Utility', 'Modal', function ($rootScope, $scope, $timeout, $interval, User, Utility, Modal) {
  $rootScope.streamList = [];
  $scope.newStreams = [];

  $scope.getStream = function () {
    User.stream($rootScope.user, 0, 20, 'community')
    .then(function(response) {
      $rootScope.streamList = response.streams.map(function (stream, key) {
        stream['streamType'] = 'streamNew.' + stream.action + '_' + stream.type;
        return stream
      })
    })
  };

  $scope.pushStream = function (stream) {
    // Add object into stream array
    stream.new = true;
    $scope.newStreams.push(stream);

    $rootScope.streamList.reverse();
    $rootScope.streamList.push(stream);
    $rootScope.streamList.reverse()
  };

  $scope.animateMe = function (streamId) {
    var elemId = 'streamBox_' + streamId;
    var delayStartAnimation = 500;
    var delayCloseAnimation = 2000;

    $timeout(function () {
      var streamElem = document.getElementById(elemId);
      streamElem.style.right = '285px';

      $timeout(function () {
        streamElem.classList.add('closeStreamNotify');

        $timeout(function () {
          animateStreamIcon();
          removeStreamNotify(streamId)
        }, 500)
      }, delayCloseAnimation)
    }, delayStartAnimation)
  };

  function removeStreamNotify (streamId) {
    $scope.newStreams = $scope.newStreams.filter(function (newStream) {
      return streamId !== newStream.id
    })
  }

  function animateStreamIcon () {
    var streamIconElem = document.getElementById('streamIcon');
    streamIconElem.classList.add('shake');

    $timeout(function () {
      streamIconElem.classList.remove('shake')
    }, 300)
  }

  // Genera un nuovo stream.
  var testStream = function () {
    return {
      new: true,
      id: new Date().getTime(),
      target: 4485,
      userTitle: new Date().getTime(),
      userSlug: 'carmelo-catalfamo',
      avatar: 362534,
      action: 'UserRelation',
      type: 'create',
      toUserTitle: 'Sara Augugliaro',
      toUserSlug: 'sara-augugliaro',
      created: new Date().getTime(),
      streamType: 'streamNew.UserRelation_create'
    }
  }

  // Push automatico di stream casuali.
  // if (!$rootScope.testStreamInterval) {
  //   $rootScope.testStreamInterval = $interval(function () {
  //     $scope.pushStream(testStream())
  //   }, 7000)
  // }
}])
