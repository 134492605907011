import isUndefined from 'lodash/isUndefined'
const { getWidgetPosition, isCursorOn } = require("./widgetUtils.js");

angular.module('ngApp')
  /**
  * Widget Tree.
  * NOTE: Specificare altezza e larghezza del widget che deve essere fissa!
  *
  * @param {Object} attrs
  * @param {Object} attrs.widgetKey - Chiave per salvare l'oggetto in una cache temporanea (preferibilmente ID o SLUG)
  * @param {Object} attrs.widgetTreeId - ID dell'albero.
  */
  .directive('widgettree', ['$rootScope', '$compile', '$document', '$interval', 'Utility', 'Tree', '$state', function ($rootScope, $compile, $document, $interval, Utility, Tree, $state) {
    var _tempCache = {}
    var mouseOverElement = false
    var renderInterval

    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {
        if ($rootScope.isMobile) return
        var _activeWidget = false
        var widget = { width: 240, height: 393 }

        // Cerca il widget nella cache
        function findInCache(widgetKey) {
          if (!widgetKey || isUndefined(_tempCache[widgetKey])) return false
          return _tempCache[widgetKey]
        }

        // Rimuove il widget.
        function removeWidget(widget) {
          window.removeEventListener('mousemove', mouseMoveListener)
          widget.remove()

          // Controllo per sicurezza se esistono altri widget e li cancello.
          var otherWidgets = $document.find('.w_' + _activeWidget.key)
          if (otherWidgets.length) {
            otherWidgets.each(function (index) {
              otherWidgets[index].remove()
            })
          }

          _activeWidget = false
        }

        // Listener movimento mouse.
        function mouseMoveListener(e) {
          var widget = $document.find('#' + _activeWidget.key)

          if (!isCursorOn(element, e) && !isCursorOn(widget, e)) removeWidget(widget)

          $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            var widget = $document.find('#' + _activeWidget.key)
            removeWidget(widget)
          })
        }

        // Funzione per renderizzare il widet con i dati richiesti.
        function renderWidget(widgetKey) {
          if (!mouseOverElement) return

          // Se si tratta di un iframe, non renderizzo il widget.
          if ($rootScope.isIframe) return

          widgetKey = widgetKey || attrs.widgetKey
          _activeWidget = _tempCache[widgetKey]
          _activeWidget.key = widgetKey

          // Modifico l'altezza del widget nel caso in cui l'utente che ha piantato
          // l'albero è lo stesso che lo custodisce.
          if (_activeWidget.initialUserSlug === _activeWidget.userSlug) {
            widget.height = 332
          }

          // Creo il template del widget.
          var position = getWidgetPosition(element, widget)
          var template = '../../bundles/treedomnewfrontend/html-templates/widget/tree.html'
          var style = 'top: ' + position.top + 'px; ' + 'left: ' + position.left + 'px;'
          var arrowUp = position.position === 'bottom' ? '<div class="arrow-up" style="left: ' + position.offsetLeftArrow + 'px"></div>' : ''
          var arrowDown = position.position === 'top' ? '<div class="arrow-down" style="left: ' + position.offsetLeftArrow + 'px"></div>' : ''
          var html = '<div id="' + _activeWidget.key + '" style="' + style + '" class="widgetContainer w_' + _activeWidget.key + '"><div class="widgetContent">' + arrowUp + '<div ng-include="\'' + template + '\'"></div>' + arrowDown + '</div></div>'

          // Attacco allo $scope l'utente attivo.
          $scope.widgetTree = _activeWidget
          // Vai alla pagina di un utente.
          $scope.widgetGoToUser = function (type, slug) {
            $state.go('user', { slug: slug })
          }

          // Appendo il widget in fondo al body.
          var body = $document.find('body').eq(0)
          body.append($compile(angular.element(html))($scope))
          if (!$scope.$$phase) $scope.$apply()

          // Creo l'ascoltatore per il movimento del mouse.
          window.addEventListener('mousemove', mouseMoveListener)
        }

        // Funzione per renderizzare il widget con un intervallo di tempo.
        function renderWidgetWithInterval(widgetKey, milliseconds) {
          if (renderInterval) clearInterval(renderInterval)

          renderInterval = setInterval(function () {
            renderWidget(widgetKey)
            clearInterval(renderInterval)
          }, milliseconds)
        }

        // Mouseenter per creare il widget.
        element.on('mouseenter', function () {
          mouseOverElement = true
          var widgetKey = attrs.widgetKey
          var widgetTreeId = attrs.widgetTreeId

          // Se il widget esiste, non lo ricreo.
          var oldWidget = document.getElementById(widgetKey)
          if (oldWidget) return

          // Lo cerco nella cache.
          if (findInCache(widgetKey)) {
            renderWidgetWithInterval(widgetKey, 700)
            return
          }

          // Caso in cui il widget non è disponibile nella cache
          if (!findInCache(widgetKey)) {
            Tree.getTreeWidgetData(widgetKey)
              .then(function (_treedata) {
                _tempCache[widgetKey] = _treedata
                renderWidgetWithInterval(widgetKey, 700)
              })
            /*Utility.getWidgetData('tree', widgetTreeId, 0)
            .then(function(response) {
              
              response = response.data || response
              _tempCache[widgetKey] = response
              renderWidgetWithInterval(widgetKey, 700)
            })*/
          }

          _activeWidget = false
        })

        element.on('mouseleave', function () {
          mouseOverElement = false
          clearInterval(renderInterval)
        })
      }
    }
  }])
