



























import GameAuthentication from "@f/components/Game/Pages/GameAuthentication/GameAuthentication.vue";
import LanguageSelector from "@f/components/Navbar/partials/LanguageSelector.vue";
import Logo from "@f/images/logo-dynamic.svg";
import { onLoggedStatusChange } from "@f/services/Auth";
import { computed, defineComponent } from "@vue/composition-api";
import GameCompleted from "../components/Game/Pages/GameCompleted/GameCompleted.vue";
import useUserInfo from "../composables/useUserInfo";

export default defineComponent({
  name: "GameCompletePage",
  components: {
    GameAuthentication,
    Logo,
    LanguageSelector,
    GameCompleted
  },
  props: {
    treecode: String,
    rewardUuid: String,
  },
  setup() {

    const { userInfo } = useUserInfo()
    const authenticated = computed(() => !!userInfo.value)
    onLoggedStatusChange(() => (authenticated), false);

    return {
      authenticated
    };
  },
});
