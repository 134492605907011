
































































































import {load} from 'recaptcha-v3'
import {defineComponent, PropType, ref, watch} from '@vue/composition-api'

import {apiClient} from '@f/apollo'
import {getRootScope} from "@f/services/Angular";
import {maxWidth, useMediaQuery} from "@f/utils/breakpoints";

import type {Forest} from "@f/@types";
import {FOLLOW_THE_FOREST} from "@f/graphql/mutations";

import Modal from '@f/components/Modal.vue'
import Alert from '@f/components/Alert.vue'
import BaseInput from "@f/components/Form/BaseInput.vue";
import Treecon from "@f/components/Treecons/Treecon.vue";
import SlideDownTransition from '@f/transitions/SlideDown'
import BaseCheckbox from "@f/components/Form/BaseCheckbox.vue";
import StatefulButton from "@f/components/StatefullButton.vue";
import {validateFollowTheForest} from "@f/validation/followTheForest";
import {ErrorBag} from "@f/validation/errors";


const purgeErrorMessage = (error: string | undefined) => error?.replace(/ *\[[^\]]*]/, '') ?? ''

const getInitialFollowTheForestData = (forest: Partial<Forest>) => ({
  email: "",
  acceptForestCommunications: false,
  rejectMarketingCommunications: false,
  captchaResponse: '',
  locale: getRootScope()?.ln,
  eventId: forest?.id
})

const getInitialFollowTheForestState = () => ({
  success: false,
  loading: false,
  errorMessage: "",
  errors: new ErrorBag()
})

// @ts-ignore
const RECAPTCHA_SITE_KEY = window.recaptcha?.siteKey ?? ''
const FOLLOW_THE_FOREST_RECAPTCHA = 'followTheForest'

export default defineComponent({
  name: "FollowTheForest",
  components: {Treecon, BaseCheckbox, BaseInput, StatefulButton, Modal, Alert, SlideDownTransition},
  props: {
    forest: {
      type: Object as PropType<Partial<Forest>>,
      required: true
    }
  },
  setup(props) {
    const apollo = apiClient
    const isMobile = useMediaQuery(maxWidth('md'))

    const followTheForestModal = ref(false)

    const followTheForestData = ref(getInitialFollowTheForestData(props.forest))

    const followTheForestState = ref(getInitialFollowTheForestState())

    const resetInitialState = () => {
      followTheForestData.value = getInitialFollowTheForestData(props.forest)

      followTheForestState.value = getInitialFollowTheForestState()
    }

    const onFollowTheForestClick = () => {
      followTheForestModal.value = true
    }

    const validate = () => {
      const result = validateFollowTheForest(followTheForestData.value)

      if (result) return true

      const errors = validateFollowTheForest.errors
      followTheForestState.value.errors = new ErrorBag(errors)

      return false
    }

    const onFollowTheForestSubmit = async () => {
      try {
        const validationResult = validate()

        if (!validationResult) return

        followTheForestState.value.loading = true

        const {data, errors} = await apollo.mutate<{ followTheForest: { result: boolean } }>({
          mutation: FOLLOW_THE_FOREST,
          variables: followTheForestData.value
        })

        followTheForestState.value.success = !!data?.followTheForest.result
        followTheForestState.value.errorMessage = errors?.[0].message ?? ''

      } catch (ex) {
        followTheForestState.value.errorMessage = ex.message
      } finally {
        followTheForestState.value.loading = false
      }
    }

    const validateReCaptcha = async () => {
      const recaptcha = await load(RECAPTCHA_SITE_KEY, {autoHideBadge: true})
      const token = await recaptcha.execute(FOLLOW_THE_FOREST_RECAPTCHA)

      followTheForestData.value.captchaResponse = token
    }

    watch([followTheForestModal], async () => {
      resetInitialState()

      if (followTheForestModal.value && !followTheForestData.value.captchaResponse) {
        await validateReCaptcha()
      }
    })

    return {
      isMobile,
      followTheForestData,
      followTheForestState,
      followTheForestModal,
      purgeErrorMessage,
      onFollowTheForestClick,
      onFollowTheForestSubmit
    }
  }
})

