import {PuzzlePiece} from '.'

const options = {itemsPerRow: 3, baseWidth: 97, roundedMargin: 16} as const

const px = (value: number) => `${value}px`

const place = (index: number) => {
    const row = Math.ceil((index + 1) / options.itemsPerRow) - 1
    const column = index % options.itemsPerRow

    return {row, column}
}

const position = (index: number, {border}: PuzzlePiece) => {
    const {column, row} = place(index)

    const left = px(
        column * options.baseWidth - (border?.left ? options.roundedMargin : 0)
    )
    const top = px(
        row * options.baseWidth - (border?.top ? options.roundedMargin : 0)
    )

    return {left, top}
}

const dimension = (piece: PuzzlePiece) => {
    const {baseWidth, roundedMargin} = options
    const {left, bottom, right, top} = piece.border ?? {}

    const width = px(
        baseWidth + (left ? roundedMargin : 0) + (right ? roundedMargin : 0)
    )
    const height = px(
        baseWidth + (bottom ? roundedMargin : 0) + (top ? roundedMargin : 0)
    )

    return {width, height}
}

export const styled = (piece: PuzzlePiece, index: number) => {
    const {top, left} = position(index, piece)

    const {width, height} = dimension(piece)

    const style = {top, left, width, height}

    const id = index

    return {piece, style, id} as const
}

export const finalDimensions = (puzzle: PuzzlePiece[]) => {
    const rows = place(puzzle.length).row // I get number of rows with the last index of the puzzle

    const totalWidth = px(options.itemsPerRow * options.baseWidth)

    const totalHeight = px(rows * options.baseWidth)

    return {totalWidth, totalHeight}
}
