import isNumber from 'lodash/isNumber'
import head from 'lodash/head'
import calendar from '@f/_legacy/js-lib/calendar'

angular.module('ngApp')
.controller('genderAndBirthController', ['$rootScope', '$scope', 'Utility', 'User', function ($rootScope, $scope, Utility, User) {
  var $ = window.$

  $scope.userInfo = {}

  $scope.yearToSelect = calendar.years
  $scope.monthToSelect = calendar.months
  $scope.monthsName = calendar.monthsName
  $scope.days = calendar.days($scope.userInfo.month, $scope.userInfo.year)

  /**
   * Funziona che controlla se un campo è vuoto o non valido.
   * NOTE: Per le animazioni uso i nomi degli input.
   * @param {string} type - One of ['login', 'signup'].
   */
  var checkEmptyField = function () {
    var fields = ['SignupGender', 'SignupMonth', 'SignupYear', 'SignupDay']

    var isValid = fields.reduce((result, name) => {
      var element = document.getElementsByName(name)
      var el = element[0]
      var value = el.value

      // Se il campo è radio
      if (name === 'SignupGender') {
        if (!$scope.userInfo.gender) {
          el.classList
            ? el.classList.add('input-error-animated')
            : $(element).addClass('input-error-animated')

          return result * 0
        }
        return result * 1
      }

      // Se il campo contiene un testo
      if (value) {
        // Se è di tipo mail
        if (name.toLowerCase().includes('email')) {
          if (!value.includes('@')) {
            el.classList
              ? el.classList.add('input-error-animated')
              : $(element).addClass('input-error-animated')

            return result * 0
          }
        }

        return result * 1
      // Se il campo è vuoto
      } else {
        el.classList
          ? el.classList.add('input-error-animated')
          : $(element).addClass('input-error-animated')
        return result * 0
      }
    }, true)

    return !!isValid
  }

  /**
   * Parse form field birth in a valid php date string.
   */
  var parseDateOfBirth = function () {
    if (isNumber($scope.userInfo.day) && isNumber($scope.userInfo.month) && isNumber($scope.userInfo.year)) {
      var day = String($scope.userInfo.day).length < 2
        ? '0' + $scope.userInfo.day
        : '' + $scope.userInfo.day

      var month = String($scope.userInfo.month + 1).length < 2
        ? '0' + (Number($scope.userInfo.month) + 1)
        : '' + (Number($scope.userInfo.month) + 1)

      var year = String($scope.userInfo.year)

      return year + '-' + month + '-' + day
    }

    return null
  }

  /**
   * Aggiorna l'array $scope.days al cambio di mese e/o anno.
   * @param {number} month - From 0 to 11.
   * @param {number} year - Format YYYY.
   * @return {array} - Array of numbers [1, 2, 3...]
   */
  $scope.updateDays = function (month, year) {
    $scope.days = calendar.days(month, year)

    // Se il mese o l'anno selezionato non include il giorno (che ho già scelto)
    // setto il giorno di nascita al primo del mese
    if ($scope.userInfo.day) {
      if (!$scope.days.includes($scope.userInfo.day * 1)) {
        $scope.userInfo.day = head($scope.days)
      }
    }
  }

  /**
   * Resetta i campi solo se contengono errori
   * @param {string} name - Attribute 'name' of HTML node
   */
  $scope.resetFieldError = function (name) {
    var element = document.getElementsByName(name)
    var el = element[0]

    if (el.classList) {
      if (el.classList.contains('input-error-animated')) {
        el.classList.remove('input-error-animated')
      }
    } else {
      $(element).removeClass('input-error-animated')
    }
  }

  $scope.submitForm = function () {
    var isValid = checkEmptyField()
    if (!isValid) return

    // Utility.disableButton()

    $scope.oldUserData.date_of_birth = parseDateOfBirth()
    $scope.oldUserData.gender = $scope.userInfo.gender

    User.userEdit($scope.oldUserData)
    .then(function () {
      Utility.enableButton()
      $rootScope.closeTreedomModal()
    })
    .catch(function () {
      Utility.enableButton()
    })
  }

  User.userEditInfo()
  .then(function (data) {
    $scope.oldUserData = data

    $scope.userInfo.gender = $scope.oldUserData.gender || null
    // Trasformo la data in stringa
    if ($scope.oldUserData.date_of_birth) {
      var d = new Date($scope.oldUserData.date_of_birth)

      $scope.userInfo = {
        day: d.getDate(),
        month: d.getMonth(),
        year: d.getFullYear()
      }

      if (!$scope.$$phase) $scope.$apply()
    }
  })
}])
