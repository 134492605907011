
























































































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import { useMachine } from '@xstate/vue'
import { StateValue } from 'xstate'
import { fsm } from '@f/fsm/flows/OpenForestRedeem'
import { commonFlowOptions } from '@f/fsm/flows/common'
import UserMixin from '@f/mixins/UserMixin'
import SlideDownTransition from '@f/transitions/SlideDown'
import DotLoader from '@f/components/DotLoader.vue'
import StatefullButton from '@f/components/StatefullButton.vue'
import ProductSimpleCard from '@f/components/ProductSimpleCard.vue'
import TreeSimpleCard from '@f/components/TreeSimpleCard.vue'
import BaseSlider from '@f/components/BaseSlider.vue'
import Icon from '@f/components/Icon.vue'
import LanguageSelector from '@f/components/Navbar/partials/LanguageSelector.vue'
import { TrProduct, TrTree } from '@f/@types/graphql'
import { Event as Forest } from '@f/@types'
import { PRODUCT_LIST, TREE_CARD } from '@f/graphql/queries'
import { fetchForest } from '@f/services/Forest'
import { getRootScope, getStateService } from '@f/services/Angular'

type Props = {
  slug: string
}

type RawData = ReturnType<typeof setup>
type Data = ReturnType<typeof data>

type ComputedGetter<T> = (ctx?: any) => T;
type ComputedSetter<T> = (v: T) => void;
interface WritableComputedOptions<T> {
    get: ComputedGetter<T>;
    set: ComputedSetter<T>;
}

const mixins = [UserMixin]

const components = {
  SlideDownTransition,
  DotLoader,
  StatefullButton,
  ProductSimpleCard,
  TreeSimpleCard,
  BaseSlider,
  Icon,
  LanguageSelector,
  Gift: () => import( /* webpackChunkName: "gift-colors.svg" */ '@f/images/gift/gift-colors.svg'),
  GiftTree: () => import( /* webpackChunkName: "gift-tree-pin.svg" */ '@f/images/gift/gift-tree-pin.svg'),
  Tree: () => import( /* webpackChunkName: "tree-pin.svg" */ '@f/images/tree-pin.svg'),
}

const props = {
  slug: { type: String }
}

function data() {
  return {
    products: [] as TrProduct[],
    tree: null as (TrTree | null),
  }
}

function setup(props: Props) {
  const { state, send, service } = useMachine(fsm, commonFlowOptions)
  const lastSelectedItem = ref<number|null>(null)

  const loading = computed(() => {
    return (['checking', 'authenticating', 'redeeming'] as StateValue[]).includes(state.value.value)
  })

  const step = computed(() => state.value.value)

  const error = computed(() => state.value.context.error || '')

  const forest = ref<Forest | null>(null)

  const errorStatusMap = {
    event_not_open: {
      title: 'redeemcoupon.done.closed.title',
      text: 'redeemcoupon.done.closed.text',
    },
    no_more_trees: {
      title: 'redeemcoupon.done.notrees.title',
      text: 'redeemcoupon.done.notrees.text',
    },
    user_already_redeem: {
      title: 'redeemcoupon.done.alreadyredeem.title',
      text: 'redeemcoupon.done.alreadyredeem.text',
    },
  }

  const errorMessage = computed(() => {
    return error.value in errorStatusMap
      ? errorStatusMap[error.value as keyof typeof errorStatusMap]
      : { title: '', text: error.value }
  })

  const productsId = computed(() => state.value.context.items?.map(i => i.product_id) || [])

  const redeemedTreeId = computed(() => {
    const { redeemed, items, picked } = state.value.context
    return redeemed && items?.filter(t => t.product_id === picked)?.[0].tree_id
  })

  const picked = computed({
    get: () => state.value.context.picked || null,
    set: (productId: number | null): void => {
      lastSelectedItem.value = productId
      send('PICK', { data: productId })
    }
  })

  const userUrl = computed(() => {
    const { userType, user: slug } = getRootScope() || {}
    const stateService = getStateService()
    const state = userType === 'Business' ? 'organization' : 'user'
    return {
      state,
      slug,
      href: stateService && stateService.href(state, { slug }) || ''
    }
  })

  const forestUrl = computed(() => {
    const { user, slug: eventslug } = forest.value || {}
    const state = user?.type === 'Business'
      ? 'organization.event'
      : 'user.event'
    const slug = user?.slug
    const stateService = getStateService()
    return {
      state,
      slug,
      href: stateService && stateService.href(state, { slug, eventslug }) || ''
    }
  })

  function pick({ id }: TrProduct) {
    picked.value = id
  }

  function isPicked({ id }: TrProduct): boolean {
    return id === picked.value
  }

  function goToTree({ treeId }: TrTree) {
    const stateService = getStateService()
    const { state, slug } = userUrl.value
    return stateService && stateService.go(state+'.trees.item', { slug, item: treeId }) || ''
  }

  onMounted(() => {
    if (props.slug) {
      send && send('SET_SLUG', { data: props.slug })
      fetchForest(props.slug).then(data => forest.value = data)
    }
  })

  return {
    state,
    send,
    service,
    loading,
    forest,
    step,
    error,
    productsId,
    picked,
    isPicked,
    redeemedTreeId,
    pick,
    lastSelectedItem,
    errorMessage,
    goToTree,
    userUrl,
    forestUrl,
  }
}

export default defineComponent<Props, RawData, Data, Record<string, ComputedGetter<any> | WritableComputedOptions<any>>>({
  name: 'OpenForestRedeemFlow',
  components,
  mixins,
  props,
  data,
  computed: {
    sliderActiveIndex() {
      return this.lastSelectedItem && Math.max(0, this.products.findIndex(({ id }) => id === this.lastSelectedItem)) || 0
    }
  },
  apollo: {
    products: {
      query: PRODUCT_LIST,
      variables() { return { ids: this.productsId } },
      skip() { return !this.productsId.length },
    },
    tree: {
      query: TREE_CARD,
      variables() { return { id: this.redeemedTreeId } },
      skip() { return !this.redeemedTreeId },
    },
  },
  setup,
})
