




































import { defineComponent } from '@vue/composition-api'
import { EventUsersPage, ForestUser, OrganizationBalancer } from '@f/@types'
import ForestUsersBox from '@f/components/Forest/ForestUsersBox.vue'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import { fetchBusinessUsers, fetchUserInfo } from '@f/services/User'
import Treecon from '@f/components/Treecons/Treecon.vue'
import useFormat from '@f/composables/useFormat'

export default defineComponent({
  name: 'OrganizationUsersPage',
  components: {
    ForestUsersBox,
    Treecon
  },
  setup() {
    const mobileQuery = maxWidth('md')
    const isMobile = useMediaQuery(mobileQuery)
    const { formatLocalNumbers } = useFormat();
    return {
      isMobile,
      formatLocalNumbers
    }
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data(): {
    users: ForestUser[]
    user: OrganizationBalancer | null
    usersCount: number
    page: number
    loading: boolean
  } {
    return {
      users: [],
      user: null,
      usersCount: 0,
      page: 0,
      loading: false
    }
  },
  computed: {
    fetchMoreEnabled(): boolean {
      return this.users.length < this.usersCount;
    }
  },
  mounted() {
    this.getUserData()
  },
  methods: {
    getUserData(): void {
      this.loading = true;
      fetchUserInfo(this.slug)
        .then((data) => {
          this.user = data as OrganizationBalancer
          this.fetchUsers();
        })
        .finally(() => {
          this.loading = false;
        })
    },
    fetchUsers():void {
      if(this.page > 0 && !this.fetchMoreEnabled) return;
      this.loading = true;
      fetchBusinessUsers(this.user!.info.id!, this.page, 18).then((data: EventUsersPage) => {
        this.users = [...this.users, ...data.users];
        this.usersCount = data.count;
        this.page++;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
})
