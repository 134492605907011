














import { defineComponent, PropType, computed } from '@vue/composition-api'
import { MediaRawReference, getImageAsset } from '@f/utils/media'
import { MediaFormat } from '@f/@types/media'

export default defineComponent({
  name: 'Avatar',
  props: {
    src: {
      type: [Number, String, Object] as PropType<MediaRawReference>,
      required: true
    },
    size: {
      // ntds-avatar size variant
      type: String,
      default: 'medium'
    },
    format: {
      // media format
      type: String as PropType<MediaFormat>,
      default: 'small'
    }
  },
  setup(props) {
    const avatar = computed(() => {
      return getImageAsset(String(props.src), props.format)
    })
    
    return {
      avatar
    }
  }
})
