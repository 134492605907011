angular.module('ngApp')
.controller('navController', ['$scope', '$document', 'Utility', 'User', 'Modal', '$rootScope', '$state', '$location', '$timeout', 'MenuMobile', function ($scope, $document, Utility, User, Modal, $rootScope, $state, $location, $timeout, MenuMobile) {
  var $ = window.$;
  $scope.loggedMenuIsOpen = false;
  $scope.navbarState = $state.current.name;

  // Open/close menu (user logged in)
  $scope.toggleLoggedMenu = function () {
    if (!$scope.loggedMenuIsOpen) {
      $rootScope.$broadcast('toggleUserMenuBroadcast', 'open');
      return
    }

    $rootScope.$broadcast('toggleUserMenuBroadcast', 'close')
  };

  /**
   * Broadcast to open/close menu (user logged in)
   * @param {string} type - Valori accettati: 'open' o 'close'
   */
  $rootScope.$on('toggleUserMenuBroadcast', function (event, type) {
    var loggedMenu = document.getElementById('navbarLoggedMenu');

    if (type === 'open') {
      loggedMenu.style.display = 'block';

      $timeout(function () {
        var navbar = $rootScope.screenWidth > 768
          ? document.getElementById('navbar')
          : document.getElementById('navbarMobile');

        var navbarHeight = navbar.offsetHeight;

        loggedMenu.style.top = navbarHeight + 'px';
        $scope.loggedMenuIsOpen = true;
        $rootScope.$broadcast('resetSearchBroadcast');

        if (!$rootScope.isMobile && $rootScope.screenWidth > 768) {
          window.addEventListener('click', onClickCloseNavbar, true)
        }

        if (!$scope.$$phase) $scope.$apply()
      }, 200);

      return
    }

    if (type === 'close' && loggedMenu) {
      var navbarLoggedMenuHeight = loggedMenu.offsetHeight;
      loggedMenu.style.top = '-' + navbarLoggedMenuHeight + 'px';
      $scope.loggedMenuIsOpen = false;

      $timeout(function () {
        loggedMenu.style.display = 'none';
        if (!$rootScope.isMobile && $rootScope.screenWidth > 768) {
          window.removeEventListener('click', onClickCloseNavbar, true)
        }
      }, 200);

      if (!$scope.$$phase) $scope.$apply();

    }
  });

  window.addEventListener('resize', resizeLoggedMenu);

  function isDescendant (parent, child) {
    var node = child.parentNode;

    while (node !== null) {
      if (node === parent) {
        return true
      }
      node = node.parentNode
    }
    return false
  }

  function onClickCloseNavbar (e) {
    var loggedMenu = document.getElementById('navbarLoggedMenu');
    var iconOpenLoggedMenu = document.getElementById('iconOpenLoggedMenu');

    if (!isDescendant(loggedMenu, e.target) && e.target.id !== iconOpenLoggedMenu.id) {
      $rootScope.$broadcast('toggleUserMenuBroadcast', 'close')
    }
  }

  /**
   * Resize logged menu
   */
  function resizeLoggedMenu () {
    var loggedMenu = document.getElementById('navbarLoggedMenu');
    if (!loggedMenu) return
    if ($scope.loggedMenuIsOpen) {
      var navbar = $rootScope.screenWidth > 768
        ? document.getElementById('navbar')
        : document.getElementById('navbarMobile');

      var navbarHeight = navbar.offsetHeight;

      loggedMenu.style.top = navbarHeight + 'px';
      return
    }

    if (!$scope.loggedMenuIsOpen && loggedMenu) {
      var navbarLoggedMenuHeight = loggedMenu.offsetHeight;
      loggedMenu.style.top = '-' + navbarLoggedMenuHeight + 'px'
    }
  }

  /**
   * Apre la modale di login.
   */
  $scope.openModalLogin = function () {
    Modal.open({templateUrl: 'login.html', easyClose: false, fitcontent: true})
  };

  /**
   * Watcher che aggiorna lo stato attuale in cui mi trovo.
   */
  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    $scope.navbarState = toState.name;
    if ($scope.loggedMenuIsOpen) $rootScope.$broadcast('toggleUserMenuBroadcast', 'close');
  });

  /**
   * Apertura della modale con stream generale.
   */
  $scope.openStreamModal = function () {
    Modal.open({templateUrl: 'stream/community.html'})
  };
  var websiteScrollElement = function (element, fromState, fromParams, toState, toParams) {
    $document.scrollToElement(element, 85)
  };


  var websiteScrollTop = function (element, fromState, fromParams, toState, toParams) {
    if (fromState.name === 'species' ||
      fromState.name === 'species.tree' ||
      (fromState.name === 'project' && toState.name === 'project.tree' && fromState.name === 'project.tree' && toState.name === 'project')
    ) return;

    if (fromState.name === 'user.badge' || toState.name === 'user.badge') {
      return
    }

    // Se navigo tra le schede dell'albero
    var treeStateRegexFrom= /user\.trees\.item(\..*)?$/gm;
    var treeStateRegexTo= /user\.trees\.item(\..*)?$/gm;
    if(treeStateRegexFrom.test(fromState.name) && treeStateRegexTo.test(toState.name) && fromParams.item === toParams.item ){
      return;
    }

    // Se navigo tra le pagine di customer care non scrollare in cima
    if (fromState.name.includes('customercare') && toState.name.includes('customercare')) {
      return
    }
    $document.scrollToElement(element)
  };

  // Cart counter animation
  const getAnimatedCartElement = () => {
    const el = angular.element('#navbar #cartCounter')
    return (el.length) ? el : null
  }

  const getActualCount = () => {
    const countText = angular.element('#navbar #cartCounter span').html()
    return (countText) ? Number(countText) : 0
  }


  // Search
  $scope.openMobileMenuModal = function (_menu) {
    MenuMobile.openMobileMenuModal(_menu)
    $timeout(function () {
      $scope.$apply()
    })
  }

  $scope.menuIsOpen = function (_menu) {
    return MenuMobile.menuIsOpen(_menu)
  }


  // Vecchio controller - TODO: Verificare il codice sottostante.

  var routesMenu = [
    'user.stream.mine',
    'user.stream.community',
    'user.badge'
  ];

  var routesTreePage = [
    'user.trees.item.diary',
    'user.trees.item.message',
    'user.trees.item.specie',
    'user.trees.item.co2',
    'user.trees.item.emission',
    'user.trees.item.info',
    'user.trees.item.gift',
    'user.event.trees.item.diary',
    'user.event.trees.item.message',
    'user.event.trees.item.info',
    'user.event.trees.item.specie',
    'user.event.trees.item.co2',
    'user.event.trees.item.gift',
    'user.nursery',
    'user.event.nursery',
    'user.event.nursery.specie',
    'user.nursery.specie',
    'iframe.trees.item.diary',
    'iframe.trees.item.info',
    'iframe.trees.item.message',
    'iframe.trees.item.specie',
    'iframe.trees.item.emission',
    'iframe.trees.item.gift',
    'iframe.event.trees.item.diary',
    'iframe.event.trees.item.info',
    'iframe.event.trees.item.message',
    'iframe.event.trees.item.specie',
    'iframe.event.trees.item.emission',
    'iframe.event.trees.item.gift',
    'iframe.nursery',
    'iframe.event.nursery',
    'iframe.event.nursery.specie',
    'iframe.nursery.specie'
  ];

  var speciePage = ['species.tree', 'project.tree'];

  var blogPage = ['blog', 'blog.category', 'blog.author'];

  var businessPages = [
    'business.contacts',
    'business.about',
    'business.partner',
    'business.clients',
    'business.portfolio.client',
    'business.portfolio',
    'business'
  ];

  $rootScope.isBusiness = businessPages.indexOf($state.current.name) >= 0;

  $rootScope.routesMenu = routesMenu;

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    $rootScope.absoluteUrl = $location.$$absUrl;
    // Modifico titolo della pagina.
    !$scope.neverChanged ? Utility.titlePage(toState.seo) : $scope.neverChanged = false;

    // Controllo se mi trovo su Treedom o Treedom Business.
    $rootScope.isBusiness = businessPages.indexOf(toState.name) >= 0;

    if (blogPage.indexOf(fromState.name) >= 0) {
      $rootScope.TofromState = fromState.name;
      $rootScope.TofromParams = fromParams
    }

    // se apro il menu di destra e non provengo dallo stesso
    if (routesMenu.indexOf(toState.name) >= 0 && routesMenu.indexOf(fromState.name) < 0) {
      // mi salvo lo stato di provenienza in modo da rimadarmici quando lo chiuderò
      // nel caso avessi lo slider aperto
      if ($('#tree-slide').hasClass('tree-slide-open')) {
        $('#tree-slide').removeClass('tree-slide-open')
      } else {
        // questo nel caso che i parametru non fossero definiti ... lo rimando alla pagina dell'utente ... vedi se apro direttamente quella pagina dall'esterno
        if (fromState.name) {
          $rootScope.TofromState = fromState.name;
          $rootScope.TofromParams = fromParams
        } else {
          $rootScope.TofromState = 'user';
          $rootScope.TofromParams = fromParams
        }
      }
    }

    // se apro lo sliderdell'albero e non provengo dallo stesso
    if (routesTreePage.indexOf(toState.name) >= 0 && routesTreePage.indexOf(fromState.name) < 0) {
      // mi salvo lo stato di provenienza in modo da rimadarmici quando lo chiuderò
      if ($('#left-menu').hasClass('left-menu-open')) {
        $('#left-menu').removeClass('left-menu-open')
      } else {
        if (fromState.name) {
          $rootScope.TofromState = fromState.name;
          $rootScope.TofromParams = fromParams
        } else {
          $rootScope.TofromState = 'user';
          $rootScope.TofromParams = fromParams
        }
      }
    }

    if (routesTreePage.indexOf(toState.name) >= 0) {
      if (!$('#tree-slide').hasClass('tree-slide-open')) {
        $('#tree-slide').addClass('tree-slide-open')
      }
    } else {
      if ($('#tree-slide').hasClass('tree-slide-open')) {
        $('#tree-slide').removeClass('tree-slide-open')
      }
    }

    if (routesTreePage.indexOf(toState.name) >= 0 || routesMenu.indexOf(toState.name) >= 0) {
      Utility.blockScroll()
    } else {
      Utility.allowScroll()
    }

    if (routesMenu.indexOf(toState.name) >= 0) {
      if (!$('#left-menu').hasClass('left-menu-open')) {
        $('#left-menu').addClass('left-menu-open');

        setTimeout(function () {
          $('#left-menu-buttons').addClass('right-menu-container-open')
        }, 70)
      }
    }

    if ((routesMenu.indexOf(toState.name) >= 0 && $rootScope.hardware === 'mobile') ||
      (routesTreePage.indexOf(toState.name) >= 0 && $rootScope.hardware === 'mobile') ||
      (speciePage.indexOf(toState.name) >= 0 && $rootScope.hardware === 'mobile')) {
      $('#footermobile').hide()
    } else {
      $('#footermobile').show()
    }
  });

  // Notifiche

  // Open modal with notifications
  $scope.openNotificationsModal = function () {
    Modal.open({templateUrl: 'modalNotifications.html'})
  };

  // Find user notifications
  var getUserNotifications = function (userId) {
    if (!userId) return;
    User.getNotifications(userId)
    .then(function (response) {
      // Remove notifications with status planted
      var filteredResponse = response.filter(function (r) { return r.title !== 'planted' });

      // Parse array of object
      var parsedResponse = filteredResponse.map(function (r) {
        r.notify = r.notify * 1;
        return r
      });

      $rootScope.notifications = {
        list: parsedResponse,
        toRead: parsedResponse.filter(function (r) { return !r.notify }).length
      }
    })
  };

  $timeout(function () {
    $scope.$watch(function () {
      return $rootScope.userdata && $rootScope.userdata.info
    }, function (info) {
      if (info && !$rootScope.notifications) {
        getUserNotifications(info.id)
      }
    })
  }, 2000);

  // Old, used on treedom for business mobile menu
  // funzione che gestisce la visualizzazione della parte di sinistra della  NAV mobile
  this.menuType = false;
  var menuMobileIsOpen = false;

  this.showMenuMobile = function (type) {
    if (type === 'back') {
      return (routesMenu.indexOf($state.current.name) >= 0 ||
         speciePage.indexOf($state.current.name) >= 0 ||
         routesTreePage.indexOf($state.current.name) >= 0 ||
         $state.current.name === 'business.portfolio.client' ||
         $state.current.name === 'blog.post'
       ) ? true : false
    } else {
      return (routesMenu.indexOf($state.current.name) >= 0 ||
         speciePage.indexOf($state.current.name) >= 0 ||
         routesTreePage.indexOf($state.current.name) >= 0 ||
         $state.current.name === 'business.portfolio.client' ||
         $state.current.name === 'blog.post'
       ) ? false : true
    }
  };

  var openMenuMobile = this.openMenuMobile = function () {
    $rootScope.slideMenuType = 'normal';
    $rootScope.loginType = 'login';

    if (menuMobileIsOpen) {
      menuMobileIsOpen = false;
      Utility.closeMenuEffect()
    } else {
      menuMobileIsOpen = true;
      Utility.openMenuEffect()
    }

    if (!$scope.$$phase) $scope.$apply()
  };

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    if ($rootScope.isBusiness && menuMobileIsOpen) {
      openMenuMobile()
      getActualCount()
    }
  })
}])
