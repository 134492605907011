


























































import {computed, defineComponent, PropType} from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'
import TreeWeatherClock from '@f/components/Tree/TreeWeatherClock.vue'
import useRoutes from '@f/composables/useRoutes'
import DotLoader from '@f/components/DotLoader.vue'
import {maxWidth, useMediaQuery} from '@f/utils/breakpoints'
import GiftInDelivery from '@f/components/Gift/GiftInDelivery.vue'
import {getVisibleImage} from '@f/services/Tree'

export default defineComponent({
  name: 'TreeRow',
  components: {
    TreeWeatherClock,
    Treecon,
    DotLoader,
    GiftInDelivery,
  },
  props: {
    tree: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    url: {
      type: String,
      required: false,
    },
    country: {
      type: Boolean,
      default: false,
    },
    specie: {
      type: Boolean,
      default: false,
    },
    weather: {
      type: Boolean,
      default: false,
    },
    gift: {
      type: Boolean,
      default: false,
    },
    gifted: {
      type: Boolean,
      default: false,
    },
    giftUpdate: {
      type: Boolean,
      default: false,
      required: false,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const {parseTreeUrl} = useRoutes()
    const mobileQuery = maxWidth('md')
    const isMobile = useMediaQuery(mobileQuery)

    const treeName = computed(() => {
      return props.tree.name || props.tree.limited?.limitedName || props.tree.specie.name || ''
    })

    const treeImage = computed(() => getVisibleImage(props.tree as any)?.medium)

    const treeImageClass = computed(() => {
      return {
        'tree-row__image--photo': props.tree.status > 3,
        'tree-row__image--icon': props.tree.status <= 3,
      }
    })

    const treeUrl = computed(() => props.tree && parseTreeUrl(props.tree))

    const countryFlag = computed(() => {
      return props.tree.specie.country.flagImage || ''
    })

    const showSpecie = computed(() => {
      return !!(props.specie && props.tree.specie)
    })

    const showCountry = computed(() => {
      return !!(props.country && props.tree.specie?.country)
    })

    const showData = computed(() => {
      return props.weather || !!context.slots.actions
    })

    const showGift = computed(() => {
      return props.gift && !!props.tree.gift
    })

    const showGifted = computed(() => {
      return props.gifted
    })

    const treeUserName = computed(() => {
      return props.tree?.user?.firstName
    })

    return {
      countryFlag,
      showCountry,
      showData,
      showGift,
      showSpecie,
      treeImage,
      treeImageClass,
      treeName,
      treeUrl,
      isMobile,
      showGifted,
      treeUserName,
    }
  },
})
