angular.module('ngApp')
.controller('welcomeBackgroundParallaxController', ['$scope', function ($scope) {
  // NOTE: controller per il parallax mouseX e mouseY non usato
  window.onload = function () {
    var parallaxBox = document.getElementById('welcomeParallax')
    var c1left = document.getElementById('l1').offsetLeft
    var c1top = document.getElementById('l1').offsetTop
    var c2left = document.getElementById('l2').offsetLeft
    var c2top = document.getElementById('l2').offsetTop
    var c3left = document.getElementById('l3').offsetLeft
    var c3top = document.getElementById('l3').offsetTop
    var c4left = document.getElementById('l4').offsetLeft
    var c4top = document.getElementById('l4').offsetTop

    window.addEventListener('mousemove', function (event) {
      event = event || window.event
      var x = event.clientX - parallaxBox.offsetLeft
      var y = event.clientY - parallaxBox.offsetTop

      mouseParallax('l1', c1left, c1top, x, y, 5)
      mouseParallax('l2', c2left, c2top, x, y, 65)
      mouseParallax('l3', c3left, c3top, x, y, 15)
      mouseParallax('l4', c4left, c4top, x, y, 65)
    })
    // parallaxBox.onmousemove = function (event) {
    //
    // }

    var mouseParallax = function (id, left, top, mouseX, mouseY, speed) {
      var obj = document.getElementById(id)
      var parentObj = obj.parentNode
      var containerWidth = parseInt(parentObj.offsetWidth)
      var containerHeight = parseInt(parentObj.offsetHeight)
      obj.style.left = left - (((mouseX - (parseInt(obj.offsetWidth) / 2 + left)) / containerWidth) * speed) + 'px'
      obj.style.top = top - (((mouseY - (parseInt(obj.offsetHeight) / 2 + top)) / containerHeight) * speed) + 'px'
    }
  }
}])
