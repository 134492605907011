import moment from 'moment-timezone'
import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
// Controller per il meteo di un albero.
.controller('treeWeatherController', ['$scope', '$rootScope', '$interval', '$timeout', 'Modal', function ($scope, $rootScope, $interval, $timeout, Modal) {
  const SunCalc = require('suncalc')

  // lat, lng, timezone
  $scope.latitude = undefined
  $scope.longitude = undefined
  $scope.timezone = undefined
  // Movimento della luna prima della mezzanotte
  var savedDegMoon
  // Dati finti in caso di errore meteo.
  //var fakeWeatherResponse

  $scope.time = undefined
  $scope.startNightTime = undefined
  $scope.startSunriseTime = undefined
  $scope.startDayTime = undefined
  $scope.startSunsetTime = undefined
  $scope.wrendered = false

  $scope.rain = undefined // ['light', 'normal', 'strong']
  $scope.clouds = undefined // ['light', 'strong']
  $scope.moonPhase = undefined // ['newMoon', 'waxingCrescent', 'firstQuarter', 'waxingGibbous', 'fullMoon', 'waningGibbous', 'lastQuarter', 'waningCrescent']

  // Informazioni aggiuntive
  $scope.temperature = { fahrenheit: 0, celsius: 0 }
  // Controllers
  $scope.showControllers = false
  $scope.treeStatus = false

  /**
   * Resto in ascolto delle informazioni dell'albero
   */
  var initWeather = function () {

    $scope.latitude = ($rootScope.singleTree.lat != 0 ? $rootScope.singleTree.lat : $rootScope.singleTree.specie.originator.nurseryLat)
    $scope.longitude = ($rootScope.singleTree.lng != 0  ?$rootScope.singleTree.lng : $rootScope.singleTree.specie.originator.nurseryLng)
    //$rootScope.singleTree.location = getLocation('Tree', $rootScope.singleTree)
    //$rootScope.singleTree.specie.originator.location = getLocation('Originator', $rootScope.singleTree.specie.originator)

    // Creo dati falsi in caso di errore meteo.
    $scope.timezone = $rootScope.singleTree.timezone
    var fakeForecast = setFakeWeatherInfo($scope.timezone)
    //getWeatherStats($scope.latitude, $scope.longitude)
    elaborateWeather($rootScope.singleTree.forecast || fakeForecast)
  }

  $scope.$watch('singleTree', function (singleTree) {
    $scope.wrendered = false;
    if (singleTree) {
      // if (singleTree.id === $stateParams.item) {
      //   $scope.treeStatus = singleTree.status
      //   initWeather()
      // }

      $scope.treeStatus = singleTree.status
      initWeather()
    }
  }, true)

  /**
   * Find Tree/Originator location
   * @param {string} type - One of 'Tree', 'Originator'
   * @param {Object} obj - Tree or Originator object
   */
  /*var getLocation = function (type, obj) {
    if (!type || !obj) return
    if (obj.location) return obj.location

    var lat = type === 'Tree' ? obj.lat : obj.nurseryLat
    var lng = type === 'Tree' ? obj.lng : obj.nurseryLng

    Tree.getLocation(obj.id, type, lat, lng)
    .then(function(response) {

      response = response.data || response
      return response.location
    })
    .catch(function() {

      return ''
    })
  }*/

  /**
   * Funzione per creare finte informazioni sul meteo nel caso di errore dell'API
   *
   */
  var setFakeWeatherInfo = function (timezone) {
    var parsedTimezone = timezone || 'Europe/Rome'

    var momentTime = moment().tz(parsedTimezone)
    return {
      temperature: 77.33, // 25° Celsius,
      time: momentTime.unix(), // 10am
      icon: 'clear-day',
      timezone: parsedTimezone
    }
  }
  /**
   * Elabora i dati meteorologici.
   * @param {Object} weather - Oggetto di dati darksky (https://darksky.net/dev/docs)
   */
  var elaborateWeather = function (weather) {
    // Update temperature
    $scope.temperature.fahrenheit = parseInt(weather.temperature)
    $scope.temperature.celsius = parseInt(($scope.temperature.fahrenheit - 32) / 1.8)

    // Update time
    var weatherMillisecondsTimes = weather.time * 1000
    $scope.time = moment(weatherMillisecondsTimes).tz(weather.timezone)

    // Velocità vento in miglia per ora
    $scope.windSpeed = weather.windSpeed

    // Update rain/clouds
    switch (weather.icon) {
      case 'clear-day':
      case 'clear-night':
      case 'windy':
        $scope.rain = false
        $scope.clouds = false
        break
      case 'fog':
      case 'cloudy':
      case 'partly-cloudy-day':
      case 'partly-cloudy-night':
        $scope.rain = false
        $scope.clouds = 'light'
        break
      case 'rain':
      case 'snow':
      case 'sleet':
      case 'hail':
      case 'tornado':
      case 'thunderstorm':
        // Rain
        if (weather.precipIntensity < 0.1) {
          $scope.rain = 'light'
        } else if (weather.precipIntensity > 1) {
          $scope.rain = 'strong'
        } else {
          $scope.rain = 'normal'
        }
        $scope.clouds = 'strong'
        break
      default:
        $scope.rain = false
        $scope.clouds = false
    }

    // Decommentare per test
    // $scope.windSpeed = 25
    // $scope.clouds = 'strong'
    // $scope.rain = 'strong'

    // Alba/Tramonto
    var times = SunCalc.getTimes($scope.time, $scope.latitude, $scope.longitude)
    $scope.startSunriseTime = moment(times.sunrise).tz(weather.timezone)
    $scope.startSunsetTime = moment(times.sunset).tz(weather.timezone)
    $scope.startNightTime = moment($scope.startSunsetTime).add(1, 'hours')
    $scope.startDayTime = moment($scope.startSunriseTime).add(1, 'hours')

    // Fasi lunari
    var moonIllumination = SunCalc.getMoonIllumination($scope.time)
    var moonPhase = Number(moonIllumination.phase.toFixed(3))

    if (moonPhase >= 0 && moonPhase < 0.125) {
      $scope.moonPhase = 'newMoon'
    } else if (moonPhase >= 0.125 && moonPhase < 0.25) {
      $scope.moonPhase = 'waxingCrescent'
    } else if (moonPhase >= 0.25 && moonPhase < 0.375) {
      $scope.moonPhase = 'firstQuarter'
    } else if (moonPhase >= 0.375 && moonPhase < 0.5) {
      $scope.moonPhase = 'waxingGibbous'
    } else if (moonPhase >= 0.5 && moonPhase < 0.625) {
      $scope.moonPhase = 'fullMoon'
    } else if (moonPhase >= 0.625 && moonPhase < 0.75) {
      $scope.moonPhase = 'waningGibbous'
    } else if (moonPhase >= 0.75 && moonPhase < 0.875) {
      $scope.moonPhase = 'lastQuarter'
    } else {
      $scope.moonPhase = 'waningCrescent'
    }

    renderElements()
  }

  /**
   * Cambia le date mantenendo invariati gli orari di alba, giorno, tramonto e notte.
   */
  var changeVariablesDay = function () {
    if (!$scope.startSunsetTime || !$scope.startNightTime ||
    !$scope.startSunsetTime || !$scope.startDayTime) return

    var sunsetHour = $scope.startSunsetTime.hour()
    var sunsetMinutes = $scope.startSunsetTime.minutes()
    var sunsetSeconds = $scope.startSunsetTime.seconds()
    $scope.startSunsetTime = moment($scope.time).hour(sunsetHour).minutes(sunsetMinutes).seconds(sunsetSeconds)

    var nightHour = $scope.startNightTime.hour()
    var nightMinutes = $scope.startNightTime.minutes()
    var nightSeconds = $scope.startNightTime.seconds()
    $scope.startNightTime = moment($scope.time).hour(nightHour).minutes(nightMinutes).seconds(nightSeconds)

    var sunriseHour = $scope.startSunriseTime.hour()
    var sunriseMinutes = $scope.startSunriseTime.minutes()
    var sunriseSeconds = $scope.startSunriseTime.seconds()
    $scope.startSunriseTime = moment($scope.time).hour(sunriseHour).minutes(sunriseMinutes).seconds(sunriseSeconds)

    var dayHour = $scope.startDayTime.hour()
    var dayMinutes = $scope.startDayTime.minutes()
    var daySeconds = $scope.startDayTime.seconds()
    $scope.startDayTime = moment($scope.time).hour(dayHour).minutes(dayMinutes).seconds(daySeconds)
  }

  /**
   * Funzione per posizionamento degli elementi.
   */
  var renderElements = function () {
    changeVariablesDay()

    // Sky
    var sky = document.getElementById('sky')
    if (!sky) return
    var skyHeight = sky.offsetHeight
    var skySection = skyHeight / 4
    var startNightPx = skySection * 0
    var startSunrisePx = skySection * 1
    var startDayPx = skySection * 2
    var startSunsetPx = skySection * 3

    // Stars
    var stars = document.getElementById('stars')
    var opacity

    // Sun
    var sunCircle = document.getElementById('sun')
    sunCircle.style.left = 'calc(50% - ' + (sunCircle.clientWidth / 2) + 'px)'

    // Moon
    var moonCircle = document.getElementById('moon')
    var moonImage = document.getElementById('moonImage')
    moonCircle.style.left = 'calc(50% - ' + (moonCircle.clientWidth / 2) + 'px)'

    // Variables to elaborate weather stats.
    var translate
    var hoursDurationSection
    var pxForHour
    var pxForMinutes
    var minutesDifference

    switch (getMomentOfDay()) {
      case 'night':
        translate = startNightPx
        sky.style.transform = 'translate3d(0px, -' + translate + 'px, 0px)'
        stars.style.opacity = '1'
        sunCircle.style.transform = 'translate3d(0px, 0px, 0px)'
        break
      case 'sunrise':
        hoursDurationSection = Math.abs($scope.startSunriseTime.diff($scope.startDayTime, 'hours'))
        pxForHour = (skySection * 2) / hoursDurationSection
        pxForMinutes = pxForHour / 60

        minutesDifference = Math.abs($scope.startSunriseTime.diff($scope.time, 'minutes'))
        translate = (startSunrisePx - skySection) + (pxForMinutes * minutesDifference)

        sky.style.transform = 'translate3d(0px, -' + translate + 'px, 0px)'

        opacity = 1 - ((1 / (startSunrisePx + skySection)) * translate)
        stars.style.opacity = opacity + ''
        break
      case 'day':
        translate = startDayPx
        sky.style.transform = 'translate3d(0px, -' + translate + 'px, 0px)'
        stars.style.opacity = '0'
        moonCircle.style.transform = 'translate3d(0px, 0px, 0px)'
        break
      case 'sunset':
        hoursDurationSection = Math.abs($scope.startSunsetTime.diff($scope.startNightTime, 'hours'))
        pxForHour = (skySection * 2) / hoursDurationSection
        pxForMinutes = pxForHour / 60

        minutesDifference = Math.abs($scope.startSunsetTime.diff($scope.time, 'minutes'))
        translate = (startSunsetPx - skySection) + (pxForMinutes * minutesDifference)

        sky.style.transform = 'translate3d(0px, -' + translate + 'px, 0px)'

        opacity = (1 / ((startSunsetPx + skySection) - (startSunsetPx - skySection))) * translate
        stars.style.opacity = (opacity - 1) + ''
        break
    }

    // Sole
    var minutesOfSun = minutesOfDay($scope.startSunsetTime) - minutesOfDay($scope.startSunriseTime)
    var degSun = 185 / minutesOfSun
    if (minutesOfDay($scope.startSunriseTime) < minutesOfDay($scope.time)) {
      var differenceSun = minutesOfDay($scope.time) - minutesOfDay($scope.startSunriseTime)
      sunCircle.style.transform = 'rotate(' + (differenceSun * degSun) + 'deg)'
    }

    // Luna
    var minutesOfMoon = minutesOfDay($scope.startSunsetTime) - minutesOfDay($scope.startSunriseTime)
    var pxMoon = 230 / minutesOfMoon
    var differenceMoon
    if (minutesOfDay($scope.startNightTime) < minutesOfDay($scope.time) || minutesOfDay($scope.startSunsetTime) > minutesOfDay($scope.time)) {
      if (minutesOfDay($scope.startNightTime) < minutesOfDay($scope.time)) {
        differenceMoon = minutesOfDay($scope.time) - minutesOfDay($scope.startNightTime)
        savedDegMoon = differenceMoon
      }

      if (minutesOfDay($scope.startSunsetTime) > minutesOfDay($scope.time)) {
        savedDegMoon = isUndefined(savedDegMoon)
          ? minutesOfDay(moment($scope.startNightTime).hours(23).minutes(59))
          : savedDegMoon
        differenceMoon = savedDegMoon + minutesOfDay($scope.time)
      }

      var rotate = differenceMoon * pxMoon
      if (getMomentOfDay() === 'day') rotate = '0'
      moonCircle.style.transform = 'rotate(' + rotate + 'deg)'
      moonImage.style.transform = 'rotate(-' + (rotate + 180) + 'deg)'
    }
    $timeout(function(){
      $scope.wrendered = true
    },500)
  }

  /**
   * Trasforma una data per la comparazione
   * @param {Object} time - Data formato moment
   */
  var minutesOfDay = function (time) {
    return time.minutes() + time.hours() * 60
  }

  /**
   * Trova il momento del giorno
   * @return {string} - ['night', 'sunrise', 'day', 'sunset', 'error']
   */
  var getMomentOfDay = function () {
    if (minutesOfDay($scope.time) >= minutesOfDay($scope.startNightTime) || minutesOfDay($scope.time) < minutesOfDay($scope.startSunriseTime)) {
      return 'night'
    } else if (minutesOfDay($scope.time) >= minutesOfDay($scope.startSunriseTime) && minutesOfDay($scope.time) < minutesOfDay($scope.startDayTime)) {
      return 'sunrise'
    } else if (minutesOfDay($scope.time) >= minutesOfDay($scope.startDayTime) && minutesOfDay($scope.time) < minutesOfDay($scope.startSunsetTime)) {
      return 'day'
    } else if (minutesOfDay($scope.time) >= minutesOfDay($scope.startSunsetTime) && minutesOfDay($scope.time) < minutesOfDay($scope.startNightTime)) {
      return 'sunset'
    } else {
      return 'error'
    }
  }

  /**
   * Aggiunge un minuto all'orario attuale.
   */
  $scope.addOneMinute = function () {
    if (!$scope.time) return
    $scope.time.add(1, 'minutes')
    if (!$scope.$$phase) $scope.$apply()
    renderElements()
  }

  /**
   * Aggiunge un'ora all'orario attuale.
   */
  $scope.addOneHour = function () {
    $scope.time.add(1, 'hours')
    if (!$scope.$$phase) $scope.$apply()
    renderElements()
  }

  /**
   * Rimuove un minuto dall'orario attuale.
   */
  $scope.removeOneMinute = function () {
    $scope.time.subtract(1, 'minutes')
    if (!$scope.$$phase) $scope.$apply()
    renderElements()
  }

  /**
   * Rimuove un'ora all'orario attuale.
   */
  $scope.removeOneHour = function () {
    $scope.time.subtract(1, 'hours')
    if (!$scope.$$phase) $scope.$apply()
    renderElements()
  }

  /**
   * Trova le ore ed i minuti della data attuale.
   * @param {Object} time - Data formato moment.
   * @return {string} // '23:45'
   */
  $scope.getHourAndMinutes = function (time) {
    if (!time) return null
    return time.format('HH:mm')
  }

  /**
   * Trova il giorno della settimana.
   * @param {Object} time - Data formato moment.
   * @return {string} // 'Lunedì'
   */
  $scope.getDayOfWeek = function (time) {
    if (!time) return null

    var days = {
      it: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
      en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      de: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      es: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sabado']
    }

    return days[$rootScope.ln][time.day()]
  }

  /**
   * Trova il giorno del mese.
   * @param {Object} time - Data formato moment.
   * @return {number} // 24
   */
  $scope.getDayOfMonth = function (time) {
    if (!time) return null
    return time.format('D')
  }

  /**
   * Trova il mese.
   * @param {Object} time - Data formato moment.
   * @return {string} // 'Gen', 'Feb'
   */
  $scope.getMonth = function (time) {
    if (!time) return null
    var months = {
      it: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
      en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      fr: ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juill', 'août', 'sept', 'oct', 'nov', 'déc'],
      de: ['Jan', 'Febr', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'],
      es: ['en', 'feb', 'marzo', 'abr', 'mayo', 'jun', 'jul', 'ag', 'sept', 'oct', 'nov', 'dic']
    }

    return months[$rootScope.ln][time.format('M') - 1]
  }

  $scope.changeTreePhases = function (phase) {
    if (!$scope.showControllers) return
    if (interval) $interval.cancel(interval)
    $scope.treeStatus = Number(phase)
  }

  $scope.changeClouds = function (type) {
    if (!$scope.showControllers) return
    if (interval) $interval.cancel(interval)
    $scope.clouds = type
  }

  $scope.changeRain = function (type) {
    if (!$scope.showControllers) return
    if (interval) $interval.cancel(interval)
    $scope.rain = type
  }

  $scope.changeMoonPhases = function (type) {
    if (!$scope.showControllers) return
    if (interval) $interval.cancel(interval)
    $scope.moonPhase = type
  }

  /**
   * Listener, alla pressione della freccia a destra aumenta l'orario di 1 minuto.
   */
  window.addEventListener('keydown', function (e) {
    if (!$scope.showControllers) return

    if (e.keyCode === 39 || e.keyCode === 37) e.preventDefault()
    if (e.keyCode === 39) $scope.addOneMinute()
    if (e.keyCode === 37) $scope.removeOneMinute()
  })

  /**
   * Intervallo, ogni minuto, cambia la posizione degli elementi.
   */
  var interval
  if (!interval) interval = $interval($scope.addOneMinute, 60000)

  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, scope) {
    if (fromState.name !== toState.name && toState.name.indexOf('item') < 0) {
      $interval.cancel(interval)
    }

    // if (fromParams.item !== toParams.item){
    //     console.log("Sono cambiato")

    // }

    // Se lo stato è uguale
    if (fromState.name === toState.name) {
      // Ed esistono i parametri item, quindi la pagina è la stessa
      if (fromParams.item && toParams.item) {
        // Ma l'albero è diverso
        if (fromParams.item !== toParams.item) {
          // Cancello il timeout
          $interval.cancel(interval)
        }
      }
    }
  })

  window.addEventListener('resize', renderElements)

  // If pressing CTRL + S show weather settings
  document.onkeydown = function (e) {
    var isPressingCtrl = e.ctrlKey

    if (isPressingCtrl && e.keyCode === 83) {
      e.preventDefault()
      if (interval) $interval.cancel(interval)
      $scope.showControllers = !$scope.showControllers
      if (!$scope.$$phase) $scope.$apply()
    }
  }

  /**
   * Apre la modale del meteo.
   */
  $scope.openWeatherModal = function () {
    $rootScope.modalWeatherData = {
      temperature: $scope.temperature,
      dayOfWeek: $scope.getDayOfWeek($scope.time),
      dayOfMonth: $scope.getDayOfMonth($scope.time),
      month: $scope.getMonth($scope.time),
      time: $scope.getHourAndMinutes($scope.time),
      country: $rootScope.singleTree.location || $rootScope.singleTree.specie.originator.location,
      rain: $scope.rain,
      clouds: $scope.clouds
    }

    Modal.open({ templateUrl: 'modalWeather.html', closeOnContent: true })
  }

  $scope.$on('$destroy', function () {
    $interval.cancel(interval)
  })
}])
