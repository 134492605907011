import { render, staticRenderFns } from "./ForestUsersPage.vue?vue&type=template&id=d2f6d48a&scoped=true&"
import script from "./ForestUsersPage.vue?vue&type=script&lang=ts&"
export * from "./ForestUsersPage.vue?vue&type=script&lang=ts&"
import style0 from "./ForestUsersPage.vue?vue&type=style&index=0&id=d2f6d48a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2f6d48a",
  null
  
)

export default component.exports