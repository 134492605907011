






















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { path } from "d3";
import Buckler from "@f/components/Emissions/Buckler.vue";
import Treecon from "@f/components/Treecons/Treecon.vue";
import { scssColor } from '@f/utils/style'

interface Vector2 {
  x: number
  y: number
}

interface Size {
  width: number
  height: number
}

interface GradientStop {
  color: string
  position: number
}

interface GradientSegment {
  rotation: number
  stops: GradientStop[]
}

const DIRECTIONS = { N: -0.25, NE: -0.125, E: 0, SE: 0.125, S: 0.25, SW: 0.375, W: 0.5, NW: 0.626 }
type Orientation = keyof typeof DIRECTIONS

@Component({
  name: "TreeCo2Progress",
  components: {
    Buckler,
    Treecon,
  }
})
export default class TreeCo2Progress extends Vue {
  @Prop({ type: [Number, Array], required: true, default: 1 }) readonly max!: number | number[] | null;
  @Prop({ type: [Array, Number], required: true, default: 0 }) readonly value!: number | number[] | null;
  @Prop({ type: [String, Number] }) readonly assignedDate!: number | string | null;
  @Prop({ type: Number, default: 14 }) readonly barWidth!: number;
  @Prop({ type: Number, default: 300 }) readonly rayLength!: number;
  @Prop({ type: String,
    default: "N",
    validator: (val: string): boolean => Object.keys(DIRECTIONS).includes(val)
  }) readonly orientation!: Orientation;
  @Prop({ type: Number, default: 0.05 }) readonly circleGap!: number;
  @Prop({ type: Number, default: 0 }) readonly connectorsOffset!: number;
  @Prop({ type: Number, default: 4 }) readonly connectorsWidth!: number;
  @Prop({ type: Boolean, default: false }) readonly connectors!: boolean;

  debug: boolean = false
  debugValue: number = 0
  ngEmissionService: any = null
  viewbox: Size = { width: 600, height: 600 }
  // TODO: move rotation to method based on segments count
  gradientSegments: GradientSegment[] = [
    { rotation: 45, stops: [{ color: scssColor('azure-500'), position: 0 }, { color: scssColor('azure-700'), position: 100 }] },
    { rotation: 135, stops: [{ color: scssColor('azure-700'), position: 0 }, { color: scssColor('azure-500'), position: 100 }] },
    { rotation: 205, stops: [{ color: scssColor('azure-500'), position: 0 }, { color: scssColor('primary-700'), position: 100 }] },
    { rotation: 315, stops: [{ color: scssColor('primary-700'), position: 0 }, { color: scssColor('primary-500'), position: 100 }] },
  ]

  // Computed

  get maxValue(): number {
    return this.maxValidData(this.max)
  }

  get currentValue() {
    return this.debug ? this.debugValue : this.maxValidData(this.value)
  }

  get currentValueSegments() {
    const stepRatio = 1 / this.gradientSegments.length
    const segmentsCount = this.currentValue / (this.maxValue * stepRatio)
    return Array(Math.ceil(segmentsCount)).fill(undefined).map((_, index) => {
      const length = Math.min(1, Math.max(0, segmentsCount - index))
      const start = stepRatio * index
      const end = stepRatio * (index + length)
      return this.circularPath(start, end)
    })
  }

  get maxExample() {
    const { id, unit, qt } = this.getExample(this.maxValue, ['km', 'kg'])
    const subject = this.$t(`emissions.name.${id}`)
    const subjectString = `pageTree.co2.comparisonValue.${unit}`
    return {
      text: `${qt.toLocaleString(this.$rootScope.ln)}${unit} ${this.$t(subjectString, { subject })}`,
      image: `/bundles/treedomnewfrontend/images/calculator/${id}.png`
    }
  }

  get currentValueExample() {
    if (!this.currentValue) return { image: '', text: `<h4>${this.$t('pageTree.co2.comparison.empty')}</h4>` }
    const { id, unit, qt } = this.getExample(this.currentValue, ['km', 'kg'])
    const subject = this.$t(`emissions.name.${id}`)
    const subjectString = `pageTree.co2.comparisonValue.${unit}`
    return {
      text: `<h1 class="no-margin">${qt.toLocaleString(this.$rootScope.ln)}${unit}</h1><h4 class="no-margin">${this.$t(subjectString, {subject})}</h4>`,
      image: `/bundles/treedomnewfrontend/images/calculator/${id}.png`
    }
  }

  get year(): number | null {
    return this.assignedDate ? new Date(this.assignedDate).getFullYear() : null
  }

  get ray() {
    return Math.min(this.viewbox.width / 2, this.viewbox.height / 2, this.rayLength)
  }

  get circleRotation(): number {
    return DIRECTIONS[this.orientation]
  }

  // TODO: delete after segments refactor
  get currentConnectorPath() {
    const currentRatio = this.currentValue / this.maxValue
    const target = this.progressPosition(currentRatio)
    const source = { x: this.viewbox.width/2*(currentRatio >= 0.5 ? -1 : 1)*0.9, y: this.viewbox.height/2*0.9 }
    return this.elbowPath(source, target, currentRatio)
  }

  get bucklerStyle() {
    const paddingRatio = 0.14;
    const bucklerWidthRatio = (this.rayLength + this.barWidth) * 2 / this.viewbox.width - paddingRatio * 2;
    const bucklerHeightRatio = (this.rayLength + this.barWidth) * 2 / this.viewbox.height - paddingRatio * 2;
    return {
      position: 'absolute',
      width: `${bucklerWidthRatio * 100}%`,
      height: `${bucklerHeightRatio * 100}%`,
      top: `${(1 - bucklerHeightRatio) * 50}%`,
      left: `${(1 - bucklerWidthRatio) * 50}%`,
    }
  }

  // Events
  created() {
    const side = (this.rayLength + this.barWidth) * 2.2
    this.viewbox = { width: side, height: side }
    this.ngEmissionService = this.ngService('Emission')
  }

  maxValidData(data: number | number[] | null): number {
    return !data ? 0 : Number((data instanceof Array && !!data.length) ? data[data.length - 1] : data)
  }

  getExample(co2: number, units: string[]): any {
    if (this.ngEmissionService) return this.ngEmissionService.emissions.randomExample(co2, units)
  }

  progressPosition(value: number, offset: number = 0): Vector2 {
    return {
      x: this.ray * (1 + this.connectorsOffset + offset) * Math.cos(this.clampedAngle(value)),
      y: this.ray * (1 + this.connectorsOffset + offset) * Math.sin(this.clampedAngle(value))
    }
  }

  clampedAngle(value: number): number {
    return Math.PI * (2 * ((1 - this.circleGap) * value + (this.circleRotation + this.circleGap / 2)))
  }

  clampPosition({ x, y }: Vector2, padding: number) {
    const { width, height } = this.viewbox
    return {
      x: Math.min(width * 0.5 - padding, Math.max(width * -0.5 + padding, x)),
      y: Math.min(height * 0.5 - padding, Math.max(height * -0.5 + padding, y))
    }
  }

  circularPath(startPoint: number, endPoint: number, offsetRatio: number = 1) {
    if (endPoint - startPoint <= 0) return ''
    const circularPath = path()
    circularPath.arc(0, 0, this.ray * offsetRatio, this.clampedAngle(startPoint), this.clampedAngle(endPoint))
    return circularPath.toString()
  }

  connectorPath(xPosition: number, yPosition: number, strokePosition: number) {
    const target = this.progressPosition(strokePosition)
    const source = this.clampPosition({
      x: this.viewbox.width * (xPosition - 0.5),
      y: this.viewbox.height * (yPosition - 0.5)
    }, this.connectorsWidth)
    return this.elbowPath(source, target, 0)
  }

  // TODO: check elbowValue deletion after TypeScript refactoring
  elbowPath(source: Vector2, target: Vector2, elbowValue: number) {
    const path = [`M${source.x},${source.y}`]
    const elbow = Math.abs(source.x - target.x) > Math.abs(source.y - target.y)
      ? { x: target.x, y: source.y }
      : { x: source.x, y: target.y}
    path.push(`L${elbow.x},${elbow.y}`)
    path.push(`L${target.x},${target.y}`)
    return path.join() 
  }
}
