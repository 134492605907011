module.exports = {
  en: {
    'redeemcoupon.intro.tab': 'Welcome!',
    'redeemcoupon.intro.title': 'Your tree awaits you!',
    'redeemcoupon.intro.p1': 'Welcome to Treedom, the only website that allows you to plant trees online while directly involving local farmers around the world.',
    'redeemcoupon.intro.p2': "If you received a code, you're just a few clicks away from owning a tree in your name. You can find out where it's growing, what its features are, you can follow its diary online and much more.",
    'redeemcoupon.intro.p2_fromLink': "You're just a few clicks away from owning a tree in your name. You can find out where it's growing, what its features are, you can follow its diary online and much more.",
    'redeemcoupon.intro.p3': 'Start now!',
    'redeemcoupon.intro.button': 'Receive your tree',
    'redeemcoupon.access.tab': 'Log in',
    'redeemcoupon.access.title': "What's cool about Treedom is that your tree belongs to you and you only!",
    'redeemcoupon.access.p1': 'In order to receive a gifted tree, you must have a profile on Treedom.',
    'redeemcoupon.access.p2': "That's the only way we can give you the tree: once you start your profile, you'll receive updates about the tree, making it truly yours!",
    'redeemcoupon.access.p3': 'If you already own a profile on Treedom, log in with your credentials, otherwise register now and receive your tree.',
    'redeemcoupon.access.button': 'Log in or register',
    'redeemcoupon.insertCode.tab': 'Activate',
    'redeemcoupon.insertCode.title': 'Your tree awaits you!',
    'redeemcoupon.insertCode.p1': 'Activate your code here and get your tree now.',
    'redeemcoupon.insertCode.p2': "You only need the code now and you won't be asked to use it again. As soon as you activate the code, the tree will be yours and will be always visible on your profile.",
    'redeemcoupon.insertCode.p3': 'Activate the code!',
    'redeemcoupon.insertCode.placeholder': 'Insert the code',
    'redeemcoupon.insertCode.button': 'Continue',
    'redeemcoupon.redeem.button': 'Confirm',
    'redeemcoupon.redeem.giftpicker.button': 'Confirm',
    'redeemcoupon.redeem.giftpicker.p1': '',
    'redeemcoupon.redeem.giftpicker.p2': 'What tree do you want?',
    'redeemcoupon.redeem.giftpicker.p3': 'Check out all the available trees and confirm your choice',
    'redeemcoupon.redeem.giftpicker.title': function (user) { return user + ' is inviting you to pick the tree you prefer!' },
    'redeemcoupon.redeem.p1': 'What tree did they pick for you? Where will it grow? What\'s its meaning?',
    'redeemcoupon.redeem.p2': 'One last click and you\'ll find out.',
    'redeemcoupon.redeem.p3': 'Come and meet your tree!',
    'redeemcoupon.redeem.tab': 'Confirm',
    'redeemcoupon.redeem.title': function (user) { return user + ' gifted you a tree!' },
    'redeemcoupon.redeem.virgin.button': 'Confirm',
    'redeemcoupon.redeem.virgin.p1': 'What tree is it? Where will it grow? What\'s its meaning?',
    'redeemcoupon.redeem.virgin.p2': 'One last click and you\'ll find out.',
    'redeemcoupon.redeem.virgin.p3': 'Come and meet your tree!',
    'redeemcoupon.redeem.virgin.title': 'Check out your tree now!',
    'coupon.error.no_gift': 'Code incorrect or already used. Check your profile if this tree is already in your forest.',
    'coupon.error.no_rimbo': 'It never hurts to try…but the code that you see here is only an example. If you have been given a code try using it and you’ll have better luck!',
    'coupon.error.used.button': 'Visit the forest',
    'coupon.error.used.button_user': 'Come and see it',
    'coupon.error.used.p1': function (user) { return 'This tree is already owned by ' + user + '.' },
    'coupon.error.used.p1_user': 'This tree is already yours!',
    'coupon.error.used.title': 'Oops, this code has already been used!',
    'coupon.error.user_alone': 'This tree is already yours and can’t be gifted to someone else! The code functions only when inserted by a different user.',
    'menu.login': 'Log in',
    'menu.logout': 'Sign out',
    'login.title': 'Log in',
    'login.subtitle_question': '<a class="pointer" ng-click="loginType = \'sign-up\'; error=false">Sign up</a> for a new Treedom profile',
    'login.email': 'Email',
    'login.password': 'Password',
    'login.forgot_password': 'Forgot your password?',
    'login.button': 'Log in',
    'login.option_text': 'or',
    'login.button_fb': 'Log in with Facebook',
    'login.button_google': 'Log in with Google',
    'password_reset.title.username': 'Insert your email',
    'password_reset.subtitle.username': 'We will send instructions for resetting your password.',
    'password_reset.placeholder.email': 'Email',
    'password_reset.button.username': 'Send',
    'password_reset.success.mail_sent': 'Check your email. We sent instructions for resetting your password.',
    'password_reset.title.password': 'Choose a new password',
    'password_reset.placeholder.new_password': 'New password',
    'password_reset.placeholder.new_password_confirm': 'Confirm new password',
    'password_reset.button.password': 'Save',
    'password_reset.success.password_reset': 'Password successfully changed. You will now be redirected to your profile.',
    'edit.error.password.different': 'Password and confirmation do not match.',
    'signup.title': 'Sign up',
    'signup.subtitle_question': '<a class="pointer" ng-click="loginType = \'login\'; error=false">Log in</a> if you already have a profile',
    'signup.email': 'Email',
    'signup.name': 'Name',
    'signup.surname': 'Lastname',
    'signup.password': 'Password',
    'signup.confirm_password': 'Confirm your password',
    'signup.button': 'Sign Up',
    'signup.option_text': 'or',
    'signup.button_fb': 'Log in with Facebook',
    'signup.button_google': 'Log in with Google',
    'signup.privacy': 'By signing up you accept <a class="text-inverse" ui-sref="page.terms">the terms,</a> <a class="text-inverse" ui-sref="page.privacy">conditions,</a> and <a class="text-inverse" ui-sref="page.cookie">regulations for data collection and cookie use.</a>',
    'error.login.user': 'Invalid email.',
    'error.login.password': 'Invalid password.',
    'error.login.facebook': 'You are already connected through Facebook. Select "Log in with Facebook."',
    'error.login.google': 'You are already connected through Google+. Select "Log in with Google+."',
    'error.signup.other_user': 'Email taken. You could already be signed up or have incorrectly typed your address.',
    'error.signup.password_match': 'Password and confirmation do not match.',
    'error.password_recovery.email_recovery': 'Email not found. Are you sure that you signed up for treedom.net with this address?',
    'password_reset.error.user': 'Email not found. Are you sure that you signed up for treedom.net with this address?',
    'error.password_recovery.password_recovery_match': 'Password and confirmation do not match.',
    'password_reset.error.time': 'You already received an email within the last 24 hours with instructions for resetting your password. Check your email.',
    'error.generic': 'Oops! Something went wrong. Try again.',
    'signup.confirm_email': 'Confirm e-mail address',
    'error.signup.email_match': "The e-mail addresses don't match",
    'signup.gender.female': 'Female',
    'signup.gender.male': 'Male',
    'signup.birthdate.day': 'Day',
    'signup.birthdate.month': 'Month',
    'signup.birthdate.title': 'Date of birth',
    'signup.birthdate.year': 'Year',
    'modalAlertMe.button': 'Go back',
    'password_reset.button.cancel': 'Cancel',
    'signup.denyCommunications': 'I do not want to receive communications about promotions, news from the Treedom world and information dedicated only to the community.',
    'signup.implicitAcceptance': function([{ termsUrl, privacyUrl, cookieUrl }]) {
      return 'By proceeding with the registration I confirm that I have read and accept the <a href="'+termsUrl+'" target="_blank">Terms and Conditions</a>, <a href="'+privacyUrl+'" target="_blank">Privacy Policy</a> and <a href="'+cookieUrl+'" target="_blank">Use of Cookies</a>.'
    },
    'signup.title2019': 'Welcome to Treedom',
    'signup.subtitle': 'Complete your profile with the missing data',
    'giftRedeem.logout.button': 'Switch account',
  },
  fr: {
    'redeemcoupon.intro.tab': 'Bienvenue !',
    'redeemcoupon.intro.title': 'Votre arbre vous attend!',
    'redeemcoupon.intro.p1': 'Bienvenue sur Treedom, le seul site qui vous permet de planter des arbres en ligne en implquant directement les agriculteurs locaux à travers le monde.',
    'redeemcoupon.intro.p2': 'Si vous avez reçu un code, en quelques clics, vous aurez un arbre à votre nom. Vous pourrez ensuite découvrir son emplacement, ses caractéristiques, suivre son journal en ligne et bien plus encore.',
    'redeemcoupon.intro.p2_fromLink': 'Il suffit de quelques clics pour avoir un arbre à votre nom. Vous pourrez ensuite découvrir son emplacement, ses caractéristiques, suivre son journal en ligne et bien plus encore.',
    'redeemcoupon.intro.p3': 'Démarrez maintenant !',
    'redeemcoupon.intro.button': 'Recevez votre arbre',
    'redeemcoupon.access.tab': 'Connectez-vous',
    'redeemcoupon.access.title': "L'avantage de Treedom est que l'abre n'appartient qu'à vous!",
    'redeemcoupon.access.p1': 'Pour recevoir un arbre en cadeau, il est nécessaire posséder un profil Treedom.',
    'redeemcoupon.access.p2': "C'est uniquement de cette façon que nous pouvons vous assigner un arbre, vous tenir au courant des dernières mises à jour et en faire de cet arbre vraiment le vôtre.",
    'redeemcoupon.access.p3': 'Si vous avez déjà un profil sur Treedom,connectez-vous avec votre identifiant et mot de passe, ou inscrivez-vous dès maintenant et recevez votre arbre.',
    'redeemcoupon.access.button': 'Se connecter ou s’inscrire',
    'redeemcoupon.insertCode.tab': 'Activer',
    'redeemcoupon.insertCode.title': 'Votre arbre vous attend!',
    'redeemcoupon.insertCode.p1': 'Activez le code ici et recevez aussitôt votre arbre.',
    'redeemcoupon.insertCode.p2': "Une fois le code activé, lequel ne vous sera plus demandé par la suite, l'arbre sera à vous et sera toujours visible sur votre profil.",
    'redeemcoupon.insertCode.p3': 'Activez le code!',
    'redeemcoupon.insertCode.placeholder': 'Saisir le code',
    'redeemcoupon.insertCode.button': 'Continuer',
    'redeemcoupon.redeem.button': 'Confirmer',
    'redeemcoupon.redeem.giftpicker.button': 'Confirmer',
    'redeemcoupon.redeem.giftpicker.p1': '',
    'redeemcoupon.redeem.giftpicker.p2': 'Quel arbre voulez-vous ?',
    'redeemcoupon.redeem.giftpicker.p3': 'Regardez tous les arbres disponibles et confirmez votre choix.',
    'redeemcoupon.redeem.giftpicker.title': function (user) { return user + " vous invite à choisir l'arbre que vous préférez!" },
    'redeemcoupon.redeem.p1': 'Quel arbre Orange a choisi pour vous ? Dans quel pays poussera-t-il? Quelle est sa signification ?',
    'redeemcoupon.redeem.p2': 'Encore un clic et vous allez pouvoir découvrir cela.',
    'redeemcoupon.redeem.p3': 'Venez faire la connaissance de votre arbre !',
    'redeemcoupon.redeem.tab': 'Confirmer',
    'redeemcoupon.redeem.title': function (user) { return user + ' vous a offert un arbre !' },
    'redeemcoupon.redeem.virgin.button': 'Confirmer',
    'redeemcoupon.redeem.virgin.p1': "De quel arbre s'agit-il? Dans quel pays poussera-t-il? Quelle est sa signification?",
    'redeemcoupon.redeem.virgin.p2': 'Encore un clic et vous allez pouvoir découvrir cela.',
    'redeemcoupon.redeem.virgin.p3': 'Venez faire la connaissance de votre arbre !',
    'redeemcoupon.redeem.virgin.title': 'Découvrez votre arbre dès maintenant !',
    'coupon.error.no_gift': 'Code incorrect ou déjà utilisé. Vérifiez sur votre profil si cet arbre est déjà dans votre forêt.',
    'coupon.error.no_rimbo': 'Essayer ne coûte rien... Mais le code ci-contre n\'est qu\'un exemple ! Si vous avez reçu un code, utilisez-le pour tenter votre chance !',
    'coupon.error.used.button': 'Venez voir',
    'coupon.error.used.button_user': 'Venez le voir',
    'coupon.error.used.p1': function (user) { return 'Cet arbre appartient déjà à ' + user + '.' },
    'coupon.error.used.p1_user': 'Cet arbre vous appartient déjà !',
    'coupon.error.used.title': 'Oups, ce code a déjà été utilisé',
    'coupon.error.user_alone': 'Cet arbre est à vous, vous ne pouvez pas vous l\'offrir ! Le code ne fonctionne que s\'il est utilisé par un autre utilisateur que vous.',
    'menu.login': 'Connexion',
    'menu.logout': 'Quitter',
    'login.title': 'Connexion',
    'login.subtitle_question': 'Vous êtes nouveau sur Treedom ? <a class="pointer" ng-click="loginType = \'sign-up\'; error=false">Inscrivez-vous</a>',
    'login.email': 'Adresse électronique',
    'login.password': 'Mot de passe',
    'login.forgot_password': 'Mot de passe oublié ?',
    'login.button': 'Connexion',
    'login.option_text': 'ou',
    'login.button_fb': 'Connexion avec Facebook',
    'login.button_google': 'Connexion avec Google',
    'password_reset.title.username': 'Veuillez saisir votre adresse électronique',
    'password_reset.subtitle.username': 'Nous vous enverrons les instructions pour réinitialiser votre mot de passe.',
    'password_reset.placeholder.email': 'Adresse électronique',
    'password_reset.button.username': 'Envoyer',
    'password_reset.success.mail_sent': 'Consultez votre boîte de réception. Nous venons de vous envoyer les instructions pour réinitialiser votre mot de passe.',
    'password_reset.title.password': 'Veuillez saisir un nouveau mot de passe',
    'password_reset.placeholder.new_password': 'Nouveau mot de passe',
    'password_reset.placeholder.new_password_confirm': 'Confirmez votre nouveau mot de passe',
    'password_reset.button.password': 'Enregistrer',
    'password_reset.success.password_reset': 'Mot de passe modifié avec succès. Vous allez être redirigé vers votre profil.',
    'edit.error.password.different': 'Les mots de passe ne correspondent pas.',
    'signup.title': 'Inscrivez-vous',
    'signup.subtitle_question': 'Déjà inscrit ? <a class="pointer" ng-click="loginType = \'login\'; error=false">Connexion</a>',
    'signup.email': 'Adresse électronique',
    'signup.name': 'Prénom',
    'signup.surname': 'Nom',
    'signup.password': 'Mot de passe',
    'signup.confirm_password': 'Confirmez votre mot de passe',
    'signup.button': 'Inscrivez-vous',
    'signup.option_text': 'ou',
    'signup.button_fb': 'Inscrivez-vous avec Facebook',
    'signup.button_google': 'Inscrivez-vous avec Google',
    'signup.privacy': 'En vous inscrivant sur Treedom, vous acceptez <a class="text-inverse" ui-sref="page.terms">les Conditions d\'Utilisation des données</a> et <a class="text-inverse" ui-sref="page.cookie">l\'Utilisation des cookies.</a>',
    'error.login.user': 'Adresse électronique incorrecte.',
    'error.login.password': 'Mot de passe incorrect.',
    'error.login.facebook': 'Vous êtes déjà inscrit avec Facebook. Cliquez sur "Accédez avec Facebook".',
    'error.login.google': 'Vous êtes déjà inscrit avec Google+. Cliquez sur "Accédez avec Google+".',
    'error.signup.other_user': 'Cette adresse électronique existe déjà. Vous êtes peut-être déjà inscrit ou avez fait une faute de frappe.',
    'error.signup.password_match': 'Les mots de passe ne correspondent pas.',
    'error.password_recovery.email_recovery': "Cette adresse électronique n'existe pas. Vous êtes peut-être inscrit sur Treedom avec une autre adresse.",
    'password_reset.error.user': "Cette adresse électronique n'existe pas. Vous êtes peut-être inscrit sur Treedom avec une autre adresse.",
    'error.password_recovery.password_recovery_match': 'Les mots de passe ne correspondent pas.',
    'password_reset.error.time': 'Dans les dernières 24h vous avez reçu un e-mail avec les instructions pour réinitialiser votre mot de passe. Consultez votre boîte de réception.',
    'error.generic': 'Oups ! Une erreur inattendue est survenue. Veuillez essayer à nouveau.',
    'signup.confirm_email': "Confirmer l'adresse e-mail",
    'error.signup.email_match': 'Les adresses e-mail ne correspondent pas.',
    'signup.gender.female': 'Femme',
    'signup.gender.male': 'Homme',
    'signup.birthdate.day': 'Jour',
    'signup.birthdate.month': 'Mois',
    'signup.birthdate.title': 'Date de naissance',
    'signup.birthdate.year': 'Année',
    'modalAlertMe.button': 'Retour',
    'password_reset.button.cancel': 'Annuler',
    'signup.denyCommunications': 'Je ne souhaite pas recevoir de communications promotionnelles, les nouvelles du monde de Treedom et les informations relatives à sa communauté.',
    'signup.implicitAcceptance': function([{ termsUrl, privacyUrl, cookieUrl }]) {
      return 'En m\'inscrivant, je confirme que j\'ai lu et accepté les <a href="'+termsUrl+'" target="_blank">conditions générales</a>, la <a href="'+privacyUrl+'" target="_blank">politique de traitement des données</a> et les <a href="'+cookieUrl+'" target="_blank">informations sur l\'utilisation des cookies</a>.'
    },
    'signup.title2019': 'Bienvenue sur Treedom',
    'signup.subtitle': 'Complétez votre profil avec les informations manquantes',
    'giftRedeem.logout.button': 'Changer de compte',
  },
  nl: {
    'redeemcoupon.intro.tab': 'Welkom!',
    'redeemcoupon.intro.title': 'Uw boom wacht op u!',
    'redeemcoupon.intro.p1': 'Welkom bij Treedom, de enige site die online bomen plant in nauwe samenwerking met lokale landbouwers over de hele wereld.',
    'redeemcoupon.intro.p2': 'Indien u een code gekregen hebt kunt u in enkele muisklikken een boom op uw naam krijgen! Vervolgens kunt u online meer vernemen over zijn exacte plaats en zijn kenmerken en kunt u zijn logboek volgen.',
    'redeemcoupon.intro.p2_fromLink': 'Enkele muisklikken volstaan om een boom op uw naam te krijgen! Vervolgens kunt u online meer vernemen over zijn exacte plaats en zijn kenmerken en kunt u zijn logboek volgen.',
    'redeemcoupon.intro.p3': 'Start nu!',
    'redeemcoupon.intro.button': 'Zo krijgt u uw boom',
    'redeemcoupon.access.tab': 'Log in',
    'redeemcoupon.access.title': 'Wat zo cool is aan Treedom, is dat u uw eigen boom hebt.',
    'redeemcoupon.access.p1': 'Om een boom als geschenk te krijgen moet je een account openen bij Treedom.',
    'redeemcoupon.access.p2': 'Nadat u een account gecreëerd hebt, kunt u een boom aanvragen en zijn logboek volgen zodat u echt het gevoel hebt dat de boom van u is.',
    'redeemcoupon.access.p3': 'Indien u al een account bij Treedom hebt, kunt u inloggen met uw user-id en paswoord. Anders schrijft u zich nu in om uw boom te krijgen.',
    'redeemcoupon.access.button': 'Inloggen of inschrijven',
    'redeemcoupon.insertCode.tab': 'Activeren',
    'redeemcoupon.insertCode.title': 'Uw boom wacht op u!',
    'redeemcoupon.insertCode.p1': 'Activeer hier uw code om een boom aan te vragen.',
    'redeemcoupon.insertCode.p2': 'Na het activeren van uw code krijgt u een boom en kunt u hem om het even wanneer op uw profiel volgen. Achteraf zal u geen enkele code meer gevraagd worden.',
    'redeemcoupon.insertCode.p3': 'Activeer uw code.',
    'redeemcoupon.insertCode.placeholder': 'Geef de code in.',
    'redeemcoupon.insertCode.button': 'Verdergaan',
    'redeemcoupon.redeem.button': 'Bevestigen',
    'redeemcoupon.redeem.giftpicker.button': 'Bevestigen',
    'redeemcoupon.redeem.giftpicker.p1': '',
    'redeemcoupon.redeem.giftpicker.p2': 'Welke boom wilt u?',
    'redeemcoupon.redeem.giftpicker.p3': 'Bekijk de beschikbare bomen en bevestig uw keuze.',
    'redeemcoupon.redeem.giftpicker.title': function (user) { return user + ' nodigt u uit om uw favoriete boom te kiezen.' },
    'redeemcoupon.redeem.p1': 'Welke boom koos hij/zij voor u? In welk land zal hij groeien? Wat is zijn betekenis?',
    'redeemcoupon.redeem.p2': 'Ontdek alles in een klik!',
    'redeemcoupon.redeem.p3': 'Maak kennis met uw boom.',
    'redeemcoupon.redeem.tab': 'Bevestigen',
    'redeemcoupon.redeem.title': function (user) { return user + ' heeft u een boom geschonken!' },
    'redeemcoupon.redeem.virgin.button': 'Bevestigen',
    'redeemcoupon.redeem.virgin.p1': 'Welke boom is het? Waar zal hij groeien? Wat is zijn betekenis?',
    'redeemcoupon.redeem.virgin.p2': 'Ontdek alles in een klik!',
    'redeemcoupon.redeem.virgin.p3': 'Maak kennis met uw boom.',
    'redeemcoupon.redeem.virgin.title': 'Ontdek nu uw boom.',
    'coupon.error.no_gift': 'Code incorrect or already used. Check your profile if this tree is already in your forest.',
    'coupon.error.no_rimbo': 'It never hurts to try…but the code that you see here is only an example. If you have been given a code try using it and you’ll have better luck!',
    'coupon.error.used.button': 'Bekijk hem',
    'coupon.error.used.button_user': 'Bekijk hem',
    'coupon.error.used.p1': function (user) { return 'Deze boom behoort al toe aan ' + user + '.' },
    'coupon.error.used.p1_user': 'Deze boom is al van u.',
    'coupon.error.used.title': 'Oeps, deze code werd al gebruikt.',
    'coupon.error.user_alone': 'This tree is already yours and can’t be gifted to someone else! The code functions only when inserted by a different user.',
    'menu.login': 'Inloggen ',
    'menu.logout': 'Afsluiten',
    'login.title': 'Inloggen ',
    'login.subtitle_question': 'Bent u nieuw op Treedom? <a class="pointer" ng-click="loginType = \'sign-up\'; error=false">Schrijf u in</a>',
    'login.email': 'E-mailadres',
    'login.password': 'Paswoord',
    'login.forgot_password': 'Paswoord vergeten?',
    'login.button': 'Inloggen',
    'login.option_text': 'of',
    'login.button_fb': 'Inloggen via Facebook',
    'login.button_google': 'Inloggen met Google',
    'password_reset.title.username': 'Gelieve uw e-mailadres in te geven',
    'password_reset.subtitle.username': 'We sturen u een mail met instructies om uw paswoord opnieuw in te stellen.',
    'password_reset.placeholder.email': 'E-mailadres',
    'password_reset.button.username': 'Versturen',
    'password_reset.success.mail_sent': 'Raadpleeg uw inbox We hebben u een mail met instructies gestuurd om uw paswoord opnieuw in te stellen.',
    'password_reset.title.password': 'Gelieve een nieuw paswoord op te geven',
    'password_reset.placeholder.new_password': 'Nieuw paswoord',
    'password_reset.placeholder.new_password_confirm': 'Bevestig uw nieuw paswoord',
    'password_reset.button.password': 'Registreren',
    'password_reset.success.password_reset': 'Uw paswoord werd gewijzigd. U wordt opnieuw naar uw profiel gestuurd.',
    'edit.error.password.different': 'De paswoorden zijn verschillend.',
    'signup.title': 'Schrijf u in',
    'signup.subtitle_question': 'Al ingeschreven? <a class="pointer" ng-click="loginType = \'login\'; error=false">Inloggen</a>',
    'signup.email': 'E-mailadres',
    'signup.name': 'Voornaam',
    'signup.surname': 'Naam',
    'signup.password': 'Paswoord',
    'signup.confirm_password': 'Bevestig uw paswoord',
    'signup.button': 'Schrijf u in',
    'signup.option_text': 'of',
    'signup.button_fb': 'Schrijf u in via Facebook',
    'signup.button_google': 'Schrijf u in met Google',
    'signup.privacy': 'Door u in te schrijven op Treedom aanvaardt u <a class="text-inverse" ui-sref="page.terms">de Voorwaarden voor het Gebruik van Persoonlijke Gegevens</a> en <a class="text-inverse" ui-sref="page.cookie">het Gebruik van Cookies.</a>',
    'error.login.user': 'Verkeerd e-mailadres.',
    'error.login.password': 'Verkeerd paswoord.',
    'error.login.facebook': 'U bent al ingeschreven via Facebook. Klik op "Inloggen via Facebook".',
    'error.login.google': 'U bent al ingeschreven met Google+. Klik op "Inloggen met Google+".',
    'error.signup.other_user': 'Dit e-mailadres bestaat al. U bent misschien al ingeschreven of u hebt een schrijffout gemaakt.',
    'error.signup.password_match': 'De paswoorden zijn verschillend.',
    'error.password_recovery.email_recovery': 'Dit e-mailadres bestaat niet. U bent misschien met een ander adres ingeschreven op Treedom.',
    'password_reset.error.user': 'Dit e-mailadres bestaat niet. U bent misschien met een ander adres ingeschreven op Treedom.',
    'error.password_recovery.password_recovery_match': 'De paswoorden zijn verschillend.',
    'password_reset.error.time': 'De laatste 24 uur hebt u een e-mail gekregen met instructies om een nieuw paswoord in te stellen. Raadpleeg uw inbox',
    'error.generic': 'Oeps! Er is een fout opgetreden. Gelieve opnieuw te proberen.',
    'signup.confirm_email': 'Bevestig dit e-mailadres',
    'error.signup.email_match': 'De e-mailadressen komen niet overeen.',
    'signup.gender.female': 'Vrouw',
    'signup.gender.male': 'Man',
    'signup.birthdate.day': 'Dag',
    'signup.birthdate.month': 'Maand',
    'signup.birthdate.title': 'Geboortedatum',
    'signup.birthdate.year': 'Jaar',
    'modalAlertMe.button': 'Ga terug',
    'password_reset.button.cancel': 'Annuleren',
    'signup.denyCommunications': 'Ik wil geen mededelingen ontvangen over promoties, nieuws uit de Treedom-wereld en informatie die alleen aan de gemeenschap is gewijd.',
    'signup.implicitAcceptance': function([{ termsUrl, privacyUrl, cookieUrl }]) {
      return 'En m\'inscrivant, je confirme que j\'ai lu et accepté les <a href="'+termsUrl+'" target="_blank">conditions générales</a>, la <a href="'+privacyUrl+'" target="_blank">politique de traitement des données</a> et les <a href="'+cookieUrl+'" target="_blank">informations sur l\'utilisation des cookies</a>.'
    },
    'signup.title2019': 'Welkom in Treedom',
    'signup.subtitle': 'Vul uw profiel aan met de ontbrekende gegevens',
    'giftRedeem.logout.button': 'Schakel account',
  }
}
