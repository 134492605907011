var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Portal',[_c('div',_vm._b({class:_vm.inheritedClass,attrs:{"id":_vm.modalId},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.rapidClose.apply(null, arguments)}}},'div',_vm.getScopeAttrs(),false),[(_vm.open)?_c('Overlay',_vm._b({staticClass:"modal__dialog",class:{
        'modal__dialog--padded': !_vm.lightbox,
        'modal__dialog--unresponsive': !_vm.responsive,
        'modal__dialog--fullscreen': _vm.fullscreen,
        'modal__dialog--overlayclose': _vm.overlayClose
      },style:({
        maxHeight: _vm.maxHeight,
        maxWidth: _vm.maxWidth
      }),attrs:{"center":"","black":""},on:{"click":_vm.rapidClose}},'Overlay',_vm.layer,false),[(_vm.$slots.header)?_c('div',{staticClass:"modal__header"},[_vm._t("header"),_vm._v(" "),(!_vm.hideClose)?_c('button',{staticClass:"tr-button--icon modal__close",on:{"click":function($event){return _vm.emitToggle(false)}}},[_c('Icon',{attrs:{"name":"close"}})],1):_vm._e()],2):(!_vm.hideClose)?_c('button',{staticClass:"tr-button--icon modal__close",on:{"click":function($event){return _vm.emitToggle(false)}}},[_c('Icon',{attrs:{"name":"close"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal__content"},[_vm._t("default")],2),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"modal__footer"},[_vm._t("footer")],2):_vm._e()]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }