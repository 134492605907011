









import { Vue, Component, Prop } from 'vue-property-decorator'
import { TrTreeCodeGift } from '@f/@types/graphql'


@Component({
  name: 'TreeGiftStrip',
  components: {
    Gift: () => import( /* webpackChunkName: "gift-colors.svg" */ '@f/images/gift/gift-colors.svg'),
  }
})
export default class TreeGiftStrip extends Vue {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  @Prop({ type: [Object, String], required: true }) readonly gift!: TrTreeCodeGift | string
  @Prop({ type: String, required: true }) readonly treeId!: string

  /*--- DATA ----------*/
  
  /*--- COMPUTED ------*/
  get parsedGift() {
    return typeof this.gift === 'string' ? JSON.parse(this.gift) as TrTreeCodeGift : this.gift
  }
  
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  
  /*--- METHODS -------*/
  showRecap() {
    const ngModal = this.ngService('Modal')
    if (ngModal) {
      if (this.$isFeatureActive('giftflow')) {
        ngModal.open({
          templateUrl: 'v-modal-gift_recap.html',
          id: `treecode-redeem-${this.parsedGift.uuid}`,
          data: { gift: this.parsedGift, treeId: this.treeId },
        })
      } else {
        ngModal.open({
          templateUrl: 'v-modal-feature-off.html',
          id: 'giftflowOff',
          data: {
            title: this.$t('feature.giftflow.off.title'),
            text: this.$t('feature.giftflow.off.text'),
          }
        })
      }
    }
  }
  
}
