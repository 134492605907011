<template>
  <div class="impact-container">
    <div>
      <p v-html="$t(mainText)" />
    </div>
    <div class="impact-inner-section">
      <img
        :src="firstImage"
        :alt="firstSectionTitle"
      />
      <div class="text-container right">
        <h4 class="ntds-heading--4 with-icon">{{ $t(firstSectionTitle) }}</h4>
        <p>
          {{ $t(firstSectionText) }}
        </p>
      </div>
    </div>
    <div class="impact-inner-section">
      <div class="text-container left">
        <h4 class="ntds-heading--4 with-icon">{{ $t(secondSectionTitle) }}</h4>
        <p>
          {{ $t(secondSectionText) }}
        </p>
      </div>
      <img
        :src="secondImage"
        :alt="$t(secondSectionTitle)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImpactOverview',
  props: {
    mainText: {
      type: String,
      default: 'forest.impact.modal.text',
    },
    firstSectionTitle: {
      type: String,
      default: 'forest.benefits.modal.env.title',
    },
    firstSectionText: {
      type: String,
      default: 'forest.benefits.modal.env.text',
    },
    secondSectionTitle: {
      type: String,
      default: 'forest.benefits.modal.social.title',
    },
    secondSectionText: {
      type: String,
      default: 'forest.benefits.modal.social.text',
    },
    firstImage: {
        type: String,
        default: require('@f/images/forest/impact-01.jpg')
    },
    secondImage: {
        type: String,
        default: require('@f/images/forest/impact-02.jpg')
    }
  },
}
</script>

<style scoped lang="scss">
@import '@f/scss/design-system/_variables';
.ntds-heading--4 {
  font-weight: $ntds-weight-bold;
  margin-top: 0;
  font-size: 20px;
  color: $ntds-font-100;
  margin-bottom: 5px;
}
p {
  font-size: 16px;
}
.impact-container {
  padding-bottom: 20px;
  color: $ntds-font-300;

  .impact-inner-section {
    display: flex;
    margin-bottom: 20px;

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.right {
        padding-left: 20px;
      }

      &.left {
        padding-right: 20px;
      }
    }

    img {
      border-radius: 10px;
      width: 298px;
      height: 298px;
      object-fit: cover;
    }
  }
}
</style>