export enum SocialAction {
    click = 'click',
    like = 'like',
    share = 'share',
}
  
export enum SocialType {
    facebook = 'facebook',
    instagram = 'instagram',
    youtube = 'youtube',
    linkedin = 'linkedin',
    twitter = 'twitter',
    whatsapp = 'whatsapp',
    email = 'email',
}
 