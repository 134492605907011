
angular.module( "treedomSearch", [])
  .directive('treedomSearch', ['$compile', '$rootScope', '$timeout' ,'Search', '$state', '$filter',  function($compile,$rootScope,$timeout,Search, $state, $filter) {
    return {
      restrict: 'AE', // come puo venire definita es E:element <angular-tip>/  A:attribute <div angular-tip> / C:classe etc...
        replace: false, // se viene rimpiazzato con il template sotto
        link: function(scope, elem, attrs) {


						var body = document.body,
						    html = document.documentElement;

						var MaxHeight = Math.max( body.scrollHeight, body.offsetHeight,
					                       html.clientHeight, html.scrollHeight, html.offsetHeight );

         	 	var position = elem.parent().offset();
	          var options = {
	            height: attrs.height || MaxHeight,
	          }

							var top = position.top;
		          var left = position.left;
		          var width = elem.parent().outerWidth();
		          var height = elem.parent().outerHeight();

							if ($rootScope.hardware == 'mobile') {

								options.height = MaxHeight - position.top - elem.parent().outerHeight();
						  	resultDiv = angular.element('<div class="result-search" style="width:'+width+'px; min-height:'+options.height+'px; max-height:'+options.height+'px"></div>');

							}else {
								options.height = options.height - 300;
							  resultDiv = angular.element('<div class="result-search" style="width:'+width+'px; max-height:'+options.height+'px"></div>');

							}




							$(window).resize(function() {
								if (!attrs.height){


							if ($rootScope.hardware == 'mobile') {
										newHeight = Math.max( body.scrollHeight, body.offsetHeight,
						                       html.clientHeight, html.scrollHeight, html.offsetHeight )- position.top - elem.parent().outerHeight();

										 resultDiv.css('max-height', newHeight+'px');
										 resultDiv.css('min-height', newHeight+'px');

							} else {
									newHeight = Math.max( body.scrollHeight, body.offsetHeight,
							                       html.clientHeight, html.scrollHeight, html.offsetHeight )-300;
									resultDiv.css('max-height', newHeight+'px')
							}

								}
							});

              elem.bind('keydown', function() {

                inputChanged();

              });

         		 elem.val('');

            	var inputChangedPromise;
            	inputChanged = function(){

            		    if(inputChangedPromise){
            		        $timeout.cancel(inputChangedPromise);
            		    }
            		    inputChangedPromise = $timeout(doSomething,500);
            	}


              var doSomething = function () {
								charge =' <div class="charging more text-center">';
								charge +='<i class="fa fa-spinner fa-spin fa-2x"></i>';
								charge +='<p class="small">Loading...</p>';
								charge +='</div>';

								resultDiv.html(charge);

								elem.parent().after($compile(resultDiv)(scope));

                var varToSearch = elem.val();
                scope.isLoading = false;
                scope.friends = [];
                requestForFriends = null;
                // ---
                // PUBLIC METHODS.
                // ---
                // I abort the current request (if its running).
                scope.abortRequest = function() {
                    return( requestForFriends && requestForFriends.abort() );
                };

								if (varToSearch) {

                // I load the remote data for the view.


                    // Flag the data is currently being loaded.
                    scope.isLoading = true;
                    scope.friends = [];


                    ( requestForFriends = Search.getGeneralSearch(varToSearch) ).then(
                        function( newFriends ) {

													if (newFriends.length > 0) {
														resultDiv.html('');
														newFriends.forEach(function(result){

														var div;

														if (result.type == 'user') {
																	div = '<div class="media text-left pointer" ui-sref=user({slug:"'+result.slug+'"})>';
																	div	+='<div class="media-left">';
																	if (result.userType == 'Private') {

																	}

																	div	+=  (result.userType == 'Private')
																			? '<div class="newTreedomAvatar newTreedomAvatar-xs newTreedomAvatar-user" style="background-image:url('+$filter('s3')(result.picture)+')"></div>'
																			: '<div class="newTreedomAvatar newTreedomAvatar-xs newTreedomAvatar-business" style="background-image:url('+$filter('s3')(result.picture)+')"></div>'
																	div += '</div>';
																	div += '<div class="media-body media-middle">';
																	div += '<small>'+result.name+'</small>';
			                 						div += '</div>';
																	div += '</div>';

														} else if (result.type == 'tree') {
																	div = '<div class="media text-left pointer" ui-sref=species.tree({treeId:"'+result.slug+'"})>';
																	div	+='<div class="media-left">';
																	div	+='<div class="newTreedomAvatar newTreedomAvatar-xs newTreedomAvatar-tree" style="background-image:url('+$filter('s3')(result.picture)+')"></div>';
																	div += '</div>';
																	div += '<div class="media-body media-middle">';
																	div += '<small>'+result.name+'</small>';
			                 						div += '</div>';
																	div += '</div>';

														}  else if (result.type == 'event') {
																div = '<div class="media text-left pointer" ui-sref="user.event({slug:\''+result.userslug+'\', eventslug:\''+result.slug+'\'})">';
																div	+='<div class="media-left">';
																div	+='<div class="newTreedomAvatar newTreedomAvatar-xs newTreedomAvatar-business" style="background-image:url('+$filter('s3')(result.picture)+')"></div>';
																div += '</div>';
																div += '<div class="media-body media-middle">';
																div += '<small>'+result.name+'</small>';
		                 						div += '</div>';
																div += '</div>';
														} else if (result.type == 'emission') {
                                var image = $filter('asset')('bundles/treedomnewfrontend/images/calculator/'+result.slug+'.png');
																div = '<div class="media text-left pointer" ui-sref="emission.type({type:\''+result.slug+'\'})">';
																div	+='<div class="media-left">';
																div	+='<div class="newTreedomAvatar newTreedomAvatar-xs  newTreedomAvatar-user" style="background-image:url('+image+')"></div>';
																div += '</div>';
																div += '<div class="media-body media-middle">';
																div += '<small>'+result.name+'</small>';
		                 						div += '</div>';
																div += '</div>';
														}


														resultDiv.append($compile(div)(scope));

													});
												} else {
													resultDiv.html('');
													resultDiv.append($compile('<div class="text-center">no results</div>')(scope));
												}
                            // Flag the data as loaded.
                            scope.isLoading = false;
                            scope.friends = newFriends;
                        },
                        function( errorMessage ) {
                            // Flag the data as loaded (or rather, done trying to load). loading).
                            scope.isLoading = false;
                            console.warn( "Request for friends was rejected." );
                            console.info( "Error:", errorMessage );
                        }
                    );
							} else {

								resultDiv.remove();

							}

            };

            elem.bind('click', function() {


            });

            elem.bind('blur', function() {
								$timeout(function(){
									elem.val('');
									resultDiv.remove();
								},250)



            });

        }
      };
}]);
