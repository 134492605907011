




















import Icon from '@f/components/Icon.vue'
import Popover from '@f/components/Popover.vue'
import {computed, defineComponent, PropType} from '@vue/composition-api'
import useRoutes from '@f/composables/useRoutes'
import {getRootScope} from "@f/services/Angular";
import {IsoCode} from "@f/@types/localization";

export default defineComponent({
  name: 'UsersNumbersMediakit',
  components: {
    Icon,
    Popover
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    description: Object as PropType<Record<IsoCode, string>>
  },
  setup(props) {
    const { go } = useRoutes()
    const rootScope = getRootScope()
    const activeLanguage = computed(() => rootScope?.ln)
    const onClick = () => {
      if (props.active) {
        go('settings.widgets.user-numbers')
        return
      }
      go('business.subscriptions')
    }
    const cardStyle = computed(() => ({
      // TODO update with correct image
      backgroundImage: `url(${require('./assets/user-numbers-mediakit.png')})`
    }))
    return {
      onClick,
      cardStyle,
      activeLanguage
    }
  }
})
