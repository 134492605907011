


















import { defineComponent } from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'

export default defineComponent({
  name: 'ForestQrCode',
  components: { Treecon },
  setup() {
    const currentUrl = new URL(window.location.href)

    const baseUrl = currentUrl.origin

    const qrCodeLink = new URL(`${baseUrl}/prints/api/qrcode`)

    qrCodeLink.searchParams.set('url', currentUrl.href)
    qrCodeLink.searchParams.set('width', '500')

    return {
      qrCodeLink
    }
  }
})
