

































import { computed, defineComponent, PropType } from '@vue/composition-api'
import CopyInput from '@f/components/Form/CopyInput.vue'
import Modal from '@f/components/Modal.vue'
import { getMeta } from '@f/utils'

export default defineComponent({
  name: 'ShareFallbackModal',
  components: {
    Modal,
    CopyInput,
    EmailShareIcon: () => import(/* webpackChunkName: "email-fill.svg" */ '@f/images/share/email-fill.svg'),
    FacebookShareIcon: () => import(/* webpackChunkName: "facebook-fill.svg" */ '@f/images/share/facebook-fill.svg'),
    LinkedInShareIcon: () => import(/* webpackChunkName: "linkedin-fill.svg" */ '@f/images/share/linkedin-fill.svg'),
    TwitterShareIcon: () => import(/* webpackChunkName: "twitter-fill.svg" */ '@f/images/share/twitter-fill.svg'),
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    shareData: {
      type: [String, Object] as PropType<string | ShareData | undefined>,
      required: false
    },
  },
  setup(props, { emit }) {
    const url = computed(() => {
      return typeof props.shareData === 'string'
        ? props.shareData
        : props.shareData?.url || window.location.href
    })
    const getShareData = () => {
      if (typeof props.shareData === 'object') {
        return props.shareData
      }
      return { url: url.value }
    }

    const meta = computed(() => {
      return {
        url: url.value,
        title: getShareData().title
          || getMeta('title'),
        description: getShareData().text
          || getMeta('description'),
      }
    })
    const openGraph = computed(() => {
      return {
        url: url.value,
        title: getShareData().title
          || getMeta('og:title')
          || getMeta('title'),
        description: getShareData().text
          || getMeta('og:description')
          || getMeta('description'),
      }
    })
    const twitter = computed(() => {
      return {
        url: url.value,
        twitterUser: 'treedom',
        title: getShareData().title
          || getMeta('twitter:title')
          || getMeta('og:title')
          || getMeta('title'),
      }
    })
    const close = () => emit('close')
    return {
      url,
      meta,
      openGraph,
      twitter,
      close,
    }
  }
})
