type PersistedKey =
  'subscriptionEventsFromPosition' |
  'forestInProgress' |
  'forestSelectedProduct' |
  'treeOnBoardingModal__seen';

export default <T = any>(key: PersistedKey, storage: Storage = sessionStorage) => ({
    get(): T | null {
        const data: string | null =  storage.getItem(key);
        return data ? JSON.parse(data) : null;
    },
    set(data: T) {
        return storage.setItem(key, JSON.stringify(data));
    },
    remove() {
        return storage.removeItem(key);
    },
    clear() {
        return storage.clear();
    }
});