import { ref, onMounted } from '@vue/composition-api'
import { getRootScope } from '@f/services/Angular'
import { UserInfo } from '@f/@types'
import { IAngularEvent } from 'angular'

export default function useUserInfo(onUpdateCallback?: (info?: UserInfo | null) => void) {
  const userInfo = ref<UserInfo | null>(null)
  let userInfoListener: void | (() => void)

  onMounted(() => {
    const rootScope = getRootScope()
    if (!rootScope) return
    if (rootScope.userdata.info) {
      userInfo.value = rootScope.userdata.info
      onUpdateCallback && onUpdateCallback(userInfo.value)
    } else {
      userInfoListener = rootScope.$on('userdata.info', updateUserInfo)
    }
  })

  function updateUserInfo(_: IAngularEvent ,info?: UserInfo | null) {
    userInfo.value = info || null
    onUpdateCallback && onUpdateCallback(userInfo.value)
  }

  return {
    userInfo
  }
}
