angular.module('ngApp')
.controller('modalBadgeReceivedController', ['$rootScope', '$scope', 'Badge', function ($rootScope, $scope, Badge) {
  var ctrl = this
  ctrl.selected = 0
  ctrl.shareUrl = window.location.origin + '/' + $rootScope.ln + '/user/' + $rootScope.user + '/badges/' + $rootScope.badges[ctrl.selected].slug

  ctrl.selectToShow = function (type) {
    if (type && ctrl.selected + 1 < $rootScope.badges.length) {
      ctrl.selected++
      ctrl.shareUrl = window.location.origin + '/' + $rootScope.ln + '/user/' + $rootScope.user + '/badges/' + $rootScope.badges[ctrl.selected].slug
    }
    if (!type && ctrl.selected > 0) {
      ctrl.selected--
      ctrl.shareUrl = window.location.origin + '/' + $rootScope.ln + '/user/' + $rootScope.user + '/badges/' + $rootScope.badges[ctrl.selected].slug
    }
  }

  var closedModalListener = $rootScope.$on('closedTreedomModal', function (event, modalInfo) {
    if (modalInfo.closedModalId.match(/^modalBadgeReceived.html/) !== null) {
      Badge.postNotifiedBadges($rootScope.badges)
      $rootScope.badges = []
      $rootScope.modalBadgeReceivedShowed = false
    }
  })

  $scope.$on('$destroy', closedModalListener)
}])
