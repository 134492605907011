import { render, staticRenderFns } from "./ImpactOverview.vue?vue&type=template&id=760a85f2&scoped=true&"
import script from "./ImpactOverview.vue?vue&type=script&lang=js&"
export * from "./ImpactOverview.vue?vue&type=script&lang=js&"
import style0 from "./ImpactOverview.vue?vue&type=style&index=0&id=760a85f2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "760a85f2",
  null
  
)

export default component.exports