












import {defineComponent} from "@vue/composition-api";
import CommonCardGame from "./../Components/CommonCardGame.vue";


const LEVELS = [
  {
    threshold: 0,
    data: {
      icon: "level-1",
      title: "apprentice",
    }
  },
  {
    threshold: 2,
    data: {
      icon: "level-2",
      title: "ambassador",
    }
  },
  {
    threshold: 4,
    data: {
      icon: "level-3",
      title: "expert",
    }
  },
]

export default defineComponent({
  name: "GameCompleted",
  props: {
    correctAnswers: {type: Number, required: true},
  },
  components: {
    CommonCardGame,
  },
  setup(props) {
    const getLevel = () => LEVELS
        .filter(level => props.correctAnswers > level.threshold)
        .map(level => level.data)
        .pop();

    return {
      getLevel,
    };
  },
});
