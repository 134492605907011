import gql from 'graphql-tag'
import { MAP_CLUSTER } from '@f/graphql/queries'

angular.module('mapService', [])
.factory('Map', ['$http', 'Routing', 'apollo', function ($http, Routing, apollo) {
  var mapFactory = {}
  var routing = Routing

  var routeJs = function (route, params) {
    var url = routing.route(route, params)
    if (url.indexOf('?') > -1) {
      url = url.substring(0, url.indexOf('?'))
    }
    return url
  }

  /**
   * Get map cluster
   * @param {number} data.zoom - Map zoom level.
   * @param {number} data.NE.lat - Northeast lat of map corner.
   * @param {number} data.NE.lng - Northeast lng of map corner.
   * @param {number} data.SW.lat - Southwest lng of map corner.
   * @param {number} data.SW.lng - Southwest lng of map corner.
   * @param {number} [data.userId] - User/Organization ID.
   * @param {number} [data.eventId] - Event ID.
   * @param {boolean} [data.nursery] - If true nursery will be sent.
   */

  mapFactory.getCluster = function (data) {
    return apollo.query({
      query: MAP_CLUSTER,
      variables: data
    })
      .then(({ data }) => {
        return data.cluster || []
      })
      .catch(error => {
        console.log(error)
        return []
      })
    // return $http.post(routeJs('new_map_cluster'), data)
    // .then(function(response) {
    //   return response.data || response
    // })
    // .catch(function(err) {
    //   throw err.data || err
    // })
  }

  /**
   * Get marker a single Tree by ID
   * @param {string|number} id - Tree ID
   * @param {boolean} nursery - True or false
   */
  mapFactory.getTree = function (id, nursery) {
    return $http.post(routeJs('new_map_tree'), { id: id, nursery: nursery || false })
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  }

  return mapFactory
}])
