angular.module('ngApp')
// Controller per ottenere le informazioni dell'utente o dell'evento di cui si stà visitando l'albero
.controller('treeInfoBoxController', ['$scope', '$state', '$stateParams', 'User', function ($scope, $state, $stateParams, User) {
  // NOTE: Attualmente non viene più usato, il box dell'utente è stato
  // rimpiazzato con il box dell'albero e le sue informazioni.

  $scope.infoBoxLoading = true

  $scope.getInfoBoxData = function (type) {
    var slug = $state.current.name.includes('user.event')
      ? $stateParams.eventslug
      : $stateParams.slug

    User.getInfoBox(type, slug)
    .then(function(res) {

      res = res.data || res
      $scope.infoBox = res
      $scope.infoBoxLoading = false
    })
    .catch(function() {

      $scope.infoBoxLoading = false
    })
  }
}])
