







import { defineComponent, PropType } from '@vue/composition-api'
import showWeather from '@f/composables/showWeather'
import { TrForecastTree, TrForecastTreeEnhanced } from '@f/@types/graphql'


export default defineComponent({
  name: 'TreeWeatherClock',
  components: {
    ClearDay: () => import(/* webpackChunkName: "clear-day.svg" */ '@f/images/weather/clear-day.svg'),
    ClearNight: () => import(/* webpackChunkName: "clear-night.svg" */ '@f/images/weather/clear-night.svg'),
    Cloudy: () => import(/* webpackChunkName: "cloudy.svg" */ '@f/images/weather/cloudy.svg'),
    PartlyCloudyDay: () => import(/* webpackChunkName: "partly-cloudy-day.svg" */ '@f/images/weather/partly-cloudy-day.svg'),
    PartlyCloudyNight: () => import(/* webpackChunkName: "partly-cloudy-night.svg" */ '@f/images/weather/partly-cloudy-night.svg'),
    Rain: () => import(/* webpackChunkName: "rain.svg" */ '@f/images/weather/rain.svg'),
    Snow: () => import(/* webpackChunkName: "snow.svg" */ '@f/images/weather/snow.svg'),
    
  },
  props: {
    forecast: {
      type: Object as PropType<TrForecastTreeEnhanced>
    }
  },
  setup(props) {
    const { icon, temperature, time } = showWeather(props.forecast)
    
    return {
      icon,
      temperature,
      time,
    }
  }
})
