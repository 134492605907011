angular.module( "treedomSwipe", [])
  .directive('treedomSwipe', ['$window','$compile', '$rootScope', '$timeout', '$state', '$swipe', function($window, $compile,$rootScope,$timeout, $state, $swipe) {
    return {
      restrict: 'AE', // come puo venire definita es E:element <angular-tip>/  A:attribute <div angular-tip> / C:classe etc...
        replace: false, // se viene rimpiazzato con il template sotto
        link: function(scope, elem, attrs) {
      //
      //     $swipe.bind(elem, {
      //     'start': function(coords) {
      //        updateScrollPos(coords.x, coords.y,'start')
      //     },
      //     // 'move': function(coords) {
      //     //  updateScrollPos(coords.x,coords.y, 'move');
      //     //
      //     // },
      //     'end': function(coords){
      //        updateScrollPos(coords.x,coords.y, 'end')
      //     },
      // });
      //
      // var start = 0;
      // var isMoving = false;
      // function updateScrollPos(x, y, type){
      //   // console.log(x,y,type)
      //
      //
      //   if (type == 'start') {
      //       start = y;
      //   } else {
      //
      //     console.log(start, y)
      //     if (y > start) {
      //       console.log('down')
      //     } else if (y < start) {
      //       console.log('up');
      //     } else {
      //       console.log('click');
      //     }
      //
      //   }
      // }
          
  
      }
    }
}]);    
