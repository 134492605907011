














import {computed, defineComponent, PropType, provide} from '@vue/composition-api'
import DotLoader from "@f/components/DotLoader.vue";
import {DefaultApolloClient, useQuery, useResult} from "@vue/apollo-composable";
import {TREE, USER_WALL_ITEM} from "@f/graphql/queries";
import {DataEventTreeDiaryInteraction} from "@f/services/TagManager/events/tree";
import {DataEventProfileActivity} from "@f/services/TagManager/events/profile";
import {apiClient} from "@f/apollo";
import {TrTree, TrTreeDiaryItem} from "@f/@types/graphql";

const Post = defineComponent({
  name: 'Post',
  components: {
    DiaryItem: () => import(/* webpackChunkName: "DiaryItem" */ '@f/components/Diary/DiaryItem.vue'),
  },
      props: {
        content: {
          type: Object as PropType<TrTreeDiaryItem>,
          required: true
        },
        showComments: {
          type: Boolean,
          default: false
        },
        shareUrl: {
          type: String,
          default: undefined
        },
        tree: {
          type: Object as PropType<TrTree>,
        }
      },
  setup(props) {
    provide('tree', props.tree)
    return {}
  },
  template: `<DiaryItem
      class="diary"
      :content="content"
      :shareUrl="shareUrl"
      show-comments
      v-on='$listeners'
  />`
})

export default defineComponent({
  name: 'PostPage',
  components: {
    DotLoader,
    Post
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'tree' | 'user' | 'business'>,
      default: 'tree',
    },
    shareUrl: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    provide(DefaultApolloClient, apiClient)

    const treeId = computed(() => {
      if (props.shareUrl?.includes('/trees/')) {
        return props.shareUrl.split('/').slice(-3)[0]
      }
      return null
    })

    const postQuery = useQuery(
        USER_WALL_ITEM,
        () => ({postId: props.id}),
        () => {
          return {
            enabled: !!props.id,
          }
        }
    )


    const treeQuery = useQuery(
        TREE,
        () => ({id: treeId.value}),
        () => {
          return {
            enabled: !!treeId.value,
            fetchPolicy: 'no-cache'
          }
        }
    )

    const tree = useResult(treeQuery.result)

    const post = useResult(postQuery.result)

    const isLoading = computed(() => postQuery.loading.value || treeQuery.loading.value)

    function handleDataEvent({action, element}: { action: string, element: string }) {
      if (props.type === 'tree') {
        DataEventTreeDiaryInteraction('myDiary', action, element)
      } else {
        DataEventProfileActivity('diary', action, element)
      }
    }

    return {
      loading: isLoading,
      post,
      tree,
      handleDataEvent,
    }
  }
})

