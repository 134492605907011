















































import { Vue, Component, Prop } from 'vue-property-decorator'
import Comment from './Comment.vue'
import CommentInput from './CommentInput.vue'
import Loader from '@f/components/Loader.vue'
import { TREE_DIARY_ITEM_COMMENTS, TREE_DIARY_ITEM_COMMENT_REPLIES } from '@f/graphql/queries'
import { TREE_DIARY_COMMENT_FRAGMENT, TREE_DIARY_COMMENT_REPLY_FRAGMENT } from '@f/graphql/fragments'
import { gql } from '@apollo/client/core'
import { ApolloCache } from '@apollo/client/cache'
import { TrPageInfo, TrTreeDiaryComment } from '@f/@types/graphql'

@Component({
  components: {
    Comment,
    CommentInput,
    Loader
  }
})
export default class CommentList extends Vue {
  @Prop({ type: [Number, String], required: true }) readonly postId!: number | string
  @Prop({ type: [Number, String, Boolean], default: false }) readonly commentsCount!: number | string | boolean
  
  showingRepliesId = false
  preloadLimit = 3
  paginationLimit = 10
  showCommentsCount = 3
  commentsQuery = TREE_DIARY_ITEM_COMMENTS
  repliesQuery = TREE_DIARY_ITEM_COMMENT_REPLIES

  // Refs
  $refs!: Vue["$refs"] & {
    commentsQueryComponent: Vue
  };
  
  // Computed
  get commentsVariables() {
    return { id: this.postId, limit: this.preloadLimit }
  }
  get remainingCount(): number | string {
    return this.commentsCount ? Math.max(0, +this.commentsCount - this.showCommentsCount) : ''
  }

  // Methods
  toggleRepliesShow(parentId = false) {
    this.showingRepliesId = parentId !== this.showingRepliesId && parentId
  }

  updateComments(cache: ApolloCache<any>, { data: { comment } }: any) {
    this.showCommentsCount++
    const queryData = cache.readQuery<any>({
      query: this.commentsQuery,
      variables: this.commentsVariables,
    })
    cache.writeQuery({
      query: this.commentsQuery,
      variables: this.commentsVariables,
      data: {
        ...queryData,
        commentList: {
          ...queryData.commentList,
          comments: [ comment, ...queryData.commentList.comments ]
        }
      },
    })
  }

  commentDeleted(cache: ApolloCache<any>, result: any, deletedComment: any) {
    if (result) {
      this.showCommentsCount--
      cache.modify({
        fields: {
          Tree_Diary_Item_Comment_page(cachedList: any = { edges: [], pageInfo: null }, { readField }) {
            return {
              ...cachedList,
              edges: cachedList.edges.filter((commentRef: any) => readField('id', commentRef) !== deletedComment.id)
            }
          }
        }
      })
      this.$emit('deletedComment')
    }
  }

  fetchMoreComments(query: any, cachedList: any[], pageInfo: TrPageInfo) {
    this.showCommentsCount += this.paginationLimit
    if (this.showCommentsCount > cachedList.length && pageInfo.hasNextPage) {
      const limit = Math.min(this.showCommentsCount - cachedList.length, this.paginationLimit)
      const after = btoa(String(cachedList.length))
      query.fetchMore({
        variables: {
          id: this.postId,
          after,
          limit,
        },
        updateQuery: (previousResult: any, { fetchMoreResult: { commentList } }: any) => {
          return {
            commentList: {
              comments: [...previousResult.commentList.comments, ...commentList.comments],
              pageInfo: { ...commentList.pageInfo },
              __typename: commentList.__typename
            }
          }
        }
      })
    }
  }

  updateReplies(cache: ApolloCache<any>, { data: { reply } }: any) {
    this.updateRepliesCount(cache, reply.parentId, 1)
  }

  replyDeleted(cache: ApolloCache<any>, result: any, deletedReply: any) {
    if (result) {
      this.updateRepliesCount(cache, deletedReply.parentId, -1)
    }
  }

  updateRepliesCount(cache: ApolloCache<any>, commentId: string, diff: number) {
    cache.modify({
      id: 'TreeDiaryComment:'+commentId,
      fields: {
        repliesCount: (count: number = 0) => count+diff
      }
    })
  }

  paginated(cachedList: any[]) {
    return cachedList.slice(0, this.showCommentsCount)
  }

  hasMoreComments(cachedList: any[], pageInfo: TrPageInfo) {
    return (!this.$refs.commentsQueryComponent || !this.$refs.commentsQueryComponent.$apollo.loading) && (this.showCommentsCount < cachedList.length || pageInfo.hasNextPage)
  }
}
