const { getWidgetPosition, isCursorOn } = require("./widgetUtils.js");

angular.module('ngApp')
  /**
  * Widget Specie.
  * NOTE: Specificare altezza e larghezza del widget che deve essere fissa!
  *
  * @param {Object} attrs
  * @param {string} attrs.widgetKey - Chiave per salvare l'oggetto in una cache temporanea (Deve essere l'ID della limited, se non esiste, l'ID della specie)
  * @param {string} attrs.widgetLimitedId - ID della limited edition, se presente.
  * @param {string} attrs.widgetSpecieId - ID della specie.
  * @param {string} attrs.disabled - Se true, il widget non viene mostrato
  */
  .directive('widgetspecie', ['$rootScope', '$compile', '$document', '$state', '$interval', 'Utility', function ($rootScope, $compile, $document, $state, $interval, Utility) {
    var mouseOverElement = false
    var renderInterval

    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {
        if ($rootScope.isMobile) return
        if (attrs.widgetDisabled) return
        var _activeWidget = false
        var widget = { width: 240, height: 307 }

        // Rimuove il widget.
        function removeWidget(widget) {
          window.removeEventListener('mousemove', mouseMoveListener)
          widget.remove()

          // Controllo per sicurezza se esistono altri widget e li cancello.
          var otherWidgets = $document.find('.w_' + _activeWidget.key)
          if (otherWidgets.length) {
            otherWidgets.each(function (index) {
              otherWidgets[index].remove()
            })
          }

          _activeWidget = false
        }

        // Listener movimento mouse.
        function mouseMoveListener(e) {
          var widget = $document.find('#' + _activeWidget.key)

          if (!isCursorOn(element, e) && !isCursorOn(widget, e)) removeWidget(widget)

          $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            var widget = $document.find('#' + _activeWidget.key)
            removeWidget(widget)
          })
        }

        // Funzione per renderizzare il widget con i dati richiesti.
        function renderWidget(widgetKey) {
          if (!mouseOverElement) return

          _activeWidget = $rootScope.trees.find(tree => tree.id === widgetKey)

          // Creo il template del widget.
          var position = getWidgetPosition(element, widget)
          var template = '../../bundles/treedomnewfrontend/html-templates/widget/specie.html'
          var style = 'top: ' + position.top + 'px; ' + 'left: ' + position.left + 'px;'
          var arrowUp = position.position === 'bottom' ? '<div class="arrow-up" style="left: ' + position.offsetLeftArrow + 'px"></div>' : ''
          var arrowDown = position.position === 'top' ? '<div class="arrow-down" style="left: ' + position.offsetLeftArrow + 'px"></div>' : ''
          var html = '<div id="' + _activeWidget.key + '" style="' + style + '" class="widgetContainer w_' + _activeWidget.key + '"><div class="widgetContent">' + arrowUp + '<div ng-include="\'' + template + '\'"></div>' + arrowDown + '</div></div>'

          // Attacco allo $scope la specie attiva.
          $scope.widgetSpecie = _activeWidget

          $scope.widgetShowThisTree = function (isGift) {
            if (isGift) $scope.widgetGiftFromPage()

            /*Utility.gaAdd('addProduct', _activeWidget)
            Utility.gaAction('click', {})
            Utility.gaSend()
            */
            $state.go('species.tree', { treeId: _activeWidget.key })
            $rootScope.activeTree = _activeWidget
            Utility.blockScroll()
          }

          $scope.widgetGiftFromPage = function () {
            // nel caso ci fosse un evento segnato
            $rootScope.plantIn = false
            $rootScope.giftToUser = $rootScope.showUser.info
          }

          // Appendo il widget in fondo al body.
          var body = $document.find('body').eq(0)
          body.append($compile(angular.element(html))($scope))
          $scope.$apply()

          // Creo l'ascoltatore per il movimento del mouse.
          window.addEventListener('mousemove', mouseMoveListener)
        }

        // Funzione per renderizzare il widget con un intervallo di tempo.
        function renderWidgetWithInterval(widgetKey, milliseconds) {
          if (renderInterval) clearInterval(renderInterval)

          renderInterval = setInterval(function () {
            renderWidget(widgetKey)
            clearInterval(renderInterval)
          }, milliseconds)
        }

        // Mouseenter per creare il widget.
        element.on('mouseenter', function () {
          // Mouse sopra l'elemento che renderizza il widget.
          mouseOverElement = true

          // Impostazioni iniziali
          var widgetKey = parseInt(attrs.widgetProductId);

          // Se il widget esiste, non lo ricreo.
          var oldWidget = document.getElementById(widgetKey)
          if (oldWidget) return

          // Lo cerco nella cache.
          if ($rootScope.trees.find(tree => tree.id === widgetKey)) {
            renderWidgetWithInterval(widgetKey, 700)
            return
          }
        })

        element.on('mouseleave', function () {
          mouseOverElement = false
          clearInterval(renderInterval)
        })
      }
    }
  }])
