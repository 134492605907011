import { YouTubeThumbnailResolution, YouTubeThumbnailShot } from '@f/services/YouTube/types'
import { nextTick } from '@vue/composition-api'

const scriptSource = 'https://www.youtube.com/iframe_api'
const scriptTagId = 'yt-iframe-api'
const defaultOptions = {
  width: 1920,
  height: 1080,
  playerVars: {
    enablejsapi: 1,
    modestbranding: 1,
    origin: window.location.origin,
    rel: 0
  },
}

function sanitizeVideoId(url: string) {
  return url?.match(/(?:https:\/\/(?:www.)*youtu(?:\.be\/|be\.com\/.*v=))*([\w-_]*)(?:&|$)/)?.[1] || ''
}

function runPlayersQueue() {
  if (!window.youTubeAPIReadyQueue?.length) return
  for (const init of window.youTubeAPIReadyQueue) {
    init()
  }
}

function mountPlayer(el: HTMLElement, options: Record<string, any>) {
  new window.YT.Player(el, options)
}

function mergedOptions(options: Record<string, any>) {
  return {
    ...defaultOptions,
    ...options,
    playerVars: {
      ...defaultOptions.playerVars,
      ...options.playerVars,
    }
  }
}

export function mountScriptTag(callback: () => void) {
  const scriptTag = document.createElement('script') as HTMLScriptElement
  scriptTag.src = scriptSource
  scriptTag.id = scriptTagId
  const firstSibling = document.getElementsByTagName('script')[0]
  if (firstSibling) {
    firstSibling.parentNode?.insertBefore(scriptTag, firstSibling)
    if (!window.onYouTubeIframeAPIReady) {
      window.onYouTubeIframeAPIReady = runPlayersQueue
    }
    window.youTubeAPIReadyQueue = window.youTubeAPIReadyQueue
      ? [...window.youTubeAPIReadyQueue, callback]
      : [callback]
  }
}

export function initYTPlayer(el: HTMLElement, id: string, options: Record<string, any> = {}) {
  const videoId = sanitizeVideoId(id)
  const playerOptions = mergedOptions({ ...options, videoId })
  const mountCallback = mountPlayer.bind(undefined, el, playerOptions)
  if (window.YT) {
    nextTick(mountCallback)
  } else {
    mountScriptTag(mountCallback)
  }
}
