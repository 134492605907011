





























import { Component, Mixins } from 'vue-property-decorator'
import TreeDiaryLike from '../post_types/TreeDiaryLike.vue'

@Component({
  name: 'TreeDiaryLikeModal',
})
export default class TreeDiaryLikeModal extends Mixins(TreeDiaryLike) {}
