





























import { defineComponent, ref, computed, provide, watch, PropType } from '@vue/composition-api'
import { DefaultApolloClient, useQuery } from '@vue/apollo-composable'
import { apiClient } from '@f/apollo'
import HUDPage from '@f/components/Map/HUD/HUDPage.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import { FOREST_TREES } from '@f/graphql/queries'
import useRoutes from '@f/composables/useRoutes'
import { fetchForestNumbers } from '@f/services/Forest'
import { TrEvent, TrEventNumbers } from '@f/@types/graphql'

export default defineComponent({
  name: 'HUDForestTrees',
  components: {
    DotLoader,
    HUDPage,
    Treecon,
  },
  props: {
    state: String,
    forest: Object as PropType<TrEvent>,
    perPage: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    provide(DefaultApolloClient, apiClient)
    const { currentState } = useRoutes()
    const numbersLoading = ref(false)
    const numbers = ref<TrEventNumbers>()

    const headerStyle = computed(() => {
      return !!props.forest?.background
        ? { backgroundImage: `url(${props.forest?.background?.large})` }
        : {}
    })

    const forestName = computed(() => props.forest?.name || '')
    const treesCount = computed(() => {
      return typeof numbers.value?.trees !== 'undefined'
        ? numbers.value.trees
        : ' '
    })
    function fetchNumbers() {
      if (!props.forest?.slug) return
      numbersLoading.value = true
      fetchForestNumbers(props.forest.slug)
        .then(data => numbers.value = data)
        .finally(() => numbersLoading.value = false)
    }

    watch(() => props.forest, fetchNumbers)

    const itemsToShow = ref(props.perPage)
    const forestTreesVariables = computed(() => {
      return {
        eventId: Number(props.forest?.id),
        limit: props.perPage,
        after: '',
      }
    })
    const { result, loading, fetchMore } = useQuery(
      FOREST_TREES,
      () => forestTreesVariables.value,
      () => {
        return {
          enabled: !!props.forest,
          notifyOnNetworkStatusChange: true,
        }
      }
    )
    const trees = computed(() => {
      return result.value?.forestTrees.trees?.slice(0, itemsToShow.value) || []
    })
    const endOfList = computed(() => !result.value?.forestTrees.pageInfo?.hasNextPage)

    function loadMore() {
      itemsToShow.value += props.perPage
      if (trees.value.length < itemsToShow.value && !endOfList.value) {
        const pageOffsetCount = itemsToShow.value - trees.value.length
        fetchMore({
          variables: {
            limit: pageOffsetCount,
            after: btoa(String(trees.value.length))
          },
        })
      }
    }

    return {
      endOfList,
      forestName,
      loading,
      loadMore,
      headerStyle,
      trees,
      treesCount,
    }
  }
})
