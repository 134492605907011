angular.module('blogService', [])
.factory('Blog', ['$http', 'Routing', function ($http, Routing) {
  var routing = Routing

  var routeJs = function (route, params) {
    var url = routing.route(route, params)

    // Funzione per pulire l'url dal' bug di fos_user che mantiene i parameters nelle varie chiamate
    if (url.indexOf('?') > -1) url = url.substring(0, url.indexOf('?'))

    return url
  }

  var blogFactory = {}


  blogFactory.getArticles = function (offset, limit, type, search, post) {
    return $http.post(routeJs('treedom_get_blog_articles', { offset: offset, limit: limit, type: type, search: search }), post)
    .then(function(response) {
      return response.data || response
    })
  }

  blogFactory.getSimilarArticles = function (slug) {
    return $http.get(routeJs('treedom_get_blog_similar_articles', { slug: slug }))
    .then(function(response) {
      return response.data || response
    })
  }

  blogFactory.getArticle = function (slug) {
    return $http.get(routeJs('treedom_get_blog_article', { slug: slug }))
    .then(function(response) {
      return response.data || response
    })
  }

  return blogFactory
}])
