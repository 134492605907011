





























import {Component, Mixins, Prop} from 'vue-property-decorator'
import {IsoCode} from '@f/@types/localization'
import ShowLanguages from '@f/mixins/ShowLanguages'
import Icon from '@f/components/Icon.vue'
import Popover from '@f/components/Popover.vue'
import {getRootScope} from '@f/services/Angular'
import {TreedomAuth} from '@f/auth'

@Component({
  name: 'MediaKit',
  inheritAttrs: false,
  components: {
    Icon,
    Popover
  }
})
export default class BusinessSubscriptionMediaKit extends Mixins(ShowLanguages) {
  /*--- MODEL ---------*/

  /*--- PROPS ---------*/
  @Prop({ type: Object, required: false }) readonly description!: string | Record<IsoCode, string>
  @Prop({ type: Object, required: false }) readonly certificateStatus!: {
    status: 'inactive' | 'active-enough' | 'active-not-enough' | 'unsupported-subscription',
    remainingTime: number | undefined
  }

  /*--- DATA ----------*/

  /*--- COMPUTED ------*/

  get buttonDisabled() {
    return this.certificateStatus.status === 'active-not-enough'
  }

  get certificateDisabled() {
    return this.certificateStatus.status === 'inactive'
  }

  get cardStyle() {
    return {
      backgroundImage: `url(${require('./assets/badge.png')})`
    }
  }

  get downloadAvailableDate() {
    if (this.certificateStatus.status !== 'active-not-enough') return ''

    const { remainingTime = 0 } = this.certificateStatus
    const formatter = new Intl.DateTimeFormat(getRootScope()?.ln, {
      day: '2-digit',
      month: 'long'
    })
    return formatter.format(new Date(new Date().getTime() + remainingTime))
  }

  get buttonText() {
    if (this.certificateStatus.status === 'inactive') return this.$t('edit.mediakits.subscribe')

    if (this.certificateStatus.status === 'active-not-enough') return this.$t('edit.mediakits.download')

    if (this.certificateStatus.status === 'unsupported-subscription') return this.$t('edit.mediakits.change')

    return this.$t('edit.mediakits.download')
  }

  /*--- WATCHERS ------*/

  /*--- REFS ----------*/

  /*--- EVENTS --------*/

  /*--- METHODS -------*/

  onClick() {
    if (this.certificateStatus.status === 'inactive')
      return this.$state.go('subscriptions-switch')

    if (this.certificateStatus.status === 'active-not-enough')
      return

    if (this.certificateStatus.status === 'unsupported-subscription')
      return this.$state.go('settings.subscription')

    return this.fetchCertificate()
  }

  downloadImage(url: string) {
    const a = document.createElement('a')

    a.href = url
    a.download = 'treedom-certificate.png'

    document.body.appendChild(a)

    a.click()

    document.body.removeChild(a)
  }

  shareBlob(files: File[]) {
    return navigator.share({
      // @ts-ignore
      files,
      title: 'Treedom',
      text: 'Treedom'
    })
  }

  async fetchCertificate() {
    const token = await TreedomAuth.getJwtToken()

    if (!token) return

    const response = await fetch(
      '/prints/api/business/subscriptions/certificate?' + new URLSearchParams({ lang: getRootScope()?.ln ?? 'en' }),
      {
        headers: { Authorization: 'Bearer ' + token }
      })
      .then(response => response.blob())

    const files = [new File([response], 'treedom-certificate.png', { type: 'image/png' })]
    // @ts-ignore
    if (navigator.canShare && navigator.canShare({ files })) {
      return this.shareBlob(files)
    }

    const url = URL.createObjectURL(response)

    this.downloadImage(url)
  }
}
