import { onMounted, onUnmounted, Ref, ref } from '@vue/composition-api'
import { BreakpointName, breakpoints } from '@f/configs/breakpoints'

export const minWidth = (size: BreakpointName) => `(min-width: ${breakpoints[size]}px)`
export const maxWidth = (size: BreakpointName) => `(max-width: ${breakpoints[size]}px)`
export const maxWidthExc = (size: BreakpointName) => `(max-width: ${(breakpoints[size]-1)}px)`
export const useMediaQuery = (query: string): Ref<boolean> => {
    const mediaQueryList = ref(window.matchMedia(query))
    const result = ref<boolean>(mediaQueryList.value.matches)
    const updateListener = (e: MediaQueryListEvent) => {
      result.value = e.matches;
    }
    onMounted(() => {
      mediaQueryList.value.addEventListener('change', updateListener)
    })
    onUnmounted(() => {
      mediaQueryList.value.removeEventListener('change', updateListener)
    })
    return result
}

export const useMediaQueryChange = (query: string,  listener: (this: MediaQueryList, ev: MediaQueryListEventMap['change']) => any) => {
  const mediaQueryList = ref(window.matchMedia(query))
  onMounted(() => {
    mediaQueryList.value.addEventListener('change', listener)
  })
  onUnmounted(() => {
    mediaQueryList.value.removeEventListener('change', listener)
  })
}