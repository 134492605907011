

















































import { defineComponent } from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'
import useRoutes from '@f/composables/useRoutes'

export default defineComponent({
  name: 'OrganizationMenu',
  components: { Treecon },
  props: {
    slug: {
      type: String,
      required: true
    },
    showImpacts: {
      type: Boolean,
      default: false
    },
    showForests: {
      type: Boolean,
      default: false
    },
    showComunicationKits: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { currentState, currentParams, href, go, isCurrentState } =
      useRoutes()

    const isActiveState = (state: string) => {
      return isCurrentState(
        state,
        { slug: props.slug },
        { state: true }
      )
    }
    return {
      currentState,
      currentParams,
      href,
      go,
      isActiveState,
    }
  }
})
