























import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from '@f/components/Icon.vue'
import Logo from '@f/images/logo-dynamic.svg'
import OpenForestRedeemFlow from '@f/components/Flow/OpenForestRedeemFlow.vue'

@Component({
  name: 'OpenForestRedeem',
  components: { Icon, Logo, OpenForestRedeemFlow }
})
export default class OpenForestRedeem extends Vue {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  @Prop({ type: String, required: true }) readonly eventSlug!: string
  @Prop({ type: String, required: true }) readonly userSlug!: string
  
  /*--- DATA ----------*/
  
  /*--- COMPUTED ------*/
  
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  
  /*--- METHODS -------*/
  closeRedeem() {
    this.$rootScope.goBack()
  }
}
