

















import {computed, defineComponent, PropType, ref} from '@vue/composition-api'
import BaseInput from '@f/components/Form/BaseInput.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Modal from '@f/components/Modal.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import useAuthentication from '@f/composables/useAuthentication'
import {TrTree} from '@f/@types/graphql'
import TreeEditNameModal from '@f/components/Tree/TreeEditNameModal.vue'
import {baseStripTag, replaceHtmlAsciiCodes} from "@f/utils";

export default defineComponent({
  name: 'TreeInfoName',
  components: {TreeEditNameModal, BaseInput, DotLoader, Modal, Treecon,},
  props: {
    nameTag: {
      type: String,
      default: 'div'
    },
    nameClass: String,
    tree: Object as PropType<TrTree>,
    disableEditing: {default: false}
  },
  setup(props) {
    const editing = ref(false)
    const {isLoggedAs} = useAuthentication()

    const treeName = computed(() => {
      if(props.tree?.name) {
        return baseStripTag(replaceHtmlAsciiCodes(props.tree.name))
      }
    })
    const treeSpecie = computed(() => props.tree?.specie?.name || '')
    const canEditName = computed(() => props.tree?.user && isLoggedAs(props.tree.user))

    return {
      canEditName,
      editing,
      treeName,
      treeSpecie,
    }
  }
})
