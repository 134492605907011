






























import { defineComponent } from '@vue/composition-api'
import HUDTreeCard from '@f/components/Map/HUD/HUDTreeCard.vue'
import useRoutes from '@f/composables/useRoutes'
import TextTruncate from '@f/components/TextTruncate.vue'

export default defineComponent({
  name: 'OrganizationMapHUDCountryTree',
  components: { TextTruncate, HUDTreeCard },
  props: {
    treeId: [Number, String],
  },
  setup() {
    const { href } = useRoutes()
    return {
      href,
    }
  }
})
