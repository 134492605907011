














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  inheritAttrs: false
})
export default class BaseCheckbox extends Vue {
  @Prop({ required: true }) readonly value!: string | boolean
  @Prop({ type: String, required: true }) readonly label!: string
  @Prop({ type: String, default: null }) readonly error!: string | null
  @Prop({ type: Boolean, default: false }) readonly hideValidationError!: boolean
  @Prop({ type: String, default: '' }) readonly labelClass!: string
  @Prop({ type: Boolean, default: false }) readonly inline!: boolean
  @Prop({ type: Boolean, default: false }) readonly circle!: boolean

  emitInput(): void {
    // TODO: specify right $ev type
    this.$emit('input', !this.value)
  }
}
