
import { CookieStorage } from '@aws-amplify/auth'
import { CognitoStorage } from './cognito-provider'

const storeInCookies = (key: string): boolean => {
  if (key.endsWith('idToken')) return true
  if (key.endsWith('refreshToken')) return true
  if (key.endsWith('LastAuthUser')) return true
  return false
}
export type CognitoHybridStorageConfig = {
  cookieStorage: {
    domain: string
    secure: boolean
    path: string
    expires: number
  }
}

/**
 * This is a workaround for the fact that localStorage is not available in incognito mode
 * in iframes in chrome.
 * This is a known issue in chrome, and the workaround is to catch the error and return
 * a dummy object.
 * Ref:
 * - https://www.chromium.org/for-testers/bug-reporting-guidelines/uncaught-securityerror-failed-to-read-the-localstorage-property-from-window-access-is-denied-for-this-document/
 */
const getSafeLocalStorage = (): Storage => {
  try {
    return window.localStorage
  } catch (e) {
    console.error('localStorage is not available')
    const storage = new Map<string, string>()
    return {
      getItem: (key) => storage.get(key) ?? null,
      setItem: (key, value) => storage.set(key, value),
      removeItem: (key) => storage.delete(key),
      clear: () => storage.clear(),
      key: (index) => [...storage.keys()].at(index) ?? null,
      get length() {
        return storage.size
      },
    }
  }
}

export class CognitoHybridStorage implements CognitoStorage{

  private cookie: CookieStorage
  private local: Storage

  constructor(config: CognitoHybridStorageConfig) {
    this.cookie = new CookieStorage(config.cookieStorage)
    this.local = getSafeLocalStorage()
  }
  setItem(key: string, value: string) {
    if (storeInCookies(key)) return this.cookie.setItem(key, value)
    return this.local.setItem(key, value)
  }

  getItem(key: string): string | null {
    if (storeInCookies(key)) return this.cookie.getItem(key)
    return this.local.getItem(key)
  }

  removeItem(key: string): void {
    if (storeInCookies(key)) return this.cookie.removeItem(key)
    return this.local.removeItem(key)
  }

  clear(): void {
    this.cookie.clear()
    this.local.clear()
  }

  async sync(): Promise<void> {}
}
