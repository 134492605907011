
































































import StatefullButton from '@f/components/StatefullButton.vue'
import { defineComponent } from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'
import useFormat from '@f/composables/useFormat'

export default defineComponent({
  name: 'ForestHeader',
  components: {
    StatefullButton,
    Treecon,
  },
  props: {
    avatarImageUrl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      defualt: '',
    },
    count: {
      type: Number,
      default: 0,
    },
    isForestPublished: {
      type: Boolean,
      default: false,
    },
    isCtaVisible: {
      type: Boolean,
      default: false,
    },
    ctaLabel: {
      type: String,
      default: '',
    },
    forestName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'trees',
      validator: (value: string) => ['trees', 'users'].includes(value),
    },
  },
  setup() {
    const { formatLocalNumbers } = useFormat();

    return {
      formatLocalNumbers,
    }
  },
})
