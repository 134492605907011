









































import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator'
import Modal from '@f/components/Modal.vue'
import DotLoader from '@f/components/DotLoader.vue'
import URLSearchParams from '@ungap/url-search-params'
import {setStructuredData} from '@f/utils/dom'
import {getImageAsset} from '@f/utils'

Vue.directive('$model', {
    bind: function (el, binding, vnode) {
      // @ts-ignore
      el.oninput = () => (vnode.context[binding.expression] = el.value)
    }
  })


  const SEARCH_CATEGORIES = [
    'emissions',
    'species',
    'privates',
    'businesses',
    'events'
  ];

  @Component({
    name: 'SearchModal',
    components: { Modal, DotLoader }
  })
  export default class SearchModal extends Vue {
    /*--- MODEL ---------*/
    @Model('change', { type: Boolean, required: true, default: false }) readonly open!: boolean

    /*--- PROPS ---------*/
    @Prop({ type: Number, default: 500 }) readonly delay!: number

    /*--- DATA ----------*/
    query = ''
    results: any[] | null = null
    delayHandler: ReturnType<typeof setTimeout> | null = null
    isBusy = false
    ngSearchService: any = null
    // @ts-ignore
    isMobile= !!(window.isMobile)

    /*--- WATCHERS ------*/
    @Watch('open')
    onOpenChanged(newState: unknown, oldState: unknown) {
      if (newState === oldState) return
      if (newState) {
        if (this.query.length) this.scheduleSearch()
      } else {
        this.resetOptions()
      }
    }

    @Watch('query')
    onQueryUpdate(){
      this.scheduleSearch()
    }

    /*--- EVENTS --------*/
    created() {
      this.ngSearchService = this.ngService('Search')
    }

    mounted() {
      this.injectSearchSchema()
      this.checkQueryParams()
    }

    destroyed() {
      this.clearDelay()
    }

    /*--- METHODS -------*/
    injectSearchSchema() {
      const searchSchema = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        'url': document.baseURI,
        'potentialAction': {
          '@type': 'SearchAction',
          'target': document.baseURI+'?q={search_term_string}',
          'query-input': 'required name=search_term_string'
        }
      }
      setStructuredData(searchSchema)
    }

    checkQueryParams() {
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.has('q')) {
        this.query = searchParams.get('q')!
        this.$emit('change', true)
      }
    }

    resetOptions(event?: KeyboardEvent) {
      if (this.isBusy) {
        event?.stopPropagation()
        return
      } else if (this.query.length || this.results?.length) {
        event?.stopPropagation()
        this.results = null;
        this.query = '';
        this.clearDelay()
      }
    }

    clearDelay() {
      this.delayHandler && clearInterval(this.delayHandler)
    }

    scheduleSearch() {
      this.clearDelay()
      this.delayHandler = setTimeout(this.search, this.delay)
    }

    search() {
      if (this.query === '') {
        this.results = null
        return
      } else if (!!this.ngSearchService) {
        this.isBusy = true
        this.ngSearchService
          .getGeneralSearch(encodeURIComponent(this.query), SEARCH_CATEGORIES)
          .then((data: any) => {
            this.results = data
          })
          .finally(() => {
            this.isBusy = false
          })

      }

    }

    // TODO write Type for Result
    generateSearchLink(result: any): string {
      if (result.type === "user") {
        return this.$rootScope.getLink(result.userType, "", { slug: result.slug });
      } else if (result.type === "event") {
        return this.$rootScope.getLink(result.userType, "event", {
          slug: result.userslug,
          eventslug: result.slug
        });
      } else if (result.type === 'tree') {
        return this.$state.href('species.tree', { treeId: result.slug })
      } else if (result.type === 'emission') {
        return this.$state.href('emission.type', { type: result.slug })
      }
      return "";
    }

    resultPortraitStyle(result: any): Pick<CSSStyleDeclaration, "backgroundImage"> {
      let filter: Function = getImageAsset
      let image = result.picture
      if (result.type === 'emission') {
        filter = this.$options.filters!.asset
        image = `bundles/treedomnewfrontend/images/calculator/${result.slug}.png`
      } else if (result.type === 'event') {
        filter = result.avatar ? getImageAsset : this.$options.filters!.asset
        image = result.avatar || 'bundles/treedomnewfrontend/images/event/event_avatar_default.png'
      }
      return {
        backgroundImage: `url(${filter(image)})`
      }
    }
  }
