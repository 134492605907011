import { checkTreecode } from '@f/services/Treecodes'

angular.module('ngApp')
.controller('iframeCodeController', ['$scope', '$state', '$rootScope', 'Tree', '$filter', '$timeout', function ($scope, $state, $rootScope, Tree, $filter, $timeout) {
  $scope.asset = $filter('asset')
  $scope.error = ''
  $scope.checking = false

  $scope.iframeInit = function (variables, baseUrl) {
    $rootScope.baseUrlAssets = baseUrl
    $rootScope.iframeOption = variables
  }

  $scope.checkGift = function(treecode) {
    if (!treecode) return
    window.open($state.href('treecode', {treecode}, {absolute: true}), '_blank')
  }
}])
