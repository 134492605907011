













import { defineComponent } from "@vue/composition-api";
import QuestionLayout from "../../Layout/Question/QuestionLayout.vue";
import QuestionHeader from "../../Layout/Question/QuestionHeader.vue";
import AnswerList, { Answer } from "../../Layout/Question/AnswerList.vue";

export default defineComponent({
  name: "CO2BenefitQuestion",
  components: {
    QuestionLayout,
    QuestionHeader,
    AnswerList,
  },
  emits: ["complete"],
  methods: {
    onAnswer(isCorrect: boolean) {
      this.$emit("complete", { result: isCorrect });
    },
  },
  setup: () => {
    const questionText =
        "game.question.co2";
    const answers: Answer[] = [
      {
        description: "game.question.co2.option1",
        wrong: "game.question.co2.wrong",
        explanation: {
          wrong: "game.question.co2.option1.explanation",
        },
        isCorrect: false,
      },
      {
        description: "game.question.co2.option2",
        wrong: "game.question.co2.wrong",
        explanation: {
          wrong: "game.question.co2.option2.explanation",
        },
        isCorrect: false,
      },
      {
        description: "game.question.co2.option3",
        wrong: "game.question.co2.wrong",
        explanation: {
          wrong: "game.question.co2.option3.explanation",
        },
        isCorrect: false,
      },
      {
        description: "game.question.co2.option4",
        correct: "game.question.co2.correct",
        explanation: {
          correct: "game.question.co2.option4.explanation",
        },
        isCorrect: true,
      }
    ];
    return {
      questionText,
      answers,
    };
  },
});
