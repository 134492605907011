















import { Mixins, Component } from 'vue-property-decorator'
import InputLikeComponentMixin from '@f/mixins/InputLikeComponent'
import Treecon from '@f/components/Treecons/Treecon.vue'

@Component({
  name: 'PasswordInput',
  components: { Treecon },
  inheritAttrs: false
})
export default class PasswordInput extends Mixins(InputLikeComponentMixin) {
  /*--- DATA ----------*/
  reveal = false
}
