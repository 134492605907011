export type AuthUserInfo = {
  id: number
  slug: string
  roles: string[]
  email: string
  email_verified: boolean
  locale: string
  name: string
  userType: string
  avatarURL: {
    small: string
    medium: string
    large: string
    xlarge: string
  }
  sub: string
}

export type IdentityProvider = 'google' | 'facebook' | 'apple'
export type FederatedSignInOptions = {
  identityProvider: IdentityProvider,
}
export type FederatedSignInResponse = {
  isRegistration: boolean,
}
export type SignInCredentials = {
  username: string
  password: string
}
export type SignUpParams = {
  username: string
  password: string
  attributes: {
    email: string,
    locale: string,
    firstName: string,
    lastName: string,
    allowPromotion: boolean,
    allowNewsletter: boolean,
    country?: string,
    countryCode?: string,
  }
  validationData: {
    confirmEmail: string,
    confirmPassword: string,
  }
}
export type StateChangeEvent = {
  logged: boolean
}
export type OnAuthStateChangeListener = (event: StateChangeEvent) => void
export type ListenerDisposer = () => void
export interface AuthProvider {
  getProviderName(): string
  isAuthenticated(): PromiseLike<boolean>
  getJwtToken(): PromiseLike<string | null>
  currentUserInfo(): PromiseLike<AuthUserInfo>
  federatedSignIn(options: FederatedSignInOptions): PromiseLike<FederatedSignInResponse>
  signIn(credentials: SignInCredentials, firstLoginAfterImport?: boolean): PromiseLike<void>
  impersonate(username: string): PromiseLike<void>
  signUp(params: SignUpParams): PromiseLike<void>
  forgotPassword(username: string): PromiseLike<void>
  forgotPasswordConfirm(username: string, token: string, password: string): PromiseLike<void>
  changePassword(oldPassword: string, newPassword: string): Promise<void>
  signOut(): PromiseLike<void>
  onStateChange(listener: OnAuthStateChangeListener): ListenerDisposer
}

export type TreedomAuthConfig = {
  provider: AuthProvider
}
export class TreedomAuth {
  private static provider?: AuthProvider

  static init(config: TreedomAuthConfig) {
    if (this.provider) {
      throw new Error('TreedomAuthError configuration already provided')
    }
    this.provider = config.provider
  }

  static getProviderName() {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    return this.provider.getProviderName()
  }

  static async isAuthenticated(): Promise<boolean> {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    return this.provider.isAuthenticated()
  }

  static async getJwtToken(): Promise<string | null> {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    return this.provider.getJwtToken()
  }

  static async currentUserInfo(): Promise<AuthUserInfo> {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    return this.provider.currentUserInfo()
  }

  static async federatedSignIn(options: FederatedSignInOptions): Promise<FederatedSignInResponse> {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    if (await this.isAuthenticated()) {
      throw new Error('TreedomAuthError user already signed in')
    }
    return  this.provider.federatedSignIn(options)
  }

  static async signIn(credentials: SignInCredentials,  firstLoginAfterImport?: boolean): Promise<void> {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    if (await this.isAuthenticated()) {
      throw new Error('TreedomAuthError user already signed in')
    }
    await this.provider.signIn(credentials, firstLoginAfterImport)
  }

  static async impersonate(username: string): Promise<void> {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    await this.provider.impersonate(username)
  }

  static async signUp(params: SignUpParams): Promise<void> {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    await this.provider.signUp(params)
  }

  static async signOut(): Promise<void> {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    await this.provider.signOut()
  }

  static async forgotPassword(username: string) {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    return this.provider.forgotPassword(username)
  }

  static async forgotPasswordConfirm(username: string, token: string, password: string) {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    await this.provider.forgotPasswordConfirm(username, token, password)
  }

  static async changePassword(oldPassword: string, newPassword: string) {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    await this.provider.changePassword(oldPassword, newPassword)
  }

  static onStateChange(listener: OnAuthStateChangeListener): ListenerDisposer {
    if (!this.provider) {
      throw new Error('TreedomAuthError provider not provided')
    }
    return  this.provider.onStateChange(listener)
  }
}
