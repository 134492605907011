import { DataEventAddToCartSubs, DataEventRemoveFromCartSubs } from '@f/services/TagManager/events';


angular.module('subscriptionCartService', [])
  .factory('SubscriptionCart', ['$rootScope', '$state', 'Modal', 'Ecommerce', 'TagManager', function($rootScope, $state, Modal, Ecommerce, TagManager) {

    const SubscriptionCartService = {

      get cartIsEmpty() {
        return !($rootScope.userdata.subscriptionCart && $rootScope.userdata.subscriptionCart.newPlanProductId)
      },

      choosePlan(newPlanProductId, activePlanProductId, subscriptionId, currentBillingCycle, paymentMethodId, trialUsed) {
        if (!newPlanProductId) return
        $rootScope.userdata.subscriptionCart = { newPlanProductId }
        if (!$rootScope.user) {
          $rootScope.actionAfterLogin = 'subscriptionCheckout';
          Modal.open({ templateUrl: 'login.html', easyClose: false, fitcontent: true })
        } else {
          this.subscriptionCheckout(activePlanProductId, subscriptionId, currentBillingCycle, paymentMethodId, trialUsed)
        }
      },

      clear(subscribed = false) {
        if ($rootScope.userdata.subscriptionCart) {
          const planProductId = $rootScope.userdata.subscriptionCart.newPlanProductId
          const cart = $rootScope.userdata.cart;

          if (!subscribed && planProductId) {
            Ecommerce.getProducts([planProductId], true)
              .then(response => {
                const planProduct = response[planProductId]
                if (planProduct) {
                  DataEventRemoveFromCartSubs(cart, planProduct )
                }
              })
          }
          delete($rootScope.userdata.subscriptionCart)
        }
      },

      subscribe() {
        if (this.cartIsEmpty) return
      },

      validateSubscription() {
        if (this.cartIsEmpty) return 'modalPlans.error.cartEmpty'
        if ($rootScope.userType && $rootScope.userType === "Business") {
          return { title: 'modalPlans.error.isBusiness.title', action: { label: 'modalPlans.error.isBusiness.button' } }
        }
        const { newPlanProductId, activePlanProductId } = $rootScope.userdata.subscriptionCart
        if (Number(newPlanProductId) === Number(activePlanProductId)) {
          const userType = $rootScope.userType === 'Business' ? 'organization' : 'user';
          const slug = $rootScope.userdata.info.slug
          const tab = 'subscriptions'
          const action = { label: 'edit.plans.managePlans', state: { name: 'edit', params: { userType, slug, tab } } }
          const title = 'modalPlans.error.haveThisPlan'
          return { title, action }
        }
        return false
      },

      subscriptionCheckout(activePlanProductId, subscriptionId, currentBillingCycle, paymentMethodId, trialUsed) {
        if (this.cartIsEmpty) return
        $rootScope.userdata.subscriptionCart = {
          ...$rootScope.userdata.subscriptionCart,
          activePlanProductId,
          subscriptionId,
          currentBillingCycle,
          paymentMethodId,
          trialUsed
        }

        const validationError = this.validateSubscription()
        if (!validationError) {
          const planProductId = $rootScope.userdata.subscriptionCart.newPlanProductId
          
          if (planProductId) {
            Ecommerce.getProducts([planProductId], true)
              .then(response => {
                const planProduct = response[planProductId]
                const cart = $rootScope.userdata.cart
                if( planProduct ) {
                  DataEventAddToCartSubs(cart, planProduct)
                }
              })
          }
          $state.go('subscription')
        } else {
          this.clear()
          Modal.open({ templateUrl: 'modalBasicMessage.html', data: validationError })
        }
      }
    }
    return SubscriptionCartService
  }])
