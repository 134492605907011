import { computed } from '@vue/composition-api'

type Props<T> = {
  value?: T,
}
const useModel = <T = any>(props: Props<T>, emit: (event: string, value: T | undefined) => void) => {
  return computed<T | undefined>({
    get: () => props.value,
    set: (value) => emit('input', value)
  })
}

export default useModel