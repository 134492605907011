import merge from 'lodash/merge';
import assign from 'lodash/assign';
import isUndefined from 'lodash/isUndefined';

import {
  userInitialTreesQuery,
  iframePagedTreesQuery,
  iframePagedEventTreesQuery,
  userPageTreesQuery,
  businessUserInitialTreesQuery,
  businessUserPageTreesQuery,
  eventInitialTreesQuery,
  eventPageTreesQuery
} from './GraphQLQueries/UserQueries';
import { api } from '@f/configs/app';
import tagManagerService from '@f/services/TagManager';
import { validateAccountInfoSubmitted } from '@f/validation/datalayer/user';
import { DataLayerPushVariables, DataLayerUpdateVariables } from '@f/services/TagManager/variables';
import { datadogRum } from '@datadog/browser-rum';
import { TreedomAuth } from '@f/auth';
import { cartService } from '@f/services/NewEcommerce/CartService';
import { subscriptionsService } from '@f/services/NewEcommerce/SubscriptionsService';
import { getOrigins } from "@f/utils/origins";
import treedomNextClient from '@f/apollo/clients/api/treedom-next';
import { NEXT_USER_INFO } from '@f/graphql/queries';


const { cartUri } = api;

angular.module('userService', [])
  .factory('User', ['$http', '$cookies', '$graph', 'Routing', '$rootScope', 'ipCookie', 'Cart', 'Business', 'TagManager', '$broadcastChannel', function ($http, $cookies, $graph, Routing, $rootScope, ipCookie, Cart, Business, TagManager, $broadcastChannel) {
    var userFactory = {};
    var routing = Routing;
    var authChannel = $broadcastChannel.channel('auth');

    // Flags
    userFactory.pendingGetSubscription = null;

    authChannel.onmessage = function (payload) {
      if (payload.merge) merge($rootScope, payload.merge);
      if (payload.assign !== undefined) assign($rootScope, payload.assign);
    };

    userFactory.authBroadcastUpdate = function (payload) {
      authChannel.postMessage(payload);
    };

    var routeJs = function (route, params) {
      var url = routing.route(route, params);
      if (url.indexOf('?') > -1) {
        url = url.substring(0, url.indexOf('?'));
      }
      return url;
    };

    userFactory.logout = function (slug) {
      return TreedomAuth.signOut()
        .then(function () {
          ipCookie('hasLoggedOut', true, { expires: 600, expirationUnit: 'minutes', path: '/' });
          $rootScope.loginStatus = 'disconnected';
          $rootScope.logged = false;
          $rootScope.user = false;
          $rootScope.userdata = {};
          $rootScope.userType = 'Private';
          delete $rootScope.plantIn;
          userFactory.authBroadcastUpdate({
            merge: {
              loginStatus: 'disconnected',
              logged: false,
              user: false
            },
            assign: {
              userdata: {}
            }
          });
          $rootScope.$broadcast('userdata.info', undefined);
          DataLayerPushVariables();
          datadogRum.removeUser();

          // Find cart
          Cart.fetch(true);
        });
    };

    userFactory.legacyUserInfo = function (slug) {
      return $http.get(routeJs('treedom_get_user', { slug: slug }))
        .then(function (response) {
          return response.data || response;
        });
      }

    userFactory.userInfo = async function (slug) {
      if(!(await TreedomAuth.isAuthenticated())) {
        return {data: null}
      }
      const { data } = await treedomNextClient.query(
        { query: NEXT_USER_INFO, variables: { slug } }
        );

      if(!data?.user) return {data: null}

      const {user: {createdAt, id, trees, roles, type, profile}} = data

      const mapGoogleAndFacebookData = (data) => ({
        em: data.email,
        fn: data.firstName,
        ln: data.lastName,
        db: data.birthday,
        ge: data.gender,
        country: data.country,
        externalId: data.externalId,
      })
    
      return {following: [], events: [] ,info: {
        id: Number(id),
        createdAt,
        country: profile.countryCode,
        countryCode: profile.countryCode,
        firstname: profile.firstName,
        lastname: profile.lastName,
        slug: profile.slug,
        locale: profile.language,
        numTrees: trees.totalCount,
        title: profile.displayName,
        permissions: roles.reduce((acc, curr) => ({...acc, [curr]: true}), {}),
        usertype: type === 'PRIVATE' ? 'Private' : 'Business',
        facebook_data: mapGoogleAndFacebookData(profile.datalayer.facebook),
        google_data: mapGoogleAndFacebookData(profile.datalayer.google),
        hasConfirmedMail: profile.emailConfirmed
      }}
    };

    userFactory.sessionActive = function () {
      return $http.get(routeJs('treedom_session_active'))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.user = function (slug) {
      return $http.get(routeJs('treedom_user', { slug: slug }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (data) {

          return data;
        });
    };

    userFactory.login = function (userLogin) {
      return $http.post(routeJs('treedom_new_login'), userLogin)
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (error) {
          throw (error.response) ? error.response.data || error.response : error.data || error;
        });
    };

    userFactory.loginWithId = function (userId, password) {
      return $http.post(routeJs('treedom_login_with_id', { userId: userId }), { password: password })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (error) {
          throw error;
        });
    };

    userFactory.resendConfirmationMail = function () {
      return $http.get(routeJs('treedom_regenerate_confirm_mail_token'))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () { });
    };

    userFactory.signUp = function (singUp) {
      return $http.post(routeJs('treedom_new_register'), singUp)
        .then(function (response) {
          const data = response.data || response;
          $rootScope.isNewUser = true;
          userFactory.authBroadcastUpdate({
            assign: {
              isNewUser: true
            }
          });
          return data;
        }).catch(function (error) {
          throw error;
        });
    };

    userFactory.businessSignup = function (businessData) {
      return $http.post(routeJs('treedom_new_business_signup'), businessData)
        .then(function (response) {
          const data = response.data || response;
          $rootScope.isNewUser = true;
          userFactory.authBroadcastUpdate({
            assign: {
              isNewUser: true
            }
          });
          return data;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    var cookieName = document.querySelector('html').getAttribute('data-cjname');
    var cartHeaders = function () {

      const tmpCartId = $cookies.get('cartId');


      return {
        "Authorization": $cookies.get(cookieName) ? "Bearer " + $cookies.get(cookieName) : '',
        "X-User-tmp": tmpCartId
      };
    };
    /**
     * Get braintree client token by user ID
     * @param {string} userID - Required.
     */
    userFactory.getBraintreeClientToken = function () {
      return cartService.loadClientToken()
        .then(function (response) {
          const data = response.data || response;
          $rootScope.userdata.info.clientToken = data.clientToken;
          userFactory.authBroadcastUpdate({
            merge: {
              userdata: {
                info: {
                  clientToken: response.clientToken
                }
              }
            }
          });
          return $rootScope.userdata.info.clientToken;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };


    /**
     * Get braintree client token by user ID
     * @param {string} userID - Required.
     */
    userFactory.getOldCarts = function () {
      return $http.get(cartUri + '/carts/', { headers: cartHeaders() })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Get payment methods by user ID
     * @param {string} userID - Required.
     */
    userFactory.getPaymentMethods = function () {
      return cartService.getUserPaymentMethods()
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Delete payment method by user ID
     * @param {string} userID - Required.
     * @param {string} paymentMethodID - Required.
     */
    userFactory.deletePaymentMethod = function (userID, paymentMethod) {
      const paymentMethodID = paymentMethod.token;
      return cartService.deletePaymentMethod({ paymentMethodToken: paymentMethodID })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Save payment methods from braintree by user ID
     * @param {string} userID - Required.
     * @param {string} nonce - Braintree payment method nonce. Required.
     */
    userFactory.savePaymentMethod = function (userID, nonce) {
      return $http.post(cartUri + '/payments/' + userID, { nonce: nonce }, { headers: cartHeaders() })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Create subscription
     * @param {string} userID - Required.
     * @param {string} paymentID - Required.
     * @param {string} planID - Required.
     */
    userFactory.createSubscription = function (userID, paymentID, planID) {
      return $http.post(cartUri + '/subscription', {
        userId: userID,
        paymentMethodId: paymentID,
        planId: planID
      }, { headers: cartHeaders() })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Create subscription
     * @param {string} subscriptionID - Required.
     * @param {string} userID - Required.
     */
    userFactory.cancelSubscription = function (subscriptionID) {
      return subscriptionsService().cancelSubscription({ subscriptionId: subscriptionID })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Update subscription plan
     * @param {string} subscriptionID - Required.
     * @param {string} userID - Required.
     * @param {string} planID - Required.
     */
    userFactory.updateSubscriptionPlan = function (subscriptionID, userID, planID) {
      return $http.put(cartUri + '/subscription/' + subscriptionID + '/user/' + userID, {
        planId: planID
      }, { headers: cartHeaders() })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Change payment method of subscription
     * @param {string} subscriptionID - Required.
     * @param {string} userID - Required.
     * @param {string} paymentID - Required.
     */
    userFactory.changeSubscriptionPayment = function (subscriptionID, userID, paymentID) {
      return $http.post(cartUri + '/subscription/' + subscriptionID + '/user/' + userID, { paymentMethodId: paymentID }, { headers: cartHeaders() })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    userFactory.me = function (slug, _checkout) {
      return this.userInfo(slug)
        .then(function (response) {
          const data = response.data || response;
          var userID = data.info.id;

          if (isUndefined($rootScope.userdata)) {
            $rootScope.userdata = {};
          }

          $rootScope.userdata.info = data.info;
          $rootScope.userdata.events = data.events;
          $rootScope.userdata.following = data.following;
          $rootScope.userdata.trees = {};

          if (data.info.usertype === 'Private') {
            // FIXME: avoid opening private modal during checkout. Unify profiles validation logic
            $rootScope.userdata.complete = true;
          } else if (data.info.usertype === 'Business') {
            userFactory.userEditInfo().then(fullInfo => {
              Business.profileIsComplete(fullInfo)
                .then(complete => {
                  $rootScope.userdata.complete = complete;
                });
            });
          }

          const origins = getOrigins();

          DataLayerPushVariables(data.info);
          datadogRum.onReady(() => {
            datadogRum.setUser({
              id: data.info.id,
              name: data.info.title,
              userType: data.info.usertype,
              slug: data.info.slug,
              locale: data.info.locale,
              permissions: Object.keys(data.info.permissions || {})?.join(','),
              ancestorOrigins: origins
            });
          });

          $rootScope.userType = data.info.usertype;

          Cart.attemptFetch(!_checkout, $rootScope.needMerge || (!_checkout && userID));

          $rootScope.needMerge = !!_checkout;


          $rootScope.login = false;
          userFactory.authBroadcastUpdate({
            merge: {
              login: false,
              usertype: data.info.usertype
            },
            assign: {
              userdata: $rootScope.userdata
            }
          });

          $rootScope.$broadcast('userdata.info', data.info);

          return data;
        })
        .catch(function (data) {
          return false;
        });
    };

    userFactory.fetchAndUpdateDataLayerVariables = async (slug) => {
      const response = await $http.get(routeJs('treedom_get_user_datalayer_info', { slug: slug }));
      DataLayerUpdateVariables((response.data || response).info);
      return response;
    };

    userFactory.userMail = function (slug) {
      return $http.get(routeJs('treedom_get_user_mail', { slug: slug }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.userEditInfo = function () {
      return $http.get(routeJs('treedom_user_edit'))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.userEdit = function (newData) {
      return $http.post(routeJs('treedom_user_edit'), newData)
        .then(function (response) {
          tagManagerService.push({
            event: 'accountInfoSubmitted'
          }, validateAccountInfoSubmitted);
          return response.data || response;
        });
    };

    userFactory.userEditCommunications = function (newData) {
      return $http.post(routeJs('treedom_user_edit_communications'), newData)
        .then(function (data) {
          return data.data || data;
        });
    };

    userFactory.userEditProfile = function (user) {
      return $http.post(routeJs('treedom_user_edit_profile'), user)
        .then(function (response) {
          tagManagerService.push({
            event: 'accountInfoSubmitted'
          }, validateAccountInfoSubmitted);
          return response.data || response;
        });
    };

    userFactory.cart = function (slug) {
      return $http.get(routeJs('get_cart'))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.toggleFollow = function (slugFollow) {
      return $http.post(routeJs('treedom_toggle_follow', { slug: slugFollow }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.myFollowing = function (slug) {
      return $http.get(routeJs('treedom_user_my_following', { slug: slug }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.stream = function (offset) {
      return $http.get(routeJs('treedom_stream', { offset: offset }))
        .then(function (data) {
          return data.data || data;
        });
    };

    userFactory.myFollower = function (slug) {
      return $http.get(routeJs('treedom_user_my_follower', { slug: slug }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.emissions = function (slug) {
      return $http.get(routeJs('treedom_user_get_emissions', { slug: slug }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.businessNurseryOriginatorTrees = function (id, projectId, event) {
      return $http.get(routeJs('treedom_get_user_nursery_originator', { id: id, projectId: projectId, event: event }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.searchingUser = function (something) {
      return $http.get(routeJs('treedom_searching_user', { search: something }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.checkMailForPasswordReset = function (mail) {
      return $http.post(routeJs('treedom_check_mail_user'), { mail: mail })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return false;
        });
    };

    userFactory.checkSlug = function (slug, userId) {
      return $http.post(routeJs('treedom_check_slug', { slug: slug, userId: userId }))
        .then(function (response) {
          return response.data || response;
        }).catch(function () {
          return false;
        });
    };

    userFactory.checkUsername = function (username, userId) {
      return $http.post(routeJs('treedom_check_username', { username: username, userId: userId }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return false;
        });
    };

    userFactory.checkTokenForPasswordReset = function (token) {
      return $http.get(routeJs('treedom_check_token_password', { token: token }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return false;
        });
    };

    userFactory.checkPermission = function (role) {
      if (typeof $rootScope.userdata.info === 'undefined') {
        return false;
      }

      for (var key in $rootScope.userdata.info.permissions) {
        if (key === role) return true;
      }

      return false;
    };

    userFactory.resetPassword = function (token, password) {
      return $http.post(routeJs('treedom_reset_password', { token: token }), { password: password })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return false;
        });
    };

    userFactory.editPassword = async function (passwordOld, passwordNew) {
      return TreedomAuth.changePassword(passwordOld, passwordNew)
        .then(() => ({
          success: true
        }))
        .catch((error) => ({
          error: error.message
        }));
    };

    userFactory.connectSocial = function (provider, response) {
      return $http.post(routeJs('treedom_social_login', { provider: provider }), response)
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return false;
        });
    };

    userFactory.findUsersRegistered = function (data) {
      // Oggetto post passato così, per mostrare le chiavi che deve contenere.
      return $http.post(routeJs('treedom_users_registered'), {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        facebookId: data.facebookId,
      })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    userFactory.disconnectSocial = function (type) {
      return $http.post(routeJs('treedom_disconnect_social', { type: type }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return false;
        });
    };

    userFactory.idFromSlug = function (type, slug) {
      return $http.get(routeJs('treedom_user_id_from_slug', { type: type, slug: slug }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.logoBusinessHome = function () {
      return $http.get(routeJs('treedom_GET_home_business_logo'))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.loginReferral = function (data) {
      return $http.post(routeJs('treedom_login_referral'), data)
        .then(function (response) {
          return response.data || response;
        }).catch(function (err) {
          return err.data || err;
        });
    };

    userFactory.getOrders = function (slug) {
      return $http.get(routeJs('treedom_get_business_orders', { slug: slug }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };


    userFactory.getUserStream = function (userId, offset) {
      offset = offset || 0;
      // return $http.get(routeJs('treedom_user_stream_new', {userId: userId, offset: offset}))
      // .then(function(response) {
      //   return response.data || response
      // })
      // .catch(function(err) {
      //   throw err.data || err
      // })
    };

    userFactory.getUserEventInfo = function (eventSlug) {
      return $http.get(routeJs('treedom_user_event', { eventSlug: eventSlug }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    userFactory.getUserRelationships = function (userId) {
      return $http.get(routeJs('treedom_user_follow', { userId: userId }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.getInitalsUserTrees = function (userId) {
      var gql_query = userInitialTreesQuery(userId, $rootScope.ln);
      return $graph.query(gql_query)()
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return null;
        });
    };

    userFactory.getPageUserTrees = function (userId, _filter, _cursor) {
      var gql_query = userPageTreesQuery(userId, _filter, _cursor, $rootScope.ln);
      return $graph.query(gql_query)()
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (_error) {
          return null;
        });
    };

    userFactory.getIframePagedTrees = function (userId, _cursor) {
      var gql_query = iframePagedTreesQuery(userId, _cursor, $rootScope.ln);
      return $graph.query(gql_query)()
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return null;
        });
    };

    userFactory.getIframePagedEventTrees = function (eventId, _cursor) {
      var gql_query = iframePagedEventTreesQuery(eventId, _cursor, $rootScope.ln);
      return $graph.query(gql_query)()
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (_error) {
          return null;
        });
    };

    userFactory.getInitalsBusinessUserTrees = function (userId) {
      var gql_query = businessUserInitialTreesQuery(userId, $rootScope.ln);
      return $graph.query(gql_query)()
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return null;
        });
    };

    userFactory.getPageBusinessUserTrees = function (userId, _cursor) {
      var gql_query = businessUserPageTreesQuery(userId, _cursor, $rootScope.ln);
      return $graph.query(gql_query)()
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return null;
        });
    };

    userFactory.getInitalsEventTrees = function (eventId, isRally) {
      var gql_query = eventInitialTreesQuery(eventId, (isRally ? 11 : 12), $rootScope.ln);
      return $graph.query(gql_query)()
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return null;
        });
    };

    userFactory.getPageEventTrees = function (eventId, _cursor) {
      var gql_query = eventPageTreesQuery(eventId, _cursor, $rootScope.ln);
      return $graph.query(gql_query)()
        .then(function (response) {
          return response.data || response;
        })
        .catch(function () {
          return null;
        });
    };

    userFactory.getUserEmissions = function (userId) {
      return $http.get(routeJs('treedom_user_co2_new', { userId: userId }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.getUsersByMail = function (query) {
      return $http.post(routeJs('treedom_search_users_by_mail'), { query: query })
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    userFactory.getUserEvents = function (userId) {
      return $http.get(routeJs('treedom_user_events_new', { userId: userId }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.getUserTreesInEvents = function (userId) {
      return $http.get(routeJs('treedom_user_events_trees', { userId: userId }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.disable = function (userId, reasonId, message) {
      return $http.post(routeJs('treedom_disable_user', { id: userId, reason: reasonId }), { message: message })
        .then(function (response) {
          return response.data || response;
        });
    };

    // Utilities

    userFactory.getUserWithBalancer = function (slug) {
      return $http.get(routeJs('treedom_user_balancer', { slug: slug }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Find info box data in tree page.
     * @param {string} type - One of ['Business', 'Private', 'Event']
     * @param {string} slug
     */
    userFactory.getInfoBox = function (type, slug) {
      return $http.get(routeJs('treedom_get_info_box', { type: type, slug: slug }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Get user trees (as ID list or object list) by user ID.
     * @param {string} userId
     * @param {string} filter - ['gifted', 'mine', 'liked', 'received']
     * @param {string} outputFormat - ['id', 'object']
     * @param {string} treeType - [all, planted, nursery]
     */
    userFactory.getFilteredTrees = function (userId, filter, outputFormat, treeType) {
      var data = {
        userId: userId,
        type: filter,
        idOrObject: outputFormat,
        treeType: treeType
      };
    };

    // Notifiche.

    /**
     * Trova le notifiche di un utente.
     * @param {string|number} id - ID Utente.
     */
    userFactory.getNotifications = function (id) {
      return $http.get(routeJs('new_tree_trees_notification', { id: id }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Setta le notifiche dell'utente attualmente loggato come lette.
     */
    userFactory.setReadNotifications = function () {
      return $http.post(routeJs('new_tree_set_notification'))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    // Commenti.

    /**
     * Cancella un commento sullo streaming di un albero.
     * @param {string} id - ID del commento.
     */
    userFactory.deleteComment = function (id) {
      return $http.post(routeJs('new_tree_delete_comment', { id: id }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    userFactory.newMap = function (id) {
      return $http.get(routeJs('treedom_get_new_map', { id: id }))
        .then(function (response) {
          return response.data || response;
        });
    };

    userFactory.newMapMarkerNeighbors = function (id) {
      return $http.get(routeJs('treedom_get_new_map_tree_neighbors', { id: id }))
        .then(function (response) {
          return response.data || response;
        });
    };

    // Utente business

    /**
     * Trova le informazioni di un utente business tramite il suo slug
     * @param {string} slug - Slug utente business.
     */
    userFactory.getBusinessUserInfo = function (slug) {
      return $http.get(routeJs('treedom_business', { slug: slug }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Trova gli alberi dell'utente tramite ID ed offset
     * @param {string} userId - ID utente business.
     * @param {number} offset
     */
    userFactory.getBusinessTrees = function (userId, offset) {
      offset = isUndefined(offset) ? 0 : offset;
      return $http.get(routeJs('treedom_business_trees', { userId: userId, offset: offset }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Trova la lista di utenti che hanno ottenuto alberi da un business.
     * @param {string} userId - ID utente business.
     * @param {number} offset
     */
    userFactory.getBusinessReceivers = function (userId, offset) {
      offset = isUndefined(offset) ? 0 : offset;
      return $http.get(routeJs('treedom_business_users', { userId: userId, offset: offset }))
        .then(function (response) {
          return response.data || response;
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    /**
     * Get braintree client token by user ID
     * @param {string} url - Required.
     * @param {string} type - Required.
     */
    userFactory.downloadFile = function (url, type) {
      return $http.get(url, { responseType: 'blob' })
        .then(function (response) {
          return response.data || response;
        })
        .then(function (data) {
          const file = window.URL.createObjectURL(new Blob([data], { type }));
          window.open(file, '_blank');
        })
        .catch(function (err) {
          throw err.data || err;
        });
    };

    return userFactory;
  }]);
