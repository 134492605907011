var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"treeDiaryContainer"},[_c('tree-diary-item-header',{attrs:{"picture":_vm.icon,"title":_vm.authorName,"subtitle":_vm._f("datetime")(_vm.post.createdAt),"from-tree":true}}),_vm._v(" "),_c('div',{staticClass:"treeMediaContainer"},[_c('div',{staticClass:"contentRatio"},[_c('div',{staticClass:"treeMediaContent",style:(_vm.mediaStyle)},[(_vm.statusHasPhoto)?_c('div',{staticClass:"treeButtonExpandFixed"},[_c('button',{staticClass:"btn btn-sm btn-ground",on:{"click":_vm.handleOpenModal}},[_c('i',{staticClass:"fa fa-expand",attrs:{"aria-hidden":"true"}})])]):_vm._e(),_vm._v(" "),(_vm.post.status === 'NEW')?_c('iframe',{attrs:{"src":_vm.$t('pageTree.status1.video'),"width":"100%","height":"100%","frameborder":"0","webkitallowfullscreen":"","mozallowfullscreen":"","allowfullscreen":""}}):_vm._e()])])]),_vm._v(" "),_c('tree-diary-item-share',{attrs:{"url":_vm.shareUrl},nativeOn:{"click":function($event){return _vm.$emit('triggerDataEvent', {
        action: 'share',
        element: 'statusPost',
      })}}}),_vm._v(" "),_c('div',{staticClass:"treeItemContainer no-margin"},[_c('p',{staticClass:"small no-margin"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_vm._v(" "),(['NEW', 'PLANT'].includes(this.post.status))?_c('div',[_c('h5',{staticClass:"no-margin-bottom"},[_c('img',{attrs:{"src":_vm._f("asset")('bundles/treedomnewfrontend/images/marker.png'),"height":"33"}}),_vm._v("\n        "+_vm._s(_vm.$t('pageTree.status1.Where'))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"small no-margin"},[_vm._v("\n        "+_vm._s(_vm.$t('treeNew.status.general.latitude'))+"\n        "+_vm._s(_vm._f("degrees")(_vm.coords.lat,'lat'))+"\n        "),_c('br'),_vm._v("\n        "+_vm._s(_vm.$t('treeNew.status.general.longitude'))+"\n        "+_vm._s(_vm._f("degrees")(_vm.coords.lng,'lng'))+"\n      ")])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"treeItemContainer no-margin",attrs:{"ng-if":"attrs.postType === 'stream'"}},[_c('hr',{staticClass:"no-margin-top"}),_vm._v(" "),_c('p',{staticClass:"textLight smallest no-margin"},[_c('em',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.visibilityText)}})])])]),_vm._v(" "),_c('comment-list',{attrs:{"post-id":_vm.post.id,"comments-count":_vm.post.commentsCount},on:{"saved":function($event){return _vm.$emit('triggerDataEvent', {
        action: 'saveComment',
        element: 'statusPost',
      })},"deletedComment":function($event){return _vm.$emit('triggerDataEvent', {
        action: 'deleteComment',
        element: 'statusPost',
      })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }