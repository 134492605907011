import template from './loading-template.html'
const bindings = {
  hideText: '<'
}

angular.module('ngApp')
  .component('loading', {
    template,
    bindings
  })
