









import { Vue, Component } from 'vue-property-decorator'
import Glide, { MoveInterface } from '@glidejs/glide/dist/glide.modular.esm'
import { RunEventPayloadInterface } from './types'

@Component
export default class Stepper extends Vue {

  name: string = 'Stepper'
  glide: Glide | null = null

  // Computed
  get index(): number | null {
    return this.glide && this.glide.index || 0
  }

  // Events
  // TODO: Move glide init to mixin
  mounted(): void {
    this.glide = new Glide(this.$el, {})
    this.$nextTick(() => {
      if (this.glide && this.glide.mount)  {
        this.registerEventEmitters(this.glide)
        this.glide.mount()
      }
    })
  }

  destroyed(): void {
    if (this.glide) this.glide.destroy()
  }

  // Methods
  registerEventEmitters(glide: Glide): Glide {
    return glide
      .on('mount.before', () => { this.$emit('mount-before') })
      .on('mount.after', () => { this.$emit('mount-after') })
      .on('run', (move: MoveInterface) => {
        this.$emit('run', { index: glide.index, move } as RunEventPayloadInterface)
      })
      // Step animation begins
      .on('run.before', (move: MoveInterface) => {
        this.$emit('run-before', { index: glide.index, move } as RunEventPayloadInterface)
      })
      // Step animation ends
      .on('run.after', (move: MoveInterface) => {
        this.$emit('run-after', { index: glide.index, move } as RunEventPayloadInterface)
      })
  }

  goToStep(index: number = 0): void {
    this.glide && this.glide.go("="+String(index));
  }

  prevStep(): void {
    this.glide && this.glide.go && this.glide.go('<');
  }

  nextStep(): void {
    this.glide && this.glide.go && this.glide.go('>')
  }

}
