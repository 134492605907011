import { ref, computed, watch, ComputedRef, Ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import { fetchRestUserMap } from '@f/services/User'
import { MAP_ECOMMERCE_COUNTRIES_LIST } from '@f/graphql/queries'
import { TrCountry } from '@f/@types/graphql'
import { RestUserMapTree } from '@f/@types/rest'

export function fetchPrivateMap(
  userId: Ref<number | undefined> | ComputedRef<number | undefined>,
  enabled: Ref<boolean> | ComputedRef<boolean> | boolean = true
) {
  const loading = ref(false)
  const trees = ref<RestUserMapTree[]>()
  const canFetch = computed(() => {
    return (typeof enabled === 'boolean' ? enabled : !!enabled.value)
      && !!userId.value
  })

  watch(() => canFetch.value, fetchMap, { immediate: true })
  
  function fetchMap() {
    if (!userId.value) return
    loading.value = true
    fetchRestUserMap(userId.value)
      .then(({ trees: userTrees }) => trees.value = Object.values(userTrees))
      .finally(() => loading.value = false)
  }

  return {
    loading,
    trees,
  }

}

export function fetchEcommerceCountries(enabled: Ref<boolean> | ComputedRef<boolean> | boolean = true ) {
  const { result, loading } = useQuery<{ countries: TrCountry[] }>(
    MAP_ECOMMERCE_COUNTRIES_LIST,
    {},
    () => {
      return {
        enabled: typeof enabled === 'boolean' ? enabled : !!enabled.value
      }
    }
  )
  const countries = computed(() => result.value?.countries || [])

  return {
    countries,
    loading,
  }
}
