






























import {defineComponent, onMounted, PropType, ref} from "@vue/composition-api";
import Treecon from "@f/components/Treecons/Treecon.vue";
import {translate} from "@f/services/Translation";
import LevelCompletion from "../../Components/LevelCompletion.vue";
import GameUnavailable from "../../Components/GameUnavailable.vue";
import CommonHeaderGame from "./../../Layout/CommonHeaderGame.vue";
import CommonCardGame from "../../Components/CommonCardGame.vue";

export enum GameException {
  Generic = `generic`,
  Expired = `expired`,
  Completed = `completed`,
  NotAGame = `not-a-game`,
  UserCantCompleteGame = `cant-complete-game`,
}

interface ExceptionOptions {
  correctAnswers?: number;
}

export default defineComponent({
  name: "GameExceptions",
  components: {
    CommonHeaderGame,
    Treecon,
    GameUnavailable,
    CommonCardGame,
    LevelCompletion,
  },
  props: {
    exception: {
      type: String as PropType<GameException>,
      required: true,
      default: GameException.Generic,
    },
    options: {type: Object as PropType<ExceptionOptions>},
    christmasSkin: {type: Boolean, default: false},
  },
  setup(props) {
    const exceptionData = ref();
    onMounted(() => {
      switch (props.exception) {
        case GameException.Expired:
          exceptionData.value = {
            header: {
              icon: "allert-fill",
              title: "game.expired.header.title",
              card: true,
            },
            description: "game.expired.description",
            redirectTo: "profile",
          };
          break;
        case GameException.Completed:
          exceptionData.value = {
            header: {
              icon: "success-circle-fill",
              title: translate(`game.complete.header.title`, {
                nn: props.options?.correctAnswers,
              }),
            },
            description: "game.completed.body",
            redirectTo: "profile",
          };
          break;
        case GameException.NotAGame:
          exceptionData.value = {
            header: {
              icon: "allert-fill",
              title: "There is no Game associated to this Treecode",
              card: true,
            },
            redirectTo: "home",
          };
          break;
        case GameException.UserCantCompleteGame:
          exceptionData.value = {
            header: {
              icon: "allert-fill",
              title:
                  "Soory, that's not your game! But we are glad if you learned something",
              card: true,
            },
            redirectTo: "home",
          };
          break;
        default:
          exceptionData.value = {
            header: {
              icon: "allert-fill",
              title: "Something went wrong",
              card: true,
            },
            redirectTo: "home",
          };
      }
    });

    return {
      GameException,
      exceptionData,
    };
  },
});
