







































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import BaseInput from '@f/components/Form/BaseInput.vue'
import SlideDownTransition from '@f/transitions/SlideDown'
import { HomonymyUser } from '@f/@types'

@Component({
  components: { BaseInput, SlideDownTransition }
})
export default class HomonymyItem extends Vue {
  @Prop({ type: Object, required: true }) readonly user!: HomonymyUser
  @Prop({ type: Boolean, default: false }) readonly isSelected!: boolean
  @Prop({ type: Boolean, default: false }) readonly isHidden!: boolean

  @Watch('isSelected')
  onIsSelectedChange(selected: boolean) {
    if (selected) {
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    }
  }

  password: string = ''

  get avatarStyle() {
    return {
      backgroundImage: 'url('+this.user.avatar.small+')'
    }
  }

  // Refs
  $refs!: Vue["$refs"] & {
    password: BaseInput
  }

  // Methods
  toggleSelect(event: Event): void {
    event.preventDefault()
    this.$emit('select', this.isSelected ? null : this.user)
  }

  submit(): void {
    // FIXME: needs validation
    this.$emit('submit', this.password)
  }

}
