angular.module('ngApp')
  .controller('cartBadgesController', ['$scope', '$rootScope', 'Ecommerce', '$timeout', function ($scope, $rootScope, Ecommerce, $timeout) {

    $scope.activeBadges = [];
    $scope.badges = [];
    $scope.openBadge = false;

    $scope.getBadges = function() {
      Ecommerce.getBadges().then(function(resp) {
        $scope.badges = resp.treeBadges
      }).catch(function(err) {
        console.error(err)
      })
    };

    $scope.getName = function(names) {
      return names[$rootScope.ln]
    };

    $scope.getDescription = function(descriptions) {
      return descriptions[$rootScope.ln]
    };

    $scope.isActive = function(badge) {
      return $scope.activeBadges.includes(badge.icon.small)
    };

    $scope.openSelectedBadge = function (badge) {
      $scope.openBadge = $scope.openBadge === badge ? false : badge
    };

    $timeout(function() {
      $scope.activeBadges = getBadgesList()
      if(!$scope.$$phase) $scope.$apply()
    });

    $rootScope.$on('userdata.cart', function() {
      $scope.activeBadges = getBadgesList()
      if(!$scope.$$phase) $scope.$apply()
    });

    $rootScope.$on('userdata.cart.update', function() {
      $scope.activeBadges = getBadgesList()
      if(!$scope.$$phase) $scope.$apply()
    });

    var getBadgesList = function(items, badgeSize = "small") {
      items = items || $rootScope.userdata.cart && $rootScope.userdata.cart.items || []
      if (!(items && items.length)) return []
      return items.reduce((list, item) => {
        const product = item.product || item
        const badges = product.package
          ? getBadgesList(product.package.products, badgeSize)
          : product.specie && product.specie.badges.map(badge => badge.icon && badge.icon[badgeSize]) || []
        const newBadges = badges.filter(b => !~list.indexOf(b))
        return [ ...list, ...newBadges ]
      }, [])
    };
  }]);
