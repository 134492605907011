import { Moment } from 'moment'
import { UserInfo, AppRootScope } from '@f/@types'
import {
  formatNumber,
  formatCurrency,
  formatDate,
  formatDegrees,
} from '@f/utils/formats'
import { getAsset, getImageAsset, getS3Asset } from '@f/utils/media'
import { getStaticUrl } from '@f/utils/assets'

interface FiltersMap {
  [key: string]: Function
}

function getRootscope(key: keyof AppRootScope): any {
  const rootEl = angular && angular.element(document.documentElement)
  if (rootEl) {
    return key && ((rootEl.scope().$root as AppRootScope)[key] || null) || rootEl.scope().$root
  }
  return null
}

const filters: FiltersMap = {
  number: (val: string | number, decimal = 0) => formatNumber(val, getRootscope('ln'), decimal),
  currency: (val: string | number, symbol?: string) => formatCurrency(val, getRootscope('ln'), symbol),
  date: (val: string | Moment | Date) => formatDate(val, getRootscope('ln'), 'L'),
  time: (val: string | Moment | Date) => formatDate(val, getRootscope('ln'), 'LT'),
  datetime: (val: string | Moment | Date) => formatDate(val, getRootscope('ln'), ['L', 'LT']),
  month_year: (val: string | Moment | Date) => formatDate(val, getRootscope('ln'), 'MMMM YYYY'),
  degrees: formatDegrees,

  translate: (text: string, payload: Object = {}): string => {
    switch (typeof text) {
      case 'string':
        return (window.Translator) ? window.Translator.trans(text, payload) : text
      default:
        return text[getRootscope('ln') || 'it']
    }
  },

  userUrl: (user: UserInfo): string => {
    const origin = window.location.origin
    const basePath = document.getElementsByTagName('base')[0].getAttribute('href')
    const userPath = ([
      (user.usertype === 'Private') ? 'user' : 'organization',
      user.slug
    ]).join('/')
    return `${origin}${basePath}${userPath}`
  },

  s3: getS3Asset,

  image: getImageAsset,

  asset: getAsset,

  static: getStaticUrl
  
}

export default filters;
