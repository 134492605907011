































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DotLoader from '@f/components/DotLoader.vue'
import Icon from '@f/components/Icon.vue'

@Component({
  components: {
    DotLoader,
    Icon,
  }
})
export default class StatefullButton extends Vue {
  @Prop({ type: String, required: false }) readonly icon!: string | null
  @Prop({ type: String, default: '' }) readonly label!: string
  @Prop({ type: String, default: 'Success' }) readonly labelSuccess!: string
  @Prop({ type: String, required: false }) readonly iconSuccess!: string | null
  @Prop({ type: String, default: 'Error' }) readonly labelError!: string
  @Prop({ type: String, required: false }) readonly iconError!: string | null
  @Prop({ type: Boolean, required: false }) readonly loading!: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
  @Prop({ type: Boolean, default: true }) readonly fluid!: boolean
  @Prop({ type: Number, default: 2000 }) readonly feedbackDuration!: number
  @Prop({ type: Number, default: 20000 }) readonly requestTTL!: number
  @Prop({ type: String, default: 'tr-button--accent' }) readonly baseClass!: string
  @Prop({ type: String, default: 'tr-button--success' }) readonly successClass!: string
  @Prop({ type: String, default: 'tr-button--error' }) readonly errorClass!: string

  success: boolean | null = null;
  waitingFeedback: boolean = false;
  abortTimeout: ReturnType<typeof setTimeout> | null = null;

  // Computed
  get busy(): boolean {
    return this.loading || this.waitingFeedback
  }

  get buttonClasses(): (string | Record<string, boolean>)[] {
    return [
      {
        [this.baseClass]: this.success === null,
        [this.successClass]: this.success === true,
        [this.errorClass]: this.success === false,
        'statefull-button--busy': this.busy,
        'statefull-button--fluid': this.fluid,
        'statefull-button--disabled': this.disabled,
      }
    ]
  }

  get feedbackClass(): Record<string, boolean> {
    return {
      'button-feedback--success': !!this.success,
      'button-feedback--error': !this.success,
    }
  }

  get longestFeedback(): { label: string, icon: string | null } {
    return [
      { label: this.label, icon: this.icon },
      { label: this.labelSuccess, icon: this.iconSuccess },
      { label: this.labelError, icon: this.iconError },
    ].sort((a, b) => {
      // Fixed icon pseudo width: 4
      return (b.label.length + (4 * Number(!!b.icon))) - (a.label.length + (4 * Number(!!a.icon)))
    })[0]
  }

  emitClick(): void {
    if (this.loading === undefined) {
      this.waitingFeedback = true
      if (!!this.requestTTL) this.startAbortTimeout()
    }
    this.$emit('click', this.showFeedback)
  }

  showFeedback(success: boolean | void) {
    this.waitingFeedback = false
    this.success = !!success
    if (this.abortTimeout) clearTimeout(this.abortTimeout)
    this.abortTimeout = null
    setTimeout(() => { this.success = null }, this.feedbackDuration)
  }

  startAbortTimeout(): void {
    this.abortTimeout = setTimeout(() => this.showFeedback(false), this.requestTTL)
  }
}
