












import { defineComponent, computed, ref, onMounted, watch, nextTick } from '@vue/composition-api'
import { onResize } from '@f/composables/hooks/resize'
import { closestElementWithStyle } from '@f/utils/dom/traverse'

export default defineComponent({
  name: 'Dropdown',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Number,
      default: 15,
    },
    bounded: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const container = ref<HTMLElement | Window>(window)
    const wrapper = ref<HTMLElement | null>(null)
    const dropdownStyle = computed(() => {
      if (!wrapper.value) return {}
      const { y, width } = wrapper.value.getBoundingClientRect()
      let maxWidth = '100%'
      let maxHeight = ''
      if (container.value instanceof Window) {
        maxWidth = container.value.innerWidth - props.padding * 2 + 'px'
        maxHeight = (container.value.innerHeight - y - props.padding) + 'px'
      } else {
        const containerRect = container.value.getBoundingClientRect()
        maxWidth = containerRect.width -props.padding * 2 + 'px'
        maxHeight = (containerRect.y + containerRect.height - y - props.padding) + 'px'
      }
      return {
        maxWidth,
        maxHeight,
        width: width + 'px',
      }

    })

    watch(() => props.value, open => {
      if (open) nextTick(findContainerRecursively)
    })

    function findContainerRecursively() {
      if (!(props.bounded && wrapper.value)) return
      closestElementWithStyle(wrapper.value, { overflow: 'hidden' })
        .then(({ el }) => {
          if (el) container.value = el
        })
    }

    onMounted(findContainerRecursively)
    onResize(findContainerRecursively, { throttle: 500 })
    
    return {
      container,
      dropdownStyle,
      wrapper,
    }
  }
})
