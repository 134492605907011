










































































import { Component, Mixins } from 'vue-property-decorator'
import Accordion from '@f/components/Accordion.vue'
import SocialLinks from '@f/components/Social/SocialLinks.vue'
import { footer as footerMenu } from '@f/configs/menus'
import ShowLanguages from '@f/mixins/ShowLanguages'
import ShowMenu from '@f/mixins/ShowMenu'
import UseIubendaService from '@f/mixins/UseIubendaService'
import Logo from '@f/images/logo-dynamic.svg'

@Component({
  name: "Footer",
  components: {
    Accordion,
    Logo,
    SocialLinks,
  },
})
export default class Footer extends Mixins(ShowMenu, ShowLanguages, UseIubendaService) {
  /*--- DATA ----------*/
  menuItems = footerMenu;

  /*--- METHODS ----------*/
  get currentYear(): number  {
    return new Date().getFullYear();
  }
}
