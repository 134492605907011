var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{ref:"postList",attrs:{"query":require('@f/graphql/queries/TreeWall.gql'),"variables":{ id: _vm.id, limit: this.paginationLimit }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
var query = ref.query;
return [(error)?_c('div',{staticClass:"error apollo"},[_vm._v("An error occured")]):(data)?_vm._l((_vm.paginated(data.wall.posts)),function(post){return _c(_vm.type2component(post),{key:post.id,tag:"component",attrs:{"post":post,"tree":data.tree,"id":("treediary-item-" + (post.id))},on:{"triggerDataEvent":_vm.handleDataEvent,"open-modal":_vm.openInModal}})}):_vm._e(),_vm._v(" "),(isLoading)?_c('loader',{attrs:{"show-text":true}}):(data && _vm.hasNextPage(data.wall.posts, data.wall.pageInfo))?_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-xs btn-ground",domProps:{"innerHTML":_vm._s(_vm.translate('pageTree.viewMorePosts'))},on:{"click":function($event){return _vm.fetchMore(query, data.wall.posts, data.wall.pageInfo)}}})]):_vm._e()]}}])}),_vm._v(" "),_c('LegacyModal',{attrs:{"borderless":!_vm.displayShare,"size":_vm.displayShare ? 'small' : 'large',"open":!!_vm.modalContentVariables},on:{"close":_vm.closeModal}},[_c('ApolloQuery',{ref:"modal",attrs:{"query":require('@f/graphql/queries/TreeDiaryItem.gql'),"variables":_vm.modalContentVariables,"skip":!_vm.modalContentVariables},on:{"error":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('loader',{attrs:{"show-text":false}}):(data)?_c(_vm.type2component(data.post, _vm.displayShare ? '' : 'Modal'),{tag:"component",attrs:{"post":data.post,"tree":data.tree},on:{"triggerDataEvent":_vm.handleDataEvent}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }