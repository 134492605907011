import { assign, send, ActionFunctionMap, ServiceConfig, MachineOptions, ConditionPredicate } from 'xstate'
import { checkOpenForest, redeemFromOpenForest } from '@f/services/Forest'
import { authenticate, isAuthenticated } from '@f/services/Auth'
import { OpenForestRedeemContext, OpenForestRedeemEvent } from './types'

const actions: ActionFunctionMap<OpenForestRedeemContext, OpenForestRedeemEvent> = {
  setSlug: assign({
    slug: (_context, { data }) => data
  }),
  setNoSlugError: send({ type: 'setError', payload: 'no_slug' }),
  setError: assign({
    error: (_context, { data }) => data.errorMessage || data.message || String(data)
  }),
  clearError: assign<OpenForestRedeemContext, any>({
    error: ''
  }),
  setItems: assign({
    items: (_context, { data }) => data
  }),
  pickProduct: assign({
    picked: ({ picked }, { data }) => picked === data ? null : data
  }),
  autoPick: assign({
    picked: ({ items }) => items?.[0]?.product_id || null
  }),
  setResult: assign({
    redeemed: (_context, { data }) => data.success
  })
}

const services: Record<string, ServiceConfig<OpenForestRedeemContext, OpenForestRedeemEvent>> = {
  authenticate: () => authenticate(),
  check: ({ slug }) => checkOpenForest(slug!),
  redeem: ({ slug, picked }) => redeemFromOpenForest(slug!, picked!)
}

const guards: Record<string, ConditionPredicate<OpenForestRedeemContext, OpenForestRedeemEvent>> = {
  userIsAuthenticated: ({ error }) => error !== 'user_not_logged' && isAuthenticated(),
  userIsAnonymous: ({ error }) => error === 'user_not_logged' || !isAuthenticated(),
  slugIsSet: ({ slug }) => !!slug,
  itemsAreSet: ({ items }) => !!items?.length,
  cantBePicked: ({ items }) => items?.length === 1
}

const options: Partial<MachineOptions<OpenForestRedeemContext, OpenForestRedeemEvent>> = {
  actions,
  services,
  guards,
}

export default options
