angular.module('productService', [])
.factory('Product', ['Utility', function (Utility) {
  return {
    /**
     * Generate key on each product (key is used for product details link)
     * example: www.treedom.net/species/cacao_58
     * @param {string} id - Product ID.
     * @param {string} productType - Name of productType ('tree', 'tree_limited', 'package', etc..)
     * @param {string} name - Name of product ['Cacao', 'St. Valentine 2018', 'Coupled Package', etc..]
     */
    generateKey: function (id, productType, name) {
      var key = ''
      name = name ? Utility.slugify(name) : ''

      switch (productType) {
        case 'tree':
          key = name + (name.length && "-" ) + 'tree_' + id
          break

        case 'tree_limited':
          key = name + (name.length && "-" ) + 'limited_' + id
          break

        case 'package':
          key = name + (name.length && "-" ) + 'pack_' + id
          break

        case 'plan':
          key = name + (name.length && "-" ) + 'plan_' + id
          break

        default:
          key = name + (name.length && "-" ) + 'prod_' + id
          break
      }

      return key
    },

    /**
     * Parse product key to get ID
     * example: 'tree_58_cacao' => '58' (l'ID è sempre in seconda posizione)
     * @param {string} key - Product key.
     */
    getIdFromKey: function (key) {
      return key.split('_')[1]
    }
  }
}])
