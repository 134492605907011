import { TreeWall, UserWall } from './TreeDiary'
import { TreeCo2Specie, TreeCo2Progress } from './Emissions'
import VideoPlayer from './VideoPlayer.vue'
import SignForm from './Auth/SignForm.vue'
import ProductBuyWidget from './Ecommerce/ProductBuyWidget.vue'
import SpecieHeader from './Ecommerce/SpecieHeader.vue'
import Navbar from './Navbar/Navbar.vue'
import Footer from './Footer.vue'
import TreeRegistryList from './TreeRegistryList.vue'
import TreeGiftStrip from './Gift/TreeGiftStrip.vue'
import AlertMessage from './AlertMessage.vue'
import GiftATreeBargain from './Modals/GiftATreeBargain.vue'
import IntroVideoPlayerWrapper from './_legacy/IntroVideoPlayerWrapper.vue'
import SocialLinks from './Social/SocialLinks.vue'
import ProfileCompletionForm from './Profile/ProfileCompletionForm.vue'
import PostPage from './PostPage.vue'

export default {
  Navbar,
  TreeWall,
  UserWall,
  VideoPlayer,
  TreeCo2Specie,
  TreeCo2Progress,
  SignForm,
  ProductBuyWidget,
  SpecieHeader,
  Footer,
  TreeRegistryList,
  TreeGiftStrip,
  AlertMessage,
  GiftATreeBargain,
  IntroVideoPlayerWrapper,
  SocialLinks,
  ProfileCompletionForm,
  PostPage,
}
