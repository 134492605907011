function mergePaginatedQuery(existing: { edges: any[] } = { edges: [] }, incoming: any, { variables: { prepend } }: any) {
  const incomingEdges = incoming.edges.filter((incomingEdge: any) => {
    return !existing.edges.some(existingEdge => existingEdge.__ref === incomingEdge.__ref )
  })
  return {
    ...existing,
    ...incoming,
    edges: !!prepend
      ? [
        ...incomingEdges,
        ...existing.edges,
      ]
      : [
        ...existing.edges,
        ...incomingEdges,
      ]
  }
}

export const typePolicies = {
  User: {
    keyFields: ['id'],
  },
  Tree: {
    keyFields: ['treeId'],
  },
  ProductChild: {
    keyFields: ['id', 'quantity']
  },
  TreeCodeGift: {
    keyFields: ['uuid']
  },
  // Pagination
  Query: {
    fields: {
      Tree_page: {
        keyArgs: ['userId', 'eventId', 'mine', 'gifted', 'giftable', 'liked', 'all', 'countryId', 'limit'],
        merge: mergePaginatedQuery
      },
      Tree_Diary_page: {
        keyArgs: ['id'],
        merge: mergePaginatedQuery
      },
      User_Wall_page: {
        keyArgs: ['id'],
        merge: mergePaginatedQuery
      },
      Tree_Diary_Item_Comment_page: {
        keyArgs: ['id'],
        merge: mergePaginatedQuery
      },
      Tree_Diary_Item_Comment_Reply_page: {
        keyArgs: ['id'],
        merge: mergePaginatedQuery
      },

    }
  }
}
