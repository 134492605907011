







import { Component, Mixins } from 'vue-property-decorator'
import ShowIntroVideo from '@f/mixins/ShowIntroVideo'
import EmbedYoutubeVideo from '@f/components/CMS/EmbedYoutubeVideo.vue'

@Component({
  name: 'IntroVideoPlayerWrapper',
  components: { EmbedYoutubeVideo },
})
export default class VideoPlayerWrapper extends Mixins(ShowIntroVideo) {}
