import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
.controller('tourController', ['$scope', '$timeout', 'Utility', '$rootScope', 'Modal', function ($scope, $timeout, Utility, $rootScope, Modal) {
  var $ = window.$;

  $scope.tourShow = false;
  $rootScope.selectedChapter = isUndefined($rootScope.selectedChapter) ? 1 : $rootScope.selectedChapter;
  $rootScope.selectedPage = isUndefined($rootScope.selectedPage) ? false : $rootScope.selectedPage;
  $scope.infoMenu = false;
  $scope.bookShow = false;

  // inizializzo il realTime quando ricevuto dal server la key
  $scope.$on('OpenTourBroadcast', function (response, data) {
    if ($scope.tourShow) return;

    var chapter = isUndefined(data.chapter) ? 1 : data.chapter;
    var page = isUndefined(data.page) ? false : data.page;
    $scope.action.open(chapter, page);
    Modal.open({templateUrl: 'modalTourbook.html', small: false, backgroundColor: 'transparent'})
  });

  $scope.showMenu = function (key) {
    $scope.infoMenu = key
  };

  $scope.hideMenu = function (key) {
    $('#menu_' + (key * 1 + 1)).show();
    $('#menu_' + (key * 1 + 2)).show();
    $scope.infoMenu = false
  };

  $('body').on('click', '.translation-tour-link', function () {
    var goTo = $(this).attr('data-attr');
    var goToArray = goTo.split('-');
    var chapter = goToArray[0] ? goToArray[0] * 1 : 1;
    var page = goToArray[1] ? goToArray[1] * 1 : 1;
    $scope.action.goToPage(chapter, page)
  });

  $scope.action = {
    open: function (chapter, page) {
      $rootScope.selectedChapter = chapter;
      $rootScope.selectedPage = page;
      Utility.blockScroll();
      $scope.tourShow = true;
      $scope.bookShow = true
    },
    close: function () {
      if (!$scope.bookShow) return;
      Utility.allowScroll();
      $scope.bookShow = false;
      setTimeout(function () {
        $scope.tourShow = $rootScope.selectedPage = $rootScope.selectedChapter = false;
      }, 500)
    },
    goToPage: function (chapter, page) {
      $rootScope.selectedChapter = chapter;
      $rootScope.selectedPage = page;
      $timeout(function () {
        $scope.$apply()
      })
    },
    goNext: function () {
      if ($rootScope.selectedPage === false) {
        $rootScope.selectedPage = 0
      } else {
        if (typeof $scope.tour[$rootScope.selectedChapter].chapters[1 * ($rootScope.selectedPage) + 1] !== 'undefined') {
          ++$rootScope.selectedPage
        } else {
          // qui se è indefinito
          if (typeof $scope.tour[1 * ($rootScope.selectedChapter) + 1] !== 'undefined') {
            ++$rootScope.selectedChapter;
            $rootScope.selectedPage = false
          }
        }
      }
    },
    showNext: function () {
      if (typeof $scope.tour[1 * ($rootScope.selectedChapter) + 1] !== 'undefined') {
        return true
      } else {
        if (typeof $scope.tour[$rootScope.selectedChapter].chapters[$rootScope.selectedPage + 1] !== 'undefined') {
          return true
        }
        return false
      }
    },
    goPrevious: function () {
      if (typeof $scope.tour[$rootScope.selectedChapter].chapters[1 * ($rootScope.selectedPage) - 1] !== 'undefined') {
        --$rootScope.selectedPage
      } else {
        if ($rootScope.selectedPage === 0) {
          $rootScope.selectedPage = false
        } else {
          if (typeof $scope.tour[1 * ($rootScope.selectedChapter) - 1] !== 'undefined') {
            --$rootScope.selectedChapter;
            $rootScope.selectedPage = $scope.tour[$rootScope.selectedChapter].chapters.length - 1
          }
        }
      }
    },
    showPrevious: function () {
      if (typeof $scope.tour[1 * ($rootScope.selectedChapter) - 1] !== 'undefined') {
        return true
      } else {
        // qui non ho altri capitoli prima
        if ($rootScope.selectedPage === false) {
          return false
        } else {
          return true
        }
      }
    }
  };

  // Al tasto esc si chiude la modale
  function checkKey (e) {
    e = e || window.event;
    if (e.keyCode === 27) { // escape key maps to keycode `27`
      $scope.action.close()
    }
  }

  // Controlla cosa clicco
  document.onkeydown = checkKey;

  $scope.tour = {
    1: {
      title: 'tour.1.title',
      color: 'green',
      preface: 'tour.1.preface',
      chapters: [
        {
          title: 'tour.1.page.0.title',
          image: 'bundles/treedomnewfrontend/images/tour/1-1-howto-plant.svg',
          text: 'tour.1.page.0.text'
        },
        {
          title: 'tour.1.page.1.title',
          image: 'bundles/treedomnewfrontend/images/tour/1-2-howto-what-you-get.svg',
          text: 'tour.1.page.1.text'
        },
        {
          title: 'tour.1.page.2.title',
          image: 'bundles/treedomnewfrontend/images/tour/1-3-howto-where-is-planted.svg',
          text: 'tour.1.page.2.text'
        }
      ]
    },
    2: {
      title: 'tour.2.title',
      color: 'red',
      preface: 'tour.2.preface',
      chapters: [
        {
          title: 'tour.2.page.0.title',
          image: 'bundles/treedomnewfrontend/images/tour/2-1-why-profile.svg',
          text: 'tour.2.page.0.text'
        },
        {
          title: 'tour.2.page.1.title',
          image: 'bundles/treedomnewfrontend/images/tour/2-2-why-register.svg',
          text: 'tour.2.page.1.text'
        }
      ]
    },
    3: {
      title: 'tour.3.title',
      color: 'red',
      preface: 'tour.3.preface',
      chapters: [
        {
          title: 'tour.3.page.0.title',
          image: 'bundles/treedomnewfrontend/images/tour/3-1-howto-choose.svg',
          text: 'tour.3.page.0.text'
        },
        {
          title: 'tour.3.page.1.title',
          image: 'bundles/treedomnewfrontend/images/tour/3-2-howto-play.svg',
          text: 'tour.3.page.1.text'
        },
        {
          title: 'tour.3.page.2.title',
          image: 'bundles/treedomnewfrontend/images/tour/3-3-howto-gift.svg',
          text: 'tour.3.page.2.text'
        }
      ]
    },
    4: {
      title: 'tour.4.title',
      color: 'red',
      preface: 'tour.4.preface',
      chapters: [
        {
          title: 'tour.4.page.0.title',
          image: 'bundles/treedomnewfrontend/images/tour/4-1-how-gift-works.svg',
          text: 'tour.4.page.0.text'
        },
        {
          title: 'tour.4.page.1.title',
          image: 'bundles/treedomnewfrontend/images/tour/4-2-mode-gift.svg',
          text: 'tour.4.page.1.text'
        }
      ]
    },
    5: {
      title: 'tour.5.title',
      color: 'red',
      preface: 'tour.5.preface',
      chapters: [
        {
          title: 'tour.5.page.0.title',
          image: 'bundles/treedomnewfrontend/images/tour/5-1-howto-write-messagge.svg',
          text: 'tour.5.page.0.text'
        },
        {
          title: 'tour.5.page.1.title',
          image: 'bundles/treedomnewfrontend/images/tour/5-2-howto-read-messagge.svg',
          text: 'tour.5.page.1.text'
        }
      ]
    }
  };

  // Se mobile faccio il resize dell'altezza
  // in base alla dimensione dello schermo
  if ($rootScope.hardware === 'mobile') {
    $scope.$on('ngRepeatFinished', function (ngRepeatFinishedEvent) {
      $('.fixedMenu-singleItem').click(function () {
        $('.submenu_dropdown').toggle()
      })
    })
  }
}])
