var API = require('./api');
import {ecommercesQuery, productsQuery, productsEcommerceQuery, treeBadgesQuery} from "./GraphQLQueries/EcommerceQueries";
import { ECOMMERCE_PRODUCT_PAGE } from '@f/graphql/queries'
import { DataEventNotifyMe } from '@f/services/TagManager/events';

angular.module('ecommerceService', [])
.factory('Ecommerce', ['$http', '$cookies', '$graph', '$rootScope', '$q', 'Utility', 'Modal', 'Product', 'apollo', function ($http, $cookies, $graph, $rootScope, $q, Utility, Modal, Product, apollo) {


  var sumProductPackagePrices = function(_product) {
        if(_product.package) {
             _product.package.originalPackagePrice = _product.package.products.map(function(element) {
                   return (element.price * element.quantity);
             }).reduce(function(acc, element) {
                   return acc + element
             }, 0);
      }
      }

  var resultEcommAggregator = function(_ecomms){
    var results = {};
      _ecomms.forEach(function (_ecomm) {
          results[_ecomm] = $rootScope.ecomms[_ecomm] || null
      });
      return results;
  };

  var getPname = function(_product) {
      switch (_product.type) {
          case "tree":
              return _product.specie.name['en'];
          break;
          case "tree_limited":
              return _product.limited.limitedName['en'].length ? _product.limited.limitedName['en'] : _product.limited.limitedName['it'] ;
          break;
          case "package":
              return _product.package.name['en'].length ? _product.package.name['en'] : _product.package.name['it'];
          break;
      }

      return _product.name;
  };

  var resultProdAggregator = function(_prods){
        var results = {};
        _prods.forEach(function (_prod) {
            results[_prod] = $rootScope.products[_prod] || null
        });
        return results;
    };

  return {
    /**
     * Find ecommerce by slug.
     * @param {string} ecommerceSlug - Ecommerce slug
     */
    fetch: function (ecommerceSlug) {
      return $http.get(API.CART_URL + '/ecommerce/' + ecommerceSlug)
      .then(function(ecommerce) {

        ecommerce = ecommerce.data || ecommerce
        // Attach key to each product
        ecommerce.products = ecommerce.products.map(function (product) {
          var id = product.id;
          var productType = product.type.name;
          var name = product.fields && product.fields.name ? product.fields.name['en'] : '';

          product.key = Product.generateKey(id, productType, name);
          return product
        });

        return ecommerce
      })
      .catch(function(err) {

        throw err.data || err
      })
    },

    /**
     * Find product details by ID.
     * @param {string} id - Product ID.
     */
    getProduct: function (id) {
      return $http.get(API.CART_URL + '/product/' + id)
      .then(function(product) {

        product = product.data || product
        return product
      })
      .catch(function(err) {

        throw err.data || err
      })
    },

    getEcommerces: function(_ecomms, _nofilter, name) {
      var deferred = $q.defer();
      if(! _ecomms || _ecomms.constructor !== Array){
        deferred.resolve([]);
        return;
      }
      if(!$rootScope.ecomms) $rootScope.ecomms = {};
      if(!$rootScope.products) $rootScope.products = {};
      var ecomms = [];
      _ecomms.map(function (_ecomm) {
          if(!$rootScope.ecomms[_ecomm]){
            return ecomms.push(_ecomm);
          }
      });
      if(ecomms.length > 0){
          $graph.query(ecommercesQuery(ecomms, _nofilter, $rootScope.ln))()
              .then(function (data) {
                  return data.ecommerces;
              })
              .then(function (ecommerces) {
                  ecommerces.forEach(function (ecommerce) {
                      $rootScope.ecomms[ecommerce.name || name] = ecommerce.products.map(function(_product){
                          if(!$rootScope.products[_product.id]){
                              _product.key = Product.generateKey(_product.id, _product.type, getPname(_product));
                              sumProductPackagePrices(_product);
                              $rootScope.products[_product.id] = _product;
                          }
                          return $rootScope.products[_product.id]
                      })
                  });
                  deferred.resolve(resultEcommAggregator(_ecomms))
              })
              .catch(function(_error){
                  deferred.resolve([])
              })
      }
      else{
          deferred.resolve(resultEcommAggregator(_ecomms))
      }

      return deferred.promise;
    },

    getEcommerceProducts: function(variables) {
        return apollo.query({
            query: ECOMMERCE_PRODUCT_PAGE,
            variables: variables
          }).then(({ data: { product_page: { products } } }) => {
            return products.map(_product => (
                {
                    ..._product,
                    key: Product.generateKey(_product.id, _product.type, getPname(_product))
                }))
          }).catch(() => [])
    },

      getProducts: function(_products, _nofilter) {
          var deferred = $q.defer();
          if(! _products || _products.constructor !== Array){
              deferred.resolve([]);
              return;
          }
          if(!$rootScope.products) $rootScope.products = {};
          var products = [];
          _products.forEach(function (_product) {
              if(!$rootScope.products[_product]){
                  products.push(_product)
              }
          });
          if(products.length > 0){
              $graph.query(productsQuery(products, _nofilter, $rootScope.ln))()
                  .then(function (data) {
                      return data.ecommerces[0].products;
                  })
                  .then(function (products) {
                      products.forEach(function (_product) {
                          _product.key = Product.generateKey(_product.id, _product.type, getPname(_product));
                          sumProductPackagePrices(_product);
                          $rootScope.products[_product.id] = _product
                      });
                      deferred.resolve(resultProdAggregator(_products))
                  })
                  .catch(function(_error){
                      deferred.resolve([])
                  })
          }
          else{
              deferred.resolve(resultProdAggregator(_products))
          }

          return deferred.promise;
      },

      getEcommProducts: function(_productsTypes, _ecomms, _nofilter) {
          var deferred = $q.defer();
          if(! _productsTypes || _productsTypes.constructor !== Array){
              deferred.resolve([]);
              return;
          }
          if(!$rootScope.products) $rootScope.products = {};
          $graph.query(productsEcommerceQuery(_productsTypes, _ecomms, _nofilter, $rootScope.ln))() // GUARDA QUI
              .then(function (data) {
                  return data.ecommerceProducts;
              })
              .then(function (_products) {
                  var products =_products.map(function (_product) {
                      _product.key = Product.generateKey(_product.id, _product.type, getPname(_product));
                      return $rootScope.products[_product.id] = _product
                  });
                  deferred.resolve(products)
              })
              .catch(function(_error){
                  deferred.resolve([])
              });

          return deferred.promise;
      },

      letMeKnow: function (product) {
          if (!product || !product.id) return;
          // If user is not logged in
          if (!$rootScope.user) {
              Modal.open({ templateUrl: 'login.html', easyClose: false, fitcontent: true });
              $rootScope.openAlertMeModal = { productId: product.id };
              return
          }
          var cookieName = document.querySelector('html').getAttribute('data-cjname');
          Utility.disableButton();
          $http.post(API.CART_URL + '/product/UserInterest/' + product.id,{}, {headers: { "Authorization": "Bearer "+$cookies.get(cookieName)}})
              .then(() => {
                    DataEventNotifyMe(product)
                    Utility.enableButton()
                    $rootScope.openAlertMeModal = undefined
                    Modal.open({ templateUrl: 'modalAlertMe.html' })
              })
              .catch(function() {
                  Utility.enableButton()
              })
      },


    getBadges: function () {
        return this.getBadgesRequest = this.getBadgesRequest || new Promise(function(resolve, reject) {
            const query = treeBadgesQuery($rootScope.ln);
            $graph.query(query)()
                .then(function(_response) {
                    return resolve(_response)
                })
                .catch(function(_error) {
                    return reject(_error)
                })
        })
    }
  }
}]);
