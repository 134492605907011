angular.module('menuMobileService', [])
  .service('MenuMobile', ['$rootScope', 'Utility', '$state', function ($rootScope, Utility, $state) {

    var menuFactory = {}
    var openMenu = null
    $rootScope.$on('$stateChangeStart', function () {
      openMenu = null
    })

    menuFactory.menuIsOpen = function (menu) {
      return $state.params.tab === menu
    }

    menuFactory.openMobileMenuModal = function (menu) {
      openMenu = (menu === openMenu ? null : menu)
      return true
    }

    menuFactory.forceClose = function () {
      openMenu = null
    }
    return menuFactory
  }])
