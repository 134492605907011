


















import { Vue, Component, Prop } from "vue-property-decorator"

@Component({
  name: "Loader"
})
export default class Loader extends Vue {
  @Prop({ type: String, required: false }) readonly text!: string | undefined
  @Prop({ type: Boolean, default: false }) readonly showText!: boolean
  @Prop({ type: Boolean, default: false }) readonly inline!: boolean
  @Prop({ type: Boolean, default: false }) readonly overlay!: boolean
  
  // Computed
  get loadingText(): string {
    return this.text ? this.text : this.translate('generic.loading')
  }
}
