



import {TrTree, TrTreeRowFragmentFragment} from '@f/@types/graphql'
import {computed, defineComponent, onMounted, PropType} from "@vue/composition-api";
import {getRootScope} from "@f/services/Angular";
import useRoutes from '../composables/useRoutes';

export default defineComponent({
  name: 'GiftRecapModal',
  props:{
    gift: { type: Object as PropType<[TrTreeRowFragmentFragment['gift'], String]>  },
    treeId:  String,
    tree: { type: Object as PropType<TrTree>  },
    onChange: Function  ,
  },
  setup(props) {
    const rootScope =  getRootScope();
    const routes = useRoutes();

    const isTreePage = computed( () => {
      return rootScope?.currentState.includes('user.trees.item')
    })

    const locale = computed(() => (getRootScope() || null)?.ln || 'en')
    const showTreePageCta = !isTreePage.value ? `&showTreePageCta=${!isTreePage.value}` : ''
    const iframeURL = computed(() => locale.value && props.tree &&`/${locale.value}/gift-details?treeId=${props.tree.id}${showTreePageCta}`)

    onMounted(() => {
      rootScope?.onMessage('gift-deleted', () => {
        if (props.onChange) {
          props.onChange()
        }
      })

      rootScope?.onMessage('go-to-tree-page', () => {
        routes.go('user.trees.item', {item: props.treeId})
      })
    })

    return {iframeURL}
  }
})
