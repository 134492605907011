

























import Treecon from '@f/components/Treecons/Treecon.vue'
import { formatNeutralized } from '@f/utils'
import { defineComponent } from '@vue/composition-api'
import ReadMoreModal from '@f/components/Forest/ReadMoreModal.vue'
import Modal from '@f/components/Modal.vue'
import TonneExplanation from '@f/components/Alerts/TonneExplanation.vue'

export default defineComponent({
  name: 'ImpactBox',
  components: {
    Treecon,
    TonneExplanation,
    ReadMoreModal,
    Modal,
  },
  props: {
    emissions: {
      type: Number,
      default: 0,
    },
  },
  data(): { showImpactModal: boolean } {
    return {
      showImpactModal: false,
    }
  },
  computed: {
    neutralized(): string {
      return formatNeutralized(this.emissions, this.$rootScope?.ln || 'it')
    },
  }
})
