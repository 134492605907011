import { Product } from '@f/@types';
import tagManagerService from '@f/services/TagManager';
import { validateNotifyMe } from '@f/validation/datalayer';


export function DataEventNotifyMe(product: Product) {
    tagManagerService.push({
        event: 'notifyProducts',
        productName: product.productName?.en,
        productAvailability: product.badge
    }, validateNotifyMe)
}
