import {MenuItem} from '@f/@types/navigation'
import {IsoCode} from '@f/@types/localization';

const impactChildren: MenuItem[] = [
    {
        icon: "manifesto-icon.svg",
        state: "manifesto",
        label: "nav.manifesto.title",
        description: "nav.manifesto.description",
    },
    {
        icon: "agroforestry-icon.svg",
        state: "agroforestry",
        label: "nav.agroforestry.title",
        description: "nav.agroforestry.description",
    },
    {
        icon: "howwedo-icon.svg",
        state: "projects",
        label: "nav.howWeDoIt.title",
        description: "nav.howWeDoIt.description",
    },
    {
        icon: "countries-icon.svg",
        state: "countries",
        label: "nav.countries.title",
        description: "nav.countries.description",
    }
];

export const navbar: MenuItem[] = [
    // {
    //     label: "new.navbar.plant.title",
    //     description: "nav.trees.description",
    //     children: [
    //         {
    //             icon: "tree.svg",
    //             state: "species",
    //             label: "nav.plant.title",
    //             description: "nav.plant.description",
    //         },
    //         {
    //             icon: "subscriptions.svg",
    //             state: "subscriptions",
    //             label: "nav.subscriptions.title",
    //             description: "nav.subscriptions.description",
    //         },
    //         {
    //             icon: "cloud-line.svg",
    //             state: "emission",
    //             label: "nav.co2.title",
    //             description: "nav.co2.description",
    //         },
    //         {
    //             icon: "trees.svg",
    //             state: "landing-forest",
    //             label: 'nav.forests.title',
    //             description: 'nav.forests.description',
    //         },
    //     ],
    // },
    {
        label: "new.navbar.gift.title",
        description: "new.navbar.gift.description",
        children: [
            {
                icon: "gift-icon.svg",
                state: "giftATree",
                label: "new.navbar.subGift.title",
                description: "new.navbar.subGift.description",
            },
            {
                state: "howToGift",
                label: 'nav.howToGift.title',
                description: 'nav.howToGift.description',
                icon: 'hand-heart-line.svg',
            },
        ],
    },
    {
        label: "nav.impact.title",
        description: "nav.impact.description",
        children: impactChildren,
    },
    {
        label: "nav.whoWeAre.title",
        description: "nav.whoWeAre.description",
        children: [
            {
                icon: "treedom-icon.svg",
                state: "aboutUs",
                label: "nav.whoWeAre.treedom.title",
                description: "nav.whoWeAre.treedom.description",
            },
            {
                icon: "treedomnumbers2-icon.svg",
                state: "community",
                label: "nav.whoWeAre.treedomNumber.title",
                description: "nav.whoWeAre.treedomNumber.description",
            },
            {
                icon: "team2-icon.svg",
                state: "team",
                label: "nav.whoWeAre.team.title",
                description: "nav.whoWeAre.team.description",
            },
            {
                icon: "workwithus2-icon.svg",
                state: "workwithus",
                label: "nav.whoWeAre.workwithus.title",
                description: "nav.whoWeAre.workwithus.description"
            }
        ],
    },
/*
    {
        label: 'nav.business.title',
        description: 'nav.business.description',
        children: [
            {
                state: 'business',
                label: 'nav.business.home.title',
                description: 'nav.business.home.description',
                icon: 'business-icon.svg',
            },
            {
                state: 'business.subscriptions',
                label: 'nav.business.subscriptions.title',
                description: 'nav.business.subscriptions.description',
                icon: 'trees.svg',
            },
        ],
    },
*/
]

export const footer: MenuItem[] = [
    {
        label: "new.navbar.plant.title",
        description: "nav.trees.description",
        children: [
            {
                icon: "tree.svg",
                state: "species",
                label: "nav.plant.title",
                description: "nav.plant.description",
            },
            {
                icon: "subscriptions.svg",
                state: "subscriptions",
                label: "nav.subscriptions.title",
                description: "nav.subscriptions.description",
            },
            {
                icon: "airc-line.svg",
                state: "airc-landing",
                label: "nav.airc.title",
                description: "nav.airc.description",
            },
        ],
    },
    {
        label: "new.navbar.gift.title",
        description: "new.navbar.gift.description",
        children: [
            {
                icon: "gift-icon.svg",
                state: "giftATree",
                label: "new.navbar.subGift.title",
                description: "new.navbar.subGift.description",
            },
            {
                state: "howToGift",
                label: 'nav.howToGift.title',
                description: 'nav.howToGift.description',
                icon: 'hand-heart-line.svg',
            },
        ],
    },
    {
        label: "nav.impact.title",
        description: "nav.impact.description",
        children: impactChildren,
    },
    {
        label: "nav.whoWeAre.title",
        description: "nav.whoWeAre.description",
        children: [
            {
                icon: "treedom-icon.svg",
                state: "aboutUs",
                label: "nav.whoWeAre.treedom.title",
                description: "nav.whoWeAre.treedom.description",
            },
            {
                icon: "treedomnumbers2-icon.svg",
                state: "community",
                label: "nav.whoWeAre.treedomNumber.title",
                description: "nav.whoWeAre.treedomNumber.description",
            },
            {
                icon: "team2-icon.svg",
                state: "team",
                label: "nav.whoWeAre.team.title",
                description: "nav.whoWeAre.team.description",
            },
            {
                icon: "workwithus2-icon.svg",
                state: "workwithus",
                label: "nav.whoWeAre.workwithus.title",
                description: "nav.whoWeAre.workwithus.description"
            }
        ],
    },
    {
        label: 'footerNew.customer_care.title',
        children: [
            {
                label: 'footerNew.customer_care.help',
                url: '//help.treedom.net/' + document.documentElement.lang as IsoCode || 'it',
                target: "_self",
            },
            {
                label: 'footerNew.customer_care.contacts',
                state: 'contacts',
            },
            {
                label: 'registerTrees.page.title',
                state: 'page.register',
            },
            {
                label: 'footerNew.whistleBlow',
                url: 'https://treedom.segnalazioni.net/',
                target: "_self",
            },
        ]
    }
]
