var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"forest-header-container"},[_c('div',{staticClass:"forest-header"},[_c('div',{staticClass:"back-to"},[_c('span',{on:{"click":function($event){return _vm.$emit('back')}}},[_c('Treecon',{attrs:{"icon":"arrow-simple-fill","rotate":"180"}}),_vm._v("\n        "+_vm._s(_vm.$t('forest.backToForest'))+" "+_vm._s(_vm.forestName)+"\n      ")],1)]),_vm._v(" "),_c('div',{staticClass:"forest-actions-container"},[_c('div',{staticClass:"left-section"},[_c('div',{staticClass:"avatar-container"},[_c('img',{staticClass:"avatar-image",attrs:{"src":_vm.avatarImageUrl}})]),_vm._v(" "),_c('div',{staticClass:"forest-name"},[_c('div',[_c('h4',{staticClass:"ntds-heading--4"},[_vm._v("\n              "+_vm._s(_vm.type === 'trees'
                  ? _vm.$t('forest.forestTrees')
                  : _vm.$t('forest.forestPeople'))+"\n            ")])]),_vm._v(" "),_c('span',{staticClass:"forest-created-by small"},[(_vm.count)?_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.type === 'trees'
                ? _vm.count > 1
                  ? _vm.$t('forest.forestNumbers', {
                      forestName: _vm.forestName,
                      count: _vm.formatLocalNumbers(_vm.count),
                    })
                  : _vm.$t('forest.forestNumber', {
                      forestName: _vm.forestName,
                      count: _vm.formatLocalNumbers(_vm.count),
                    })
                : _vm.count > 1
                ? _vm.$t('forest.forestPeople.count', {
                    count: _vm.formatLocalNumbers(_vm.count),
                  })
                : _vm.$t('forest.forestPerson.count', {
                    count: _vm.formatLocalNumbers(_vm.count),
                  })))]):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"right-section"},[_c('div',{staticClass:"forest-cta"},[(_vm.isCtaVisible)?_c('StatefullButton',{attrs:{"label":_vm.ctaLabel,"baseClass":"tr-button--primary","disabled":!_vm.isForestPublished},on:{"click":function($event){return _vm.$emit('goToCta')}}}):_vm._e()],1)])])]),_vm._v(" "),_vm._t("content")],2)}
var staticRenderFns = []

export { render, staticRenderFns }