import union from 'lodash/union'

angular.module('ngApp')
  .controller('partyFavorEcommerceBoxController', function ($scope, $filter, Ecommerce) {
    
    $scope.ecommerce = $scope.attrs.ecommerce;
    $scope.ecommerces = $scope.attrs.ecommerces;
    $scope.ecommerceData = [];
    
    $scope.title = $filter('trans')($scope.attrs.title);
    $scope.subTitle = $scope.attrs.subtitle ? $filter('trans')($scope.attrs.subtitle) : null;
    
    $scope.customClick = $scope.attrs.customClick === 'true';
    $scope.showQuantity = $scope.attrs.showQuantity === 'true';
    $scope.wrapperClasses = $scope.attrs.wrapperClasses;
    $scope.loading = true;

    var ecomms = $scope.ecommerces && $scope.ecommerces.length ? ($scope.ecommerces.indexOf(",") >= 0 ? $scope.ecommerces.split(",") : [$scope.ecommerces]) : [$scope.ecommerce];
    Ecommerce.getEcommerces(ecomms).then(function (ecomm) {
      $scope.loading = false;
      ecomms.forEach(function (_ecomm) {
        $scope.ecommerceData = union($scope.ecommerceData, ecomm[_ecomm])
      });
    });
  });
