






import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {MenuItem} from "@f/@types/navigation";
import ShowMenu from '@f/mixins/ShowMenu'
import persistedStateService from '@f/services/persistedStateService'
import {SubscriptionFromPosition} from "@f/validation/types/dataLayer";

const store = persistedStateService<SubscriptionFromPosition>('subscriptionEventsFromPosition', localStorage)

@Component({
  name: "SubMenu",
})
export default class SubMenu extends Mixins(ShowMenu) {
  @Prop({ type: Array, required: true, default: () => []}) readonly items!: MenuItem[];
  @Prop({ type: Number, default: 600}) readonly width!: number;


  menuMaxWidth: number | null  = 0;
  menuPosition: number = 0;
  offsetLeft: number | null = null;

  // Refs
  $refs!: Vue["$refs"] & {
    submenu: Element
  }

  mounted() {
    this.calculateOffsetLeft();
  }

  calculateOffsetLeft() {
    this.$nextTick(() => {
      if (this.$refs.submenu) {
        this.offsetLeft = this.$refs.submenu.getBoundingClientRect().left * -1;
      }
    });
  }

  handleMenuItemClick(state: string) {
    switch(state) {
      case 'subscriptions':
        store.set('menu-flow')
        break
      default:
        break
    }
  }

}
