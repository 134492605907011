










































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { DocumentNode } from 'graphql'
import { OperationVariables, gql } from '@apollo/client/core'
import { REGISTRY_SEARCH } from '@f/graphql/queries'
import { TrPageInfo } from '@f/@types/graphql'
import URLSearchParams from '@ungap/url-search-params'
import { DataEventTreeRegistry, DataEventTreeRegistrySelection } from '@f/services/TagManager/events'
import { TreeRegistryItem } from '@f/@types'
import TreeCardStatusBadge from '@f/components/Cards/contents/TreeCardStatusBadge.vue'

interface RegistryResults {
  registry: {
    trees: any[]
    pageInfo: TrPageInfo
  }
}

@Component({
  name: "TreeRegistryList",
  components: { TreeCardStatusBadge }
})
export default class TreeRegistryList extends Vue {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  @Prop({ type: Number, default: 20 }) readonly paginationLimit!: number
  
  /*--- DATA ----------*/
  visibleItems = 20
  query = REGISTRY_SEARCH
  internalTreeId: string | null = null
  internalCountryId: number | null = null
  internalUserSelected: any = null
  users: any[] = []
  userSearch = ""

  /*--- COMPUTED ------*/
  get projectsCountry(): any[] {
    return this.$rootScope?.projects || []
  }

  get treeId(): string | null {
    return this.internalTreeId || null
  }

  set treeId(val: string | null) {
    this.internalTreeId = val
    if (!!val) {
      this.clearUser()
      this.internalCountryId = null
    }
  }

  get countryId(): number | null {
    return this.internalCountryId || null
  }

  set countryId(val: number | null) {
    this.internalCountryId = val
    if (!!val) this.internalTreeId = null
  }

  get userSelected(): any | null {
    return this.internalUserSelected || null
  }

  set userSelected(val: any | null) {
    this.internalUserSelected = val
    if (!!val) this.internalTreeId = null
  }

  get variables(): any {
    return {
      treeId: this.treeId,
      countryId: this.countryId,
      userId: this.userSelected?.id
    }
  }
  
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue["$refs"] & {}
  /*--- EVENTS --------*/
  mounted(): void {
    const searchParams = new URLSearchParams(window.location.search)
    const id = searchParams.get('id') || (this.$state.params && this.$state.params.id)
    if (!!id) {
      this.internalTreeId = id
    }
    this.addUsersQuery()
  }
  
  /*--- METHODS -------*/
  addUsersQuery(): void {
    this.$apollo.addSmartQuery('users', {
      query: gql`
        query UsersSearchPublic($title: String!, $limit: Int, $type: String) {
          usersResult: User_search_public(title: $title, limit: $limit, type: $type) {
            users: edges {
              id
              title
              userType
              avatar {
                medium
              }
            }
          }
        }
      `,
      variables: () => ({ title: this.userSearch, limit: this.paginationLimit, type: 'ANY' }),
      update: ({ usersResult: { users } }) => users,
      skip: () => this.userSearch?.length < 3,
      debounce: 1000
    })
  }

  fetchMore(query: any, data: RegistryResults) {
    if (!data?.registry) return
    const { trees: cachedList, pageInfo } = data.registry
    this.visibleItems += this.paginationLimit
    if (this.visibleItems > cachedList.length && pageInfo.hasNextPage) {
      query.fetchMore({
        variables: {
          ...this.variables,
          after: pageInfo.endCursor,
        },
        updateQuery: (previousResult: any, { fetchMoreResult: { registry } }: any) => {
          return {
            registry: {
              trees: [...previousResult.registry.trees, ...registry.trees],
              pageInfo: { ...registry.pageInfo },
              __typename: registry.__typename
            }
          }
        }
      })
    }
  }

  paginated(data?: RegistryResults): any[] {
    if (!data?.registry?.trees) return []
    return data.registry.trees.slice(0, this.visibleItems)
  }

  hasNextPage(data?: RegistryResults): boolean {
    if (!data?.registry) return false
    return !!(this.visibleItems < data.registry.trees?.length || data.registry.pageInfo?.hasNextPage)
  }

  clearUser(): void {
    this.internalUserSelected = null
    this.userSearch = ""
    this.users = []
  }

  searched() {
    const selectedCountry = this.projectsCountry.find(x => x.id === this.countryId)?.name || null;
    DataEventTreeRegistry(this.treeId, selectedCountry, this.userSelected?.id || null);
  }

  clickedTree(tree: TreeRegistryItem) {
    DataEventTreeRegistrySelection(tree.treeId, tree.specie.name.en!, tree.user.id)
  }

}
