window.$ = window.jQuery = require('jquery')
window.viewportSize = require('viewport-size')
window.mapboxgl = require('mapbox-gl/dist/mapbox-gl.js')
window.CountUp = require('countup.js')
window.SunCalc = require('suncalc')
window.swipe = require('angular-swipe')
window.moment = require('moment')
require('moment/locale/it')
require('moment/locale/en-gb')
require('moment/locale/de')
require('moment/locale/fr')
require('moment/locale/es')
window.QRCode= require('qrcode')
require('bootstrap')
require('jquery-ui/ui/effect')
require('jquery-ui/ui/widget')
require('jquery-ui/ui/version')
require('jquery-ui/ui/scroll-parent')
require('jquery-ui/ui/safe-blur')
require('jquery-ui/ui/safe-active-element')
require('jquery-ui/ui/plugin')
require('jquery-ui/ui/data')
require('jquery-ui/ui/widgets/mouse')
require('jquery-ui/ui/widgets/draggable')
require('jquery-ui/ui/widgets/droppable')
require('jquery-file-download')
require('jquery-datetimepicker')
require('jquery-colpick')
require('qr-image')
require('bootstrap-switch')
require('leaflet')
require('leaflet.label')
require('leaflet.markercluster')
// require('angular')
require('jquery-touchswipe')
require('@uirouter/angularjs')
/* FIX: UI-Router events polyfill */
require('@uirouter/angularjs/release/stateEvents')
require('angular-animate')
require('angular-cookie')
require('angular-cookies')
require('angular-touch')
require('angular-file-upload')
require('angular-scroll')
require('angular-youtube-embed')
require('angular-socialshare')
require('angulargrid')
require('angular-chart.js')
require('angularjs-slider')
require('./lib/jquery.booklet.latest.min.js')
require('./lib/jquery.easing.1.3.js')
require('countup.js-angular1')
require('ng-infinite-scroll')
require('ui-select')
require('angular-svg-round-progressbar')
require('angular-ui-bootstrap')
require('angular-leaflet-directive')
require('v-accordion')
