import { DirectiveOptions } from 'vue'
import { closestElement } from '@f/utils/dom/traverse'

function isClipping(container: HTMLElement, element: HTMLElement, axis: 'x' | 'y' = 'y') {
  const { position } = window.getComputedStyle(container)
  const outerRect = container.getBoundingClientRect()
  const innerRect = element.getBoundingClientRect()
  const size = axis === 'x' ? 'width' : 'height'
  const upperOffset = Math.min(0, innerRect[axis] - outerRect[axis])
  const lowerOffset = Math.max(
    0,
    (innerRect[axis] + innerRect[size]) - (outerRect[axis] + outerRect[size])
  )
  const offset = upperOffset || lowerOffset
  if (offset) return offset
  // Exit recursive promise
  if (position === 'fixed' || position === 'absolute') throw Error()
}

export default {
  componentUpdated: (el, binding) => {
    if (!binding.value) return
    closestElement(el, ancestor => isClipping(ancestor, el))
      .then(({ el: container, result: offset }) => {
        if (container && offset) {
          container.scrollTop += offset
        }
      })
      // Handle fast exit catch
      .catch(() => {})
  }
} as DirectiveOptions
