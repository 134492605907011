type StructuredDataValidTypes = string | string[] | number | number[] | boolean | boolean[]

interface StructuredDataValue {
  '@type': string;
  [key: string]: StructuredDataValidTypes | StructuredDataValue;
}

interface StructuredData extends StructuredDataValue {
  '@context': string;
}

function getElementOfType(type: string): HTMLElement | void {
  const matchRegExp = new RegExp(`@type.*${type.toLowerCase()}`)
  return Array.from(document.getElementsByTagName('script'))
    .find(e => {
      return e.type === 'application/ld+json'
        && e.innerHTML.toLowerCase().match(matchRegExp)
    })
}

function getElementWithData(data: StructuredData): HTMLElement | void {
  return Array.from(document.getElementsByTagName('script'))
    .find(e => {
      return e.type === 'application/ld+json'
        && JSON.stringify(JSON.parse(e.innerHTML)) === JSON.stringify(data)
    })
}

function appendNewElement(): HTMLElement {
  const scriptEl = document.createElement('script')
  scriptEl.type = 'application/ld+json'
  document.head.append(scriptEl)
  return scriptEl
}

export function setStructuredData(data?: StructuredData): void {
  let dataType
  if (!isStructuredData(data) || !(dataType = data['@type'])) return
  const el = getElementOfType(dataType) || appendNewElement()
  el.innerHTML = JSON.stringify(data, null, '  ')
}


export function getStructuredData(type: string): StructuredData | void {
  const el = getElementOfType(type)
  const data = el && JSON.parse(el.innerHTML)
  return isStructuredData(data) && data || undefined
}

export function clearStructuredData(data: StructuredData): void {
  let existingElement = getElementWithData(data)
  if (existingElement) {
    existingElement.parentNode?.removeChild(existingElement)
  }
}

// Type Guard
function isStructuredData(val: unknown): val is StructuredData {
  return val
    && typeof val === 'object'
    && val.hasOwnProperty('@context')
    && val.hasOwnProperty('@type')
}
