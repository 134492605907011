import ajv from '@f/validation/ajv'
import * as QuizSchema from '@f/validation/schema/datalayer/quiz'
import { IQuizAction, IQuizBenefitChoice, IQuizCo2Choice, IQuizCountryChoice, IQuizDone, IQuizMeaningChoice, IQuizTreeChoice } from '@f/validation/types/dataLayer'

export const validateQuizStart = ajv.compile<IQuizAction>(QuizSchema.QuizStartSchema)
export const validateQuizCountryChoice = ajv.compile<IQuizCountryChoice>(QuizSchema.QuizCountryChoiceSchema)
export const validateQuizBenefitChoice = ajv.compile<IQuizBenefitChoice>(QuizSchema.QuizBenefitChoiceSchema)
export const validateQuizCo2Choice = ajv.compile<IQuizCo2Choice>(QuizSchema.QuizCo2ChoiceSchema)
export const validateQuizMeaningChoice = ajv.compile<IQuizMeaningChoice>(QuizSchema.QuizMeaningChoiceSchema)
export const validateQuizTreeChoice = ajv.compile<IQuizTreeChoice>(QuizSchema.QuizTreeChoiceSchema)
export const validateQuizDone = ajv.compile<IQuizDone>(QuizSchema.QuizDoneSchema)