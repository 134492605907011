






































import {Component, Mixins} from "vue-property-decorator";
import MobileSidebar from "@f/components/Navbar/partials/MobileSidebar.vue";
import Overlay from '@f/components/Overlay.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import {UserMixin} from '@f/mixins'
import {DataEventRansomStart} from '@f/services/TagManager/events/ransom'
import {abTestEcommerceEntrypoint} from "@f/services/ecommerceEntrypointService";

@Component({
  name: "Toolbar",
  methods: {
    abTestEcommerceEntrypoint() {
      return abTestEcommerceEntrypoint
    }
  },
  components: {
    Overlay,
    MobileSidebar,
    Treecon,
  },
})
export default class Toolbar extends Mixins(UserMixin) {
  /*--- DATA ----------*/
  open = false;
  showLanguageSelector = false;

  dispatchRansomStartEvent = (treecode: string) => {
    DataEventRansomStart({
      logged: this.$rootScope.logged!,
      ransomCode: treecode,
      position: 'treecode-toolbar',
      giftType: 'link'
    })
  }

  async handleTreecodeClick() {
    this.$state.go('treecodeLanding')
  }

}
