import { appCookies } from '@f/configs/app'
import { ITranslation, IsoCode } from '@f/@types/localization'

export function getTranslator() {
  return window.Translator || undefined
}

export function getLanguage() {
  return (document.documentElement.lang
    || appCookies.language
    || 'it') as IsoCode
}

export function resolvePluralizationChoice(pluralChoice: number | string, payload?: Record<string, any> | string) {
  if (typeof pluralChoice === 'number') return pluralChoice

  if (!payload || typeof payload === 'string' || !(pluralChoice in payload)) return 0

  return Number(payload[pluralChoice])
}

export function translate(text?: string | ITranslation, payload?: Record<string, any> | string, pluralChoice?: number | string): string {
  if (!text) return ''

  if (typeof text === 'string' && !!getTranslator()) {
    return pluralChoice !== undefined
      ? getTranslator().transChoice(text, resolvePluralizationChoice(pluralChoice, payload), payload || {})
      : getTranslator().trans(text, payload || {})
  }

  if (typeof text === 'object') {
    const lang = getLanguage()
    if (!(lang in text)) return ''

    return !!payload && !(typeof payload === 'object') && (payload in text[lang])
      ? text[lang][payload]
      : text[lang]
  }

  return text
}

export function loadTranslations(resource: string, lang: IsoCode) {
  return fetch(resource)
    .then((response) => response.json())
    .then((translations) => {
      Object.entries(translations).forEach(([key, value]) => {
        Translator.add(key, value as string, 'messages', lang)
      })
    })
}