




































import { Vue, Component, Prop } from "vue-property-decorator"
import Loader from "@f/components/Loader.vue";
import { TREE_DIARY_COMMENT_DELETE, TREE_DIARY_COMMENT_REPLY_DELETE } from "@f/graphql/mutations";
import { TrTreeDiaryComment, TrTreeDiaryCommentReply } from '@f/@types/graphql'

@Component({
  name: "Comment",
  components: { Loader }
})
export default class Comment extends Vue {
  @Prop({ type: Object, required: true }) readonly comment!: TrTreeDiaryComment | TrTreeDiaryCommentReply
  
  // Computed
  get isReply() {
    return 'parentId' in this.comment
  }

  get deleteMutation() {
    return (this.isReply) ? TREE_DIARY_COMMENT_REPLY_DELETE : TREE_DIARY_COMMENT_DELETE
  }

  get repliesLabel(): string {
    if (this.isReply || !('repliesCount' in this.comment) || !this.comment.repliesCount) return this.translate('pageTree.reply.cta')
    const repliesLabel = this.translate((this.comment.repliesCount === 1) ? 'pageTree.replyOne' : 'pageTree.replyMore')
    return `<i class="fa fa-reply" aria-hidden="true"></i> ${this.comment.repliesCount || 1} ${repliesLabel}`
  }

  get commentDate(): string {
    return ((this.isReply) ? '' : ' · ') + this.$options.filters!.datetime(this.comment.createdAt)
  }

  // Methods
  userUrl(user: any): string {
    return (user) ? this.parseState(
      user.userType === 'Private' ? 'user' : 'organization',
      { slug:  user.slug }
    ) : ''
  }

  userIsAuthor(user: any): boolean {
    return user && user.slug === this.$rootScope.user
  }

  repliesText(comment: any): string {
    const repliesLabel = this.translate((comment.repliesCount === 1) ? 'pageTree.replyOne' : 'pageTree.replyMore')
    return `${comment.repliesCount || 1} ${repliesLabel}`
  }

  confirmDelete(mutation: any) {
    // TODO: implements delete confirmation
    mutation()
  }

  emitToggleChildren(): void {
    this.$emit('toggle-children', this.comment.id)
  }

  emitDeleted(store: any, { data }: { data: any }): void {
    this.$emit('deleted', store, data.comment || data.reply, { ...this.comment })
  }

  emitError(store: any, response: any): void {
    this.$emit('error', store, response)
  }
}
