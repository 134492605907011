















































































































































import {Component, Vue} from 'vue-property-decorator'
import {navbar as navbarMenu} from '@f/configs/menus'
import SearchModal from '@f/components/Modals/SearchModal.vue'
import EmailConfirm from '@f/components/Alerts/EmailConfirm.vue'
import Icon from '@f/components/Icon.vue'
import Logo from '@f/images/logo-dynamic.svg'
import {getBlogUrl} from '@f/services/Blog'
import {CartWidgetNew, LanguageSelector, Sidebar, Submenu, Toolbar, TreecodeWidget, UserWidget,} from './partials'

import CurrencySelector from './partials/CurrencySelector.vue'
import {getRootScope} from '@f/services/Angular'
import {translate} from "@f/services/Translation";
import PlantMenu from "@f/components/Navbar/partials/PlantMenu.vue";
import WhoWeAreMenu from "@f/components/Navbar/partials/WhoWeAreMenu.vue";
import BusinessMenu from "@f/components/Navbar/partials/BusinessMenu.vue";
import Overlay from "@f/components/Overlay.vue";
import MobileSidebar from "@f/components/Navbar/partials/MobileSidebar.vue";
import Treecon from "@f/components/Treecons/Treecon.vue";

type NavbarTemplate = 'default' | 'empty' | 'modal'

@Component({
  name: 'Navbar',
    methods: {translate},
  components: {
    Treecon, MobileSidebar, Overlay,
    BusinessMenu,
    WhoWeAreMenu,
    PlantMenu,
    Submenu,
    UserWidget,
    LanguageSelector,
    TreecodeWidget,
    Toolbar,
    Sidebar,
    SearchModal,
    Icon,
    Logo,
    CartWidgetNew,
    EmailConfirm,
    CurrencySelector
  },
})
export default class NavBar extends Vue {
  // Data
  sidebarOpen = false
  searchModalOpen = false
  activeMenu: number | null = null
  menuItems = navbarMenu
  internalNavbarHeight = 0
  template: NavbarTemplate = 'default'
  ngStateParamsService: any = null
  hideToolbar = false


  // Refs
  $refs!: Vue['$refs'] & {
    navbar: Element
  }

  get navbarHeight(): string {
    return !!this.internalNavbarHeight
      ? this.internalNavbarHeight + 'px'
      : 'auto'
  }

  get blogUrl(): string {
    return getBlogUrl(this.$rootScope.ln)
  }
  // Events

  created() {
    this.template = !!this.$rootScope.hideNavbar ? 'empty' : 'default'
    this.ngStateParamsService = this.ngService('$stateParams')
    const rootScope = getRootScope()
    this.hideToolbar = this.ngStateParamsService?.hideToolbar
    if (rootScope) {
      rootScope.$on('$stateChangeSuccess', () => {
        this.hideToolbar = this.ngStateParamsService?.hideToolbar
      })
    }
    window.addEventListener('resize', this.updateNavbarHeight)
  }

  destroyed() {
    window.removeEventListener('resize', this.updateNavbarHeight)
  }

  mounted() {
    this.$nextTick(() => {
      this.updateNavbarHeight()
    })
  }

  updateNavbarHeight() {
    this.internalNavbarHeight = Object.values(
      this.$refs.navbar?.children
    ).reduce((total: number, i: Element) => {
      return total + i.clientHeight
    }, 0)
  }
}
