import tagManagerService from '@f/services/TagManager'
import { validateOpenNotification } from '@f/validation/datalayer'

export function DataEventOpenNotificationPanel() {
  tagManagerService.push({
    event: 'openNotification',
  })
}

export function DataEventOpenNotification(
  notificationType: string,
  notificationId: number
) {
  tagManagerService.push(
    {
      event: 'notification',
      notificationType,
      notificationId,
    },
    validateOpenNotification
  )
}
