import Swiper from 'swiper'

angular.module('ngApp')
  .controller('ecommerceHoroscopeController', ['$scope', '$rootScope', '$timeout', 'Horoscope', function ($scope, $rootScope, $timeout, Horoscope) {
    $scope.ecommerceHoroscope = null;
    $scope.ecommerceHoroscopeLoading = true;

    $scope.trees = [];
    $scope.horoscopeSlider = null;

    $scope.horoscope = Horoscope.signs

    Horoscope.getHoroscope().then(function(horoscope) {
          $scope.ecommerceHoroscopeLoading = false;
          $scope.ecommerceHoroscope = horoscope;
          $scope.$broadcast('hasHoroscopeSpecies', true);
          $timeout(function(){
              renderHoroscopeSwiper();
          })

      });

    // Trova l'oroscopo attivo
    var findActiveSign = Horoscope.findActiveSign()

    // Swiper horoscope
    var renderHoroscopeSwiper = function () {
      if ($scope.horoscopeSlider) {
        !Array.isArray($scope.horoscopeSlider)
          ? $scope.horoscopeSlider.destroy(true, true)
          : $scope.horoscopeSlider.forEach(function (s) { s.destroy(true, true) })
      }
      var initSlide = (
        $rootScope.sliderHistory
        && $rootScope.sliderHistory.ecommerce === 'treedom_horoscope'
      )
      ? $scope.ecommerceHoroscope.findIndex(product => {
          return product.id == $rootScope.sliderHistory.productId
      })
      : 0;
      $scope.horoscopeSlider = new Swiper('#SwiperHoroscope', {
        slidesPerView: 3,
        // centeredSlides: true,
        spaceBetween: 30,
        slidesPerGroup: 3,
        nextButton: '#HoroscopeButtonNextSlider',
        prevButton: '#HoroscopeButtonPrevSlider',
        pagination: '#HoroscopeSliderPagination',
        paginationClickable: true,
        grabCursor: true,
        initialSlide: initSlide,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
            centeredSlides: true
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
            slidesPerGroup: 1,
            centeredSlides: true
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 30,
            slidesPerGroup: 1,
            centeredSlides: true
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 30,
            slidesPerGroup: 1,
            centeredSlides: true
          }
        }
      })
    }
  }]);
