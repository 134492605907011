angular.module('ngApp')
.controller('modalNotificationsController', ['$scope', '$rootScope', 'User', function ($scope, $rootScope, User) {
  if ($rootScope.notifications && $rootScope.notifications.toRead) {
    User.setReadNotifications()
    .then(function () {
      $rootScope.notifications.toRead = 0
      $rootScope.notifications.list = $rootScope.notifications.list.map(function (n) {
        n.notify = 0
        return n
      })
    })
  }
}])
