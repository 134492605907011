









































































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { OrganizationUserData } from '@f/composables/fetchUserData'
import Treecon from '@f/components/Treecons/Treecon.vue'
import Modal from '@f/components/Modal.vue'
import Icon from '@f/components/Icon.vue'
import Logo from '@f/images/logo-dynamic.svg'
import { getRootScope } from '@f/services/Angular'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'

export default defineComponent({
  name: 'OrganizationCommitmentBadge',
  props: {
    user: {
      required: true,
      type: Object as PropType<OrganizationUserData>
    },
    committedSince: {
      required: true,
      type: Date as PropType<Date | null>
    },
    open: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    Icon,
    Modal,
    Treecon,
    Logo,
    TreedomCommitmentStamp: () => import( /* webpackChunkName: "treedom-stamp.svg" */ '@f/images/commitment/TreedomStamp.svg'),
    TreedomCommitmentStampFull: () => import( /* webpackChunkName: "treedom-stamp.svg" */ '@f/images/commitment/TreedomStampFull.svg'),
    TreedomCommitmentStampMobile: () => import( /* webpackChunkName: "treedom-stamp.svg" */ '@f/images/commitment/TreedomStampFullMobile.svg'),
    CommitmentStar: () => import( /* webpackChunkName: "gift-colors.svg" */ '@f/images/commitment/CommitmentStar.svg')
  },

  setup(props, { emit }) {
    const mobileQuery = maxWidth('md')
    const isMobile = useMediaQuery(mobileQuery)
    const lang = (getRootScope() || null)?.ln || 'it'
    const formatter = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' })

    const formattedCommittedSince = computed(() => {
      const date = props.committedSince ?? new Date()

      return formatter.format(date)
    })

    const close = () => emit('close')

    return {
      formattedCommittedSince,
      isMobile,
      close,
    }
  }
})
