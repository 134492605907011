
























import { defineComponent } from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'
import ImpactBox from '@f/components/Forest/ImpactBox.vue'
import Modal from '@f/components/Modal.vue'
import ReadMoreModal from '@f/components/Forest/ReadMoreModal.vue'
import ImpactOverview from '@f/components/Emissions/ImpactOverview.vue'

export default defineComponent({
  name: 'EmissionsSection',
  components: {
    Treecon,
    ImpactBox,
    Modal,
    ReadMoreModal,
    ImpactOverview
  },
  props: {
    emissions: {
      type: Number,
      default: 0,
    },
  },
  data(): {
    showImpactReadMore: boolean
  } {
    return {
      showImpactReadMore: false,
    }
  },
})
