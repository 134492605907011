






































































import {defineComponent, PropType, ref, computed} from '@vue/composition-api'
import {PrivateUserData} from '@f/composables/fetchUserData'
import Treecon from '@f/components/Treecons/Treecon.vue'
import Modal from '@f/components/Modal.vue'
import useAuthentication from '@f/composables/useAuthentication'
import useRoutes from '@f/composables/useRoutes'
import { getRootScope } from '@f/services/Angular'

export default defineComponent({
  name: 'UserSubscriberBadge',
  props: {
    user: {
      required: true,
      type: Object as PropType<PrivateUserData>,
    },
  },
  components: {
    Modal,
    Treecon,
    SubscriberStamp: () =>
      import(
        /* webpackChunkName: "treedom-b2b-stamp.svg" */ '@f/images/b2cSubscribers/UserSubscriberStar.svg'
      ),
    SubscriberStampFull: () =>
      import(
        /* webpackChunkName: "treedom-b2b-full-stamp.svg" */ '@f/images/b2cSubscribers/UserSubscriberFullStamp.svg'
      ),
    SubscriberStar: () =>
      import(
        /* webpackChunkName: "treedom-b2b-star.svg" */ '@f/images/b2cSubscribers/SubscriberStar.svg'
      ),
  },

  setup(props) {
    const subscriberModal = ref(false)
    const { isLoggedAs } = useAuthentication()
    const { go } = useRoutes()
    const rootScope = getRootScope()

    const subscriberSince = computed(() =>
      props.user.activeSubscriptionStartDate
        ? new Date(props.user.activeSubscriptionStartDate).toLocaleDateString(
            rootScope?.ln,
            { year: 'numeric', month: 'long' }
          )
        : null
    )

    const onDiscoverMoreClick = () => {
      if(isLoggedAs(props.user.info)) {
        return go('settings.certificate')
      }
        subscriberModal.value = true
    }

    const onGoToSubscriptionsClick = () => {
      if (isLoggedAs(props.user.info)) {
        return go('settings.subscription')
      }
      go('subscriptions-switch')
    }

    return {
      subscriberModal,
      onDiscoverMoreClick,
      onGoToSubscriptionsClick,
      isLoggedAs,
      subscriberSince,
    }
  },
})
