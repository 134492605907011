// import defer from 'lodash/defer'
import isUndefined from 'lodash/isUndefined'
import { emailConfirmQueryParam } from '@f/configs/app'

angular.module('ngApp')
.controller('appController', ['Routing', '$scope', '$rootScope', 'Utility', 'Modal', 'ipCookie', '$state', '$interval', 'User', '$filter', '$timeout', '$document', '$compile', '$location', 'MenuMobile', 'ScrollService', function (Routing, $scope, $rootScope, Utility, Modal, ipCookie, $state, $interval, User, $filter, $timeout, $document, $compile, $location, MenuMobile, ScrollService) {
  var $ = window.$;
  var treeSchedeModalIsOpen = false;
  var getVars = $location.search();
  $rootScope.absoluteUrl = $location.$$absUrl;
  $rootScope.userdata = {};
  $rootScope.login = false;
  $rootScope.iframe = false;
  $rootScope.currentState = $state.current.name;
  $rootScope.modalGiftInActionShowed = false;
  $scope.caching = false;
  $rootScope.modules = { 'badge': false };
  $rootScope.forceCloseMobileMenu = function () {
    MenuMobile.forceClose()
  }

  $rootScope.getUrl = function (state, stateParams) {
    return $state.href(state, stateParams)
  }

  $rootScope.showEmailConfirmAlert = RegExp("\\b"+emailConfirmQueryParam+"\\b").test(window.location.search)

  const userInfoListener = $scope.$on('userdata.info', (_event, userInfo) => {
    $rootScope.showEmailConfirmAlert = $rootScope.showEmailConfirmAlert
      || (userInfo && !userInfo.hasConfirmedMail)

    if (userInfo) userInfoListener()
  })


  $scope.switchImageBucket = function () {
    window.public_bucket = $rootScope.public_bucket = $rootScope.public_bucket === 'dev-www-treedom-net' ? 'www-treedom-net' : 'dev-www-treedom-net';
    $state.reload()
  };

  $rootScope.getUrlVars = function () {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value
    });
    return vars
  };
  //  funzione che rinnova la sessione chiamata ogni 60 secondi
  $interval(function (data) {
    if ($rootScope.user) {
      $rootScope.loggingOut = true;
      if ($rootScope.loadingCount === 0) {
        User.sessionActive()
      }
    }
  }, 300000);

  $rootScope.logout = function (redirect = true) {
    ipCookie.remove($rootScope.session_global_url, { path: '/', domain: $rootScope.session_global_url });
    return User.logout()
    .then(function() {
      redirect && $state.go('index')
    })
  };

  $rootScope.reloadTranslations = function () {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://trans.treedom.net/api/v1/translation.js?locales=' + $rootScope.ln;
    head.appendChild(script);
    $state.reload()
  };

  $scope.initApp = function (user, ln, baseUrl, baseUrlAsset, hardware, environment, state, params, comingFromPayment, version) {
    if (state) Utility.redirectAfterLogin(state, params);

    var protocol = window.location.protocol;
    var host = window.location.host;
    $rootScope.baseHtml5Url = protocol + '//' + host + baseUrl;
    $rootScope.hardware = hardware;
    $rootScope.environment = environment;
    $rootScope.comingFromPayment = comingFromPayment;
    $rootScope.version = version
    $scope.bodyClass = {
      'tr-body': true
    }


    ScrollService.init()

    // UA classes
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    const instagramMatch = ua.match(/(instagram)\s+([\d.]+)/i)
    if (instagramMatch) {
      const instagramClass = `tr-body--${instagramMatch[1].toLowerCase()}--${instagramMatch[2].replaceAll('.', '_')}`
      $scope.bodyClass[instagramClass] = true
    }


    if (!$rootScope.isFeatureActive('giftflow') && !ipCookie('bootgift')) {
      const alertListener = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        const avoidedStates = [
          '^treecode',
          '^checkout',
        ]
        const avoid = !toState.name || avoidedStates.some(check => {
          return toState.name.match(RegExp(check))
        })
        if (!avoid) {
          alertListener && alertListener()
          ipCookie('bootgift', 'ok')
          $timeout(() => {
            Modal.open({
              templateUrl: 'v-modal-feature-off.html',
              id: 'bootGift',
              data: {
                title: $filter('trans')('feature.bootgift.off.title'),
                text: $filter('trans')('feature.bootgift.off.text'),
              }
            })
          })
        }
      })
    }
  };

  $scope.shareTwit = function (text, type, id, twitter) {
    var shortify = {
      ln: {
        'it': 'I',
        'en': 'E',
        'es': 'S',
        'fr': 'F',
        'de': 'D'
      },
      type: {
        'post': 'P',
        'landing': 'L',
        'tree': 'T'
      }
    };

    var baseLink = $rootScope.baseHtml5Url;
    var link = baseLink + 's/' + shortify['ln'][$rootScope.ln] + shortify['type'][type] + id;
    return text + ' ' + link + ' ' + twitter
  };

  $rootScope.openTour = function (chapter, page) {
    Utility.openTour(chapter, page)
  };

  $rootScope.lnChanging = false

  $rootScope.changeLn = function (lng) {
    if (lng !== ipCookie('hl') && !$rootScope.lnChanging) {
      $rootScope.lnChanging = true
      var url = Routing.route('treedom_user_switch_ln');
      var form = $('<form action="' + url + '" method="post">' + '<input type="hidden" name="pathname" value="' + window.location.pathname + '" />' + '<input type="hidden" name="ln" value="' + lng + '" />' + '</form>');
      $('body').append(form);
      form.submit()
    }
  };

  /**
   * Function that return user ui-sref. Based on type and slug
   * @param {string} type - User type ['Private', 'Business']
   * @param {string} state - State without 'user' or 'organization'
   * @param {Object} params - State params
   */
  $rootScope.getLink = function (type, state, params) {
    if (isUndefined(type)) return '/';

    var stateToRedirect = type === 'Private' ? 'user' : 'organization';

    if (state) stateToRedirect += '.' + state;

    return $state.href(stateToRedirect, params, { absolute: true })
  };

  $scope.openModal = function (template, data) {
    Modal.open({ templateUrl: template, data: data })
  }

  $rootScope.mapIcons = {
    Boat: {
      lng: 32.90058638279345,
      lat: -1.1159208218838472,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/sailing_boat.png'),
      class: 'markerPimp',
      minZoom: 5.9,
      maxZoom: 22,
      minSize: 30,
      maxSize: 130
    },
    // Kenya
    Kilimanjaro: {
      lng: 39.874050031241336,
      lat: 2.4548,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/kenya_kilimangiaro.svg'),
      class: 'markerPimp',
      ZoomOut: 10,
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 250
    },
    // Capanna
    Kenya: {
      lng: 36.82194619999996,
      lat: -1.2920659,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/10_kenya.svg'),
      class: 'markerPimp',
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    // Elefante
    KenyaSecond: {
      lng: 37.8931858416126,
      lat: 0.45035380490385535,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/11_kenya.svg'),
      class: 'markerPimp',
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    // Giraffa
    KenyaThird: {
      lng: 38.41666669999995,
      lat: -2.1833333,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/giraffa.svg'),
      class: 'markerPimp',
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    Senegal: {
      lng: -15.762935124076193,
      lat: 15.29484654520985,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/senegal.svg'),
      class: 'markerPimp',
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    SenegalSecond: {
      lng: -12.13910969818528,
      lat: 13.495554936402726,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/senegal_second.svg'),
      class: 'markerPimp',
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    SenegalThird: {
      lng: -16.756909415073437,
      lat: 13.717850211330457,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/senegal_third.svg'),
      class: 'markerPimp',
      minZoom: 5,
      maxZoom: 22,
      minSize: 10,
      maxSize: 60
    },
    SenegalFourth: {
      lng: -14.533523243304444,
      lat: 14.576238693494247,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/senegal_oasi.svg'),
      class: 'markerPimp',
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    Argentina: {
      lng: -61.45502437488625,
      lat: -36.327107938480296,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/20_argentina.svg'),
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    // Cascate dell'Iguazú
    ArgentinaSecond: {
      lng: -54.292493892050004,
      lat: -27.016894520341403,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/argentina_cascate.svg'),
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 300
    },
    Malawi: {
      lng: 33.76827549773736,
      lat: -13.723988577161123,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/16_malawi.svg'),
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 250
    },
    MalawiSecond: {
      lng: 34.87615455177877,
      lat: -14.442597352985828,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/17_malawi.svg'),
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    // Moschea
    Burkina: {
      lng: -0.8236783317111076,
      lat: 13.065202985876851,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/burkina_faso_cattedrale.svg'),
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 300
    },
    // Capanna
    BurkinaSecond: {
      lng: -2.869458503691021,
      lat: 12.037598442955115,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/18_burkina_faso.svg'),
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    Camerun: {
      lng: 13.158175400000005,
      lat: 6.789296499999999,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/06_camerun.svg'),
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 200
    },
    CamerunSecond: {
      lng: 13.315572285063809,
      lat: 3.460773831741051,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/07_camerun.svg'),
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 160
    },
    // Capanna
    Haiti: {
      lng: -72.18958724747722,
      lat: 19.289575387609716,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/08_haiti.svg'),
      zoom: 10,
      minZoom: 5,
      maxZoom: 22,
      minSize: 30,
      maxSize: 250
    },
    // Barca
    HaitiSecond: {
      lng: -73.20619116281648,
      lat: 19.38242041775233,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/09_haiti.svg'),
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 220
    },
    // Vesuvio
    Italy: {
      lng: 14.525353206835234,
      lat: 40.98983079147001,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/italia_vesuvio.svg'),
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 250
    },
    Oasis: {
      lng: 31.499322183422123,
      lat: 25.863669633933455,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/oasis_second.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 100
    },
    OasisSecond: {
      lng: 21.862019633691034,
      lat: 21.40654310238608,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/oasis_second.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    Dunes: {
      lng: -11.578810299999986,
      lat: 25.2322352,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/camerun_deserto.svg'),
      class: 'markerPimp',
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    DuneSecond: {
      lng: 3.67384119999997,
      lat: 32.4902246,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/camerun_deserto.svg'),
      class: 'markerPimp',
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    DuneThird: {
      lng: 1.6760690999999497,
      lat: 20.8948062,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/camerun_deserto.svg'),
      class: 'markerPimp',
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Balena: {
    //   lng: -18.342622913824016,
    //   lat: 12.8281556727312,
    //   src: $filter('asset')('bundles/treedomnewfrontend/images/map/balena.gif'),
    //   class: 'markerPimp',
    //   minZoom: 4,
    //   maxZoom: 22,
    //   minSize: 30,
    //   maxSize: 200
    // },
    // Piramide e Sfinge
    Egypt: {
      lng: 31.132495500000005,
      lat: 30.0,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/pyramid_sphinx.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 300
    },
    // Eiffel
    France: {
      lng: 2.2944813000000295,
      lat: 48.85837009999999,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/eiffel.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 200
    },
    // Big Ben
    Uk: {
      lng: -0.12462540000001354,
      lat: 51.50072919999999,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/big_ben.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 180
    },
    // Lochness
    UkSecond: {
      lng: -4.424381799999992,
      lat: 57.32285750000001,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/lochness.svg'),
      class: 'markerPimp',
      minZoom: 10,
      maxZoom: 22,
      minSize: 30,
      maxSize: 200
    },
    // Igloo
    Groenlandia: {
      lng: -64.31105189613675,
      lat: 77.64860110485794,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/igloo.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 200
    },
    // Acropoli
    Athene: {
      lng: 23.725749199999996,
      lat: 37.9715323,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/athen.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Cactus
    Messico: {
      lng: -100.96409879999999,
      lat: 25.5569093,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/cactus.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Temple
    Japan: {
      lng: 139.69170639999993,
      lat: 35.6894875,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/giappone.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // New York Liberty statue
    America: {
      lng: -74.1723667,
      lat: 40.735657,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/ny.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Houston - razzo
    Houston: {
      lng: -95.3698028,
      lat: 29.7604267,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/houston.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Cristo redentore
    Brasil: {
      lng: -43.45244020000001,
      lat: -22.7255466,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/cristo_redentore.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Isole di pasqua - moai
    Moai: {
      lng: -109.34968650000002,
      lat: -27.112723,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/moai.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // temple intihuatana
    Peru: {
      lng: -76.9956133,
      lat: -12.1157013,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/peru.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Kangaroo
    Australia: {
      lng: 144.25550410000005,
      lat: -23.4421917,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/kangaroo.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Koala
    AustraliaSecond: {
      lng: 122.29377810000005,
      lat: -22.6073151,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/koala.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Sydney - teatro
    AustraliaThird: {
      lng: 151.20929550000005,
      lat: -33.8688197,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/sydney.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Dubai - Grattacielo a pinna - Torch Tower
    Emirates: {
      lng: 55.270782800000006,
      lat: 25.2048493,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/dubai.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Taj Mahal
    India: {
      lng: 78.04215520000002,
      lat: 27.1750151,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/taj-mahal.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // San Pietroburgo
    Russia: {
      lng: 30.335098600000038,
      lat: 59.9342802,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/russia.svg'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    Cina: {
      lng: 101.77049609999995,
      lat: 36.6821433,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/fireworks.gif'),
      class: 'markerPimp',
      minZoom: 4,
      maxZoom: 22,
      minSize: 30,
      maxSize: 150
    },
    // Alpi
    Montagne: {
      lng: 10.683540131967334,
      lat: 46.55121678107983,
      src: $filter('asset')('bundles/treedomnewfrontend/images/map/mountains_texture.svg'),
      class: 'markerPimp',
      minZoom: 2,
      maxZoom: 22,
      minSize: 30,
      maxSize: 100
    }
  };

  /**
   * Add illustrations on map and return an array of markers
   * @param {Object} map - Mapbox map Object
   * @param {boolean} satelliteModeEnabled - If satellite mode is enabled
   * @param {Array} activeMarkers - Array of markers with illustrations
   */
  $rootScope.mapPimp = function (map, satelliteModeEnabled, activeMarkers) {
    activeMarkers = activeMarkers || [];
    activeMarkers.forEach(function (entry) {
      entry.remove()
    });

    if (satelliteModeEnabled) return false;

    var bounds = map.getBounds();
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();
    var southWestLat = sw.lat;
    var southWestLng = sw.lng;
    var northEastLat = ne.lat;
    var northEastLng = ne.lng;

    for (var key in $rootScope.mapIcons) {
      var obj = $rootScope.mapIcons[key];

      // Trovo corretto zoom
      var actualeZoom = map.getZoom();
      var show = false;

      if (actualeZoom >= obj.minZoom && actualeZoom <= obj.maxZoom) {
        if (obj.lat > southWestLat && obj.lat < northEastLat && obj.lng > southWestLng && obj.lng < northEastLng) show = true
      }

      if (!show) continue;

      var size = obj.minSize + ((obj.maxSize - obj.minSize) * actualeZoom / 22);
      var el = document.createElement('div');
      el.className = 'markerPimp';
      el.style.backgroundImage = 'url(' + obj.src + ')';
      el.style.width = size + 'px';
      el.style.height = size + 'px';
      el.setAttribute('marker-type', 'pimp');
      el.setAttribute('marker-class', 'pimp');
      el.setAttribute('marker-id', key);


      // add marker to map
      var newMarker = new window.mapboxgl.Marker(el, {offset: [-size / 2, -size / 2]})
      .setLngLat([obj.lng, obj.lat])
      .addTo(map);

      activeMarkers.push(newMarker)
    }

    return activeMarkers
  };

  // $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
  //   Scroll.saveHistory(event, toState, toParams, fromState, fromParams)
  // })

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    // Update variable $rootScope.currentState
    $rootScope.currentState = toState.name;

    // Close tree schede
    if ($rootScope.activeTree && (toState.name !== 'project.tree' || toState.name !== 'species.tree' || toState.name !== 'ecommerce.tree')) {
      $rootScope.closeTreeSchede()
    }

    // Scroll history
    // Scroll.apply(event, toState, toParams, fromState, fromParams)

  })

  $rootScope.goBack = function(fallback, params) {
    var state = fallback || 'index'
    var params = params || {}
    if (
      $rootScope.statusHistory
      && $rootScope.statusHistory.previous
      && $rootScope.statusHistory.previous.state.name !== ''
      && (
        $rootScope.statusHistory.previous.state.name !== $state.current.name
        || JSON.stringify($rootScope.statusHistory.previous.params) !== JSON.stringify($state.params)
      )
    ) {
      state = $rootScope.statusHistory.previous.state.name
      params = $rootScope.statusHistory.previous.params
    }
    return $state.go(state, params)
  };

  $rootScope.createEventFlow = function () {
    $state.go('create-forest')
  };

  $rootScope.closeTreeSchede = function () {
    if (!treeSchedeModalIsOpen) return;

    var treeSchede = $document.find('#TreeSchedeModal');
    var el = treeSchede[0];
    el.style.transform = 'scale(0.2)';
    el.style.opacity = '0';

    // Allow website scroll
    Utility.allowScroll();

    $timeout(function () {
      treeSchede.remove();
      $rootScope.activeTree = undefined;

      document.body.style.overflow = 'auto';
      treeSchedeModalIsOpen = false;

      if (!$scope.$$phase) $scope.$apply()
    }, 300)
  };

  $rootScope.validateEmail = function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
  };

  $rootScope.openSubscribeModal = function (productId) {
    $rootScope.subscriptionIdToOpen = productId;
    Modal.open({ templateUrl: 'modalSubscribePlan.html', easyClose: false })
  };


  // Modale openFaq
  $rootScope.openFaq = function () {
    Modal.open({templateUrl: 'modalOpenFaq.html', preventCloseToStatusChange: true});
    $rootScope.$on('closedTreedomModal', function() {
      $rootScope.statusFaqListener();
    });
  };

  // Check if url contains get variables
  if (typeof getVars === 'object' && Object.keys(getVars).length) {

    // Forest creation flow
    if (getVars.event) {
      $timeout(function () {
        if ($rootScope.userdata) {
          if ($rootScope.userdata.info) {
            if ($rootScope.userdata.info.usertype === 'Private') {
              $state.go('create-forest');
              return
            }
          }
        }

        $rootScope.actionAfterLogin = 'openEventCreation';
        Modal.open({ templateUrl: 'login.html', easyClose: false, fitcontent: true })
      }, 3500)
    }

    // Subscription Flow
    if (getVars.subscription) {
      // Utente loggato
      if ($rootScope.user) {
        $rootScope.$watch('userdata.info', function (userInfo) {
          if (!userInfo) return;

          var userType = userInfo.usertype === 'Private' ? 'user' : 'organization';
          $state.go(userType, { slug: $rootScope.user });
          $rootScope.openSubscribeModal(getVars.subscription)
        })

      // Utente non loggato
      } else {
        $timeout(function () {
          $rootScope.actionAfterLogin = 'openSubscriptionModal';
          $rootScope.subscriptionIdToOpen = getVars.subscription;
          Modal.open({ templateUrl: 'login.html', easyClose: false, fitcontent: true })
        }, 2000)
      }
    }

    if (getVars.shareForest) {
      $rootScope.showEventShareModal = true
    }
  }
}]);
