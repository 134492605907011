
















import { defineComponent, ref } from '@vue/composition-api'
import Icon from '@f/components/Icon.vue'
import socialsData from '@f/configs/socials'
import { DataEventSocialButton} from '@f/services/TagManager/events';

export default defineComponent({
  name: 'SocialLinks',
  components: { Icon },
  setup() {
    const socials = ref(socialsData)
    const handleSocialButtonClick = (social: string): void => {
      DataEventSocialButton(social, 'click')
    }
    
    return {
      socials,
      handleSocialButtonClick
    }
  }
})
