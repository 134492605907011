







































import { computed, defineComponent, PropType } from '@vue/composition-api'
import ForestCard from '@f/components/Forest/ForestCard.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import ShareNative from '@f/components/ShareNative.vue'
import { Forest } from '@f/@types'
import useAuthentication from '@f/composables/useAuthentication'
import { getRootScope, getStateService } from '@f/services/Angular'
import useRoutes from '@f/composables/useRoutes'

export default defineComponent({
  name: 'BirthdayForestEmptyState',
  components: { ShareNative, Treecon, ForestCard },
  props: {
    forest: {
      type: Object as PropType<Partial<Forest>>,
      required: true,
    },
  },
  setup(props) {
    const {isLoggedAs} = useAuthentication()
    const isOwner = computed(() => isLoggedAs(props.forest.user))
    const { href  } = useRoutes()
    const moveTreesUrl = computed(() => href('forest-add-trees', {
      slug: props.forest.slug,
      id: props.forest.id,
    }))
    const plantInThisForest = () => {
      const rootScope = getRootScope()!
      const stateService = getStateService()!
      rootScope.plantIn = props.forest as Forest
      stateService.go('species', {
        type: 'rallyforest',
      })
    }
    const shareData = computed(() => {
      const url = new URL(href('forest.birthday', {
          slug: props.forest.slug,
        }), window.location.href).toString()
      return ({
        text: `Ciao amici! Per il mio compleanno ho creato una foresta su Treedom. Invece dei soliti regali pianta un albero per me. Ogni albero conta! Grazie per rendere il mio compleanno speciale e prendervi cura del Pianeta. 🌳🌍`,
        url,
      })
    })
    return {
      plantInThisForest,
      moveTreesUrl,
      isOwner,
      shareData,
    }
  }
})
