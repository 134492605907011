import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { apiClient as api, cartClient as cart, wwwClient as www } from '@f/apollo'
import VueSocialSharing from 'vue-social-sharing'
import VTooltip from 'v-tooltip'
import VueCompositionApi from '@vue/composition-api'
// @ts-ignore
import StoryblokVue from 'storyblok-vue'
import VueLazyLoad from 'vue-lazyload'

import plugins from '@f/plugins'
import directives from '@f/directives'
import filters from '@f/filters'

// Import design system styles
import '@f/scss/app.scss'

export function bootstrapVue() {
  // Register plugins
  Vue.use(VueApollo)
  Vue.use(VueSocialSharing)
  Vue.use(VTooltip, {
    defaultTemplate: '<div class="v-tooltip" role="tooltip"><div class="v-tooltip-arrow"></div><div class="v-tooltip-inner"></div></div>',
    defaultArrowSelector: '.v-tooltip-arrow, .v-tooltip__arrow',
    defaultInnerSelector: '.v-tooltip-inner, .v-tooltip__inner'
  })
  Vue.use(VueCompositionApi)
  Vue.use(VueLazyLoad)

  // Register Plugins
  Object.keys(plugins).forEach( pluginKey => {
    Vue.use(plugins[pluginKey])
  })

  // Register Directives
  Object.keys(directives).forEach( directiveKey => {
    Vue.directive(directiveKey, directives[directiveKey as keyof typeof directives])
  })

  // Register Filters
  Object.keys(filters).forEach( filterKey => {
    Vue.filter(filterKey, filters[filterKey])
  })

  // Stoyblok CMS
  Vue.use(StoryblokVue)
}

// Create Apollo Provider
const vueApollo = new VueApollo({
  defaultClient: api,
  clients: {
    api,
    cart,
    www
  },
})

export const apolloProvider = vueApollo
