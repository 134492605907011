

























































import { Component, Mixins } from 'vue-property-decorator'
import TreeDiaryItem from '../mixins/TreeDiaryItem'

@Component({
  name: 'TreeDiaryGift',
})
export default class TreeDiaryGift extends Mixins(TreeDiaryItem) {
  get text(): string {
    return this.translate('pageTree.diary.gift.text', {
      giftUser: this.post.user.firstName,
      initialUser: this.post.initialUser.firstName,
      linkGiftUser: this.parseState(
        this.post.user.userType === 'Private' ? 'user' : 'organization',
        { slug: this.post.user.slug }
      ),
      linkInitialUser: this.parseState(
        this.post.initialUser.userType === 'Private' ? 'user' : 'organization',
        { slug: this.post.initialUser.slug }
      ),
    })
  }
}
