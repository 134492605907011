import moment, { Moment } from 'moment'
import { IsoCode } from "@f/@types/localization"
import { ShopCurrency } from '../configs/app'
import { getRootScope } from '../services/Angular'

// TODO: replace all with Intl methods and remove moment once officially dropped IE11 compatibility

export const CURRENCIES = {
  [ShopCurrency.Usd]: {
    symbol: '$',
    decimals: 2,
  },
  [ShopCurrency.Eur]: {
    symbol: '€',
    decimals: 2,
  },
  [ShopCurrency.Gbp]: {
    symbol: '£',
    decimals: 2,
  },
} as const

export function formatCurrency(
  amount: string | number,
  locale: IsoCode,
  symbol?: string
): string {
  if (Number.isNaN(amount)) return '0'

  const currency = (getRootScope()?.currency ?? 'EUR') as ShopCurrency

  const amountInNumber =
    typeof amount === 'string' ? parseInt(amount, 10) : amount

  // const amountWithDecimals = amountInNumber / 100

  return (CURRENCIES[currency] && `${symbol ?? CURRENCIES[currency].symbol} `) +
        formatNumber(amountInNumber, locale, CURRENCIES[currency].decimals)
}


export function formatNumber(val: string | number, locale: IsoCode, decimal: number = 0): string {
  val = Number(val)
  if (isNaN(val)) return val.toString()
  if (val.toLocaleString) {
    return `${val.toLocaleString(locale, { maximumFractionDigits: decimal, minimumFractionDigits: decimal })}`
  }
  return `${val.toFixed(decimal)}`
}


type FormatNeutralizedOptions = {
  englishSafeUnit?: boolean
}
const formatNeutralizedDefaultOptions: FormatNeutralizedOptions = {
  englishSafeUnit: true
}
export function formatNeutralized(val: number | string, locale: IsoCode, options: FormatNeutralizedOptions = formatNeutralizedDefaultOptions): string {
  const value = Number(val)
  if (isNaN(value)) return val.toString()
  if(value > 999) {
    const tons = formatNumber(value / 1000, locale, 2).replace(/\,00+$/,'')
    if (options.englishSafeUnit) {
      return `${tons} t*`
    }
    return `${tons} t`
  }
  else {
    return formatNumber(value, locale, 2).replace(/\,00+$/,'') + ' kg'
  }
}

export function formatDate(date: string | Moment | Date, locale: IsoCode, format: string | string[]) {
  const mDate = moment(date)
  if (Array.isArray(format)) {
    return (format as string[]).map(frmt => mDate.locale(locale).format(frmt)).join(' ')
  }
  return mDate.locale(locale).format(format as string)
}

export function relativeDate(date: string | Date | Moment, locale: IsoCode, format?: string) {
  return moment(date).locale(locale).fromNow()
}

export function formatDegrees(value: string | number, axis: "lat" | "lng"): string {
  const parts = (value.toString()).split('.')
  parts[0] = parts[0] || '00'
  parts[1] = parts[1] || '000000'
  const degrees = Math.abs(+parts[0])
  const secondsTotal = +parts[1] * (3600 / Math.pow(10, parts[1].length))
  const minutes = Math.floor(secondsTotal / 60)
  const seconds = (secondsTotal % 60).toFixed(2)
  const card = axis === 'lng' ? (+value > 0 ? 'E' : 'W') : (+value > 0 ? 'N' : 'S')
  return `${degrees.toString().padStart(2, '0')}° ${minutes.toString().padStart(2, '0')}′ ${seconds}″ ${card}`
}

export function compactNumber(value: string | number) {
  return String(value).replace(/(.+)\d{6}$/, '$1mil').replace(/(.+)\d{3}$/, '$1k')
}
