import { setContext } from '@apollo/client/link/context'
import { appCookies } from '@f/configs/app'
import { TreedomAuth } from '@f/auth'

const createApolloAuthorizationLink = () => setContext(async () => {
  if (await TreedomAuth.isAuthenticated()) {
    return {
      headers: {
        Authorization: `Bearer ${await TreedomAuth.getJwtToken()}`
      }
    }
  }
  return {}
})

export default createApolloAuthorizationLink