import { ref, computed, onMounted } from '@vue/composition-api'
import { preferences } from '@f/configs/app'
import {
  fetchCountries,
  getLocalizedCountryName,
  CountriesSourceData,
  CountryTranslationsKey,
} from '@f/services/Countries'

type SearchCountriesOptions = Partial<typeof DEFAULT_OPTIONS>

const DEFAULT_OPTIONS = {
  page: Infinity,
  minSearchLength: 0
}

export function searchCountry(customOptions?: SearchCountriesOptions) {
  const countriesSourceData = ref<CountriesSourceData>([])
  const search = ref('')

  const options = computed(() => ({ ...DEFAULT_OPTIONS, ...customOptions }))

  const sortedCountries = computed(() => countriesSourceData.value.sort((a, b) => {
    return (
      a.translations[preferences.language as CountryTranslationsKey]
      || a.name
    ).localeCompare(
      b.translations[preferences.language as CountryTranslationsKey]
      || b.name
    )
  }))

  const filteredCountries = computed(() => {
    if (
      options.value.minSearchLength
      && search.value.length < options.value.minSearchLength
    ) return []
    return (
      !!search.value
        ? sortedCountries.value.filter(searchFilter.bind(undefined, search.value))
        : sortedCountries.value
    ).slice(0, options.value.page)
  })

  function matchCountryName(name: string, regExp: RegExp) {
    return name?.split(/[-_'\s\t]/).some(namePart => namePart.match(regExp))
  }

  function searchFilter(searchString: string, country: CountriesSourceData[number]) {
    const matchRegExp = RegExp('^'+searchString, 'i')
    return preferences.language in country.translations
      ? matchCountryName(country.translations[preferences.language as CountryTranslationsKey] || '', matchRegExp)
      : matchCountryName(country.name, matchRegExp) || matchCountryName(country.nativeName, matchRegExp)
  }

  function countryName(country: CountriesSourceData[number]) {
    return getLocalizedCountryName(country, preferences.language)
  }

  function findCountry(codeOrName?: string) {
    if (!codeOrName) return
    return countriesSourceData.value.find(country => {
      return country.alpha2Code === codeOrName
        || country.translations[preferences.language as CountryTranslationsKey] === codeOrName
        || country.name === codeOrName
    })
  }

  onMounted(() => {
    fetchCountries().then(data => countriesSourceData.value = data)
  })

  return {
    countryName,
    filteredCountries,
    findCountry,
    search,
  }
}
