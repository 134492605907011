






































































































































































































































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import { AxiosError } from 'axios'
import URLSearchParams from '@ungap/url-search-params'
import { AuthProvider, UserType } from '@f/models'
import { api } from '@f/configs/app'
import { UserAgent } from '@f/utils'
import {
  HomonymyUser,
  ExistingUserPayload,
  SignInCredentials,
  SignUpData,
  HomonymyLoginPayload,
  AmplitudeExperiment
} from '@f/@types'

// Mixins
import AuthMixin from '@f/mixins/Auth'
import UseCountriesMixin from '@f/mixins/UseCountries'
import UseIubendaService from '@f/mixins/UseIubendaService'
import PasswordResetRequestMixin from '@f/mixins/PasswordResetRequest'
import ShowCustomTranslationMixin from '@f/mixins/ShowCustomTranslations'
import ShowAuthErrorsMixin, { AuthErrorResponse } from '@f/mixins/ShowAuthErrors'

// Components
import { Stepper, StepperStep, RunEventPayloadInterface } from '@f/components/Stepper'
import BaseInput from '@f/components/Form/BaseInput.vue'
import BaseCheckbox from '@f/components/Form/BaseCheckbox.vue'
import HomonymyList from '@f/components/Auth/HomonymyList.vue'
import FacebookLoginButton from '@f/components/Auth/FacebookLoginButton.vue'
import GoogleLoginButton from '@f/components/Auth/GoogleLoginButton.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Overlay from '@f/components/Overlay.vue'
import Alert from '@f/components/Alert.vue'
import Icon from '@f/components/Icon.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import FilteredSelect from '@f/components/Form/FilteredSelect.vue'
import SlideDownTransition from '@f/transitions/SlideDown'
import { DataEventLogin, DataEventRegistration, DataEventCreateAccount } from '@f/services/TagManager/events'
import experimentService, {
  ActiveExperiments,
  WhyRegisterLoginSignupSectionValues
} from '@f/services/experimentService'
import SignInFormWhyRegister from '@f/components/Auth/SignInFormWhyRegister.vue'
import { FederatedSignInOptions } from '@f/auth'
import { SignInAbortError } from '@f/auth/cognito-provider'
import AppleLoginButton from "@f/components/Auth/AppleLoginButton.vue";

type ActionTypes = 'redeem' | 'checkOut' | 'login' | null
const availableActionTypes = ['redeem', 'checkOut'] as ActionTypes[]

enum FlowStep {
  EmailCheck,
  SignIn,
  SignUp,
  SignInAsHomonym
}

@Component({
  components: {
    AppleLoginButton,
    BaseInput,
    BaseCheckbox,
    HomonymyList,
    FacebookLoginButton,
    FilteredSelect,
    GoogleLoginButton,
    DotLoader,
    Overlay,
    Alert,
    Stepper,
    StepperStep,
    Icon,
    Treecon,
    SlideDownTransition,
    SignInFormWhyRegister
  }
})
export default class SignForm extends Mixins(
  AuthMixin,
  PasswordResetRequestMixin,
  UseIubendaService,
  ShowAuthErrorsMixin,
  ShowCustomTranslationMixin,
  UseCountriesMixin
) {

  @Prop({ type: String, default: null }) readonly position!: string | null
  @Prop({ type: Object }) readonly formData!: Record<string, any> | null

  loading: boolean = false
  hide: boolean = false
  error: string | null = null
  social: string | null = null
  socialToken: string | null = null
  homonymies: HomonymyUser[] | null = null
  searchParams: URLSearchParams | null = null
  termsUrl: string = ''
  stepperRunAfterHandler: ((move: RunEventPayloadInterface) => void) | null = null
  resetRequestSuccess: boolean = false
  _ngState: any
  defaultIntroTitle = 'login.modalTitle'
  defaultIntroText = ['login.help.text']


  whyRegisterEvent: AmplitudeExperiment | undefined | null = null //null to trigger vue reactivity

  // Computed

  get ngState() {
    if (!this._ngState) this._ngState = this.ngService('$state')
    return this._ngState
  }

  get flowStep(): FlowStep {
    if (!this.$refs.stepper.index) {
      return FlowStep.EmailCheck
    } else if (this.homonymies) {
      return FlowStep.SignInAsHomonym
    } else if (this.userData) {
      return FlowStep.SignIn
    } else {
      return FlowStep.SignUp
    }
  }

  get isFirstStep(): boolean {
    return this.$refs.stepper && this.$refs.stepper.index === 0
  }

  get businessUrl(): string {
    return window.businessUrl || ''
  }

  get googleRedirectUri(): string {
    return api.authUri + '/google-login'
  }

  get showGoogle(): boolean {
    return !['facebook', 'instagram'].includes(UserAgent.browser)
  }

  get showFacebook(): boolean {
    return true
  }

  get introTitle(): string {
    return this.formData && this.formData.title || this.defaultIntroTitle
  }

  get introText(): string[] {
    if (!(this.formData && this.formData.text)) return this.defaultIntroText
    return Array.isArray(this.formData.text)
      ? this.formData.text
      : typeof this.formData.text === 'string'
        ? [this.formData.text]
        : this.defaultIntroText
  }

  get hasCountryErrors() {
    return this.errors.first('country')
      || this.errors.first('countryCode')
  }

  // HACK: inject class into translations html
  get privacyUrl(): string {
    return this.policyLinks.privacy.class
      ? this.policyLinks.privacy.href + '" class="' + this.policyLinks.privacy.class
      : this.policyLinks.privacy.href
  }

  get cookieUrl(): string {
    return this.policyLinks.cookie.class
      ? this.policyLinks.cookie.href + '" class="' + this.policyLinks.cookie.class
      : this.policyLinks.cookie.href
  }

  // HACK: Orange
  get customLanguage() {
    return this.formData && this.formData.language || this.language
  }

  get translateMethod() {
    return this.formData && this.formData.language
      ? this.$tCustom
      : this.$t
  }

  get showWhyRegisterExperiment() {
    return this.whyRegisterEvent?.value?.value === WhyRegisterLoginSignupSectionValues.On && availableActionTypes.includes(this.actionType)
  }

  get actionType(): ActionTypes {
    return this.formData?.actionType
  }

  get disableSurvey(): boolean {
    return this.formData?.disableSurvey ?? false
  }

  // Refs
  $refs!: Vue['$refs'] & {
    stepper: Stepper,
    'stepper-step-0': StepperStep,
    'stepper-step-1': StepperStep,
    'stepper-step-2': StepperStep,
    signinPassword: BaseInput,
    signupFirstname: BaseInput,
  }

  // Events
  mounted() {
    this.onCheckout = !!this.ngState && /^checkout/.test(this.ngState.current.name)
    this.fetchUrlParams()
    this.getTermsUrls()

    if (availableActionTypes.includes(this.actionType)) {
      this.loadWhyRegisterExperiment()
    }
  }

  // Methods
  onStepperMountAfter(): void {
    this.toggleAnchorsTabIndex()
  }

  onStepperRun(move?: RunEventPayloadInterface): void {
    this.clearErrors()
  }

  onStepperRunAfter(move: RunEventPayloadInterface): void {
    if (this.stepperRunAfterHandler) {
      this.stepperRunAfterHandler(move)
      this.stepperRunAfterHandler = null
    }
    // Wait next tick to settle the page
    this.$nextTick(() => {
      this.toggleAnchorsTabIndex()
      switch (this.flowStep) {
        case FlowStep.SignIn:
          this.$refs.signinPassword && this.$refs.signinPassword.focus()
          break
        case FlowStep.SignUp:
          this.$refs.signupFirstname && this.$refs.signupFirstname.focus()
          break
      }
    })
  }

  toggleAnchorsTabIndex(): void {
    const activeIndex = this.$refs.stepper.index
    if (activeIndex !== null) {
      Object.keys(this.$refs).forEach(ref => {
        const match = ref.match(/^stepper-step-(\d+)$/)
        if (match) {
          const [stepRef, stepIndex] = match
          this.toggleStepperAnchorsTabIndex(stepRef, Number(stepIndex) !== activeIndex)
        }
      })
    }
  }

  toggleStepperAnchorsTabIndex(stepRef: string, inactive: boolean): void {
    const anchors = ((this.$refs[stepRef] as Vue).$el as HTMLElement).getElementsByTagName('a')
    if (anchors && anchors.length) {
      Array.from(anchors).forEach(anchor => {
        anchor.tabIndex = 0 - Number(inactive)
      })
    }
  }

  stepTabIndex(stepIndex: number): number {
    return 0 - Number(this.$refs.stepper && this.$refs.stepper.index !== stepIndex)
  }

  fetchUrlParams(): void {
    this.searchParams = new URLSearchParams(window.location.search)
    this.clearInputData()
    if (this.email && this.email !== '') this.checkEmail()
  }

  getTermsUrls(): void {
    if (this.ngState) {
      this.termsUrl = this.ngState.href('page.terms')
    }
  }

  clearInputData(): void {
    this.email = this.searchParams && this.searchParams.get('email') || ''
    this.email = this.searchParams && (this.searchParams.get('username') || this.searchParams.get('email') || '')
    this.firstName = this.searchParams && this.searchParams.get('firstname') || ''
    this.lastName = this.searchParams && this.searchParams.get('lastname') || ''
    this.password = ''
    this.confirmPassword = ''
    this.country = ''
    this.countryCode = ''
  }

  clearErrors(): void {
    this.error = null
    this.clearValidationErrors()
  }

  clearData(): void {
    this.userData = null
    this.homonymies = null
    this.error = null
  }

  clearCountryErrors() {
    this.errors.clear('country')
    this.errors.clear('countryCode')
  }

  backToCheck(): void {
    this.clearInputData()
    this.stepperRunAfterHandler = this.clearData
    this.$refs.stepper.goToStep(0)
  }

  hasProvider(provider: string): boolean {
    return (this.userData?.providers || []).includes(provider as AuthProvider)
  }

  hasNoProvider(): boolean {
    return !!(this.userData && this.userData.providers && this.userData.providers.length === 0)
  }

  denyCommunications(checkValue: boolean): void {
    this.allowCommunications = !checkValue
  }

  submitSocialLogin(): void {
    this.clearErrors()
    this.loading = true
  }

  onFacebookSuccess(): void {
    this.clearErrors()
    this.loading = true
    this.onSocialSuccess({
      identityProvider: 'facebook'
    })
  }

  onGoogleSuccess(): void {
    this.clearErrors()
    this.loading = true
    this.onSocialSuccess({
      identityProvider: 'google'
    })
  }

  onAppleSuccess(): void {
    this.onSocialSuccess({
      identityProvider: 'apple'
    })
  }

  onSocialSuccess(options: FederatedSignInOptions): void {
    this.federatedSignIn(options)
      .then((response) => {
        if (response.isRegistration) {
          DataEventRegistration({ accountType: options.identityProvider, disableSurvey: this.disableSurvey })
        } else {
          DataEventLogin(options.identityProvider)
        }

        this.closeFlow()
      })
      .catch(error => {
        if (error.name === 'SocialHomonymyError') {
          this.social = error.data.social
          this.socialToken = error.data.token
          this.homonymies = error.data.users
          this.$refs.stepper.nextStep()
        } else {
          this.onSocialError(error)
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  onSocialError(error: Error | string): void {
    this.loading = false
    if (error instanceof Error && error.message === 'SignIn Aborted by user') {
      return
    }
    this.error = this.getErrorLabelByMessage((error instanceof Error) ? error.message : error)
  }

  checkEmail(): void {
    this.clearErrors()
    this.loading = true
    this.checkUserExistence(this.email!)
      .then((response: ExistingUserPayload | undefined) => {
        // If user as only signed-up with social services
        if (response && response.providers && response.providers.length && !response.providers.includes(AuthProvider.Password)) {
          let provider = 'user'
          if (response.providers.includes(AuthProvider.Facebook)) {
            provider = 'facebook'
          } else if (response.providers.includes(AuthProvider.Google)) {
            provider = 'google'
          }
          throw new Error(`error.login.${provider}`)
        }
        this.userData = response
        this.$refs.stepper.nextStep()
      })
      .catch((error?: AxiosError) => {
        if (error?.response?.data.message === 'UserNotFound') {
          DataEventCreateAccount()
          this.$refs.stepper.nextStep()
        } else {
          this.error = typeof error === 'string'
            ? error
            : error?.message || error?.response?.data.message || ''
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  attemptSignIn(): void {
    this.clearErrors()

    if (!this.email || !this.password) return

    this.loading = true
    const credentials: SignInCredentials = { username: this.email!, password: this.password! }
    this.signIn(credentials)
      .then(() => {
        DataEventLogin()
        this.closeFlow()
      })
      .catch((error: AxiosError | Error) => {
        console.error('SignIn failed', error)
        if ('response' in error) {
          this.error = (error.response && error.response.status === 401) ? this.$t('error.login.password') : this.getErrorLabelByMessage(error.message)
          return
        }
        if (error instanceof SignInAbortError) {
          return
        }
        this.error = this.getErrorLabelByMessage(error.message)
      })
      .finally(() => {
        this.loading = false
      })
  }

  attemptSignUp(): void {
    this.clearErrors()
    // FIXME: needs validation
    this.loading = true
    const signUpData: SignUpData = {
      email: this.email!,
      confirmEmail: this.confirmEmail!,
      password: this.password!,
      confirmPassword: this.confirmPassword!,
      firstName: this.firstName!,
      lastName: this.lastName!,
      allowPromotion: this.allowCommunications,
      allowNewsletter: this.allowCommunications,
      country: this.country || '',
      countryCode: this.countryCode || ''
    }
    this.signUp(signUpData)
      .then(() => {
        DataEventRegistration({ disableSurvey: this.disableSurvey })
        this.closeFlow()
      })
      .catch((error?: AxiosError<AuthErrorResponse> | Error) => {
        console.error('SignUp failed', error)
        if (!error) {
          // il messaggio di errore null | undefined arriva solo quando la validazione lato FE fallisce
          // quindi si può evitare di mettere generic error
          // this.error = 'Generic Error'
          return
        }
        if ('response' in error) {
          this.error = error.response?.data && this.getErrorLabel(error.response.data) || this.getErrorLabelByMessage(error.message)
          return
        }
        this.error = this.getErrorLabelByMessage(error.message)
      })
      .finally(() => {
        this.loading = false
      })
  }

  attemptHomonymyLogin(credentials: HomonymyLoginPayload): void {
    this.clearErrors()
    this.loading = true
    const payload: HomonymyLoginPayload = {
      ...credentials,
      social: this.social,
      token: this.socialToken,
      allowCommunications: this.allowCommunications
    }
    this.homonymyLogin(payload)
      .then(() => {
        DataEventRegistration({ accountType: payload.social!, disableSurvey: this.disableSurvey })
        DataEventLogin(payload.social!)
        this.closeFlow()
      })
      .catch((error: Error | AxiosError) => {
        if ('response' in error) {
          this.error = (error.response?.status === 401) ? this.translateMethod('error.login.password') : this.getErrorLabelByMessage(error.message)
        } else if (error.message) {
          this.error = this.getErrorLabelByMessage(error.message)
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  requestPasswordReset() {
    this.clearErrors()
    this.loading = true
    this.requestReset(this.email)
      .then(() => {
        this.resetRequestSuccess = true
      })
      .catch(error => {
        this.error = this.getErrorLabelByMessage(error.message)
      })
      .finally(() => {
        this.loading = false
      })
  }

  closeFlow(): void {
    if (this.position === 'modal') {
      const ngModalService = this.ngService('Modal')
      if (ngModalService) ngModalService.close()
    }
  }

  async loadWhyRegisterExperiment() {
    this.whyRegisterEvent = await experimentService.getExperiment(this.$rootScope.userdata.info || { id: undefined }, ActiveExperiments.WhyRegisterLoginSignupSection)
  }
}
