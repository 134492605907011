






























import { defineComponent, PropType, ref } from '@vue/composition-api'
import { OrganizationUserData } from '@f/composables/fetchUserData'
import Treecon from '@f/components/Treecons/Treecon.vue'
import Modal from '@f/components/Modal.vue'
import useAuthentication from '@f/composables/useAuthentication'
import useRoutes from '@f/composables/useRoutes'

export default defineComponent({
  name: 'OrganizationSubscriberModal',
  props: {
    user: {
      required: true,
      type: Object as PropType<OrganizationUserData>
    },
    open: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    Modal,
    Treecon,
    SubscriberStamp: () => import( /* webpackChunkName: "treedom-b2b-stamp.svg" */ '@f/images/b2bSubscribers/SubscriberStamp.svg'),
    SubscriberStampFullBlue: () => import( /* webpackChunkName: "treedom-b2b-full-stamp.svg" */ '@f/images/b2bSubscribers/SubscriberStampFullBlue.svg'),
    SubscriberStar: () => import( /* webpackChunkName: "treedom-b2b-star.svg" */ '@f/images/b2bSubscribers/SubscriberStar.svg')
  },

  setup(props, { emit }) {
    const { isLoggedAs } = useAuthentication()
    const { go } = useRoutes()

    const onGoToSubscriptionsClick = () => {
      if(isLoggedAs(props.user.info)) {
        return go('settings.subscription')
      }
      go('subscriptions-switch')
    }

    const close = () => emit('close')

    return {
      onGoToSubscriptionsClick,
      isLoggedAs,
      close,
    }
  }
})
