import { IsoCode } from '@f/@types/localization'

const languageMap = {
  it: 'it',
  en: 'en',
  de: 'de',
  fr: 'fr',
  es: 'es',
}

export function getBlogUrl(language: IsoCode) {
  return '//blog.treedom.net/'+(languageMap[language] || 'it')
}
