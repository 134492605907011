




























import { defineComponent, PropType } from '@vue/composition-api'
import DotLoader from '@f/components/DotLoader.vue'
import HUDPage from '@f/components/Map/HUD/HUDPage.vue'
import { Maybe, TrEvent, TrStandardMedia } from '@f/@types/graphql'
import useRoutes from '@f/composables/useRoutes'
import Avatar from '@f/components/Avatar.vue'
import { defaultForestAvatar } from '@f/composables/useForestData'

export default defineComponent({
  name: 'HUDUserForests',
  components: { Avatar, HUDPage, DotLoader },
  props: {
    title: {
      type: String,
      required: true,
    },
    forests: {
      type: Array as PropType<TrEvent[]>,
      default: [],
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { href } = useRoutes()

    function getAvatar(avatar?: Maybe<TrStandardMedia>) {
      return avatar ? avatar.small : defaultForestAvatar
    }
    return {
      getAvatar,
      href,
    }
  },
})
