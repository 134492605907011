import { TrTranslation } from '@f/@types/graphql'
import { isDataObject, isArray, isFunction } from './typeguards'

export namespace OptionsList {

  export type ItemLabel = TrTranslation | string

  export interface Item<T = ItemLabel, K = string>{
    value: K
    label: T
  }

  export interface OptionsObject<T = ItemLabel> {
    [key: string]: T
  }

  export type ItemTransformSchema = {
    valueKey?: string
    labelKey?: string
  }

  export type ItemTransformFunction<T> = (item: any, key?: string) => Item<T>

  export type ItemTransformer<T = ItemLabel> = ItemTransformSchema | ItemTransformFunction<T>

  const defaultTransformSchema: Required<ItemTransformSchema> = {
    valueKey: 'id',
    labelKey: 'name'
  }

  function transformOption<T>(optionItem: any, schema: Required<ItemTransformSchema>): Item<T> {
    return { value: optionItem[schema.valueKey], label: optionItem[schema.labelKey] }
  }

  function transformObjectProperty<T>(optionItem: any, itemKey: string, schema?: ItemTransformSchema): Item<T> {
    return {
      value: schema?.valueKey ? optionItem[schema.valueKey] : itemKey,
      label: schema?.labelKey ? optionItem[schema.labelKey] : optionItem
    }
  }

  export function transformOptions<T = ItemLabel>(options: unknown | unknown[], transformer?: ItemTransformer<T>): Item<T>[] {
    if (isArray(options)) {
      if (transformerIsFunction(transformer))
        return options.map(item => transformer(item))
      const mergeSchema: Required<ItemTransformSchema> = { ...defaultTransformSchema, ...transformer }
      return options.map(optionItem => transformOption<T>(optionItem, mergeSchema))
    } else if (isDataObject(options)) {
      return Object.keys(options).map(optionKey => {
        return transformerIsFunction<T>(transformer) ?
          transformer(options[optionKey], optionKey) :
          transformObjectProperty<T>(options[optionKey], optionKey, transformer)
      })
    }
    return []
  }

  // Type Guards
  function transformerIsSchema<T>(item: unknown): item is ItemTransformSchema {
    return isDataObject<T>(item)
  }

  function transformerIsFunction<T>(item: unknown): item is ItemTransformFunction<T> {
    return isFunction(item)
  }

}
