import { Vue, Component } from 'vue-property-decorator'
import { TreedomAuth } from '@f/auth'
import { apiClient } from '@f/apollo'
import { USER_EMAIL_BY_SLUG } from '@f/graphql/queries'

@Component({
  name: 'HandleImpersonate'
})
export default class HandleImpersonate extends Vue {

  stateParamsService: any = null
  
  /*--- COMPUTED ------*/
  get canImpersonate(): boolean {
    return this.stateParamsService?.slug
      && !!this.$rootScope.userdata?.info?.permissions.ROLE_ALLOWED_TO_SWITCH
      && !!this.$state.current.name?.match(/^(user|organization)/)
  }

  get stopImpersonateUrl(): string {
    return '/?_switch_user=_exit'
  }

  /*--- EVENTS --------*/
  created() {
    this.stateParamsService = this.ngService('$stateParams')
  }

  get startImpersonateUrl(): string {
    if (!this.canImpersonate) return ''
    return this.$state.href('impersonate', {
      slug: this.stateParamsService.slug,
    })
  }
}
