








import { Vue, Component, Prop } from 'vue-property-decorator'
import { TrTree } from '@f/@types/graphql'

@Component({
  name: 'TreeSimpleCard'
})
export default class TreeSimpleCard extends Vue {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  @Prop({ type: Object, required: true }) readonly tree!: TrTree
  /*--- DATA ----------*/
  
  /*--- COMPUTED ------*/
  get icon() {
    return ((this.tree.limited || this.tree.specie)?.icon?.small || '').replace('dev-', '')
  }

  get name() {
    return this.tree.name
      || this.$t(this.tree.limited?.limitedName || this.tree.specie?.name || '')
  }

  get country() {
    return this.$t(this.tree.specie?.country?.name || '')
  }

  get variantsClassMap(): Record<string, boolean> {
    return {
      'tree-simple-card--anchor': !!this.$listeners.click
    }
  }
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  
  /*--- METHODS -------*/
  
}
