
























import { defineComponent, inject, onMounted, PropType, ref, Ref, watch } from '@vue/composition-api'
import { useQuery, useResult } from '@vue/apollo-composable'
import { MAP_CLUSTER } from '@f/graphql/queries'
import { MAP_INSTANCE } from '@f/services/Map'
import { FlyToOptions, Map as Mapbox } from 'mapbox-gl'
import { TrMapCluster, TrMapClusterQueryVariables } from '@f/@types/graphql'
import ClusterMarker from '@f/components/Map/Markers/ClusterMarker'
import TreeClusterPin from '@f/components/Map/Markers/TreeClusterPin.vue'
import Spinner from '@f/components/Spinner.vue'
import TrTreeClusterPin from '@f/components/Map/Markers/TrTreeClusterPin.vue'

const getMapClusterVariables = (map: Mapbox) => {
  const mapBounds = map.getBounds()
  const NE = mapBounds.getNorthEast()
  const SW = mapBounds.getSouthWest()
  const zoom = Math.round(map.getZoom())
  return {
    NE,
    SW,
    zoom,
  }
}

const useNonNullValue = <T = any>(data: Ref<T>) => {
  const result = ref<T | null | undefined>(data.value)
  watch(data, (current, previous) => {
    //@ts-ignore
    result.value = current || previous
  })
  return result
}

const getId = (cluster: TrMapCluster) => [
  cluster.count, cluster.lat, cluster.lng,
].join('-')

export default defineComponent({
  name: 'MapClusterMarkers',
  components: { Spinner, TreeClusterPin, ClusterMarker, TrTreeClusterPin },
  props: {
    userId: {
      type: Number,
      required: false,
    },
    eventId: {
      type: Number,
      required: false,
    },
    autoCenterOnChange: {
      type: Boolean,
      default: false,
    },
    centerOptions: {
      type: Object as PropType<FlyToOptions>,
      default: () => ({
        zoom: 0,
        center: [-30, 0],
      }),
    },
  },
  setup(props, { emit }) {
    const map = inject(MAP_INSTANCE)
    const variables = {
      ...getMapClusterVariables(map!.value),
      userId: props.userId,
      eventId: props.eventId,
    }
    const {
      result,
      loading,
      refetch,
    } = useQuery<TrMapCluster[], TrMapClusterQueryVariables>(MAP_CLUSTER, variables, () => ({
      enabled: !!props.userId || !!props.eventId,
      debounce: 1500,
      throttle: 1500,
    }))
    onMounted(() => {
      const reloadData = () => {
        refetch({
          ...getMapClusterVariables(map!.value),
          userId: props.userId,
          eventId: props.eventId,
          nursery: true,
        })
      }
      map?.value.on('moveend', () => {
        reloadData()
      })
      map?.value.on('zoomend', () => {
        reloadData()
      })
    })
    watch([() => props.userId, () => props.eventId], ([userId, eventId]) => {
      refetch({
        ...getMapClusterVariables(map!.value),
        userId,
        eventId,
        nursery: true,
      })
    })
    const data = useNonNullValue(useResult<TrMapCluster[] | undefined>(result))
    watch([() => props.centerOptions], () => {
      if (!props.autoCenterOnChange) return
      if (!props.centerOptions) return
      map?.value?.easeTo(props.centerOptions)
    }, {
      immediate: true,
    })
    return {
      data,
      map,
      getId,
      loading,
      onMarkerClick: ({ id }: { id: string }) => emit('tree-marker-click', id)
    }
  }
})
