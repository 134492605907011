import tagManagerService from '@f/services/TagManager';
import { validateAuthentication } from '@f/validation/datalayer';


export function DataEventLogin(accountType: string = 'email') {
    tagManagerService.push({
        event: 'logIn',
        accountType
    }, validateAuthentication)
}

export function DataEventLogout() {
    tagManagerService.push({
        event: 'logOut'
    }, validateAuthentication)
}