






























  import { Vue, Component, Prop } from "vue-property-decorator"
  import URLSearchParams from '@ungap/url-search-params'
  import { VueConstructor } from "vue"
  import { IAngularEvent } from "angular"

  import Loader from '../Loader.vue'
  import LegacyModal from '@f/components/_legacy/LegacyModal.vue'
  import CommentList from './partials/CommentList.vue'
  import { TrPageInfo } from '@f/@types/graphql'
  // Post Types Components
  import {
    TreeDiaryGift,
    TreeDiaryLike,
    TreeDiaryMessage,
    TreeDiaryName,
    TreeDiaryPost,
    TreeDiaryStatus } from './post_types'
  // Post Types Modals
  import {
    TreeDiaryGiftModal,
    TreeDiaryLikeModal,
    TreeDiaryMessageModal,
    TreeDiaryNameModal,
    TreeDiaryPostModal,
    TreeDiaryStatusModal } from './modals'
import { DataEventTreeDiaryInteraction } from "@f/services/TagManager/events"

  const postsComponents = {
    TreeDiaryGift,
    TreeDiaryLike,
    TreeDiaryMessage,
    TreeDiaryName,
    TreeDiaryPost,
    TreeDiaryStatus,
    TreeDiaryGiftModal,
    TreeDiaryLikeModal,
    TreeDiaryMessageModal,
    TreeDiaryNameModal,
    TreeDiaryPostModal,
    TreeDiaryStatusModal,
  }

  @Component({
    name: 'TreeDiaryWall',
    components: {
      Loader,
      LegacyModal,
      CommentList,
      ...postsComponents
    }
  })
  export default class TreeDiaryWall extends Vue {
    @Prop({ type: String, required: true }) readonly id!: string
    @Prop({ type: Number, default: 5 }) readonly paginationLimit!: number
    
    visibleItems = 5
    modalContentVariables: any = null
    displayShare = false
    clearWallUpdateListener: (() => void) | null = null
    
    // Events
    mounted() {
      this.registerWallUpdatedListener()
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.has('share')) {
        const postId = searchParams.get('share')
        if (!isNaN(Number(postId))) return
        const treeId = this.id
        this.modalContentVariables = { postId, treeId }
        this.displayShare = true
        this.$state.go('.', { share: '' }, { inherit: true })
      }
    }

    destroyed() {
      this.clearWallUpdateListener && this.clearWallUpdateListener()
    }

    // Methods
    registerWallUpdatedListener() {
      this.clearWallUpdateListener = this.$rootScope.$on('treeWallUpdated', (_ev: IAngularEvent, treeId: string) => {
        if (treeId === this.id && !!this.$refs.postList) {
          (this.$refs.postList as Vue).$apollo.queries.query.refetch()
        }
      })
    }

    type2component (post: any, layoutSuffix = ''): VueConstructor {
      const componentName = post.__typename+layoutSuffix as keyof typeof postsComponents;
      return postsComponents[componentName]
    }

    fetchMore(query: any, cachedList: any[], pageInfo: TrPageInfo) {
      this.visibleItems += this.paginationLimit
      if (this.visibleItems > cachedList.length && pageInfo.hasNextPage) {
        query.fetchMore({
          variables: {
            id: this.id,
            after: pageInfo.endCursor,
            limit: this.paginationLimit,
            postsOnly: true
          },
          updateQuery: (previousResult: any, { fetchMoreResult: { wall } }: any) => {
            return {
              tree: previousResult.tree,
              wall: {
                posts: [...previousResult.wall.posts, ...wall.posts],
                pageInfo: { ...wall.pageInfo },
                __typename: wall.__typename
              }
            }
          }
        })
      }
    }

    paginated(cachedList: any[]): any[] {
      return cachedList.slice(0, this.visibleItems)
    }

    hasNextPage(cachedList: any[], pageInfo: TrPageInfo): boolean {
      return this.visibleItems < cachedList.length || !!pageInfo.hasNextPage
    }

    openInModal(modalContent: any): void {
      this.modalContentVariables = modalContent
    }

    closeModal(): void {
      this.displayShare = false
      this.modalContentVariables = null
    }

    handleDataEvent({action, element}: {action: string, element: string}) {
      DataEventTreeDiaryInteraction('myDiary', action, element);
    }
  }
