angular.module('ngApp')
// Controller per la tab delle emissioni di ogni singolo albero (chart e termine di paragone).
.controller('treeCO2Controller', ['$scope', '$rootScope', '$stateParams', '$timeout', 'Utility', 'Tree', '$filter', function ($scope, $rootScope, $stateParams, $timeout, Utility, Tree, $filter) {
  $scope.emissionSearched = false

  Object.defineProperty($scope, 'yearValue',{
    get() {
      const val = (this.dataComplete)
        ? Math.round(Math.max(...this.dataComplete) * 10) / 100
        : 0
      return (val).toLocaleString($rootScope.ln)
    }
  })

  var comparisonEmission = [
    {
      value: 0.023,
      category: 'alimentation',
      icon: $filter('asset')('bundles/treedomnewfrontend/images/calculator/coffee.png'),
      title: $filter('trans')('pageTree.co2.comparisonValue.cofee')
    },
    {
      value: 0.097,
      category: 'home',
      icon: $filter('asset')('bundles/treedomnewfrontend/images/calculator/tv.png'),
      title: $filter('trans')('pageTree.co2.comparisonValue.tv')
    },
    {
      value: 0.5,
      category: 'alimentation',
      icon: $filter('asset')('bundles/treedomnewfrontend/images/calculator/beer.png'),
      title: $filter('trans')('pageTree.co2.comparisonValue.beer')
    },
    {
      value: 0.8,
      category: 'home',
      icon: $filter('asset')('bundles/treedomnewfrontend/images/calculator/laundry.png'),
      title: $filter('trans')('pageTree.co2.comparisonValue.laundry')
    },
    {
      value: 1.5,
      category: 'alimentation',
      icon: $filter('asset')('bundles/treedomnewfrontend/images/calculator/champagne.png'),
      title: $filter('trans')('pageTree.co2.comparisonValue.champagne')
    },
    {
      value: 17,
      category: 'alimentation',
      icon: $filter('asset')('bundles/treedomnewfrontend/images/calculator/steak.png'),
      title: $filter('trans')('pageTree.co2.comparisonValue.steak')
    },
    {
      value: 110,
      category: 'trip',
      icon: $filter('asset')('bundles/treedomnewfrontend/images/calculator/scooter.png'),
      title: $filter('trans')('pageTree.co2.comparisonValue.scooter')
    },
    {
      value: 350,
      category: 'trip',
      icon: $filter('asset')('bundles/treedomnewfrontend/images/calculator/car.png'),
      title: $filter('trans')('pageTree.co2.comparisonValue.car')
    },
    {
      value: 650,
      category: 'trip',
      icon: $filter('asset')('bundles/treedomnewfrontend/images/calculator/plane.png'),
      title: $filter('trans')('pageTree.co2.comparisonValue.plane')
    }
  ]

  $scope.inizializeComparison = function () {
    var co2AbsorbedYear = $scope.co2PerYear[new Date().getFullYear()] / 1000000
    $scope.selectedEmission = comparisonEmission.reduce((acc, emission) => (co2AbsorbedYear > emission.value) ? emission : acc)
    $scope.totalEmission = comparisonEmission.reduce((acc, emission) => ($rootScope.singleTree.tankSize > emission.value) ? emission : acc)
    $timeout(function () {
      $scope.emissionSearched = true
        Utility.allowScroll()
    }, 300)
  }

  $scope.inizializeTreeEmissionChart = function () {
    var treeTankSize = $rootScope.singleTree.tankSize
    // oggetto con la co2 dei 10 anni in kg
    var dataComplete = $scope.dataComplete
    // co2 dell'anno in corso in kg
    var dataCurrentYear = $scope.dataCurrentYear

    var serieTitle = $filter('trans')('pageTree.co2.chart.serieTitle_01')

    $scope.series = [serieTitle]

    $scope.data = [dataComplete.map(Number)]

    $scope.onClick = function (points, evt) {
      // console.log(points, evt)
    }

    $scope.datasetOverride = [
      {
        yAxisID: 'y-axis-1',
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: '#90a6ba',
        pointRadius: 0,
        pointHoverRadius: 0
      }
    ]

    $scope.options = {
      stacked: false,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: $filter('trans')('signup.birthdate.year')
            },
            gridLines: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            id: 'y-axis-1',
            type: 'linear',
            display: true,
            position: 'left',
            stacked: false,
            gridLines: {
              display: false
            },
            ticks: {
              max: treeTankSize
            },
            scaleLabel: {
              display: true,
              labelString: 'Kg'
            }
          }
        ]
      }
    }
  }

  Tree.treeEmissionNeutralized($stateParams.item)
    .then(function(response) {
      response = response.data || response
      $scope.treeEmissions = response
    })

  $scope.$on('TreeIsFound', function () {
    $scope.inizializeTreeEmissionChart()
    $scope.inizializeComparison()
  })

  if ($rootScope.singleTree) {
    $scope.inizializeTreeEmissionChart()
    $scope.inizializeComparison()
  }
}])
