/* @ngInject */
export default function productQtCardController($rootScope) {
  this.product = {}
  this.quantity = this.quantity || 0
  this.imageBackground = {}
  this.name = ''
  this.country = ''
  this.qtSteps = [1, 5, 10, 20, 50, 100]

  this.$onInit = () => {
    // Set icon
    const icon = (this.product.limited || this.product.specie).icon.medium
    this.imageBackground['backgroundImage'] = `url(${icon})`
    // Set name
    this.name = this.product.limited && this.product.limited.limitedName[$rootScope.ln] != 'Limited Edition' ? this.product.limited.limitedName[$rootScope.ln] : this.product.specie.name[$rootScope.ln]
    // Set country
    this.country = this.product.specie && this.product.specie.country.name ? this.product.specie.country.name[$rootScope.ln] : ''
    // Set meaning
    this.meaning = this.product.specie.sayTitle && this.product.specie.sayTitle[$rootScope.ln] || ''
    // Set template flags
    this.isPreview = this.template === 'preview'
    // Set prices and discount
    this.onSale = this.product.badge === 'discount'
    this.price = this.product.price / 100
    this.fullPrice = this.getFullPrice()
  }

  this.wrapClasses = () => {
    return {
      treeWrapPreview: this.isPreview,
      treeWrap: !this.isPreview,
      active: this.quantity
    }
  }

  this.getFullPrice = () => {
    if (!(this.onSale && this.product.badgeDiscount)) return this.price
    return this.price * (1 + (this.product.badgeDiscount / 100))
  }

  this.onClickCallback = () => {
    this.onClick({ product: this.product })
  }

  this.onChangeCallback = (qt) => {
    this.onChange({ qt, product: this.product })
  }

  Object.defineProperty(this, 'isMobile', { get: () => !!$rootScope.isMobile })
}
