import { render, staticRenderFns } from "./UsersNumbersMediakit.vue?vue&type=template&id=5cdd20ef&scoped=true&"
import script from "./UsersNumbersMediakit.vue?vue&type=script&lang=ts&"
export * from "./UsersNumbersMediakit.vue?vue&type=script&lang=ts&"
import style0 from "./UsersNumbersMediakit.vue?vue&type=style&index=0&id=5cdd20ef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cdd20ef",
  null
  
)

export default component.exports