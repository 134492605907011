





























































































import {defineComponent, PropType, ref} from '@vue/composition-api'
import Treecon, {TreeconIcon} from '@f/components/Treecons/Treecon.vue'

const ListItem = defineComponent({
  name: 'ListItem',
  components: { Treecon },
  props: {
    href: String,
    title: String,
    description: String,
    icon: String as PropType<TreeconIcon>,
  },
  template: `
    <a :href="href" class='item'>
      <div class='icon'>
        <Treecon :icon='icon' size="normal" />
      </div>
      <div class='content'>
        <h6 class='contentTitle'>
          {{ title }}
        </h6>
        <div class='description'>
          {{ description }}
        </div>
      </div>
    </a>
  `,
})

const List = defineComponent({
  name: 'List',
  components: { ListItem },
  props: {
    title: String,
    double: Boolean,
  },
  template: `
    <div :class='{ "section": true, "double": double }'>
      <h4 class='title'>{{ title }}</h4>
      <div class='divider'></div>
      <div class='items'>
        <slot />
      </div>
    </div>
  `,
})

export default defineComponent({
  name: 'CustomBusinessSection',
  components: { Treecon, List, ListItem },
  setup() {
    const double = ref(false)
    return {
      double,
    }
  }
})
