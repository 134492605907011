























import { Component, Mixins } from "vue-property-decorator";
import Popover from "@f/components/Popover.vue";
import { ShowLanguages } from "@f/mixins";
import Icon from '@f/components/Icon.vue'

@Component({
  name: "LanguageSelector",
  components: {
    Popover,
    Icon,
  },
})
export default class LanguageSelector extends Mixins(ShowLanguages) {
  openPopover: boolean = false;
}
