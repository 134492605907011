/* @ngInject */
angular.module('ngApp')
  .controller('customerCareController', function ($rootScope, $scope, $state, $document, $timeout, $animate, Utility) {
    // Enable ngAnimate
    $animate.enabled(true)

    $scope.faqs = [];
    $scope.expand = {};

    Utility.faq()
      .then(function (data) {
        $scope.faqs = data
        init()
      });

    $scope.sendFaqVote = function (categoryId, faqId, type) {
      var categoryIndex = $scope.faqs.findIndex(f => f.id === categoryId);
      var faqIndex = $scope.faqs[categoryIndex].faqs.findIndex(f => f.id === faqId);
      $scope.voting = true
      Utility.faqVote(faqId, type)
        .then((data) => {
          $scope.faqs[categoryIndex].faqs[faqIndex].userVote = data.userVote
        })
        .then(() => {
          $scope.voting = false
        })
    };

    const init = () => {
      if (!$scope.$$phase) $scope.$apply();
      if ($state.params.category) {
        $scope.expand[$state.params.category] = true
        let scrollTarget = $state.params.category
        if ($state.params.faqId) {
          const faqId = `faq_${$state.params.faqId}`
          $scope.expand[faqId] = true
          scrollTarget = faqId
        }
        $state.go('customercare', null, { notify: false })
        $timeout(() => {
          const navbarEl = document.getElementById('navbar')
          const offset = navbarEl && navbarEl.offsetHeight || 0
          $document.scrollToElement(document.getElementById(scrollTarget), offset)
        })
      }
    }

    $rootScope.statusFaqListener = $scope.$on('$stateChangeStart', statusFaqStateListener)
    
    function statusFaqStateListener(event, toState, toParams, fromState, fromParams) {
      if (!(/customercare\./).test(toState.name)) return
      $scope.expand = {}
      let scrollTarget = null
      if (toParams.category) {
        $scope.expand[toParams.category] = true
        scrollTarget = toParams.category
      }
      if (toParams.faqId) {
        const faqId = `faq_${toParams.faqId}`
        $scope.expand[faqId] = true
        scrollTarget = faqId
      }
      if (fromState.name !== 'customercare') {
        event.preventDefault()
      } else {
        $state.go('customercare', null, { notify: false })
        $timeout(() => {
          const navbarEl = document.getElementById('navbar')
          const offset = navbarEl && navbarEl.offsetHeight || 0
          $document.scrollToElement(document.getElementById(scrollTarget), offset)
        })
      }
    }

  })
