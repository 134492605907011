import { DataEventToggleFollow } from '@f/services/TagManager/events';
import isUndefined from 'lodash/isUndefined'
const { getWidgetPosition, isCursorOn } = require("./widgetUtils.js");

angular.module('ngApp')
  /**
  * Widget Utente.
  * NOTE: Specificare altezza e larghezza del widget che deve essere fissa!
  *
  * @param {Object} attrs
  * @param {Object} attrs.widgetKey - Chiave per salvare l'oggetto in una cache temporanea (preferibilmente ID o SLUG)
  * @param {Object} attrs.widgetUserSlug - SLUG dell'utente.
  * @param {string | boolean} attrs.disabled - Se true, il widget non viene mostrato
  */
  .directive('widgetuser', ['$rootScope', '$compile', '$document', '$interval', 'Utility', 'Modal', 'User', function ($rootScope, $compile, $document, $interval, Utility, Modal, User) {
    var _tempCache = {}
    var mouseOverElement = false
    var renderInterval

    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {
        if ($rootScope.isMobile) return
        if (attrs.widgetDisabled) return

        var _activeWidget = false
        var widget = { width: 240, height: 381 }

        // Cerca il widget nella cache
        function findInCache(widgetKey) {
          if (!widgetKey || isUndefined(_tempCache[widgetKey])) return false
          return _tempCache[widgetKey]
        }

        // Rimuove il widget.
        function removeWidget(widget) {
          window.removeEventListener('mousemove', mouseMoveListener)
          widget.remove()

          // Controllo per sicurezza se esistono altri widget e li cancello.
          var otherWidgets = $document.find('.w_' + _activeWidget.key)
          if (otherWidgets.length) {
            otherWidgets.each(function (index) {
              otherWidgets[index].remove()
            })
          }

          _activeWidget = false
        }

        // Listener movimento mouse.
        function mouseMoveListener(e) {
          var widget = $document.find('#' + _activeWidget.key)

          if (!isCursorOn(element, e) && !isCursorOn(widget, e)) removeWidget(widget)

          $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            var widget = $document.find('#' + _activeWidget.key)
            removeWidget(widget)
          })
        }

        // Funzione per renderizzare il widget con i dati richiesti.
        function renderWidget(widgetKey) {
          if (!mouseOverElement) return

          widgetKey = widgetKey || attrs.widgetKey
          _activeWidget = _tempCache[widgetKey]
          _activeWidget.key = widgetKey

          // Creo il template del widget.
          var position = getWidgetPosition(element, widget)
          var template = '../../bundles/treedomnewfrontend/html-templates/widget/user.html'
          var style = 'top: ' + position.top + 'px; ' + 'left: ' + position.left + 'px;'
          var arrowUp = position.position === 'bottom' ? '<div class="arrow-up" style="left: ' + position.offsetLeftArrow + 'px"></div>' : ''
          var arrowDown = position.position === 'top' ? '<div class="arrow-down" style="left: ' + position.offsetLeftArrow + 'px"></div>' : ''
          var html = '<div id="' + _activeWidget.key + '" style="' + style + '" class="widgetContainer w_' + _activeWidget.key + '"><div class="widgetContent">' + arrowUp + '<div ng-include="\'' + template + '\'"></div>' + arrowDown + '</div></div>'

          // Attacco allo $scope l'utente attivo.
          $scope.widgetUser = _activeWidget

          $scope.widgetToggleFollow = function (slug) {
            if (!$rootScope.user) {
              Modal.open({ templateUrl: 'login.html', easyClose: false, fitcontent: true })
              return
            }

            Utility.disableButton()

            User.toggleFollow(slug)
              .then(function (response) {
                const data = response.data || response
                $rootScope.userdata.following = data

                const shouldFollow = data.includes(slug)
                DataEventToggleFollow(shouldFollow, 'user-widget','user');

                Utility.enableButton()
              })
              .catch(function () {

                Utility.enableButton()
              })
          }

          // Appendo il widget in fondo al body.
          var body = $document.find('body').eq(0)
          body.append($compile(angular.element(html))($scope))
          if (!$scope.$$phase) $scope.$apply()

          // Creo l'ascoltatore per il movimento del mouse.
          window.addEventListener('mousemove', mouseMoveListener)
        }

        // Funzione per renderizzare il widget con un intervallo di tempo.
        function renderWidgetWithInterval(widgetKey, milliseconds) {
          if (renderInterval) clearInterval(renderInterval)

          renderInterval = setInterval(function () {
            renderWidget(widgetKey)
            clearInterval(renderInterval)
          }, milliseconds)
        }

        // Mouseenter per creare il widget.
        element.on('mouseenter', function () {
          mouseOverElement = true
          var widgetKey = attrs.widgetKey
          var widgetUserSlug = attrs.widgetUserSlug

          // Se il widget esiste, non lo ricreo.
          var oldWidget = document.getElementById(widgetKey)
          if (oldWidget) return

          // Lo cerco nella cache.
          if (findInCache(widgetKey)) {
            renderWidgetWithInterval(widgetKey, 700)
            return
          }

          // Caso in cui il widget non è disponibile nella cache
          if (!findInCache(widgetKey)) {
            Utility.getWidgetData('user', widgetUserSlug, 0)
              .then(function (response) {
                response = response.data || response
                if (response.info.user_type === 'Business') return

                // Alberi regalati
                if(response.trees) {
                  response.treesGiven = response.trees.filter(function (tree) {
                    return tree.initialuser.slug === response.info.slug && tree.initialuser.id !== tree.user.id
                  })
  
                  // Alberi piantati
                  response.plantedTrees = response.trees.filter(function (tree) {
                    return tree.initialuser.slug === response.info.slug
                  })
  
                  // Alberi custoditi
                  response.trees = response.trees.filter(function (tree) {
                    return tree.user.id === response.info.id
                  })
                }

                _tempCache[widgetKey] = response
                renderWidgetWithInterval(widgetKey, 700)
              })
          }
        })

        element.on('mouseleave', function () {
          mouseOverElement = false
          clearInterval(renderInterval)
        })
      }
    }
  }])
