const { getWidgetPosition, isCursorOn } = require("./widgetUtils.js");

angular.module('ngApp')
  /**
  * Widget cart.
  * NOTE: Specificare altezza e larghezza del widget che deve essere fissa!
  *
  * @param {Object} attrs
  * @param {Object} attrs.widgetKey - Chiave per salvare l'oggetto in una cache temporanea (preferibilmente ID o SLUG)
  */
  .directive('widgetcart', ['$rootScope', '$compile', '$document', '$timeout', '$state', 'Utility', 'Cart', function ($rootScope, $compile, $document, $timeout, $state, Utility, Cart) {
    let mouseOverElement = false
    let renderInterval
    let openDelay = 0

    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {

        const widget = { width: 360, height: 448 }
        const _mobileBreakpoint = 769

        let _activeWidget = false
        let _openTimeout
        let _closeTimeout

        $scope.hasFeedback = false
        
        $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
          immediateRemove()
        })

        $scope.$on('userdata.cart.update', function(_, { action }) {
          if (action !== 'productAssigned') return
          const widgetKey = attrs.widgetKey || 'CartWidget'
          $scope.hasFeedback = true
          renderWidgetWithInterval(widgetKey, openDelay, true)
        })

        element.on('click', immediateRemove)

        // Mouseenter per creare il widget.
        element.on('mouseenter', function () {
          mouseOverElement = true
          // Prendo la chiave del carrello dagli attributi se non c'è la assegno io.
          let widgetKey = attrs.widgetKey || 'CartWidget'
          // Se il widget esiste, non lo ricreo.
          let oldWidget = document.getElementById(widgetKey)
          if (oldWidget  && oldWidget.style.display !== 'none') return

          renderWidgetWithInterval(widgetKey, openDelay)
        })

        element.on('mouseleave', function () {
          mouseOverElement = false
          clearInterval(renderInterval)
          if (!_activeWidget) Utility.allowScroll()
        })
        
        // Rimuove il widget.
        function removeWidget(widget) {
          if (!_activeWidget.key) return
          window.removeEventListener('mousemove', mouseMoveListener)
          window.removeEventListener('resize', updateSizeAndPosition.bind(undefined, undefined))
          let el = document.getElementById(_activeWidget.key)
          const overlayEl = angular.element(`#${_activeWidget.key}_overlay`)
          overlayEl.removeClass('widgetCartOverlay--active')
          if (!el) return
          // Animazione widget
          el.style.transform = 'scale(0)'
          el.style.opacity = '0'
          $timeout(function() {
            $scope.hasFeedback = false
            // Rimuovo l'ascoltatore per il movimento del mouse.
            Utility.allowScroll()
            setCloseTimeout(null)
            widget.remove()
            overlayEl.remove()
            // Controllo per sicurezza se esistono altri widget e li cancello.
            const otherWidgets = $document.find('.w_' + _activeWidget.key)
            if (otherWidgets.length) {
              otherWidgets.each(function (index) {
                otherWidgets[index].remove()
              })
            }
            _activeWidget = false
          }, 150)
        }

        function immediateRemove() {
          $scope.hasFeedback = false
          window.removeEventListener('mousemove', mouseMoveListener)
          window.removeEventListener('resize', updateSizeAndPosition.bind(undefined, undefined))
          if (renderInterval) clearInterval(renderInterval)
          if (_openTimeout) $timeout.cancel(_openTimeout)
          const widgetElement = $document.find(`#${_activeWidget.key}`)
          const overlayElement = angular.element(`#${_activeWidget.key}_overlay`)
          if (widgetElement && widgetElement.length) {
            widgetElement.remove()
          }
          if (overlayElement && overlayElement.length) {
            overlayElement.remove()
          }
        }

        // Listener movimento mouse.
        function mouseMoveListener(e) {
          let widgetElement = $document.find('#' + _activeWidget.key)

          if (!isCursorOn(element, e) && !isCursorOn(widgetElement, e) && !_closeTimeout) {
            removeWidget(widgetElement)
          } else if (isCursorOn(widgetElement, e)) {
            $scope.hasFeedback = false
            $scope.$apply()
            setCloseTimeout(null)
          }

        }

        function openWidget() {
          if (!_activeWidget.key) return
          // Animazione widget
          _openTimeout = $timeout(function () {
            const el = angular.element(`#${_activeWidget.key}`)
            el.css({
              transform: 'scale(1)',
              opacity: 1
            })
            const overlayEl = angular.element(`#${_activeWidget.key}_overlay`)
            overlayEl.addClass('widgetCartOverlay--active')
            $timeout(function () {
              const list = el.find('.widgetCartList').eq(0)
              if ($scope.hasFeedback) {
                const children = list.children()
                list.scrollTop(children.innerHeight() * children.length)
              }
            }, 100)

            // Creo l'ascoltatore per il movimento del mouse.
            if (!$rootScope.isMobile) window.addEventListener('mousemove', mouseMoveListener)
            window.addEventListener('resize', updateSizeAndPosition.bind())
          }, 1)
        }

        function updateSizeAndPosition(widgetElement, overlayElement) {
          widgetElement = !(widgetElement || widgetElement instanceof Event)
            ? widgetElement
            : angular.element(`#${_activeWidget.key}`)
          overlayElement = overlayElement || angular.element(`#${_activeWidget.key}_overlay`)
          const { width, height, top, left, offsetLeftArrow, position, overlayMargin } = getWidgetPosition(element, widget, _mobileBreakpoint, true)
          widgetElement.css({
            top: `${top}px`,
            left: `${left}px`,
            width: `${width}px`,
            height: `${height}px`,
            transformOrigin: `${offsetLeftArrow}px 0px`
          })
          widgetElement.find('.arrow-up').css({
            left: `${offsetLeftArrow}px`,
            display: position === 'bottom' ? 'block' : 'none'
          })
          widgetElement.find('.arrow-down').css({
            left: `${offsetLeftArrow}px`,
            display: position === 'top' ? 'block' : 'none'
          })
          overlayElement.css({
            marginTop: `${overlayMargin.top}px`
          })
          if (!$scope.$$phase) $scope.$apply()
        }

        function setCloseTimeout(widget, closeDelay = 3000) {
          if (!!_closeTimeout) $timeout.cancel(_closeTimeout)
          if (widget && !$rootScope.isMobile) {
            _closeTimeout = $timeout(function() { removeWidget(widget) }, closeDelay)
          } else if ($scope.hasFeedback) {
            $timeout(function() { $scope.hasFeedback = false }, closeDelay)
          } else {
            _closeTimeout = null
          }
        }

        // Funzione per renderizzare il widget con un intervallo di tempo.
        function renderWidgetWithInterval(widgetKey, milliseconds, cartFeedback = false) {
          if (renderInterval) clearInterval(renderInterval)

          renderInterval = setInterval(function () {
            renderWidget(widgetKey, cartFeedback)
            clearInterval(renderInterval)
          }, milliseconds)
        }

        // Aggiorna la variabile $scope.widgetCart dove viene 'clonato' $rootScope.userdata.cart per evitare modifiche
        // dell'oggetto originale.
        function updateCartInfo(cart) {
          $scope.widgetCart = { ...cart };
          $scope.widgetCart.items = $scope.widgetCart.items.map(function (item) {
            item.gift = item.gift == 1;
            return item;
          });
          $scope.voucherInfo = Cart.getVoucherInfo(cart)
          $scope.voucherUniqueInfo = Cart.getVoucherUniqueInfo(cart)

          if (!$scope.$$phase) $scope.$apply()
        };

        // Funzione per renderizzare il widget con i dati richiesti.
        function renderWidget(widgetKey, cartFeedback = false) {
          if (!mouseOverElement && !cartFeedback) return
          if (!$rootScope.userdata) return

          widgetKey = widgetKey || $rootScope.userdata.cart.id
          _activeWidget = $rootScope.userdata.cart
          _activeWidget.key = widgetKey

          // Attacco allo $scope l'utente attivo.
          $scope.widgetCart = _activeWidget
          $scope.widgetRemoving = []

          $scope.goOrClose = (ev, state) => {
            ev && ev.preventDefault && ev.preventDefault()
            $state.current.name === state && $scope.closeWidget() || $state.go(state)
          }

          $scope.closeWidget = function (ev) {
            ev && ev.preventDefault && ev.preventDefault()
            removeWidget($document.find('#' + _activeWidget.key))
          }

          $scope.widgetDeleteCartItem = function (itemID) {
            Utility.disableButton()
            $scope.widgetRemoving.push(itemID)
            Cart.removeProduct($scope.widgetCart.id, itemID)
              .then(cart => {
                updateCartInfo(cart)
              })
              .catch(error => {
                console.error('cartController -> $scope.removeProduct', error)
              })
              .finally(() => {
                $scope.widgetRemoving.pop(itemID)
              })
          }

          $scope.isRemoving = function (id) {
            return $scope.widgetRemoving.includes(id)
          }

          $scope.voucherInfo = Cart.getVoucherInfo($scope.widgetCart)
          $scope.voucherUniqueInfo = Cart.getVoucherUniqueInfo($scope.widgetCart)

          $scope.widgetBusinessCart = Cart.groupTreesByQuantity(_activeWidget.items)

          // Appendo il widget in fondo al body.
          const component = angular.element(`#${_activeWidget.key}`).eq(0)
          let compiledWidget = component.length ? component : function() {
            // Creo il template del widget.
            let html = `
            <div id="${_activeWidget.key}" style="transform: scale(0.2); opacity: 0; transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1), opacity 0.1s ease-in-out;" class="widgetContainer widgetContainerFixed w_${_activeWidget.key}">
              <div class="widgetContent">
                <div class="arrow-up"></div>
                <div ng-include="'../../bundles/treedomnewfrontend/html-templates/widget/cart.html\'"></div>
                <div class="arrow-down"></div>
              </div>
            </div>
            `
            let overlayHtml = `<div id="${_activeWidget.key}_overlay" class="widgetCartOverlay"></div>`
            const newComponent = $compile(angular.element(html))($scope)
            let body = $document.find('body').eq(0)
            body.append(newComponent)
            body.append(overlayHtml)
            return newComponent
          }()
          Utility.blockScroll()
          updateSizeAndPosition(compiledWidget)
          if (cartFeedback)  setCloseTimeout(compiledWidget)
          openWidget()
        }
      }
    }
  }])
