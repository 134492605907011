

















import {Vue, Component, Prop} from 'vue-property-decorator'

@Component
export default class AppleLoginButton extends Vue {
  @Prop({type: String, default: 'Apple'}) readonly text!: string
  @Prop({type: Boolean, default: false}) readonly fullWidth!: boolean

  // Events
  mounted() {
  }


  appleLogin(): void {
    this.$emit('submit')
    this.$emit('success')

  }

}
