







































import { defineComponent } from "@vue/composition-api";
import { getStateService } from "@f/services/Angular";
import Modal from "@f/components/Modal.vue";
import DotLoader from "@f/components/DotLoader.vue";
import Treecon from "@f/components/Treecons/Treecon.vue";
import { shareRewardByMail } from "../../../gameData";
import {
  DataEventGameSentGift,
  DataEventGameCtaUnderstand,
} from "@f/services/TagManager/events";

export default defineComponent({
  name: "ShareRewardByMail",
  components: {
    DotLoader,
    Modal,
    Treecon,
  },
  props: {
    treecodeUuid: { type: String, required: true },
  },
  data() {
    return {
      email: "",
      isValidMail: true,
      sendingMail: false,
      mailSent: false,
    };
  },
  methods: {
    validateEmail() {
      const emailValidator = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
      this.isValidMail = emailValidator.test(this.email);
    },
    sendMail() {
      this.validateEmail();
      if (this.isValidMail) {
        this.sendingMail = true;
        shareRewardByMail(this.treecodeUuid, this.email).then(() => {
          this.sendingMail = false;
          this.mailSent = true;
          DataEventGameSentGift();
        });
      }
    },
    redirectToIndex() {
      DataEventGameCtaUnderstand();
      getStateService()?.go("index");
    },
  },
  watch: {
    email(value) {
      this.email = value;
      this.validateEmail();
    },
  },
});
