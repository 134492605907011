import UAParser from 'ua-parser-js'
import { onResize } from '@f/composables/hooks/resize'
import { ref, onMounted } from '@vue/composition-api'
import scssVariables from '@f/scss/design-system/_export.scss'

let uaParser: UAParser
let device: UAParser.IDevice

enum BreakpointsName {
  big = 'big' ,
  extralarge = 'extralarge',
  extrasmall = 'extrasmall',
  huge = 'huge',
  large = 'large',
  main = 'main',
  medium = 'medium',
  small = 'small',
  tiny = 'tiny'
}

export type BreakpointsList = {
  [key in BreakpointsName | number]: number
}

function widthVariables() {
   return Object.keys(scssVariables)
    .reduce((breakpoints, key) => {
      if (/^width-/.test(key)) {
        const breakpoint = {
          [key.replace('width-', '')]: parseInt(scssVariables[key])
        }
        breakpoints = Object.assign(breakpoints, breakpoint)
      }
      return breakpoints
    }, {} as BreakpointsList)
}

export function responsive() {
  const windowWidth = ref(window.innerWidth)
  const windowHeight = ref(window.innerHeight)
  const isMobile = ref(false)
  const isTablet = ref(false)
  const isDesktop = ref(true)
  const breakpoints = ref<BreakpointsList>(widthVariables())
  const currentBreakpoint = ref(setBreakpoint(window.innerWidth))

  function setBreakpoint(width: number) {
    return Object.entries(breakpoints.value)
      .sort((a,b) => b[1]-a[1])
      .find((val, key) => {
      return breakpoints.value[key] < width
    })
  }

  onResize(({ width, height }) => {
    windowWidth.value = width
    windowHeight.value = height
    currentBreakpoint.value = setBreakpoint(width)
  })

  onMounted(() => {
    const uaDevice = device
      || (device = (uaParser?.getDevice() || (new UAParser().getDevice())))
    isMobile.value = uaDevice.type === 'mobile'
    isTablet.value = uaDevice.type === 'tablet'
    isDesktop.value = uaDevice.model === undefined
  })

  return {
    breakpoints,
    currentBreakpoint,
    isDesktop,
    isMobile,
    isTablet,
    windowHeight,
    windowWidth,
  }
}