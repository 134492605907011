import { JSONSchemaType } from 'ajv'
import { UserProfile } from '@f/@types'

export interface IUserCheckSchema {
  email: string
}

export interface ISignInSchema {
  username: string
  password: string
}

export interface IHomonymySignInSchema {
  id: number
  token: string
  password: string
  locale: string
  allowCommunications: boolean
}

export interface IHomonymySignUpSchema {
  token: string
  locale: string
  allowCommunications: boolean
}

export interface ISignUpSchema {
  email: string
  confirmEmail: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
  allowPromotion: boolean
  allowNewsletter: boolean
}

export interface IPasswordResetRequestSchema {
  email: string
}

export interface IPasswordResetSchema {
  email: string,
  password: string
}

export const UserCheckSchema: JSONSchemaType<IUserCheckSchema> = {
  type: 'object',
  properties: {
    email: { type: 'string', format: 'email'},
  },
  required: ['email']
}

export const SignInSchema: JSONSchemaType<ISignInSchema> = {
  type: 'object',
  properties: {
    username: { type: 'string', format: 'email', nonEmpty: true },
    password: { type: 'string', nonEmpty: true },
  },
  required: ['username', 'password']
}

export const HomonymySignInSchema: JSONSchemaType<IHomonymySignInSchema> = {
  type: 'object',
  properties: {
    id: { type: 'number' },
    token: { type: 'string', nonEmpty: true },
    password: { type: 'string', nonEmpty: true },
    locale: { type: 'string', minLength: 2 },
    allowCommunications: { type: 'boolean' },
  },
  required: ['id', 'token', 'password', 'locale', 'allowCommunications']
}

export const HomonymySignUpSchema: JSONSchemaType<IHomonymySignUpSchema> = {
  type: 'object',
  properties: {
    token: { type: 'string', nonEmpty: true },
    locale: { type: 'string', minLength: 2 },
    allowCommunications: { type: 'boolean' },
  },
  required: ['token', 'locale', 'allowCommunications'],
}

export const SignUpSchema: JSONSchemaType<ISignUpSchema> = {
  type: 'object',
  properties: {
    email: { type: 'string', format: 'email', nonEmpty: true },
    confirmEmail: {
      type: 'string',
      format: 'email',
      nonEmpty: true,
      const: {
        $data: '1/email'
      }
    },
    password: { type: 'string', nonEmpty: true },
    confirmPassword: {
      type: 'string',
      nonEmpty: true,
      const: {
        $data: '1/password'
      }
    },
    firstName: { type: 'string', nonEmpty: true },
    lastName: { type: 'string', nonEmpty: true },
    allowPromotion: { type: 'boolean' },
    allowNewsletter: { type: 'boolean' },
    country: { type: 'string', nonEmpty: true },
    countryCode: { type: 'string', nonEmpty: true }
  },
  required: ['email', 'confirmEmail', 'password', 'confirmPassword', 'firstName', 'lastName', 'allowPromotion', 'allowNewsletter']
}

export const PasswordResetRequestSchema: JSONSchemaType<IPasswordResetRequestSchema> = {
  type: 'object',
  properties: {
    email: { type: 'string', format: 'email', nonEmpty: true }
  },
  required: ['email']
}

export const PasswordResetSchema: JSONSchemaType<IPasswordResetSchema> = {
  type: 'object',
  properties: {
    email: { type: 'string', nonEmpty: true },
    password: { type: 'string', nonEmpty: true },
    passwordConfirm: {
      type: 'string',
      nonEmpty: true,
      const: {
        $data: '1/password'
      }
    },
  },
  required: ['password', 'passwordConfirm']
}

export const PrivateProfileCheckSchema: JSONSchemaType<UserProfile> = {
  type: 'object',
  properties: {
    usertype: {
      const: 'Private'
    },
    gender: {
      type: 'string',
      nonEmpty: true
    },
    dateOfBirth: {
      type: 'string',
      nonEmpty: true
    },
    countryCode: {
      type: 'string',
      nonEmpty: true
    }
  },
  required: [ 'usertype', 'gender', 'dateOfBirth', 'countryCode' ]
}
