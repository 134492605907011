import { Vue, Component } from 'vue-property-decorator'

export type JobCallback = (result?: boolean | void) => any

class JobError extends Error {
  constructor(message?: string, public callback?: JobCallback) {
    super(message)
  }
}

export class Job {
  constructor(
    private _reference: string | number,
    private _task: (...payload: any) => Promise<any>,
    private _callback?: JobCallback
  ) {}

  get reference(): string {
    return this._reference.toString()
  }

  run(): Promise<JobCallback | undefined> {
    return this._task()
      .then(() => this._callback)
      .catch((error: Error & { data?: { message: string } }) => {
        throw new JobError(
          error.data ? error.data.message : error.message,
          this._callback
        )
      })
  }

  dismiss(): void {
    this._callback && this._callback()
  }
}

type Queue = Job[]

type QueuesPool = {
  [key: string]: Queue
}

type QueueWorker = Promise<any> | undefined

type WorkersPool = {
  [key: string]: QueueWorker
}

@Component
export default class ManageQueues extends Vue {
  queues: QueuesPool = {}
  workers: WorkersPool = {}

  addJobToQueue(queueName: string, job: Job): void {
    this.queues[queueName] = this.queues[queueName] || []
    this.queues[queueName].push(job)
    this.workers[queueName] =
      this.workers[queueName] || this.createNewWorker(queueName)
  }

  createNewWorker(queueName: string): Promise<any> | undefined {
    if (!this.queues[queueName].length) return
    if (!!this.queues[queueName][0]) {
      return this.queues[queueName][0]
        .run()
        .then((callback?: JobCallback) => {
          callback && callback(true)
          this.queues[queueName].shift()
          this.workers[queueName] = this.createNewWorker(queueName)
        })
        .catch((error: JobError) => {
          console.log(error)
          error.callback && error.callback()
          while (this.queues[queueName].length) {
            const job = this.queues[queueName].shift()
            if (job) job.dismiss()
            delete this.workers[queueName]
          }
        })
    }
  }

  jobIsPending(queueName: string, jobReference: string | number) {
    return (
      this.queues[queueName] &&
      this.queues[queueName].some(
        (pendingJob) => pendingJob.reference === jobReference.toString()
      )
    )
  }
}
