import { JSONSchemaType } from 'ajv';
import { UserType } from '@f/models';
import {
  IAccountInfoSubmitted,
  IAuthentication,
  ICreateAccountStarted,
  IDeactivateSubscription,
  IRegistration,
  IUnsubscribe,
  IProfileInfoEdited,
  IProfileInfoCompleted,
  IProfileActivityInteraction
} from '@f/validation/types/dataLayer';


export const AuthenticationSchema: JSONSchemaType<IAuthentication> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: { type: 'string', enum: ['logIn', 'logOut'] },
    accountType: { type: 'string', enum: ['email', 'facebook', 'google'], nullable: true }
  }
}

export const UnsubscribeSchema: JSONSchemaType<IUnsubscribe> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: { type: 'string', const: 'unsubscription' },
  }
}

export const DeactivateSubscriptionSchema: JSONSchemaType<IDeactivateSubscription> = {
  type: 'object',
  required: ['event', 'name'],
  properties: {
    event: { type: 'string', const: 'deactivateSubscription' },
    name: { type: 'string' }
  }
}

export const CreateAccountStartedSchema: JSONSchemaType<ICreateAccountStarted> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: { type: 'string', const: 'createAccountStarted' }
  }
}

export const AccountInfoSubmittedSchema: JSONSchemaType<IAccountInfoSubmitted> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: { type: 'string', const: 'accountInfoSubmitted' }
  }
}

export const RegistrationSchema: JSONSchemaType<IRegistration> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: { type: 'string', const: 'registration' },
    accountType: { type: 'string', enum: Object.values(UserType) }
  }
}

export const ProfileInfoEditedSchema: JSONSchemaType<IProfileInfoEdited> = {
  type: 'object',
  required: ['event', 'profileCategory'],
  properties: {
    event: { type: 'string', const: 'profileInfoEdited' },
    profileCategory: { type: 'string' },
    fieldEdited: {
      type: ['array', 'string'],
      nullable: true,
      anyOf: [
        { type: 'array', items: { type: 'string'}},
        { type: 'string' }
      ],
    },
    profileAction: { 
      type: ['array', 'string'], 
      nullable: true,
      anyOf: [
        { type: 'array', items: { type: 'string' }, nullable: true},
        { type: 'string', nullable: true }
      ],
    },
  },
  additionalProperties: true
}

export const ProfileInfoCompletedSchema: JSONSchemaType<IProfileInfoCompleted> = {
  type: 'object', 
  required: ['event', 'profileAction'],
  properties: {
    event: { type: 'string', const: 'profileInfoCompleted' },
    profileAction: { type: 'string', enum: ['submitted', 'skip']}
  }
}

export const ProfileActivityInteractionSchema: JSONSchemaType<IProfileActivityInteraction> = {
  type: 'object',
  required: ['event', 'content', 'action', 'element', 'isOrganization'],
  properties: {
    event: { type: 'string' },
    content: { type: 'string' },
    action: { type: 'string', nullable: true },
    element: { type: 'string', nullable: true },
    isOrganization: { type: 'boolean' },
  }
}