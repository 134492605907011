angular.module('ngApp')
.controller('modalTreeUsesController', ['$scope', '$rootScope', '$timeout', 'Modal', 'Ecommerce', function ($scope, $rootScope, $timeout, Modal, Ecommerce) {
  $scope.allBadges = []

  $scope.getBadges = function() {
    Ecommerce.getBadges().then(function(resp) {
      $scope.allBadges = resp.treeBadges
      if (!$scope.$$phase) $scope.$apply()
    }).catch(function(err) {
      console.error(err)
    })
  };
}]);
