import Swiper from 'swiper'
import union from 'lodash/union'

angular.module('ngApp')
  .controller('ecommerceBoxController', ['$scope', '$rootScope', '$state', '$timeout', '$filter', 'Utility', 'Ecommerce', function ($scope, $rootScope, $state, $timeout, $filter, Utility, Ecommerce) {

    if (!$rootScope.sliders) {
      $rootScope.sliders = [];
    }

    var ctrl = this;
    ctrl.ecommerce = $scope.attrs.ecommerce;
    ctrl.ecommerces = $scope.attrs.ecommerces;
    ctrl.slider = $scope.attrs.slider && $scope.attrs.slider.toLowerCase() === "true";
    ctrl.preview = $scope.attrs.preview && $scope.attrs.preview.toLowerCase() === "true";
    ctrl.wrapperClasses = $scope.attrs.wrapperClasses;
    ctrl.backgroundImage = $scope.attrs.backgroundImage || '';
    ctrl.columns = $scope.attrs.columns || 3;
    ctrl.title = $filter('trans')($scope.attrs.title);
    ctrl.subTitle = $scope.attrs.subtitle ? $filter('trans')($scope.attrs.subtitle) : null;
    ctrl.banner = $scope.attrs.banner && $scope.attrs.banner.toLowerCase() === "true";
    // ctrl.bannerComponent = $scope.attrs.ecommerce || '';
    ctrl.ecommerceLoading = true;
    ctrl.ecommerceData = [];
    ctrl.sliderPV = $scope.attrs.sliderpv || 1;
    ctrl.sliderPG = $scope.attrs.sliderpg || 1;
    ctrl.minLength = $scope.attrs.minLength || 1;
    ctrl.minBulletsToView = $scope.attrs.minBulletsToView || 1;
    ctrl.hide = false;
    ctrl.counter = null
    ctrl.bestValue = $scope.attrs.bestValue || null
    $scope.customClick = $scope.attrs.customClick === 'true';
    $scope.showQuantity = $scope.attrs.showQuantity === 'true';
    ctrl.availableOnly = $scope.attrs.availableOnly || $scope.attrs.availableOnly === 'true' || false
    $scope.itemClickAction = function () {
        return $scope.attrs.onItemClick || null
    }

    var ecomms = ctrl.ecommerces && ctrl.ecommerces.length ? (ctrl.ecommerces.indexOf(",") >= 0 ? ctrl.ecommerces.split(",") : [ctrl.ecommerces]) : [ctrl.ecommerce];
    Ecommerce.getEcommerces(ecomms, undefined, 'treedom_horoscope_pack').then(function (ecomm) {
      ctrl.ecommerceLoading = false;
      //ctrl.ecommerceData = ecomm[ctrl.ecommerce];
      if(ctrl.bestValue && ecomm[ctrl.bestValue] && ecomm[ctrl.bestValue].length)  {
        ctrl.ecommerceData = ecomm[ctrl.bestValue].filter(product => ctrl.availableOnly ? product.badge !== 'soldOut' : true )
      }
      else {
        ecomms.forEach(function(_ecomm){
          ctrl.ecommerceData = union(ctrl.ecommerceData, ecomm[_ecomm])
          .filter(product => ctrl.availableOnly ? product.badge !== 'soldOut' : true )
        });
      }
      ctrl.hide = ctrl.ecommerceData.length == 0;
      if(ctrl.slider){
        $timeout(function(){
          renderSwiper();
        }, 4000)
      }
      ctrl.counter = 0
    });

    ctrl.isProductBundle = (product) => {
      return product.type === 'bundle'
    }

    ctrl.renderedElement = function () {
      if (ctrl.counter == 0 && ctrl.slider) {
        $timeout(function () {
          renderSwiper();
        })
      }
      ctrl.counter = (ctrl.counter * 1) + 1;
      return null;
    };

    var renderSwiper = function () {
      if ($rootScope.sliders[ctrl.ecommerce] && $rootScope.sliders[ctrl.ecommerce].length) {
        !Array.isArray($rootScope.sliders[ctrl.ecommerce]) ?
          $rootScope.sliders[ctrl.ecommerce].destroy(true, true) :
          $rootScope.sliders[ctrl.ecommerce].forEach(function (s) {
            s.destroy(true, true)
          })
      }
      var initSlide = (
          $rootScope.sliderHistory
          && $rootScope.sliderHistory.ecommerce === ctrl.ecommerce
        )
        ? ctrl.ecommerceData.findIndex(product => {
            return product.id == $rootScope.sliderHistory.productId
        })
        : 0;
        
      $rootScope.sliders[ctrl.ecommerce] = new Swiper('#Swiper'+ctrl.ecommerce, {
              slidesPerView: Math.min(ctrl.sliderPV, ctrl.ecommerceData.length),
              // centeredSlides: true,
              spaceBetween: 30,
              slidesPerGroup: Math.min(ctrl.sliderPG, ctrl.ecommerceData.length),
              nextButton: '#ButtonNextSlider'+ctrl.ecommerce,
              prevButton: '#ButtonPrevSlider'+ctrl.ecommerce,
              pagination: '#SliderPagination'+ctrl.ecommerce,
              paginationClickable: true,
              grabCursor: true,
              initialSlide: initSlide,
              // Responsive breakpoints
              breakpoints: {
                  // when window width is <= 480px
                  480: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                      slidesPerGroup: 1,
                      centeredSlides: true
                  },
                  // when window width is <= 640px
                  640: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                      slidesPerGroup: 1,
                      centeredSlides: true
                  },
                  991: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                      slidesPerGroup: 1,
                      centeredSlides: true
                  },
                  1200: {
                      slidesPerView: Math.min(2, ctrl.sliderPV, ctrl.ecommerceData.length),
                      spaceBetween: 30,
                      slidesPerGroup: Math.min(2, ctrl.sliderPG, ctrl.ecommerceData.length),
                      centeredSlides: true
                  }
              }
          })
    };
  }]);
