var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ntds-page"},[(!_vm.isMobile)?_c('UserGiftUpselling'):_vm._e(),_vm._v(" "),(_vm.isMobile && _vm.isOwner)?_c('DownloadAppSnackBanner'):_vm._e(),_vm._v(" "),_c('section',{staticClass:"ntds-page__header"},[_c('div',{staticClass:"ntds-container"},[_c('PrivateInfo',{staticClass:"user-page__info",attrs:{"user":_vm.user,"show-subscriber":_vm.show_subscriber},on:{"subscriberClick":_vm.onSubscriberClick}}),_vm._v(" "),_c('div',{staticClass:"user-page__map",on:{"click":_vm.goToMap}},[_c('Map',{staticClass:"user-page__mapcmp",attrs:{"data":_vm.mapData,"disabled":"","animate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dataLoaded = ref.dataLoaded;
return [_c('div',{staticClass:"user-page__map__overlay"},[(dataLoaded)?_c('button',{staticClass:"tr-button--primary tr-button--compact scale-up",on:{"click":_vm.goToMap}},[_c('Treecon',{attrs:{"icon":"map-line"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('map.explore'))}})],1):_vm._e()])]}}])})],1)],1)]),_vm._v(" "),_c('section',{staticClass:"ntds-page__body"},[_c('div',{staticClass:"ntds-container--flex"},[(_vm.isMobile)?_c('UserGiftUpselling'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"user-sidebar-container"},[_c('div',{staticClass:"ntds-page__sidebar",class:{ 'show-subscriptions': _vm.showSubscriptionTab }},[_c('ul',{staticClass:"ntds-tab-menu--medium"},[_c('li',{staticClass:"ntds-tab-menu__item",class:{
                active: _vm.isCurrentState(
                  'user',
                  { slug: _vm.slug },
                  { state: true }
                ),
              }},[_c('a',{staticClass:"ntds-tab-menu__label",attrs:{"href":_vm.href('user', { slug: _vm.slug })}},[_c('Treecon',{attrs:{"icon":_vm.routeIcon(
                      'document',
                      'user',
                      { slug: _vm.slug },
                      { state: true }
                    )}}),_vm._v("\n                "+_vm._s(_vm.$t('user.nav.journal.title'))+"\n              ")],1)]),_vm._v(" "),(_vm.showSubscriptionTab)?_c('li',{staticClass:"ntds-tab-menu__item",class:{
                active: _vm.isCurrentState(
                  'user.subscription',
                  { slug: _vm.slug },
                  { state: true }
                ),
              }},[_c('a',{staticClass:"ntds-tab-menu__label",attrs:{"href":_vm.href('user.subscription')}},[_c('TreedomIconOutlined',{staticClass:"subscription-icon",class:{
                    active: _vm.isCurrentState(
                      'user.subscription',
                      { slug: _vm.slug },
                      { state: true }
                    ),
                  }}),_vm._v("\n                "+_vm._s(_vm.$t('user.nav.subscription.title'))+"\n              ")],1)]):_vm._e(),_vm._v(" "),_c('li',{staticClass:"ntds-tab-menu__item",class:{
                active: _vm.isCurrentState(
                  'user.trees',
                  { slug: _vm.slug },
                  { state: true }
                ),
              }},[_c('a',{staticClass:"ntds-tab-menu__label",attrs:{"href":_vm.href('user.trees', { slug: _vm.slug })}},[_c('Treecon',{attrs:{"icon":_vm.routeIcon(
                      'plant',
                      'user.trees',
                      { slug: _vm.slug },
                      { state: true }
                    )}}),_vm._v("\n                "+_vm._s(_vm.$t('user.nav.trees.title'))+"\n              ")],1)]),_vm._v(" "),_c('li',{staticClass:"ntds-tab-menu__item",class:{
                active: _vm.isCurrentState(
                  'user.badges',
                  { slug: _vm.slug },
                  { state: true }
                ),
              }},[_c('a',{staticClass:"ntds-tab-menu__label",attrs:{"href":_vm.href('user.badges', { slug: _vm.slug })}},[_c('Treecon',{attrs:{"icon":_vm.routeIcon(
                      'trophy',
                      'user.badges',
                      { slug: _vm.slug },
                      { state: true }
                    )}}),_vm._v("\n                "+_vm._s(_vm.$t('user.nav.badges.title'))+"\n              ")],1)]),_vm._v(" "),_c('li',{staticClass:"ntds-tab-menu__item",class:{
                active: _vm.isCurrentState(
                  'user.forests',
                  { slug: _vm.slug },
                  { state: true }
                ),
              }},[_c('a',{staticClass:"ntds-tab-menu__label",attrs:{"href":_vm.href('user.forests', { slug: _vm.slug })}},[_c('Treecon',{attrs:{"icon":_vm.routeIcon(
                      'forest',
                      'user.forests',
                      { slug: _vm.slug },
                      { state: true }
                    )}}),_vm._v("\n                "+_vm._s(_vm.$t('user.nav.forests.title'))+"\n              ")],1)])])]),_vm._v(" "),(_vm.show_subscriber)?_c('div',{staticClass:"subscriber-badge-container"},[_c('UserSubscriberBadge',{ref:"subscriberBadge",attrs:{"user":_vm.user}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"ntds-page__content",attrs:{"id":"userContent"}},[_c(_vm.routeComponent,_vm._b({tag:"component",attrs:{"user":_vm.user,"loading":_vm.loading}},'component',_vm.currentParams,false))],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }