import {
  defineComponent,
  PropType,
} from '@vue/composition-api'

import { Map as Mapbox } from 'mapbox-gl'
import { Feature, Point } from 'geojson'
import treeNurseryPin from '@f/components/Map/graphics/tree-nursery-pin.png'
import ecommerceCountryPin from '@f/components/Map/graphics/ecommerce-country-pin.png'
import treePin from '@f/components/Map/graphics/tree-pin.png'
import useMarker from '@f/components/Map/Markers/useMarker'

export type PinVariant = 'default' | 'ecommerce'
const getBasePinImage = (variant?: PinVariant) => {
  if (variant === 'ecommerce') return ecommerceCountryPin
  return treePin
}

export type Properties = {
  id: string | number,
  type: 'tree' | 'country',
  variant?: PinVariant,
  icon?: string,
  link: string,
  country: string
}

export default defineComponent({
  name: 'SingleMarker',
  props: {
    map: {
      type: Object as PropType<Mapbox>,
      required: true,
    },
    feature: {
      type: Object as PropType<Feature<Point, Properties>>,
      required: true,
    }
  },
  setup(props, { emit }) {
    const root = useMarker(() => props.feature.geometry.coordinates, props.map)
    const onMarkerClick = () => {
      emit("marker-click", props.feature)
    }
    return {
      root,
      getBasePinImage,
      treeNurseryPin,
      onMarkerClick,
    }
  },
  template: `
    <div style='display: none'>
      <div class='ntds-single-pin' ref='root'>
        <a
          :href='feature.properties.link'
          class='ntds-single-pin-container'
          @click='onMarkerClick'
        >
          <img
            v-if='!feature.properties.icon'
            alt='tree'
            :src='treeNurseryPin'
            class='ntds-single-pin-icon'
          />
          <template v-else>
            <img
              alt='tree'
              :src='getBasePinImage(feature.properties.variant)'
              class='ntds-single-pin-icon'
            />
            <span
              class='ntds-single-pin-icon-image'
              :style='"background-image: url("+feature.properties.icon+");"'
            />
          </template>
        </a>
      </div>
    </div>
  `
})