






















import { Vue, Component, Prop, Watch, Model } from "vue-property-decorator";
import { Portal } from '@linusborg/vue-simple-portal'
import Overlay from '@f/components/Overlay.vue'

@Component({
  name: "Popover",
  components: { Overlay, Portal },
  directives: {
    focus: {
      // directive definition
      inserted: function(el) {
        el.focus();
      }
    }
  }
})
export default class Popover extends Vue {
  // Model
  @Model('change', { type: Boolean, default: false }) readonly active!: boolean
  // Props
  @Prop({ type: [Number, String], required: false, default: "auto" }) readonly width!: any;
  @Prop({ type: Boolean, default: true }) readonly caret!: boolean;
  @Prop({ type: Boolean, default: false }) readonly overlay!: boolean;
  @Prop({ type: String, default: 'popoverTrigger' }) readonly anchor!: string;
  @Prop({ type: Boolean, default: false }) readonly hide!: boolean;
  @Prop({ type: Boolean, default: false }) readonly absolute!: boolean;

  // Data
  areaOffset = 0
  caretOffset = 0
  resizeUpdateHandler = 0

  // Computed
  get areaStyle(): Partial<CSSStyleDeclaration> {
    return {
      width: isNaN(Number(this.width)) ? 'auto' : this.width + 'px',
      left: this.areaOffset + 'px',
    }
  }

  get caretStyle(): Partial<CSSStyleDeclaration> {
    return {
      left: this.caretOffset+'px'
    }
  }

  get anchorElement() {
    return this.$parent.$refs[this.anchor] as Element
  }

  // Watchers
  @Watch('active')
  onActiveChanged(newValue: boolean) {
    if (newValue) {
      this.schedulePositionUpdate()
      this.setListeners()
    } else {
      this.clearListeners()
    }
  }

  // Refs
  $refs!: Vue["$refs"] & {
    area: HTMLElement
  };

  toggle(value?: boolean): void {
    const newValue = typeof value === 'boolean' ? value : !this.open
    this.$emit("change", newValue);
  }
  
  // Events
  destroyed() {
    this.clearListeners()
  }

  // Methods
  open(): void {
    this.toggle(true)
  }

  close(): void {
    this.toggle(false)
  }

  onClickOutside(event: Event) {
    if (!event.composedPath().includes(this.anchorElement)) {
      this.close()
    }
  }

  setListeners() {
    window.addEventListener('resize', this.schedulePositionUpdate)
  }

  clearListeners() {
    window.removeEventListener('resize', this.schedulePositionUpdate)
  }

  schedulePositionUpdate() {
    this.resizeUpdateHandler && cancelAnimationFrame(this.resizeUpdateHandler)
    this.resizeUpdateHandler = requestAnimationFrame(this.updatePosition)
  }

  updatePosition() {
    if (!this.anchorElement) return

    const {
      width: anchorWidth = 0,
      left: anchorOffset = 0
    } = this.anchorElement.getBoundingClientRect() || {}

    if (!this.$refs.area) return

    const areaWidth = (this.$refs.area as HTMLElement).offsetWidth
    const offset = (anchorWidth - areaWidth) / 2
    this.areaOffset = Math.min(offset, window.innerWidth - anchorOffset - areaWidth)

    if (this.$refs.caret) {
      const caretWidth = (this.$refs.caret as HTMLElement).offsetWidth
      this.caretOffset = (areaWidth - caretWidth) / 2 - this.areaOffset + offset
    }
  }

}
