





import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TextTruncate',
  props: {
    value: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 100,
    },
  },
  setup(props) {
    const text = computed(() => {
      if (props.value.length > props.maxLength)
        return props.value.slice(0, props.maxLength - 1)
      return props.value
    })
    const isTruncated = computed(() => props.value.length > props.maxLength)
    return {
      isTruncated,
      text,
    }
  }
})
