import isUndefined from 'lodash/isUndefined'
import Swiper from 'swiper'

angular.module('ngApp')
.controller('specieTreeController', ['$scope', '$rootScope', 'Cart', 'Utility', '$stateParams', '$state', '$filter', '$timeout', 'Modal', 'Tree', 'TagManager', 'Ecommerce', 'Product', function ($scope, $rootScope, Cart, Utility, $stateParams, $state, $filter, $timeout, Modal, Tree, TagManager, Ecommerce, Product) {
  var $ = window.$
  var productID = Product.getIdFromKey($rootScope.activeTreeKey || $stateParams.treeId);

  var startController = function () {
    $scope.formTree = {}
    $scope.formTree.quantity = '1'
    $scope.formTree.isAgift = !!$rootScope.giftToUser
    $scope.quantityAvailable = [1, 2, 3, 4, 5, 10, 20, 50, 100]
    $scope.treeUsesSlider = null
    $scope.pageWrapNoPadding = false
    $scope.isPackage = $rootScope.activeTree.type === 'package'
    $scope.activeTreeToShow = $scope.isPackage ? $scope.activeTree.trees[0] : $rootScope.activeTree

    // Google Analytics
    /*Utility.gaAdd('addProduct', $rootScope.activeTree)
    Utility.gaAction('detail', {})*/
    TagManager.push({
      event: 'viewContent',
      contents: [{
        'id': $rootScope.activeTree.id,
        'quantity': 1,
        'price': $rootScope.activeTree.price
      }],
      contentType: 'product',
      purchaseValue: $rootScope.activeTree.price
    })

    $scope.activeThisTree = function (index) {
      $scope.activeTreeToShow = $scope.activeTree.trees[index]
      if (!$scope.$$phase) $scope.$apply()
    }

    $scope.changeQuantity = function (num) {
      $scope.formTree.quantity = Number(num) || 1
    }

    $scope.backToSpecie = function () {
      switch ($state.current.name) {
        case 'project.tree':
          $state.go('project')
          break
        case 'species.tree':
          $state.go('species')
          break
        case 'ecommerce.tree':
          $state.go('ecommerce')
          break
        default:
          $rootScope.closeTreeSchede()
      }
    }

    $scope.galleryTool = function (url) {
      $('#galleryBig').css('background-image', 'url(' + $filter('s3')(url) + ')')
    }

    $scope.removeGift = function () {
      $rootScope.giftToUser = false
      $scope.formTree.isAgift = false
    }

    $scope.removePlantIn = function () {
      $rootScope.plantIn = false
    }

    $scope.addToCart = function (idItem, isGift, goTo, quantity) {
      Utility.disableButton()

      var gift = isGift ? 1 : 0
      var goToUser = !$rootScope.giftToUser ? 0 : $rootScope.giftToUser.id

      var hasEvent = $rootScope.plantIn
        ? $rootScope.plantIn.id
        : $rootScope.activeTree.idEvent ? $rootScope.activeTree.idEvent : 0

      var isLimited = $rootScope.activeTree.limitedId ? $rootScope.activeTree.limitedId : 0

      if (goToUser) gift = 1 // se comunque lo regalo ad un utento segno anche il fleg del regalo

      quantity = isUndefined(quantity) ? 1 : quantity <= 0 ? 1 : quantity

      Cart.add(idItem, gift, goToUser, 0, isLimited, hasEvent, quantity)
      .then(function(response) {
        const data = response.data || response
        // #GA
        /*Utility.gaAdd('addProduct', $rootScope.activeTree)
        Utility.gaAction('add', {})
        Utility.gaSend()*/
        TagManager.push({
          event: 'addToCart',
          contents: [{
            'id': $rootScope.activeTree.id,
            'quantity': 1,
            'price': $rootScope.activeTree.price
          }],
          contentType: 'product',
          purchaseValue: $rootScope.activeTree.price
        })

        Utility.enableButton()
        $rootScope.userdata.cart = data
        if (goTo) {
          $state.go('checkout')
          if ($state.current.name !== 'project.tree' || $state.current.name !== 'species.tree' || $state.current.name !== 'ecommerce.tree') {
            $rootScope.closeTreeSchede()
          }
        } else {
          $scope.backToSpecie()
        }

        moveCartCounter()
      })
    }

    $scope.addPackageToCart = function (packageId, fastBuy) {
      Utility.disableButton()

      Cart.addPackage(packageId)
      .then(function(response) {

        response = response.data || response
        $rootScope.userdata.cart = response
        Utility.enableButton()

        if (fastBuy) {
          $state.go('checkout')

          if ($state.current.name !== 'project.tree' || $state.current.name !== 'species.tree' || $state.current.name !== 'ecommerce.tree') {
            $rootScope.closeTreeSchede()
          }
        } else {
          if ($state.current.name === 'species.tree') {
            $state.go('species')
          } else {
            $rootScope.closeTreeSchede()
          }
        }

        moveCartCounter()
      })
    }

    $scope.openModalImage = function (index) {
      var images = $scope.activeTreeToShow.gallery.map(function (i) {
        return { image_id: i }
      })

      $rootScope.modalImageInfo = {
        images: images,
        activeIndex: index,
        hideComments: true
      }

      Modal.open({ templateUrl: 'modalImage.html', backgroundColor: 'transparent', small: false })
    }

    // Remove tree from cart
    $scope.deleteCartItem = function (id) {
      // if (!$scope.deleteTree) return
      Utility.disableButton()

      Cart.delete(id)
      .then(function(response) {
        const data = response.data || response
        $rootScope.userdata.cart = data
        $scope.itemData(data.trees)
        Utility.enableButton()
      })
      .catch(function() {

        Utility.enableButton()
      })
    }

    // Alert me when tree is available
    $scope.letMeKnow = function (tree) {
      if (!tree) return

      var specieId = tree.id
      var limitedId = tree.isLimited ? tree.limitedId : false
      if (!specieId) return

      // If user is not logged in
      if (!$rootScope.user) {
        Modal.open({ templateUrl: 'login.html', easyClose: false, fitcontent: true })
        $rootScope.openAlertMeModal = { specieId: specieId, limitedId: limitedId }
        return
      }

      Utility.disableButton()

      Tree.alertMeWhenAvailable(specieId, limitedId)
      .then(function(res) {

        res = res.data || res
        Utility.enableButton()
        Modal.open({ templateUrl: 'modalAlertMe.html' })
      })
      .catch(function() {

        Utility.enableButton()
      })
    }

    function moveCartCounter () {
      var cartCounter = document.getElementById('cartCounter')
      cartCounter.style.transform = 'scale(1.7)'
      $timeout(function () {
        cartCounter.style.transform = 'scale(1)'
      }, 250)
    }

    // Swiper tree Uses
    $scope.renderTreeUsesSwiper = function () {
      if ($scope.treeUsesSlider) {
        !Array.isArray($scope.treeUsesSlider)
          ? $scope.treeUsesSlider.destroy(true, true)
          : $scope.treeUsesSlider.forEach(function (s) { s.destroy(true, true) })
      }

      if (!$scope.activeTree.treeUses.length) return

      $scope.treeUsesSlider = new Swiper('#SwiperTreeUses', {
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 30,
        nextButton: '#TreeUsesButtonNextSlider',
        prevButton: '#TreeUsesButtonPrevSlider',
        pagination: '#TreeUsesSliderPagination',
        paginationClickable: true,
        grabCursor: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        }
      })
    }

    if ($rootScope.currentState !== 'project.tree' && $rootScope.currentState !== 'species.tree' && $rootScope.currentState !== 'ecommerce.tree') {
      $scope.pageWrapNoPadding = true
    }

    // Modale info su regalo
    $scope.openGiftInfo = function () {
      Modal.open({templateUrl: 'modalGiftInfo.html'})
    }
  }


  if (!$rootScope.activeTree) {
    $rootScope.activeTree = $rootScope.trees[$stateParams.treeId]

    if (!$rootScope.activeTree && $stateParams.treeId.indexOf("_LTD_")>=0) {
        Utility.getLimitedBySlug($stateParams.treeId)
            .then(function(response) {
              const data = response.data || response
                $rootScope.activeTree = Array.find(data, ['key', $stateParams.treeId])
            })
    }

    // NOTE: inserito per la giornata mondiale dell'ambienta 2018 (wed)
    if (!$rootScope.activeTree) {
      $rootScope.$watch(function () {
        return $rootScope.wed2018
      }, function (trees) {
        if (trees) {
          $rootScope.activeTree = Array.find(trees, ['key', $stateParams.treeId])
        }
      })
    }

    // NOTE: inserito per i pacchetti coppia di legami (ecommerceCoupleBonds)
    if (!$rootScope.activeTree) {
      $rootScope.$watch(function () {
        return $rootScope.coupleBonds
      }, function (trees) {
        if (trees) {
          $rootScope.activeTree = Array.find(trees, ['key', $stateParams.treeId])
        }
      })
    }

    if (!$rootScope.activeTree) {
      Ecommerce.getProducts([productID])
        .then(response => {
          $rootScope.activeTree = (response.data || response)[productID] || null
        })
    }


  }

  $rootScope.$watch(function () {
    return $rootScope.activeTree
  }, function (tree) {
    if (tree) startController()
  })
}])
.controller('errorPaymentController', ['$scope', function ($scope) {
  function getUrlVars () {
    var vars = {}

    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value
    })

    return vars
  }

  $scope.errorCode = getUrlVars()['errorCode']
}])
