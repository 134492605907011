import { Vue, Component } from "vue-property-decorator";

type CheckFunction<T> = (args: T) => boolean
// TODO: temporary disable async checks
// type AsyncCheckFunction<T> = (args: T) => Promise<boolean>
type Check<T = void> = boolean | CheckFunction<T> // | AsyncCheckFunction<T>

export interface IFlowStep {
  name: string,
  icon?: string,
  label: string,
  state: string,
  visible: Check,
  valid: Check,
  clickable?: Check,
  onEnter?: () => void,
  nextStep?: () => Promise<any> | any,
  previousStep?: () => Promise<any> | any,
}

@Component
export default class FlowMixin extends Vue {
  /*--- DATA ----------*/
  private activeStepIndex: number = 0

  /*--- COMPUTED ------*/
  get steps(): IFlowStep[] {
    return []
  }

  get activeSteps() {
    return this.steps.filter(step => this.isVisible(step))
  }

  get activeStep(): number {
    return this.activeStepIndex
  }

  set activeStep(val: number) {
    this.activeStepIndex = Math.max(0, Math.min(this.steps.length, val))
  }

  get currentStep(): IFlowStep {
    return this.activeSteps[this.activeStep]
  }

  get canProceed(): boolean {
    return this.isValid(this.currentStep)
  }

  /*--- METHODS -------*/
  stepIs(stepNames: string | string[]): boolean {
    return Array.isArray(stepNames)
      ? stepNames.includes(this.activeSteps[this.activeStepIndex]?.name)
      : this.activeSteps[this.activeStepIndex]?.name === stepNames
  }

  isVisible({ visible }: IFlowStep): boolean {
    return !!(typeof visible === 'function'
      ? visible()
      : visible)
  }

  isValid({ valid }: IFlowStep): boolean {
    return !!(typeof valid === 'function'
      ? valid()
      : valid)
    }
    
  isClickable({ clickable }: IFlowStep): boolean {
    return !!(typeof clickable === 'function'
      ? clickable()
      : clickable)
  }

  nextStep() {
    if (this.currentStep.nextStep) {
      this.currentStep.nextStep()
        .then((response?: unknown) => {
          if (response === false) return
          this.changeStep(this.clampIndex(this.activeStep+1))
        })
        .catch((error: Error) => console.log(error))
    } else {
      this.changeStep(this.clampIndex(this.activeStep+1))
    }
  }

  previousStep() {
    this.changeStep(this.clampIndex(this.activeStep-1))
  }

  changeStep(targetIndex: number) {
    this.$state.go(this.activeSteps[targetIndex].state)
  }

  clampIndex(index: number) {
    return Math.max(0, Math.min(this.activeSteps.length-1, index))
  }

}
