angular.module('overlayLoaderService', [])
.factory('OverlayLoader', [function() {

  const _template = `
    <div id="overlayModalLoader" class="overlay-modal-loader hidden">
      <div class="treedomModalBackground">
        <div class="treedomModalTable">
          <div class="treedomModalCell">
            <div class="treedomModalContentWrapper text-center">
              <div class="spinnerTreedom">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
  
  let _modalEl
  
  const OverlayLoader = {
    get modalEl() {
      return _modalEl || this.appendTemplate()
    },

    appendTemplate() {
      const modal = document.getElementById('overlayModalLoader');
      if (modal) {
        _modalEl = angular.element(modal)
      } else {
        _modalEl = angular.element(_template)
        angular.element(document.body).append(_modalEl)
      }
      return _modalEl
    },

    show(variationClass) {
      if (variationClass) {
        this.modalEl.addClass(variationClass)
      }
      this.modalEl.removeClass('hidden')
      this.modalEl.addClass('active')
    },

    hide(variationClass) {
      this.modalEl.one('transitionend.hiding webkitTransitionEnd.hiding', () => {
        this.modalEl.off('.hiding')
        if (variationClass) {
          this.modalEl.removeClass(variationClass)
        }
        this.modalEl.addClass('hidden')
      })
      this.modalEl.removeClass('active')
    }
  }

  return OverlayLoader

}])
