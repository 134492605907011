import { PageScrollManager } from '@f/utils'
import { AppRootScope } from '@f/@types'
import { StateParams, StateService, StateDeclaration } from '@uirouter/angularjs'
import { IAngularEvent } from 'angular'

angular.module('scrollServiceProvider', [])
  .factory('ScrollService', ['$rootScope', '$state', ($rootScope: AppRootScope, $state: StateService) => {
    const navbarId = 'navbar'

    const getOffsetTop = () => {
      return document.getElementById(navbarId)?.clientHeight || 0
    }

    const pageScrollManager = new PageScrollManager({ getOffsetTop })

    const handleHash = (_event: IAngularEvent, _toState: StateDeclaration, _toParams: StateParams, _from: StateDeclaration) => {
      const hashTarget = _toParams['#'] ? '#'+_toParams['#'] : undefined
      if(_from.name !== _toState.name)
      pageScrollManager.scroll({ target: hashTarget, smooth: !!hashTarget })
    }

    const init = () => {
      // Avoids scrollRestoration conflicts
      window.history && window.history.scrollRestoration && (window.history.scrollRestoration = 'manual')
      $rootScope.$on('$stateChangeSuccess', handleHash)
    }

    const scrollServiceFactory = {
      init
    }

    return scrollServiceFactory
  }])
