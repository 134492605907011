angular.module('ngApp')
.controller('userController', ['$scope', '$rootScope', '$stateParams', '$state', 'User', 'Modal', function ($scope, $rootScope, $stateParams, $state, User, Modal) {
  const userTypePaths = {
    Private: 'user',
    Business: 'organization'
  }

  $scope.initUser = function (slug) {
    // Se ho già l'utente, mi fermo qui.
    if ($rootScope.showUser) {
      if ($rootScope.showUser.info.slug === slug) {
        redirectUserType($rootScope.showUser.info.user_type)
        return
      }
    }
    // Resetto tutto, importante per reload template private/business!
    $rootScope.showUser = false

    // Altrimenti chiedo al server le sue informazioni.
    User.getUserWithBalancer(slug)
    .then(function (data) {
      if (!data) return $state.go('index')
      $rootScope.showUser = data
      redirectUserType(data.info.user_type)
    })
  }

  // Se non ho le info dell'utente, le chiedo al server.
  if (!$rootScope.showUser || ($rootScope.showUser && $rootScope.showUser.slug !== $stateParams.slug)) {
    if ($stateParams.slug) $scope.initUser($stateParams.slug)
  }

  // Se l'utente che visita il sito esegue il login, richiedo i dati.
  $scope.$on('UserLoggedBroadcast', function (response, data) {
    if (data) $scope.initUser($stateParams.slug)
  })


  function redirectUserType(typeToCheck) {
    const userTypePath = userTypePaths[typeToCheck]
    if (!userTypePath) {
      $state.go('index')
    } else if (!~$state.current.name.indexOf(userTypePath)) {
      const convertedStateName = $state.current.name.replace(/^\w+/, userTypePath)
      if ($state.href(convertedStateName, $state.params)) {
        $state.go(convertedStateName, $stateParams)
      } else if ($state.href(userTypePath, $state.params)) {
        $state.go(userTypePath, $state.params)
      } else {
        $state.go('index')
      }
    }
  }
}])
