import 'cross-fetch/polyfill'
import { ApolloClient, HttpLink, from, InMemoryCache } from '@apollo/client/core'

import { api } from '@f/configs/app';
import logErrorLink from '@f/apollo/links/logErrorLink'
import { createApolloAuthorizationLink } from '@f/auth/helpers'
import schema from '@f/@types/treedom-next'

const abortController = new AbortController();
const httpLink = new HttpLink({
  uri: api.treedomNextUri,
  //HACK: remove error in console.log by abort user request
  fetchOptions: {
    signal: abortController.signal,
  }
})

const authorizationLink = createApolloAuthorizationLink()
const treedomNextClient = new ApolloClient({
  link: from([
    logErrorLink,
    authorizationLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    possibleTypes: schema.possibleTypes,
  }),
})

export default treedomNextClient
