import ajv from '@f/validation/ajv'
import * as AuthSchemas from '@f/validation/schema/auth'
import { UserProfile } from '@f/@types'

export const validateUserCheck = ajv.compile<AuthSchemas.IUserCheckSchema>(AuthSchemas.UserCheckSchema)
export const validateSignIn = ajv.compile<AuthSchemas.ISignInSchema>(AuthSchemas.SignInSchema)
export const validateHomonymySignIn = ajv.compile<AuthSchemas.IHomonymySignInSchema>(AuthSchemas.HomonymySignInSchema)
export const validateHomonymySignUp = ajv.compile<AuthSchemas.IHomonymySignUpSchema>(AuthSchemas.HomonymySignUpSchema)
export const validateSignUp = ajv.compile<AuthSchemas.ISignUpSchema>(AuthSchemas.SignUpSchema)
export const validatePasswordResetRequest = ajv.compile<AuthSchemas.IPasswordResetRequestSchema>(AuthSchemas.PasswordResetRequestSchema)
export const validatePasswordReset = ajv.compile<AuthSchemas.IPasswordResetSchema>(AuthSchemas.PasswordResetSchema)
export const validatePrivateProfile = ajv.compile<UserProfile>(AuthSchemas.PrivateProfileCheckSchema)
