angular.module('ngApp')
// NOTE: Controller usato ancora nell'iframe.
.controller('nurserySpecieController', ['$scope', 'Project', '$rootScope', '$stateParams', '$state', 'Tree', '$filter', function ($scope, Project, $rootScope, $stateParams, $state, Tree, $filter) {
  Project.tree($stateParams.treeId)
  .then(function(response) {
    const data = response.data || response
    $scope.nurseryTree = data
  })

  $scope.galleryTool = function (url) {
    $('#galleryBig').css('background-image', 'url(' + $filter('s3')(url) + ')')
  }

  $scope.goBack = function () {
    $rootScope.selectedNurserySpecie = false
    !$scope.event ? $state.go('user.nursery') : $state.go('user.event.nursery')
  }
}])
