const waitAndNotifySignInPopupIsClosed = (target: Window) => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (!target.closed) return
      clearInterval(interval)
      setTimeout(() => resolve(null), 1000)
    }, 1000)
  }).then(() => {
    window.postMessage({
      name: 'signIn_abort',
    }, location.origin)
  })
}
const centeredPopup = (pageURL: string, title: string, w: number, h: number) => {
  const left = (screen.width / 2) - (w / 2)
  const top = (screen.height / 2) - (h / 2)
  return window.open(pageURL, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`);
}
export const launchUri = (url: string) => {
  const target = centeredPopup(
    url,
    `cognito_federated_${Math.random()}`,
    Math.min(window.innerWidth, 400),
    Math.min(window.innerWidth, 500),
  );
  if (target) {
    return waitAndNotifySignInPopupIsClosed(target);
  } else {
    return Promise.reject();
  }
};

const openUrlInPopup = async (url: string, redirectUrl: string): Promise<any> => {
  console.log('opening cognito url', { url, redirectUrl })
  return launchUri(url);
};

export default openUrlInPopup
