import Vue from 'vue'
import { getBoundsHeight } from '@f/utils/dom'

export default Vue.extend({
  functional: true,
  props: {
    mode: { type: String, default: '' },
    enter: { type: Number, default: 700 },
    leave: { type: Number, default: 500 },
    appear: { type: Boolean, default: false},
  },
  render(h, { props: { mode, enter, leave, appear }, children }) {
    const data = {
      props: {
        name: 'SlideDownTransition',
        mode,
        duration: { enter, leave },
        appear
      },
      on: {
        beforeEnter: function(el: HTMLElement) {
          el.style.maxHeight = '0px'
          el.style.boxSizing = 'content-box'
          el.style.overflow = 'hidden'
          el.style.opacity = '0'
          el.style.paddingTop = '0'
          el.style.paddingBottom = '0'
        },
        enter: function(el: HTMLElement, done: Function) {
          const { top } = el.getBoundingClientRect()
          const children: Element[] = Array.from(el.children)
          const contentHeight = getBoundsHeight(children as HTMLElement[], top)
          requestAnimationFrame(() => {
            el.style.transition = `max-height ${enter*0.0007}s ease-in-out, padding ${enter*0.0007}s ease-in-out, opacity ${enter*0.0006}s ${enter*0.0004}s ease-in`
              // .reduce((sum: number, child: HTMLElement) => sum + child.offsetHeight, 0)
            el.style.maxHeight = `${contentHeight}px`
            el.style.opacity = '1'
            el.style.paddingTop = ''
            el.style.paddingBottom = ''
            setTimeout(done, enter)
          })
        },
        afterEnter: function(el: HTMLElement) {
          el.style.maxHeight = ''
          el.style.opacity = ''
          el.style.overflow = ''
          el.style.transition = ''
          el.style.boxSizing = ''
        },
        enterCancelled: function(_el: HTMLElement) {},
        beforeLeave: function(el: HTMLElement) {
          el.style.maxHeight = `${el.offsetHeight}px`
          el.style.opacity = '1'
          el.style.overflow = 'hidden'
        },
        leave: function(el: HTMLElement, done: Function) {
          requestAnimationFrame(() => {
            el.style.transition = `max-height ${leave*0.001}s ease-in-out, padding ${leave*0.001}s ease-in-out, opacity ${leave*0.0006}s ease-out`
            el.style.maxHeight = '0px'
            el.style.opacity = '0'
            el.style.paddingTop = '0'
            el.style.paddingBottom = '0'
            setTimeout(done, leave)
          })
        },
        afterLeave: function(el: HTMLElement) {},
        leaveCancelled: function(_el: HTMLElement) {},
      }
    }

    return h('transition', data, children)
  }
})
