
























































import {defineComponent, PropType, Ref, ref} from "@vue/composition-api";
import SlideDownTransition from "@f/transitions/SlideDown";

export interface Answer {
  description?: string;
  image?: any;
  correct?: string | undefined;
  wrong?: string | undefined;
  explanation: {
    correct?: string | undefined;
    wrong?: string | undefined;
  };
  isCorrect: boolean;
}

export default defineComponent({
  name: "AnswerList",
  emits: ["answer"],
  components: {
    SlideDownTransition,
  },
  props: {
    answers: {
      type: Array as PropType<Answer[]>,
      required: true,
    },
    gridLayout: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, {emit}) {
    const answersContainer = ref<HTMLElement | null>(null);
    const answered: Ref<number | undefined> = ref(undefined);
    const onAnswer = (answer: Answer, answerIndex: number) => {
      if (answered.value === undefined) {
        emit("answer", answer.isCorrect);
        answered.value = answerIndex;
        setTimeout(
            () =>
                answersContainer.value?.scrollIntoView({behavior: "smooth", block: "end"}),
            300
        );
      }
    };
    const showCorrectAnswerFeedback = (answer: Answer) =>
        answer.isCorrect && answered.value !== undefined;
    const showWrongAnswerFeedback = (answer: Answer) =>
        !answer.isCorrect && isUserChoice(answer);
    const isUserChoice = (answer: Answer) =>
        answered.value === props.answers.indexOf(answer);

    return {
      onAnswer,
      answered,
      showCorrectAnswerFeedback,
      showWrongAnswerFeedback,
      isUserChoice,
      answersContainer,
    };
  },
});
