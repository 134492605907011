

































































































































import { Component, Mixins } from 'vue-property-decorator'

// Mixins
import ManageQueues, { Job, JobCallback } from "@f/mixins/ManageQueues"
import ShowProduct from "@f/mixins/ShowProduct"
import AssignProducts from '@f/mixins/AssignProducts'

// Components
import StatefullButton from '@f/components/StatefullButton.vue'
import NumberInput from '@f/components/Form/NumberInput.vue'
import Icon from '@f/components/Icon.vue'

@Component({
  components: {
    StatefullButton,
    NumberInput,
    Icon,
  }
})
export default class ProductBuyWidget extends Mixins(ManageQueues, ShowProduct, AssignProducts) {

  modalService: any = null
  cartAssignQueueKey: string = 'cartAssignment'

  // Purchase data
  quantity = 1
  isGift = false
  plantIn: any = null
  giftToUser: any = null

  eventDefaultAvatar: string = 'bundles/treedomnewfrontend/images/event/event_avatar_default.png'

  // Computed
  get eventId(): number | null {
    return this.product?.limited?.limitedEvent?.id || this.$rootScope.plantIn?.id || null
  }

  get recipientId(): string | null {
    return !this.isCoupled && this.$rootScope.giftToUser?.id || null
  }

  get badge(): string {
    return this.product.badge ? this.$t(`specie.badge.${this.product.badge}`, {
      numberTrees: this.product.onHand, // used by showAvailability badge label
      percentage: this.product.badgeDiscount, // used by discount badge label
      date: this.product.badgeDate // used by comingSoon and until badge labels
    }) : ''
  }

  get badgeClasses(): any {
    return this.product.badge ? {
      'treeBadge-primary': ['limited', 'new'].includes(this.product.badge),
      'treeBadge-warning': ['showAvailability', 'discount', 'until'].includes(this.product.badge),
      'treeBadge-info': ['soldOut', 'comingSoon'].includes(this.product.badge),
      'treeBadgeMedium': ['showAvailability'].includes(this.product.badge),
      'treeBadgeBig': ['new', 'discount'].includes(this.product.badge),
    } : {}
  }

  get isPackage(): boolean {
    return this.product.type === 'package' && !!this.product.package
  }

  get country(): any {
    return this.product.specie && this.product.specie.country && this.product.specie.country.name
  }

  get scientificName(): any {
    return this.product.specie && this.product.specie.scientificName && this.product.specie.scientificName.name
  }

  get quantityMin(): number {
    return 1
  }

  get quantityMax(): number {
    return Math.min(this.product.onHand, 100)
  }

  get userIsBusiness(): boolean {
    return this.$rootScope && this.$rootScope.userType && this.$rootScope.userType === 'Business'
  }

  get inForest(): any {
    return this.product.limited?.limitedEvent || null
  }

  // Events
  mounted() {
    this.modalService = this.ngService('Modal')

    this.giftToUser = !this.isCoupled && this.$rootScope.giftToUser || null
    this.plantIn = this.$rootScope.plantIn
  }

  // Methods
  openModal(template: string): void {
    this.modalService && this.modalService.open({templateUrl: `${template}.html`})
  }
  
  attemptCartAssignment(callback?: JobCallback) {
    if (!this.cartService) {
      callback && callback()
      throw Error('Unable to call Angular Cart Service.')
    }
    const task = this.cartService.assignProduct.bind(undefined, this.product.id, this.isGift, this.eventId, this.recipientId, this.quantity)
    this.addJobToQueue(this.cartAssignQueueKey, new Job(this.product.id, task, callback))
  }

  removeEvent(): void {
    this.plantIn = this.$rootScope.plantIn = null
  }

  removeRecipient(): void {
    this.giftToUser = this.$rootScope.giftToUser = null
  }

  setGift(ev: UIEvent) {
    if (!this.$isFeatureActive('giftflow')) {
      ev.preventDefault()
      return this.modalService && this.modalService.open({
        templateUrl: 'v-modal-feature-off.html',
        id: 'giftflowOff',
        data: {
          title: this.$t('feature.giftflow.off.title'),
          text: this.$t('feature.giftflow.off.text'),
        }
      })
    }
    this.isGift = !this.isGift
  }
}
