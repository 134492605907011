import { ref } from '@vue/composition-api'
import { authenticate, getAuthState, isAuthenticated } from '@f/services/Auth'
import { onLogged } from '@f/composables/hooks/auth'

export type Authentication = {
  id: number,
  slug: string,
}
export default function useAuthentication() {
  const isLogged = ref(isAuthenticated())
  const authentication = ref<Authentication | null>(getAuthState() as Authentication)

  function updateRefs(logged: boolean) {
    isLogged.value = logged
    authentication.value = getAuthState() as Authentication
  }

  onLogged(updateRefs, false)

  return {
    authenticate,
    authentication,
    isLogged,
    isLoggedAs: isAuthenticated
  }
}
