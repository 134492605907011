import moment from 'moment'

angular.module('ngApp')
// Controller per share singolo post nella pagina albero
.controller('shareItemController', ['$scope', '$rootScope', '$stateParams', 'Modal', 'User', '$timeout', function ($scope, $rootScope, $stateParams, Modal, User, $timeout) {

  $scope.openReplicasBox = {}
  $scope.userMessage = {}
  $scope.openCommentsBox = {}
  $scope.now = new Date().getTime()

  /**
   * Check if string contain only blank spaces
   * @param {string} string
   * @return {boolean}
   */
  var containOnlyBlankSpaces = function (string) {
    if (!string) return true

    var splittedString = string.split('')
    var lettersButBlankSpaces = splittedString.filter(function (letter) { return letter !== ' ' })
    if (!lettersButBlankSpaces.length) return true

    return false
  }

  /**
   * Remove special characters like whatsapp emoji
   * @param {string} string
   */
  var removeSpecialCharacters = function (string) {
    return string
      .replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, '')
  }

  // Se lo stream è di tipo mappa (planted || status_planted)
  $scope.initMap = function (streamTitle, streamId) {
    $timeout(function () {
      $rootScope.$broadcast('LoadSingleTreeMap', {
        treeId: $rootScope.singleTree.id,
        containerId: 'map' + streamTitle + streamId,
        nursery: streamTitle === 'planted'
      })
    }, 100)
  }

  /**
   * Get timestamp
   */
  $scope.getTimestamp = function (date) {
    return moment(date).unix() * 1000
  }

  /**
   * Posta un nuovo commento.
   * @param {string} message
   * @param {string} type
   * @param {string|number} postId
   * @param {string|number} parentId
   * @param {number} idx
   */
  $scope.postMessage = function (message, type, postId, parentId, idx) {
    if (!$rootScope.user) Modal.open({templateUrl: 'login.html', easyClose: false, fitcontent: true})
    if (!message || !type || !postId) return

    message = removeSpecialCharacters(message)
    if (containOnlyBlankSpaces(message)) return

    var actualPost = $rootScope.postToShare[0]

    var commentData = {
      message: message,
      type: type,
      id: postId,
      parent: parentId
    }

    User.postComment(commentData)
    .then(function(response) {

      response = response.data || response
      var newComment = {
        id: response.id,
        created_on: new Date(),
        message: message,
        parent_id: parentId,
        user_avatarId: $rootScope.userdata.info.picture,
        user_firstname: $rootScope.userdata.info.firstName,
        user_id: $rootScope.userdata.info.id,
        user_lastname: $rootScope.userdata.info.lastName,
        user_slug: $rootScope.userdata.info.slug,
        user_title: $rootScope.userdata.info.title,
        visible: true
      }

      if (parentId) {
        var index = actualPost.comments.findIndex(comment => comment.id === parentId)
        var actualParent = actualPost.comments[index]
        actualParent.replicas
          ? actualParent.replicas.push(newComment)
          : actualParent.replicas = [newComment]
        $scope.openReplicasBox[idx] = true
        $scope.userMessage[postId + '_' + parentId] = ''
        $scope.openCommentsBox[postId + type] = true
        return
      }

      // Aggiungi il commento alla lista.
      actualPost.comments
        ? actualPost.comments.unshift(newComment)
        : actualPost.comments = [newComment]

      actualPost.visibleComments
        ? actualPost.visibleComments.unshift(newComment)
        : actualPost.visibleComments = [newComment]

      if (Object.keys($scope.openReplicasBox).length) {
        $scope.openReplicasBox.forEach((_value, index) => {
          delete $scope.openReplicasBox[index]
          var newIndex = ((index * 1) + 1).toString()
          $scope.openReplicasBox[newIndex] = true
        })
      }

      // Se chiuso, apro il box commento
      $scope.openCommentsBox[postId + type] = true

      // Reset input.
      $scope.userMessage[postId] = ''

      $rootScope.$broadcast('treeWallUpdated', $stateParams.item)
    })
  }

  /**
   * Cancella un commento.
   * @param {string|number} id - ID del commento da rimuovere.
   */
  $scope.deleteComment = function (postId, postType, id, parentId) {
    var actualPost = $rootScope.postToShare[0]

    if (!parentId) {
      var commentIndex = actualPost.comments.findIndex(comment => comment.id === id)
      var actualComment = actualPost.comments[commentIndex]
      
      // Se il post non è tuo, non lo elimino
      if (actualComment.user_slug !== $rootScope.user) return
    } else {
      var parentIndex = actualPost.comments.findIndex(comment => comment.id === parentId)
      var actualParent = actualPost.comments[parentIndex]
      var replyIndex = actualParent.replicas.findIndex(reply => reply.id === id)
      var actualReply = actualParent.replicas[replyIndex]

      // Se il post non è tuo, non lo elimino
      if (actualReply.user_slug !== $rootScope.user) return
    }

    User.deleteComment(id)
    .then(function() {

      if (parentId) {
        actualParent.replicas = actualParent.replicas.filter((_comment, index) => replyIndex !== index)
        return
      }

      actualPost.comments = actualPost.comments.filter((_comment, index) => commentIndex !== index)

      $rootScope.$broadcast('treeWallUpdated', $stateParams.item)
    })
  }

  /**
   * Mostra altri 10 commenti.
   */
  $scope.showMoreComments = function (postId, postType) {
    var actualPost = $rootScope.postToShare[0]

    // Show 10 comments
    var editedComments = actualPost.hiddenComments.map(function (comment, index) {
      comment.visible = index < 10
      return comment
    })

    var newCommentsToShow = editedComments.filter(function (comment) {
      return comment.visible
    })

    actualPost.hiddenComments = editedComments.filter(function (comment) {
      return !comment.visible
    })

    actualPost.visibleComments = actualPost.visibleComments.concat(newCommentsToShow)

    actualPost.comments = actualPost.visibleComments.concat(actualPost.hiddenComments)
  }
}])
