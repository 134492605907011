








import { defineComponent } from "@vue/composition-api";
import QuestionLayout from "../../Layout/Question/QuestionLayout.vue";
import QuestionHeader from "../../Layout/Question/QuestionHeader.vue";
import AnswerList, { Answer } from "../../Layout/Question/AnswerList.vue";

export default defineComponent({
  name: "IntruderQuestion",
  components: {
    QuestionLayout,
    QuestionHeader,
    AnswerList,
  },
  emits: ["complete"],
  methods: {
    onAnswer(isCorrect: boolean) {
      this.$emit("complete", { result: isCorrect });
    },
  },
  setup: () => {
    const questionText = "game.question.intruder";
    const answers: Answer[] = [
      {
        image: require("@f/components/Game/Steps/Intruder/IntruderQuestionImage-1.jpg"),
        wrong: "game.question.intruder.wrong",
        explanation: {
          wrong: "game.question.intruder.option1.explanation.wrong",
        },
        isCorrect: false,
      },
      {
        image: require("@f/components/Game/Steps/Intruder/IntruderQuestionImage-2.jpg"),
        wrong: "game.question.intruder.wrong",
        explanation: {
          wrong: "game.question.intruder.option2.explanation.wrong",
        },
        isCorrect: false,
      },
      {
        image: require("@f/components/Game/Steps/Intruder/IntruderQuestionImage-3.jpg"),
        correct: "game.question.intruder.correct",
        explanation: {
          correct: "game.question.intruder.option3.explanation.correct",
        },
        isCorrect: true,
      },
      {
        image: require("@f/components/Game/Steps/Intruder/IntruderQuestionImage-4.jpg"),
        wrong: "game.question.intruder.wrong",
        explanation: {
          wrong: "game.question.intruder.option4.explanation.wrong",
        },
        isCorrect: false,
      },
    ];
    return {
      questionText,
      answers,
    };
  },
});
