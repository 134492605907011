
angular.module('errorService', [])
.factory('$exceptionHandler', ['$injector', function ($injector) {
  return function (exception, cause) {
    var $log = $injector.get('$log')
    var $state = $injector.get('$state')
    var $stateParams = $injector.get('$stateParams')
    var $rootScope = $injector.get('$rootScope')

    if (typeof $state.current.name !== 'undefined') exception.message += ' (state:  "' + $state.current.name + '" params  "' + JSON.stringify($stateParams) + '")'

    if (typeof $rootScope.user !== 'undefined') {
      exception.message += ' | user slug  "' + $rootScope.user + '"'
    } else {
      exception.message += ' | user anonymous '
    }

    $log.info(exception)

    var host = window.location.host
    if (host === 'www.treedom.net' || host === 'beta2.treedom.net') {
      // $state.go('error500')
    }
  }
}])
