import { validateVariables } from '@f/validation/datalayer'
import tagManagerService from '@f/services/TagManager'
import { UserInfo } from '@/public_src/@types'
import { IDataLayerVariables } from '@f/validation/types/dataLayer'
import { appCookies } from '@f/configs/app'

const markets = {
  IT: ['Italy'],
  FR: ['France'],
  ES: ['Spain'],
  UK: ['United Kingdom', 'Ireland'],
  BENELUX: ['Netherlands', 'Luxembourg', 'Belgium'],
  DACH: ['Germany', 'Austria', 'Switzerland'],
  NORDIX: ['Sweden', 'Norway', 'Denmark', 'Iceland', 'Finland'],
}

class DataLayerVariables implements IDataLayerVariables {
  userId: number | null = null
  market: string | null = null
  country: string | null = null
  language: string | null = null
  userStatus = 'not-logged'
  userType = 'Private'
  userSpecification = 'private'
  deviceId: string | null = null
  numberOfTreesOwned: number = 0
  treeOwner: string = 'no'
  treeRedeemer: string | undefined
  facebookData: unknown | undefined
  googleData: unknown | undefined

  constructor(userInfo: UserInfo | null) {
    if (userInfo && userInfo.id) {
      this.userId = userInfo.id
      this.market = this.getMarket(userInfo.country || '')
      this.country = userInfo.countryCode || null
      this.language = userInfo.locale
      this.userStatus = 'logged'
      this.userType = userInfo.usertype
      this.facebookData = userInfo.facebook_data
      this.googleData = userInfo.google_data
      this.userSpecification =
        userInfo.usertype === 'Business' ? 'starter' : 'private'
      this.numberOfTreesOwned = userInfo.numTrees
        ? parseInt(userInfo.numTrees as string)
        : 0
      this.treeOwner = this.numberOfTreesOwned > 0 ? 'yes' : 'no'
    }
    this.deviceId = appCookies.deviceId
  }

  getMarket(country: string) {
    const [market] = Object.entries(markets).find(([key, value]) =>
      value.includes(country)
    ) || [null]
    return market
  }
}

export function DataLayerPushVariables(userInfo: UserInfo | null = null) {
  tagManagerService.push(
    {
      ...new DataLayerVariables(userInfo),
    },
    validateVariables
  )
  tagManagerService.push({ event: 'userDataUpdated' })
}

export function DataLayerUpdateVariables(userInfo: UserInfo) {
  tagManagerService.push({
    treeRedeemer: userInfo.treeRedeemer,
  })
  tagManagerService.push({ event: 'userDataUpdated' })
}

export function DataLayerPushDeviceId() {
  tagManagerService.push(() => ({
    deviceId: appCookies.deviceId,
  }))
  tagManagerService.push({ event: 'userDataUpdated' })
}
