angular.module('ngApp')
.controller('communityRankingController', ['$scope', 'Game', function ($scope, Game) {

  $scope.currentYear = window.moment().year()
  $scope.currentMonth = window.moment().month() + 1
  $scope.year = $scope.currentYear
  $scope.month = $scope.currentMonth

  $scope.go = function (type) {
    if (type === 'forward') {
      if ($scope.month === 12) {
        $scope.month = 1
        $scope.year += 1
      } else {
        $scope.month += 1
      }
    } else {
      if ($scope.month === 1) {
        $scope.month = 12
        $scope.year -= 1
      } else {
        $scope.month -= 1
      }
    }
    $scope.dateToViewCalculate()
    $scope.getTopPlanterPerMonth($scope.year, $scope.month)
  }

  $scope.dateToViewCalculate = function () {
    var monthToView = $scope.month >= 10 ? '' + $scope.month : '0' + $scope.month
    $scope.dateToView = $scope.year + '-' + monthToView + '-' + '01'
  }
  $scope.dateToViewCalculate()

  $scope.getTopPlanterPerMonth = function (year, month) {
    Game.getTopPlanterPerMonth(year, month)
    .then(function(res) {
      var data = res.data || res
      $scope.topPlanterMonth = data
    })
  }
  $scope.getTopPlanterPerMonth($scope.year, $scope.month)
}])
