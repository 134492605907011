



















import { maxWidth, useMediaQuery } from "@f/utils/breakpoints";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "QuestionLayout",
  props: {
    question: {
      type: String,
    },
  },
  setup() {
    const isMobile = useMediaQuery(maxWidth("md"));
    return {
      isMobile,
    };
  },
});
