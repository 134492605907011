import Swiper from 'swiper'
import { checkTreecode } from '@f/services/Treecodes'

angular.module('ngApp').controller('iframeController', [
  '$rootScope',
  '$scope',
  '$state',
  '$timeout',
  '$filter',
  'User',
  'Event',
  'Tree',
  function ($rootScope, $scope, $state, $timeout, $filter, User, Event, Tree) {
    var ctrl = this
    $rootScope.isIframe = true
    ctrl.iframeMode = null
    ctrl.options = {}
    ctrl.user = null
    ctrl.events = []
    ctrl.eventSelected = null
    ctrl.reicevers = []
    ctrl.otherReiceversToLoad = true
    ctrl.trees = []
    ctrl.treesOffset = 0
    ctrl.treesSlider = null
    ctrl.otherTreesToLoad = true
    ctrl.reiceversOffset = 0
    ctrl.reiceversSlider = null
    ctrl.eventsSlider = null
    ctrl.userCountriesSlider = null
    ctrl.eventCountriesSlider = null
    ctrl.activeTab = ''
    ctrl.activeTree = null
    ctrl.treeWeatherToShow = null
    ctrl.externalLink = $state.href('index', null, { absolute: true })
    $scope.coords = null

    /**
     * Initialize iframe
     * @param {string} type - 'user' or 'event'
     * @param {Object} user - User info
     * @param {Object} variables - Iframe variables { code, emission, event, generallink, href, project, title, tree, user }
     * @param {Object|Array} event - If type 'user': iframe list. If type 'event': object
     */
    ctrl.iframeInit = function (type, user, variables, event, baseUrl) {
      $rootScope.iframeOption = variables
      $rootScope.baseUrlAssets = baseUrl

      // Settings variables
      const { lat, lng, ...opts } = variables
      ctrl.options = opts
      $scope.coords = lng && lat ? [lng, lat] : null

      // event, Private or Business
      ctrl.iframeMode = type === 'event' ? type : user.user_type
      $rootScope.iframeMode = ctrl.iframeMode
      var userOrEventId = ctrl.iframeMode === 'event' ? event.id : user.id

      getUserInfo(user.slug, event.slug, function () {
        getTrees(ctrl.iframeMode, userOrEventId, ctrl.treesOffset)
      })
    }

    ctrl.selectEvent = function (slug) {
      if (ctrl.eventSelected && ctrl.eventSelected.slug === slug) return
      ctrl.eventSelected = getActiveEvent(slug, ctrl.events)

      if (slug) {
        renderMap('Business', null, ctrl.eventSelected.id, true)
        getTrees('event', ctrl.eventSelected.id, ctrl.treesOffset)
        getReceiverslist(
          ctrl.user.id,
          ctrl.eventSelected.id,
          ctrl.reiceversOffset,
          function (reicevers) {
            if (reicevers.length < 100) ctrl.otherReiceversToLoad = false
            ctrl.reicevers = reicevers
          }
        )
        return
      }

      renderMap('Business', ctrl.user.id, null, true)
      getTrees(ctrl.user.usertype, ctrl.user.id, ctrl.treesOffset)
      getReceiverslist(
        ctrl.user.id,
        null,
        ctrl.reiceversOffset,
        function (reicevers) {
          ctrl.reiceversOffset++
          ctrl.reicevers = reicevers
        }
      )
    }

    // Swiper trees
    ctrl.renderTreesSwiper = function () {
      if (ctrl.treesSlider) {
        !Array.isArray(ctrl.treesSlider)
          ? ctrl.treesSlider.destroy(true, true)
          : ctrl.treesSlider.forEach(function (s) {
              s.destroy(true, true)
            })
      }

      if (!ctrl.trees.length) return
      $scope.nextCursor = false
      ctrl.treesSlider = new Swiper('#SwiperTrees', {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 30,
        nextButton: '#TreesButtonNextSlider',
        prevButton: '#TreesButtonPrevSlider',
        pagination: '#TreesSliderPagination',
        paginationClickable: true,
        grabCursor: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        },
      })

      ctrl.treesSlider.on('transitionEnd', function (swiper) {
        if (swiper.isEnd && $scope.hasNextPage) {
          var index = swiper.activeIndex
          var userOrEventId =
            ctrl.iframeMode === 'event' ? ctrl.eventSelected.id : ctrl.user.id
          var type =
            ctrl.iframeMode === 'event' ? ctrl.iframeMode : ctrl.user.usertype

          // Get trees than return to index
          getTrees(type, userOrEventId)
        }
      })
    }

    $timeout(function () {
      ctrl.renderTreesSwiper()
    }, 300)

    // Swiper Reicever
    ctrl.renderReiceverSwiper = function () {
      if (ctrl.reiceversSlider) {
        !Array.isArray(ctrl.reiceversSlider)
          ? ctrl.reiceversSlider.destroy(true, true)
          : ctrl.reiceversSlider.forEach(function (s) {
              s.destroy(true, true)
            })
      }

      if (!ctrl.reicevers.length) return

      ctrl.reiceversSlider = new Swiper('#SwiperReicevers', {
        slidesPerView: 4,
        centeredSlides: false,
        spaceBetween: 30,
        nextButton: '#ReiceversButtonNextSlider',
        prevButton: '#ReiceversButtonPrevSlider',
        pagination: '#ReiceversSliderPagination',
        paginationClickable: true,
        grabCursor: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      })

      ctrl.reiceversSlider.on('transitionEnd', function (swiper) {
        if (swiper.isEnd && ctrl.otherReiceversToLoad) {
          getReceiverslist(
            ctrl.user.id,
            null,
            ctrl.reiceversOffset,
            function (reicevers) {
              ctrl.reiceversOffset++
              ctrl.reicevers = ctrl.reicevers.concat(reicevers)
              if (reicevers.length < 100) ctrl.otherReiceversToLoad = false
            }
          )
        }
      })
    }

    // Swiper Events
    ctrl.renderEventsSwiper = function () {
      if (ctrl.eventsSlider) {
        !Array.isArray(ctrl.eventsSlider)
          ? ctrl.eventsSlider.destroy(true, true)
          : ctrl.eventsSlider.forEach(function (s) {
              s.destroy(true, true)
            })
      }

      if (!ctrl.events.length) return

      ctrl.eventsSlider = new Swiper('#SwiperEvents', {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 30,
        nextButton: '#EventsButtonNextSlider',
        prevButton: '#EventsButtonPrevSlider',
        pagination: '#EventsSliderPagination',
        paginationClickable: true,
        grabCursor: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      })
    }

    // Swiper User Countries
    ctrl.renderUserCountriesSwiper = function () {
      if (ctrl.userCountriesSlider) {
        !Array.isArray(ctrl.userCountriesSlider)
          ? ctrl.userCountriesSlider.destroy(true, true)
          : ctrl.userCountriesSlider.forEach(function (s) {
              s.destroy(true, true)
            })
      }

      if (!ctrl.user.projects.length) return

      ctrl.userCountriesSlider = new Swiper('#SwiperUserCountries', {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 30,
        nextButton: '#UserCountriesButtonNextSlider',
        prevButton: '#UserCountriesButtonPrevSlider',
        pagination: '#UserCountriesSliderPagination',
        paginationClickable: true,
        grabCursor: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      })
    }

    // Swiper Event Countries
    ctrl.renderEventCountriesSwiper = function () {
      if (ctrl.eventCountriesSlider) {
        !Array.isArray(ctrl.eventCountriesSlider)
          ? ctrl.eventCountriesSlider.destroy(true, true)
          : ctrl.eventCountriesSlider.forEach(function (s) {
              s.destroy(true, true)
            })
      }

      if (!ctrl.eventSelected.projects.length) return

      ctrl.eventCountriesSlider = new Swiper('#SwiperEventCountries', {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 30,
        nextButton: '#EventCountriesButtonNextSlider',
        prevButton: '#EventCountriesButtonPrevSlider',
        pagination: '#EventCountriesSliderPagination',
        paginationClickable: true,
        grabCursor: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      })
    }

    // Open tab
    ctrl.openTab = function (tab) {
      if (ctrl.activeTab === tab) {
        ctrl.activeTab = ''
        return
      }
      switch (tab) {
        case 'trees':
          $timeout(function () {
            ctrl.renderTreesSwiper()
          })
          break
      }

      ctrl.activeTab = tab
    }

    // Open tree modal
    ctrl.openTreeModal = function (tree) {
      ctrl.activeTree = tree
    }

    // Close tree modal
    ctrl.closeTreeModal = function () {
      ctrl.activeTree = undefined
    }

    // Emission comparison number
    ctrl.comparisonNumber = function (co2Captured) {
      var totComparison = 0

      if (co2Captured < 20000) {
        totComparison = co2Captured / 0.13
        $scope.comparisonType = 'businessPage.comparison.small'
      } else if (co2Captured >= 20000 && co2Captured < 2000000) {
        totComparison = co2Captured / 192
        $scope.comparisonType = 'businessPage.comparison.medium'
      } else {
        totComparison = co2Captured / 4708
        $scope.comparisonType = 'businessPage.comparison.large'
      }

      return $filter('number')(totComparison, 0)
    }

    ctrl.openWeatherPreview = function (treeId) {
      if (!treeId) return

      ctrl.closeTreeModal()
      ctrl.openTab()
      ctrl.treeWeatherToShow = treeId

      Tree.getTreeData(treeId).then(function (_treedata) {
        $scope.currentItemTree = 0
        $rootScope.singleTree = _treedata
        $scope.actualTreeId = $rootScope.singleTree.id
        $scope.$apply()
      })
    }

    ctrl.closeWeatherPreview = function () {
      ctrl.treeWeatherToShow = undefined
      $rootScope.singleTree = undefined
    }

    ctrl.checkGift = function (giftCode) {
      $scope.error = $scope.errorMessage = false
      window.open(
        $state.href('treecode', { treecode: giftCode }, { absolute: true }),
        '_blank'
      )
    }

    var renderMap = function (type, id, eventId, noTimeout) {
      var timeout = noTimeout ? 1 : 2000

      $timeout(function () {
        if (type === 'Private' && !eventId) {
          $rootScope.$broadcast('LoadUserMap', {
            userId: id,
            expanded: true,
            hideHeader: true,
            hideFilters: true,
          })
        } else {
          $rootScope.$broadcast('LoadOrganizationMap', {
            id: eventId || id,
            type: eventId ? 'event' : 'Business',
            expanded: true,
            hideHeader: true,
            hideFilters: true,
            preview: true,
          })
        }
      }, timeout)
    }

    var getUserInfo = function (slug, eventSlug, cb) {
      User.legacyUserInfo(slug).then(function (res) {
        res = res.data || res
        ctrl.user = res.info
        ctrl.events = res.events

        if (eventSlug)
          ctrl.eventSelected = getActiveEvent(eventSlug, ctrl.events)

        // Se l'utente è di tipo business o c'è un evento selezionato,
        // trovo gli utenti che hanno ricevuto un albero.
        if (ctrl.user.usertype !== 'Private' || ctrl.eventSelected) {
          var eventId = ctrl.eventSelected ? ctrl.eventSelected.id : undefined
          getReceiverslist(
            ctrl.user.id,
            eventId,
            ctrl.reiceversOffset,
            function (reicevers) {
              ctrl.reicevers = reicevers
              if (reicevers.length < 100) ctrl.otherReiceversToLoad = false
            }
          )
        }

        if (cb) cb(res)
      })
    }

    var getTrees = function (type, idOrSlug, offset, cb) {
      offset = offset || 0

      switch (type) {
        case 'Business':
          /*User.getBusinessTrees(idOrSlug, offset)
            .then(function(res) {

              res = res.data || res
              var parsedTrees = parseTrees(type, res)
              if (parsedTrees.length < 100) ctrl.otherTreesToLoad = false
              ctrl.trees = ctrl.trees.concat(parsedTrees)
              if (cb) cb(ctrl.trees)
            })
*/
          return User.getIframePagedTrees(idOrSlug, $scope.nextCursor).then(
            function (data) {
              var trees = data.trees
              $scope.hasNextPage =
                (trees.pageInfo && trees.pageInfo.hasNextPage) || false
              $scope.nextCursor =
                trees.pageInfo &&
                trees.pageInfo.hasNextPage &&
                trees.pageInfo.endCursor
              //ctrl.trees = ctrl.trees.concat(trees.edges)
              if (!ctrl.trees.length) {
                ctrl.trees = trees.edges
              } else {
                trees.edges.forEach(function (_tree) {
                  ctrl.trees.push(_tree)
                })
                updateSlider()
              }
            }
          )
        case 'Private':
          return User.getIframePagedTrees(idOrSlug, $scope.nextCursor).then(
            function (data) {
              var trees = data.trees
              $scope.hasNextPage =
                (trees.pageInfo && trees.pageInfo.hasNextPage) || false
              $scope.nextCursor =
                trees.pageInfo &&
                trees.pageInfo.hasNextPage &&
                trees.pageInfo.endCursor
              //ctrl.trees = ctrl.trees.concat(trees.edges)
              if (!ctrl.trees.length) {
                ctrl.trees = trees.edges
              } else {
                trees.edges.forEach(function (_tree) {
                  ctrl.trees.push(_tree)
                })
                updateSlider()
              }
            }
          )
        case 'event':
          return User.getIframePagedEventTrees(
            idOrSlug,
            $scope.nextCursor
          ).then(function (data) {
            var trees = data.trees
            $scope.hasNextPage =
              (trees.pageInfo && trees.pageInfo.hasNextPage) || false
            $scope.nextCursor =
              trees.pageInfo &&
              trees.pageInfo.hasNextPage &&
              trees.pageInfo.endCursor
            //ctrl.trees = ctrl.trees.concat(trees.edges)
            if (!ctrl.trees.length) {
              ctrl.trees = trees.edges
            } else {
              trees.edges.forEach(function (_tree) {
                ctrl.trees.push(_tree)
              })
              updateSlider()
            }
          })
        default:
          ctrl.trees = []
          if (cb) cb(ctrl.trees)
      }
    }

    var updateSlider = function () {
      if (ctrl.treesSlider) {
        if (!$scope.$$phase) $scope.$apply()
        $timeout(function () {
          ctrl.treesSlider.update()
        })
      }
    }

    // Parse tree.
    // FIXME: Funzione che parsa gli alberi perchè da backend hanno strutture diverse.
    var parseTrees = function (type, trees) {
      switch (type) {
        case 'Business':
          return trees.map(function (tree) {
            return {
              user: {
                id: Number(tree.user.id),
                slug: tree.user.slug,
                title: tree.user.title,
                avatar: tree.user.avatar,
                type: tree.user.type,
              },
              initialUser: {
                id: Number(ctrl.user.id),
                slug: ctrl.user.slug,
                title: ctrl.user.title,
                avatar: ctrl.user.picture,
                type: ctrl.user.usertype,
              },
              specie: tree.specie.name,
              icon: tree.limited.icon || tree.specie.picture,
              country: tree.country.name,
              id: Number(tree.tree.id),
            }
          })

        case 'Private':
          return trees.map(function (tree) {
            return {
              user: {
                id: Number(ctrl.user.id),
                slug: ctrl.user.slug,
                title: ctrl.user.title,
                avatar: ctrl.user.picture,
                type: ctrl.user.usertype,
              },
              initialUser: {
                id: Number(tree.initialuser.id),
                slug: tree.initialuser.slug,
                title: tree.initialuser.title,
                avatar: tree.initialuser.avatar,
                type: tree.initialuser.userType,
              },
              specie: tree.specie.name,
              icon: tree.limited.icon || tree.specie.picture,
              country: tree.country.name,
              id: Number(tree.tree.id),
            }
          })

        case 'event':
          return trees.map(function (tree) {
            return {
              user: {
                id: Number(tree.utilizer.id),
                slug: tree.utilizer.slug,
                title: tree.utilizer.title,
                avatar: tree.utilizer.picture,
                type: tree.utilizer.type,
              },
              initialUser: {
                id: Number(tree.planter.id),
                slug: tree.planter.slug,
                title: tree.planter.tutle,
                avatar: tree.planter.picture,
                type: tree.planter.type,
              },
              specie: tree.specie.name,
              icon: tree.limited.icon || tree.specie.picture,
              country: tree.country.name,
              id: Number(tree.tree.id),
            }
          })
      }
    }

    var getActiveEvent = function (slug, eventsList) {
      return eventsList.filter(function (event) {
        return event.slug === slug
      })[0]
    }

    var getReceiverslist = function (userId, eventId, offset, cb) {
      if (eventId) {
        Event.getUsers(eventId, offset).then(function (res) {
          res = res.data || res
          ctrl.eventSelected.numReicevers = res.count
          if (cb) cb(res.users)
        })
      } else {
        User.getBusinessReceivers(userId, offset).then(function (res) {
          res = res.data || res
          ctrl.user.numReicevers = res.count
          if (cb) cb(res.users)
        })
      }
    }
  },
])
