
































import DotLoader from "@f/components/DotLoader.vue";
import Treecon from "@f/components/Treecons/Treecon.vue";
import { getStateService } from "@f/services/Angular";
import { defineComponent, ref, watch } from "@vue/composition-api";
import LevelCompletion from "./../../Components/LevelCompletion.vue";
import CommonHeaderGame from "./../../Layout/CommonHeaderGame.vue";
import ShareReward from "./shareReward/ShareReward.vue";
import SocialShare from "./SocialShare.vue";
// import {fetchGameData, getTreecodeInfo} from "../../mockData";
import useUserInfo from '@f/composables/useUserInfo';
import { fetchGameData, getTreecodeInfo } from "./../../gameData";

export default defineComponent({
  name: "GameCompleted",
  props: {
    treecode: {type: String, required: true},
    rewardUuid: String,
  },
  components: {
    DotLoader,
    Treecon,
    ShareReward,
    LevelCompletion,
    SocialShare,
    CommonHeaderGame
  },
  setup(props) {
    const gameInfo = ref();
    const gameInfoLoading = ref(true);
    const rewardloading = ref(true);
    const rewardTreecode = ref();
    const forestLink = ref();
    const stateService = getStateService();
    const christmasSkin = ref(false);
    const background = ref('');
    const {userInfo} = useUserInfo();

    watch(userInfo, async (value) => {
      fetchGameData(props.treecode, String(value?.id)).then(async (_gameInfo) => {
        if (!_gameInfo || _gameInfo.gameCompleted) {
          stateService && stateService.go("game", {treecode: props.treecode});
          return 
        }
        gameInfo.value = _gameInfo;
        gameInfoLoading.value = false;
        await getTreecodeInfo({field: 'CODE', value: props.treecode}).then((treecode) => {
          if (!treecode) {
            stateService && stateService.go("game", {treecode: props.treecode});
            return
          }
          const forestSlug = treecode.event?.slug || treecode.trees[0].event?.slug;
          const userForestSlug = treecode.event?.slug || treecode.trees[0].event?.slug;
          forestLink.value = `${window.origin}${stateService && stateService.href('organization.event', {
            slug: userForestSlug,
            eventslug: forestSlug
          })}`;
        }).finally(() => rewardloading.value = false);
      }).catch((error) => {
        console.log(error)
        stateService && stateService.go("game", {treecode: props.treecode});
      });
    });

    return {
      gameInfo,
      rewardTreecode,
      gameInfoLoading,
      rewardloading,
      forestLink,
      christmasSkin,
      background
    };
  },
});
