import { Vue, Component } from 'vue-property-decorator'

@Component
export default class FetchApi extends Vue {
  
  apiUri: string | null = null
  locale: string = 'it'

  // Events
  mounted(): void {
    this.apiUri = document.getElementsByTagName('base')[0].href
    this.locale = this.$rootScope.ln
  }

}
