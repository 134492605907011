import Vue from 'vue'

// Functional placeholder
const TreeconPlaceholder = Vue.extend({
  name: 'TreeconPlaceholder',
  functional: true,
  render(createElement) {
    return createElement(
      'svg',
      {
        attrs: {
          viewBox: '0 0 24 24',
          xmlns: 'http://www.w3.org/2000/svg'
        }
      }
    )
  }
})

const Treecons = {
  'treedom-fill': () => ({
    component: import(/* webpackChunkName: 'treedom-fill.svg' */ '@f/images/treecons/treedom-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'QR-code-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-QR-code-fill.svg' */ '@f/images/treecons/QR-code-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'QR-code-line': () => ({
    component: import(/* webpackChunkName: 'treecon-QR-code-line.svg' */ '@f/images/treecons/QR-code-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'QR-scan-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-QR-scan-fill.svg' */ '@f/images/treecons/QR-scan-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'QR-scan-line': () => ({
    component: import(/* webpackChunkName: 'treecon-QR-scan-line.svg' */ '@f/images/treecons/QR-scan-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'account-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-account-fill.svg' */ '@f/images/treecons/account-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'account-line': () => ({
    component: import(/* webpackChunkName: 'treecon-account-line.svg' */ '@f/images/treecons/account-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'account-pin-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-account-pin-fill.svg' */ '@f/images/treecons/account-pin-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'account-pin-line': () => ({
    component: import(/* webpackChunkName: 'treecon-account-pin-line.svg' */ '@f/images/treecons/account-pin-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'add-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-add-fill.svg' */ '@f/images/treecons/add-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'add-image-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-add-image-fill.svg' */ '@f/images/treecons/add-image-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'add-image-line': () => ({
    component: import(/* webpackChunkName: 'treecon-add-image-line.svg' */ '@f/images/treecons/add-image-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'add-line': () => ({
    component: import(/* webpackChunkName: 'treecon-add-line.svg' */ '@f/images/treecons/add-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'airplaine-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-airplaine-fill.svg' */ '@f/images/treecons/airplaine-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'airplaine-line': () => ({
    component: import(/* webpackChunkName: 'treecon-airplaine-line.svg' */ '@f/images/treecons/airplaine-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'allert-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-allert-fill.svg' */ '@f/images/treecons/allert-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'allert-line': () => ({
    component: import(/* webpackChunkName: 'treecon-allert-line.svg' */ '@f/images/treecons/allert-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'arrow-simple-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-arrow-simple-fill.svg' */ '@f/images/treecons/arrow-simple-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'arrow-simple-line': () => ({
    component: import(/* webpackChunkName: 'treecon-arrow-simple-line.svg' */ '@f/images/treecons/arrow-simple-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'arrow-sx-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-arrow-sx-fill.svg' */ '@f/images/treecons/arrow-sx-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'arrow-sx-line': () => ({
    component: import(/* webpackChunkName: 'treecon-arrow-sx-line.svg' */ '@f/images/treecons/arrow-sx-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'at-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-at-fill.svg' */ '@f/images/treecons/at-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'at-line': () => ({
    component: import(/* webpackChunkName: 'treecon-at-line.svg' */ '@f/images/treecons/at-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'bank-card-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-bank-card-fill.svg' */ '@f/images/treecons/bank-card-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'bank-card-line': () => ({
    component: import(/* webpackChunkName: 'treecon-bank-card-line.svg' */ '@f/images/treecons/bank-card-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'book-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-book-fill.svg' */ '@f/images/treecons/book-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'book-line': () => ({
    component: import(/* webpackChunkName: 'treecon-book-line.svg' */ '@f/images/treecons/book-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'briefcase-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-briefcase-fill.svg' */ '@f/images/treecons/briefcase-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'briefcase-line': () => ({
    component: import(/* webpackChunkName: 'treecon-briefcase-line.svg' */ '@f/images/treecons/briefcase-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'bus-line-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-bus-line-fill.svg' */ '@f/images/treecons/bus-line-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'bus-line-line': () => ({
    component: import(/* webpackChunkName: 'treecon-bus-line-line.svg' */ '@f/images/treecons/bus-line-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'business-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-business-fill.svg' */ '@f/images/treecons/business-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'business-line': () => ({
    component: import(/* webpackChunkName: 'treecon-business-line.svg' */ '@f/images/treecons/business-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'card-line': () => ({
    component: import(/* webpackChunkName: 'card-line.svg' */ '@f/images/treecons/card-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cake-2-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-cake-2-fill.svg' */ '@f/images/treecons/cake-2-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cake-2-line': () => ({
    component: import(/* webpackChunkName: 'treecon-cake-2-line.svg' */ '@f/images/treecons/cake-2-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cake-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-cake-fill.svg' */ '@f/images/treecons/cake-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cake-line': () => ({
    component: import(/* webpackChunkName: 'treecon-cake-line.svg' */ '@f/images/treecons/cake-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'calendar-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-calendar-fill.svg' */ '@f/images/treecons/calendar-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'calendar-line': () => ({
    component: import(/* webpackChunkName: 'treecon-calendar-line.svg' */ '@f/images/treecons/calendar-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'camera-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-camera-fill.svg' */ '@f/images/treecons/camera-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'camera-line': () => ({
    component: import(/* webpackChunkName: 'treecon-camera-line.svg' */ '@f/images/treecons/camera-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'card': () => ({
    component: import(/* webpackChunkName: 'treecon-card.svg' */ '@f/images/treecons/card.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'car-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-car-fill.svg' */ '@f/images/treecons/car-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'car-line': () => ({
    component: import(/* webpackChunkName: 'treecon-car-line.svg' */ '@f/images/treecons/car-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cart-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-cart-fill.svg' */ '@f/images/treecons/cart-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cart-full-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-cart-full-fill.svg' */ '@f/images/treecons/cart-full-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cart-full-line': () => ({
    component: import(/* webpackChunkName: 'treecon-cart-full-line.svg' */ '@f/images/treecons/cart-full-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cart-line': () => ({
    component: import(/* webpackChunkName: 'treecon-cart-line.svg' */ '@f/images/treecons/cart-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cartoon-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-cartoon-fill.svg' */ '@f/images/treecons/cartoon-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cartoon-line': () => ({
    component: import(/* webpackChunkName: 'treecon-cartoon-line.svg' */ '@f/images/treecons/cartoon-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'check-fill-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-check-fill-fill.svg' */ '@f/images/treecons/check-fill-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'check-fill-line': () => ({
    component: import(/* webpackChunkName: 'treecon-check-fill-line.svg' */ '@f/images/treecons/check-fill-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'clockwise-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-clockwise-fill.svg' */ '@f/images/treecons/clockwise-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'clockwise-line': () => ({
    component: import(/* webpackChunkName: 'treecon-clockwise-line.svg' */ '@f/images/treecons/clockwise-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'close-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-close-fill.svg' */ '@f/images/treecons/close-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'close-line': () => ({
    component: import(/* webpackChunkName: 'treecon-close-line.svg' */ '@f/images/treecons/close-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cloud-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-cloud-fill.svg' */ '@f/images/treecons/cloud-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'cloud-line': () => ({
    component: import(/* webpackChunkName: 'treecon-cloud-line.svg' */ '@f/images/treecons/cloud-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'crop-line-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-crop-line-fill.svg' */ '@f/images/treecons/crop-line-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'crop-line-line': () => ({
    component: import(/* webpackChunkName: 'treecon-crop-line-line.svg' */ '@f/images/treecons/crop-line-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'crown-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-crown-fill.svg' */ '@f/images/treecons/crown-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'crown-line': () => ({
    component: import(/* webpackChunkName: 'treecon-crown-line.svg' */ '@f/images/treecons/crown-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'delete-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-delete-fill.svg' */ '@f/images/treecons/delete-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'delete-line': () => ({
    component: import(/* webpackChunkName: 'treecon-delete-line.svg' */ '@f/images/treecons/delete-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'desktop-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-desktop-fill.svg' */ '@f/images/treecons/desktop-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'desktop-line': () => ({
    component: import(/* webpackChunkName: 'treecon-desktop-line.svg' */ '@f/images/treecons/desktop-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'diary-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-diary-fill.svg' */ '@f/images/treecons/diary-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'diary-line': () => ({
    component: import(/* webpackChunkName: 'treecon-diary-line.svg' */ '@f/images/treecons/diary-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'document-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-document-fill.svg' */ '@f/images/treecons/document-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'document-line': () => ({
    component: import(/* webpackChunkName: 'treecon-document-line.svg' */ '@f/images/treecons/document-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'double-cartoon-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-double-cartoon-fill.svg' */ '@f/images/treecons/double-cartoon-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'double-cartoon-line': () => ({
    component: import(/* webpackChunkName: 'treecon-double-cartoon-line.svg' */ '@f/images/treecons/double-cartoon-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'download-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-download-fill.svg' */ '@f/images/treecons/download-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'download-line': () => ({
    component: import(/* webpackChunkName: 'treecon-download-line.svg' */ '@f/images/treecons/download-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'earth-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-earth-fill.svg' */ '@f/images/treecons/earth-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'earth-line': () => ({
    component: import(/* webpackChunkName: 'treecon-earth-line.svg' */ '@f/images/treecons/earth-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'edit-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-edit-fill.svg' */ '@f/images/treecons/edit-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'edit-image-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-edit-image-fill.svg' */ '@f/images/treecons/edit-image-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'edit-image-line': () => ({
    component: import(/* webpackChunkName: 'treecon-edit-image-line.svg' */ '@f/images/treecons/edit-image-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'edit-line': () => ({
    component: import(/* webpackChunkName: 'treecon-edit-line.svg' */ '@f/images/treecons/edit-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'emotion-happy-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-emotion-happy-fill.svg' */ '@f/images/treecons/emotion-happy-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'emotion-happy-line': () => ({
    component: import(/* webpackChunkName: 'treecon-emotion-happy-line.svg' */ '@f/images/treecons/emotion-happy-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'error-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-error-fill.svg' */ '@f/images/treecons/error-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'error-line': () => ({
    component: import(/* webpackChunkName: 'treecon-error-line.svg' */ '@f/images/treecons/error-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'euro-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-euro-fill.svg' */ '@f/images/treecons/euro-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'euro-line': () => ({
    component: import(/* webpackChunkName: 'treecon-euro-line.svg' */ '@f/images/treecons/euro-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'usd-line': () => ({
    component: import(/* webpackChunkName: 'treecon-usd-line.svg' */ '@f/images/treecons/usd-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'gbp-line': () => ({
    component: import(/* webpackChunkName: 'treecon-usd-line.svg' */ '@f/images/treecons/gbp-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'expand-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-expand-fill.svg' */ '@f/images/treecons/expand-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0
  }),
  'eye-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-eye-fill.svg' */ '@f/images/treecons/eye-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'eye-line': () => ({
    component: import(/* webpackChunkName: 'treecon-eye-line.svg' */ '@f/images/treecons/eye-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'eye-offline-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-eye-offline-fill.svg' */ '@f/images/treecons/eye-offline-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'eye-offline-line': () => ({
    component: import(/* webpackChunkName: 'treecon-eye-offline-line.svg' */ '@f/images/treecons/eye-offline-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'favors-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-favors-fill.svg' */ '@f/images/treecons/favors-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'favors-line': () => ({
    component: import(/* webpackChunkName: 'treecon-favors-line.svg' */ '@f/images/treecons/favors-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'file-add-line': () => ({
    component: import(/* webpackChunkName: 'treecon-file-add-line.svg' */ '@f/images/treecons/file-add-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'fileadd-line': () => ({
    component: import(/* webpackChunkName: 'treecon-fileadd-line.svg' */ '@f/images/treecons/fileadd-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'filter-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-filter-fill.svg' */ '@f/images/treecons/filter-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'filter-line': () => ({
    component: import(/* webpackChunkName: 'treecon-filter-line.svg' */ '@f/images/treecons/filter-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'flag-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-flag-fill.svg' */ '@f/images/treecons/flag-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'flag-line': () => ({
    component: import(/* webpackChunkName: 'treecon-flag-line.svg' */ '@f/images/treecons/flag-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'focus-target-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-focus-target-fill.svg' */ '@f/images/treecons/focus-target-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'focus-target-line': () => ({
    component: import(/* webpackChunkName: 'treecon-focus-target-line.svg' */ '@f/images/treecons/focus-target-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'folder-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-folder-fill.svg' */ '@f/images/treecons/folder-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'folder-line': () => ({
    component: import(/* webpackChunkName: 'treecon-folder-line.svg' */ '@f/images/treecons/folder-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'footprint-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-footprint-fill.svg' */ '@f/images/treecons/footprint-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'footprint-line': () => ({
    component: import(/* webpackChunkName: 'treecon-footprint-line.svg' */ '@f/images/treecons/footprint-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'forest-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-forest-fill.svg' */ '@f/images/treecons/forest-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'forest-line': () => ({
    component: import(/* webpackChunkName: 'treecon-forest-line.svg' */ '@f/images/treecons/forest-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'gift-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-gift-fill.svg' */ '@f/images/treecons/gift-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'gift-fill-2': () => ({
    component: import(/* webpackChunkName: 'treecon-gift-fill.svg' */ '@f/images/treecons/gift-fill-2.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'gift-in-delivery': () => ({
    component: import(/* webpackChunkName: 'treecon-gift-in-delivery.svg' */ '@f/images/treecons/gift-in-delivery.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'gift-line': () => ({
    component: import(/* webpackChunkName: 'treecon-gift-line.svg' */ '@f/images/treecons/gift-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'ham-menu-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-ham-menu-fill.svg' */ '@f/images/treecons/ham-menu-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'ham-menu-line': () => ({
    component: import(/* webpackChunkName: 'treecon-ham-menu-line.svg' */ '@f/images/treecons/ham-menu-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'hand-heart-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-hand-heart-fill.svg' */ '@f/images/treecons/hand-heart-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'hand-heart-line': () => ({
    component: import(/* webpackChunkName: 'treecon-hand-heart-line.svg' */ '@f/images/treecons/hand-heart-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'heart-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-heart-fill.svg' */ '@f/images/treecons/heart-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'heart-line': () => ({
    component: import(/* webpackChunkName: 'treecon-heart-line.svg' */ '@f/images/treecons/heart-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'hearts-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-hearts-fill.svg' */ '@f/images/treecons/hearts-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'hearts-line': () => ({
    component: import(/* webpackChunkName: 'treecon-hearts-line.svg' */ '@f/images/treecons/hearts-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'home-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-home-fill.svg' */ '@f/images/treecons/home-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'home-line': () => ({
    component: import(/* webpackChunkName: 'treecon-home-line.svg' */ '@f/images/treecons/home-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'image-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-image-fill.svg' */ '@f/images/treecons/image-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'image-line': () => ({
    component: import(/* webpackChunkName: 'treecon-image-line.svg' */ '@f/images/treecons/image-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'info-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-info-fill.svg' */ '@f/images/treecons/info-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'info-line': () => ({
    component: import(/* webpackChunkName: 'treecon-info-line.svg' */ '@f/images/treecons/info-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'leaf-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-leaf-fill.svg' */ '@f/images/treecons/leaf-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'leaf-line': () => ({
    component: import(/* webpackChunkName: 'treecon-leaf-line.svg' */ '@f/images/treecons/leaf-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'less-circle-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-less-circle-fill.svg' */ '@f/images/treecons/less-circle-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'less-circle-line': () => ({
    component: import(/* webpackChunkName: 'treecon-less-circle-line.svg' */ '@f/images/treecons/less-circle-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'lightbulb-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-lightbulb-fill.svg' */ '@f/images/treecons/lightbulb-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'lightbulb-line': () => ({
    component: import(/* webpackChunkName: 'treecon-lightbulb-line.svg' */ '@f/images/treecons/lightbulb-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'like-fb-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-like-fb-fill.svg' */ '@f/images/treecons/like-fb-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'like-fb-line': () => ({
    component: import(/* webpackChunkName: 'treecon-like-fb-line.svg' */ '@f/images/treecons/like-fb-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'link-line': () => ({
    component: import(/* webpackChunkName: 'link-line.svg' */ '@f/images/treecons/link-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'lock-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-lock-fill.svg' */ '@f/images/treecons/lock-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'lock-line': () => ({
    component: import(/* webpackChunkName: 'treecon-lock-line.svg' */ '@f/images/treecons/lock-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'mail-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-mail-fill.svg' */ '@f/images/treecons/mail-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'mail-line': () => ({
    component: import(/* webpackChunkName: 'treecon-mail-line.svg' */ '@f/images/treecons/mail-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'map-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-map-fill.svg' */ '@f/images/treecons/map-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'map-line': () => ({
    component: import(/* webpackChunkName: 'treecon-map-line.svg' */ '@f/images/treecons/map-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'map-pointer-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-map-pointer-fill.svg' */ '@f/images/treecons/map-pointer-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'map-pointer-line': () => ({
    component: import(/* webpackChunkName: 'treecon-map-pointer-line.svg' */ '@f/images/treecons/map-pointer-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'map-pointers-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-map-pointers-fill.svg' */ '@f/images/treecons/map-pointers-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'map-pointers-line': () => ({
    component: import(/* webpackChunkName: 'treecon-map-pointers-line.svg' */ '@f/images/treecons/map-pointers-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'microphone-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-microphone-fill.svg' */ '@f/images/treecons/microphone-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'microphone-line': () => ({
    component: import(/* webpackChunkName: 'treecon-microphone-line.svg' */ '@f/images/treecons/microphone-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'minus': () => ({
    component: import(/* webpackChunkName: 'treecon-minus.svg' */ '@f/images/treecons/minus.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'money-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-money-fill.svg' */ '@f/images/treecons/money-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'money-line': () => ({
    component: import(/* webpackChunkName: 'treecon-money-line.svg' */ '@f/images/treecons/money-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'more-fill-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-more-fill-fill.svg' */ '@f/images/treecons/more-fill-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'more-fill-line': () => ({
    component: import(/* webpackChunkName: 'treecon-more-fill-line.svg' */ '@f/images/treecons/more-fill-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'motorbike-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-motorbike-fill.svg' */ '@f/images/treecons/motorbike-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'motorbike-line': () => ({
    component: import(/* webpackChunkName: 'treecon-motorbike-line.svg' */ '@f/images/treecons/motorbike-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'multicurrency-line': () => ({
    component: import(/* webpackChunkName: 'multicurrency-line.svg' */ '@f/images/treecons/multicurrency-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'music-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-music-fill.svg' */ '@f/images/treecons/music-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'music-line': () => ({
    component: import(/* webpackChunkName: 'treecon-music-line.svg' */ '@f/images/treecons/music-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'music-note-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-music-note-fill.svg' */ '@f/images/treecons/music-note-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'music-note-line': () => ({
    component: import(/* webpackChunkName: 'treecon-music-note-line.svg' */ '@f/images/treecons/music-note-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'notification-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-notification-fill.svg' */ '@f/images/treecons/notification-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'notification-line': () => ({
    component: import(/* webpackChunkName: 'treecon-notification-line.svg' */ '@f/images/treecons/notification-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'numbers-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-numbers-fill.svg' */ '@f/images/treecons/numbers-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'numbers-line': () => ({
    component: import(/* webpackChunkName: 'treecon-numbers-line.svg' */ '@f/images/treecons/numbers-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'paper-clip-line': () => ({
    component: import(/* webpackChunkName: 'treecon-paper-clip-line.svg' */ '@f/images/treecons/paper-clip-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'paper-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-paper-fill.svg' */ '@f/images/treecons/paper-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'paper-line': () => ({
    component: import(/* webpackChunkName: 'treecon-paper-line.svg' */ '@f/images/treecons/paper-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pause-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-pause-fill.svg' */ '@f/images/treecons/pause-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pause-line': () => ({
    component: import(/* webpackChunkName: 'treecon-pause-line.svg' */ '@f/images/treecons/pause-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pencil-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-pencil-fill.svg' */ '@f/images/treecons/pencil-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pencil-line': () => ({
    component: import(/* webpackChunkName: 'treecon-pencil-line.svg' */ '@f/images/treecons/pencil-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'phone-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-phone-fill.svg' */ '@f/images/treecons/phone-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'phone-line': () => ({
    component: import(/* webpackChunkName: 'treecon-phone-line.svg' */ '@f/images/treecons/phone-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'ping-pong-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-ping-pong-fill.svg' */ '@f/images/treecons/ping-pong-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'ping-pong-line': () => ({
    component: import(/* webpackChunkName: 'treecon-ping-pong-line.svg' */ '@f/images/treecons/ping-pong-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'plant-classic-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-plant-classic-fill.svg' */ '@f/images/treecons/plant-classic-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'plant-classic-line': () => ({
    component: import(/* webpackChunkName: 'treecon-plant-classic-line.svg' */ '@f/images/treecons/plant-classic-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'plant-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-plant-fill.svg' */ '@f/images/treecons/plant-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'plant-line': () => ({
    component: import(/* webpackChunkName: 'treecon-plant-line.svg' */ '@f/images/treecons/plant-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'play-circle-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-play-circle-fill.svg' */ '@f/images/treecons/play-circle-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'play-circle-line': () => ({
    component: import(/* webpackChunkName: 'treecon-play-circle-line.svg' */ '@f/images/treecons/play-circle-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'play-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-play-fill.svg' */ '@f/images/treecons/play-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'play-line': () => ({
    component: import(/* webpackChunkName: 'treecon-play-line.svg' */ '@f/images/treecons/play-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'plus': () => ({
    component: import(/* webpackChunkName: 'treecon-plus.svg' */ '@f/images/treecons/plus.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pointer-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-pointer-fill.svg' */ '@f/images/treecons/pointer-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pointer-line': () => ({
    component: import(/* webpackChunkName: 'treecon-pointer-line.svg' */ '@f/images/treecons/pointer-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'price-tag-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-price-tag-fill.svg' */ '@f/images/treecons/price-tag-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'price-tag-line': () => ({
    component: import(/* webpackChunkName: 'treecon-price-tag-line.svg' */ '@f/images/treecons/price-tag-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'print-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-print-fill.svg' */ '@f/images/treecons/print-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'print-line': () => ({
    component: import(/* webpackChunkName: 'treecon-print-line.svg' */ '@f/images/treecons/print-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pushpin-2-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-pushpin-2-fill.svg' */ '@f/images/treecons/pushpin-2-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pushpin-2-line': () => ({
    component: import(/* webpackChunkName: 'treecon-pushpin-2-line.svg' */ '@f/images/treecons/pushpin-2-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pushpin-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-pushpin-fill.svg' */ '@f/images/treecons/pushpin-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'pushpin-line': () => ({
    component: import(/* webpackChunkName: 'treecon-pushpin-line.svg' */ '@f/images/treecons/pushpin-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'question-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-question-fill.svg' */ '@f/images/treecons/question-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'question-line': () => ({
    component: import(/* webpackChunkName: 'treecon-question-line.svg' */ '@f/images/treecons/question-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'quote-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-quote-fill.svg' */ '@f/images/treecons/quote-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'quote-line': () => ({
    component: import(/* webpackChunkName: 'treecon-quote-line.svg' */ '@f/images/treecons/quote-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'recenter-pointer-line': () => ({
    component: import(/* webpackChunkName: 'recenter-pointer-line.svg' */ '@f/images/treecons/recenter-pointer-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'restaurant-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-restaurant-fill.svg' */ '@f/images/treecons/restaurant-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'restaurant-line': () => ({
    component: import(/* webpackChunkName: 'treecon-restaurant-line.svg' */ '@f/images/treecons/restaurant-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'rewind-arrows-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-rewind-arrows-fill.svg' */ '@f/images/treecons/rewind-arrows-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'rewind-arrows-line': () => ({
    component: import(/* webpackChunkName: 'treecon-rewind-arrows-line.svg' */ '@f/images/treecons/rewind-arrows-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'scooter-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-scooter-fill.svg' */ '@f/images/treecons/scooter-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'scooter-line': () => ({
    component: import(/* webpackChunkName: 'treecon-scooter-line.svg' */ '@f/images/treecons/scooter-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'search-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-search-fill.svg' */ '@f/images/treecons/search-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'search-line': () => ({
    component: import(/* webpackChunkName: 'treecon-search-line.svg' */ '@f/images/treecons/search-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'settings-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-settings-fill.svg' */ '@f/images/treecons/settings-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'settings-line': () => ({
    component: import(/* webpackChunkName: 'treecon-settings-line.svg' */ '@f/images/treecons/settings-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'share-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-share-fill.svg' */ '@f/images/treecons/share-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'share-line': () => ({
    component: import(/* webpackChunkName: 'treecon-share-line.svg' */ '@f/images/treecons/share-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'ship-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-ship-fill.svg' */ '@f/images/treecons/ship-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'ship-line': () => ({
    component: import(/* webpackChunkName: 'treecon-ship-line.svg' */ '@f/images/treecons/ship-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'smartphone-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-smartphone-fill.svg' */ '@f/images/treecons/smartphone-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'smartphone-line': () => ({
    component: import(/* webpackChunkName: 'treecon-smartphone-line.svg' */ '@f/images/treecons/smartphone-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'speed-arrows-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-speed-arrows-fill.svg' */ '@f/images/treecons/speed-arrows-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'speed-arrows-line': () => ({
    component: import(/* webpackChunkName: 'treecon-speed-arrows-line.svg' */ '@f/images/treecons/speed-arrows-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'star-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-star-fill.svg' */ '@f/images/treecons/star-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'star-line': () => ({
    component: import(/* webpackChunkName: 'treecon-star-line.svg' */ '@f/images/treecons/star-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'stop-audio-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-stop-audio-fill.svg' */ '@f/images/treecons/stop-audio-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'stop-audio-line': () => ({
    component: import(/* webpackChunkName: 'treecon-stop-audio-line.svg' */ '@f/images/treecons/stop-audio-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'subway-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-subway-fill.svg' */ '@f/images/treecons/subway-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'subway-line': () => ({
    component: import(/* webpackChunkName: 'treecon-subway-line.svg' */ '@f/images/treecons/subway-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'success-circle-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-success-circle-fill.svg' */ '@f/images/treecons/success-circle-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'success-circle-line': () => ({
    component: import(/* webpackChunkName: 'treecon-success-circle-line.svg' */ '@f/images/treecons/success-circle-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'suitcase-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-suitcase-fill.svg' */ '@f/images/treecons/suitcase-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'suitcase-line': () => ({
    component: import(/* webpackChunkName: 'treecon-suitcase-line.svg' */ '@f/images/treecons/suitcase-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'taxi-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-taxi-fill.svg' */ '@f/images/treecons/taxi-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'taxi-line': () => ({
    component: import(/* webpackChunkName: 'treecon-taxi-line.svg' */ '@f/images/treecons/taxi-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'team-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-team-fill.svg' */ '@f/images/treecons/team-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'team-line': () => ({
    component: import(/* webpackChunkName: 'treecon-team-line.svg' */ '@f/images/treecons/team-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'train-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-train-fill.svg' */ '@f/images/treecons/train-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'train-line': () => ({
    component: import(/* webpackChunkName: 'treecon-train-line.svg' */ '@f/images/treecons/train-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'tree-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-tree-fill.svg' */ '@f/images/treecons/tree-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'tree-line': () => ({
    component: import(/* webpackChunkName: 'treecon-tree-line.svg' */ '@f/images/treecons/tree-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'trees-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-trees-fill.svg' */ '@f/images/treecons/trees-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'trees-line': () => ({
    component: import(/* webpackChunkName: 'treecon-trees-fill.svg' */ '@f/images/treecons/trees-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'triangle-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-trees-fill.svg' */ '@f/images/treecons/triangle-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),

  'trophy-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-trophy-fill.svg' */ '@f/images/treecons/trophy-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'trophy-line': () => ({
    component: import(/* webpackChunkName: 'treecon-trophy-line.svg' */ '@f/images/treecons/trophy-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'truck-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-truck-fill.svg' */ '@f/images/treecons/truck-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'truck-line': () => ({
    component: import(/* webpackChunkName: 'treecon-truck-line.svg' */ '@f/images/treecons/truck-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'umbrella-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-umbrella-fill.svg' */ '@f/images/treecons/umbrella-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'umbrella-line': () => ({
    component: import(/* webpackChunkName: 'treecon-umbrella-line.svg' */ '@f/images/treecons/umbrella-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'usb-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-usb-fill.svg' */ '@f/images/treecons/usb-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'usb-line': () => ({
    component: import(/* webpackChunkName: 'treecon-usb-line.svg' */ '@f/images/treecons/usb-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'video-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-video-fill.svg' */ '@f/images/treecons/video-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'video-line': () => ({
    component: import(/* webpackChunkName: 'treecon-video-line.svg' */ '@f/images/treecons/video-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'volume-mute-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-volume-mute-fill.svg' */ '@f/images/treecons/volume-mute-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'volume-mute-line': () => ({
    component: import(/* webpackChunkName: 'treecon-volume-mute-line.svg' */ '@f/images/treecons/volume-mute-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'volume-up-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-volume-up-fill.svg' */ '@f/images/treecons/volume-up-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'volume-up-line': () => ({
    component: import(/* webpackChunkName: 'treecon-volume-up-line.svg' */ '@f/images/treecons/volume-up-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'wallet-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-wallet-fill.svg' */ '@f/images/treecons/wallet-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'wallet-line': () => ({
    component: import(/* webpackChunkName: 'treecon-wallet-line.svg' */ '@f/images/treecons/wallet-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'wifi-fill': () => ({
    component: import(/* webpackChunkName: 'treecon-wifi-fill.svg' */ '@f/images/treecons/wifi-fill.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'wifi-line': () => ({
    component: import(/* webpackChunkName: 'treecon-wifi-line.svg' */ '@f/images/treecons/wifi-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'forest2': () => ({
    component: import(/* webpackChunkName: 'wedding.svg' */ '@f/images/icons/2-forest.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'forest3': () => ({
    component: import(/* webpackChunkName: 'birth.svg' */ '@f/images/icons/3-forest.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'forest1': () => ({
    component: import(/* webpackChunkName: 'birthday.svg' */ '@f/images/icons/1-forest.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'forest4': () => ({
    component: import(/* webpackChunkName: 'other.svg' */ '@f/images/icons/4-forest.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'social_facebook': () => ({
    component: import(/* webpackChunkName: 'social_facebook.svg' */ '@f/images/treecons/social_facebook.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'social_instagram': () => ({
    component: import(/* webpackChunkName: 'social_instagram.svg' */ '@f/images/treecons/social_instagram.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'social_linkedin': () => ({
    component: import(/* webpackChunkName: 'social_linkedin.svg' */ '@f/images/treecons/social_linkedin.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'social_pinterest': () => ({
    component: import(/* webpackChunkName: 'social_pinterest.svg' */ '@f/images/treecons/social_pinterest.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'social_twitter': () => ({
    component: import(/* webpackChunkName: 'social_twitter.svg' */ '@f/images/treecons/social_twitter.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'social_youtube': () => ({
    component: import(/* webpackChunkName: 'social_youtube.svg' */ '@f/images/treecons/social_youtube.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'website': () => ({
    component: import(/* webpackChunkName: 'website.svg' */ '@f/images/treecons/website.svg'),
    loading: TreeconPlaceholder,
    delay: 0,
  }),
  'social_tiktok': () => ({
    component: import(/* webpackChunkName: 'website.svg' */ '@f/images/treecons/social_tiktok.svg'),
    loading: TreeconPlaceholder,
    delay: 0
  }),
  'calendar-check-line': () => ({
    component: import(/* webpackChunkName: 'treecon-calendar-check-line.svg' */ '@f/images/treecons/calendar-check-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0
  }),
  'coffee-seed-line': () => ({
    component: import(/* webpackChunkName: 'treecon-calendar-check-line.svg' */ '@f/images/treecons/coffee-seed-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0
  }),
  'airc-line': () => ({
    component: import(/* webpackChunkName: 'treecon-calendar-check-line.svg' */ '@f/images/treecons/airc-line.svg'),
    loading: TreeconPlaceholder,
    delay: 0
  }),
}

export default {
  TreeconPlaceholder,
  ...Treecons
}
