










import {Component, Vue} from "vue-property-decorator";
import Popover from "@f/components/Popover.vue";
import Treecon from '@f/components/Treecons/Treecon.vue'
import {DataEventRansomStart} from '@f/services/TagManager/events/ransom'
import {TreecodeGiftDeliveryType} from "@f/services/Treecodes/types";
import {GiftType} from "@f/validation/types/dataLayer";
import experimentService, {ActiveExperiments, TypedAmplitudeExperiment} from '@f/services/experimentService'

@Component({
  name: "TreecodeWidget",
  components: {
    Popover,
    Treecon,
  },
})
export default class TreecodeWidget extends Vue {

  didYouReceiveAGiftExperiment: TypedAmplitudeExperiment<ActiveExperiments.LabelDidYouReceiveAGift> | undefined | null = null;

  params = new URLSearchParams(window.location.search)
  giftType = GiftType.fromTreecodeGiftDeliveryType(this.params.get("intcmp") as TreecodeGiftDeliveryType);

  async created() {
    this.didYouReceiveAGiftExperiment = await experimentService
      .getExperimentV2(this.$rootScope.userdata.info || { id: undefined }, ActiveExperiments.LabelDidYouReceiveAGift);
  }

  dispatchRansomStartEvent = (treecode: string) => {
    DataEventRansomStart({
      logged: this.$rootScope.logged,
      ransomCode: treecode,
      position: 'treecode-widget',
      giftType: this.giftType
    })
  }

  handleTreecodeClick() {
    this.$state.go(`treecodeLanding`)
  }


}
