import {Vue, Component, Prop} from "vue-property-decorator";
@Component
export default class PageMixin extends Vue {
  @Prop({ type: String }) readonly title!: string;
  @Prop({ type: String }) readonly subtitle!: string;
  @Prop({ type: Boolean, default: true }) readonly localized!: boolean;

  // Computed
  get pageTitle() {
    return this.localized ? this.$t(this.title) : this.title
  }

  get pageSubtitle() {
    return this.localized ? this.$t(this.subtitle) : this.subtitle
  }
}
