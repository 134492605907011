angular.module('ngApp')
.controller('badgesController', ['$scope', '$rootScope', 'Badge', '$stateParams', '$timeout', function ($scope, $rootScope, Badge, $stateParams) {
  var ctrl = this
  $scope.totalUserBadges
  ctrl.badgeCharged = false

  $scope.calculateBadgeToShow = function () {
    if (!$rootScope.badgesAvailable) return
    $scope.badges = $rootScope.badgesAvailable.slice()
    Badge.getUserBadges($rootScope.showUser.info.id)
    .then(function (data) {
      $scope.badges.map(function (e, index) {
        var myBadge = data.findIndex(badge => badge.slug === e.slug)
        if (myBadge  > -1) {
          $scope.badges[index].active = true
          $scope.badges[index].createdAt = data[myBadge].createdAt
        } else {
          $scope.badges[index].active = false
        }
      })
      $scope.totalUserBadges = $scope.badges.filter(badge => badge.active === true).length
      ctrl.badgeCharged = true
      $rootScope.$broadcast('UserBadgeCharged')
    })
  }
  $scope.calculateBadgeToShow()

  $rootScope.$on('BadgeAssigned', function (event, user) {
    if ($stateParams.slug === user) $scope.calculateBadgeToShow()
  })
  $rootScope.$on('BadgeCharged', function (event, user) {
    $scope.calculateBadgeToShow()
  })
}])
