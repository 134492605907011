








































































































































































































































































import Vue from 'vue'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {UserInfo} from '@f/@types'
import Modal from '@f/components/Modal.vue'
import ShowLanguages from '@f/mixins/ShowLanguages'
import ShowMenu from '@f/mixins/ShowMenu'
import Accordion from '@f/components/Accordion.vue'
import {navbar as navbarMenu} from '@f/configs/menus'
import Icon from '@f/components/Icon.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import {getBlogUrl} from '@f/services/Blog'
import {DataEventRansomStart} from '@f/services/TagManager/events/ransom'
import {getRootScope} from '@f/services/Angular'
import {ShopCurrency} from '@f/configs/app'
import {changeCurrency} from '@f/services/NewEcommerce/CartService'
import CustomPlantATreeSection from '@f/components/Navbar/partials/CustomPlantATreeSection.vue'
import CustomBusinessSection from "@f/components/Navbar/partials/CustomBusinessSection.vue";
import Overlay from "@f/components/Overlay.vue";

type MenuName = 'plant' |
    'business' |
      'business-plant' |
      'business-services' | 'business-services-reduction' | 'business-services-involvement' | 'business-services-reporting' | 'business-services-compensation' |
    'who' |  'languages' | 'currency' | ''
@Component({
  name: 'MobileSidebar',
  components: {
    Overlay,
    CustomBusinessSection,
    CustomPlantATreeSection,
    Accordion,
    Modal,
    Icon,
    Treecon,
  },
})
export default class MobileSidebar extends Mixins(ShowLanguages, ShowMenu) {
  /*--- MODEL ---------*/

  /*--- PROPS ---------*/
  @Prop({ type: Object }) readonly userInfo?: UserInfo

  /*--- DATA ----------*/
  modalService: any = null

  menuItems = navbarMenu
  /*--- COMPUTED ------*/
  // FIXME: deduplicate UserMixin property
  get userTypeState(): 'organization' | 'user' {
    return this.userInfo?.usertype === 'Business' ? 'organization' : 'user'
  }

  // FIXME: deduplicate UserMixin property
  get userSlug(): string {
    return this.userInfo?.slug || ''
  }

  // FIXME: deduplicate UserMixin property
  get profileUrl(): string {
    return this.userInfo
      ? this.$state.href(this.userTypeState, { slug: this.userSlug })
      : '#'
  }

  // FIXME: deduplicate UserMixin property
  get editProfileUrl(): string {
    return this.$state.href('settings')
  }

  get blogUrl(): string {
    return getBlogUrl(this.$rootScope.ln)
  }

  get shopCurrencies() {
    return ShopCurrency
  }

  get currencyIcons() {
    return {
      [this.shopCurrencies.Eur]: 'euro-line',
      [this.shopCurrencies.Usd]: 'usd-line',
      [this.shopCurrencies.Gbp]: 'gbp-line',
    }
  }

  get activeCurrency() {
    return getRootScope()?.currency
  }


  /*--- WATCHERS ------*/

  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  mounted(): void {
    this.modalService = this.ngService('Modal')
  }

  // FIXME: deduplicate UserMixin method
  logout() {
    this.$rootScope.$broadcast('UserLoggedBroadcast', false)
    this.$rootScope.logout()
  }


  goToBlog () {
    this.$state.go('blog')
  }

  reportLink() {
    if(this.$rootScope.ln === 'es' || this.$rootScope.ln === 'fr') {
      return `https://static.treedom.net/reports/01_Impact_Report_2022_EN_Ver1.pdf`
    }
    return `https://static.treedom.net/reports/01_Impact_Report_2022_${this.$rootScope.ln.toUpperCase()}_Ver1.pdf`
  }


  dispatchRansomStartEvent = (treecode: string) => {
    DataEventRansomStart({
      logged: this.$rootScope.logged!,
      ransomCode: treecode,
      position: 'treecode-sidebar',
      giftType: 'link',
    })
  }
  secondLevelMenu: MenuName = ''

  handleFirstLevelMenuClick(secondLevelMenu: MenuName) {
    this.secondLevelMenu = secondLevelMenu
  }

  handleBackButtonClick(backMenu?: MenuName )  {
    this.secondLevelMenu = backMenu || ''
  }

  changeCurrency = async (currency: ShopCurrency) => {
    return changeCurrency(currency)
  }

  handleTreecodeClick() {
    this.$state.go('treecodeLanding')
  }
}
