angular.module('alertService', [])
.service('Alert', [function () {
  var addAlert = function (message) {
    var idAlert = Date.now()

    $('#growlContainer').append('<div id="' + idAlert + '" class="myGrowl">' + message + '</div>')

    $('#' + idAlert).fadeIn('slow', function () {
      $(this).delay(3000).queue(function () {
        $(this).addClass('animated bounceOutRight').clearQueue()
        $(this).delay(1000).queue(function () {
          $(this).remove()
        })
      })
    })
  }

  return { addAlert: addAlert }
}])
