<template>
  <div>
    <h4 class="ntds-heading--4">{{ title }}</h4>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ImpactModal',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
@import '@f/scss/design-system/_variables';

.ntds-heading--4 {
  font-weight: $ntds-weight-bold;
}
.ntds-heading--4, p::v-deep {
    color: $ntds-font-100;
}
</style>