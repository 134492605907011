angular.module('angularTip', [])
.directive('angularTip', ['$compile', '$window', '$rootScope', '$state', '$timeout', function ($compile, $window, $rootScope, $state, $timeout) {
  return {
    restrict: 'AE', // come puo venire definita es E:element <angular-tip>/  A:attribute <div angular-tip> / C:classe etc...
    replace: false, // se viene rimpiazzato con il template sotto
    link: function (scope, elem, attrs) {
      var options = {
        trigger: attrs.trigger || 'over',
        position: attrs.position || 'right',
        align: attrs.align || 'center',
        isOpen: false
      }

      var app = angular.element(document.querySelector('#ngApp'))
      var newTip = angular.element('<div class="treetip" style="text-align:' + options.align + '">' + attrs.text + '</div>')
      var triangle = angular.element('<div class="triangletip"></div>')

      var positioned = false
      var positionElem = {}
      var top = false
      var left = false

      var position = function () {
        // Aggiorno newTip
        newTip = angular.element('<div class="treetip" style="text-align:' + options.align + '">' + attrs.text + '</div>')
        newTip.prepend(triangle)
        app.append($compile(newTip)(scope))

        var offset = 8
        var trianDimens = 8

        positionElem = elem.offset()
        // poizionamento del div cliccato
        top = positionElem.top - $(window).scrollTop()
        left = positionElem.left
        // dimensione del divcliccato
        var width = elem.outerWidth()
        var height = elem.outerHeight()

        if (options.position === 'right') {
          var positionLeft = left + width + offset
          var positionTop = top + (height / 2) - (newTip.outerHeight() / 2)
          newTip.css({'top': positionTop, 'left': positionLeft})
          //triangle
          // newTip.before(triangle)
          // posiziono il triangolo: 10 è la altezza del triangolo
          var positionTopTriangle = top + (height / 2) - trianDimens
          var positionLeftTriangle = positionLeft - trianDimens
          triangle.css({'top': Math.ceil(positionTopTriangle), 'left': Math.ceil(positionLeftTriangle)})

        } else if (options.position === 'top') {
          var positionLeft = left - newTip.outerWidth() / 2 + width / 2
          var positionTop = top - (newTip.outerHeight() + offset)
          newTip.css({'top': Math.ceil(positionTop), 'left': Math.ceil(positionLeft)})
          //  posiziono il triangolo: 5 è la meta della larghezza del triangolo
          var positionTopTriangle = top - (trianDimens / 2) - offset
          var positionLeftTriangle = left + (width / 2) - (trianDimens / 2)

          triangle.addClass('rotate270')
          triangle.css({'top': Math.ceil(positionTopTriangle), 'left': Math.ceil(positionLeftTriangle)})
        }

        if (options.position === 'left') {
          var positionLeft = left - newTip.outerWidth() - offset
          var positionTop = top + (height / 2) - (newTip.outerHeight() / 2)
          newTip.css({'top': Math.ceil(positionTop), 'left': Math.ceil(positionLeft)})

          // posiziono il triangolo: 10 è la altezza del triangolo
          var positionTopTriangle = top + (height / 2) - trianDimens
          var positionLeftTriangle = left - offset
          triangle.addClass('rotate180')
          triangle.css({'top': Math.ceil(positionTopTriangle), 'left': Math.ceil(positionLeftTriangle)})
        }

        if (options.position === 'bottom') {
          var positionLeft = left - newTip.outerWidth() / 2 + width / 2
          var positionTop = top + offset + height + (trianDimens / 2)
          newTip.css({'top': positionTop, 'left': positionLeft})

          // posiziono il triangolo: 10 è la meta della larghezza del triangolo
          var positionTopTriangle = top + height + offset - trianDimens
          var positionLeftTriangle = left + (width / 2) - (trianDimens / 2)
          triangle.addClass('rotate90')
          triangle.css({'top': Math.ceil(positionTopTriangle), 'left': Math.ceil(positionLeftTriangle)})
        }
        positioned = true
      }

      var hide = function () {
        newTip.remove()
        triangle.hide()
        newTip.hide()
        options.isOpen = false

        var queryResult = document.querySelector('.treetip')
        var wrappedQueryResult = angular.element(queryResult)
        angular.forEach(wrappedQueryResult, function (value) {
          value.remove()
        })
      }

      var compile = function () {
        position()
        triangle.show()
        newTip.show()
        options.isOpen = true
      }

      elem.bind('mouseover', function () {
        if (options.trigger !== 'over') return
        compile()
      })

      elem.bind('mouseleave', function () {
        if (options.trigger !== 'over') return
        hide()
      })

      elem.bind('click', function () {
        if (options.trigger !== 'click') return

        if (!options.isOpen) {
          compile()
          newTip.bind('click', function () {
            hide()
          })
        } else {
          hide()
        }
      })

      var bindingClick = function (event) {
        if (elem.has(event.target).length === 0 && elem.context !== event.target && $rootScope.hardware !== 'mobile' && options.isOpen) {
          return hide()
        } else if (positionElem.left !== elem.offset().left) {
          return hide()
        }
      }

      var bindingScroll = function () {
        hide()
      }

      angular.element('*').bind('click', bindingClick)
      angular.element($window).bind('scroll', bindingScroll)
      angular.element('*').bind('scroll', bindingScroll)

      elem.on('$destroy', function () {
        angular.element('*').bind('click', bindingClick)
        angular.element($window).unbind('scroll', bindingScroll)
        angular.element($window).unbind('scroll', bindingScroll)
        elements = document.getElementsByClassName('treetip')
        angular.element(elements).remove()
        elements = document.getElementsByClassName('triangletip')
        angular.element(elements).remove()
      })

      $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        hide()
      })
    }
  }
}])
