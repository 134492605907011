import { IDLTreeDiaryInteractionContent } from '@f/validation/types/dataLayer';
import tagManagerService from '@f/services/TagManager';
import { validateTreeNameConfirm, validateTreeDiaryInteraction } from '@f/validation/datalayer';


export function DataEventTreeName(nameConfirmed: string) {
    tagManagerService.push({
        event: 'confirmName',
        nameConfirmed
    }, validateTreeNameConfirm)
}

export function DataEventTreeDiaryInteraction(content: IDLTreeDiaryInteractionContent, action: string | null, element: string | null) {
    tagManagerService.push({
        event: 'diary',
        content,
        action,
        element
    }, validateTreeDiaryInteraction)
}