import isString from 'lodash/isString'

angular.module('ngApp')
  .controller('productPowersBarsController', ['$scope', '$rootScope', 'Utility', '$filter', 'Modal', function ($scope, $rootScope, Utility, $filter, Modal) {

    $scope.width = $scope.attrs.width;
    $scope.duration = $scope.attrs.duration || 1000;
    $scope.background = $scope.attrs.background || '#ffffff';
    $scope.max = $scope.attrs.max || 10;
    $scope.radius = $scope.width / 2 + 'px';

    $scope.barWrapperStyle = {
      borderRadius: $scope.radius,
      backgroundColor: $scope.background,
      padding: '0 ' + $scope.radius,
      width: 'calc(100% - ' + $scope.width + 'px)'
    };

    $scope.$watch('attrs.powers', function() {
      $scope.powers = isString($scope.attrs.powers)
        && $scope.attrs.powers.indexOf('{') >= 0
        ? Utility.hydratePowers(JSON.parse($scope.attrs.powers))
        : Utility.getEmptyPowers()
    })

    // Modale powers details
    $scope.openModalPowers = function () {
      Modal.open({templateUrl: 'modalPowers.html'})
    };

  }]);
