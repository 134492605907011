angular.module('ngApp')
.controller('voucherFormController', ['$scope', '$rootScope', '$state', 'Cart', 'Utility', 'Modal', function ($scope, $rootScope, $state, Cart, Utility, Modal) {
  $scope.voucherCode = ''
  $scope.voucherFormError = false

  $scope.addVoucher = function () {
    $scope.voucherFormError = false

    Utility.disableButton()
    Cart.assignVoucher($scope.voucherCode)
    .then(function (data) {
        if (!data.error) {
            Modal.close()
            //$rootScope.userdata.cart = data
        } else {
            $scope.voucherFormError = data.error
        }

        Utility.enableButton()
    })
    .catch(function (err) {
        if (!(err && err.data && err.data.message)) return $scope.voucherFormError = 'generic'
        switch (err.data.message) {
            case 'Voucher can be used one time once':
                $scope.voucherFormError = 'singleUsage'
                break

            case 'Voucher is not enabled':
            case 'Voucher not available':
            case 'Voucher expired':
            case 'VoucherNotFound':
                $scope.voucherFormError = 'notAvailable'
                break

            case 'Cart already contain a voucher':
                $scope.voucherFormError = 'alreadyHaveVoucher'
                break

            case 'IsARedeemCode':
                $scope.voucherFormError = 'isRedeemCode'
                $scope.redeemUrl = $state.href('gift', { id: $scope.voucherCode }, {absolute: true})
                break

            default:
                $scope.voucherFormError = 'generic'
                break
        }

        Utility.enableButton()
    })
  }
}])
