import axios from 'axios'

async function fetchDataAtUrl<T>(url: string) {
  return !!url.trim()
    ? axios.get<T>(url).then(({ data }) => data)
    : Promise.resolve()
}

// TODO: recursive breaks on catch. Evaluate possibility of handling fallback on empty data
async function recursiveFetchData<T>(urls?: string[]) {
  while (urls?.length) {
    try {
      const url = urls.splice(0, 1)[0].trim()
      if (url) {
        const data = await fetchDataAtUrl<T>(url)
        urls = undefined
        return data
      }
    } catch {}
  }
}

export function fetchStaticData<T = any>(urls: string | string[]) {
  return typeof urls === 'string'
    ? fetchDataAtUrl<T>(urls)
    : recursiveFetchData<T>([...urls])
}
