import { SubscriptionFromPosition } from '@f/validation/types/dataLayer';
import { Product, ProductPlan, UserCart } from '@f/@types';
import { ecommerceOptions } from '@f/configs/app';
import persistedStateService from '../persistedStateService';
export interface ProductPartialDetail {
    isGift?: boolean,
    quantity?: number,
    position?: number
  }

export const cartPartialInfo = (cart?: UserCart) => {
    return cart ? { currencyCode: cart.currency, cartId: cart.id } : { currencyCode: 'EUR' }
}
  
export const cartPartialOrderManagement = (cart: UserCart) => {
    return cart ? { 
      cartId: cart.id, 
      cartValue: cart.total / 100, 
      cartSize: cart.itemsQtCount, 
      voucherId: cart.voucher?.id || null,
      coupon: cart.voucher?.code || null,
    } : {}
}
  
export const productPartial = (product: Product | ProductPlan, detail: ProductPartialDetail) => {
    const price = product.price / 100;
    const tax = Math.round((price / 100) * ecommerceOptions.vatPercentage)
  
    return {
      id: product.id,
      name: product.name,
      price: price,
      tax,
      discount: product.badgeDiscount || 0,
      brand: ecommerceOptions.brand,
      category: product.type,
      position: detail.position,
      dimension1: detail.isGift,
      quantity: detail.quantity || 1
    }
}

export const paymentInfoPartial = (cart: UserCart, paymentMethod: string) => {
  return {
    cartValue: cart.total / 100,
    cartId: cart.id,
    cartSize:cart.itemsQtCount,
    paymentMethod
  }
}

export const paymentInfoSubPartial = (plan: ProductPlan, paymentMethod: string) => {
  return {
    name: plan.name,
    paymentMethod
  }
}

export const isUserRegisteredToday = (createdAt: string) => {
  return (new Date(createdAt)).toDateString() === (new Date()).toDateString();
}

export const getKeyObjectDiff = (obj1: Record<string,any>, obj2: Record<string,any>): string[] => {
  let fieldEdited = [];
  for (const key of Object.keys(obj2)) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !getKeyObjectDiff(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      fieldEdited.push(key)
    }
  }
  return fieldEdited;
}

function isObject(object: Record<string, any>) {
  return object != null && typeof object === 'object';
}

export const getPositionFromSubscriptions = () => {
  const store = persistedStateService<SubscriptionFromPosition>('subscriptionEventsFromPosition', localStorage)
  return store.get() || ''
}

export const clearPositionFromSubscriptions = () => {
  const store = persistedStateService<SubscriptionFromPosition>('subscriptionEventsFromPosition', localStorage)
  return store.remove()
}