




























import Game from "@f/components/Game/Game.vue";
import GameAuthentication from "@f/components/Game/Pages/GameAuthentication/GameAuthentication.vue";
import LanguageSelector from "@f/components/Navbar/partials/LanguageSelector.vue";
import useUserInfo from '@f/composables/useUserInfo';
import Logo from "@f/images/logo-dynamic.svg";
import { getStateService } from "@f/services/Angular";
import { computed, defineComponent, onMounted } from "@vue/composition-api";

export default defineComponent({
  name: "GamePage",
  components: {
    Game,
    Logo,
    LanguageSelector,
    GameAuthentication,
  },
  props: {
    treecode: String,
  },
  setup(props) {
    
    const { userInfo } = useUserInfo()
    const authenticated = computed(() => !!userInfo.value)
    
    const stateService = getStateService();
    
    onMounted(() => {      
      if (!props.treecode) {
        stateService && stateService.go("index");
      }
    })
    return {
      authenticated
    };
  },
});
