import { TrTranslation } from '@f/@types/graphql'
import { IsoCode } from '@f/@types/localization'
import { StateOrName, StateParams } from '@uirouter/angularjs'

export interface GrowthTest {
  id: string
  type: GrowthTestType
  filters?: GrowthTestFilter | GrowthTestFilter[]
  content: TrTranslation
}

export type GrowthTestType = 'custom_message' | 'product_extras' // TODO: add types as they'll be asked for
export type GrowthTestRoutesMap = Record<string, Record<string, any>>

export interface GrowthTestFilter {
  routes?: string | string[] | GrowthTestRoutesMap
  languages?: IsoCode | IsoCode[]
  activeRange?: {
    from: string
    to: string
  }
}

export type GrowthTestValidators = ReturnType<typeof useValidators>

type GrowthTestRoute = string | { name: string, params: StateParams }

function validateFilter(validators: GrowthTestValidators, filter: GrowthTestFilter) {
  return (!filter.routes || validators.routes(filter.routes))
    && (!filter.languages || validators.languages(filter.languages))
    && (!filter.activeRange || validators.activeRange(filter.activeRange))
}

function matchesValidators(test: GrowthTest, validators: GrowthTestValidators) {
  if (!test.filters) return true
  return Array.isArray(test.filters)
    ? test.filters.some(validateFilter.bind(undefined, validators))
    : validateFilter(validators, test.filters)
}

function testStateRoute(
  stateName: string,
  routeName: string,
  stateParams: Partial<StateParams> = {},
  routeParams: Partial<StateParams> = {}
) {
  return stateName === routeName
    && !Object.keys(routeParams).some(paramKey => {
        return !(paramKey in stateParams)
          || stateParams[paramKey].toString() !== routeParams[paramKey].toString()
      })
}

function stateMatchesRoutes(state?: StateOrName, routes?: string | string[] | GrowthTestRoutesMap) {
  if (!state || !routes) return true
  const stateName = typeof state === 'string' ? state : state?.name || ''
  if (typeof routes === 'string') return stateName === routes
  if (Array.isArray(routes)) return routes.includes(stateName)
  const stateParams = typeof state !== 'string' && state.params || {}
  return Object.keys(routes).some(routeName => {
    return testStateRoute(stateName, routeName, stateParams, routes[routeName])
  })
}

function localeMatchesLanguages(locale?: IsoCode, languages?: IsoCode | IsoCode[]) {
  return !locale
    || !languages?.length
    || (Array.isArray(languages)
        ? languages.includes(locale)
        : languages === locale)
}

function testActivePeriod(range: Record<"from" | "to", string>) {
  if (!(range.from && range.to)) return true
  const current = new Date().getTime()
  const from = new Date(range.from).getTime()
  const to = new Date(range.to).getTime()
  return current >= from && current <= to
}

// TODO: rewrite validation login using ajv
export function useValidators(state?: GrowthTestRoute, locale?: IsoCode) {
  return {
    routes: stateMatchesRoutes.bind(undefined, state),
    languages: localeMatchesLanguages.bind(undefined, locale),
    // TODO: dedup BannerMakerSlider checkDates method
    activeRange: testActivePeriod,
  }
}

export function activeTests(tests: GrowthTest[], validators: GrowthTestValidators, type?: string) {
  return tests.filter(test => (!type || test.type === type) && matchesValidators(test, validators))
}
