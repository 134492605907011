


































































































import { defineComponent, computed } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import HUDPage from '@f/components/Map/HUD/HUDPage.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import { TREE_CARD } from '@f/graphql/queries'
import useRoutes from '@f/composables/useRoutes'
import { translate } from '@f/services/Translation'
import ForestAvatarDefault from '@f/images/avatars/event_avatar_default.png'
import { TrTree, TrTreeCardQuery, TrTreeCardQueryVariables } from '@f/@types/graphql'
import { ITranslation } from '@f/@types/localization'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import { formatDegrees } from '@f/utils'
import TextTruncate from '@f/components/TextTruncate.vue'
import { getVisibleCoordinates } from '@f/services/Tree'

interface TreeInfoItem {
  image: string
  text: string
}

// @ts-ignore
export default defineComponent({
  name: 'HUDTreeCard',
  components: {
    TextTruncate,
    HUDPage,
    DotLoader,
    Treecon,
  },
  props: {
    treeId: [Number, String]
  },
  setup(props) {
    const isMobile = useMediaQuery(maxWidth('md'))
    const { parseProfileUrl, parseForestUrl, parseTreeUrl, href } = useRoutes()

    const { result, loading } = useQuery<TrTreeCardQuery, TrTreeCardQueryVariables>(
      TREE_CARD,
      () => {
        return {
          id: String(props.treeId)
        }
      },
      () => {
        return {
          enabled: !!props.treeId
        } as any
      }
    )

    const tree = computed<TrTree>(() => result.value?.tree as TrTree)
    const treeName = computed(() => tree.value?.name || translate(tree.value!.specie!.name as ITranslation))
    const treeUrl = computed(() => tree.value && parseTreeUrl(tree.value))
    const treeStatus = computed(() => tree.value!.status)
    const isInNursery = computed(() => treeStatus.value! <= 2)
    const showPhoto = computed(() => treeStatus.value === 4)
    const photo = computed(() => tree.value?.picture?.small || '')
    const icon = computed(() => tree.value?.specie?.icon?.small || '')
    const scientificName = computed(() => tree.value?.specie?.scientificName?.name || '')
    const countryFlag = computed(() => tree.value?.specie?.country?.flagImage || '')
    const countryName = computed(() => tree.value?.specie?.country?.name || '')
    const countrySlug = computed(() => tree.value?.specie?.country?.slug || '')
    const forestSlug = computed(() => tree.value?.event?.slug || '')
    const forestName = computed(() => tree.value?.event?.name || '')
    const hasBeenGifted = computed(() => tree.value?.user?.id !== tree.value?.initialUser?.id)
    const userInfo = computed(() => {
      return hasBeenGifted.value && tree.value?.user
        ? {
          image: tree.value?.user?.avatar?.small || '',
          text: translate(
            'tree.infoCard.user',
            {
              url: parseProfileUrl(tree.value!.user!),
              name: tree.value?.user?.title
            }
          )
        }
        : null
    })
    const initialUserInfo = computed(() => {
      const initialUser = tree.value?.initialUser
      if (initialUser) {
        return {
          image: initialUser.avatar?.small || '',
          text: translate(
            'tree.infoCard.initialUser',
            { url: parseProfileUrl(initialUser), name: initialUser.title }
          )
        }
      }
      return null
    })
    const forestInfo = computed(() => {
      const event = tree.value?.event
      if (event) {
        return {
          image: event.avatar?.small || ForestAvatarDefault,
          text: translate(
            'tree.infoCard.forest',
            {
              url: parseForestUrl(event),
              name: event.name
            }
          )
        }
      }
      return null
    })
    const coupledInfo = computed(() => {
      const coupled = tree.value?.coupled
      if (coupled) {
        return {
          image: coupled.limited?.icon?.small
            || coupled.specie?.icon?.small
            || '',
          text: translate(
            'tree.infoCard.coupled',
            {
              url: parseTreeUrl(coupled),
              name: coupled.name
                || (coupled.specie?.name && translate(coupled.specie.name))
                || ''
            }
          )
        }
      }
      return null
    })
    const relations = computed(() => {
      return [
        userInfo.value,
        initialUserInfo.value,
        forestInfo.value,
        coupledInfo.value
      ].filter((i): i is TreeInfoItem => !!i)
    })

    const coordinates = computed(() => {
      if (!tree.value) return null
      return getVisibleCoordinates(tree.value)
    })

    return {
      isMobile,
      countryFlag,
      countryName,
      countrySlug,
      icon,
      loading,
      photo,
      relations,
      scientificName,
      tree,
      treeName,
      treeUrl,
      treeStatus,
      isInNursery,
      showPhoto,
      href,
      formatDegrees,
      parseTreeUrl,
      forestSlug,
      forestName,
      coordinates,
    }
  }
})
