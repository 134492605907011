
import tagManagerService from '@f/services/TagManager';
import { validateStartGame, validateGameQuestion1, validateGameQuestion2, validateGameQuestion3, validateGameQuestion4, validateGameQuestion5, validateGameCopiedCode, validateGameSentGift, validateGameCtaUnderstand } from '@f/validation/datalayer';

export function DataEventGameStart() {
    tagManagerService.push({
        event: 'startGame',
    }, validateStartGame)
}

export function DataEventGameAnswer(questionNr: number) {
    const event = {
        event: `gameQuestion${questionNr}`,
    }
    switch(questionNr){
        case 1:
            tagManagerService.push(event, validateGameQuestion1)
            break;
        case 2:
            tagManagerService.push(event, validateGameQuestion2)
            break;
        case 3:
            tagManagerService.push(event, validateGameQuestion3)
            break;
        case 4:
            tagManagerService.push(event, validateGameQuestion4)
            break;
        case 5:
            tagManagerService.push(event, validateGameQuestion5)
            break;
    }
}
export function DataEventGameCopiedCode(){
    tagManagerService.push({
        event: 'gameCopiedCode',
    }, validateGameCopiedCode)
}

export function DataEventGameSentGift(){
    tagManagerService.push({
        event: 'gameSentGift',
    }, validateGameSentGift)
}

export function DataEventGameCtaUnderstand(){
    tagManagerService.push({
        event: 'gameCtaUnderstand',
    }, validateGameCtaUnderstand)
}
