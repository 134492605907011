






import { defineComponent, provide, readonly } from '@vue/composition-api'
import useRoutes from '@f/composables/useRoutes'

// @ts-ignore
export default defineComponent({
  name: 'HUDNavigator',
  components: {},
  props: {
    rootState: {
      type: String
    }
  },
  setup(props) {
    const { currentState } = useRoutes()
    provide('rootState', props.rootState)
    provide('currentState', readonly(currentState))
    
    return {}
  }
})
