import { render, staticRenderFns } from "./FollowTheForest.vue?vue&type=template&id=6cb4dae1&scoped=true&"
import script from "./FollowTheForest.vue?vue&type=script&lang=ts&"
export * from "./FollowTheForest.vue?vue&type=script&lang=ts&"
import style0 from "./FollowTheForest.vue?vue&type=style&index=0&id=6cb4dae1&scoped=true&lang=scss&"
import style1 from "./FollowTheForest.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb4dae1",
  null
  
)

export default component.exports