<template>
  <div class="forest-card">
      <h4 v-if="title" class="ntds-heading--4">
        <Treecon v-if="icon" :icon="icon" />
        {{ title }}
      </h4>
      <p v-if="content" v-html="content"></p>
      <slot />
  </div>
</template>

<script>
import Treecon from '@f/components/Treecons/Treecon.vue'

export default {
    name: 'ForestCard',
    components: {
      Treecon
    },
    props: {
      title: {
        type: String,
        default: null
      },
      content: {
        type: String,
        default: null
      },
      icon: {
        type: String,
        default: null
      }
    }
}
</script>

<style lang="scss" scoped>
@import '@f/scss/design-system/_variables';

.forest-card {
  padding: 30px 35px;
  background-color: white;
  border-radius: 10px;
  max-width: 100%;

  .ntds-heading--4 {
    font-weight: $ntds-weight-bold;
    margin-top: 0;
    font-size: 20px;
    color: $ntds-font-100;
    margin-bottom: 5px;
  }
}

@media (max-width: $ntds-width-small) {
  .forest-card {
    border-radius: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}

</style>
