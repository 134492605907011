










import { computed, defineComponent, PropType } from '@vue/composition-api'
import { getVisibleCoordinates, getVisibleImage } from '@f/services/Tree'
import { TrTree } from '@f/@types/graphql'
import TreeMarker from '@f/components/Map/Markers/TreeMarker.vue'

export default defineComponent({
  name: 'TrTreeClusterPin',
  components: {
    TreeMarker,
  },
  props: {
    tree: {
      type: Object as PropType<TrTree>,
      required: true,
    }
  },
  setup(props) {
    const tree = computed(() => props.tree)
    const coordinates = computed(() => {
      if (!tree.value) return null
      return getVisibleCoordinates(tree.value)
    })
    const lat = computed(() => coordinates.value?.[1])
    const lng = computed(() => coordinates.value?.[0])
    const icon = computed(() => {
      if (!tree.value) return null
      if (tree.value.status! < 4) return null
      return getVisibleImage(tree.value)?.small
    })
    return {
      lat,
      lng,
      icon,
    }
  }
})

