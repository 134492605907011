






















































































































































import { defineComponent, computed, ref } from '@vue/composition-api'
import {
  fetchForest,
  fetchForestNumbers,
  fetchForestUsers,
  saveForest,
  saveForestImage,
} from '@f/services/Forest'
import {
  EventUsersPage,
  ForestUser,
  Event,
  Forest,
  CreateForest,
} from '@f/@types'
import ForestHeader from '@f/components/Forest/ForestHeader.vue'
import UnpublishedBox from '@f/components/Forest/UnpublishedBox.vue'
import ForestCard from '@f/components/Forest/ForestCard.vue'
import ForestTreesBox from '@f/components/Forest/ForestTreesBox.vue'
import ForestUsersBox from '@f/components/Forest/ForestUsersBox.vue'
import { TrEventNumbers } from '@f/@types/graphql'
import { FOREST_TREES } from '@f/graphql/queries'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import MapPreview from '@f/components/Forest/MapPreview.vue'
import EmissionsSection from '@f/components/Forest/EmissionsSection.vue'
import ForestDescription from '@f/components/Forest/ForestDescription.vue'
import EditForest from '@f/components/Forest/EditForest.vue'
import useForestData from '@f/composables/useForestData'
import useUserInfo from '@f/composables/useUserInfo'
import Modal from '@f/components/Modal.vue'
import StatefullButton from '@f/components/StatefullButton.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import {
  DataEventEditForest,
  DataEventEditForestPersonalization,
  DataEventPublishedForest,
} from '@f/services/TagManager/events'
import ForestCountryBoxes from '@f/components/Forest/ForestCountryBoxes.vue'
import FollowTheForest from "@f/pages/Forest/FollowTheForest.vue";
import ForestQrCode from '@f/pages/Forest/ForestQrCode.vue'
import BirthdayForestEmptyState from '@f/components/Forest/BirthdayForestEmptyState.vue'
import { getRootScope } from '../services/Angular'

type ForestPageTabs = 'trees' | 'users' | 'impact'

export default defineComponent({
  name: 'ForestPage',
  components: {
    BirthdayForestEmptyState,
    ForestQrCode,
    FollowTheForest,
    ForestHeader,
    UnpublishedBox,
    ForestCard,
    ForestTreesBox,
    ForestUsersBox,
    EmissionsSection,
    MapPreview,
    ForestDescription,
    Modal,
    EditForest,
    StatefullButton,
    Treecon,
    ForestCountryBoxes,
  },
  setup(props) {
    const mobileQuery = maxWidth('md')
    const isMobile = useMediaQuery(mobileQuery)
    const { userInfo } = useUserInfo()
    const {
      forest,
      loading,
      eventNumbers,
      isForestPrivate,
      isForestPublished,
      ctaAction,
      ctaLabel,
      isCtaVisible,
      goToCta,
      isMine,
      isFollowable,
      coverImageUrl,
      avatarImageUrl,
    } = useForestData(userInfo, props.eventSlug)
    const isBirthdayTestForest = computed((): boolean => {
      if (getRootScope()?.ln !== 'it') {
        return false
      }
      if (!forest.value?.isRally) {
        return false
      }
      return !!(forest.value?.type === 'Compleanno')
    })

    const isUserPrivate = computed((): boolean => {
      return !!(userInfo.value?.usertype === 'Private')
    })

    const tabs: ForestPageTabs[] = ['trees']
    const selectedTab = ref<ForestPageTabs>('trees')

    return {
      isMobile,
      userInfo,
      forest,
      loading,
      eventNumbers,
      isMine,
      isFollowable,
      isForestPrivate,
      isUserPrivate,
      isForestPublished,
      ctaAction,
      ctaLabel,
      isCtaVisible,
      goToCta,
      coverImageUrl,
      avatarImageUrl,
      selectedTab,
      tabs,
      isBirthdayTestForest,
    }
  },
  props: {
    eventSlug: {
      type: String,
      required: true,
    },
  },
  data(): {
    forestUsers: ForestUser[]
    forestTrees: any[]
    forestUsersCount: number
    showEditModal: boolean
  } {
    return {
      forestUsers: [],
      forestTrees: [],
      forestUsersCount: 0,
      showEditModal: false,
    }
  },
  created() {
    this.$apollo.addSmartQuery('forestTrees', {
      query: FOREST_TREES,
      variables() {
        return { eventId: this.forest.id, limit: 5 }
      },
      skip() {
        return !this.forest.id
      },
      update: (data) => data.forestTrees.trees,
    })
  },
  mounted() {
    this.getForestData()
  },
  methods: {
    getForestData(): void {
      this.loading = true
      fetchForest(this.eventSlug, true)
        .then((data: Event) => {
          this.forest = data
          if (!this.forest.hide_people) {
            this.tabs.push('users')
          }
          if (!this.forest.hide_emissions) {
            this.tabs.push('impact')
          }
          if (!this.forest.published && !this.isMine) {
            this.$state.go('index')
          }

          fetchForestNumbers(this.eventSlug).then(
            (eventNumbers: TrEventNumbers) => {
              this.eventNumbers = eventNumbers
            }
          )

          fetchForestUsers(this.forest.id!, 0, 6).then(
            (data: EventUsersPage) => {
              this.forestUsers = data.users
              this.forestUsersCount = data.count
            }
          )
        })
        .catch(() => {
          this.$state.go('error404')
        })
        .finally(() => {
          this.loading = false
        })
    },
    goToTrees(countryId?: string) {
      let state = this.isForestPrivate
        ? 'user.event.trees'
        : 'organization.event.trees'
      if (countryId) {
        state += '.country'
        this.$state.go(state, { ...this.$state.params, countryId })
      } else {
        this.$state.go(state, this.$state.params)
      }
    },
    goToUsers() {
      const state = this.isForestPrivate
        ? 'user.event.users'
        : 'organization.event.users'
      this.$state.go(state, this.$state.params)
    },
    goToOwnerProfile() {
      const state = this.isForestPrivate ? 'user' : 'organization'
      this.$state.go(state, { slug: this.forest.user?.slug })
    },
    async saveForestData({
      editedForest,
      uploadedImages,
      publish = true,
    }: {
      editedForest: Forest
      uploadedImages: {
        avatar: null | File
        cover: null | File
      }
      publish: boolean
    }) {
      this.loading = true
      try {
        let avatarData
        let coverData
        if (uploadedImages.avatar && uploadedImages.avatar instanceof File) {
          avatarData = await saveForestImage(
            { id: editedForest.id!, slug: editedForest.slug! },
            uploadedImages.avatar,
            'avatar'
          )
        }
        if (uploadedImages.cover && uploadedImages.cover instanceof File) {
          coverData = await saveForestImage(
            { id: editedForest.id!, slug: editedForest.slug! },
            uploadedImages.cover,
            'background'
          )
        }
        await saveForest({
          ...(editedForest as CreateForest),
          published: publish,
          avatar: avatarData
            ? avatarData.data.picturePath
            : editedForest.avatar,
          background: coverData
            ? coverData.data.picturePath
            : editedForest.background,
        })

        if (!editedForest.published && publish) {
          DataEventEditForestPersonalization(editedForest.id!)
          DataEventPublishedForest(editedForest.id!)
        } else if (publish) {
          DataEventEditForest(editedForest.id!)
        } else {
          DataEventEditForestPersonalization(editedForest.id!)
        }
      } finally {
        this.showEditModal = false
        fetchForest(this.eventSlug, true)
          .then((data: Event) => {
            this.forest = data
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
})
