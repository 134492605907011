

















































































import { defineComponent, computed, provide, ref } from '@vue/composition-api'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apiClient } from '@f/apollo'
import useRoutes from '@f/composables/useRoutes'
import { fetchPrivateData } from '@f/composables/fetchUserData'
import { fetchPrivateMap, fetchEcommerceCountries } from '@f/composables/fetchMaps'
import fillPage from '@f/composables/fillPage'
import Treecon from '@f/components/Treecons/Treecon.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Map, { Data } from '@f/components/Map/Map.vue'
import HUDNavigator from '@f/components/Map/HUD/HUDNavigator.vue'
import HUDEcommerceCountryProducts from '@f/components/Map/HUD/HUDEcommerceCountryProducts.vue'
import HUDUserCountryTrees from '@f/components/Map/HUD/HUDUserCountryTrees.vue'
import HUDTreeCard from '@f/components/Map/HUD/HUDTreeCard.vue'
import HUDUserCountries from '@f/components/Map/HUD/HUDUserCountries.vue'
import HUDEcommerceCountries from '@f/components/Map/HUD/HUDEcommerceCountries.vue'
import { translate, getLanguage } from '@f/services/Translation'
import { isLoggedInAs } from '@f/services/Auth'
import { getImageAsset } from '@f/utils/media'
import { Maybe, TrCountry, TrUserCountrySummary } from '@f/@types/graphql'
import { FlyToOptions } from '@f/services/Map'
import { RestUserMapTree } from '@f/@types/rest'
import { Map as Mapbox } from 'mapbox-gl'
import TextTruncate from '@f/components/TextTruncate.vue'

const isOnlyMine = (tree: RestUserMapTree) => tree.type.includes('mine')

// @ts-ignore
export default defineComponent({
  name: 'UserMap',
  components: {
    TextTruncate,
    HUDUserCountries,
    HUDUserCountryTrees,
    DotLoader,
    Treecon,
    Map,
    HUDNavigator,
    HUDEcommerceCountries,
    HUDEcommerceCountryProducts,
    HUDTreeCard,
  },
  props: {
    slug: {
      type: String,
      required: true
    },
  },
  setup(props) {
    provide(DefaultApolloClient as any, apiClient)
    const mapRef = ref<Mapbox>()
    const { href, currentState, currentParams, isCurrentState, } = useRoutes()
    const { container } = fillPage()
    const isEcommerce = computed(() => !!currentState.value?.includes['user.map.plant'])
    const { user } = fetchPrivateData(props.slug)
    const userId = computed(() => user.value?.info?.id && Number(user.value!.info.id) || undefined)
    const title = computed(() => {
      if (isEcommerce.value) return translate('map.user.title_ecommerce')
      return !!user.value?.info && translate(
        'map.user.title',
        { name: user.value!.info.firstname },
        Number(user.value!.info && isLoggedInAs(user.value!.info))
      )
    })
    const userCountriesTitle =  computed(() => {
      if (!user.value?.info) return ''
      return translate(
        'map.user.countries.title',
        {
          name: user.value!.info.firstname,
        },
        Number(user.value!.info && isLoggedInAs(user.value!.info))
      )
    })
    const treesCountryTitle = computed(() => {
      if (!user.value?.info) return ''
      return translate(
        'map.user.country.title',
        {
          name: user.value!.info.firstname,
          country: activeCountry.value?.name?.[getLanguage()]
        },
        Number(user.value!.info && isLoggedInAs(user.value!.info))
      )
    })
    const userCountries = computed<Maybe<TrUserCountrySummary>[]>(() => user.value?.summary?.countries || [])

    const {
      loading: loadingUserCountries,
      trees: userTrees
    } = fetchPrivateMap(userId)

    const {
      countries: ecommerceCountries,
      loading: loadingEcommerceCountries
    } = fetchEcommerceCountries()

    const hasTrees = computed(() => (userTrees.value as RestUserMapTree[])
      ?.filter(isOnlyMine)?.length > 0)

    const treeData = computed(() => {
      if (!userTrees.value) return {
        type: 'FeatureCollection',
        features: []
      } as Data
      return {
        type: 'FeatureCollection',
        features: (userTrees.value as RestUserMapTree[])
          ?.filter(isOnlyMine)
          ?.map((tree) => ({
              type: 'Feature',
              id: `tree-${tree.id}`,
              geometry: {
                type: 'Point',
                coordinates: [tree.lng, tree.lat]
              },
              properties: {
                country: tree.country,
                type: 'tree',
                id: `tree-${tree.id}`,
                icon: tree.status > 3 ? getImageAsset(String(tree.treePicture || tree.specieIcon), 'small') : undefined,
                link: href('user.map.tree', { treeId: tree.id })
              }
            }))
      }
    })

    const ecommerceCountriesData = computed(() => {
      if (!ecommerceCountries.value) return {
        type: 'FeatureCollection',
        features: [],
      } as Data
      return {
        type: 'FeatureCollection',
        features: ecommerceCountries.value!.map((country) => ({
          type: 'Feature',
          id: country.id,
          properties: {
            type: 'country',
            id: country.id,
            icon: country.flagImage,
            link: href('user.map.plant.country', { countrySlug: country.slug }),
            variant: 'ecommerce',
          },
          geometry: {
            type: 'Point',
            coordinates: [country.lng, country.lat],
          },
        }))
      }
    })

    const mapData = computed(() => {
      if (isEcommerce.value) return ecommerceCountriesData.value
      return treeData.value
    })

    const treeId = computed(() => currentParams.value?.treeId)

    const activeCountry = computed<TrCountry | null>(() => {
      if (!currentParams.value?.countrySlug) return null;
      const countrySlug = currentParams.value!.countrySlug
      if (isEcommerce.value) {
        return ecommerceCountries.value?.find((country) => country.slug === countrySlug) as TrCountry
      }
      return userCountries.value?.find((summary) => summary?.country?.slug === countrySlug)?.country! as TrCountry
    })

    const hasCurrentCountryProducts = computed(() => {
      if (isEcommerce.value) {
        return false
      }
      if (!currentParams.value?.countrySlug) {
        return false
      }
      const countrySlug = currentParams.value!.countrySlug
      return ecommerceCountries.value?.some((country) => country.slug === countrySlug)
    })

    const zoomTarget = computed<FlyToOptions | null>(() => {
      const country = activeCountry.value
      if (country) {
        return {
          lat: country.lat!,
          lng: country.lng!,
          zoom: 6,
        }
      }
      if (treeId.value && userTrees.value) {
        const tree = userTrees.value!.find((tree) => String(tree.id) === String(treeId.value!))
        if (tree) {
          return {
            lat: tree.lat,
            lng: tree.lng,
            zoom: 14,
            animationType: 'fly',
            preserveZoom: true,
          }
        }
      }
      return null
    })

    const recenter = () => {
      const target = zoomTarget.value!
      mapRef.value?.flyTo({
        minZoom: target?.zoom,
        center: [target.lng, target.lat] as [number, number],
      })
    }

    return {
      mapRef,
      currentState,
      isCurrentState,
      href,
      activeCountry,
      container,
      ecommerceCountries,
      loadingUserCountries,
      loadingEcommerceCountries,
      title,
      treeId,
      userCountriesTitle,
      treesCountryTitle,
      user,
      userId,
      userCountries,
      zoomTarget,
      mapData,
      hasCurrentCountryProducts,
      hasTrees,
      recenter,
    }
  }
})
