import 'cross-fetch/polyfill'
import { ApolloClient, from } from '@apollo/client/core'
import { RestLink } from 'apollo-link-rest'

import cache from '../shared/cache';
import { api, appCookies } from '@f/configs/app';
import logErrorLink from '@f/apollo/links/logErrorLink'
import { createApolloAuthorizationLink } from '@f/auth/helpers'
import { setContext } from '@apollo/client/link/context'

const { cartUri: uri } = api;

const restLink = new RestLink({
  uri,
});

const cartHeadersLink = setContext(() => ({
  headers: {
    "X-User-tmp": appCookies.tempCart,
    "X-User-referral": appCookies.referral
  }
}))
const authorizationLink = createApolloAuthorizationLink()
const client = new ApolloClient({
  link: from([
    logErrorLink,
    cartHeadersLink,
    authorizationLink,
    restLink
  ]),
  cache,
})

export default client
