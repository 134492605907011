import { render, staticRenderFns } from "./EditForest.vue?vue&type=template&id=98e2259e&scoped=true&"
import script from "./EditForest.vue?vue&type=script&lang=ts&"
export * from "./EditForest.vue?vue&type=script&lang=ts&"
import style0 from "./EditForest.vue?vue&type=style&index=0&id=98e2259e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98e2259e",
  null
  
)

export default component.exports