import isUndefined from 'lodash/isUndefined'
import last from 'lodash/last'
import isEqual from 'lodash/isEqual'

angular.module('modalService', [])
.factory('Modal', ['$document', '$rootScope', '$timeout', '$compile', 'Utility', '$location', '$interval', '$filter', '$state', '$stateParams', function ($document, $rootScope, $timeout, $compile, Utility, $location, $interval, $filter, $state, $stateParams) {
  /**
   * Impostazioni modale
   * @param {Object} settings - Oggetto di impostazione modale.
   *
   * L'oggetto contiene le seguenti proprietà
   * @param {string} templateUrl - REQUIRED. Url del template HTML located in html-templates/. Esempio 'template.html'.
   * @param {boolean} fullpage - DEFAULT: false. Determina se la modale viene visualizzata a tutto schermo o in base al suo contenuto.
   * @param {boolean} fitcontent - DEFAULT: false. Determina se la larghezza della modale si adatta al suo contenuto altrimenti è small al 50% dello schermo.
   * @param {boolean} small - DEFAULT: true. Determina se la modale viene visualizzata a larghezza 50% dello schermo e adatta il contenuto altrimenti 80%.
   * @param {boolean} hideNavbar - DEFAULT: false. Se true, la modale copre anche la navbar.
   * @param {boolean} blockModal - DEFAULT: false. Se true, la modale NON può essere chiusa.
   * @param {boolean} closeOnContent - DEFAULT: false. Se true, cambia il bottone di chiusura.
   * @param {boolean} sponsoreBackgroundId - DEFAULT: false. Assegna id immagine riscatto sposnorizzato se presente nelle informazioni dell'utente.
   * @param {boolean} assignBackground - DEFAULT: false. Settata true nella modale per far comparire l'imagine di background.
   * @param {boolean} easyClose - DEFAULT: true. Se true, la modale si chiude anche  con il tasto ESC o cliccando sullo sfono.
   * @param {string} backgroundColor - DEFAULT: rgba(0,0,0,0.4). Colore di sfondo della modale. Esempio: '#FAFAFA'.
   * @param {number} interval - DEFAULT: 0. Se viene settato, la modale si chiude dopo x millisecondi.
   * @param {object} data - DEFAULT: {}. dati che possono essere passati alla modale.
   */

  var modalFactory = {};
  $rootScope.modalIntervalActive = false;
  $rootScope.closeModalAfterLogin = true;
  $rootScope.preventCloseToStatusChange = false;
  $rootScope.state = $state;

  // IDs delle modali aperte
  $rootScope.openedModals = [];

  /**
   * Apro la modale
   * @param {Object}
   */
  modalFactory.open = function (settings) {
    var tempId = settings.id || settings.templateUrl + new Date().setSeconds(0,0);

    if (modalFactory.isOpen(tempId)) return tempId;
    openModalAction(tempId);
    Utility.blockScroll({scrollToTop: settings?.scrollToTop ?? true});
    // Settings
    var fullpage = !!settings.fullpage || false;
    var fitcontent = !!settings.fitcontent || false;
    var small = isUndefined(settings.small) ? true : settings.small;
    var hideNavbar = !!settings.hideNavbar || false;
    var blockModal = !!settings.blockModal || false;
    var closeOnContent = !!settings.closeOnContent || false;
    var sponsoreBackgroundId = settings.sponsoreBackgroundId || false;
    var assignBackgroundClass = settings.assignBackgroundClass || '';
    $rootScope.modalData = !settings.data ? $rootScope.modalData || {} : settings.data;
    var easyClose = isUndefined(settings.easyClose) ? true : settings.easyClose;
    var interval = settings.interval * 1;
    var backgroundImage = settings.backgroundImage;
    var backgroundColor = settings.backgroundColor
      ? settings.backgroundColor === 'transparent'
        ? 'background-color: ' + settings.backgroundColor + '; box-shadow: none;'
        : 'background-color: ' + settings.backgroundColor + ';'
      : '';
    var backgroundUrl = (sponsoreBackgroundId) ? 'url(' + $filter('s3')(sponsoreBackgroundId, 'large') + ')' : false
    $rootScope.preventCloseToStatusChange = isUndefined(settings.preventCloseToStatusChange) ? false : settings.preventCloseToStatusChange;

    // Creo la modale
    var modalWrapperId = settings.modalWrapperId || 'modal-wrapper-id';
    var template = '../../bundles/treedomnewfrontend/html-templates/' + settings.templateUrl;
    var fullpageClassName = fullpage ? ' treedomModalContent-FullPage' : '';
    var fitcontentClassName = fitcontent ? ' treedomModalContent-FitContent' : '';
    var smallClassName = small ? ' treedomModalContent-Small' : 'treedomModalContent-Large';
    var hideNavbarClassName = hideNavbar ? ' treedomModalContent-hideNavbar' : '';
    var closeButton = blockModal ? '' : '<span ng-click="closeTreedomModal()" class="closeDarkMiddle">&times;</span>';
    var closeOnContentClass = closeOnContent ? 'closeModalButtonWrapOnContent' : '';
    var closeModalButton = blockModal ? '' : '<span ng-click="closeTreedomModal()" class="closeDarkMiddle">&times;</span>';
    var closeMobile = blockModal ? '<div class="closeMobile"></div>' : '<div class="closeMobile">' + closeButton + '</div>';
    var modalTemplate = angular.element('<div id="' + tempId + '"><div id="'+tempId + '_background" class="' + assignBackgroundClass + ' treedomModalBackground ' + hideNavbarClassName + '"><div class="closeModalDesktop ' + (backgroundImage ? 'closeModalDesktopSponsored' : '') + '">' + closeButton + '</div><div class="treedomModalTable"><div ' + (closeButton && easyClose ? ' ng-click="closeTreedomModal()"' : '') + ' class="treedomModalCell"> ' + closeMobile + '<div class="treedomModalContentWrapper ' + smallClassName + fitcontentClassName + '" id="' + modalWrapperId + '" ><div style="' + backgroundColor + '" class="treedomModalContent' + fullpageClassName + ' "><div class="closeModalButtonWrap ' + closeOnContentClass + '">' + closeModalButton + '</div><div class="treedomModalContentInside" ng-click="$event.stopPropagation()"><div style="height:100%;" ng-include="\'' + template + '\'"></div></div></div></div></div></div></div></div>');
    var modalComponent = $compile(modalTemplate)($rootScope)

    var body = $document.find('body').eq(0);
    body.append(modalComponent);
    if (!modalComponent.scope().$$phase) modalComponent.scope().$apply()

    if (backgroundUrl) {
      var modal = document.getElementById(tempId + '_background')
      if (modal) {
        modal.style.backgroundImage = backgroundUrl
        modal.classList.add('treedomModalBackgroundSponsored')
      }
    }

    // Possibilità di chiudere la modale al tasto ESC
    if (!blockModal) {
      if (easyClose) window.addEventListener('keydown', closeModalOnKeyPress, false)
    }

    // Animazione
    var modalElem = document.getElementById(tempId).querySelector('.treedomModalContentWrapper');
    modalElem.classList.add('treedomModalOpened');

    // Se ho specificato un intervallo, settalo
    if (interval) {
      $rootScope.modalIntervalActive = $interval(function () {
        modalFactory.close(settings)
      }, interval)
    }

    return tempId
  };

  /**
   * Chiudo la modale
   */
  modalFactory.close = function (modalId) {
    // ID della modale aperta che voglio chiudere
    var openedModalId = modalId || last($rootScope.openedModals); // TODO: remove lodash last

    // Se la modale non è aperta non eseguire nulla
    if (!modalFactory.isOpen(openedModalId)) return;

    // Rimuovi l'evento closeModalOnKeyPress
    window.removeEventListener('keydown', closeModalOnKeyPress, false);

    // Se è stato settato un intervallo, rimuovilo
    if ($rootScope.modalIntervalActive) {
      $interval.cancel($rootScope.modalIntervalActive);
      $rootScope.modalIntervalActive = false
    }


    var modalElem = document.getElementById(openedModalId).querySelector('#treedomModalContent');
    if (modalElem) modalElem.classList.add('treedomModalClosing');

    $timeout(function () {
      if (modalFactory.isOpen(openedModalId)) {

        closeModalAction(openedModalId, modalId)
        // Clear rootScope modal data
        $rootScope.modalData = null
        Utility.allowScroll({scrollToTop: modalId?.scrollToTop ?? true})
      }
    }, 450)
  };

  modalFactory.setData = function(modalData) {
    $rootScope.modalData = modalData
  }

  /**
   * Controllo se la modale è aperta
   * @return {boolean}
   */
  modalFactory.isOpen = function (modalId) {
    return !!document.getElementById(modalId)
  };

  /**
   * Close event broadcast key
   */
  modalFactory.closeEventKey = 'closedTreedomModal'

  /**
   * Funzione `globale` per chiudere la modale
   */
  $rootScope.closeTreedomModal = function (modalId) {
    modalFactory.close(modalId)
  };

  /**
   * Ascoltatore sul cambio di stato: se la modale è aperta e l'utente cambia
   * pagina chiudo la modale
   */
  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
    if($rootScope.preventCloseToStatusChange){
      return;
    }
    if (toState.name !== fromState.name) {
      if (!$rootScope.modalIntervalActive) {
        modalFactory.close()
      }
    } else {
      if (!isEqual(toParams, fromParams)) {
        if (!$rootScope.modalIntervalActive) modalFactory.close()
      }
    }
  });

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    // Se trovo nell'url il parametro www.example.com?login allora apro la
    // modale di Login
    if ($location.search().login) {
      if (!$rootScope.user && !modalFactory.isOpen()) {
        modalFactory.open({ templateUrl: 'login.html', easyClose: false, fitcontent: true })
      }
    }
  });

  /**
   * Chiude la modale quando viene premuto il tasto ESC (27).
   */
  function closeModalOnKeyPress (e) {
    e = e || window.event;
    if (e.keyCode === 27) {
      modalFactory.close()
    }
  }

  /**
   * Azione che rimuove la modale. Se ce ne sono altre, le mostra.
   * @param {string} modalId - ID della modale.
   */
  function closeModalAction (modalId, settings) {
    if (modalFactory.isOpen(modalId)) {
      var el = angular.element(document.getElementById(modalId));
      el.remove();

      // Casi particolari di chiusura
      if (RegExp(/modalShare/).test(modalId)) {
        let state = (RegExp(/event/).test($state.current.name)) ? 'user.event.trees' : 'user.trees'
        state += ($stateParams.id) ? '.item' : ''
        $state.go(state, $stateParams)
      }

      if (modalId && modalId.includes('subscribe')) {
        $rootScope.planToSubscribe = undefined;
        $rootScope.planToUnsubscribeId = undefined
      }

      $rootScope.openedModals = $rootScope.openedModals.filter(id => modalId !== id);

      $rootScope.$broadcast(modalFactory.closeEventKey, {
        closedModalId: modalId,
        openedModals: $rootScope.openedModals
      });

      if (!$rootScope.openedModals.length) {
        Utility.allowScroll({scrollToTop: settings?.scrollToTop ?? true});

        // Se non ci sono modali aperte e mi trovo nello stato 'gift' o 'coupon'
        // Rimando l'utente alla index
        if ($state.current.name === 'gift' || $state.current.name === 'coupon') {
          $state.go('index')
        }
      } else {
        $rootScope.openedModals.forEach(id => {
          if (last($rootScope.openedModals) === id) {
            document.getElementById(id).style.opacity = '1'
          }
        })
      }
    }
  }

  /**
   * Azione che apre la modale. Se ce ne sono altre le nasconde
   * @param {string} modalId - ID della modale.
   */
  function openModalAction (modalId) {
    $rootScope.openedModals.push(modalId);

    // Se ci sono altre modali aperti le oscuro!
    if ($rootScope.openedModals.length) {
      $rootScope.openedModals.forEach(id => {
        if (modalId !== id) {
          var el = document.getElementById(id);
          if (el) el.style.opacity = '0'
        }
      })
    }
  }

  return modalFactory
}]);
