import Swiper from 'swiper'
import isUndefined from 'lodash/isUndefined'
import shuffle from 'lodash/shuffle'
import uniqBy from 'lodash/uniqBy'
import isNumber from 'lodash/isNumber'
import Inputmask from 'inputmask'
import { DataEventToggleFollow } from '@f/services/TagManager/events';

angular.module('ngApp')
.controller('privateUserController', ['$scope', '$rootScope', 'Utility', '$stateParams', '$state', 'User', '$filter', 'Modal', '$timeout', function ($scope, $rootScope, Utility, $stateParams, $state, User, $filter, Modal, $timeout) {
  var $ = window.$

  $timeout(function () {
    var selector1 = document.getElementById('date_of_birth_input_1')
    var selector2 = document.getElementById('date_of_birth_input_2')

    if (selector1) Inputmask('dd/mm/yyyy', { yearrange: { minyear: 1900, maxyear: 2099 } }).mask(selector1)
    if (selector2) Inputmask('dd/mm/yyyy', { yearrange: { minyear: 1900, maxyear: 2099 } }).mask(selector2)
  }, 1000)

  $scope.userPageIsLoading = true
  $scope.requestUserSlug = $stateParams.slug
  $rootScope.pageUser = {}
  $scope.lastTrees = []
  $scope.neutralizedEmissions = []
  $scope.hasNeutralizedEmissions = false
  $scope.countries = []
  $scope.allCountries = []
  $scope.collection = []
  $scope.lastCollectionSpecies = []
  $scope.lastEvents = []
  $scope.userStream = []
  $scope.eventTrees = []
  $scope.mapIsExpanded = false
  $scope.activeTreeFilter = 'all'
  $scope.userTrees = { all: [], gifted: [], liked: [] }
  $scope.swiper = null
  $scope.userSummary = {}
  $scope.busy = true;
  $scope.userId = false;
  var mapIsLoaded = false

  // =========================== //
  //        GET USER INFO        //
  // =========================== //

  // Get user info
  var getUserInfo = function (cb) {
    if ($rootScope.showUser && $rootScope.showUser.info && $rootScope.showUser.info.slug === $stateParams.slug) {
      $rootScope.pageUser.info = $rootScope.showUser.info
      renderUserMap($rootScope.showUser.info.id)
      cb($rootScope.showUser.info)
      return
    }

    User.user($stateParams.slug)
    .then(function (response) {
      var data = response.data || response
      $rootScope.pageUser.info = data.info
      renderUserMap(data.info.id)
      cb(data.info)
    })
  }

  var formatUserDateOfBirth = function (dateOfBirth) {
    if (dateOfBirth && isNumber(dateOfBirth)) {
      $rootScope.pageUser.info.date_of_birth = $filter('datetime')(dateOfBirth)
    }
  }

  $scope.nextPage = function(_filter){
      if ($scope.busy || !$scope.userTrees.pages) return;
      var pageIndex = $scope.userTrees.pages.mine;
      if(_filter == "gifted"){
          pageIndex = $scope.userTrees.pages.gifted;
      }
      else if(_filter == "liked"){
          pageIndex = $scope.userTrees.pages.liked;
      }
      if(!pageIndex.hasNextPage) return;

      $scope.busy = true;
      User.getPageUserTrees($rootScope.pageUser.info.id, _filter, pageIndex.nextCursor).then(function(_data){
          var trees = _data.trees;
          if(_filter == "gifted"){
              $scope.userTrees.pages.gifted = {
                  hasNextPage: trees.pageInfo.hasNextPage,
                  nextCursor: trees.pageInfo.endCursor
              };
              for (var i = 0; i < trees.edges.length; i++) {
                  $scope.userTrees.gifted.push(trees.edges[i]);
              }
          }
          else if(_filter == "liked"){
              $scope.userTrees.pages.liked = {
                  hasNextPage: trees.pageInfo.hasNextPage,
                  nextCursor: trees.pageInfo.endCursor
              };
              for (var i = 0; i < trees.edges.length; i++) {
                  $scope.userTrees.liked.push(trees.edges[i]);
              }
          }
          else{
              $scope.userTrees.pages.mine = {
                  hasNextPage: trees.pageInfo.hasNextPage,
                  nextCursor: trees.pageInfo.endCursor
              };
              for (var i = 0; i < trees.edges.length; i++) {
                  $scope.userTrees.all.push(trees.edges[i]);
              }
          }
          $scope.busy = false;
      })
  }

  // Get user trees and parse them
  var getUserTrees = function (id, cb) {
    if (!id) return

    User.getInitalsUserTrees(id)
      .then(function(_trees){
        $rootScope.pageUser.treesGiven = _trees && _trees.gifted && _trees.gifted.edges ? _trees.gifted.edges : []
        $scope.userTrees.gifted = $rootScope.pageUser.treesGiven
        $scope.userTrees.all = $rootScope.pageUser.trees = _trees && _trees.mine && _trees.mine.edges ?  _trees.mine.edges : []
        $scope.userSummary = _trees.userSummary
        //$rootScope.pageUser.treesPlanted  = _trees.mine.edges;
        $scope.userTrees.liked = _trees && _trees.liked && _trees.liked.edges ?  _trees.liked.edges : []
        $scope.userTrees.sumTrees = _trees.userSummary.mine + _trees.userSummary.gifted + _trees.userSummary.liked
        $scope.userTrees.pages = {
          mine: {
            hasNextPage: _trees.mine.pageInfo.hasNextPage,
            nextCursor: _trees.mine.pageInfo.endCursor
          },
          gifted: {
            hasNextPage: _trees.gifted.pageInfo.hasNextPage,
            nextCursor: _trees.gifted.pageInfo.endCursor
          },
          liked: {
            hasNextPage: _trees.liked.pageInfo.hasNextPage,
            nextCursor: _trees.liked.pageInfo.endCursor
          },
        };
        if($scope.userSummary.countries) userTreesCountries($scope.userSummary.countries)
        if($scope.userSummary.products){
            $rootScope.treesLoaded.then(function(){
                specieCollection($scope.userSummary.products, $rootScope.trees)
            })
        }
        $scope.busy = false
        $scope.renderUserTreesSwiper()
        if (cb) cb($rootScope.pageUser.trees)
      })
  }

  // Filter preview trees
  $scope.getPreviewTrees = function(filter) {
    return ($scope.userTrees[filter])
          ? $scope.userTrees[filter].slice(0, ($scope.userTrees[filter].length > 6) ? 5 : 6)
          : []
  }

  $scope.$on('tree.giftUpdated', function(_, trees, gift) {
    const ids = typeof trees === 'string' ? [trees] : trees
    $scope.userTrees.all.forEach(tree => {
      if (ids.includes(tree.treeId)) {
        tree.gift = gift
      }
    })
    if (!$scope.$$phase) $scope.$apply()
  })

  // Get user emissions and render chart
  var getUserEmissions = function (id) {
    if (!id) return

    User.getUserEmissions(id)
    .then(function (response) {
      var data = response.data || response
      $rootScope.pageUser.co2 = data

      if (data.emissions) {
        $scope.neutralizedEmissions = data.emissions.filter((_emission, key) => key < 3)
      }

      renderPieChart(data.categories, data.emissions)
    })
  }

  // Get user events
  var getUserEvents = function (id) {
    if (!id) return

    User.getUserEvents(id)
    .then(function (response) {
      var data = response.data || response
      // Hide unpublished events
      if (!$rootScope.user || ($rootScope.user && $rootScope.pageUser.info.slug !== $rootScope.user)) {
        data = data.filter(function (e) {
          return Number(e.published)
        })
      }

      $rootScope.pageUser.events = data

      // Store last 2 events in another variable
      $scope.lastEvents = data.filter(function (event, key) {
        return key < 2
      })
    })
  }

  // Get user relationships
  var getUserRelationships = function (id) {
    if (!id) return

    User.getUserRelationships(id)
    .then(function (response) {
      $rootScope.pageUser.follow = response.data || response
    })
  }

  // Get user stream
  var getUserStream = function (userId) {
    // User.getUserStream(userId)
    // .then(function (response) {
    //   var data = response.data || response
    //   for (var key in data.streams) {
    //     data.streams[key]['streamType'] = 'streamNew.' + data.streams[key].action + '_' + data.streams[key].type
    //     $scope.userStream.push(data.streams[key])
    //   }
    // })
  }

  // Get user trees grouped for events
  var getUserTreesInEvents = function (id) {
    User.getUserTreesInEvents(id)
    .then(function (response) {
      var data = response.data || response
      $rootScope.pageUser.eventTrees = data
      groupTreesByEvent(data)
    })
  }

  var userTreesCountries = (countries) => {
      $scope.countries = countries.map(({ country: { slug, name, flag }, count }) => ({
        slug,
        name: name[$rootScope.ln],
        flag: flag.medium,
        count
      }));

      // Get all countries
      const allProjects = [ ...$rootScope.projects ]

      const countriesMapped = allProjects.map(({ slug, name, flag }) => ({
        slug,
        name,
        flag,
        count: null
      }))

      const userCountriesSlugs = $scope.countries.map(c => c.slug)

      var countriesNotHave = countriesMapped.filter(({ slug }) => !userCountriesSlugs.includes(slug))

      const allCountries = [ ...$scope.countries, ...countriesNotHave ]

      $scope.allCountries = allCountries.sort((a, b) => (a && a.name || "").localeCompare(b && b.name || ""))

      // Call other functions
      //findLastCountries($scope.countries);
      $scope.lastCountries = [
        ...$scope.countries.slice(0, 3),
        ...countriesNotHave.slice(0, Math.max(0, 3 - $scope.countries.length))
      ]
  }

  // Render user map
  var renderUserMap = function (id) {
    if (!mapIsLoaded) {
      $timeout(function () {
        mapIsLoaded = true
        $rootScope.$broadcast('LoadUserMap', { userId: id, expanded: false })
      }, 1)
    }
  }

  /**
   * Pie chart settings
   * @param {Object} emissionsCategories - Oggetto con le categorie delle emissioni di un utente
   * @param {Object} emissionsNeutralized - Array di emissioni neutralizzate da un utente
   */
  var renderPieChart = function (emissionsCategories, emissionsNeutralized) {
    // Se non hai emissioni non renderizzo il diagramma
    if (!Object.keys(emissionsCategories).length || !emissionsNeutralized) {
      $scope.hasNeutralizedEmissions = false
      return
    }

    var categories = Object.keys(emissionsCategories).reduce((iterator, key) => {
      // Colori categorie
      var colors = {
        alimentation: '#a9be74',
        fun: '#be6f5b',
        hobby: '#f9b364',
        home: '#b59d8c',
        products: '#e49ea1',
        trip: '#588cac',
        wear: '#9b87a4'
      }

      // Titoli categorie
      var titles = {
        alimentation: $filter('trans')('emissions.computation.categories.cat_02'),
        fun: $filter('trans')('emissions.computation.categories.cat_08'),
        hobby: $filter('trans')('emissions.computation.categories.cat_07'),
        home: $filter('trans')('emissions.computation.categories.cat_03'),
        products: $filter('trans')('emissions.computation.categories.cat_06'),
        trip: $filter('trans')('emissions.computation.categories.cat_01'),
        wear: $filter('trans')('emissions.computation.categories.cat_05')
      }

      // Calcolo il totale neutralizzato per ogni categoria
      var totalNeutralized = emissionsNeutralized.filter(function (emission) {
        return emission.category === key
      })
      .reduce(function (total, emission) {
        total += emission.kg
        return total
      }, 0)

      iterator.push({
        title: titles[key],
        value: totalNeutralized,
        color: colors[key]
      })

      return iterator
    }, [])

    // $timeout(function () {
    //   const $el = $('#emissionsChart');
    //   if ($el && $el.length) {
    //     $el.empty()
    //     $el.drawDoughnutChart(categories, {
    //       summaryTitle: $filter('trans')('myemissionsNew.chart.inside'),
    //       language: $rootScope.ln, // Serve per far sapere al diagramma se convertire o no i valori da kg a lb
    //       segmentShowStroke : true,
    //       segmentStrokeColor : "#ffffff",
    //       segmentStrokeWidth : 0,
    //       baseColor: "rgba(0,0,0,0.01)"
    //     })
    //   }
    // }, 500)

    $scope.hasNeutralizedEmissions = true
  }

  var specieCollection = function (myProducts, ecommerceSpecies) {
      ecommerceSpecies = Object.values(ecommerceSpecies).filter(function (item) { return item.type === 'tree' || item.type === 'tree_limited' });
      var myCollProducts = {};
      myProducts.forEach(function(_product){
        var key = _product.limited ? _product.limited.limitedName[$rootScope.ln] : "specie"+_product.specie.scientificName.id;
        if(!myCollProducts[key]){
            myCollProducts[key] = {
                key: key,
                productId: _product.id,
                limited: _product.limited ? true : false,
                name: _product.limited && _product.limited.limitedName[$rootScope.ln] || _product.specie.name[$rootScope.ln],
                scientificName: _product.specie.scientificName.name,
                picture: _product.limited && _product.limited.icon && _product.limited.icon.medium || _product.specie && _product.specie.icon && _product.specie.icon.medium || "",
                have: true
            };
        }
      })
      // Aggiungo alla mia collezione le specie dell'ecommerce che mi mancano
      var myCollection = ecommerceSpecies.reduce(function (collection, _product) {
          var inCollection = collection
            .findIndex(c => c.key === (
              _product.limited
              && _product.limited.limitedName
              && _product.limited.limitedName[$rootScope.ln]
              || "specie"+_product.specie.scientificName.id))

          if (inCollection < 0) {
              collection.push({
                  key: _product.limited ? _product.limited.limitedName[$rootScope.ln] : "specie"+_product.specie.scientificName.id,
                  productId: _product.id,
                  limited: _product.limited ? true : false,
                  name: _product.limited && _product.limited.limitedName && _product.limited.limitedName[$rootScope.ln] || _product.specie.name[$rootScope.ln],
                  scientificName: _product.specie.scientificName.name,
                  picture: _product.limited && _product.limited.icon && _product.limited.icon.medium || _product.specie.icon.medium,
                  have: false
              })
          }

          return collection
      }, Object.values(myCollProducts))

      $scope.collection = {
          trees: myCollection.sort((a, b) => (a && a.specie || "").localeCompare(b && b.specie || "")),
          total: myCollection.length,
          mine: myCollection.filter(function (_product) { return _product.have }).length
      }
      findLastSpecieCollection($scope.collection.trees)
  }
  /**
   * Trova la collezione di alberi con l'ecommerce attuale
   * @param {Array} myTrees - Array di alberi di un utente
   * @param {Object} ecommerceTrees - Oggetto degli alberi in ecommerce
   */
  var findSpecieCollection = function (myTrees, ecommerceSpecies) {


    // Remove packages from ecommerceSpecies array
    ecommerceSpecies = Object.values(ecommerceSpecies).filter(function (item) { return item.type === 'tree' })

    // Mappo gli array di alberi per trasformare il nome scientifico in minuscolo.
    /*ecommerceSpecies = ecommerceSpecies.map(function (tree) {
      if (tree.specie.scientificName) {
        tree.specie.scientificName.name = tree.specie.scientificName.name.toLowerCase()
      }

      return tree
    })

    myTrees = myTrees.map(function (tree) {
      if (tree.specie.scientificName) {
        tree.specie.scientificName.name = tree.specie.scientificName.name.toLowerCase()
      }

      return tree
    })*/

    // Trovo le specie e le limited editions
    var mySpecies = uniqBy(myTrees.filter(function (tree) { return !tree.limited }), 'specie.scientificName.name')
    var myLimited = uniqBy(myTrees.filter(function (tree) { return tree.limited }), 'limited.id')

    // Unisco specie e limited e li mappo trovando le proprietà che mi interessano
    var myAllSpecie = mySpecies.concat(myLimited).map(function (tree) {
      return {
        key: (tree.limited && tree.limited.id || tree.specie.id) * 1, // ZZZ.. stringa!
        specieId: tree.specie.id,
        limitedId: tree.limited && tree.limited.id || false,
        specie: tree.limited && tree.limited.limitedName[$rootScope.ln] || tree.specie.name[$rootScope.ln],
        scientificName: tree.specie.scientificName.name,
        picture: tree.limited && tree.limited.icon.medium || tree.specie.icon.medium,
        have: true
      }
    })

    // Aggiungo alla mia collezione le specie dell'ecommerce che mi mancano
    var myCollection = ecommerceSpecies.reduce(function (collection, specie) {
      var inCollection = collection.findIndex(c => {
        if (specie.limited) return c.key === specie.limited.id
        return c.scientificName === specie.specie.scientificName.name
      })

      if (inCollection < 0) {
        collection.push({
          key: (specie.limited && specie.limited.id || specie.specie.id),
          specieId: specie.specie.id,
          limitedId: specie.limited && specie.limited.id || false,
          specie: specie.limited && specie.limited.limitedName[$rootScope.ln] || specie.specie.name[$rootScope.ln],
          scientificName: specie.specie.scientificName.name,
          picture:specie.limited && specie.limited.icon.medium || specie.specie.icon.medium,
          have: false
        })
      }

      return collection
    }, myAllSpecie)

    $scope.collection = {
      trees: myCollection.sort((a, b) => (a && a.specie || "").localeCompare(b && b.specie || "")),
      total: myCollection.length,
      mine: myCollection.filter(function (tree) { return tree.have }).length
    }

    findLastSpecieCollection($scope.collection.trees)
  }

  /**
   * Trovo le ultime 3 specie della collezione
   * NOTE: called in a function findSpecieCollection()
   * @param {Array} collectionSpecies - Array di collezioni di un utente
   */
  var findLastSpecieCollection = function (collectionSpecies) {
    $scope.lastCollectionSpecies = []
    if (!collectionSpecies.length) return

    var speciesHave = shuffle(collectionSpecies.filter(function (tree) { return tree.have }))
    var speciesNotHave = shuffle(collectionSpecies.filter(function (tree) { return !tree.have }))

    $scope.lastCollectionSpecies = speciesNotHave.length >= 2
      ? $scope.lastCollectionSpecies.concat([speciesNotHave[0], speciesNotHave[1]])
      : speciesNotHave.length === 1
        ? $scope.lastCollectionSpecies.concat([speciesNotHave[0]])
        : []

    $scope.lastCollectionSpecies = speciesHave.length
      ? $scope.lastCollectionSpecies.concat([speciesHave[0]])
      : $scope.lastCollectionSpecies.concat([speciesNotHave[2]])

    $scope.lastCollectionSpecies = $scope.lastCollectionSpecies.length === 2
      ? $scope.lastCollectionSpecies.concat([speciesHave[1]])
      : $scope.lastCollectionSpecies.length === 1
        ? $scope.lastCollectionSpecies.concat([speciesHave[1], speciesHave[2]])
        : $scope.lastCollectionSpecies
  }

  /**
   * Raggruppa gli alberi per evento.
   * @param {Array} events - Array di eventi a cui appartengono gli alberi.
   */
  var groupTreesByEvent = function (events) {
    if (!events.length) {
      $scope.eventTrees = []
      return
    }

    $scope.eventTrees = events.reduce(function (collection, event) {
      // Controllo se l'albero è presente nella collezione.
      var indexInCollection = collection.findIndex( c => c.id === event.id)

      // Se l'evento non è presente nella collezione, aggiungilo e setta il counter
      // a 1 albero.
      if (indexInCollection < 0) {
        event.trees = 1
        collection.push(event)
        return collection
      }

      // Se l'evento è già presente aggiungo +1 al counter degli alberi.
      collection[indexInCollection].trees++
      return collection
    }, [])
  }

  // TODO: modificare questa funzione o scriverne un'altra in caso un utente
  // compri un pacchetto, fare aprire la modale di scelta albero da regalare
  var openModalGiftInfo = function (trees) {
    if (!trees.length) return
    if ($rootScope.modalGiftInActionShowed) return

    var openGiftModalInfo = !!trees.some(function(tree) {
      return !openGiftModalInfo && tree.actions && tree.actions.isVibrating && tree.gift && !tree.gift.toUser
    })

    if (openGiftModalInfo) {
      $timeout(function () {
        $rootScope.modalGiftInActionShowed = true
        Modal.open({ templateUrl: 'modalGiftInAction.html', id: '' })
      }, 1000)
    }
  }

  var findTreesCoupled = function (trees) {
    var treesWithCoupledId = trees.filter(
      function (tree) {
        return tree.coupled && tree.coupled.treeId && !tree.coupled.gift && !tree.gift
      }
    )
    var coupledTrees = []

    while (treesWithCoupledId.length) {
      // Prendo il primo albero dall'array
      var tree = treesWithCoupledId[0]
      // Trovo l'albero connesso
      var treeCoupled = treesWithCoupledId.find(t => t.id === tree.coupled.id)

      // Se ho trovato entrambi, li inserisco nello stesso array e salvo
      if (tree && treeCoupled) {
        coupledTrees.push([tree, treeCoupled])
      }

      // Se ho trovato l'albero, lo rimuovo dall'array originale
      if (tree) {
        treesWithCoupledId = treesWithCoupledId.filter(function (t) { return t.treeId !== tree.treeId })
      }

      // Se ho trovato l'albero connesso, lo rimuovo dall'array originale
      if (treeCoupled) {
        treesWithCoupledId = treesWithCoupledId.filter(function (t) { return t.treeId !== treeCoupled.treeId})
      }
    }

    return coupledTrees
  }

  getUserInfo(function (userInfo) {
    var id = userInfo.id
    formatUserDateOfBirth(userInfo.date_of_birth)
    getUserEmissions(id)
    getUserEvents(id)
    getUserStream(id)
      //TODO rimuovere
    getUserTreesInEvents(id)
    getUserRelationships(id)
    getUserTrees(id, function (mineTrees) {
      $scope.userPageIsLoading = false
    })
  })

  // On planting trees succeeded
  $scope.$on('plantingTreesSucceeded', function () {
    getUserTrees($rootScope.pageUser.info.id, function () {
      $scope.userPageIsLoading = false
      // Attiva lo slider per gli alberi degli utenti (mobile only)
      $timeout(function () { $scope.renderUserTreesSwiper() }, 100)
      $rootScope.comingFromPayment = false;
    })
  })

  // =========================== //
  //      USER INTERACTION       //
  // =========================== //

  // Open stream modal
  $scope.openPersonalStreamModal = function () {
    Modal.open({templateUrl: 'stream/personal.html'})
  }

  /**
   * Apre la scheda albero
   * @param {string} treeId - ID dell'albero (NON DELLA SPECIE)
   * @param {boolean} gift - Se l'albero è in fase di regalo o no
   * @param {string} filterActive
   */
  $scope.openSingleTree = function (treeId, gift, filterActive) {
    if ($scope.eventSelected) {
      return $state.href('user.event.trees.item', { slug: $rootScope.pageUser.info.slug, item: treeId }, { absolute: true })
    }

    var params = { slug: $rootScope.pageUser.info.slug, item: treeId }
    if (filterActive && filterActive !== 'all') params.filter = filterActive
    return $state.href('user.trees.item', params, { absolute: true })
  }

  /**
   * Modifica informazioni utente
   * @param {string} field - Campo che sto modificando, esempio: 'location'
   */
  $scope.editUserInfo = function (field) {
    if ($rootScope.pageUser.info.slug !== $rootScope.user) return
    $scope.editingUserField = field

    var elem1 = document.getElementById(field + '_input_1')
    var elem2 = document.getElementById(field + '_input_2')
    $timeout(function () {
      if (elem1) elem1.select()
      if (elem2) elem2.select()
    }, 50)

    window.addEventListener('click', enabledEditEventListener, false)
    window.addEventListener('keydown', enabledEditEventListener, false)
  }

  /**
   * @return true, if user can create event
   */
  $scope.canCreateEvent = function () {
    if (!$rootScope.pageUser.events || !$rootScope.pageUser.events.length) return true
    var canCreateEvent = true

    // Se draft null e published false: evento creato ma non pubblicato.
    // Se draft null e published true: evento creato e pubblicato.
    // Se draft diverso da null e published false: evento salvato come bozza.
    $rootScope.pageUser.events.forEach(function (e) {
      if (!Number(e.published) && !e.draft) canCreateEvent = false
      if (!Number(e.published) && e.draft) canCreateEvent = false
    })

    return canCreateEvent
  }

  $scope.canEditEvent = function () {
    if (!$rootScope.pageUser.events || !$rootScope.pageUser.events.length) return false
    var canEditEvent = false

    // Se draft null e published false: evento creato ma non pubblicato.
    // Se draft null e published true: evento creato e pubblicato.
    // Se draft diverso da null e published false: evento salvato come bozza.
    $rootScope.pageUser.events.forEach(function (e) {
      if (!Number(e.published) && e.draft) canEditEvent = true
    })

    return canEditEvent
  }

  $scope.editEventUnpublished = function (event) {
    if (Number(event.draft)) {
      $rootScope.createEventFlow()
      return
    }

    $state.go('user.event', { eventslug: event.slug })
  }

  // EventListener
  function enabledEditEventListener (e) {
    var elem = e.target

    if (!elem.id || e.keyCode === 13) {
      saveNewUserInfo()
    }
  }

  function updateUserId() {
    if ($stateParams.slug !== $rootScope.user) return
    if ($rootScope.userdata && $rootScope.userdata.info) {
      $scope.userId = $rootScope.userdata.info.id
    } else {
      $scope.$on('userdata.info', (_event, { id }) => {
        $scope.userId = id
      })
    }
  }

  updateUserId()

  // Save edited user informations
  function saveNewUserInfo () {
    // Chiudo gli input.
    $scope.editingUserField = false
    $scope.$apply()

    // Rimuovo i listeners su click e pressione tasti.
    window.removeEventListener('click', enabledEditEventListener, false)
    window.removeEventListener('keydown', enabledEditEventListener, false)

    // Parso la data in formato YYYY-MM-DD per il PHP.
    if ($rootScope.pageUser.info.date_of_birth) {
      var splittedDate = $rootScope.pageUser.info.date_of_birth.split('/')
      var day = splittedDate[0]
      var month = splittedDate[1]
      var year = splittedDate[2]
      var parsedDate = year + '-' + month + '-' + day
    }

    // Compongo l'oggetto da mandare al backend.
    var userEdited = {
      location: $rootScope.pageUser.info.location,
      date_of_birth: parsedDate || null
    }

    // Se la data non è formattata nel modo corretto, non salvare.
    if (userEdited.date_of_birth !== null) {
      if (userEdited.date_of_birth.includes('y') ||
        userEdited.date_of_birth.includes('m') ||
        userEdited.date_of_birth.includes('d')) return
    }

    // Salva le modifiche.
    User.userEditProfile(userEdited)
    .then(function (response) {
      var data = response.data || response
      $rootScope.pageUser.info.location = data.location
      formatUserDateOfBirth(data.date_of_birth)
    })
  }

  /**
   * Segui/Smetti di seguire l'utente della pagina.
   * @param {string} userSlug - Slug dell'utente che si vuole seguire.
   */
  $scope.toggleFollow = function (slug) {
    if (!$rootScope.user) {
      Modal.open({templateUrl: 'login.html', easyClose: false, fitcontent: true})
      return
    }

    Utility.disableButton()
    var userToFollow = slug || $rootScope.pageUser.info.slug

    User.toggleFollow(userToFollow)
    .then(function (response) {
      const data = response.data || response
      $rootScope.userdata.following = data
      $scope.following = $rootScope.userdata.following.indexOf($rootScope.pageUser.info.slug) > -1
      getUserRelationships($rootScope.pageUser.info.id)

      const shouldFollow = data.includes(userToFollow)
      DataEventToggleFollow(shouldFollow, 'user-profile','user');

      Utility.enableButton()
    })
    .catch(function () {
      Utility.enableButton()
    })
  }

  // Watcher per aggiornamento following/follower
  $scope.$watch(function () {
    return $rootScope.userdata
  }, function (newVal) {
    if (!isUndefined(newVal)) {
      if ($rootScope.userdata.following) {
        $scope.following = $rootScope.user
          ? $rootScope.userdata.following.indexOf($stateParams.slug) > -1
          : false
      }
    }
  })

  /**
   * Broadcast che viene eseguito quando aggiungi/rimuovi follower o following
   */
  $rootScope.$on('ChangedUserRelationships', function (event) {
    getUserStream($rootScope.pageUser.info.id)
    getUserRelationships($rootScope.pageUser.info.id)
  })

  /**
   * Broadcast che viene eseguito quando esegui azioni con gli alberi
   */
  $rootScope.$on('ChangedUserTrees', function (event) {
    if ($rootScope.user !== $stateParams.slug) return
    getUserTrees($rootScope.pageUser.info.id)
  })

  /**
   * Anima il box dell'utente lungo tutta la pagina.
   */
  var shotScrollTopAddedClass
  function animateUserWrap () {
    var userWrap = document.getElementById('userWrap')
    if (!userWrap) return

    if (!$state.current.name.includes('user')) {
      userWrap.classList
       ? userWrap.classList.remove('newPrivateUserInfoFixed')
       : userWrap.className.replace(' newPrivateUserInfoFixed', '')
      return
    }

    var scrollTop = document.body.scrollTop || document.documentElement.scrollTop

    if (scrollTop >= 85) {
      userWrap.classList
        ? userWrap.classList.add('newPrivateUserInfoFixed')
        : userWrap.className += ' newPrivateUserInfoFixed'
    } else {
      userWrap.classList
        ? userWrap.classList.remove('newPrivateUserInfoFixed')
        : userWrap.className.replace(' newPrivateUserInfoFixed', '')
    }

    var footer = document.getElementById('footer')
    var footerOffsetTop = footer.offsetTop
    var userWrapRect = userWrap.getBoundingClientRect()
    var userWrapSpace = footerOffsetTop - (userWrapRect.top + userWrapRect.height + 17.5)

    if (scrollTop >= userWrapSpace) {
      if (!shotScrollTopAddedClass) shotScrollTopAddedClass = scrollTop
      userWrap.classList
        ? userWrap.classList.add('newPrivateUserInfoBottom')
        : userWrap.className += ' newPrivateUserInfoBottom'
    }

    if (shotScrollTopAddedClass) {
      if (scrollTop <= shotScrollTopAddedClass) {
        // shotScrollTopAddedClass = undefined
        userWrap.classList
          ? userWrap.classList.remove('newPrivateUserInfoBottom')
          : userWrap.className = userWrap.className.replace(' newPrivateUserInfoBottom', '')
      }
    }
  }

  // Start funzione toggleUserNavbar ed eventlisteners
  $timeout(function () {
    animateUserWrap()
    window.addEventListener('scroll', animateUserWrap)
  }, 1000)

  // Watcher per chiusura Header mobile al cambio di stato dell'applicazione.
  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
    animateUserWrap()

    // Chiusura mappa se cambio stato
    if (fromState.name === 'user.map' && toState.name !== fromState.name) {
      $scope.collapseMap(true)
    }
  })

  /**
   * Vai alla pagina follower
   */
  $scope.goToFollowerPage = function (slug) {
    if (!slug) return
    $state.go('user.relation.follower', {slug: slug})
  }

  /**
   * Vai alla pagina following
   */
  $scope.goToFollowingPage = function (slug) {
    if (!slug) return
    $state.go('user.relation.following', {slug: slug})
  }

  /**
   * Vai alla pagina specie
   */
  $scope.goToSpecies = function () {
    $state.go('species')
  }

  /**
   * Vai alla pagina di un evento.
   * @param {string} userSlug - Slug dell'utente a cui appartiene l'evento.
   * @param {string} eventSlug - Slug dell'evento.
   * @param {string} userType - Tipo di utente che ha organizzato l'evento.
   */
  $scope.goToEvent = function (userSlug, eventSlug, userType) {
    userType === 'Business'
      ? $state.go('organization.event', {slug: userSlug, eventslug: eventSlug})
      : $state.go('user.event', {slug: userSlug, eventslug: eventSlug})
  }

  /**
   * Apre la modale del riscatto.
   */
  $scope.startRedeemCouponFlow = function () {
    $state.go('treecodeLanding')
  }

  $scope.changeTreesFilter = function (filter) {
    $scope.activeTreeFilter = filter
    $scope.renderUserTreesSwiper()
  }

  // Swiper trees
  $scope.renderUserTreesSwiper = function () {
    if ($scope.swiper) {
      !Array.isArray($scope.swiper)
        ? $scope.swiper.destroy ? $scope.swiper.destroy(true, true) : null
        : $scope.swiper.forEach(function (s) { s.destroy(true, true) })
    }
    var trees = $scope.userTrees[$scope.activeTreeFilter]
    if (!trees.length) {
      $scope.swiper = null
      return
    }
    $timeout(function () {
      $scope.swiper = new Swiper('#SwiperUserTrees', {
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 30,
        nextButton: '#UserTreesButtonNextSlider',
        prevButton: '#UserTreesButtonPrevSlider',
        pagination: '#UserTreesPagination',
        dynamicBullets: true,
        paginationClickable: true,
        grabCursor: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        }
      })
    })
  }

  // Se regalo da pagina di altro utente metto in rootScope e mando alla pagina 'pianta'
  $scope.giftFromPage = function () {
    // nel caso ci fosse un evento segnato
    $rootScope.plantIn = false
    $rootScope.giftToUser = $rootScope.showUser.info
    $state.go('species')
  }

  $scope.expandMap = function () {
    $scope.mapIsExpanded = true
    var mapContainer = document.getElementById('MapContainer')
    if (!mapContainer) return
    let mapHeight = window.innerHeight - (mapContainer.offsetTop + mapContainer.offsetParent.offsetTop)

    var toolbar = document.getElementById('toolbar')
    if (toolbar) {
      mapHeight -= toolbar.offsetHeight
    }

    mapContainer.style.transition = '0.3s ease all'
    mapContainer.style.height = mapHeight + 'px'

    var userBox = document.getElementById('userWrap')
    if (userBox) userBox.style.top = '0px'

    $timeout(function () {
      Utility.blockScroll()
      $rootScope.$broadcast('ResizeUserMap')
      $rootScope.$broadcast('ExpandUserMap', true)
      window.addEventListener('resize', ResizeMapListener)
    }, 300)

    $state.go('user.map')
  }

  $scope.collapseMap = function (blockChangeStatus) {
    $scope.mapIsExpanded = false

    var mapContainer = document.getElementById('MapContainer')
    mapContainer.style.transition = '0.3s ease all'
    mapContainer.style.height = null
    $rootScope.$broadcast('ExpandUserMap', false)

    var userBox = document.getElementById('userWrap')
    if (userBox) userBox.style.top = null

    $timeout(function () {
      $rootScope.$broadcast('ResizeUserMap')
      window.removeEventListener('resize', ResizeMapListener)
      Utility.allowScroll()
    }, 300)

    if (!blockChangeStatus) $state.go('user')
  }

  var ResizeMapListener = function () {
    if (!$scope.mapIsExpanded) return
    var navbar = document.getElementById('navbar')
    var heightOffset = navbar ? navbar.offsetHeight : 75

    var mapContainer = document.getElementById('MapContainer')
    mapContainer.style.height = (window.innerHeight - heightOffset) + 'px'
    $rootScope.$broadcast('ResizeUserMap')
  }

  if ($rootScope.currentState === 'user.map') {
    $timeout(function () { $scope.expandMap() }, 1000)
  }

  var ListenerUpdateUserTrees = $rootScope.$on('UpdateUserTrees', function () {
    getUserTrees($rootScope.pageUser.info.id)
  })

  var EventsUpdatedBroadcast = $scope.$on('EventsUpdated', function () {
    getUserEvents($rootScope.pageUser.info.id)
  })

  // Destroy listeners when controller has been detached
  $scope.$on('$destroy', ListenerUpdateUserTrees)
  $scope.$on('$destroy', EventsUpdatedBroadcast)
  $scope.$on('$destroy', function () {
    window.removeEventListener('scroll', animateUserWrap)
  })
}])
