






































import { defineComponent, ref, onMounted } from "@vue/composition-api";
import VimeoPlayer, { Player } from "@vimeo/player";
import Treecon from "@f/components/Treecons/Treecon.vue";
import DotLoader from "@f/components/DotLoader.vue";

export default defineComponent({
  name: "QuestionVideoPlayer",
  components: {
    DotLoader,
    Treecon,
  },
  props: {
    url: { type: String, required: true },
    expandedPlayer: { type: Boolean },
    videoCanPlay: { type: Boolean },
    showMuteToggle: { type: Boolean, default: false },
    canBePaused: { type: Boolean, default: false },
  },
  emits: ["play", "stop"],
  setup: (props, { emit }) => {
    const videoLoading = ref(true);
    const video = ref(null);
    const videoPlayer = ref<Player | null>(null);
    const videoIsPlaying = ref(false);
    const play = () => {
      if (props.videoCanPlay) {
        videoLoading.value = true;
        videoPlayer.value?.play().then(() => (videoLoading.value = false));
        emit("play");
      }
    };
    const pause = () => {
      if (props.canBePaused) {
        videoLoading.value = true;
        videoPlayer.value?.pause().then(() => (videoLoading.value = false));
      }
    };
    const togglePlay = () => (videoIsPlaying.value ? pause() : play());
    const replay = () => {
      videoPlayer.value?.setCurrentTime(0);
      play();
    };
    const stop = () => {
      pause();
      videoPlayer.value?.setCurrentTime(0);
      emit("stop");
    };
    const toggleMuted = () =>
      muted.value ? videoPlayer.value?.setVolume(1) : videoPlayer.value?.setVolume(0);

    const percentagePlayback = ref(0);
    const muted = ref(true);

    onMounted(() => {
      if (video.value !== null) {
        videoPlayer.value = new VimeoPlayer((video.value as unknown) as HTMLElement, {
          title: false,
          responsive: true,
          transparent: true,
          portrait: true,
          byline: false,
          muted: muted.value,
          controls: false,
          url: props.url,
        });

        videoPlayer.value.on("loaded", () => (videoLoading.value = false));
        videoPlayer.value.on("bufferstart", () => (videoLoading.value = true));
        videoPlayer.value.on("bufferend", () => (videoLoading.value = false));
        videoPlayer.value.on("play", () => (videoIsPlaying.value = true));
        videoPlayer.value.on("pause", () => (videoIsPlaying.value = false));
        videoPlayer.value.on("ended", () => stop());
        videoPlayer.value.on("volumechange", (data) => (muted.value = data.volume === 0));
        videoPlayer.value.on(
          "timeupdate",
          (data) => (percentagePlayback.value = data.percent * 100)
        );
      }
    });

    return {
      video,
      togglePlay,
      videoIsPlaying,
      pause,
      replay,
      stop,
      videoLoading,
      percentagePlayback,
      muted,
      toggleMuted,
    };
  },
});
