import { Vue, Component } from 'vue-property-decorator'

export type Focusable = InputLikeComponent | HTMLInputElement

function isFocusable(component: unknown): component is Focusable {
  return typeof component === 'object'
    && !!component
    && 'focus' in component
}

@Component({
  name: 'InputLikeComponent'
})
export default class InputLikeComponent extends Vue {
  /*--- METHODS -------*/
  focus() {
    if (this.$refs.input) {
      const targetInput = Array.isArray(this.$refs.input) ? this.$refs.input[0] : this.$refs.input
      if (isFocusable(targetInput)) {
        targetInput.focus()
      } else if (process.env.NODE_ENV === 'development') {
        console.error('Focus called on a non-focusable element')
      }
    }
  }

  blur() {
    if (this.$refs.input) {
      const targetInput = Array.isArray(this.$refs.input) ? this.$refs.input[0] : this.$refs.input
      if (isFocusable(targetInput)) {
        targetInput.blur()
      } else if (process.env.NODE_ENV === 'development') {
        console.error('Blur called on a non-focusable element')
      }
    }
  }

  emitInput(event: InputEvent | unknown): void {
    const emittedValue = event && typeof event === 'object' && 'target' in event
      ? ((event as InputEvent).target as HTMLInputElement).value
      : event
    this.$emit('input', emittedValue)
  }

  // TODO: replace listeners filters with custom invokers on created / beforeCreate
  filteredListeners(keys: string | string[]) {
    if (!keys?.length) return this.$listeners
    const rawListeners = { ...this.$listeners }
    const purgeKeys = Array.isArray(keys) ? keys : [keys]
    for (const listenerKey of purgeKeys) {
      delete rawListeners[listenerKey]
    }
    return rawListeners
  }
}
