import axios, { AxiosResponse } from 'axios'
import { api } from '@f/configs/app'
import { apiClient } from '@f/apollo'
import gql from 'graphql-tag'
import { Event, CreateForest as Forest, EventUsersPage, ForestOption } from '@f/@types'
import { translate } from '@f/services/Translation'
import useFormat from '@f/composables/useFormat'
import { TrTranslation } from '@/public_src/@types/graphql'
import { registerAxiosAuthorizationInterceptor } from '@f/auth/helpers'

const { formatLocalNumbers } = useFormat();

const legacyApiClient = axios.create({
  baseURL: api.baseUri,
})
registerAxiosAuthorizationInterceptor(legacyApiClient)

interface OpenForestResponseItem {
  product_id: string
  tree_id: string
}

interface OpenForestSuccessResponse {
  [key: number]: OpenForestResponseItem
}

interface OpenForestErrorResponse {
  error: boolean
  errorMessage: string
}

interface StartForestResponse {
  event: Forest
  eventTypes: ForestOption[]
}

export type OpenForestCheckResponse = OpenForestSuccessResponse | OpenForestErrorResponse

export function fetchForest(slug: string, noCache: boolean = false) {
  const cacheHeader = noCache ? {
    headers: { 'Cache-Control': 'no-cache', }
  } : {};
  return legacyApiClient.get<Event>('/user/event/' + encodeURIComponent(slug), cacheHeader)
    .then(({ data }) => data)
}

export function fetchForestNumbers(slug: string) {
  return apiClient.query({
    query: gql`
      query EventNumbers ($slug: String!){
        Event_numbers(slug: $slug) {
          trees
          emissions
          countries
          farmers
        }
      }
    `,
    variables: { slug },
  }).then(({ data: { Event_numbers } }) => Event_numbers)
}

// TODO: create custom error to handle user_already_redeem tree id returned
export function checkOpenForest(slug: string) {
  return legacyApiClient.get<OpenForestCheckResponse>('/getAvailableTrees/' + encodeURIComponent(slug))
    .then(({ data }) => {
      if ('error' in data && data.error) throw Error(data.errorMessage)
      return Array.isArray(data)
        ? data.map(({ product_id, tree_id }) => {
          return {
            product_id: Number(product_id),
            tree_id: String(tree_id)
          }
        })
        : data
    })
}

// TODO: create custom error to handle user_already_redeem tree id returned
export function redeemFromOpenForest(slug: string, productId: number) {
  return legacyApiClient.get('/getOwnershipTreeOpenForest/' + encodeURIComponent(slug) + '/' + productId)
    .then(({ data }) => {
      if ('error' in data && data.error) throw Error(data.errorMessage)
      return data
    })
}

export function fetchForestUsers(id?: number, page = 0, limit = 100) {
  if (!id) return Promise.reject('No forest id provided')
  return legacyApiClient.get<EventUsersPage>(`/event/users/${id}/${page}?perPage=${limit}`)
    .then(({ data }) => data)
}

export function startForest(eventSlug: string | null = null): Promise<AxiosResponse<StartForestResponse>> {
  const fetchEventSlug = eventSlug ? `?eventSlug=${encodeURIComponent(eventSlug)}` : '';
  return legacyApiClient.get(`/start-private-forest${fetchEventSlug}`)
}

export function saveForest(forest: Forest): Promise<AxiosResponse<Forest>> {
  return legacyApiClient.post('/private-forest', forest)
}

export function saveForestImage({ id, slug }: { id: number, slug: string }, file: File, type: 'avatar' | 'background'): Promise<{ data: { picturePath: number } }> {
  const formData = new FormData();
  formData.append('file', file);
  return legacyApiClient.post(
     `/private-forest/image/${id}/${type}?slug=${encodeURIComponent(slug)}&id=0&eventId=${id}&offset=0`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
}

// POST instead of DELETE
export function deleteForest(forestId: number): Promise<AxiosResponse> {
  return legacyApiClient.post('/private-forest/delete', { id: forestId })
}

export function saveForestGalleryImage(file: File, userSlug: string, forestSlug: string, forestId: number) {
  const formData = new FormData();
  formData.append('file', file);
  return legacyApiClient.post(
    `/private-forest/image/${forestId}/gallery?slug=${userSlug}&eventslug=${encodeURIComponent(forestSlug)}&id=0&eventId=${forestId}&offset=0`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
}

export function associateForestImage(forestId: number, imageId: number) {
  return legacyApiClient.post('/private-forest/add/gallery/image', { event: forestId, image: imageId })
}

export function deleteForestGalleryImage(forestId: number, imageId: number) {
  return legacyApiClient.post('/private-forest/remove/gallery/image', { event: forestId, image: imageId })
}

export function getForestTreesCountFormatted(treesCount: number): string {
  if (treesCount === 1) {
    return translate('forest.forestTree.count.second', { count: formatLocalNumbers(treesCount) })
  }
  return translate('forest.forestTrees.count.second', { count: formatLocalNumbers(treesCount) })
}

export function getForestCountryNumbersFormatted(country: TrTranslation, treesCount: number, wrapNumberInParentheses = false): string {
  if (wrapNumberInParentheses) {
    return translate(country ) + ' (' + getForestTreesCountFormatted(treesCount) + ')'
  }
  return translate(country ) + ' ' + getForestTreesCountFormatted(treesCount)
}