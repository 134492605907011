var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-mediakits__wrapper tr-container",class:{'profile-mediakits' : _vm.location === 'profile'}},[(_vm.loading)?_c('DotLoader'):_c('div',[_c('h5',{domProps:{"innerHTML":_vm._s(_vm.$t('edit.mediakits.subtitle'))}}),_vm._v(" "),_c('p',{staticClass:"mediakit-warning"},[_vm._v(_vm._s(_vm.$t('business.mediakit.warning')))]),_vm._v(" "),_c('div',{staticClass:"tr-grid"},[(_vm.b2bCertificate.loaded && _vm.b2bCertificate.visible)?_c('MediaKit',{staticClass:"tr-width-1-1 tr-width-1-2--md tr-width-1-3--lg",attrs:{"type":"certificate","url":_vm.certificate,"location":_vm.location,"icon":require('@f/images/media_kits/treedom_certificate_ck.jpg')}}):_vm._e(),_vm._v(" "),_c('UsersNumbersMediakit',{staticClass:"tr-width-1-1 tr-width-1-2--md tr-width-1-3--lg",attrs:{"active":_vm.hasNumbersWidgetActive,"description":_vm.treedomCounterDescription}}),_vm._v(" "),_vm._l((_vm.currentAchievements),function(ref){
var id = ref.id;
var url = ref.url;
var icon_id = ref.icon_id;
var label = ref.label;
var description = ref.description;
var startDate = ref.startDate;
var endDate = ref.endDate;
return _c('MediaKit',{key:id,staticClass:"tr-width-1-1 tr-width-1-2--md tr-width-1-3--lg",attrs:{"type":"active","description":description,"label":label,"validityDate":{startDate: startDate, endDate: endDate},"location":_vm.location,"url":url,"icon":_vm.iconUrl(icon_id)}})}),_vm._v(" "),(_vm.b2bCertificate.loaded && _vm.b2bCertificate.visible)?_c('business-subscription-media-kit',{staticClass:"tr-width-1-1 tr-width-1-2--md tr-width-1-3--lg",attrs:{"description":_vm.b2bCertificate.description,"certificate-status":_vm.b2bCertificate}}):_vm._e()],2),_vm._v(" "),(_vm.achievementArchive && _vm.achievementArchive.length)?_c('div',{staticClass:"user-mediakits__archive"},[_c('button',{staticClass:"user-mediakits__show-more",class:{ 'tr-caret--down': !_vm.archiveIsOpen, 'tr-caret--up': _vm.archiveIsOpen },domProps:{"innerHTML":_vm._s(_vm.$t('edit.mediakits.showArchived'))},on:{"click":function($event){_vm.archiveIsOpen = !_vm.archiveIsOpen}}}),_vm._v(" "),_c('SlideDownTransition',[(_vm.archiveIsOpen)?_c('div',{staticClass:"tr-grid"},_vm._l((_vm.achievementArchive),function(ref){
var id = ref.id;
var url = ref.url;
var icon_id = ref.icon_id;
return _c('MediaKit',{key:id,staticClass:"tr-width-1-1 tr-width-1-2--md tr-width-1-3--lg",attrs:{"type":"archived","url":url,"icon":_vm.iconUrl(icon_id)}})}),1):_vm._e()])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }