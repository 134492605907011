import Vue from 'vue'
import {
  apolloProvider
} from '@f/vue'
import vueBasicComponents from '@f/components'
import vuePages from '@f/pages'

const vueComponents = { ...vueBasicComponents, ...vuePages }

angular.module('ngApp')
  .directive('vueComponent', () => {
    return {
      restrict: 'E',
      scope: {
        component: '@',
        vProps: '=',
        permanent: '@'
      },
      link: (scope, el) => {
        if (!scope.component) throw Error('Indicare il componente da caricare con l\'attributo "component" della direttiva')

        const componentName = scope.component
        if (!vueComponents[componentName]) throw Error(`Impossibile trovare il componente Vue ${componentName}. Assicurarsi di aver esportato il componente richiesto.`)

        let componentSchema = vueComponents[componentName]

        const Component = (componentSchema instanceof Object)
          ? Vue.extend(componentSchema)
          : componentSchema
        let propsData = null
        let componentInstance = null
        let oldEl = null

        const unmountVue = function() {
          if (scope.mountedComponent) {
            oldEl = angular.element(scope.mountedComponent.$el)
            scope.mountedComponent.$destroy()
            scope.mountedComponent = null
            if (oldEl) oldEl.remove()
          }
          
        }

        scope.$parent.$on('$stateChangeStart', (_ev, _to, toParams, _from, _fromParams) => {
          if (toParams && toParams.avoidVueUnmount || typeof scope.permanent !== "undefined") return
          unmountVue()
        })

        el.on('$destroy', () => {
          if (scope.mountedComponent) scope.mountedComponent.$destroy()
        })

        scope.$watch('vProps', () => {
          if(scope.mountedComponent && scope.vProps.avoidVueUnmount) {
            propsData = {
              ...scope.vProps
            }
          }
          else {
            unmountVue()
            propsData = {
              ...scope.vProps
            }
  
            componentInstance = new Component({
              propsData,
              // Add ApolloProvider instance to component
              apolloProvider
            })
            scope.mountedComponent = componentInstance.$mount()
            el[0].appendChild(scope.mountedComponent.$el)
          }
        }, true )
      }
    }
  });
