
















import {defineComponent} from "@vue/composition-api";
import ShareNative from "@f/components/ShareNative.vue";
import {getRootScope, getStateService} from "@f/services/Angular";

export default defineComponent({
  name: "SocialShare",
  components: {
    ShareNative,
  },
  props: {
    forestLink: String
  },
  setup() {
    const stateService = getStateService();
    const rootScope = getRootScope();
    const goToProfile = () => {
      stateService && stateService.go("user", { slug: rootScope?.userdata.info.slug });
    };
    return {
      goToProfile,
    };
  },
});
