angular.module('ngApp')
.controller('singlePageController', ['$scope', '$state', '$rootScope', function ($scope, $state, $rootScope) {
  $scope.currentPage = $state.current.name;

  $scope.stateGo = function (state) {
    $state.go(state)
  };

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    $scope.currentPage = toState.name
  })
}])
