import ajv from '@f/validation/ajv'
import * as GameSchema from '@f/validation/schema/datalayer/game'
import { IStartGame, IGameQuestion1, IGameQuestion2, IGameQuestion3, IGameQuestion4, IGameQuestion5, IGameCopiedCode, IGameSentGift, IGameCtaUnderstand } from '@f/validation/types/dataLayer'

export const validateStartGame = ajv.compile<IStartGame>(GameSchema.StartGame)
export const validateGameQuestion1 = ajv.compile<IGameQuestion1>(GameSchema.GameQuestion1)
export const validateGameQuestion2 = ajv.compile<IGameQuestion2>(GameSchema.GameQuestion2)
export const validateGameQuestion3 = ajv.compile<IGameQuestion3>(GameSchema.GameQuestion3)
export const validateGameQuestion4 = ajv.compile<IGameQuestion4>(GameSchema.GameQuestion4)
export const validateGameQuestion5 = ajv.compile<IGameQuestion5>(GameSchema.GameQuestion5)
export const validateGameCopiedCode = ajv.compile<IGameCopiedCode>(GameSchema.GameCopiedCode)
export const validateGameSentGift = ajv.compile<IGameSentGift>(GameSchema.GameSentGift)
export const validateGameCtaUnderstand = ajv.compile<IGameCtaUnderstand>(GameSchema.GameCtaUnderstand)