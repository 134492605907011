import ajv from '@f/validation/ajv';
import * as MiscellaneousSchema from '@f/validation/schema/datalayer/misc';
import {
    IBoxInfo,
    IDownload,
    IFilterSelection,
    IMapInteraction,
    IOpenNotification,
    ISocialButton,
    IToggleFollow,
    ITreeDiaryInteraction,
    ITreeNameConfirmed,
    ITreeRegistryInteraction,
    ITreeRegistrySelection,
    IVideo,
    IForm
} from '@f/validation/types/dataLayer';

export const validateSocialButtonClick = ajv.compile<ISocialButton>(MiscellaneousSchema.SocialButtonSchema)
export const validateFilterSelection = ajv.compile<IFilterSelection>(MiscellaneousSchema.FilterSelectionSchema)
export const validateDownload = ajv.compile<IDownload>(MiscellaneousSchema.DownloadSchema)
export const validateTreeRegistryInteraction = ajv.compile<ITreeRegistryInteraction>(MiscellaneousSchema.TreeRegistryInteractionSchema)
export const validateTreeRegistrySelection = ajv.compile<ITreeRegistrySelection>(MiscellaneousSchema.TreeRegistrySelectionSchema)
export const validateTreeNameConfirm = ajv.compile<ITreeNameConfirmed>(MiscellaneousSchema.TreeNameConfirmedSchema)
export const validateTreeDiaryInteraction = ajv.compile<ITreeDiaryInteraction>(MiscellaneousSchema.TreeDiaryInteractionSchema)
export const validateToggleFollow = ajv.compile<IToggleFollow>(MiscellaneousSchema.ToggleFollowSchema)
export const validateOpenNotification = ajv.compile<IOpenNotification>(MiscellaneousSchema.OpenNotificationSchema)
export const validateBoxInfo = ajv.compile<IBoxInfo>(MiscellaneousSchema.BoxInfoSchema)
export const valdiateVideo = ajv.compile<IVideo>(MiscellaneousSchema.VideoSchema)
export const validateMapInteraction = ajv.compile<IMapInteraction>(MiscellaneousSchema.MapInteractionSchema)
export const validateForm = ajv.compile<IForm>(MiscellaneousSchema.FormSchema)