









import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from '@f/components/Icon.vue';

@Component({
  name: 'Alert',
  components: { Icon }
})
export default class Alert extends Vue {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  @Prop({ type: String, default: 'info' }) readonly state!: string
  @Prop({ type: Boolean, default: false }) readonly colored!: boolean
  
  /*--- DATA ----------*/
  
  /*--- COMPUTED ------*/
  get alertClass() {
    return {
      ['tds-alert--'+this.state]: true,
      'tds-alert--colored': this.colored
    }
  }
  
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  
  /*--- METHODS -------*/
  
}
