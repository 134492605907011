import { getRootScope } from '@f/services/Angular'
import { formatNumber } from '@f/utils'

export default function useFormat() {

  function formatLocalNumbers(number: number) {
    const rootScope = getRootScope()
    return formatNumber(number, rootScope?.ln || 'it')
  }

  return {
    formatLocalNumbers,
  }
}
