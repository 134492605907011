import Ajv from 'ajv'
import addFormats from 'ajv-formats'
import { nonEmpty } from '@f/validation/keywords'

const ajvOptions = {
  allErrors: true,
  $data: true,
  code: {
    source: true,
  },
  allowUnionTypes: true
}

const ajv = new Ajv(ajvOptions)
addFormats(ajv, ['email'])
ajv.addKeyword(nonEmpty)

export default ajv
