import { nonEmpty } from '@f/validation/keywords';
import { JSONSchemaType } from 'ajv'
import {
  IDlProductImpression,
  IDlProductInteraction,
  IImpressions,
  INotifyMe,
  IProductPackageTreeInfo,
  IProductPageView,
  IProductSetGift
} from '@f/validation/types/dataLayer'

const ProductImpressionSchema: JSONSchemaType<IDlProductImpression> = {
  type: 'object',
  required: ['id', 'name', 'price', 'tax', 'brand', 'category'],
  properties: {
    // FIXME: debug typescript error when using property spread or external const to merge schemas
    id: { type: 'integer' },
    name: { type: 'string', nonEmpty: true },
    price: { type: 'number' },
    discount: { type: 'number' },
    tax: { type: 'number' },
    brand: { type: 'string', const: 'Treedom' },
    category: { type: 'string', nonEmpty: true },
    position: { type: 'integer', nullable: true },
    list: { type: 'string', nullable: true }
  }
}

export const ProductInteractionSchema: JSONSchemaType<IDlProductInteraction> = {
  type: 'object',
  required: ['id', 'name', 'price', 'tax', 'brand', 'category'],
  properties: {
    // FIXME: debug typescript error when using property spread or external const to merge schemas
    id: { type: 'integer' },
    name: { type: 'string', nonEmpty: true },
    price: { type: 'number' },
    discount: { type: 'number', nullable: true },
    tax: { type: 'number' },
    brand: { type: 'string', const: 'Treedom' },
    category: { type: 'string', nonEmpty: true },
    position: { type: 'integer', nullable: true },
    quantity: { type: 'integer' },
    dimension1: { type: 'boolean', nullable: true }
  }
}

export const ProductPageViewSchema: JSONSchemaType<IProductPageView> = {
  type: 'object',
  required: ['event', 'ecommerce'],
  properties: {
    event: { type: 'string', const: 'detail' },
    ecommerce: {
      type: 'object',
      required: ['detail'],
      properties: {
        detail: {
          type: 'object',
          required: ['products'],
          properties: {
            products: {
              type: 'array',
              minItems: 1,
              maxItems: 1,
              items: ProductImpressionSchema
            }
          }
        }
      }
    }
  }
}

export const ProductSetGiftSchema: JSONSchemaType<IProductSetGift> = {
  type: 'object',
  required: ['event', 'flag', 'treeType'],
  properties: {
    event: { type: 'string', const: 'giftFlag' },
    flag: { type: 'string', enum: ['yes', 'no'] },
    treeType: { type: 'string' }
  }
}

export const ProductPackageTreeInfo: JSONSchemaType<IProductPackageTreeInfo> = {
  type: 'object',
  required: ['event', 'treeName'],
  properties: {
    event: { type: 'string', const: 'treeInfo' },
    treeName: { type: 'string', nonEmpty: true }
  }
}

export const ImpressionsSchema: JSONSchemaType<IImpressions> = {
  type: 'object',
  required: ['event', 'ecommerce'],
  properties: {
    event: { type: 'string', const: 'impressions' },
    ecommerce: {
      type: 'object',
      required: ['impressions'],
      properties: {
        currencyCode: { type: 'string', const: 'EUR', nonEmpty: false },
        cartId: { type: 'integer' },
        impressions: {
          type: 'array',
          minItems: 1,
          items: ProductInteractionSchema
        }
      }
    }
  }
}

export const NotifySchema: JSONSchemaType<INotifyMe> = {
  type: 'object',
  required: ['event', 'productAvailability', 'productName'],
  properties: {
    event: { type: 'string' },
    productAvailability: { type: 'string' },
    productName: { type: 'string' },
  }
}