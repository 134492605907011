



























import {
  defineComponent, inject,
} from '@vue/composition-api'

import treeDefaultPin from '@f/components/Map/graphics/tree-nursery-pin.png'
import treePin from '@f/components/Map/graphics/tree-pin.png'
import useMarker from '@f/components/Map/Markers/useMarker'
import { MAP_INSTANCE } from '@f/services/Map'

export default defineComponent({
  name: 'TreeMarker',
  props: {
    id: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    lat: {
      type: Number,
      required: true,
    },
    lng: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const map = inject(MAP_INSTANCE)
    const root = useMarker(() => [props.lng, props.lat], map?.value!)
    const onMarkerClick = () => {
      emit("marker-click", props)
    }
    return {
      root,
      treeDefaultPin,
      treePin,
      onMarkerClick,
    }
  },
})
