import { Vue, Component, Prop } from 'vue-property-decorator'
import { PageScrollManager, PageScrollOptions } from '@f/utils/dom/scroll'

@Component({
  name: 'CanSmoothlyScroll'
})
export default class CanSmoothlyScroll extends Vue {
  /*--- PROPS ---------*/
  @Prop({ type: [String, HTMLElement], default: '#navbar' }) readonly offsetElement!: string | HTMLElement
  @Prop({ type: [String, HTMLElement], required: false }) readonly scrollElement!: string | HTMLElement | undefined

  /*--- DATA ----------*/
  pageScrollManager: PageScrollManager | null = null

  /*--- EVENTS --------*/
  created() {
    this.pageScrollManager = this.pageScrollManager || this.newPageScrollManagerInstance()
  }

  /*--- METHODS -------*/
  newPageScrollManagerInstance(): PageScrollManager {
    const pageScrollOptions = {
      getOffsetTop: this.getOffsetTop,
      getScrollElement: this.getScrollElement
    }
    return new PageScrollManager(pageScrollOptions)
  }

  getOffsetTop(): number {
    const element = typeof this.offsetElement === 'string'
      ? document.querySelector(this.offsetElement)
      : this.offsetElement
    return element?.clientHeight || 0
  }

  getScrollElement(): HTMLElement | void {
    return !this.scrollElement ? undefined
      : typeof this.scrollElement === 'string'
        ? document.querySelector(this.scrollElement) as HTMLElement || undefined
        : this.scrollElement as HTMLElement
  }

  scrollToTarget(target?: string | HTMLElement, smooth = true): void {
    this.$nextTick(() => {
      this.pageScrollManager?.scroll({ target, smooth })
    })
  }

  scrollTop(smooth = true): void {
    this.scrollToTarget(undefined, smooth)
  }
}
