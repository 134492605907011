







import { VNode } from 'vue'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class StepperStep extends Vue {

  name: string = 'StepperStep'

  @Prop({ type: Number, required: true }) readonly index!: number

}
