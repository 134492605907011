import { JSONSchemaType } from 'ajv'
import {
  IVideo,
  IDownload,
  ISocialButton,
  IFilterSelection,
  IDLFilterCategory,
  IFilterSubcategory,
  ITreeRegistryInteraction,
  ITreeRegistrySelection,
  ITreeNameConfirmed,
  ITreeDiaryInteraction,
  IToggleFollow,
  IOpenNotification,
  IBoxInfo,
  IMapInteraction,
  IForm,
} from '@f/validation/types/dataLayer'

export const SocialButtonSchema: JSONSchemaType<ISocialButton> = {
  type: 'object',
  required: ['event', 'socialType', 'socialAction'],
  properties: {
    event: { type: 'string'},
    socialType: { type: 'string' },
    socialAction: { type: 'string' },
  }
}


const filterCategoyItem: JSONSchemaType<IDLFilterCategory> = {
  type: 'string',
}

const filterSubCategoyItem: JSONSchemaType<IFilterSubcategory> = {
  type: 'object',
  required: ['label', 'value', 'filter'],
  properties: {
    label: { type: 'string' },
    value: { type: ['number', 'string'] },
    filter: { type: 'string' },
  }
}

export const FilterSelectionSchema: JSONSchemaType<IFilterSelection> = {
  type: 'object',
  required: ['event', 'filterCategory', 'filterSubCategory'],
  properties: {
    event: { type: 'string' },
    filterCategory: {type: 'string'},
    filterSubCategory: { type: 'string' },
  }
}

export const DownloadSchema: JSONSchemaType<IDownload> = {
  type: 'object',
  required: ['event', 'downloadType', 'docName','pageUrl'],
  properties: {
    event: { type: 'string' },
    downloadType: { type: 'string' },
    docName: { type: 'string' },
    pageUrl: { type: 'string' },
  }
}

export const TreeRegistryInteractionSchema: JSONSchemaType<ITreeRegistryInteraction> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: { type: 'string' },
    treeId: { type: 'string', nullable: true },
    country: { type: 'string', nullable: true },
    userId: { type: 'number', nullable: true },
  }
}


export const TreeRegistrySelectionSchema: JSONSchemaType<ITreeRegistrySelection> = {
  type: 'object',
  required: ['event', 'treeId', 'tree', 'userId'],
  properties: {
    event: { type: 'string' },
    treeId: { type: 'string' },
    tree: { type: 'string' },
    userId: { type: 'number' },
  }
}


export const TreeNameConfirmedSchema: JSONSchemaType<ITreeNameConfirmed> = {
  type: 'object',
  required: ['event', 'nameConfirmed'],
  properties: {
    event: { type: 'string' },
    nameConfirmed: { type: 'string' }
  }
}

export const TreeDiaryInteractionSchema: JSONSchemaType<ITreeDiaryInteraction> = {
  type: 'object',
  required: ['event', 'content', 'action', 'element'],
  properties: {
    event: { type: 'string' },
    content: { type: 'string' },
    action: { type: 'string', nullable: true },
    element: { type: 'string', nullable: true }
  }
}


export const ToggleFollowSchema: JSONSchemaType<IToggleFollow> = {
  type: 'object',
  required: ['event', 'position', 'elementSelected'],
  properties: {
    event: { type: 'string' },
    position: { type: 'string' },
    elementSelected: { type: 'string' },
  }
}

export const OpenNotificationSchema: JSONSchemaType<IOpenNotification> = {
  type: 'object',
  required: ['event', 'notificationType', 'notificationId'],
  properties: {
    event: { type: 'string' },
    notificationType: { type: 'string' },
    notificationId: { type: 'number' }
  }
}

export const BoxInfoSchema: JSONSchemaType<IBoxInfo> = {
  type: 'object',
  required: ['event', 'infoLabel', 'pageUrl'],
  properties: {
    event: { type: 'string' },
    infoLabel: { type: 'string' },
    pageUrl: { type: 'string'}
  }
}

export const VideoSchema: JSONSchemaType<IVideo> = {
  type: 'object',
  required: ['event', 'videoUrl'],
  properties: {
    event: { type: 'string', const: 'video' },
    videoUrl: { type: 'string' }
  }
}
export const MapInteractionSchema: JSONSchemaType<IMapInteraction> = {
  type: 'object',
  required: ['event'],
  properties: { 
    event: { type: 'string', const: 'map' },
    countrySelected: { type: 'string', nullable: true },
    treeSelected: { type: 'string', nullable: true },
    forestSelected: { type: 'string', nullable: true },
  },
  additionalProperties: true
}

export const FormSchema: JSONSchemaType<IForm> = {
  type: 'object',
  required: ['event', 'formType', 'location'],
  properties: {
    event: { type: 'string', const: 'form' },
    formType: { type: 'string' },
    location: { type: 'string' }
  }
}