import { computed, onMounted, ref } from '@vue/composition-api'
import moment from 'moment'
import { TrForecastTreeEnhanced } from '@f/@types/graphql'

function resolveWeather(forecast?: TrForecastTreeEnhanced) {
  let rain: string | boolean = false
  let clouds: string | boolean = false
  if (!forecast) return { rain, clouds }
  switch (forecast.icon) {
    case 'clear-day':
    case 'clear-night':
    case 'windy':
      rain = false
      clouds = false
      break
    case 'fog':
    case 'cloudy':
    case 'partly-cloudy-day':
    case 'partly-cloudy-night':
      rain = false
      clouds = 'light'
      break
    case 'rain':
    case 'snow':
    case 'sleet':
    case 'hail':
    case 'tornado':
    case 'thunderstorm':
      clouds = 'strong'
      if (typeof forecast.precipIntensity !== 'number') {
        rain = 'normal'
      } else if (forecast.precipIntensity < 0.1) {
        rain = 'light'
      } else if (forecast.precipIntensity > 1) {
        rain = 'strong'
      } else {
        rain = 'normal'
      }
      break
    default:
      rain = false
      clouds = false
  }
  return { rain, clouds }
}

export default function showWeather(treeForecast?: TrForecastTreeEnhanced) {
  const forecast = ref(treeForecast)
  const rain = ref<string | boolean>(false)
  const clouds = ref<string | boolean>(false)
  
  const time = computed(() => {
    if (forecast.value?.time && forecast.value?.timezone) {
      return moment(forecast.value.time)
        .tz(forecast.value.timezone)
        .format('HH:mm')
    } else {
      return '—:—'
    }
  })

  const icon = computed(() => {
    switch (forecast?.value?.icon) {
    case 'fog':
      return 'cloudy'
    case 'sleet':
      return 'snow'
    case 'hail':
    case 'tornado':
    case 'thunderstorm':
      return 'rain'
    default:
      return forecast.value?.icon
    }
  })

  const temperature = computed(() => {
    return {
        fahrenheit: !!forecast.value?.temperature && `${Math.round(forecast.value.temperature?.fahrenheit!)} °F` || 'N/D',
        celsius: !!forecast.value?.temperature && `${Math.round(forecast.value.temperature?.celsius!)} °C` || 'N/D',
      }
  })

  onMounted(() => {
    const weather = resolveWeather(forecast.value)
    rain.value = weather.rain
    clouds.value = weather.clouds
  })

  return {
    clouds,
    icon,
    rain,
    temperature,
    time,
  }
}
