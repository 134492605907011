
































import {computed, defineComponent, PropType, ref} from '@vue/composition-api'
import Modal from '@f/components/Modal.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import BaseInput from '@f/components/Form/BaseInput.vue'
import {TrTree} from '@f/@types/graphql'
import {useEditTreeMessages} from '@f/composables/useEditTree'
import useAuthentication from '@f/composables/useAuthentication'
import useModel from '@f/composables/useModel'
import {baseStripTag, replaceHtmlAsciiCodes} from "@f/utils";

export default defineComponent({
  name: 'TreeEditMessageModal',
  components: { BaseInput, Treecon, DotLoader, Modal },
  props: {
    tree: Object as PropType<TrTree>,
    value: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const open = useModel(props, emit)
    const {
      loading: saving,
      message,
      saveMessage: updateMessage,
    } = useEditTreeMessages(props.tree)
    const { isLoggedAs } = useAuthentication()
    const isMessageMine = computed(() => isLoggedAs(message.value?.user!))
    const messageText = ref(message.value?.message || '')
    const isPrivate = ref(false)
    const saveMessage = () => {
      const messageId = isMessageMine.value ? message.value?.id : undefined
      messageText.value=baseStripTag(replaceHtmlAsciiCodes(messageText.value))
      updateMessage(messageText.value, messageId, isPrivate.value)
        .finally(() => {
          open.value = false;
          emit('savedComment')
        })
    }
    return {
      open,
      saving,
      message,
      updateMessage,
      messageText,
      isPrivate,
      saveMessage,
    }
  },
})

