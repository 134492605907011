import { fromEvent, Observable, animationFrameScheduler } from 'rxjs'
import { share, throttleTime, map } from 'rxjs/operators'
import { onUnmounted } from '@vue/composition-api'

type ResizeHookOptions = {
  throttle: number
}

export type Size = {
  width: number
  height: number
}

let _resize$: Observable<Size>

export function onResize(callback: (ev: Size) => void, options?: ResizeHookOptions) {
  const resize$ = _resize$
    || (_resize$ = fromEvent<UIEvent>(window, 'resize').pipe(
        map(({ target }) => {
          return {
            width: (target as Window).innerWidth,
            height: (target as Window).innerHeight,
          }
        }),
        share()
      ))
  const resizeSubscription = resize$.pipe(throttleTime(options?.throttle || 0, animationFrameScheduler, { leading: true, trailing: true })).subscribe(callback)

  onUnmounted(() => {
    resizeSubscription?.unsubscribe()
  })
}
