import {computed, ref,} from '@vue/composition-api'
import { useMutation } from '@vue/apollo-composable'
import { TREE_MESSAGE_ADD, TREE_MESSAGE_UPDATE, TREE_NAME_UPDATE_NEXT } from '@f/graphql/mutations'
import {ApolloCache} from '@apollo/client/cache'
import gql from 'graphql-tag'
import {TrTree, TrTreeMessage} from '@f/@types/graphql'
import {TREE_MESSAGE_FRAGMENT} from '@f/graphql/fragments'
import {baseStripTag, replaceHtmlAsciiCodes} from "@f/utils";
import treedomNextClient from '@f/apollo/clients/api/treedom-next'
import { apiClient } from '@f/apollo'

export const useEditTreeName = (tree?: TrTree) => {
  const name = ref(tree?.name || '')
  const updateTreeCache = () => {

    apiClient.cache.writeFragment({
      id: apiClient.cache.identify(tree || {}),
      fragment: gql`
          fragment CachedTree on Tree {
              name
          }
      `,
      data: { name: name.value },
    })
  }

  const updateNameNext = async() => {
   await treedomNextClient.mutate({
      mutation: TREE_NAME_UPDATE_NEXT,
      variables: {
        treeId: tree?.id,
        name: name.value,
      },
      update: updateTreeCache,
    })
  }

  return {
    name,
    updateNameNext
  }
}

export const useEditTreeMessages = (tree?: TrTree) => {
  const visibleMessage = (messages?: (TrTreeMessage | null)[] | null) => {
    if (messages?.length) {
      return [...messages]
          ?.sort((a, b) => b?.updatedOn.localeCompare(a?.updatedOn))
          ?.[0]
    }
    return null
  }
  const updateTreeMessagesCache = (cache: ApolloCache<any>, { data: { messages } }: any) => {
    return cache.writeFragment({
      id: cache.identify(tree || {}),
      fragmentName: 'TreeMessagesCache',
      fragment: gql`
          ${TREE_MESSAGE_FRAGMENT}
          
          fragment TreeMessagesCache on Tree {
            messages {
                ...TreeMessageFragment
            }
          }
      `,
      data: { messages },
      overwrite: true,
      broadcast: true,
    })
  }
  const message = ref(visibleMessage(tree?.messages ? tree?.messages?.map(trMessage => {
        return {
          ...trMessage,
          message: baseStripTag(replaceHtmlAsciiCodes(trMessage!.message)),
        } as TrTreeMessage
      })
    : []
  ))
  const { loading: adding, mutate: addMessage } = useMutation(
    TREE_MESSAGE_ADD,
    {
      update: updateTreeMessagesCache,
      awaitRefetchQueries: true,
    },
  )
  const { loading: updating, mutate: updateMessage } = useMutation(
    TREE_MESSAGE_UPDATE,
    {
      update: updateTreeMessagesCache,
      awaitRefetchQueries: true,
    },
  )
  const loading = computed(() => adding.value || updating.value)
  const saveMessage = (text: string, id?: number, isPrivate: boolean = false) => {
    const data = {
      message: baseStripTag(replaceHtmlAsciiCodes(text)),
      isPrivate,
    }
    return !!id
      ? updateMessage({ message: { ...data, id } })
      : addMessage({ message: { ...data, treeId: tree?.treeId } })
  }

  return {
    loading,
    message,
    saveMessage,
    visibleMessage
  }
}
