





























import Treecon from "@f/components/Treecons/Treecon.vue";
import { maxWidth, useMediaQuery } from "@f/utils/breakpoints";
import { defineComponent } from "@vue/composition-api";
import CommonCardGame from "../../Components/CommonCardGame.vue";
import CommonHeaderGame from "../../Layout/CommonHeaderGame.vue";

export default defineComponent({
  name: "GameStarter",
  components: {
    CommonHeaderGame,
    CommonCardGame,
    Treecon,
  },
  props: {
    companyName: {type: String, required: true},
    christmasSkin: {type: Boolean, default: false}
  },
  emits: ["startGame"],
  setup(_, {emit}) {
    const startGame = () => emit("startGame");
    const isMobile = useMediaQuery(maxWidth("md"));

    return {
      startGame,
      isMobile
    };
  },


});
