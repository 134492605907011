<template>
  <div class="unpublished-box">
    <Alert state="info">
      <p>
        {{ $t('forest.unpublished') }}
      </p>
      <StatefullButton
        @click="$emit('edit')"
        :label="$t('forest.unpublished.cta')"
        baseClass="tr-button--info"
      />
    </Alert>
  </div>
</template>

<script>
import StatefullButton from '@f/components/StatefullButton.vue'
import Alert from '@f/components/Alert.vue'

export default {
  name: 'UnpublishedBox',
  components: {
    StatefullButton,
    Alert,
  },
}
</script>

<style scoped lang="scss">
@import '@f/scss/design-system/_variables';

.statefull-button.statefull-button--fluid {
  margin: 0;
  width: auto;
}

@media (max-width: $ntds-width-small) {
  .unpublished-box {
    margin: 10px 30px 25px 30px;
  }
}
</style>