import moment from 'moment-timezone'
import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
.controller('projectHeaderController', ['$rootScope', '$interval', '$stateParams', function ($rootScope, $interval, $stateParams) {
  /**
   * Find acrive projects by param :slug
   * @param {Object} projects - { haiti: {}, italy: {}, cameroon: {}, ... }
   */
  var getActiveProject = function (projects) {
    var activeProject = projects.filter(project => project.slug === $stateParams.slug)[0];

    // Numero dei progetti in questo paese.
    activeProject.projectsNum = Object.keys(activeProject.projects).length;

    // Booleano, se tutti i progetti in questo paese sono chiusi
    var totOriginatorsClosed = Object.keys(activeProject.projects)
      .reduce((result, originatorSlug) => activeProject.projects[originatorSlug].concluded ? result + 1 : result, 0);
    activeProject.closed = totOriginatorsClosed >= activeProject.projectsNum;

    // Numero totale degli originatori.
    activeProject.totalOriginators = parseInt(activeProject.totalTrees / 17);

    return activeProject
  };

  var ctrl = this;
  var SunCalc = window.SunCalc;

  ctrl.projects = $rootScope.projects;
  ctrl.project = getActiveProject(ctrl.projects);
  ctrl.country = null;
  ctrl.time = null;
  ctrl.startNightTime = null;
  ctrl.startSunriseTime = null;
  ctrl.startDayTime = null;
  ctrl.startSunsetTime = null;
  ctrl.moonPhase = null;

  // Passing project to this controller.
  ctrl.initWeather = function (project) {
    ctrl.country = project;
    elaborateWeather(project.timezone)
  };

  ctrl.getMomentOfDay = function () {
    return getMomentOfDay()
  };

  /**
   * Elaborate weather data.
   * @param {Object} weather - Darksky data object (https://darksky.net/dev/docs)
   */
  var elaborateWeather = function (timezone) {
    // Update time
    var date = new Date();
    var timestamp = date.getTime();
    ctrl.time = moment(timestamp).tz(timezone);

    // Sunrise/sunset
    var times = SunCalc.getTimes(ctrl.time, ctrl.country.lat, ctrl.country.lng);
    ctrl.startSunriseTime = moment(times.sunrise).tz(timezone);
    ctrl.startSunsetTime = moment(times.sunset).tz(timezone);
    ctrl.startNightTime = moment(ctrl.startSunsetTime).add(1, 'hours');
    ctrl.startDayTime = moment(ctrl.startSunriseTime).add(1, 'hours');

    // Moon phases
    var moonIllumination = SunCalc.getMoonIllumination(ctrl.time);
    var moonPhase = Number(moonIllumination.phase.toFixed(3));

    if (moonPhase >= 0 && moonPhase < 0.125) {
      ctrl.moonPhase = 'newMoon'
    } else if (moonPhase >= 0.125 && moonPhase < 0.25) {
      ctrl.moonPhase = 'waxingCrescent'
    } else if (moonPhase >= 0.25 && moonPhase < 0.375) {
      ctrl.moonPhase = 'firstQuarter'
    } else if (moonPhase >= 0.375 && moonPhase < 0.5) {
      ctrl.moonPhase = 'waxingGibbous'
    } else if (moonPhase >= 0.5 && moonPhase < 0.625) {
      ctrl.moonPhase = 'fullMoon'
    } else if (moonPhase >= 0.625 && moonPhase < 0.75) {
      ctrl.moonPhase = 'waningGibbous'
    } else if (moonPhase >= 0.75 && moonPhase < 0.875) {
      ctrl.moonPhase = 'lastQuarter'
    } else {
      ctrl.moonPhase = 'waningCrescent'
    }

    renderElements()
  };

  /**
   * Render/move DOM Elements.
   */
  var renderElements = function () {
    changeVariablesDay();

    // Sky
    var sky = document.getElementById('sky');
    if (!sky) return;
    var skyHeight = sky.offsetHeight;
    var skySection = skyHeight / 4;
    var startNightPx = skySection * 0;
    var startSunrisePx = skySection * 1;
    var startDayPx = skySection * 2;
    var startSunsetPx = skySection * 3;

    // Stars
    var stars = document.getElementById('stars');
    var opacity;

    // Sun
    var sunCircle = document.getElementById('sun');
    sunCircle.style.left = 'calc(50% - ' + (sunCircle.clientWidth / 2) + 'px)';

    // Moon
    var moonCircle = document.getElementById('moon');
    var moonImage = document.getElementById('moonImage');
    moonCircle.style.left = 'calc(50% - ' + (moonCircle.clientWidth / 2) + 'px)';

    // Variables to elaborate weather stats.
    var translate;
    var hoursDurationSection;
    var pxForHour;
    var pxForMinutes;
    var minutesDifference;
    var savedDegMoon;

    // Sky
    switch (getMomentOfDay()) {
      case 'night':
        translate = startNightPx;
        sky.style.transform = 'translate3d(0px, -' + translate + 'px, 0px)';
        stars.style.opacity = '1';
        sunCircle.style.transform = 'translate3d(0px, 0px, 0px)';
        break;
      case 'sunrise':
        hoursDurationSection = Math.abs(ctrl.startSunriseTime.diff(ctrl.startDayTime, 'hours'));
        pxForHour = (skySection * 2) / hoursDurationSection;
        pxForMinutes = pxForHour / 60;

        minutesDifference = Math.abs(ctrl.startSunriseTime.diff(ctrl.time, 'minutes'));
        translate = (startSunrisePx - skySection) + (pxForMinutes * minutesDifference);

        sky.style.transform = 'translate3d(0px, -' + translate + 'px, 0px)';

        opacity = 1 - ((1 / (startSunrisePx + skySection)) * translate);
        stars.style.opacity = opacity + '';
        break;
      case 'day':
        translate = startDayPx;
        sky.style.transform = 'translate3d(0px, -' + translate + 'px, 0px)';
        stars.style.opacity = '0';
        moonCircle.style.transform = 'translate3d(0px, 0px, 0px)';
        break;
      case 'sunset':
        hoursDurationSection = Math.abs(ctrl.startSunsetTime.diff(ctrl.startNightTime, 'hours'));
        pxForHour = (skySection * 2) / hoursDurationSection;
        pxForMinutes = pxForHour / 60;

        minutesDifference = Math.abs(ctrl.startSunsetTime.diff(ctrl.time, 'minutes'));
        translate = (startSunsetPx - skySection) + (pxForMinutes * minutesDifference);

        sky.style.transform = 'translate3d(0px, -' + translate + 'px, 0px)';

        opacity = (1 / ((startSunsetPx + skySection) - (startSunsetPx - skySection))) * translate;
        stars.style.opacity = (opacity - 1) + '';
        break
    }

    // Sun
    var minutesOfSun = minutesOfDay(ctrl.startSunsetTime) - minutesOfDay(ctrl.startSunriseTime);
    var degSun = 185 / minutesOfSun;
    if (minutesOfDay(ctrl.startSunriseTime) < minutesOfDay(ctrl.time)) {
      var differenceSun = minutesOfDay(ctrl.time) - minutesOfDay(ctrl.startSunriseTime);
      sunCircle.style.transform = 'rotate(' + (differenceSun * degSun) + 'deg)'
    }

    // Moon
    var minutesOfMoon = minutesOfDay(ctrl.startSunsetTime) - minutesOfDay(ctrl.startSunriseTime);
    var pxMoon = 230 / minutesOfMoon;
    var differenceMoon;
    if (minutesOfDay(ctrl.startNightTime) < minutesOfDay(ctrl.time) || minutesOfDay(ctrl.startSunsetTime) > minutesOfDay(ctrl.time)) {
      if (minutesOfDay(ctrl.startNightTime) < minutesOfDay(ctrl.time)) {
        differenceMoon = minutesOfDay(ctrl.time) - minutesOfDay(ctrl.startNightTime);
        savedDegMoon = differenceMoon
      }

      if (minutesOfDay(ctrl.startSunsetTime) > minutesOfDay(ctrl.time)) {
        savedDegMoon = isUndefined(savedDegMoon)
          ? minutesOfDay(moment(ctrl.startNightTime).hours(23).minutes(59))
          : savedDegMoon;
        differenceMoon = savedDegMoon + minutesOfDay(ctrl.time)
      }

      var rotate = differenceMoon * pxMoon;
      if (getMomentOfDay() === 'day') rotate = '0';
      moonCircle.style.transform = 'rotate(' + rotate + 'deg)';
      moonImage.style.transform = 'rotate(-' + (rotate + 180) + 'deg)'
    }
  };

  // Cambia le date mantenendo invariati gli orari di alba, giorno, tramonto e notte.
  var changeVariablesDay = function () {
    if (!ctrl.startSunsetTime || !ctrl.startNightTime ||
    !ctrl.startSunsetTime || !ctrl.startDayTime) return;

    var sunsetHour = ctrl.startSunsetTime.hour();
    var sunsetMinutes = ctrl.startSunsetTime.minutes();
    var sunsetSeconds = ctrl.startSunsetTime.seconds();
    ctrl.startSunsetTime = moment(ctrl.time).hour(sunsetHour).minutes(sunsetMinutes).seconds(sunsetSeconds);

    var nightHour = ctrl.startNightTime.hour();
    var nightMinutes = ctrl.startNightTime.minutes();
    var nightSeconds = ctrl.startNightTime.seconds();
    ctrl.startNightTime = moment(ctrl.time).hour(nightHour).minutes(nightMinutes).seconds(nightSeconds);

    var sunriseHour = ctrl.startSunriseTime.hour();
    var sunriseMinutes = ctrl.startSunriseTime.minutes();
    var sunriseSeconds = ctrl.startSunriseTime.seconds();
    ctrl.startSunriseTime = moment(ctrl.time).hour(sunriseHour).minutes(sunriseMinutes).seconds(sunriseSeconds);

    var dayHour = ctrl.startDayTime.hour();
    var dayMinutes = ctrl.startDayTime.minutes();
    var daySeconds = ctrl.startDayTime.seconds();
    ctrl.startDayTime = moment(ctrl.time).hour(dayHour).minutes(dayMinutes).seconds(daySeconds)
  };

  /**
   * Trova il momento del giorno
   * @return {string} - ['night', 'sunrise', 'day', 'sunset', 'error']
   */
  var getMomentOfDay = function () {
    if (minutesOfDay(ctrl.time) >= minutesOfDay(ctrl.startNightTime) || minutesOfDay(ctrl.time) < minutesOfDay(ctrl.startSunriseTime)) {
      return 'night'
    } else if (minutesOfDay(ctrl.time) >= minutesOfDay(ctrl.startSunriseTime) && minutesOfDay(ctrl.time) < minutesOfDay(ctrl.startDayTime)) {
      return 'sunrise'
    } else if (minutesOfDay(ctrl.time) >= minutesOfDay(ctrl.startDayTime) && minutesOfDay(ctrl.time) < minutesOfDay(ctrl.startSunsetTime)) {
      return 'day'
    } else if (minutesOfDay(ctrl.time) >= minutesOfDay(ctrl.startSunsetTime) && minutesOfDay(ctrl.time) < minutesOfDay(ctrl.startNightTime)) {
      return 'sunset'
    } else {
      return 'error'
    }
  };

  /**
   * Trasforma una data per la comparazione
   * @param {Object} time - Data formato moment
   */
  var minutesOfDay = function (time) {
    return time.minutes() + time.hours() * 60
  };

  // Aggiunge un minuto all'orario attuale.
  var addOneMinute = function () {
    if (!ctrl.time) return;
    ctrl.time.add(1, 'minutes');
    renderElements()
  };

  // Interval, every minute, change DOM elements position.
  var interval;
  if (!interval) interval = $interval(addOneMinute, 60000);

  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, scope) {
    if (fromState.name !== toState.name) {
      $interval.cancel(interval);
      window.removeEventListener('resize', renderElements)
    }

    if (fromState.name === toState.name) {
      if (fromParams.slug && toParams.slug) {
        if (fromParams.slug !== toParams.slug) {
          $interval.cancel(interval);
          window.removeEventListener('resize', renderElements)
        }
      }
    }
  });

  // Watcher on variables $rootScope.projects
  $rootScope.$watch('projects', function (projects) {
    ctrl.projects = projects;
    ctrl.project = getActiveProject(ctrl.projects)
  });

  window.addEventListener('resize', renderElements)
}])
