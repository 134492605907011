import { JSONSchemaType } from 'ajv'
import { IStartGame, IGameQuestion1, IGameQuestion2, IGameQuestion3, IGameQuestion4, IGameQuestion5, IGameCopiedCode, IGameSentGift, IGameCtaUnderstand } from '@f/validation/types/dataLayer'

export const StartGame: JSONSchemaType<IStartGame> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {
      type: 'string'
    }
  }
}
export const GameQuestion1: JSONSchemaType<IGameQuestion1> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {
      type: 'string'
    }
  }
}
export const GameQuestion2: JSONSchemaType<IGameQuestion2> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {
      type: 'string'
    }
  }
}
export const GameQuestion3: JSONSchemaType<IGameQuestion3> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {
      type: 'string'
    }
  }
}
export const GameQuestion4: JSONSchemaType<IGameQuestion4> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {
      type: 'string'
    }
  }
}
export const GameQuestion5: JSONSchemaType<IGameQuestion5> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {
      type: 'string'
    }
  }
}
export const GameCopiedCode: JSONSchemaType<IGameCopiedCode> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {
      type: 'string'
    }
  }
}
export const GameSentGift: JSONSchemaType<IGameSentGift> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {
      type: 'string'
    }
  }
}
export const GameCtaUnderstand: JSONSchemaType<IGameCtaUnderstand> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {
      type: 'string'
    }
  }
}

















