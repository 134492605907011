angular.module('ngApp')
  .controller('pageController', ['$scope', '$rootScope', '$state', function ($scope, $rootScope, $state) {
    $scope.currentPage = $state.current.name;
    $scope.currentParams = $state.currentParams;

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      $scope.currentPage = toState.name
      $scope.currentParams = toParams
    })
  }])
