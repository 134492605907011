import { ref, onMounted, nextTick } from '@vue/composition-api'
import { onResize } from '@f/composables/hooks/resize'

export default function fillPage() {
  const container = ref<HTMLElement>()

  const updateHeight = () => {
    nextTick(() => {
      if (!container.value) return
      const root = container.value
      requestAnimationFrame(() => {
        const offsetTop = root.offsetTop + (root.parentElement?.offsetTop || 0)
        root.style.height = `calc( 100vh - ${offsetTop}px )`
      })
    })
  }

  onMounted(updateHeight)
  onResize(updateHeight)

  return {
    container
  }
}
