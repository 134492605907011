/* @ngInject */
export default function productCardController($rootScope) {
  this.product = {}
  this.imageBackground = {}
  this.name = ''
  this.country = ''

  this.$onInit = () => {
    // Set icon
    const icon = (this.product.limited || this.product.specie).icon.medium
    this.imageBackground['backgroundImage'] = `url(${icon})`
    // Set name
    this.name = this.product.limited && this.product.limited.limitedName[$rootScope.ln] != 'Limited Edition' ? this.product.limited.limitedName[$rootScope.ln] : this.product.specie.name[$rootScope.ln]
    // Set country
    this.country = this.product.specie && this.product.specie.country.name ? this.product.specie.country.name[$rootScope.ln] : ''
    // Set meaning
    this.meaning = this.product.specie.sayTitle && this.product.specie.sayTitle[$rootScope.ln] || ''
    // Set styles
    this.isPreview = this.template === 'preview'
    this.wrapClass = this.isPreview ? 'treeWrapPreview' : 'treeWrap'
    // Set prices and discount
    this.onSale = this.product.badge === 'discount'
    this.price = this.product.price / 100
    this.fullPrice = this.getFullPrice()
  }

  this.getFullPrice = () => {
    if (!(this.onSale && this.product.badgeDiscount)) return this.price
    return this.price * (1 + (this.product.badgeDiscount / 100))
  }

  this.onClickCallback = () => {
    this.onClick({ product: this.product })
  }
}
