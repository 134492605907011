import * as RansomSchemas from '@f/validation/schema/datalayer/ransom'
import ajv from '@f/validation/ajv'
import {
  IRansomChoice,
  IRansomOpen,
  IRansomRedeem,
  IRansomStart,
  IRansomStartConfirmed
} from '@f/validation/types/dataLayer'
import { RansomStartConfirmedSchema } from '@f/validation/schema/datalayer/ransom'

export const validateRansomStart = ajv.compile<IRansomStart>(RansomSchemas.RansomStartSchema)
export const validateRansomStartConfirmed = ajv.compile<IRansomStartConfirmed>(RansomSchemas.RansomStartConfirmedSchema)
export const validateRansomOpen = ajv.compile<IRansomOpen>(RansomSchemas.RansomOpenSchema)
export const validateRansomChoice = ajv.compile<IRansomChoice>(RansomSchemas.RansomChoiceSchema)
export const validateRansomRedeem = ajv.compile<IRansomRedeem>(RansomSchemas.RansomRedeemSchema)