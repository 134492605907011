



















import {defineComponent} from "@vue/composition-api";

export default defineComponent({
  name: "CommonHeaderGame",
  props: {
    christmasSkin: {type: Boolean, default: false}
  },
});
