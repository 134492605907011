import { Component, Mixins, Prop } from 'vue-property-decorator'
import FetchStaticData from '@f/mixins/FetchStaticData'
import { IsoCode } from '@f/@types/localization'

export interface VideoStaticData {
  video: Record<IsoCode, string>
}

@Component({
  name: 'ShowIntroVideo'
})
export default class ShowIntroVideo<T extends VideoStaticData> extends Mixins(FetchStaticData) {
  /*--- PROPS ---------*/
  @Prop({ type: Boolean, default: true }) readonly fetchOnMount!: boolean

  /*--- DATA ----------*/
  staticDataPath = '/data/homepage_data.json'
  localDataPathSuffix = 'bundles/treedomnewfrontend/json'
  staticData: Partial<T> = {}

  /*--- COMPUTED ------*/
  get videoIntroId(): string {
    return this.staticData.video?.[this.$rootScope.ln as IsoCode] || ''
  }

  /*--- EVENTS --------*/
  mounted() {
    if (this.fetchOnMount) this.fetchStaticData()
  }

  /*--- METHODS -------*/
  fetchStaticData() {
    this.fetchData<T>(this.staticDataPath, this.localDataPathSuffix)
        .then(response => { this.staticData = response || {} })
        .catch(() => { this.staticData = {} })
  }

}
