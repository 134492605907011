import {
  defineComponent,
  PropType,
  computed,
} from '@vue/composition-api'

import { Map as Mapbox, Marker } from 'mapbox-gl'
import { Feature, Point } from 'geojson'
import ecommerceClusterPin from '@f/components/Map/graphics/ecommerce-cluster-pin.png'
import treeClusterPin from '@f/components/Map/graphics/tree-cluster-pin.png'
import useMarker from '@f/components/Map/Markers/useMarker'

const getCountValue = (count: number) => count > 99 ? '99+' : count.toString()

export type PinVariant = 'default' | 'ecommerce'
const getClusterPinImage = (variant?: PinVariant) => {
  if (variant === 'ecommerce') return ecommerceClusterPin
  return treeClusterPin
}

type Properties = {
  point_count: number,
  variants?: string,
  icons?: string,
}

export default defineComponent({
  name: 'ClusterMarker',
  props: {
    map: {
      type: Object as PropType<Mapbox>,
      required: true,
    },
    feature: {
      type: Object as PropType<Feature<Point, Properties>>,
      required: true,
    }
  },
  setup(props, { emit }) {
    const root = useMarker(() => props.feature.geometry.coordinates, props.map)
    const icons = computed(() => props.feature.properties.icons?.split(',')?.filter(Boolean))
    const variant = computed<PinVariant | undefined>(() => props.feature.properties.variants?.split(',')[0] as PinVariant)
    const count = computed(() => props.feature.properties.point_count)
    const onMarkerClick = () => {
      emit("marker-click", props.feature)
    }
    return {
      root,
      getClusterPinImage,
      getCountValue,
      icons,
      variant,
      count,
      onMarkerClick,
    }
  },
  template: `
    <div style='display: none' @click.prevent>
      <div class='ntds-cluster-pin' ref='root' @click='onMarkerClick'>
        <div class='ntds-cluster-pin-container'>
          <img
            alt='cluster'
            :src="getClusterPinImage(variant)"
            class='ntds-cluster-pin-icon'
          />
          <template v-if='variant === "ecommerce"'>
            <div class="ntds-cluster-pin-icon-tiles">
              <img
                v-for='icon in icons'
                alt='flag'
                :src='icon'
              />
            </div>
          </template>
          <span
            class='ntds-cluster-pin-badge'
            :class='{
              "ntds-cluster-pin-badge-ecommerce": variant === "ecommerce",
            }'
            v-text='getCountValue(count)'
          />
        </div>
      </div>
    </div>
  `
})