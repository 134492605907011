import { ICartViewed, IPaymentInfo, IPaymentInfoSub } from '@f/validation/types/dataLayer';
import { JSONSchemaType } from 'ajv'
import { IAddToCart, ICheckout, IImpressions, IRemoveFromCart, ITransaction, IVoucherTransaction } from '@f/validation/types/dataLayer'
import { ProductInteractionSchema } from '.';

export const VoucherTransactionSchema: JSONSchemaType<IVoucherTransaction> = {
  type: 'object',
  required: ['event','voucher', 'cartId', 'cartSize', 'cartValue' ],
  properties: {
    event: { type: 'string', const: 'voucherTransaction' },
    voucher: { type: 'string' },
    voucherId: { type: 'integer' },
    cartSize: { type: 'integer' },
    cartValue: { type: 'number' },
    cartId: { type: 'integer' },
  }
}

export const AddToCartSchema: JSONSchemaType<IAddToCart> = {
  type: 'object',
  required: ['event', 'ecommerce'],
  properties: {
    event: { type: 'string', const: 'addToCart' },
    ecommerce: { 
      type: 'object',
      required: ['currencyCode', 'add', 'cartId'],
      properties: {
        currencyCode: { type: 'string', const: 'EUR' },
        cartId: { type: 'integer' },
        add: {
          type: 'object',
          required: ['products'],
          properties: {
            products: { 
              type: 'array',
              minItems: 1, 
              items: ProductInteractionSchema
            }
          }
        }
      }
    }
  }
}

export const RemoveFromCartSchema: JSONSchemaType<IRemoveFromCart> = {
  type: 'object',
  required: ['event', 'ecommerce'],
  properties: {
    event: { type: 'string', const: 'removeFromCart' },
    ecommerce: {
      type: 'object',
      required: ['currencyCode', 'remove', 'cartId'],
      properties: {
        currencyCode: { type: 'string', const: 'EUR' },
        cartId: { type: 'integer' },
        remove: {
          type: 'object',
          required: ['products'],
          properties: {
            products: {
              type: 'array',
              minItems: 1,
              items: ProductInteractionSchema
            }
          }
        }
      }
    }
  }
}

export const CheckoutSchema: JSONSchemaType<ICheckout> = {
  type: 'object',
  required: ['event', 'ecommerce'],
  properties: {
    event: { type: 'string', const: 'checkout' },
    ecommerce: {
      type: 'object',
      required: ['checkout'],
      properties: {
        currencyCode: { type: 'string', const: 'EUR' },
        cartId: { type: 'integer' },
        checkout: {
          type: 'object',
          required: ['products', 'activeField'],
          properties: {
            activeField: {
              type: 'object', 
              required: ['step'],
              properties: {
                step: { type: 'integer', default: 1 }
            }},
            products: {
              type: 'array',
              minItems: 1,
              items: ProductInteractionSchema
            }
          }
        }
      }
    }
  }
}

export const TransactionSchema: JSONSchemaType<ITransaction> = {
  type: 'object',
  required: ['event', 'ecommerce', 'userRegisteredToday'],
  properties: {
    event: { type: 'string', const: 'transaction' },
    userRegisteredToday: {type: 'boolean'},
    ecommerce: {
      type: 'object',
      required: ['purchase', 'cartId', 'currencyCode'],
      properties: {
        currencyCode: { type: 'string', const: 'EUR' },
        cartId: { type: 'integer' },
        totalDiscount: { type: 'number' }, 
        purchase: {
          type: 'object',
          required: ['actionField', 'products'],
          properties: {
            actionField: {
              type: 'object',
              required: ['id', 'revenue'],
              properties: {
                id: { type: ['number', 'string'] },
                revenue: { type: 'number' },
                coupon: { type: 'string' }
              }
            },
            products: {
              type: 'array',
              minItems: 1,
              items: ProductInteractionSchema}
          }
        },
      }
    },
    tshirtFlag:  {type: 'boolean'}
  } 
}

export const CartViewedSchema: JSONSchemaType<ICartViewed> = {
  type: 'object',
  required: ['event', 'cartSize', 'cartValue', 'cartId', 'totalDiscount'],
  properties: {
    event: { type: 'string', const: 'cartViewed' },
    cartSize: { type: 'integer' },
    cartValue: { type: 'number' },
    cartId: { type: 'string', nullable: true},
    totalDiscount: { type: 'number' }
  }
}

export const PaymentInfoSchema: JSONSchemaType<IPaymentInfo> = {
  type: 'object',
  required: ['event', 'cartSize', 'cartValue', 'cartId', 'paymentMethod'],
  properties: {
    event: { type: 'string' },
    cartSize: { type: 'integer' },
    cartValue: { type: 'number' },
    cartId: { type: 'integer'},
    paymentMethod: { type: 'string' },
    totalDiscount: { type: 'number', nullable: true }
  }
}

export const PaymentInfoSchemaSub: JSONSchemaType<IPaymentInfoSub> = {
  type: 'object',
  required: ['event', 'name', 'paymentMethod'],
  properties: {
    event: { type: 'string' },
    name: { type: 'string' },
    paymentMethod: { type: 'string' }
  }
}
