








import { Vue, Component, Prop } from 'vue-property-decorator'
import { TrProduct } from '@f/@types/graphql'

@Component({
  name: 'ProductSimpleCard'
})
export default class ProductSimpleCard extends Vue {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  @Prop({ type: Object, required: true }) readonly product!: TrProduct
  /*--- DATA ----------*/
  
  /*--- COMPUTED ------*/
  get icon() {
    return (this.product.icon?.small || '').replace('dev-', '')
  }

  get name() {
    return this.product.productName && this.$t(this.product.productName)
  }

  get country() {
    return this.$t(this.product.specie?.country?.name || '')
  }
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  
  /*--- METHODS -------*/
  
}
