import { ErrorObject } from 'ajv'

export class ErrorBag {
  errors: ErrorObject[] | null

  constructor(errors?: ErrorObject[] | null) {
    this.errors = errors || null
  }

  first(name: string) {
    return this.errors?.length
      && this.errors.find(this.resolveNamePath.bind(undefined, name))?.message || ''
  }

  clear(name: string) {
    if (this.errors?.length) {
      this.errors = this.errors.filter(error => !this.resolveNamePath(name, error))
    }
  }

  private resolveNamePath(namePath: string, { instancePath }: ErrorObject) {
    return instancePath.replace(/^\//, '').replace('/', '.') === namePath
  }
}
