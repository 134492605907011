import "core-js/stable";
import "regenerator-runtime/runtime";

import './libs';
import configRoutes from './routes.desktop';

import './Provider/ApolloProvider';
import { apiClient, cartClient, wwwClient } from '@f/apollo';
// Bootstrap Vue
import { bootstrapVue } from '@f/vue';
import { isFeatureActive } from "@f/utils";
import { getIubendaService } from '@f/services/Iubenda';
import { validatePrivateProfile } from '@f/validation/auth';
import { appCookies, authConfig, cookieKeys, ShopCurrency } from '@f/configs/app';
import { DataEventSocialButton } from '@f/services/TagManager/events';
import { DataLayerPushDeviceId } from '@f/services/TagManager/variables';
import Cookie from 'js-cookie';
import { TreedomAuth } from '@f/auth';
import { CognitoProvider } from '@f/auth/cognito-provider';
import { initAuthService } from '@f/services/Auth';
import { DataEventOpenGiftFlow } from '@f/services/TagManager/events/gift';
import removeLegacyAuthCookies from '@f/auth/helpers/removeLegacyAuthCookies';
import Cookies from 'js-cookie';

bootstrapVue();

require('./Directive/angular-fosjsrouting.js');
require('./Directive/angular-sanitize.js');
require('./Directive/angular-symfony-translation.js');
require('./Directive/angular-tip.js');
require('./Directive/google-plus-signin.js');
require('./Directive/ngAutocomplete.js');
require('./Directive/renderComponentDirective.js');
require('./Directive/treedom-carousel.js');
require('./Directive/treedom-search.js');
require('./Directive/treedom-swipe.js');
require('./Provider/BroadcastChannel.js');
require('./Provider/GraphQLProvider.js');
require('./Service/alertService.js');
require('./Service/badgeService.js');
require('./Service/blogService.js');
require('./Service/businessService.js');
require('./Service/cartService.js');
require('./Service/countriesService.js');
require('./Service/ecommerceService.js');
require('./Service/emissionService.js');
require('./Service/errorService.js');
require('./Service/eventService.js');
require('./Service/gameService.js');
require('./Service/growthService.js');
require('./Service/mapService.js');
require('./Service/menuMobileService.js');
require('./Service/modalService.js');
require('./Service/overlayLoaderService.js');
require('./Service/ScrollService.ts');
require('./Service/subscriptionCartService.js');
require('./Service/productService.js');
require('./Service/projectService.js');
require('./Service/routingService.js');
require('./Service/searchService.js');
require('./Service/tagManagerService.js');
require('./Service/trackingService.js');
require('./Service/treeService.js');
require('./Service/userService.js');
require('./Service/utilityService.js');

const ngLanguagesImport = {
  it: () => import( /* webpackChunkName: "angular-locale_it" */ '../js-lib/angular-locale_it'),
  en: () => import( /* webpackChunkName: "angular-locale_en" */ '../js-lib/angular-locale_en'),
  fr: () => import( /* webpackChunkName: "angular-locale_fr" */ '../js-lib/angular-locale_fr'),
  de: () => import( /* webpackChunkName: "angular-locale_de" */ '../js-lib/angular-locale_de'),
  es: () => import( /* webpackChunkName: "angular-locale_es" */ '../js-lib/angular-locale_es'),
};

removeLegacyAuthCookies({
  cookieName: authConfig.legacy.cookieName,
});
TreedomAuth.init({
  provider: new CognitoProvider(authConfig.cognito)
});
initAuthService();

var ngApp = angular.module('ngApp', [
  'angularTip', 'chart.js', 'treedomSearch', 'treedomSwipe', 'treedomCarousel',
  'ui.bootstrap', 'fosjsrouting', 'ui.router', /* FIX: UI-Router events polyfill */ 'ui.router.state.events', 'boxuk.translation', 'angularFileUpload', 'directive.g+signin', 'searchService', 'routingService', 'alertService',
  'eventService', 'projectService', 'userService', 'gameService', 'emissionService',
  'utilityService', 'mapService', 'menuMobileService', 'cartService',
  'errorService', 'treeService', 'blogService', 'badgeService', 'leaflet-directive', 'ipCookie',
  'youtube-embed', 'duScroll', 'ui.select', 'ngSanitize', '720kb.socialshare',
  'ngAutocomplete', 'countUpModule', 'modalService', 'rzModule',
  'swipe', 'angularGrid', 'ngCookies', 'renderComponentDirective', 'ecommerceService',
  'productService', 'GraphQL', 'infinite-scroll',
  'angular-svg-round-progressbar', 'BroadcastChannel', 'vAccordion',
  'ngAnimate', 'countriesService', 'businessService', 'angular-apollo', 'subscriptionCartService',
  'scrollServiceProvider', 'overlayLoaderService', 'tagManagerService', 'growthService',
  'trackingService',
])
  .config(['$locationProvider', function ($locationProvider) {
    $locationProvider.html5Mode(true);
  }])
  .config(['$interpolateProvider', function ($interpolateProvider) { // questo primo config serve a cambiare le {{ per non andare in conflitto con twig
    $interpolateProvider.startSymbol('{[{').endSymbol('}]}');
  }])
  .config(['$routingProvider', function ($routingProvider) { // questo serve a instanziare fosjsrouting vedi anche sopra
    $routingProvider.setDefaultParams({});
  }])
  .config(['$urlRouterProvider', function ($urlRouterProvider) { // redirect old 'species' route to 'plant-a-tree'
    $urlRouterProvider
      .when(/^(\/?(.{2})?\/)?species\/?(.*)?/, function ($match, $stateParams) {
        return `plant-a-tree/${$match[3] ?? ''}`;
      });
  }])
  .config(['$compileProvider', function ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|whatsapp|http|tel):/);
  }])
  .config(['$sceDelegateProvider', function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'whatsapp',
      'https://s3-eu-west-1.amazonaws.com/**',
      'https://player.vimeo.com/**',
      'https://vimeo.com/**'
    ]);
  }])
  .config(['$graphProvider', function ($graphProvider) {
    const gqlUri = document.querySelector('html').getAttribute('data-gql-uri');
    $graphProvider.jwtFactory(function () {
      return TreedomAuth.isAuthenticated().then(function (isAuthenticated) {
        if (!isAuthenticated) return null;
        return TreedomAuth.getJwtToken();
      });
    });
    $graphProvider.endpoint(gqlUri);
  }])
  .config(['$uibTooltipProvider', function ($uibTooltipProvider) {
    $uibTooltipProvider.options({
      appendToBody: true,
      placement: 'auto top'
    });
  }])
  .config(['apolloProvider', function (apolloProvider) {
    apolloProvider.setClients({
      default: apiClient,
      cart: cartClient,
      www: wwwClient,
    });
  }])
  .config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push(function () {
      return ({
        request: function (config) {
          return TreedomAuth.isAuthenticated().then(function (isAuthenticated) {
            if (!isAuthenticated) {
              return config;
            }
            return TreedomAuth.getJwtToken().then(function (token) {
              config.headers.Authorization = 'Bearer ' + token;
              return config;
            });
          });
        },
      });
    });
  }])
  .config(['$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider', configRoutes])
  .run([
    '$rootScope',
    '$state',
    '$cookies',
    '$location',
    '$animate',
    '$uiRouterGlobals',
    'User',
    'Cart',
    'Ecommerce',
    'Badge',
    '$filter',
    'OverlayLoader',
    'TagManager',
    'Utility',
    'Modal',
    '$window',
    function (
      $rootScope,
      $state,
      $cookies,
      $location,
      $animate,
      $uiRouterGlobals,
      User,
      Cart,
      Ecommerce,
      Badge,
      $filter,
      OverlayLoader,
      TagManager,
      Utility,
      Modal,
      $window
    ) {

      // prendo la lingua
      $rootScope.ln = document.documentElement.lang;
      $rootScope.baseUrlAssets = document.querySelector('html').getAttribute('data-url');
      $rootScope.availableLanguages = ['it', 'en', 'fr', 'es', 'de'];
      $rootScope.isMobile = window.isMobile;
      $rootScope.static = window.static;
      $rootScope.public_bucket = window.public_bucket;
      $rootScope.userType = window.userType;
      $rootScope.trees = {};
      $rootScope.projects = Object.values(window.treedomProjects || {}).sort((a, b) => a.sort - b.sort);
      $rootScope.campaign = window.campaign;
      $rootScope.treedomEnv = window.treedomEnv;
      $rootScope.oldBrowser = window.oldBrowser;
      $rootScope.templates = window.templates;
      $rootScope.session_global_name = window.session_global_name;
      $rootScope.session_global_url = window.session_global_url;
      $rootScope.badges = [];
      $rootScope.isNewUser = false;
      $rootScope.needMerge = false;
      $rootScope.currency = Cookies.get(cookieKeys.currency) || ShopCurrency.Eur;

      ngLanguagesImport[$rootScope.ln]();

      // Not removed only to avoid iframe breaking
      $rootScope.treesLoaded = Promise.resolve();

      if (typeof window.campaign !== 'undefined') {
        $rootScope.activeCampaign = window.campaign.id;
      }

      // Listen for Consent Given or Rejected
      let resolveContentPromise;
      window.consentPromise = new Promise((resolve) => {
        resolveContentPromise = resolve;
      });

      if (!!(window.iubendaSiteId || window.iubendaPolicyId)) {
        TagManager.initListeners();

        const iubendaService = getIubendaService({
          siteId: window.iubendaSiteId,
          policyId: window.iubendaPolicyId,
          lang: $rootScope.ln,
        }, window.iubendaScripts, ['iab', 'iubenda']);

        iubendaService.on('preference_update', function (iub) {
          TagManager.push({
            iubenda_ccpa_opted_out: iub.cs.api.isCcpaOptedOut()
          });
        });

        iubendaService.on('consent_not_needed', function () {
          TagManager.push({
            event: 'iubenda_preference_not_needed'
          });
        });

        iubendaService.on('consent_given', function () {
          TagManager.push({
            event: 'iubenda_consent_given'
          });
          resolveContentPromise();
          TagManager.setPageViewReady(true);
        });

        iubendaService.on('consent_rejected', function () {
          TagManager.push({
            event: 'iubenda_consent_rejected'
          });
          resolveContentPromise();
          TagManager.setPageViewReady(false);
        });

        iubendaService.on('consent_purposes', function (purposes) {
          for (const purposeId in purposes) {
            if (purposes[purposeId]) {
              TagManager.push({
                event: 'iubenda_consent_given_purpose_' + purposeId
              });
            }
          }
          resolveContentPromise();
          TagManager.setPageViewReady(true);
        });
      } else {
        TagManager.initListeners(true);
      }


      var user = document.querySelector('html').getAttribute('data-user');

      var addVoucherFromUrl = function () {
        var voucher = $location.search().v;
        if (voucher) {
          Cart.assignVoucher(voucher)
            .then(function () {
              $location.search('v', null);
            })
            .catch(function () {
              $location.search('v', null);
            });
        }
      };

      // Register profile validation listener
      let profileValidationListener;
      const validationBlacklistedRoutes = [
        'pay',
        'cart',
        'species.tree',
        'species',
        'reset',
        'confirmmailaddress',
        'resetpassword',
        'checkusername',
        'contest',
        'checkout',
        'checkout.business',
        'checkout.payment',
        'checkout.planting',
        'checkout.gift',
        'subscription',
        'subscription.subscribe',
        'iframeGenerator',
        'iframecode',
        'iframeNumbersEvent',
        'iframeNumbersUser',
        'iframe',
        'treecode',
        'user',
        'user.subscription',
        'user.trees',
        'user.badges',
        'user.forests',
        'user.map',
        'create-forest',
        'create-forest.trees',
        'create-forest.pay',
        'create-forest.customize',
        'gift-card-preview'
      ];

      // TODO: Centralize private and business validations?
      profileValidationListener = $rootScope.$on('userdata.info', (_event, data) => {
        if (!data || appCookies.skipProfileCheck) return;
        if (
          data.usertype === 'Private'
          && !validatePrivateProfile(data)
        ) {
          setTimeout(() => {
            // check in the timeout because the route can change in 1second (like after the login/registration)
            if (validationBlacklistedRoutes.includes($uiRouterGlobals.current.name)) {
              return;
            }
            // Ignore modal for 1 day always, not spam users with this modal
            Cookie.set(cookieKeys.skipProfileCheckKey, '1', { domain: location.hostname, expires: 1 });
            Modal.open({
              templateUrl: 'v-modal-profile-completion.html',
              id: 'profileCompletion',
              fitcontent: true,
              easyClose: false,
            });
          }, 1000);
        }
        profileValidationListener && profileValidationListener();
      });

      if (!user) {
        $rootScope.logged = false;
        Cart.attemptFetch();
        DataLayerPushDeviceId();

      } else {
        $rootScope.user = user;
        $rootScope.logged = true;

        User.me($rootScope.user);
      }

      $rootScope.$on('userdata.cart', addVoucherFromUrl);

      // Badge.all()
      //   .then(function (res) {
      //     const data = res.data || res;
      //     $rootScope.badgesAvailable = data;
      //     $rootScope.$broadcast('BadgeCharged', data);
      //   });

      $rootScope.screenWidth = window.innerWidth;
      $rootScope.screenHeight = window.innerHeight;

      window.onresize = function () {
        $rootScope.screenWidth = window.innerWidth;
        $rootScope.screenHeight = window.innerHeight;
        if (!$rootScope.$$phase) $rootScope.$apply();
      };

      $rootScope.googleClient = document.head.querySelector('[name=google-signin-client_id]').content;

      /**
       * $rootScope.hideNavbar (boolean)
       *
       * Variabile che nasconde la navbar standard mostrandone una semplice.
       * Funziona tramite variabile get nell'url (?hn=1 => true, ?hn=0 => false).
       * Se la variabile è attiva, al cambio di stato (diverso da 'cart' o 'pay')
       * viene disattivata.
       */
      $rootScope.hideNavbar = $location.search() && $location.search().hn ? !!($location.search().hn * 1) : false;

      $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
        if (toState.externalUrl) {
          event.preventDefault();
          $window.open(toState.externalUrl + $rootScope.ln, '_self');
        } else if (toState.fullRefreshNavigateTo) {
          event.preventDefault();
          $window.location.href = $state.href(toState.name, toParams);
        }
      });

      $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        // disable globally ngAnimate
        $animate.enabled(false);
        switch (toState.name) {
          case 'checkout':
          case 'checkout.business':
          case 'checkout.payment':
          case 'checkout.planting':
          case 'checkout.gift':
          case 'subscription':
          case 'subscription.subscribe':
          case 'cart':
          case 'pay':
          case 'create-forest':
          case 'create-forest.trees':
          case 'create-forest.pay':
          case 'create-forest.customize':
            $rootScope.hideNavbar = true;
            break;
          default:
            $rootScope.hideNavbar = false;
        }
        Utility.allowScroll();
        $rootScope.statusHistory = {
          current: {
            state: toState,
            params: toParams
          },
          previous: {
            state: fromState,
            params: fromParams
          }
        };
        if (fromState.name !== '') {
          document.title = $filter('trans')('seo.index.title');
        }
      });

      var currentEvent = $cookies.get('currentEvent');
      if (currentEvent) {
        $rootScope.plantIn = JSON.parse(currentEvent);
      }
      $rootScope.$watch('plantIn', function (_plantIn) {
        if (_plantIn) {
          $cookies.put('currentEvent', JSON.stringify({
            id: _plantIn.id,
            name: _plantIn.name,
            avatar: _plantIn.avatar
          }), {
            path: '/'
          });
        } else {
          $cookies.remove('currentEvent');
        }
      });

      $rootScope.isFeatureActive = isFeatureActive;

      // Treecode Composer
      $rootScope.openGiftComposer = function (ev, treeId, source = 'treedom-user-profile') {
        ev && ev.preventDefault();
        const userId = $rootScope.userdata
          && $rootScope.userdata.info
          && $rootScope.userdata.info.id;
        if (userId) {
          Modal.open({
            templateUrl: 'v-create-your-gift.html',
            fullpage: true,
            small: false,
            id: `create-your-gift`,
            assignBackgroundClass: ' treedomModalTreecodeCreate ',
            data: { userId, treeId, source },
          });
          DataEventOpenGiftFlow(source);
        }
      };

      $rootScope.openGiftComposerExternal = function (source = 'treedom-user-profile') {
        DataEventOpenGiftFlow(source);
        $state.go('create-your-gift');
      };

      $rootScope.onMessage = function (name, callback, options) {
        const listener = (event) => {
          if (!event.data) return;
          const data = event.data;
          if (data.name === name) {
            callback(data);
          }
        };
        window.addEventListener('message', listener, options);
      };

      $rootScope.eventSocialButton = function (social, action) {
        DataEventSocialButton(social, action);
      };


      // Remove preload overlay
      OverlayLoader.hide('preload');

    }]);

// new requires
require('./Controller/AppController');
require('./Controller/CartController');
require('./Controller/CommunityController');
require('./Controller/EmissionController');
require('./Controller/LoginController');
require('./Controller/MapController');
require('./Controller/ModalController');
require('./Controller/MenuController');
require('./Controller/modalBasicMessageController');
require('./Controller/PageController');
require('./Controller/ProjectsController');
require('./Controller/TreeController');
require('./Controller/UserController');
require('./Controller/iFrameController');
require('./Directive/vueComponentDirective.js');
require('./Directive/widgetDirectives');

// old requires
require('./Controller/CartController/checkoutController.js');
require('./Controller/galleryController.js');
require('./Controller/modalTreeUsesController.js');
require('./Controller/organizationController.js');
require('./Controller/specieController.js');
require('./Controller/userMenuController.js');
require('./Directive/miscDirective.js');
require('./Directive/vimeoPlayer.js');
require('./Filter/miscFilter.js');
require('../components/adv/index.js');
require('../components/banners/index.js');
require('../components/cartBadges/index.js');
require('../components/cartPowers/index.js');
require('../components/comments/index.js');
require('../components/ecommerce_horoscope/index.js');
require('../components/ecommerce_pack/index.js');
require('../components/ecommerce_tree/index.js');
require('../components/ecommerceBox/index.js');
require('../components/ecommercePlans/index.js');
require('../components/ecommerceWed/index.js');
require('../components/nums/index.js');
require('../components/post/index.js');
require('../components/previewCart/index.js');
require('../components/productPowersBars/index.js');
require('../components/productPowersCircles/index.js');
require('../components/statsNumbers/index.js');
require('../components/switch');
require('../components/talesOfUs/index.js');
require('../components/tree_uses/index.js');
require('../components/treeCollection/index.js');
require('../components/viewPeopleCard/index.js');
require('../components/welcomeParallax/index.js');
require('../components/partyFavorEcommerceBox');
require('../components/pagesHeader/index.js');

// AngularJS 1.7 components
require('../components/business-data-form-component');
require('../components/loading-component');
require('../components/ecommerce-grid-component');
require('../components/ecommerce-picker-component');
require('../components/product-card-component');
require('../components/product-qt-card-component');
require('../components/package-qt-card-component');
require('../components/quantity-input-component');

if (window.treedomEnv !== 'DEV') {
  console.log('%c[ Treedom | let\'s green the planet ]', 'color: green; font-size:18px; font-align:center');
  console.log('%cHi there! Trying to reverse engineer something? Why not join our team instead?', 'color: green; font-size:13px;');
  console.log('%cApply at https://www.treedom.net/en/page/contacts and mention this comment!', 'color: green; font-size:13px;');
}
