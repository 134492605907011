/* @ngInject */
export default function businessDataFormController($scope, $rootScope, Countries, User, Business) {
  
  this.loading = false
  this.saving = false
  this.countries = []
  this.businessTypes = []
  this.userData = null
  this.alertError = null
  this.errorFields = []
  this.requiredFields = []

  this.$onInit = () => {
    this.loading = true
    Countries.getCountriesNativeName().then(countries => {
      this.countries = countries.sort((a, b) => (a || "").localeCompare(b || ""))
      User.userEditInfo().then(info => {
        return Countries.resolveNativeName(info.country).then(country => {
          return { ...info, country }
        })
      }).then(info => {
        const userData = { ...info, type: Business.resolveType(info.type) }
        return info.country ? this.resolveCountryData(info.country).then(() => userData) : userData
      }).then(userData => {
        this.loading = false
        this.userData = userData
        $scope.$watch(() => this.userData.country, this.resolveCountryData)
        $scope.$watch(() => this.userData.type, type => this.setRequiredFields(this.userData.country, type))
      })
    })
  }

  this.resolveCountryData = country => {
    this.errorFields = []
    return this.setRequiredFields(country, this.userData && this.userData.type).then(() => {
      return this.setTypes(country).then(() => {
        if (!this.businessTypes.some(type => type.value === this.userData.type)) this.userData.type = null
      })
    }).then(() => {
      if (!$scope.$$phase) $scope.$apply()
    })
  }

  this.setRequiredFields = (country, type) => {
    return Business.getRequiredFields(country, type).then(fields => {
      this.requiredFields = fields
      if (!$scope.$$phase) $scope.$apply()
    })
  }

  this.setTypes = (country) => Business.countryAvailableTypes(country).then(types => this.businessTypes = types)

  this.requiredFlag = field => this.requiredFields.includes(field) ? ' *' : ''

  this.hasError = field => this.errorFields.includes(field)

  this.resetFieldError = name => {
    angular.element(`[name="${name}"]`).removeClass('errorAnimated');
  };

  this.saveInfo = () => {
    this.errorFields = []
    this.saving = true
    Business.validateProfile(this.userData).then(() => {
      return User.userEdit(this.userData).then(response => {
          if (response.status) $rootScope.userdata.complete = true
        }).catch(error => { this.alertError = error })
    }).catch(({ fields }) => {
      this.errorFields = [...fields]
    }).finally(() => {
      this.saving = false
      if (!$scope.$$phase) $scope.$apply()
    })
  }

}
