import Cookies from 'js-cookie'
import mapboxgl from 'mapbox-gl'
import { openUrlInPopup } from '@f/auth/helpers'
import { CognitoHybridStorage } from '@f/auth/cognito-hybrid-storage'
import { v4 } from 'uuid'
// TODO: move cache to apollo local state
class ConfigCache {
  private static internalCache: Record<string, string | number> = {}
  private static htmlConfigAttributes = {
    tokenKey: 'data-cjname',
    apiUri: 'data-gql-uri',
    treedomNextUri: 'data-treedom-next-uri',
    authUri: 'data-auth-uri',
    treecodesUri: 'data-treecodes-uri',
    baseUri: 'data-url',
    contentfulUri: 'data-contentful-uri'
  }

  static getString(key: string, fallback: string = 'XXX'): string {
    return ConfigCache.get(key, fallback).toString()
  }

  static getNumber(key: string, fallback: number = 0): number {
    return Number(ConfigCache.get(key, fallback))
  }

  private static get(
    key: string,
    fallback: string | number = ''
  ): string | number {
    if (ConfigCache.internalCache.hasOwnProperty(key)) {
      return ConfigCache.internalCache[key]
    }

    if (ConfigCache.htmlConfigAttributes.hasOwnProperty(key)) {
      const htmlAttr =
        ConfigCache.htmlConfigAttributes[
          key as keyof typeof ConfigCache.htmlConfigAttributes
        ]
      return (ConfigCache.internalCache[key] =
        ConfigCache.getHtmlNodeAttribute(htmlAttr) || fallback)
    }
    return fallback
  }

  private static getHtmlNodeAttribute(attribute: string): string | null {
    return document.querySelector('html')?.getAttribute(attribute) || null
  }
}

export enum ShopCurrency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export const cookieKeys = {
  get tokenKey() {
    return ConfigCache.getString('tokenKey')
  },
  tempCartKey: 'cartId',
  referralKey: 'rt',
  currentEventKey: 'currentEvent',
  languageKey: 'hl',
  skipEmailConfirmKey: '_trskemco',
  skipProfileCheckKey: '_trskprch',
  deviceId: 'device_id',
  currency: 'currency',
}

export const localeStorageKeys = {
  onBoardingProfileKey: 'TRonboarding_profile',
}

export const appCookies = {
  get tempCart() {
    return Cookies.get(cookieKeys.tempCartKey) || ''
  },
  get referral() {
    return Cookies.get(cookieKeys.referralKey) || ''
  },
  get language() {
    return Cookies.get(cookieKeys.languageKey) || ''
  },
  get deviceId() {
    let id = Cookies.get(cookieKeys.deviceId)
    if(!id) {
      id = Cookies.set(cookieKeys.deviceId, v4(), {
        expires: 365,
        path: '/',
        domain: '.treedom.net',
      }) as string
    }
    return id
  },
  get skipEmailConfirm() {
    return !!Cookies.get(cookieKeys.skipEmailConfirmKey)
  },
  set skipEmailConfirm(skip) {
    Cookies.get(cookieKeys.skipEmailConfirmKey) || ''
  },
  get skipProfileCheck() {
    return !!Cookies.get(cookieKeys.skipProfileCheckKey)
  },
  set skipProfileCheck(skip) {
    Cookies.get(cookieKeys.skipProfileCheckKey) || ''
  },
}

export const api = {
  get treedomNextUri() {
    return ConfigCache.getString('treedomNextUri')
  },
  get apiUri() {
    return ConfigCache.getString('apiUri')
  },
  get authUri() {
    return ConfigCache.getString('authUri')
  },
  get treecodesUri() {
    return ConfigCache.getString('treecodesUri')
  },
  get cartUri() {
    return (window.cartServiceUrl || 'XXX') + '/api/v1'
  },
  get baseUri() {
    return (window.baseUri || '').replace(/\/$/, '') + '/API'
  },
  get contentfulUri() {
    return ConfigCache.getString('contentfulUri')
  },
  get contentfulClientKey() {
    // @ts-ignore
    return (window.contentfulClientKey ?? '') as string
  }
}

export const emailConfirmQueryParam = 'econf'
export const mapBox = {
  accessToken:
    'pk.eyJ1IjoidG9tbWFzb3NwZXJvbmkiLCJhIjoiY2tnOTE3eW12MDJqazMybXNzOWV1YjloOSJ9.wtGsuDU7XIKjcv2cq8CiXw',
  styles: {
    street: 'mapbox://styles/tommasosperoni/cj78p16077drf2sshtkd6gjjf',
    satellite: 'mapbox://styles/mapbox/satellite-v9',
  },
  defaultStyle: 'satellite',
  defaultConfig: <mapboxgl.MapboxOptions>{
    attributionControl: false,
    dragRotate: false,
    logoPosition: 'top-right' as const,
    trackResize: true,
    minZoom: 0.7,
    zoom: 1,
  },
}

export const clientApp = {
  get baseUri() {
    return ConfigCache.getString('baseUri')
  },
}

export const preferences = {
  get language() {
    return document.documentElement.lang || 'it'
  },
}

export const testsConfigPath = 'data/tests.json'

export const ecommerceOptions = {
  vatPercentage: 22,
  brand: 'Treedom',
}

const [, lang = 'en'] = location.pathname.split('/')
// eslint-disable-next-line no-restricted-globals
const redirectSignIn = new URL(`/${lang}/authorize`, location.href).toString()
// eslint-disable-next-line no-restricted-globals
const redirectSignOut = new URL(`/${lang}/logout`, location.href).toString()

const appConfig = {
  // @ts-ignore
  auth: window.auth,
}


export const authConfig = {
  provider: appConfig.auth.provider,
  legacy: {
    cookieName: cookieKeys.tokenKey,
    authServiceUrl: api.authUri,
    apiServiceUrl: document.getElementsByTagName('base')[0].href,
    redirectSignIn,
    redirectSignOut,
  },
  cognito: {
    region: appConfig.auth.awsCognito.region,
    userPoolId: appConfig.auth.awsCognito.userPoolId,
    userPoolWebClientId: appConfig.auth.awsCognito.userPoolWebClientId,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    storage: new CognitoHybridStorage({
      cookieStorage: {
        domain: appConfig.auth.awsCognito.cookieStorageDomain,
        secure: false,
        path: '/',
        expires: 365,
      }
    }),
    oauth: {
      domain: appConfig.auth.awsCognito.oauthDomain,
      scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
      responseType: 'code',
      // Open url in popup only if is not already in a popup
      urlOpener: window.opener ? undefined : openUrlInPopup,
      redirectSignIn,
      redirectSignOut,
    },
  },
}

