































import { Component, Mixins } from 'vue-property-decorator'
import TreeDiaryPost from '../post_types/TreeDiaryPost.vue'

@Component({
  name: 'TreeDiaryPostModal',
})
export default class TreeDiaryPostModal extends Mixins(TreeDiaryPost) {
  // Computed
  get image(): string {
    return (this.post.image && this.post.image.medium) || ''
  }
}
