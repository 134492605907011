







import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { getRootScope, getStateService } from "@f/services/Angular";

export enum RedirectTo {
  home= 'home',
  profile= 'profile'
}

export default defineComponent({
  name: "GameUnavailable",
  props: {
    description: String ,
    redirectTo: { type: String as PropType<RedirectTo>, default: RedirectTo.profile },
  },
  setup(props) {
    const stateService = getStateService();
    const rootScope = getRootScope();
    const cta = ref();
    const goToProfile = () => {
      stateService && stateService.go("user", { slug: rootScope?.userdata.info.slug });
    };
    const goToHomePage = () => {
      stateService && stateService.go("index");
    };
    onMounted(() => {
      switch (props.redirectTo) {
        case RedirectTo.home:
          cta.value = {
            description:"game.unavailable.label",
            label: "game.unavailable.cta.home",
            callback: goToHomePage
          }
          break;
        case RedirectTo.profile:
          cta.value = {
            description:"game.unavailable.label",
            label: "game.unavailable.cta.profile",
            callback: goToProfile
          }
          break;
      }
    })
    return {
      cta,
    };
  },
});
