import ajv from '@f/validation/ajv'
import { IDataLayerVariables } from '@f/validation/types/dataLayer'
import { JSONSchemaType } from 'ajv'

const DataLayerVariablesSchema: JSONSchemaType<IDataLayerVariables> = {
  type: 'object',
  required: [
    'market',
    'country',
    'language',
    'userStatus',
    'userType',
    'userSpecification',
    'deviceId',
  ],
  properties: {
    userId: { type: 'number', nullable: true },
    market: { type: 'string', nullable: true },
    country: { type: 'string', nullable: true },
    language: { type: 'string', nullable: true },
    treeRedeemer: { type: 'string', nullable: true },
    numberOfTreesOwned: { type: 'number', nullable: true },
    treeOwner: { type: 'string', nullable: true },
    userStatus: { type: 'string' },
    userType: { type: 'string' },
    userSpecification: { type: 'string' },
    deviceId: { type: 'string' },
  },
}

export const validateVariables = ajv.compile<IDataLayerVariables>(
  DataLayerVariablesSchema
)
