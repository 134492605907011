























import {computed, defineComponent, ref} from "@vue/composition-api";
import Treecon from "@f/components/Treecons/Treecon.vue";
import {maxWidth, useMediaQuery} from "@f/utils/breakpoints";

export default defineComponent({
  name: "GameStepper",
  props: {
    canGoForward: Boolean,
  },
  components: {
    Treecon,
  },
  emits: ["next", "end"],
  setup(_, {slots, emit}) {
    const currentStepIndex = ref(0);
    const slideForward = ref(true);
    const isMobile = useMediaQuery(maxWidth("md"));
    const currentStep = computed(() => currentStepIndex.value + 1);
    const steps = computed(() => Object.keys(slots));
    const progress = computed(() => (currentStep.value / steps.value.length) * 100);
    const lastStep = ref(false)

    const goNextStep = () => {
      slideForward.value = true;
      currentStepIndex.value = currentStepIndex.value + 1;
      emit("next", currentStepIndex.value);
      if (currentStepIndex.value === steps.value.length) {
        lastStep.value = true
        emit("end");
      }
    };

    return {
      steps,
      currentStepIndex,
      slideForward,
      goNextStep,
      progress,
      isMobile,
      currentStep,
      lastStep
    };
  },
});
