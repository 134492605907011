import template from './product-card-template.html'
import controller from './product-card-controller'

const bindings = {
  product: '<',
  onClick: '&',
  template: '@'
}

angular.module('ngApp')
  .component('productCard', {
    template,
    controller,
    bindings
  })
