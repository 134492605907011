import { ProductPlan, UserCart } from '@f/@types'
import tagManagerService from '@f/services/TagManager'
import { validateAddToCart } from '@f/validation/datalayer'
import { cartPartialInfo, getPositionFromSubscriptions, productPartial } from '../eventUtils';

export function DataEventAddToCart(cart: UserCart, position: number = 0) {
  const cartItem = cart.items.length && cart.items[cart.items.length - 1]
  if (!cartItem) return
  tagManagerService.push({ ecommerce: null })
  tagManagerService.push(
    {
      event: 'addToCart',
      ecommerce: {
        ...cartPartialInfo(cart),
        add: {
          products: Array.of(
            productPartial(cartItem.product, {
              isGift: !!cartItem.gift,
              position
            })
          ),
        },
      },
    },
    validateAddToCart
  )
}

export function DataEventAddToCartSubs(cart: UserCart, plan: ProductPlan) {
  tagManagerService.push({ ecommerce: null })
  tagManagerService.push(
    {
      event: 'addToCart',
      position: getPositionFromSubscriptions(),
      ecommerce: {
        ...cartPartialInfo(cart),
        add: {
          products: Array.of(productPartial(plan, { isGift: false })),
        },
      },
    },
    validateAddToCart
  )
}
