import tagManagerService from '@f/services/TagManager'

angular.module('tagManagerService', [])
  .factory('TagManager', ['$rootScope', '$uiRouterGlobals', '$location', function ($rootScope, $uiRouterGlobals, $location) {

    return {
      deregisterStateListener: null,

      seoBlacklist: [
        'user',
        'userRelation',
        'userRelationPages',
        'userBadge',
        'userBadges',
        'userEventRally',
        'userTree',
        'userTreeGifted',
      ],

      pageViewTrigReady: false,

      get dataLayer() {
        return tagManagerService.dl
      },

      push: tagManagerService.legacyPush.bind(tagManagerService),

      initListeners(pageViewReady) {
        this.pageViewTrigReady = !!pageViewReady
        this.initStateChangeListener()
      },

      initStateChangeListener() {
        if (!tagManagerService.anonymize) return
        this.setOriginalLocation($uiRouterGlobals.current)
        this.deregisterStateListener && this.deregisterStateListener()
        this.deregisterStateListener = $rootScope.$on('$stateChangeSuccess', this.triggerPageView.bind(this))
      },

      initUserSlugListeners() {
        this.setUserSlug(null, !!$rootScope.user)
        this.deregisterAuthListener && this.deregisterSignOutListener()
        this.deregisterAuthListener = $rootScope.$on('UserLoggedBroadcast', this.setUserSlug.bind(this))
      },


      setOriginalLocation() {
        const { location } = this.anonymizeGaVariables()
        this.push({
          originalLocation: location
        })
      },

      triggerPageView(_event, toState) {
        if (!(this.pageViewTrigReady && toState && toState.name)) return

        // Wait for the url to be updated from uiRouter
        setTimeout(() => {
          // Drop location
          const { page, title, location} = this.anonymizeGaVariables(toState)
          
          this.push({
            event: 'pageView',
            ga: { location, page, title }
          })
        })
      },

      setPageViewReady(isReady) {
        if (isReady === this.pageViewTrigReady) return
        this.pageViewTrigReady = isReady
        this.triggerPageView(null, $uiRouterGlobals.current)
      },

      setUserSlug(_event, isAuthenticated) {
        this.push({
          userSlug: isAuthenticated ? this.obfuscateSlug($rootScope.user) : null
        })
      },

      anonymizeGaVariables(currentState) {
        const {
          protocol,
          host,
          pathname,
          search
        } = this.anonymizeUrl($location.absUrl())
        const location = protocol+'//'+host+pathname+search
        const page = pathname+search
        const title = currentState && this.anonymizeTitle(document.title, currentState) || ''
        return { title, location, page }
      },

      anonymizeUrl(href) {
        return new URL(href.replace(/(.*(user|organization)\/)([a-zA-Z0-9_\-.@]*)(\/?.*)/, (_url, prefix, _type, slug, suffix) => prefix+this.obfuscateSlug(slug)+suffix))
      },

      anonymizeTitle(title, state) {
        return this.seoBlacklist.includes(state.seo)
          ? state.name+' page title'
          : title
      },

      obfuscateSlug(slug) {
        return btoa(slug)
      },
    }



  }])
