import {JSONSchemaType} from "ajv";

export interface FollowTheForest {
    email: string,
    acceptForestCommunications: boolean,
    rejectMarketingCommunications: boolean,
    captchaResponse: string,
    locale: string,
    eventId: number
}

export const FollowTheForestSchema: JSONSchemaType<FollowTheForest> = {
    type: 'object',
    properties: {
        email: {
            type: "string",
            nonEmpty: true,
            format: 'email',
        },
        acceptForestCommunications: {
            type: 'boolean',
            nonEmpty: true,
            const: true
        },
        rejectMarketingCommunications: {
            type: 'boolean',

        },
        captchaResponse: {
            type: "string",
            nonEmpty: true,
        },
        locale: {
            type: 'string',
        },
        eventId: {
            type: "number",
            nonEmpty: true,
        },
    },
    required: ["email", "captchaResponse", "rejectMarketingCommunications", "acceptForestCommunications"]
}
