/**
* Trova la posizione della scheda
* NOTE: Default bottom, se non c'è spazio viene posizionato top.
* @param {Object} element - Nodo del DOM
*/
exports.getWidgetPosition = (element, widget = undefined, breakpoint = 0, fixed = false) => {
  const elementRect = element[0].getBoundingClientRect()
  const scrollTop = (document.documentElement ? document.documentElement.scrollTop : document.body.scrollTop) * !fixed

  let offsetLeftArrow = 12 // Default.
  let top = elementRect.bottom + scrollTop
  let left = elementRect.left
  let position = 'bottom'

  const { width, height } = window.innerWidth < breakpoint ? { width: window.innerWidth, height: window.innerHeight - elementRect.bottom } : widget
  
  if (width && height) {
    // Se non c'è lo spazio per posizionarlo bottom, sposta il widget in alto.
    if ((window.innerHeight - elementRect.bottom) < height) {
      // Solamente se entra in posizione top
      if (elementRect.top > height) {
        top -= (height + elementRect.height)
        position = 'top'
      }
    }
    
    // Se non c'è abbastanza spazio in larghezza spostalo più a sinistra.
    left -= Math.max((left + width) - window.innerWidth, 0)
    offsetLeftArrow += elementRect.left - left
  }
  
  const overlayMargin = {
    top: elementRect.bottom,
    right: 0,
    bottom: 0,
    left: 0
  }

  return {
    width,
    height,
    position,
    top,
    left,
    offsetLeftArrow,
    overlayMargin
  }
}

/**
* Dato un Nodo del DOM, controlla se ci sei sopra con il cursore del mouse
* @param {Object} element - Node DOM elemen
* @param {Object} event - window.event
*/
exports.isCursorOn = (element, event) => {
  var e = event || window.event
  var elemHeight = element.outerHeight()
  var elemWidth = element.outerWidth()
  var elemClientRect = element[0].getBoundingClientRect()

  if (e.clientY >= elemClientRect.top &&
    e.clientY <= (elemClientRect.top + elemHeight) &&
    e.clientX >= elemClientRect.left &&
    e.clientX <= (elemClientRect.left + elemWidth)) {
    return true
  }
  return false
}
