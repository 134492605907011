import ajv from '@f/validation/ajv'
import * as PurchaseSchemas from '@f/validation/schema/datalayer/purchase'
import { IVoucherTransaction, IAddToCart, IRemoveFromCart, ICheckout, ITransaction, ICartViewed, IPaymentInfo, IPaymentInfoSub} from '@f/validation/types/dataLayer'

export const validateVoucherTransaction = ajv.compile<IVoucherTransaction>(PurchaseSchemas.VoucherTransactionSchema)
export const validateAddToCart = ajv.compile<IAddToCart>(PurchaseSchemas.AddToCartSchema);
export const validateRemoveToCart = ajv.compile<IRemoveFromCart>(PurchaseSchemas.RemoveFromCartSchema);
export const validateCheckout = ajv.compile<ICheckout>(PurchaseSchemas.CheckoutSchema)
export const validateTransaction = ajv.compile<ITransaction>(PurchaseSchemas.TransactionSchema)
export const validateCartViewed = ajv.compile<ICartViewed>(PurchaseSchemas.CartViewedSchema)
export const validatePaymentInfo = ajv.compile<IPaymentInfo>(PurchaseSchemas.PaymentInfoSchema)
export const validatePaymentInfoSub = ajv.compile<IPaymentInfoSub>(PurchaseSchemas.PaymentInfoSchemaSub)