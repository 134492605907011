

















































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import DotLoader from '@f/components/DotLoader.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import { CartPreviewItem } from './CartPreviewNewEcommerce.vue'
@Component({
  name: 'CartPreviewRow',
  components: {
    DotLoader,
    Treecon,
  },
})
export default class CartPreviewRow extends Vue {
  /*--- MODEL ---------*/
  @Model('change', { type: Object, required: true })
  readonly item!: CartPreviewItem

  /*--- PROPS ---------*/
  @Prop({ type: Boolean, default: false }) readonly voucherGift!: boolean

  /*--- DATA ----------*/
  busy: boolean = false

  /*--- COMPUTED ------*/
  get deletable(): boolean {
    return !!(this.item.id || this.item.groupId) && !this.voucherGift
  }

  get isFree(): boolean {
    return this.voucherGift || !this.price
  }

  get price(): number {
    return !this.voucherGift ? this.item.totalPrice.centAmount / 100 : 0
  }

  get discountedPrice(): number {
    return this.voucherGift || this.item.product.badgeDiscount
      ? this.item.product.price /
          (100 - Math.abs(this.item.product.badgeDiscount))
      : 0
  }

  /*--- WATCHERS ------*/

  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/

  /*--- METHODS -------*/
  emitDelete() {
    this.busy = true
    this.$emit('delete', this.item, this.removeCallback)
  }

  removeCallback(): void {
    this.busy = false
  }
}
