import * as clipboard from 'clipboard-polyfill'

angular.module('ngApp')
.controller('postComponentController', ['$scope', '$rootScope', '$state', '$stateParams', '$timeout', 'Modal', 'Utility', function ($scope, $rootScope, $state, $stateParams, $timeout, Modal, Utility) {
  var ctrl = this
  ctrl.copied = false

  ctrl.copyToClipboard = function (textToCopy, postId) {
    clipboard.writeText(textToCopy)
    .then(function() {
      ctrl.copied = true

      $timeout(function () {
        ctrl.copied = false
      }, 1500)
    })
  }

  ctrl.openMapToShare = function (index) {
    var test = getCurrentSection($rootScope.currentState, $rootScope.singleTree)

    switch (test) {
      case 'Private':
        $state.go('user.trees.item.share', { idShare: index })
        break
      case 'Business':
        $state.go('organization.trees.item.share', { idShare: index })
        break
      case 'Event':
        var userType = $state.current.name.includes('/organization/') ? 'organization' : 'user'
        $state.go(userType + '.event.trees.item.share', { idShare: index })
        break
    }

    $timeout(openStreamModal, 500)
  }

  /**
   * Trova la sezione in cui mi trovo attualmente.
   * @param {string} currentState - Stato attuale dell'applicazione.
   * @param {Object} tree - Oggetto albero della pagina.
   * @return {string} Una della seguenti stringhe: ['Business', 'Private', 'Event']
   */
  var getCurrentSection = function (currentState, tree) {
    if (!currentState || !tree) return 'Error'
    if (currentState.includes('event')) return 'Event'
    return $stateParams.slug === tree.initialuser.slug ? tree.initialuser.type : tree.user.type
  }

  // Apre la modale dello stream e salva in $rootScope.postToShare le informazioni dello stream
  var openStreamModal = function () {
    if ($scope.currentState.includes('share')) {
      // Controllo se c'è già una modale con stream aperto.
      var thereIsShareModalOpened = !$rootScope.openedModals.reduce((bool, modalId) => {
        if (modalId.includes('modalShare')) bool = bool * 0
        return bool
      }, 1)

      // Se c'è mi fermo qui.
      if (thereIsShareModalOpened) return

      var postIndex = $state.params.idShare
      var reversedTreeWallList = []

      if (postIndex >= $scope.treeWall.length) {
        $state.current.name.includes('event')
          ? $state.go('user.event.trees.item', $stateParams)
          : $state.go('user.trees.item', $stateParams)
      }

      reversedTreeWallList = [ ...$scope.treeWall, ...[]  ].reverse()
      $rootScope.postToShare = [reversedTreeWallList[postIndex]]

      Modal.open({ templateUrl: 'modalShare.html' })
    }
  }
}])
