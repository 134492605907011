import 'cross-fetch/polyfill'
import { ApolloClient, HttpLink, from } from '@apollo/client/core'

import { api } from '@f/configs/app';
import cache from '../shared/cache';
import logErrorLink from '@f/apollo/links/logErrorLink'
import { createApolloAuthorizationLink } from '@f/auth/helpers'

const abortController = new AbortController();
const httpLink = new HttpLink({
  uri: api.apiUri,
  //HACK: remove error in console.log by abort user request
  fetchOptions: {
    signal: abortController.signal,
  }
})

const authorizationLink = createApolloAuthorizationLink()
const client = new ApolloClient({
  link: from([
    logErrorLink,
    authorizationLink,
    httpLink,
  ]),
  cache,
})

export default client
