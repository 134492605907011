angular.module('utilityService', [])
.factory('Utility', ['$http', '$q', '$routing', '$state', '$location', 'Routing', '$rootScope', '$timeout', '$filter', function ($http, $q, $routing, $state, $location, Routing, $rootScope, $timeout, $filter) {
  var routing = Routing;
  var routeJs = function (route, params) {
    var url = routing.route(route, params);
    if (url.indexOf('?') > -1) url = url.substring(0, url.indexOf('?'));
    return url
  };

  var utilityFactory = {};

  utilityFactory.root = function () {
    return $rootScope.baseUrlAssets
  };

  utilityFactory.sendForm = function (post) {
    return $http.post(routeJs('treedom_form_help'), post)
    .then(function(response) {
      return response.data || response
    })
  };

  utilityFactory.addMessage = function (system, type) {
    var message = { system: system, type: type };
    return $rootScope.$broadcast('NewMessageBroadcast', message)
  };

  utilityFactory.closeMessage = function () {
    return $rootScope.$broadcast('CloseNewMessageBroadcast', true)
  };

  utilityFactory.blockScroll = function ({scrollToTop} = {scrollToTop: true}) {

    const htmlScroll = $('html, body').scrollTop()

    if (scrollToTop){
     $('html, body').css({'overflow':'hidden'})
     $('html, body').animate({ scrollTop: htmlScroll}, 250)
    }

  };

  utilityFactory.isBlockedScroll = function () {
    if ($('html, body').css('overflow') === 'hidden') return true;
    return false
  };

  utilityFactory.allowScroll = function ({scrollToTop} = {scrollToTop: true}) {
    if (!utilityFactory.isBlockedScroll()) return
    const bodyScroll = $('html, body').scrollTop()
    $('html, body').css({ overflow: '', height: '' })
    if (scrollToTop){
        $('html, body').animate({ scrollTop: bodyScroll}, 250)
    }
  };

  utilityFactory.translations = function () {
    return $http.get(routeJs('bazinga_jstranslation_js', {_format: 'json'}))
    .then(function(response) {
      return response.data || response
    })
  };

  utilityFactory.openTour = function (chapter, page) {
    var where = { 'chapter': chapter, 'page': page };
    $rootScope.$broadcast('OpenTourBroadcast', where)
  };

  utilityFactory.caching = function (post) {
    return $http.post(routeJs('treedom_SEO_caching'), post)
    .then(function(response) {
      return response.data || response
    })
    .catch(function(data) {

      return data
    })
  };

  utilityFactory.card = function () {
    return $http.get(routeJs('treedom_get_card'))
      .then(function(response) {
        return response.data || response
      })
      .catch(function(data) {
        return data
      })
  };

  utilityFactory.checkDisabled = function () {
    return $http.get(routeJs('treedom_check_disabled'))
      .then(function(response) {
        return response.data || response
      })
      .catch(function(data) {
        return data
      })
  };

  utilityFactory.openMenuEffect = function () {
    if ($rootScope.hardware === 'desktop') {
      $('.new-menu-background').show();
      $('body').animate({'margin-left': '300px'}, 500);
      $('.new-menu').animate({'left': '0'}, 500);
      this.blockScroll();
      this.enableButton()
    } else {
      $('.new-menu-background').show();
      $('body').animate({'margin-left': '0px'}, 500);
      $('.new-menu').animate({'left': '0'}, 500);
      this.blockScroll();
      this.enableButton()
    }
    $rootScope.isMenuOpen = true
  };

  utilityFactory.closeMenuEffect = function () {
    if ($rootScope.hardware === 'desktop') {
      $('.new-menu-background').hide();
      $('body').animate({'margin-left': '0px'}, 500);
      $('.new-menu').animate({'left': '-300px'}, 500, function () {
        $rootScope.isMenuOpen = false
      });
      this.allowScroll();
      $rootScope.login = false
    } else {
      $('.new-menu-background').hide();
      $('body').animate({'margin-left': '0px'}, 500);
      $('.new-menu').animate({'left': '-300px'}, 500, function () {
        $rootScope.isMenuOpen = true
      });
      this.allowScroll();
      $rootScope.login = false
    }
  };

  utilityFactory.disableButton = function () {
    $('.btn-disabled-on-click').attr('disabled', 'disabled')
  };

  utilityFactory.enableButton = function () {
    $('.btn-disabled-on-click').removeAttr('disabled')
  };

  utilityFactory.redirectAfterLogin = function (state, params) {
    if ($rootScope.user) {
      $timeout(function () {
        $state.go(state, params)
      })
    } else {
      $rootScope.redirectState = state;
      $rootScope.redirectParams = params
    }
  };

  utilityFactory.gaAdd = function (action, object, position) {
    if (typeof object !== 'undefined') {
      var options;

      if (typeof position !== 'undefined') {
        options = {
          id: '' + object.id,
          name: '' + object.name,
          variant: object.limitedName ? object.limitedName + '' : 'normal',
          position: position
        }
      } else {
        options = {
          id: '' + object.id,
          name: '' + object.name,
          variant: object.limitedName ? object.limitedName + '' : 'normal'
        }
      }
    }

    //window.ga('ec:' + action, options)
    return true;
  };

  utilityFactory.gaAction = function (action, object) {
    //window.ga('ec:setAction', action, object)
      return true;
  };

  utilityFactory.gaSend = function () {
    //window.ga('send', 'pageview')
      return true;
  };

  utilityFactory.titlePage = function (page) {
    if (typeof page !== 'undefined') {
      // prendo la traduzione originale
      var translationTitle = Translator.trans(page + '.title', {}, 'seo');
      if (translationTitle !== page + '.title' && translationTitle.indexOf('%') === -1) {
        document.title = translationTitle
      } else if (document.title.indexOf('%') > -1) {
        document.title = Translator.trans('index.title', {}, 'seo')
      }
    }
  };

  utilityFactory.faq = function () {
    return ($rootScope.faqs) ? Promise.resolve($rootScope.faqs) : $http.get(routeJs('treedom_faq'))
    .then(function(response) {
      const faqs = response.data || response
      $rootScope.faqs = faqs
      return faqs
    })
  };

  utilityFactory.getLimitedSerie = function (limitedSerie) {
    return $http.get(routeJs('treedom_get_species_by_serie', {limitedSerie: limitedSerie}))
      .then(function(response) {
        return response.data || response
      })
  };

    utilityFactory.getLimitedBySlug = function (slug) {
      return $http.get(routeJs('treedom_get_limited_by_slug', {slug: slug}))
        .then(function(response) {
          return response.data || response
        })
    };

  utilityFactory.portfolioTags = function () {
    return $http.get(routeJs('treedom_get_portfolio_tags'))
    .then(function(reponse) {
      return reponse.data || reponse
    })
  };

  utilityFactory.sendBusinessForm = function (data) {
    return $http.post(routeJs('treedom_business_form'), data)
    .then(function(response) {
      return response.data || response
    })
  };

  utilityFactory.faqVote = function (id, type) {
    return $http.get(routeJs('treedom_faq_votes', {id: id, type: type}))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {

      throw err.data || err
    })
  };

  utilityFactory.modal = function (open) {
    if (open) {
      this.blockScroll();
      $rootScope.modal = true
    } else {
      this.allowScroll();
      $rootScope.modal = false
    }
  };

  utilityFactory.startLoading = function (type) {
    type === 'big'
      ? $('#loader-big').css({ display: 'flex' })
      : $('#loader-small').css({ display: 'block' })
  };

  utilityFactory.stopLoading = function () {
    $('#loader-big').css({ display: 'none' });
    $('#loader-small').css({ display: 'none' })
  };

  utilityFactory.getCountriesName = function () {
    return $http.get(utilityFactory.root() + 'bundles/treedomnewfrontend/json/countries_name.min.json')
    .then(function(response) {
      return response.data || response
    })
  };

  utilityFactory.getWidgetData = function (type, id, extra) {
    return $http.get(routeJs('treedom_api_widget', {type: type, id: id, extra: extra}))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };

  /**
   * Trova le pubblicità legate ad un evento.
   * @param {string} id - ID dell'utente da cui prendere gli eventi. Se non
   * specificato, tira fuori ADV random.
   * @return {Array} - Array di ADV con massimo 10 items.
   */
  utilityFactory.getBusinessAdv = function (id) {
    var params = { id: id || 0 };
    return $http.get(routeJs('treedom_business_advs', params))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };

  /**
   * Assegna un click ad un ADV.
   * @param {string} id - ID dell'ADV a cui assegnare il click.
   */
  utilityFactory.clickedAdv = function (id) {
    return $http.get(routeJs('treedom_business_adv_click', { id: id }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };

  /**
   * Find banners.
   * @param {string} type - Banner type ['home', 'rightcolumn']
   */
  utilityFactory.getBanners = function (type) {
    if (!type) return;

    return $http.get(routeJs('treedom_api_banner', { type: type }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };

  utilityFactory.getAllWallComments = function (limit, offset, ln) {
    return $http.get(routeJs('treedom_all_wall_comments', { limit: limit, offset: offset, ln: ln }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };

  utilityFactory.getUserDisableReasons = function () {
    return $http.get(routeJs('treedom_user_disabled_reason'))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  };

  utilityFactory.slugify = function (text) {
    text = text.toString().toLowerCase().trim();

    var sets = [
      { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
      { to: 'c', from: '[ÇĆĈČ]' },
      { to: 'd', from: '[ÐĎĐÞ]' },
      { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
      { to: 'g', from: '[ĜĞĢǴ]' },
      { to: 'h', from: '[ĤḦ]' },
      { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
      { to: 'j', from: '[Ĵ]' },
      { to: 'ij', from: '[Ĳ]' },
      { to: 'k', from: '[Ķ]' },
      { to: 'l', from: '[ĹĻĽŁ]' },
      { to: 'm', from: '[Ḿ]' },
      { to: 'n', from: '[ÑŃŅŇ]' },
      { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
      { to: 'oe', from: '[Œ]' },
      { to: 'p', from: '[ṕ]' },
      { to: 'r', from: '[ŔŖŘ]' },
      { to: 's', from: '[ßŚŜŞŠ]' },
      { to: 't', from: '[ŢŤ]' },
      { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
      { to: 'w', from: '[ẂŴẀẄ]' },
      { to: 'x', from: '[ẍ]' },
      { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
      { to: 'z', from: '[ŹŻŽ]' },
      { to: '-', from: '[·/_,:;\']' }
    ];

    sets.forEach(function (set) {
      text = text.replace(new RegExp(set.from, 'gi'), set.to)
    });

    return text
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  };

  utilityFactory.powers = {
    foodsecurityPower: {
      name: $filter('trans')('speciePower.01.title'),
      description: $filter('trans')('speciePower.01.text'),
      // color: '#cdd67e',
      color: '#85b973',
      icon: 'bundles/treedomnewfrontend/images/speciesPage/speciePowers/speciePowers-01.svg',
      value: 0
    },
    co2Power: {
      name: $filter('trans')('speciePower.02.title'),
      description: $filter('trans')('speciePower.02.text'),
      color: '#90a6ba',
      icon: 'bundles/treedomnewfrontend/images/speciesPage/speciePowers/speciePowers-02.svg',
      value: 0
    },
    incomePower: {
      name: $filter('trans')('speciePower.03.title'),
      description: $filter('trans')('speciePower.03.text'),
      // color: '#fbd159',
      color: '#d3b570',
      icon: 'bundles/treedomnewfrontend/images/speciesPage/speciePowers/speciePowers-03.svg',
      value: 0
    },
    soilPower: {
      name: $filter('trans')('speciePower.04.title'),
      description: $filter('trans')('speciePower.04.text'),
      color: '#A2887A',
      icon: 'bundles/treedomnewfrontend/images/speciesPage/speciePowers/speciePowers-04.svg',
      value: 0
    }
  };

  utilityFactory.getPowersList = function () {
    return Object.keys(utilityFactory.powers)
  };

  utilityFactory.getPowers = function (products) {
    return utilityFactory.hydratePowers(utilityFactory.reducePowers(products))
  };

  utilityFactory.hydratePowers = function (powersObj) {
    return Object.keys(utilityFactory.powers).reduce((powers, powerKey) => {
      return {
        ...powers,
        [powerKey]: {
          ...utilityFactory.powers[powerKey],
          value: powersObj ? powersObj[powerKey] || 0 : 0,
          icon: $filter('asset')(utilityFactory.powers[powerKey].icon)
        }
      }
    }, {})
  };

  utilityFactory.getEmptyPowers = function() {
    return utilityFactory.hydratePowers(null)
  };

  utilityFactory.reducePowers = function(products) {
    const productsList = (Array.isArray(products) ? products : [products])
    const result = productsList.reduce((maxTotal, product) => {
      const nestedProduct = (product.product || product)
      const productPowers = nestedProduct.package && nestedProduct.package.products
        ? this.reducePowers(nestedProduct.package.products)
        : nestedProduct.specie.powers
      return Object.keys(productPowers).reduce((maxTemp, powerKey) => {
        return {
          ...maxTemp,
          [powerKey]: Math.max(maxTemp && maxTemp[powerKey] || 0, productPowers[powerKey])
        }
      }, { ...maxTotal })
    }, {})
    return result
  };


  // TODO: unify with cartBadgesController's getBadgesList method
  utilityFactory.reducePackageProductsBadges = function(products) {
    if (!(products && products.length)) return []
    const uniqueKey = `name.${$rootScope.ln}`
    const result = products.reduce((badgeList, product) => {
      product.specie.badges.filter(badge => {
        const inList = utilityFactory.getValueAtPath(badgeList, uniqueKey)
        const checkValue = utilityFactory.getValueAtPath(badge, uniqueKey)
        return !inList.includes(checkValue)
      }).map(b => badgeList.push(b))
      return badgeList
    }, [])
    return result
  };

  utilityFactory.getValueAtPath = function(source, path, fallback, separator = ".") {
    if (!(source && path))
      return fallback
    if (Array.isArray(source))
      return source.map(i => utilityFactory.getValueAtPath(i, path, fallback, separator))
    const [ key, ...nested ] = Array.isArray(path) ? path : path.split(separator)
    return nested && nested.length
      ? utilityFactory.getValueAtPath(source[key], nested, fallback, separator)
      : source[key] || fallback
  };

  return utilityFactory
}])
.factory('httpInterceptor', ['$q', '$rootScope', '$log', 'ipCookie', function ($q, $rootScope, $log, ipCookie) {
  $rootScope.loadingCount = 0;
  var loadingCount = 0;

  return {
    request: function (config) {
      if (++loadingCount === 1) {
        $rootScope.loadingCount++;
        $rootScope.$broadcast('loading:progress', $rootScope.loadingCount)
      }
      return config || $q.when(config)
    },
    response: function (response) {
      if (--loadingCount === 0) {
        $rootScope.loadingCount--;
        $rootScope.$broadcast('loading:finish', $rootScope.loadingCount)
      }
      return response || $q.when(response)
    },
    responseError: function (response) {
      if (--loadingCount === 0) {
        $rootScope.loadingCount--;
        $rootScope.$broadcast('loading:finish', $rootScope.loadingCount)
      }
      return $q.reject(response)
    }
  }
}])
.config(['$httpProvider', function ($httpProvider) {
  // modifica per explorer
  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.get = {}
  }

  $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
  $httpProvider.interceptors.push('httpInterceptor')
}]);
