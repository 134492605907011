import { Vue, Component } from 'vue-property-decorator'
import { getAsset } from '@f/utils/media'
import { getStaticUrl } from '@f/utils/assets'
import { fetchStaticData } from '@f/services/StaticData'

@Component({
  name: 'FetchStaticData'
})
export default class FetchStaticData extends Vue {

  /*--- DATA ----------*/
  loading = false
  fallbackDefault = false

  /*--- COMPUTED ------*/
  get fallbackToLocal() {
    return this.fallbackDefault || this.$env === 'DEV'
  }
  
  /*--- METHODS -------*/
  fetchData<T = any>(
    filePath: string,
    localPathSuffix = 'bundles/treedomnewfrontend') {
    if (!filePath) Promise.reject('File path not provided')
    this.loading = true
    const urls = [ getStaticUrl(filePath) ]
    if (this.fallbackToLocal) {
      const fullLocalPath = localPathSuffix.replace(/\/$/, '')+'/'+filePath.replace(/^\//,'')
      urls.push(getAsset(fullLocalPath))
    }
    return fetchStaticData<T>(urls)
      .finally(() => this.loading = false)
  }
}
