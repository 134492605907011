var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"answersContainer",staticClass:"answers",class:{ gridLayout: _vm.gridLayout }},_vm._l((_vm.answers),function(answer,index){return _c('div',{key:answer.description,staticClass:"answer",class:{
      answer__correct: _vm.showCorrectAnswerFeedback(answer),
      answer__wrong: _vm.showWrongAnswerFeedback(answer),
    },style:('--animation-order: ' + index + ';'),on:{"click":function($event){return _vm.onAnswer(answer, index)}}},[(answer.image)?_c('div',{staticClass:"answer-image",style:({backgroundImage:("url(" + (answer.image) + ")")})}):_vm._e(),_vm._v(" "),(answer.description)?_c('p',{staticClass:"answer-description",domProps:{"innerHTML":_vm._s(_vm.$t(answer.description))}}):_vm._e(),_vm._v(" "),_c('SlideDownTransition',{attrs:{"enter":350}},[(
          answer.isCorrect &&
          answer.explanation.correct &&
          _vm.showCorrectAnswerFeedback(answer)
        )?_c('span',{staticClass:"answer-explanation"},[_c('b',{staticClass:"user-choice-label",domProps:{"innerHTML":_vm._s(_vm.$t(answer.correct))}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(answer.explanation.correct))}})]):_vm._e()]),_vm._v(" "),_c('SlideDownTransition',{attrs:{"enter":350}},[(
          !answer.isCorrect &&
          answer.explanation.wrong &&
          _vm.showWrongAnswerFeedback(answer)
        )?_c('span',{staticClass:"answer-explanation"},[(_vm.isUserChoice(answer))?_c('b',{staticClass:"user-choice-label",domProps:{"innerHTML":_vm._s(_vm.$t(answer.wrong))}}):_vm._e(),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(answer.explanation.wrong))}})]):_vm._e()])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }