












































































































import {computed, defineComponent, provide, ref} from '@vue/composition-api'
import {DefaultApolloClient, useQuery, useResult} from '@vue/apollo-composable'
import {apiClient} from '@f/apollo'
import useRoutes from '@f/composables/useRoutes'
import {TREE} from '@f/graphql/queries'
import DotLoader from '@f/components/DotLoader.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import TreeWeatherClock from '@f/components/Tree/TreeWeatherClock.vue'
import TreeInfoCard from '@f/components/Cards/TreeInfoCard.vue'
import {maxWidth, useMediaQuery} from '@f/utils/breakpoints'
import {TrTree} from '@f/@types/graphql'
import useAuthentication from '@f/composables/useAuthentication'
import {getModalService, getRootScope, onEventBroadcast} from '@f/services/Angular'
import {ModalServiceCloseData} from '@f/@types/app/ngApp'
import TreeCardStatusBadge from '@f/components/Cards/contents/TreeCardStatusBadge.vue'
import {atLeastOnePublicMessage, isTreeOwner, isTreeOwnerOrGifter} from '@f/composables/useMessagesTree'
import UserGiftUpselling from "@f/pages/User/partials/UserGiftUpselling.vue";
import CelebrationModalTest from "@f/pages/User/partials/CelebrationModalTest.vue";
import MobileHeaderTreePage from "@f/pages/Tree/partials/MobileHeaderTreePage.vue";
import DownloadAppSnackBanner from '@f/components/DownloadAppSnackBanner.vue'

export default defineComponent({
  name: 'TreePage',
  methods: { isTreeOwner },
  components: {
    DownloadAppSnackBanner,
    MobileHeaderTreePage,
    TreeCardStatusBadge,
    DotLoader,
    Treecon,
    UserGiftUpselling,
    CelebrationModalTest,
    TreeWeatherClock,
    TreeInfoCard,
    TreeWeatherCanvas: () => import(/* webpackChunkName: "TreeWeatherCanvas" */ '@f/components/Tree/TreeWeatherCanvas.vue'),
    TreeNews: () => import(/* webpackChunkName: "TreeNews" */ '@f/pages/Tree/TreeNews.vue'),
    TreeInfo: () => import(/* webpackChunkName: "TreeInfo" */ '@f/pages/Tree/TreeInfo.vue'),
    TreeMessages: () => import(/* webpackChunkName: "TreeMessages" */ '@f/pages/Tree/TreeMessages.vue'),
  },
  props: {
    slug: {
      type: String,
      required: true
    },
    treeId: {
      type: String,
      required: true,
    }
  },
  setup(props: any) {
    provide(DefaultApolloClient, apiClient)

    const rootscope = getRootScope()
    const {isLoggedAs} = useAuthentication()
    const isMobile = useMediaQuery(maxWidth('md'))
    const {currentState, currentParams, href, isCurrentState, parseUserState, go, goBack} = useRoutes()
    const treeQueryVariables = computed(() => ({
      id: props.treeId
    }))

    const {result, loading, refetch, onError} = useQuery(
        TREE,
        treeQueryVariables,
        () => ({
          enabled: !!props.treeId,
          fetchPolicy: 'cache-first',
        })
    )

    onError(() => go('error404'))

    const refetchTreeData = () => {
      refetch(treeQueryVariables.value)
    }

    const tree = useResult<TrTree>(result)
    const isMine = computed(() => tree.value && isLoggedAs(tree.value.user!))
    const openEditName = ref(false)


    const diaryStateName = computed(() => {
      return tree.value?.user && parseUserState(tree.value.user, 'trees.item') || ''
    })

    const infoStateName = computed(() => {
      return tree.value?.user && parseUserState(tree.value.user, 'trees.item.info') || ''
    })

    const messagesStateName = computed(() => {
      return tree.value?.user && parseUserState(tree.value.user, 'trees.item.messages') || ''
    })

    const showMessagesTab = computed(() => {
      return isTreeOwnerOrGifter(tree.value) || atLeastOnePublicMessage(tree.value)
    })

    const routeComponent = computed(() => {
      if (loading.value) return 'DotLoader'
      if (currentState.value?.name?.includes('trees.item.info')) return 'TreeInfo'
      if (currentState.value?.name?.includes('trees.item.messages')) return 'TreeMessages'
      return 'TreeNews'
    })

    const treeTitle = computed(() => {
      return ['tree.title.private', {user: tree.value?.user?.title}]
    })

    const openGiftRecap = (tree?: TrTree) => {
      if (tree?.gift) {
        const modalService = getModalService() || null
        if (modalService) {
          const redeemModalId = modalService.open({
            templateUrl: 'v-modal-gift_recap.html',
            id: `treecode-redeem-${tree.gift.uuid}`,
            data: {tree: tree, gift: tree.gift, treeId: tree.treeId, giftInProgress: false, onChange: () => refetchTreeData()},
          })

          rootscope?.onMessage('gift-deleted', ()=> {
            modalService?.close(`treecode-redeem-${tree.gift?.uuid}`);
          })

          onEventBroadcast<[ModalServiceCloseData]>(modalService.closeEventKey, ({closedModalId}) => {
            if (closedModalId === redeemModalId) {
              refetch()
            }
          })
        }
      }
    }

    const openGiftFlow = (tree?: TrTree) => {
      const rootScope = getRootScope()
      if (!rootScope) return
      rootScope.openGiftComposer(undefined, tree?.treeId, 'treedom-user-tree')
      rootScope.onMessage('gift-created', () => {
        refetch()
      })
    }

    return {
      isMobile,
      isMine,
      openEditName,
      goBack,
      currentState,
      currentParams,
      diaryStateName,
      href,
      go,
      infoStateName,
      isCurrentState,
      loading,
      routeComponent,
      tree,
      treeTitle,
      refetchTreeData,
      openGiftRecap,
      openGiftFlow,
      messagesStateName,
      showMessagesTab,
    }
  }
})
