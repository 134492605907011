import { legacySlugify } from './strings'
import { CartProductType } from '@f/models/ecommerce'
import { formatCurrency } from '@f/utils'
import { PackageChildProduct, Product } from '@f/@types'
import { IsoCode } from '@f/@types/localization'
import { NoProductError } from "@f/models/errors";
import { extractPrice, getDiscountFromPrices } from './newEcommerce'
import { Price, Product as NewProduct } from '../services/NewEcommerce/types'

const productTypeSuffix = {
  'tree': 'tree',
  'tree_limited': 'limited',
  'package': 'pack',
  'plan': 'plan',
  'gadget': 'gadget'
}

const UNAVAILABLE_BADGES = ['soldOut', 'comingSoon']

export function productName(product: Product | PackageChildProduct) {
  const titleSource = (product.limited && product.limited.limitedName !== 'Limited Edition' && product.limited.limitedName)
    || ('package' in product && product.productName)
    || product.specie?.name
    return titleSource || ''
}

function getProductTypeSuffix(productType: CartProductType): string {
  return Object.prototype.hasOwnProperty.call(productTypeSuffix, productType) ?  productTypeSuffix[productType] : 'prod'
}

export function generateKey(id: string | number, productType: CartProductType, name: string): string {
  return `${name ? legacySlugify(name) + '-' : ''}${getProductTypeSuffix(productType)}_${id}`
}

export function getIdFromKey(key: string): number {
  return Number(key.split('_')[1])
}

export function canBeGifted(product: Product) {
  return product?.giftable && !product.package?.coupled
}

// FIXME: move derived data to apollo local type extension
export function isProductDiscounted(product: Product): boolean {
  return !!getDiscountFromPrices((product as unknown as NewProduct).prices as Price[], (product as unknown as NewProduct).fullPrices as Price[])
}

export function isOriginalPriceVisible(product: Product, userIsBusiness: boolean): boolean {
  return isProductDiscounted(product)
}

export function isPriceHighlighted(product: Product, userIsBusiness: boolean): boolean {
  return isOriginalPriceVisible(product, userIsBusiness) || isProductDiscounted(product)
}

export function isProductSoldOut(product: Product): boolean {
  return product.badge === 'soldOut'
}

export function isProductNew(product: Product): boolean {
  return product.badge === 'new'
}

export function price(price: string | number, language: IsoCode): string {
  return formatCurrency(Number(price) / 100, language)
}

export function productFullPrice(product: Product, language: IsoCode, quantity: number = 1): string {
    const newProduct = product as unknown as NewProduct
    return formatCurrency(extractPrice(newProduct.fullPrices as Price[]),language) 
}


export function isLimited(product: Product): boolean {
  return product.limited && product.limited.limitedName !== 'Limited Edition' || false
}

export function isHoroscope(product: Product): boolean {
  return product.limited?.limitedSerie === 'horoscope_2017';
}

export function hasLimitedSerie(product: Product, limitedSerie: string) {
  if(!(product && product.limited && product.limited.limitedSerie)) return false;

  return (Array.isArray(product.limited.limitedSerie))
    ? product.limited.limitedSerie.includes(limitedSerie)
    : product.limited.limitedSerie === limitedSerie
}

export function isAvailable(product?: Product): boolean | void {
  if (!product) {
    throw new NoProductError()
  }
  return !(product.badge && UNAVAILABLE_BADGES.includes(product.badge))
}

export function tankSizeAmount(product?: Product): number | void{
  if(!product) return;

  if(product.type === 'package') {
    return product.package?.products.reduce((total, product) => {
      return total + product.specie.tankSize;
    }, 0)
  } else {
    return product.specie?.tankSize;
  }
}
