





import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GoogleLoginButton',
  props: {
    fullWidth: {
      default: false,
      type: Boolean,
    }
  }
})
