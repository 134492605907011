/* @ngInject */
angular.module('countriesService', [])
.factory('Countries', function ($rootScope, $http) {
  // TODO: move uri on ENV or implement a local or api graphql server
  // const COUNTRIES_URI = 'https://restcountries.eu/rest/v2/'
  // const defaultApiFields = ['name', 'nativeName', 'alpha2Code', 'translations']
  // Temp local json immplementation
  const COUNTRIES_URI = `${($rootScope.baseUrlAssets || '/')}bundles/treedomnewfrontend/json/countriesV2.min.json`

  const countriesFactory = {
    regionalBlocs: {
      EU: 'European Union',
      EFTA: 'European Free Trade Association',
      CARICOM: 'Caribbean Community',
      PA: 'Pacific Alliance',
      AU: 'African Union',
      USAN: 'Union of South American Nations',
      EEU: 'Eurasian Economic Union',
      AL: 'Arab League',
      ASEAN: 'Association of Southeast Asian Nations',
      CAIS: 'Central American Integration System',
      CEFTA: 'Central European Free Trade Agreement',
      NAFTA: 'North American Free Trade Agreement',
      SAARC: 'South Asian Association for Regional Cooperation',
    },

    getCountries() {
      return $rootScope.cachedCountries ? Promise.resolve($rootScope.cachedCountries) : this.loadCountries()
    },

    getCountriesNativeName() {
      return this.getCountries().then(countries => this.getNativeNames(countries))
    },

    getBlocCountries(bloc) {
      if (!bloc) {
        return Promise.reject('Requested empty bloc.')
      } else if (!Object.keys(this.regionalBlocs).includes(bloc)) {
        return Promise.reject('Unknown regional block.')
      } else {
        return this.getCountries().then(countries => {
          return countries.filter(country => {
            return country.regionalBlocs.some(region => region.acronym === bloc)
          })
        })
      }
    },

    getBlocCountriesNativeName(bloc) {
      return this.getBlocCountries(bloc)
        .then(countries => this.getNativeNames(countries))
        .catch(error => error)
    },

    loadCountries() {
      return $http.get(COUNTRIES_URI)
        .then(response => {
          $rootScope.cachedCountries = response.data
          return $rootScope.cachedCountries
        })
        .catch(error => { console.log(error) })
    },

    getNativeNames(countries) {
      return countries.map(country => country.nativeName)
    },

    resolveNativeName(countryName) {
      if (!countryName) return Promise.resolve(countryName)
      
      return this.getCountries().then(countries => {
        return countries.find(c => {
          return c.nativeName === countryName
            || c.name === countryName
            || Object.values(c.translations).includes(countryName);
        })
      }).then(country => country && country.nativeName || countryName);
    }
  }

  return countriesFactory
})
