type StyleKey = keyof CSSStyleDeclaration
interface ClosestSearchResult<T> {
  el: HTMLElement
  result: T
}

export function closestElement<T> (
  originElement: HTMLElement,
  predicate: (el: HTMLElement) => T
) {
  return new Promise<ClosestSearchResult<T>>((resolve) => {
    if (originElement.parentElement) {
      let parent: HTMLElement | null = originElement.parentElement
      while (!!parent) {
        const test = predicate(parent)
        if (!!test) {
          resolve({ el: parent, result: test })
        }
        parent = parent?.parentElement || null
      }
    }
    resolve()
  })
}

export function closestElementWithStyle(
  originElement: HTMLElement,
  style: Partial<CSSStyleDeclaration> = {}
) {
  return closestElement(originElement, el => matchesStyle(el, style))
}

export function matchesStyle(
  testingElement: HTMLElement,
  matchingStyle: Partial<CSSStyleDeclaration>
) {
  const style = window.getComputedStyle(testingElement)
  return !Object.keys(matchingStyle)
    .some(prop => {
      return matchingStyle[prop as StyleKey] !== style[prop as StyleKey]
    })
}
