



















































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import TreeDiaryItem from '../mixins/TreeDiaryItem'
import VideoPlayer from '@f/components/VideoPlayer.vue'

@Component({
  name: 'TreeDiaryPost',
  components: { VideoPlayer },
})
export default class TreeDiaryPost extends Mixins(TreeDiaryItem) {
  @Prop({ type: Object }) readonly tree!: any
  @Prop({ type: Boolean, default: true }) readonly hasModal!: boolean

  // Computed
  get mediaStyle(): Partial<CSSStyleDeclaration> {
    return {
      backgroundImage: `url(${this.post.image && this.post.image.medium})`,
    }
  }

  get postHasImage(): boolean {
    return !!(this.post.image && this.hasModal)
  }

  get postHasVideo(): boolean {
    return !!(this.post.video && this.translate(this.post.video))
  }

  get postTypeClass(): string {
    return `treePost${this.post.postType[0].toUpperCase()}${this.post.postType
      .substr(1)
      .toLowerCase()}Type`
  }

  get postDate(): string {
    return this.$options.filters![
      this.post.postType === 'HOROSCOPE' ? 'month_year' : 'datetime'
    ](this.post.createdAt)
  }

  get accessGrantingUrl(): string {
    switch (this.post.postType.toUpperCase()) {
      case 'HOROSCOPE':
        return this.parseState('horoscope')
        break
      case 'LIVE':
        return this.parseState('species', { '#': 'EcommerceTanzania2019' })
        break
      default:
        return ''
    }
  }

  handleOpenModal() {
    this.$emit('triggerDataEvent', { action: 'open', element: 'post' })
    this.$emit('open-modal', {
      postId: this.post.id,
      treeId: (this.tree && this.tree.id) || null,
    })
  }
}
