import tagManagerService from '@f/services/TagManager';
import { validateDownload } from '@f/validation/datalayer';


export function DataEventDownload(
    downloadType: string,
    docName: string,
    pageUrl: string,
) {
    tagManagerService.push({
        event: 'download',
        downloadType,
        docName,
        pageUrl,
    }, validateDownload)
}
