












































































import { computed, defineComponent, provide, ref, watch } from '@vue/composition-api'
import DotLoader from '@f/components/DotLoader.vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apiClient } from '@f/apollo'
import Treecon from '@f/components/Treecons/Treecon.vue'
import useRoutes from '@f/composables/useRoutes'
import Map from '@f/components/Map/Map.vue'
import HUDNavigator from '@f/components/Map/HUD/HUDNavigator.vue'
import fillPage from '@f/composables/fillPage'
import { fetchOrganizationData } from '@f/composables/fetchUserData'
import HUDForestTrees from '@f/components/Map/HUD/HUDForestTrees.vue'
import HUDUserForests from '@f/components/Map/HUD/HUDUserForests.vue'
import { Event } from '@f/@types'
import HUDUserForestTrees from '@f/components/Map/HUD/HUDUserForestTrees.vue'
import MapClusterMarkers from '@f/components/Map/MapClusterMarkers.vue'
import { fetchForest } from '@f/services/Forest'
import HUDTreeCard from '@f/components/Map/HUD/HUDTreeCard.vue'
import TextTruncate from '@f/components/TextTruncate.vue'
import MapTreeMarker from '@f/components/Map/MapTreeMarker.vue'
import { FlyToOptions, Map as Mapbox } from 'mapbox-gl'

const useForestMapUrls = () => {
  const { href, currentState } = useRoutes()
  return computed(() => {
    const name = currentState.value?.name!
    const isPrivate = name?.startsWith('user')
    return {
      forestUrl: href(isPrivate ? 'user.event' : 'organization.event'),
      forestMapHomeUrl: href(isPrivate ? 'user.event.map' : 'organization.event.map'),
    }
  })
}

export default defineComponent({
  name: 'ForestMapPage',
  components: {
    MapTreeMarker,
    TextTruncate,
    HUDTreeCard,
    MapClusterMarkers,
    HUDUserForestTrees,
    HUDUserForests,
    HUDForestTrees,
    HUDNavigator,
    Map,
    Treecon,
    DotLoader,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    provide(DefaultApolloClient as any, apiClient)
    const mapRef = ref<Mapbox>()
    const { container } = fillPage()
    const { href, go, isCurrentState, currentParams } = useRoutes()
    const { user, forests } = fetchOrganizationData(props.slug)
    const userId = computed(() => Number(user.value?.info?.id) || null)
    const userName = computed(() => user.value?.info.firstname)
    const eventSlug = computed(() => currentParams.value?.eventslug)
    const treeId = computed(() => currentParams.value?.treeId)
    const event = ref<Event>()
    const eventId = computed(() => Number(event.value?.id) || null)
    const eventName = computed(() => event.value?.name)
    const urls = useForestMapUrls();
    const loading = computed(() => !user.value || !forests.value || !event.value)
    watch(eventSlug, async (forestSlug) => {
      if (forestSlug) {
        event.value = await fetchForest(forestSlug, true)
        return
      }
      event.value = undefined
    }, {
      immediate: true,
    })
    const isHomeView = computed(() => {
      return !treeId.value
    })
    const isTreeView = computed(() => {
      return !!treeId.value
    })
    const recenter = (options: FlyToOptions) => {
      mapRef.value?.flyTo(options)
    }
    const goToTree = (id: string) => go('.tree', { treeId: id })
    return {
      loading,
      href,
      isCurrentState,
      container,
      userId,
      userName,
      eventId,
      eventSlug,
      eventName,
      event,
      forests,
      treeId,
      urls,
      isHomeView,
      isTreeView,
      recenter,
      mapRef,
      goToTree,
    }
  }
})
