const OutsideClick = {
  bind: function(el, binding, vnode) {
    el.eventOnClick = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener("mouseup", el.eventOnClick);
  },
  unbind: function(el) {
    document.removeEventListener("mouseup", el.eventOnClick);
  },
};

export default OutsideClick;
