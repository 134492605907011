export const sanitizePatternMap = {
  '[àáâãäåæāăąạảấầẩẫậắằẳẵặ]': 'a',
  '[çćĉč]': 'c',
  '[ðďđþ]': 'd',
  '[èéêëēĕėęěẹẻẽếềểễệ]': 'e',
  '[ĝğģǵ]': 'g',
  '[ĥḧ]': 'h',
  '[ìíîïĩīįi̇ỉị]': 'i',
  '[ĵ]': 'j',
  '[ĳ]': 'ij',
  '[ķ]': 'k',
  '[ĺļľł]': 'l',
  '[ḿ]': 'm',
  '[ñńņň]': 'n',
  '[òóôõöøōŏőọỏốồổỗộớờởỡợǫǭơ]': 'o',
  '[œ]': 'oe',
  '[ṕ]': 'p',
  '[ŕŗř]': 'r',
  '[ßśŝşš]': 's',
  '[ţť]': 't',
  '[ùúûüũūŭůűųụủứừửữựư]': 'u',
  '[ẃŵẁẅ]': 'w',
  '[ẍ]': 'x',
  '[ýŷÿỳỵỷỹ]': 'y',
  '[źżž]': 'z',
  '[·/_,:;\']': '-',
}
