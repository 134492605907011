import { computed, onMounted, ref, Ref } from '@vue/composition-api'
import {
  fetchUserCO2,
  fetchUserForests,
  fetchUserInfo,
  fetchUserSummary,
} from '@f/services/User'
import { OrganizationBalancer, UserBalancer, UserCO2 } from '@f/@types'
import { TrEvent, TrUserSummary } from '@f/@types/graphql'
import useRoutes from './useRoutes'
import treedomNextClient from '../apollo/clients/api/treedom-next'
import {
  MeSubscriptionsQuery,
  PlanSubscriptionBadgeType,
  PlanSubscriptionConnection,
  PlanSubscriptionState,
  UserImpactsQuery,
  UserImpactsQueryVariables,
  User,
} from '../@types/treedom-next'
import {
  ME_SUBSCRIPTIONS,
  USER_SUBSCRIPTION_BADGES,
  USER_IMPACTS,
} from '../graphql/queries'

export interface PrivateUserData extends UserBalancer {
  summary?: TrUserSummary
  co2?: UserCO2
  hasActivePlan?: boolean
  activeSubscriptionStartDate?: string
  hasActiveCertificate?: boolean
  subscriptions?: PlanSubscriptionConnection
}

export interface OrganizationUserData extends OrganizationBalancer {
  summary?: TrUserSummary
}

const fetchSummary = async (
  user: Ref<PrivateUserData | OrganizationUserData | undefined>
) => {
  if (user.value?.info.id) {
    const summary = await fetchUserSummary(Number(user.value.info.id))
    user.value = { ...user.value, summary }
  }
}

export function fetchPrivateData(slug: string, autofetch = true) {
  const user = ref<PrivateUserData>()
  const { go } = useRoutes()
  const loading = ref(true)

  async function fetchInfo() {
    const { info, numbers } = await fetchUserInfo<UserBalancer>(slug)
    if (info.user_type === 'Business') {
      throw new Error('Trying to fetch private data of a Business user')
    }
    user.value = { ...user.value, info, numbers }
  }

  async function fetchCO2() {
    if (user.value?.info.id) {
      const co2 = await fetchUserCO2(Number(user.value.info.id))
      user.value = { ...user.value, co2 }
    }
  }

  async function fetchSubscriptions() {
    if (!user.value) return
    const {
      activeSubscriptionStartDate: adyenSubscriptionStartDate,
      subscriptions,
    } = await getUserSubscriptions(user.value.info.id)
    user.value = {
      ...user.value,
      hasActivePlan: !!adyenSubscriptionStartDate,
      activeSubscriptionStartDate: adyenSubscriptionStartDate,
      subscriptions,
    }
  }

  const MONTHS_AFTER_CERTIFICATE_IS_ACTIVE = 6
  async function getMeActiveSubscriptionCertificate() {
    if (!user.value) return
    const { activeSubscriptionStartDate: adyenSubscriptionStartDate } =
      await getUserSubscriptions(user.value.info.id)
    if (!adyenSubscriptionStartDate) return
    const startDate = new Date(adyenSubscriptionStartDate)
    const currentDate = new Date()

    const monthsSinceStartDate =
      (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
      (currentDate.getMonth() - startDate.getMonth())
    const hasActiveCertificate =
      monthsSinceStartDate > MONTHS_AFTER_CERTIFICATE_IS_ACTIVE
    user.value = { ...user.value, hasActiveCertificate }
  }

  onMounted(() => {
    if (autofetch) {
      fetchInfo()
        .then(async () => {
          await Promise.all([
            fetchSummary(user),
            fetchCO2(),
            fetchSubscriptions(),
            getMeActiveSubscriptionCertificate(),
          ])

          loading.value = false
        })
        .catch((error) => {
          if (
            error.message === 'Trying to fetch private data of a Business user'
          ) {
            go(
              'organization',
              {
                slug,
              },
              { location: 'replace' }
            )
            return
          }
          // there is no error code property, so we need to retrieve it from the message
          if (error.message.includes('404')) {
            go('error404', {}, { location: 'replace' })
            return
          }
          throw error
        })
    }
  })

  return {
    user,
    loading,
  }
}

export function fetchOrganizationData(slug: string, autofetch = true) {
  const user = ref<OrganizationUserData>()
  const forests = ref<TrEvent[]>([])

  async function fetchInfo() {
    const { info, numbers, users } = await fetchUserInfo<OrganizationUserData>(
      slug
    )
    user.value = { ...user.value, info, numbers, users }
  }

  onMounted(() => {
    if (autofetch) {
      fetchInfo()
        .then(() => {
          fetchSummary(user)
          fetchUserForests(Number(user.value?.info.id!)).then(
            (data) => (forests.value = data)
          )
        })
        .catch(() => {
          const { go } = useRoutes()
          go('error404')
        })
    }
  })

  return {
    user,
    forests,
    ...organizationFlags(user),
  }
}

export function organizationFlags(user: Ref<OrganizationUserData | undefined>) {
  const hide_forests = computed(() =>
    user.value?.info.hide_forests ? Number(user.value.info.hide_forests) : false
  )
  const hide_trees = computed(() =>
    user.value?.info.hide_trees ? Number(user.value.info.hide_trees) : false
  )
  const hide_wall = computed(() =>
    user.value?.info.hide_wall ? Number(user.value.info.hide_wall) : false
  )
  const hide_name = computed(() =>
    user.value?.info?.hide_name ? Number(user.value.info.hide_name) : false
  )
  const hide_emissions = computed(() =>
    user.value?.info?.hide_emissions
      ? Number(user.value.info.hide_emissions)
      : false
  )
  const hide_redeem = computed(() =>
    user.value?.info?.hide_redeem ? Number(user.value.info.hide_redeem) : false
  )
  const hide_countries = computed(() =>
    user.value?.info?.hide_projects
      ? Number(user.value.info.hide_projects)
      : false
  )
  const hide_overview_countries = computed(
    () => hide_countries.value || hide_trees.value
  )
  const hide_people = computed(() =>
    user.value?.info.hide_people ? Number(user.value.info.hide_people) : false
  )

  const commitment_start_at = computed(() =>
    user.value?.info.commitment_start_at
      ? new Date(user.value.info.commitment_start_at)
      : null
  )
  const commitment_end_at = computed(() =>
    user.value?.info.commitment_end_at
      ? new Date(user.value.info.commitment_end_at)
      : null
  )
  const is_committed = computed(() => {
    try {
      if (!commitment_start_at.value) return false

      if (!commitment_end_at.value) return true

      const now = new Date()

      if (now < commitment_start_at.value) return false

      if (now > commitment_end_at.value) return false

      return true
    } catch {
      return false
    }
  })

  const show_commitment = computed(
    () => is_committed.value && Number(user.value?.info.premium)
  ) // B2B Premium
  const show_subscriber = computed(
    () => is_committed.value && !Number(user.value?.info.premium)
  ) // B2B Small

  return {
    hide_forests,
    hide_trees,
    hide_wall,
    hide_name,
    hide_emissions,
    hide_redeem,
    hide_countries,
    hide_overview_countries,
    hide_people,
    is_committed,
    commitment_start_at,
    show_commitment,
    show_subscriber,
  }
}

export const getMeActiveSubscriptionPlan = () =>
  treedomNextClient
    .query<MeSubscriptionsQuery>({
      query: ME_SUBSCRIPTIONS,
    })
    .then(({ data }) => {
      const activePlan = data.me.subscriptions.edges.find(
        (plan) => plan.node.state === PlanSubscriptionState.Active
      )
      return activePlan
    })

export const fetUserImpacts = (id: string) =>
  treedomNextClient
    .query<UserImpactsQuery, UserImpactsQueryVariables>({
      query: USER_IMPACTS,
      variables: {
        id,
      },
    })
    .then(({ data }) => data.user.profile.impacts)

export const getUserSubscriptions = (id: string) =>
  treedomNextClient
    .query<{ user: User }>({
      query: USER_SUBSCRIPTION_BADGES,
      variables: {
        id,
      },
      errorPolicy: 'ignore',
    })
    .then(({ data }) => {
      const activeSubscriptionBadge = data.user.subscriptionBadges.find(
        (badge) =>
          badge.type === PlanSubscriptionBadgeType.OneActiveSubscription
      )

      const subscriptions = data.user.subscriptions

      return {
        activeSubscriptionStartDate:
          activeSubscriptionBadge?.startedAt ?? undefined,
        subscriptions,
      }
    })
