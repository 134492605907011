import 'cross-fetch/polyfill'
import { ApolloClient, from } from '@apollo/client/core'
import { RestLink } from 'apollo-link-rest'

import cache from '../shared/cache';
import { api } from '@f/configs/app';
import logErrorLink from '@f/apollo/links/logErrorLink'
import { createApolloAuthorizationLink } from '@f/auth/helpers'

const { baseUri: uri } = api;


const restLink = new RestLink({
  uri,
});

const authorizationLink = createApolloAuthorizationLink()
const client = new ApolloClient({
  link: from([logErrorLink, authorizationLink, restLink]),
  cache,
})

export default client
