import { IQService } from 'angular';
import {
  ApolloClient,
  ApolloQueryResult,
  OperationVariables,
  MutationOptions,
  QueryOptions,
  FetchResult,
} from '@apollo/client/core';

type ClientOption = {
  client?: string;
}

class Apollo {
  constructor(
    private _clients: Record<string, ApolloClient<any>>,
    private _defaultClient: string,
    private $q: IQService,
  ) {}

  public query<T, V = OperationVariables>(
    options: QueryOptions<V> & ClientOption
  ): angular.IPromise<ApolloQueryResult<T>> {
    const { client, ...queryOptions } = options;
    const verifiedClient = this.check(client);
    return this.wrap(verifiedClient.query<T>(queryOptions));
  }

  public mutate<T, V = OperationVariables>(
    options: MutationOptions<T, V> & ClientOption
  ): angular.IPromise<FetchResult<T>> {
    const { client, ...mutationOptions } = options;
    const verifiedClient = this.check(client);
    return this.wrap(verifiedClient.mutate<T, V>(mutationOptions));
  }

  private check(client?: string): ApolloClient<any> {
    const clientKey = client || this._defaultClient;
    if (!this._clients[clientKey]) {
      throw new Error(
        !!client
        ? `Client ${client} is missing`
        : 'Default client is missing.'
      );
    }
    return this._clients[clientKey];
  }

  private wrap<R>(promise: Promise<R>): angular.IPromise<R> {
    return this.$q((resolve, reject) => {
      promise.then(resolve).catch(reject);
    });
  }
}

class ApolloProvider implements angular.IServiceProvider {
  private _defaultClient: string = 'default';
  private _clients: Record<string, ApolloClient<any>> = {};

  public $get = ['$q', ($q: IQService) => new Apollo(this._clients, this._defaultClient, $q)];

  public setClients(clients: Record<string, ApolloClient<any>>) {
    this._clients = clients;
  }

  // Specify the default client key
  // when no "default" key is provided on clients list
  public setDefaultClient(clientKey: string) {
    this._defaultClient = clientKey;
  }
}

export default angular
  .module('angular-apollo', [])
  .provider('apollo', new ApolloProvider()).name;
