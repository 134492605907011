/* @ngInject */
export default function packageQtCardController($rootScope) {
  this.product = {}
  this.package = null
  this.quantity = this.quantity || 0
  this.imageBackground = {}
  this.name = ''
  this.qtSteps = [1, 5, 10, 20, 50, 100]

  this.$onInit = () => {
    this.package = this.product && this.product.package || null
    if(!this.package) return
    // Set icon
    const icon = this.package.icon && this.package.icon.medium || "";
    this.imageBackground['backgroundImage'] = `url(${icon})`
    // Set name
    this.name = this.package.name && this.package.name[$rootScope.ln] || this.product.name;
    // Set trees count
    this.product.units = this.getTreesCount();
    // Set template flags
    this.isPreview = this.template === 'preview'
    // Set prices and discount
    this.onSale = this.product.badge === 'discount'
    this.price = this.product.price / 100
    this.fullPrice = this.getFullPrice()
  }

  this.wrapClasses = () => {
    return {
      treeWrapPreview: this.isPreview,
      treeWrap: !this.isPreview,
      active: this.quantity
    }
  }

  this.getFullPrice = () => {
    return ((this.package.products || []).reduce((sum, product) => sum += product.price, 0) / 100)
  }

  this.getTreesCount = () => {
    return (this.package.products || []).reduce((sum, product) => sum += product.quantity, 0);
  }

  this.onClickCallback = () => {
    this.onClick({ product: this.product })
  }

  this.onChangeCallback = (qt) => {
    this.onChange({ qt, product: this.product })
  }

  Object.defineProperty(this, 'isMobile', { get: () => !!$rootScope.isMobile })
}
