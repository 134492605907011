import {TrTree, TrTreeMessage} from "../@types/graphql"
import {getRootScope} from "@f/services/Angular";

export const treeMessages = (tree: TrTree | undefined) => {
    return tree?.messages || []
}

export function atLeastOnePublicMessage(tree: Readonly<TrTree | undefined>): boolean {
    const messages = treeMessages(tree)
    if (messages.length) {
        const publicMessage = messages.find(message => message && !message.isPrivate)
        return !!publicMessage
    } else return false
}

export function isTreeOwner(tree: Readonly<TrTree | undefined>): boolean {
    const rootScope = getRootScope()
    return !!(tree && rootScope &&
        tree.user?.slug === rootScope.user);
}

export function isTreeGifter(tree: Readonly<TrTree | undefined>): boolean {
    const rootScope = getRootScope()
    return !!(tree && rootScope &&
        tree.initialUser?.slug === rootScope.user);
}

export function isTreeOwnerOrGifter(tree: Readonly<TrTree | undefined>): boolean {
    const isUserTreeOwner = isTreeOwner(tree)
    const isUserTreeGifter = isTreeGifter(tree)
    return isUserTreeOwner || isUserTreeGifter
}

export function canAddMessage(tree: Readonly<TrTree | undefined>): boolean {
    const isUserTreeOwner = isTreeOwner(tree)
    const isUserTreeGifter = isTreeGifter(tree)

    if (tree && (isUserTreeOwner || isUserTreeGifter)) {
        const messages = treeMessages(tree)

        if (messages.length === 0) return true
        if (messages.length > 1) return false

        const isMessageFromTreeOwner = messages[0]?.user?.slug === tree.user?.slug;
        const isMessageFromTreeGifter = messages[0]?.user?.slug === tree.initialUser?.slug;

        const canTreeOwnerLeaveMessage = isUserTreeOwner && !isMessageFromTreeOwner
        const canTreeGifterLeaveMessage = isUserTreeGifter && !isMessageFromTreeGifter

        return canTreeOwnerLeaveMessage || canTreeGifterLeaveMessage;
    } else return false
}

export function canEditMessage(tree: Readonly<TrTree | undefined>, message: TrTreeMessage): boolean {
    const user = getRootScope()?.user as string
    const isMessageUser = message.user?.slug !== undefined && message.user?.slug === user
    const isTreeUser = tree?.user?.slug !== undefined && tree?.user?.slug === user
    return isMessageUser && isTreeUser
}