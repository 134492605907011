angular.module('ngApp')
.controller('newMapSingleTreeController', ['$scope', '$rootScope', 'Map', '$state', '$filter', function ($scope, $rootScope, Map, $state, $filter) {
  var mapboxgl = window.mapboxgl
  mapboxgl.accessToken = 'pk.eyJ1IjoidG9tbWFzb3NwZXJvbmkiLCJhIjoiY2tnOTE3eW12MDJqazMybXNzOWV1YjloOSJ9.wtGsuDU7XIKjcv2cq8CiXw'

  $scope.map = {}

  /**
   * Create map and store it as $scope.map
   */
  var createMap = function (containerId) {
    $scope.map[containerId] = new mapboxgl.Map({
      container: containerId || 'map',
      center: [-20.663872148719975, 18.607476500170065],
      zoom: 7,
      style: 'mapbox://styles/tommasosperoni/cj78p16077drf2sshtkd6gjjf',
      preserveDrawingBuffer: true
    })

    // $scope.map.addControl(new window.mapboxgl.NavigationControl())
  }

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    for (var key in $scope.map) {
      var map = $scope.map[key]
      var elem = document.getElementById(key)
      if (elem) elem.innerHTML = ''
      map.remove()
      delete $scope.map[key]
    }
  })

  /**
   * Create a marker of type 'tree'
   * @param {Object} marker
   * @param {Object} containerId - ID of HTML element
   * @param {Object} nursery - If true, render nursery icon
   */
  var createTreeMarker = function (marker, containerId, nursery) {
    var el = document.createElement('div')
    el.className = nursery ? 'markerGLNursery' : 'markerGL markerGLmine'
    el.style.backgroundImage = !nursery
      ? 'url("https://s3-eu-west-1.amazonaws.com/' + $rootScope.public_bucket + '/images/' + 'medium' + '_' + marker.icon_picture + '")'
      : 'url("' + $filter('asset')('bundles/treedomnewfrontend/images/map/nursery.svg') + '")'

    if (marker.user_avatar) {
      var elUser = document.createElement('div')
      elUser.className = 'markerGLUser'
      elUser.style.backgroundImage = 'url("https://s3-eu-west-1.amazonaws.com/' + $rootScope.public_bucket + '/images/' + 'medium' + '_' + marker.user_avatar + '")'
      el.append(elUser)
    }

    new mapboxgl.Marker(el, {offset: [-45 / 2, -45 / 2]})
    .setLngLat([marker.lng, marker.lat])
    .addTo($scope.map[containerId])
  }

  /**
   * Create a marker of type 'tree'
   * @param {Object} marker
   * @param {Object} containerId - ID of HTML element
   * @param {Object} nursery - If true, render nursery icon
   */
  var createFakeMap = function (marker, containerId, nursery) {
    var mapElement = document.getElementById(containerId)
    if (!mapElement) return

    var fakeMapImagePath = $filter('asset')('bundles/treedomnewfrontend/images/map/fakeMap.png')

    var fakeMapElement = document.createElement('div')
    fakeMapElement.style.width = '100%'
    fakeMapElement.style.height = '100%'
    fakeMapElement.style.position = 'absolute'
    fakeMapElement.style.backgroundImage = 'url("' + fakeMapImagePath + '")'
    fakeMapElement.style.backgroundPosition = 'center'
    fakeMapElement.style.backgroundSize = 'cover'

    mapElement.append(fakeMapElement)

    var markerElement = document.createElement('div')
    markerElement.style.position = 'absolute'
    markerElement.style.width = '60px'
    markerElement.style.height = '60px'
    markerElement.style.left = 'calc(50% - 30px)'
    markerElement.style.top = 'calc(50% - 30px)'
    markerElement.style.backgroundPosition = 'bottom center'
    markerElement.style.backgroundSize = '80%'
    markerElement.className = nursery ? 'markerGLNursery' : 'markerGL markerGLmine'
    markerElement.style.backgroundImage = !nursery
      ? 'url("https://s3-eu-west-1.amazonaws.com/' + $rootScope.public_bucket + '/images/' + 'medium' + '_' + marker.icon_picture + '")'
      : 'url("' + $filter('asset')('bundles/treedomnewfrontend/images/map/nursery.svg') + '")'

    fakeMapElement.append(markerElement)
  }

  var initMap = function (treeId, containerId, nurseryMarker, fakeMap) {
    if (!fakeMap) createMap(containerId)

    Map.getTree(treeId, nurseryMarker)
    .then(function(response) {

      response = response.data || response
      var tree = response.tree[0]

      if (fakeMap) {
        createFakeMap(tree, containerId, nurseryMarker)
        return
      }

      createTreeMarker(tree, containerId, nurseryMarker)
      $scope.map[containerId].flyTo({center: [tree.lng, tree.lat]})
    })
  }

  /**
   * Ascoltatore per inizializzare la mappa da altri controller
   * @param {string} data.treeId - Tree ID
   * @param {string} data.containerId - Container ID
   * @param {boolean} data.nursery - If true render nursery icon as a marker
   */
  var removeMe = $scope.$on('LoadSingleTreeMap', function (event, data) {
    if (!data.treeId) return
    initMap(data.treeId, data.containerId, data.nursery, data.fakeMap)
  })
  $scope.$on('$destroy', removeMe)
}])
