




























































import {Component, Prop, Vue} from 'vue-property-decorator'
import axios from 'axios'
import DotLoader from '@f/components/DotLoader.vue'
import MediaKit from '@f/components/Profile/MediaKit.vue'
import SlideDownTransition from '@f/transitions/SlideDown.ts'
import {IsoCode} from '@f/@types/localization'
import {getMedia} from '@f/utils'
import {registerAxiosAuthorizationInterceptor} from '@f/auth/helpers'
import {getMeActiveSubscriptionPlan} from '@f/composables/fetchUserData'
import UsersNumbersMediakit from '@f/components/Profile/UsersNumbersMediakit.vue'
import treedomNextClient from '@f/apollo/clients/api/treedom-next'
import {BUSINESS_SUBSCRIPTION_CERTIFICATE} from '@f/graphql/queries'
import BusinessSubscriptionMediaKit from "@f/components/Profile/BusinessSubscriptionMediaKit.vue";

interface Achievement {
    id: number
    icon_id: string
    archived: boolean
    label: Record<IsoCode, string>
    description: Record<IsoCode, string>
    startDate?: string
    endDate?: string
    url: Record<IsoCode, string>
  }

  const businessApiClient = axios.create({
    baseURL: window.businessUrl,
  })

  registerAxiosAuthorizationInterceptor(businessApiClient)

  @Component({
    name: 'BusinessMediaKits',
    components: {
      BusinessSubscriptionMediaKit,
      UsersNumbersMediakit,
      DotLoader,
      MediaKit,
      SlideDownTransition,
    }
  })
  export default class BusinessMediaKits extends Vue {
    /*--- MODEL ---------*/

    /*--- PROPS ---------*/
    @Prop({ type: String, default: "settings" }) readonly location!: "settings" | "profile"

    /*--- DATA ----------*/
    loadingCertificate = false
    loadingAchievements = false
    achievements: Achievement[] = []
    certificate: string | null = null
    archiveIsOpen = false
    hasNumbersWidgetActive = false;

    b2bCertificate = {
      status: 'inactive' as 'inactive' | 'active-enough' | 'active-not-enough' | 'unsupported-subscription',
      loaded: false,
      visible: true,
      remainingTime: undefined as number | undefined,
      description: {
        it: 'Acquista i tuoi primi 15 alberi quest\'anno per scaricare il Treedom certificate',
        en: 'Purchase your first 15 trees this year to download the Treedom certificate',
        es: 'Compra tus primeros 15 árboles de este año para descargar el certificado Treedom',
        de: 'Kaufe deine ersten 15 Bäume dieses Jahr um das Treedom-Zertifikat herunterzuladen',
        fr: 'Achetez vos 15 premiers arbres cette année pour télécharger le Treedom certificate'
      }
    }

    treedomCounterDescription = {
      it: 'Abbonati al piano Un albero al giorno per ottenere il Tree counter',
      en: 'Subscribe to the One Tree a Day plan tto unlock the Tree counter',
      es: 'Suscríbete al plan Un árbol al día para obtener el contador de árboles',
      de: 'Abboniere das "One Tree a Day" Abo um den Tree counter zu erhalten',
      fr: 'Abonnez-vous au plan Un arbre par jour pour obtenir le Tree counter'
    }


    /*--- COMPUTED ------*/
    get loading(): boolean {
      return this.loadingCertificate || this.loadingAchievements
    }

    get businessUrl(): string {
      return window.businessUrl || '';
    }

    get currentAchievements(): Achievement[] {
      return this.achievements
        .filter(({ archived }) => !archived)
    }

    get achievementArchive(): Achievement[] {
      return this.achievements
        .filter(({ archived }) => !!archived)
    }

    /*--- WATCHERS ------*/

    /*--- REFS ----------*/
    $refs!: Vue['$refs'] & {}
    /*--- EVENTS --------*/
    mounted() {
      this.fetchCertificate()
      this.fetchAchievements()
      this.fetchHasNumbersWidgetActive()
      this.fetchSubscriptionBadge()
    }

    fetchCertificate() {
      this.loadingCertificate = true
      return businessApiClient.get<{ active: boolean }>(`/ck/certificate/get`)
        .then(({ data }) => {
          if (data.active) {
            this.certificate = `/prints/${this.$rootScope.ln}/b2b/certificate/${this.$rootScope.user}`
          }
        })
        .catch(() => {})
        .finally(() => { this.loadingCertificate = false })
    }

    fetchAchievements() {
      return businessApiClient.get(`/ck/mine`)
        .then(({ data }) => { this.achievements = data })
        .catch(() => {})
        .finally(() => { this.loadingAchievements = false })
    }

    fetchHasNumbersWidgetActive() {
      return getMeActiveSubscriptionPlan().then((activePlan) => {
        if (!activePlan) return
        this.hasNumbersWidgetActive = [
          'un-albero-al-giorno',
          'un-albero-al-giorno-yearly',
        ].includes(activePlan.node.product.identifier)
      })
    }
    async fetchSubscriptionBadge() {
      try {

        const { data } = await treedomNextClient.query({ query: BUSINESS_SUBSCRIPTION_CERTIFICATE })

        const activeSubscription = data?.me.subscriptions?.edges?.find((e: any) => e.node.state === 'ACTIVE')?.node
        this.b2bCertificate = { ...this.b2bCertificate, loaded: true }

        if (!activeSubscription) return

        const availableSkus = [
          // 'subscription-business-starter', not this one
          'subscription-business-essentials',
          'subscription-business-premium'
        ]
        if(!availableSkus.includes(activeSubscription.product.sku)) {
          this.b2bCertificate = {
            ...this.b2bCertificate,
            status: 'unsupported-subscription',
          }
          return
        }

        const monthsToUnlock = 2 // number of months to wait to unlock the certificate
        const threeMonthsAgo = new Date().setMonth(new Date().getMonth() - monthsToUnlock)
        const subscriberSince = new Date(activeSubscription.createdAt)
        const isSubscribedEnough = subscriberSince.getTime() < threeMonthsAgo

        this.b2bCertificate = {
          ...this.b2bCertificate,
          status: isSubscribedEnough ? 'active-enough' : 'active-not-enough',
          remainingTime: isSubscribedEnough ? undefined: subscriberSince.getTime() - threeMonthsAgo
        }

      } catch (e) {
        console.error(e)
        this.b2bCertificate = {
          ...this.b2bCertificate,
          loaded: true,
          visible: true,
          status: 'inactive',
          remainingTime: undefined
        }
      }}

    iconUrl(iconId: string) {
      return getMedia(iconId, 'medium', 'jpg')
    }
  }
