import { computed, onBeforeUnmount, onMounted, ref, watch, WatchSource } from '@vue/composition-api'
import { Feature, Point } from 'geojson'
import { Map as Mapbox, Marker } from 'mapbox-gl'

const useMarker = (coordinates: WatchSource<number[]>, map: Mapbox) => {
  const root = ref<HTMLElement>()
  const markerRef = ref<Marker>()
  onMounted(() => {
    markerRef.value = new Marker(root.value, {
      anchor: 'bottom',
    })
      .setLngLat([0, 0])
      .addTo(map)

  })
  watch([coordinates, markerRef], ([coordinates, marker]) => {
    if (!marker) return
    (marker as Marker)?.setLngLat(coordinates as [number, number])
  })
  onBeforeUnmount(() => {
    markerRef.value?.remove()
  })
  return root
}

export default useMarker