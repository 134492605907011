import scssVariables from '@f/scss/design-system/_export.scss'

const ERROR_COLOR = '#FF00FF' as const

export function scssColor(key: string) {
  const fullKey = 'color-'+key
  return fullKey in scssVariables
    ? scssVariables[fullKey]
    : ERROR_COLOR
}
