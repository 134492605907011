angular.module('ngApp')
// NOTE: Controller usato ancora nell'iframe.
.controller('nurseryController', ['$scope', '$rootScope', '$stateParams', '$state', 'User', function ($scope, $rootScope, $stateParams, $state, User) {
  $scope.event = false
  $scope.nurseryTrees = {}
  $rootScope.selectedNurserySpecie = false

  if ($state.current.name === 'user.nursery') {
    User.businessNurseryOriginatorTrees($rootScope.showUser.info.id, $stateParams.projectId, 'all')
    .then(function(response) {
      const data = response.data || response
      $scope.nurseryTrees = data['trees']
      $scope.project = data['project']
      for (var key in $scope.nurseryTrees) {
        var obj = $scope.nurseryTrees[key]
        $scope.goToSpecie(obj.slug, obj.id)
        break
      }
    })
  } else if ($state.current.name === 'user.event.nursery') {
    $scope.event = $stateParams.eventslug

    if (typeof $scope.eventSelected === 'undefined') {
      $state.go('user')
    }

    User.businessNurseryOriginatorTrees($rootScope.showUser.info.id, $stateParams.projectId, $scope.eventSelected.id)
    .then(function(response) {
      const data = response.data || response
      $scope.nurseryTrees = data['trees']
      $scope.project = data['project']
      for (var key in $scope.nurseryTrees) {
        var obj = $scope.nurseryTrees[key]
        $scope.goToSpecie(obj.slug, obj.id)
        break
      }
    })
    // seleziono il primo albero
  } else if ($state.current.name === 'iframe.nursery') {
    User.businessNurseryOriginatorTrees($scope.user, $stateParams.projectId, 'all')
    .then(function(response) {
      const data = response.data || response
      $scope.nurseryTrees = data['trees']
      $scope.project = data['project']
      for (var key in $scope.nurseryTrees) {
        var obj = $scope.nurseryTrees[key]
        $scope.goToSpecie(obj.slug, obj.id)
        break
      }
    })
  } else if ($state.current.name === 'iframe.event.nursery') {
    $scope.event = $stateParams.eventslug

    if (typeof $scope.eventSelected === 'undefined') {
      $state.go('user')
    }

    User.businessNurseryOriginatorTrees($rootScope.showUser.info.id, $stateParams.projectId, $scope.eventSelected.id)
    .then(function(response) {
      const data = response.data || response
      $scope.nurseryTrees = data['trees']
      $scope.project = data['project']
      for (var key in $scope.nurseryTrees) {
        var obj = $scope.nurseryTrees[key]
        $scope.goToSpecie(obj.slug, obj.id)
        break
      }
    })
  }

  $scope.goBack = function () {
    $rootScope.selectedNurserySpecie = false

    if (!$rootScope.iframe) {
      (!$scope.event)
      ? $state.go('user')
      : $state.go('user.event')
    } else {
      (!$scope.event)
      ? $state.go('iframe')
      : $state.go('iframe.event')
    }
  }

  $scope.goToSpecie = function (slug, treeId) {
    $rootScope.selectedNurserySpecie = slug
    if (!$rootScope.iframe) {
      !$scope.event
        ? $state.go('user.nursery.specie', {treeId: slug})
        : $state.go('user.event.nursery.specie', {treeId: slug})

      if ($rootScope.hardware === 'mobile') {
        var someElement = angular.element(document.getElementById('nursery-anchor'))
        var height = someElement.offset().top
        document.getElementById('nursery-container').scrollTop = height
      }
    } else {
      !$scope.event
        ? $state.go('iframe.nursery.specie', {treeId: slug})
        : $state.go('iframe.event.nursery.specie', {treeId: slug})

      var someElement = angular.element(document.getElementById('nursery-anchor'))
      var height = someElement.offset().top
      document.getElementById('nursery-container').scrollTop = height
    }
  }
}])
