import chunk from "lodash/chunk"

angular.module('ngApp')
.controller('advController', ['$scope', '$rootScope', '$state', '$stateParams', 'Utility', '$interval', '$timeout', function ($scope, $rootScope, $state, $stateParams, Utility, $interval, $timeout) {

  $scope.advList = []
  $scope.activeAdvs = []
  $scope.activeAdv = null
  $scope.advInterval = null
  $scope.advTimeRefresh = 30000

  /**
   * Trova gli adv
   * @param {string} id - ID utente, non obbligatorio.
   */
  var getBusinessAdv = function (id) {
    var userId = id || 0

    Utility.getBusinessAdv(userId)
    .then(function(res) {
      res = res.data || res
      $scope.advList = res
      startAdvStaticCycle()
    })
    .catch(function() {
      $scope.advList = []
      $scope.activeAdv = null
    })
  }

  var startAdvStaticCycle = function () {
    if (!$scope.advList.length) return

    var chunkedAdvList = chunk($scope.advList, 1)

    var activeIndex = 0
    $scope.activeAdvs = chunkedAdvList[activeIndex]

    if (chunkedAdvList.length > 1) {
      $scope.advInterval = $interval(function () {
        activeIndex++
        if (activeIndex === chunkedAdvList.length) activeIndex = 0
        $scope.activeAdvs = chunkedAdvList[activeIndex]
      }, $scope.advTimeRefresh)
    }
  }

  $scope.registerClick = function (advId) {
    Utility.clickedAdv(advId)
    .then(function() {
 })
    .catch(function() {
 })
  }

  // Watcher sulle informazioni dell'utente di cui stai visitando la pagina.
  $scope.$watch('pageUser', function (pageUser) {
    var id = pageUser
      ? pageUser.info.user_type === 'Business'
        ? pageUser.info.id
        : '0'
      : '0'

    getBusinessAdv(id)
  })

  // Watcher cambio di stato.
  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    $interval.cancel($scope.advInterval)
  })
}])
