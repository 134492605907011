








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DotLoader extends Vue {
  @Prop({ type: String, default: '#3DD368' }) readonly color!: string
  @Prop({ type: Number, default: 1 }) readonly size!: number
  @Prop({ type: Boolean, default: false }) readonly absolute!: boolean

  // Computed
  get mainStyle() {
    return { fontSize: `${this.size}em` }
  }
  get dotStyle() {
    return { background: this.color }
  }
}
