import { Vue, Component } from "vue-property-decorator";
import { IsoCode } from "@f/@types/localization";

interface FlagOptions {
  flag: string
  label: string
  colorClassList: string | string[]
}

const FLAGS_MAP : Record<IsoCode, FlagOptions> = {
  'it': { flag: 'flag-stripes-mmm', label: 'Italiano', colorClassList: 'color_1_green color_2_white color_3_red' },
  'en': { flag: 'flag-union-jack', label: 'English', colorClassList: 'color_1_blue color_2_white color_3_red' },
  'de': { flag: 'flag-stripes-mmm', label: 'Deutsch', colorClassList: 'color_1_black color_2_red color_3_yellow rotate_90' },
  'fr': { flag: 'flag-stripes-mmm', label: 'Français', colorClassList: 'color_1_blue color_2_white color_3_red' },
  'es': { flag: 'flag-stripes-sls', label: 'Español', colorClassList: 'color_1_red color_2_yellow color_3_red rotate_90' },
}

@Component({
  name: "ShowLanguages",
})
export default class ShowLanguages extends Vue {
  /*--- DATA ----------*/
  flagsMap = FLAGS_MAP;
  supportedLocales: IsoCode[] = []

  /*--- COMPUTED ------*/
  get activeLanguage(): IsoCode {
    return this.$rootScope.ln as IsoCode
  }

  get activeLanguageFlag(): FlagOptions {
    return this.$rootScope.ln
      && this.flagsMap[this.$rootScope.ln as IsoCode]
      || { flag: '', label: '', colorClassList: '' }
  }

  /*--- EVENTS --------*/
  created(): void {
    this.supportedLocales = window.supportedLocales || []
  }
}
