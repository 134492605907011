











































import {PuzzlePiece} from '.'
import {finalDimensions, styled} from './Math'
import Treecon from '@f/components/Treecons/Treecon.vue';
import {computed, defineComponent, onMounted, ref, watch} from "@vue/composition-api";
import {
  DragMoveEvent,
  DragStartEvent,
  Droppable,
  DroppableDroppedEvent,
  DroppableReturnedEvent
} from '@shopify/draggable'

const skeletonPieces: PuzzlePiece[] = [
  {
    src: require('@f/images/puzzle/Skeleton/PNG/Piece01.png'),
    border: {right: true}
  },
  {
    src: require('@f/images/puzzle/Skeleton/PNG/Piece02.png'),
    border: {bottom: true, right: true}
  },
  {
    src: require('@f/images/puzzle/Skeleton/PNG/Piece03.png'),
    border: {bottom: true}
  },
  {
    src: require('@f/images/puzzle/Skeleton/PNG/Piece04.png'),
    border: {top: true}
  },
  {
    src: require('@f/images/puzzle/Skeleton/PNG/Piece05.png'),
    border: {left: true, right: true}
  },
  {
    src: require('@f/images/puzzle/Skeleton/PNG/Piece06.png'),
    border: {}
  }
]


const puzzlePieces: PuzzlePiece[] = [
  {
    src: require('@f/images/puzzle/Puzzle/PNG/Piece01.png'),
    border: {right: true}
  },
  {
    src: require('@f/images/puzzle/Puzzle/PNG/Piece02.png'),
    border: {bottom: true, right: true}
  },
  {
    src: require('@f/images/puzzle/Puzzle/PNG/Piece03.png'),
    border: {bottom: true}
  },
  {
    src: require('@f/images/puzzle/Puzzle/PNG/Piece04.png'),
    border: {top: true}
  },
  {
    src: require('@f/images/puzzle/Puzzle/PNG/Piece05.png'),
    border: {left: true, right: true}
  },
  {
    src: require('@f/images/puzzle/Puzzle/PNG/Piece06.png'),
    border: {}
  }
]

let droppableOrigin = ""

export default defineComponent({
  name: "PuzzleGame",
  setup(_, {emit}) {
    const body = document.querySelector("body");

    const skipped = ref(false)

    const isDragging = ref(false);

    const puzzleContainer = ref<HTMLElement | null>(null);

    const dimensions = finalDimensions(puzzlePieces);

    const droppableInstance = ref<Droppable>();

    const correctDroppedPieces = ref([] as string[]);

    const styledPuzzlePieces = puzzlePieces
        .map(styled)
        .map((piece) => ({...piece, sort: Math.random()})) //random sorting
        .sort((a, b) => a.sort - b.sort);

    const styledSkeletonPieces = ref(skeletonPieces.map(styled));

    const puzzleCompleted = computed(() => correctDroppedPieces.value.length === styledPuzzlePieces.length);

    const onDragStart = (evt: DragStartEvent) => {
      isDragging.value = true;
      const droppableTarget = (evt.originalSource.parentNode as any)?.dataset.target as string;
      // the user can't move a puzzle piece when is placed correctly
      if (droppableTarget !== "puzzle")
        evt.cancel();
      droppableOrigin = (evt.originalSource.parentNode as any)?.dataset.dropzone as string;
    };

    const onPuzzleDropped = (evt: DroppableDroppedEvent) => {
      const target = evt.dropzone.dataset.target;
      const targetDropzone = evt.dropzone.dataset.dropzone;
      // the user can drop the element only in the corresponding dropzone of the skeleton and the puzzle
      if (droppableOrigin !== targetDropzone)
        return evt.cancel();
      // if the user drop the element in the skeleton I save the element as solved
      if (target === "skeleton") {
        correctDroppedPieces.value = Array.from(new Set([...correctDroppedPieces.value, droppableOrigin]));
      }
    };

    const onPuzzlePieceReturned = (evt: DroppableReturnedEvent) => {
      const dropzone = evt.dropzone.dataset.dropzone;
      // if the user place the element in the original place, I remove the current piece from the correct placed pieces
      correctDroppedPieces.value = correctDroppedPieces.value.filter(piece => piece !== dropzone);
    };

    const onDragMove = (evt: DragMoveEvent) => {
      const condition = evt.originalSource.classList.contains('draggable-source--is-dragging') && evt.originalSource.classList.contains('draggable--original');
      if (condition) {
        evt.cancel();
        resetErrorOnDrag();
      }
    }

    const onDragStop = () => {
      isDragging.value = false;
      resetErrorOnDrag();
    };

    const resetErrorOnDrag = () => {
      setTimeout(() => {
        document.querySelectorAll('.draggable-source--is-dragging').forEach(e => e.remove())
        document.querySelectorAll('.draggable-mirror').forEach(e => e.remove())
        document.querySelectorAll('.draggable--original').forEach((e) => e.setAttribute('style', 'display: \'initial\''))
      }, 10)
    }

    const loadDroppablePuzzlePieces = () => {
      const containers = document.querySelectorAll(".puzzleContainer");
      const instance = new Droppable(containers, {
        dropzone: ".dropzone",
        draggable: ".draggable",
        mirror: {
          constrainDimensions: true
        },
      });
      instance.on("drag:start", onDragStart);
      instance.on("drag:stop", onDragStop);
      instance.on("drag:move", onDragMove);
      instance.on("droppable:dropped", onPuzzleDropped);
      instance.on("droppable:returned", onPuzzlePieceReturned);
      droppableInstance.value = instance;
      window.scrollTo(0, document.body.scrollHeight);
      body?.setAttribute('style', 'overflow: hidden')
    };

    watch([puzzleCompleted, isDragging, skipped], () => {
      if (puzzleCompleted.value && !isDragging.value || skipped.value) {
        body?.setAttribute('style', '')
        emit('complete', {result: true})
        setTimeout(
            () => {
              puzzleContainer.value?.scrollIntoView({behavior: "smooth", block: "end"})
            },
            1300
        );
      }
    })

    const skipThePuzzle = () => {
      styledSkeletonPieces.value = Array.from(new Set([...styledPuzzlePieces]));
      skipped.value = true;
    }

    onMounted(loadDroppablePuzzlePieces);

    return {
      dimensions,
      isDragging,
      puzzleCompleted,
      puzzleContainer,
      styledPuzzlePieces,
      correctDroppedPieces,
      styledSkeletonPieces,
      skipped,
      skipThePuzzle
    };
  },
  components: {Treecon}
})
