angular.module('ngApp')
.controller('checkoutController', ['$scope','$timeout', '$rootScope', 'Cart', '$state', 'Utility', 'Business', 'Modal', '$filter', function ($scope, $timeout, $rootScope, Cart, $state, Utility, Business, Modal, $filter) {

  $scope.loadingSubscriptionFaqs = false
  $scope.subscriptionFaqs = []

  const steps = [
    {
      name: 'cart',
      url: 'checkout',
      label: $filter('trans')(($rootScope.userdata.subscriptionCart) ? 'edit.plans.cartTabTitle' : 'checkout.menu.cart'),
      isVisible: function() { return true },
      isEnable: function() { return true }
    },
    {
      name: 'business',
      url: 'checkout.business',
      label: $filter('trans')('checkout.menu.business'),
      isVisible: function() {
        return (!cartIsEmpty() || $scope.hasSubscriptionCart())
          && !!$rootScope.user
          && $rootScope.userType === 'Business'
          && !$rootScope.userdata.complete
      },
      isEnable: function() {  }
    },
    {
      name: 'payment',
      url: 'checkout.payment',
      label: $filter('trans')('checkout.menu.pay'),
      isVisible: function() { return !$scope.hasSubscriptionCart() && !cartIsEmpty() },
      isEnable: function() { return false }
    },
    {
      name: 'subscribe',
      url: 'checkout.subscribe',
      label: $filter('trans')('checkout.menu.pay'),
      isVisible: function() { return $scope.hasSubscriptionCart() },
      isEnable: function() { return false }
    }
  ]

  let authWatcher = null

  const cartIsEmpty = function() {
    return !($rootScope.userdata.cart && $rootScope.userdata.cart.items.length > 0)
  }

  const activeStep = function() {
    return steps.slice().reverse().filter(function(step) {
      return $state.current.name === step.url
    })[0]
  }

  $scope.cartIsValid = function() {
    if (!$rootScope.userType) return true
    if (!($rootScope.userdata || $rootScope.userdata.cart)) return false
    return Cart.isValid($rootScope.userdata.cart, $rootScope.userType)
  }

  $scope.hasSubscriptionCart = function() {
    return !!($rootScope.userdata && $rootScope.userdata.subscriptionCart)
  }

  $scope.activeStepIs = function(stepName) {
    return activeStep().name === stepName
  }

  $scope.isStepEnable = function(stepName) {
    const targetStep = steps.filter(function(step) { return step.name === stepName })[0]
    return targetStep ? targetStep.isEnable() : false
  }

  $scope.stepIs = function(stepList) {
    if (Array.isArray(stepList))  return stepList.includes(activeStep().name)
    return activeStep().name === stepList
  }

  $scope.visibleSteps = function() {
    return steps.filter(function(step) {
      return step.isVisible ? step.isVisible() === true : true
    })
  }

  $scope.goTo = function(step) {
    if (step.isEnable())  $state.go(step.url)
  }

  $scope.confirmCart = function() {
    if ($rootScope.user && $rootScope.userdata && $rootScope.userdata.info) {
      $state.go('checkout.payment')
    } else {
      if (authWatcher) authWatcher()
      authWatcher = $rootScope.$watch(
        () => $rootScope.userdata.info,
        userInfo => {
          if (!userInfo) return
          if (authWatcher) authWatcher()
          $state.go('checkout.payment')
        }
      )
      Modal.open({ templateUrl: 'login.html', easyClose: false, fitcontent: true })
    }
  }

  $scope.confirmSubscription = function() {
    $state.go('checkout.subscribe')
  }

  if ($scope.hasSubscriptionCart()) {
    $scope.loadingSubscriptionFaqs = true
    Utility.faq().then(faqs => {
      const subscriptionFaqsSection = faqs && faqs.find(group => group.slug === 'abbonamenti')
      $scope.subscriptionFaqs = subscriptionFaqsSection && subscriptionFaqsSection.faqs || []
    }).then(() => {
      $scope.loadingSubscriptionFaqs = false
    })
  }

}])
