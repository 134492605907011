




















import { defineComponent, provide } from '@vue/composition-api'
import Map from '@f/components/Map/Map.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import ForestMapPreview from '@f/components/Forest/ForestMapPreview.vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apiClient } from '@f/apollo'
import useRoutes from '@f/composables/useRoutes'

export default defineComponent({
  name: 'MapPreview',
  components: {
    ForestMapPreview,
    Map,
    Treecon,
  },
  props: {
    forestId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    provide(DefaultApolloClient as any, apiClient)
    const { go } = useRoutes()
    const goToMap = () => {
      go('.map')
    }
    return {
      goToMap,
    }
  }
})
