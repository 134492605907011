import { JSONSchemaType } from 'ajv'
import { IOrderEdited } from '@f/validation/types/dataLayer'
import { ProductInteractionSchema } from '.';

export const OrderEditedSchema: JSONSchemaType<IOrderEdited> = {
  type: 'object',
  required: ['event', 'cartId', 'cartSize', 'cartValue', 'products'],
  properties: {
    event: { type: 'string', const: 'orderEdited' },
    coupon: { type: 'string', nullable: true },
    voucherId: { type: 'integer', nullable: true },
    cartSize: { type: 'integer' },
    cartValue: { type: 'number' },
    cartId: { type: 'integer' },
    products: {
      type: 'array',
      minItems: 1,
      items: ProductInteractionSchema
    }
  }
}
