import { DataEventDownload } from '@f/services/TagManager/events';

angular.module('ngApp')
.controller('modalBasicMessageController', ['$rootScope', '$scope', '$state', 'Modal', function ($rootScope, $scope, $state, Modal) {

  $scope.title = $rootScope.modalData && $rootScope.modalData.title
  $scope.message = $rootScope.modalData && $rootScope.modalData.message
  $scope.action = $rootScope.modalData && $rootScope.modalData.action

  $scope.closeAlert = function() {
    if ($scope.action && $scope.action.state && $scope.action.state.name) {
      $state.go($scope.action.state.name, $scope.action.state.params || {})
    } else {
      Modal.close()
    }
  }

  $scope.downloadDoc = function(type, fileName) {
    DataEventDownload(type, fileName, window.location.href)
  }
  
}])
