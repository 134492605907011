




import { Mixins, Component, Prop } from 'vue-property-decorator'
import InputLikeComponentMixin from '@f/mixins/InputLikeComponent'

@Component({
  name: 'TextAreaInput',
  inheritAttrs: false,
})
export default class TextAreaInput extends Mixins(InputLikeComponentMixin) {
  /*--- PROPS ---------*/
  @Prop({ required: true }) readonly value!: string | number
}
