import EditProfileBusinessMediaKits from './EditProfile/BusinessMediaKits.vue'
import GiftRecapModal from './GiftRecapModal.vue'
import GamePage from './GamePage.vue'
import GameCompletePage from './GameCompletePage.vue'
import OpenForestRedeem from './OpenForestRedeem.vue'
import ForestPage from './ForestPage.vue'
import ForestTreesPage from './Forest/ForestTreesPage.vue'
import ForestUsersPage from './Forest/ForestUsersPage.vue'
import ForestMapPage from './Forest/ForestMapPage.vue'
import * as User from './User'
import * as Organization from './Organization'
import * as Tree from './Tree'
import CreateYourGiftModal from './CreateYourGiftModal.vue'
import Page404 from './Page404.vue'

export default {
    EditProfileBusinessMediaKits,
    GiftRecapModal,
    GamePage,
    GameCompletePage,
    ...User,
    ...Tree,
    ...Organization,
    OpenForestRedeem,
    ForestPage,
    ForestTreesPage,
    ForestUsersPage,
    ForestMapPage,
    CreateYourGiftModal,
    Page404
}
