import * as smoothscroll from 'smoothscroll-polyfill'

// TODO: angular compliant block scroll
function setLegacyPageScroll(scrollable: boolean): void {
  if (scrollable === isPageScrollable()) return
  const htmlEl = document.documentElement
  const bodyEl = document.body
  htmlEl.style.height = 'auto'
  bodyEl.style.height = 'auto'
  if (scrollable) {
    const scroll = bodyEl.scrollTop
    htmlEl.removeAttribute('style')
    bodyEl.removeAttribute('style');
    if (scroll) htmlEl.scrollTop = scroll
  } else {
    const scroll = htmlEl.scrollTop
    htmlEl.style.overflow = 'hidden'
    if (scroll) bodyEl.scrollTop = scroll
  }
}

export function isPageScrollable(): boolean {
  return document.documentElement.style.overflow !== 'hidden'
    && document.getElementsByTagName('body')[0].style.overflow !== 'hidden'
}

export const lockPageScroll = setLegacyPageScroll.bind(null, false)
export const unlockPageScroll = setLegacyPageScroll.bind(null, true)
export function togglePageScroll(val?: unknown): void {
  if (typeof val === 'boolean') {
    return setLegacyPageScroll(val)
  } else if (!isNaN(Number(val))) {
    return setLegacyPageScroll(!!Number(val))
  } else if (typeof val == 'string') {
    return setLegacyPageScroll(val.toLowerCase() === 'true')
  }
  return setLegacyPageScroll(!isPageScrollable())
}

interface ElementFunction {
  (): HTMLElement | void | null
}

interface OffsetFunction {
  (): number | HTMLElement | void | null
}

interface PageScrollConfig {
  getOffsetTop?: OffsetFunction
  getScrollElement?: ElementFunction
}

export interface PageScrollOptions {
  target?: string | HTMLElement
  smooth?: boolean
  scroller?: string | HTMLElement | ElementFunction
  offsetTop?: number | string | HTMLElement | OffsetFunction
}

export class PageScrollManager {
  constructor(public options: PageScrollConfig = {}) {
    smoothscroll.polyfill()
  }

  get offsetTop(): number {
    return this._resolveOffset(this.options.getOffsetTop && this.options.getOffsetTop() || 0)
  }

  get scrollElement(): HTMLElement {
    return this.options.getScrollElement && this.options.getScrollElement()
      || (document.scrollingElement as HTMLElement)
      || document.documentElement
  }

  get reducedMotionPreferred(): boolean {
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)")
    return !mediaQuery || mediaQuery.matches
  }

  scroll({ target, offsetTop, scroller, smooth }: PageScrollOptions): void {
    const scrollElement = scroller && this._resolveElement(scroller) || this.scrollElement
    const targetElement = target && this._resolveElement(target)
    const top = !targetElement ? 0 : this._getScrollTop(targetElement, scrollElement, this._resolveOffset(offsetTop))
    const behavior = !this.reducedMotionPreferred && !!smooth ? 'smooth' : 'auto'
    setTimeout(() => {
      scrollElement.scrollTo({ top, behavior })
    })
  }
    
    private _resolveElement(target?: string | HTMLElement | ElementFunction): HTMLElement | undefined {
      if (typeof target === 'string') {
      let validateSelector = (/#[a-zA-Z][a-zA-Z0-9\-\_]+/g).test(target)
      return validateSelector ? document.querySelector(target) as HTMLElement : undefined
    } else if (typeof target === 'function') {
      return target() || undefined
    } else {
      return target
    }
  }

  private _resolveOffset(offset?: number | string | HTMLElement | OffsetFunction): number {
    if (typeof offset === 'undefined') return this.offsetTop
    const offsetTarget = typeof offset === 'function' ? offset() : offset
    return typeof offsetTarget === 'number'
      ? offsetTarget
      : this._resolveElement(offsetTarget || undefined)?.clientHeight || 0
  }

  private _getScrollTop(target: HTMLElement, scroller: HTMLElement, offset: number): number {
    const targetTop = Math.floor(target.getBoundingClientRect().y || 0)
    const scrollerScrollTop = Math.floor(scroller.scrollTop || 0)
    return targetTop + scrollerScrollTop - Math.floor(offset)
  }
  
}
