import { testsConfigPath } from '@f/configs/app'
import { fetchStaticData } from '@f/services/StaticData'
import { useValidators, activeTests as activeGrowthTests } from '@f/services/Growth';
import { getAsset } from '@f/utils/media'
import { getStaticUrl } from '@f/utils/assets'

// TODO: FetchStaticData and ShowGrowthTests vue mixins duplicated properties and methods
angular.module('growthService', [])
.factory('Growth', ['$rootScope', '$uiRouterGlobals', function($rootScope, $uiRouterGlobals, onInit) {
  let cachedTests = null
  let localDataPathSuffix = 'bundles/treedomnewfrontend/json'
  let cachedValidators

  async function fetchTests() {
    if (cachedTests) return cachedTests
    const urls = [getStaticUrl(testsConfigPath)]
    if ($rootScope.treedomEnv === 'DEV') {
      const fullLocalPath = localDataPathSuffix.replace(/\/$/, '')+'/'+testsConfigPath.replace(/^\//,'')
      urls.push(getAsset(fullLocalPath))
    }
    return await fetchStaticData(urls).then(tests => cachedTests = tests || null)
  }

  async function activeTests(type) {
    const tests = await fetchTests()
    if (!tests) return []
    const validators = cachedValidators
      || (cachedValidators = useValidators(
        {
          name: $uiRouterGlobals.current.name || '',
          params: $uiRouterGlobals.params
        },
        $rootScope.ln))
    if (!validators) return []
    return activeGrowthTests(tests, validators, type)
  }

  return {
    activeTests
  }
}]);
