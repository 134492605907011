


























































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import TreeDiaryItem from '../mixins/TreeDiaryItem'
import TreeDiaryItemHeader from '../partials/TreeDiaryItemHeader.vue'

interface Coordinates {
  lat: number
  lng: number
}

@Component({
  name: 'TreeDiaryStatus',
  components: { TreeDiaryItemHeader },
})
export default class TreeDiaryStatus extends Mixins(TreeDiaryItem) {
  @Prop({ type: Boolean, default: true }) readonly hasModal!: boolean

  // Computed
  get mediaStyle(): Partial<CSSStyleDeclaration> {
    return {
      backgroundImage: `url(${this.getStatusImage()})`,
    }
  }

  get text(): string {
    let stringKey = ''
    let translatePayload = {}
    switch (this.post.status) {
      case 'NEW':
        const userIsTheOwner =
          this.tree.initialUser.id == this.$rootScope.userdata &&
          this.$rootScope.userdata.info.id
        stringKey = userIsTheOwner
          ? 'pageTree.status1.Description_mine'
          : 'pageTree.status1.Description_your'
        translatePayload = {
          ID: this.tree.treeId,
          registerUrl: this.parseState('page.register', {
            id: this.tree.treeId,
          }),
          country: this.translate(this.tree.specie.country.name),
          countrySlug: this.tree.specie.country.slug,
          projectUrl: this.parseState('project', {
            slug: this.tree.specie.country.slug,
          }),
          initialuser: this.tree.initialUser.firstName,
          userSlug: this.tree.initialUser.slug,
          link: this.parseState(
            this.tree.initialUser.userType === 'Private'
              ? 'user'
              : 'organization',
            { slug: this.tree.initialUser.slug }
          ),
        }
        break
      case 'NURSERY':
        ;(stringKey = 'pageTree.status2.Description'),
          (translatePayload = { specie: this.translate(this.tree.specie.name) })
        break
      case 'PLANT':
        stringKey = 'pageTree.status3.Description'
        break
      case 'PHOTO':
        stringKey = 'pageTree.status4.Description'
        break
    }
    return this.translate(stringKey, translatePayload)
  }

  get statusHasPhoto(): boolean {
    // TODO: refactor pulizia
    return ['NURSERY', 'PHOTO'].includes(this.post.status) && this.hasModal
  }

  get coords(): Coordinates {
    return {
      lat:
        this.post.status === 'NEW'
          ? this.tree.specie.originator.nurseryLat
          : this.tree.lat,
      lng:
        this.post.status === 'NEW'
          ? this.tree.specie.originator.nurseryLng
          : this.tree.lng,
    }
  }

  // Methods
  getStatusImage(): string {
    switch (this.post.status) {
      case 'PLANT':
        return this.$options.filters!.asset(
          'bundles/treedomnewfrontend/images/treeDiary/tree-diary-status-planted.svg'
        )
      case 'PHOTO':
        return this.tree.picture.medium
      case 'NURSERY':
        return this.tree.nurseryPicture.medium
      default:
        return ''
    }
  }

  handleOpenModal() {
    this.$emit('triggerDataEvent', { action: 'open', element: 'statusPost' })
    this.$emit('open-modal', {
      postId: this.post.id,
      treeId: (this.tree && this.tree.treeId) || null,
    })
  }
}
