




































import { computed, defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import { TrTree } from '@f/@types/graphql'
import { getVisibleImage } from '@f/services/Tree'
import Modal from '@f/components/Modal.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'

export default defineComponent({
  name: 'TreeInfoPicture',
  components: {
    Treecon,
    Modal
  },
  props: {
    status: {
      type: Number,
      required: true
    },
    tree: {
      type: Object as PropType<TrTree>
    },
    scientificName: {
      type: String,
      default: ''
    },
    squared: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const image = computed(() => getVisibleImage(props.tree!)?.medium)
    const imageXL = computed(() => getVisibleImage(props.tree!)?.xlarge)
    const showTreeImage = ref(false)

    const files = ref<File[]>([])
    const shareButtonVisible = ref(false)

    onMounted(async () => {
      const response = await fetch(imageXL.value ?? '')
        .then(response => response.blob())

      files.value = [new File([response], `${props.scientificName}.jpg`, { type: 'image/jpeg' })]

      // @ts-ignore
      if (navigator.canShare && navigator.canShare({ files: files.value })) {
        shareButtonVisible.value = true
      }
    })

    function openTreeImage() {
      if (props.status !== 4) return

      showTreeImage.value = true
    }

    const shareImage = () => {
      // @ts-ignore
      return navigator.share({ files: files.value })
    }

    const downloadImage = async () => {
      const blob = await fetch(imageXL.value ?? '').then(response => response.blob())
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = `Treedom - ${props.scientificName}.jpg`

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    }

    return {
      image,
      imageXL,
      showTreeImage,
      openTreeImage,
      shareImage,
      downloadImage,
      shareButtonVisible
    }
  }
})
