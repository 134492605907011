import { MachineConfig } from 'xstate';
import { OpenForestRedeemContext, OpenForestRedeemEvent } from './types';

const config: MachineConfig<OpenForestRedeemContext, any, OpenForestRedeemEvent> = {
  id: 'flow:openforest_redeem',
  initial: 'idle',
  on: {
    CLEAR_ERROR: {
      actions: 'clearError'
    },
    SET_SLUG: {
      actions: 'setSlug',
    },
  },
  states: {
    idle: {
      on: {
        CHECK: [
          {
            target: 'authenticating',
            cond: 'userIsAnonymous'
          },
          {
            target: 'checking',
            cond: 'slugIsSet'
          },
          {
            actions: 'setNoSlugError'
          },
        ]
      }
    },
    checking: {
      entry: 'clearError',
      invoke: {
        src: 'check',
        onDone: [
          {
            target: 'trees',
            actions: 'setItems',
            cond: 'userIsAuthenticated',
          },
          {
            target: 'authenticating',
            actions: 'setItems',
          },
        ],
        onError: [
          {
            target: 'authenticating',
            actions: 'setError',
            cond: 'userIsAnonymous',

          },
          {
            target: 'idle',
            actions: 'setError'
          },
        ]
      }
    },
    authenticating: {
      always: {
        target: 'trees',
        cond: 'userIsAuthenticated',
      },
      invoke: {
        src: 'authenticate',
        onDone: [
          {
            target: 'trees',
            cond: 'itemsAreSet'
          },
          {
            target: 'checking',
          }
        ],
        onError: 'idle',
      }
    },
    trees: {
      on: {
        REDEEM: 'redeeming',
        PICK: {
          actions: 'pickProduct'
        }
      },
      always: {
        target: 'redeeming',
        actions: 'autoPick',
        cond: 'cantBePicked',
      }
    },
    redeeming: {
      entry: 'clearError',
      invoke: {
        src: 'redeem',
        onDone: {
          target: 'success',
          actions: 'setResult'
        },
        onError: [
          {
            target: 'idle',
            actions: 'setError',
            cond: 'cantBePicked'
          },
          {
            target: 'trees',
            actions: 'setError',
          }
        ]
      }
    },
    success: {
      type: 'final' as const
    },
  }
}

export default config
