











import { Vue, Component, Prop } from "vue-property-decorator"
import Buckler from '@f/components/Emissions/Buckler.vue'

@Component({
  name: "TreeCo2Specie",
  components: { Buckler }
})
export default class TreeCo2Specie extends Vue {
  @Prop({ type: String, default: '', required: true }) readonly specie!: string
  @Prop({ type: Number, default: 0, required: true }) readonly tankSize!: number
  
  get textTop(): string {
    return `
      <p>
        ${this.$t('specie.tree.co2Capture.textIntro', { specie: this.specie })}
      </p>
    `
  }

  get comparison() {
    if (!this.tankSize) return
    const { id, unit, qt } = this.getExample(this.tankSize, ['km', 'kg'])
    const subject = this.$t(`emissions.name.${id}`)
    const subjectString = `pageTree.co2.comparisonValue.${unit}`
    const comparisonValue = qt.toLocaleString(this.$rootScope.ln)+unit+' '+this.$t(subjectString, {subject})
    return {
      text: `
        <h3 class="no-margin">${this.tankSize.toLocaleString(this.$rootScope.ln)}</h3>
        <h5>${this.$t('specie.tree.co2Capture.textKg')}</h5>
        <p class="no-margin">${this.$t('specie.tree.co2Capture.textComparison', { comparisonValue })}</p>
      `,
      image: `/bundles/treedomnewfrontend/images/calculator/${id}.png`
    }
  }

  getExample(co2: number, units: string[]): any {
    return this.ngService('Emission')?.emissions.randomExample(co2, units) || null
  }
}
