import Swiper from 'swiper'

angular.module('ngApp')
.controller('ecommerceWedController', ['$scope', '$rootScope', function ($scope, $rootScope) {
  var ctrl = this

  ctrl.trees = $rootScope.wed2018
  $scope.wedSlider = null

  $scope.$on('wedLimitedFound', function (event, data) {
    ctrl.trees = $rootScope.wed2018
  })

  // Swiper trees
  $scope.renderWedSwiper = function () {
    if ($scope.wedSlider) {
      !Array.isArray($scope.wedSlider)
        ? $scope.wedSlider.destroy(true, true)
        : $scope.wedSlider.forEach(function (s) { s.destroy(true, true) })
    }

    $scope.wedSlider = new Swiper('#SwiperWed', {
      slidesPerView: 2,
      centeredSlides: true,
      spaceBetween: 30,
      nextButton: '#WedButtonNextSlider',
      prevButton: '#WedButtonPrevSlider',
      pagination: '#WedSliderPagination',
      paginationClickable: true,
      grabCursor: true,
      // Responsive breakpoints
      breakpoints: {
        // when window width is <= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        // when window width is <= 640px
        640: {
          slidesPerView: 2,
          spaceBetween: 0
        }
      }
    })
  }
  // $timeout($scope.renderWedSwiper, 500)
}])
