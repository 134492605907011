import { JSONSchemaType } from 'ajv'
import { IEventForestInfoEdited, IEventSelectionForest, IEventSelectionTrees, ISimpleEventForest, TreesPackage } from '@f/validation/types/dataLayer'

export const BeginForestSchema: JSONSchemaType<ISimpleEventForest> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: { type: 'string' },
  }
}

export const SelectionForestSchema: JSONSchemaType<IEventSelectionForest> = {
  type: 'object',
  required: ['event', 'idForest', 'festivityType', 'forestType'],
  properties: {
    event: { type: 'string' },
    idForest: { type: 'number', nullable: true },
    festivityType: { type: 'string' },
    forestType: { type: 'string' }
  }
}

const ForestProduct: JSONSchemaType<TreesPackage> = {
  type: 'object',
  required: ['id', 'name', 'price', 'brand', 'category', 'position', 'dimension1', 'quantity'],
  properties: {
    id: { type: 'number' },
    name: { type: 'string' },
    price: { type: 'number' },
    brand: { type: 'string' },
    category: { type: 'string' },
    position: { type: 'number' },
    dimension1: { type: 'boolean' },
    quantity: { type: 'number' }
  }
}


export const SelectionTreesSchema: JSONSchemaType<IEventSelectionTrees> = {
  type: 'object',
  required: ['event', 'idForest', 'packageType', 'forest'],
  properties: {
    event: { type: 'string' },
    idForest: { type: 'number', nullable: true },
    packageType: { type: 'string' },
    forest: { 
      type: 'object',
      required: ['add'],
      properties: {
        add: {
          type: 'object',
          required: ['products'],
          properties: {
            products: {
              type: 'array',
              minItems: 1, 
              items: ForestProduct,
              nullable: true
            }
          }
        }
      }
    },
  }
}

export const ForestInfoEditedSchema: JSONSchemaType<IEventForestInfoEdited> = {
  type: 'object',
  required: ['event', 'idForest', 'festivityType', 'forestType', 'packageType', 'forest'],
  properties: {
    event: { type: 'string' },
    festivityType: { type: 'string' },
    forestType: { type: 'string' },
    idForest: { type: 'number' },
    packageType: { type: 'string', nullable: true },
    forest: { 
      type: 'object',
      required: ['add'],
      properties: {
        add: {
          type: 'object',
          required: ['products'],
          properties: {
            products: {
              type: 'array',
              minItems: 0, 
              items: ForestProduct,
              nullable: true
            }
          }
        }
      }
    },
  }
}