import { ShopCurrency } from '@f/configs/app';
import tagManagerService from '@f/services/TagManager';
import { validateSocialButtonClick, validateToggleFollow } from '@f/validation/datalayer';
import { IElementSelected } from '@f/validation/types/dataLayer'

export function DataEventSocialButton(
    socialType: string = 'facebook', 
    socialAction: string = 'click', 
    ) {
    tagManagerService.push({
        event: 'socialButton',
        socialType,
        socialAction,
    }, validateSocialButtonClick)
}

export function DataEventToggleFollow(
    shouldSendFollow: boolean, 
    position: string,
    elementSelected: IElementSelected,
    ) {
    tagManagerService.push({
        event: shouldSendFollow ? 'follow' : 'unfollow',
        position,
        elementSelected
    }, validateToggleFollow)
}

export function DataEventChangeCurrency(
    selectedCurrency: ShopCurrency
    ) {
    tagManagerService.push({
        event: 'currencyPickerInteraction',
        selectedCurrency
    })
}