




import { computed, defineComponent, onMounted } from '@vue/composition-api'
import { getRootScope, getStateService } from '@f/services/Angular'

export default defineComponent({
  name: 'Page404',
  props: {
    previousPath: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const locale = computed(() => (getRootScope() || null)?.ln || 'en')
    const iframeURL = `/${locale.value}/404?previousPath=${props.previousPath}`
    const stateService = getStateService()

    onMounted(() => {
      getRootScope()?.onMessage('navigation', ({ payload } = {}) => {

        console.log(payload)

        const redirect = payload?.redirect

        if (redirect) { // if the iFrame has a redirect, I need to execute a redirect in the proper page
          stateService?.go(redirect)
        }
      })
    })
    return {
      iframeURL
    }
  }
})
