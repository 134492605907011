import { render, staticRenderFns } from "./GameStarter.vue?vue&type=template&id=b111f592&scoped=true&"
import script from "./GameStarter.vue?vue&type=script&lang=ts&"
export * from "./GameStarter.vue?vue&type=script&lang=ts&"
import style0 from "./GameStarter.vue?vue&type=style&index=0&id=b111f592&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b111f592",
  null
  
)

export default component.exports