
























import { defineComponent, computed } from '@vue/composition-api'
import InputLikeComponent from '@f/mixins/InputLikeComponent'

export default defineComponent({
  name: 'BaseSelect',
  inheritAttrs: false,
  mixins: [ InputLikeComponent ],
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    hideValidationError: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isUnselected = computed(() => typeof props.value !== 'number' && !props.value)

    return {
      isUnselected,
    }
  }
})
