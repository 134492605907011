






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'AlertMessage'
})
export default class AlertMessage extends Vue {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  @Prop({ type: String }) readonly title!: string
  @Prop({ type: String }) readonly text!: string
  
  /*--- DATA ----------*/
  
  /*--- COMPUTED ------*/
  
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  
  /*--- METHODS -------*/
  
}
