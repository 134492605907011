



































import { defineComponent, computed, provide } from '@vue/composition-api'
import {
  fetchForest,
  fetchForestUsers,
} from '@f/services/Forest'
import { Event, EventUsersPage, ForestUser } from '@f/@types'
import ForestCompactHeader from '@f/components/Forest/ForestCompactHeader.vue'
import ForestUsersBox from '@f/components/Forest/ForestUsersBox.vue'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import useForestData from '@f/composables/useForestData'
import useUserInfo from '@f/composables/useUserInfo'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apiClient } from '@f/apollo'

export default defineComponent({
  name: 'ForestUsersPage',
  components: {
    ForestCompactHeader,
    ForestUsersBox,
  },
  setup(props) {
    provide(DefaultApolloClient as any, apiClient)
    const mobileQuery = maxWidth('md')
    const isMobile = useMediaQuery(mobileQuery)
    const { userInfo } = useUserInfo()
    const {
      forest,
      loading,
      eventNumbers,
      isForestPrivate,
      isForestPublished,
      ctaAction,
      ctaLabel,
      isCtaVisible,
      goToCta,
      back,
      avatarImageUrl
    } = useForestData(userInfo, props.eventSlug)

    const isUserPrivate = computed((): boolean => {
      return !!(userInfo.value?.usertype === 'Private')
    })

    const isMine = computed((): boolean => {
      return !!(forest.value.user?.id === userInfo.value?.id)
    })

    const hidePeople = computed(() => {
      return forest.value?.hide_people
    })

    return {
      hidePeople,
      isMobile,
      userInfo,
      forest,
      loading,
      eventNumbers,
      isMine,
      isForestPrivate,
      isUserPrivate,
      isForestPublished,
      ctaAction,
      ctaLabel,
      isCtaVisible,
      goToCta,
      back,
      avatarImageUrl
    }
  },
  props: {
    eventSlug: {
      type: String,
      required: true,
    },
  },
  data(): {
    forestUsers: ForestUser[]
    forestUsersCount: number
    page: number
  } {
    return {
      forestUsers: [],
      forestUsersCount: 0,
      page: 0,
    }
  },
  computed: {
    fetchMoreEnabled(): boolean {
      return this.forestUsers.length < this.forestUsersCount;
    }
  },
  mounted() {
    this.getForestData()
  },
  methods: {
    getForestData(): void {
      this.loading = true;
      fetchForest(this.eventSlug)
        .then((data: Event) => {
          this.forest = data

          if (!this.forest.published && !this.isMine) {
            this.$state.go('index');
          }

          this.fetchUsers();
        })
        .finally(() => {
          this.loading = false;
        })
    },
    fetchUsers():void {
      if(this.page > 0 && !this.fetchMoreEnabled) return;
      this.loading = true;
      fetchForestUsers(this.forest.id!, this.page, 18).then((data: EventUsersPage) => {
        this.forestUsers = [...this.forestUsers, ...data.users];
        this.forestUsersCount = data.count;
        this.page++;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
})
