const { getWidgetPosition, isCursorOn } = require("./widgetUtils.js");

angular.module('ngApp')
  /**
  * Widget Stream.
  * NOTE: Specificare altezza e larghezza del widget che deve essere fissa!
  *
  * @param {Object} attrs
  * @param {Object} attrs.widgetKey - Chiave per salvare l'oggetto in una cache temporanea (preferibilmente ID o SLUG)
  */
  .directive('widgetstream', ['$rootScope', '$compile', '$document', '$interval', 'Utility', 'Cart', 'Modal', 'User', function ($rootScope, $compile, $document, $interval, Utility, Cart, Modal, User) {
    var mouseOverElement = false
    var renderInterval

    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {
        if ($rootScope.isMobile) return

        var _activeWidget = false
        var widget = { width: 290, height: 621 }

        // Rimuove il widget.
        function removeWidget(widget) {
          if (!$rootScope.openedModals.length) Utility.allowScroll()
          window.removeEventListener('mousemove', mouseMoveListener)
          window.removeEventListener('click', mouseClickListener)
          widget.remove()

          // Controllo per sicurezza se esistono altri widget e li cancello.
          var otherWidgets = $document.find('.w_' + _activeWidget.key)
          if (otherWidgets.length) {
            otherWidgets.each(function (index) {
              otherWidgets[index].remove()
            })
          }

          _activeWidget = false
        }

        // Listener movimento mouse.
        function mouseMoveListener(e) {
          var widget = $document.find('#' + _activeWidget.key)

          if (!isCursorOn(element, e) && !isCursorOn(widget, e)) {
            removeWidget(widget)
          }

          $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            var widget = $document.find('#' + _activeWidget.key)
            removeWidget(widget)
          })
        }

        // Listener click.
        function mouseClickListener(e) {
          var widget = $document.find('#' + _activeWidget.key)
          if (!isCursorOn(widget, e)) {
            removeWidget(widget)
            Utility.blockScroll()
          }
        }

        // Funzione per renderizzare il widget con i dati richiesti.
        function renderWidget(widgetKey) {
          if (!mouseOverElement) return
          if (!$rootScope.userdata) return

          widgetKey = widgetKey || 'stream'
          _activeWidget = $rootScope.streamList
          _activeWidget.key = widgetKey

          // Creo il template del widget.
          var position = getWidgetPosition(element, widget)
          var template = '../../bundles/treedomnewfrontend/html-templates/widget/stream.html'
          var style = 'top: ' + position.top + 'px; ' + 'left: ' + position.left + 'px;'
          var arrowUp = position.position === 'bottom' ? '<div class="arrow-up" style="left: ' + position.offsetLeftArrow + 'px"></div>' : ''
          var arrowDown = position.position === 'top' ? '<div class="arrow-down" style="left: ' + position.offsetLeftArrow + 'px"></div>' : ''
          var html = '<div id="' + _activeWidget.key + '" style="' + style + '" class="widgetContainer w_' + _activeWidget.key + '"><div class="widgetContent">' + arrowUp + '<div ng-include="\'' + template + '\'"></div>' + arrowDown + '</div></div>'

          var getStream = function () {
            User.stream(0)
              .then(function (response) {

                $rootScope.streamList = response
                $scope.widgetStreamLoading = false
              })
          }

          $scope.widgetOpenStreamModal = function () {
            Modal.open({ templateUrl: 'stream/community.html' })
          }

          $scope.widgetStreamLoading = !$rootScope.streamList || ($rootScope.streamList && !$rootScope.streamList.length)
          if ($scope.widgetStreamLoading) getStream()

          // Appendo il widget in fondo al body.
          var body = $document.find('body').eq(0)
          body.append($compile(angular.element(html))($scope))
          if (!$scope.$$phase) $scope.$apply()

          // Creo l'ascoltatore per il movimento del mouse.
          window.addEventListener('mousemove', mouseMoveListener)
          window.addEventListener('click', mouseClickListener)
        }

        $scope.getStreamLink = function (stream) {
          switch (stream.type) {
            case 'order_created':
              return $rootScope.getUrl('user', { 'slug': stream.userSlug })
            case 'gift_redeemed':
              return $rootScope.getUrl('user', { 'slug': stream.toUserSlug })
            case 'forest_published':
              return $rootScope.getUrl('user.event', { 'slug': stream.userSlug, 'eventslug': stream.eventSlug })
            case 'emission_created':
              return $rootScope.getUrl('emission')
            case 'emission_neutralized':
              return $rootScope.getUrl('user', { 'slug': stream.userSlug })
            case 'tree_message_created':
              return $rootScope.getUrl('user.trees.item', { 'slug': stream.userSlug, 'item': stream.treeId })
            case 'tree_named':
              return $rootScope.getUrl('user.trees.item', { 'slug': stream.userSlug, 'item': stream.treeId })
            case 'user_following':
              return $rootScope.getUrl('user', { 'slug': stream.toUserSlug })
            case 'tree_followed':
              return $rootScope.getUrl('user.trees.item', { 'slug': stream.userSlug, 'item': stream.treeId })
            case 'user_badge_assigned':
              return $rootScope.getUrl('user', { 'slug': stream.userSlug })
            case 'order_created':
              return $rootScope.getUrl('user', { 'slug': stream.userSlug })
          }
        }


        // Funzione per renderizzare il widget con un intervallo di tempo.
        function renderWidgetWithInterval(widgetKey, milliseconds) {
          if (renderInterval) clearInterval(renderInterval)

          renderInterval = setInterval(function () {
            renderWidget(widgetKey)
            clearInterval(renderInterval)
          }, milliseconds)
        }

        // Mouseenter per creare il widget.
        element.on('mouseenter', function () {
          mouseOverElement = true
          Utility.blockScroll()
          // Prendo la chiave dell'utente dagli attributi.
          var widgetKey = attrs.widgetKey || 'stream'

          // Se il widget esiste, non lo ricreo.
          var oldWidget = document.getElementById(widgetKey)
          if (oldWidget) return

          renderWidgetWithInterval(widgetKey, 700)
        })

        element.on('mouseleave', function () {
          mouseOverElement = false
          clearInterval(renderInterval)

          // Se non c'è un widget attivo o modali aperte, riabilita lo scroll.
          if (!_activeWidget && !$rootScope.openedModals.length) Utility.allowScroll()
        })
      }
    }
  }])
