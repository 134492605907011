angular.module( "renderComponentDirective", [])
  .directive('renderComponent', ['$compile', '$rootScope', '$state', function($compile, $rootScope, $state) {
    return {
      restrict: 'AE', // come puo venire definita es E:element <angular-tip>/  A:attribute <div angular-tip> / C:classe etc...
      replace: true, // se viene rimpiazzato con il template sotto
      scope: true,
      link: function(scope, elem, attrs) {
        scope.attrs = attrs;
        elem.append($compile($rootScope.templates[attrs.template])(scope))
      }
    }
}]);
