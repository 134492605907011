













































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import ManageQueues, { Job, JobCallback } from '@f/mixins/ManageQueues'
import CartPreviewRow from './CartPreviewRowNewEcommerce.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import {
  Cart,
  CartLineItem,
} from '@f/components/Navbar/partials/CartWidgetNewEcommerce.vue'

export interface CartPreviewItem extends CartLineItem {
  groupId?: number
}

@Component({
  name: 'CartPreviewNewEcommerce',
  components: {
    CartPreviewRow,
    Treecon,
  },
})
export default class CartPreviewNewEcommerce extends Mixins(ManageQueues) {
  /*--- MODEL ---------*/

  /*--- PROPS ---------*/
  @Prop({ type: Object, default: null }) readonly cart!: Cart | null
  @Prop({ type: Boolean, default: false }) readonly feedback!: boolean
  @Prop({ type: Boolean, default: false }) readonly grouped!: boolean

  /*--- DATA ----------*/
  cartService: any = null
  cartRemovingQueueKey: string = 'cartRemove'
  rowTransition = {
    beforeLeave: (el: HTMLElement) => {
      el.style.height = `${el.offsetHeight}px`
    },
  }

  /*--- COMPUTED ------*/
  get cartItems(): CartPreviewItem[] {
    return this.grouped
      ? this.productGroupedItems(this.cart?.lineItems)
      : this.cart?.lineItems || []
  }
  get cartIsEmpty(): boolean {
    return !this.cart?.lineItems?.length
  }

  /*--- WATCHERS ------*/

  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  mounted() {
    this.cartService = this.ngService('Cart')
    if (this.feedback) {
      const listElement = this.$refs.itemsList as Element
      listElement.scrollTop =
        listElement.scrollHeight - listElement.clientHeight
    }
  }

  /*--- METHODS -------*/
  attemptCartRemove({ id }: CartPreviewItem, callback?: JobCallback) {
    if (!this.cartService) {
      throw Error('Unable to call Angular Cart Service.')
    }
    if (this.cart?.id) {
      const task = () =>
        this.cartService.removeProduct({
          lineItemId: id,
        })
      this.addJobToQueue(this.cartRemovingQueueKey, new Job(id, task, callback))
    } else {
      throw Error('No cart available.')
    }
  }

  productGroupedItems(items?: CartLineItem[]): CartPreviewItem[] {
    if (!items) return []
    return Object.values(
      items.reduce((list, item) => {
        return {
          ...list,
          [item.product.id]: {
            ...item,
            quantity: (list[item.product.id]?.quantity || 0) + 1,
            groupId: item.product.id,
          },
        }
      }, {} as Record<number, CartPreviewItem>)
    )
  }
}
