import { DataEventToggleFollow } from '@f/services/TagManager/events';

angular.module('ngApp')
.controller('relationController', ['$scope', '$rootScope', '$state', '$stateParams', 'User', 'Utility', function ($scope, $rootScope, $state, $stateParams, User, Utility) {
  if ($state.current.name === 'user.relation') {
    $state.go('user.relation.follower', {slug: $stateParams.slug})
  }

  $scope.tab = $state.current.name === 'user.relation.following' ? 'following' : 'follower'

  $scope.activeTab = function (type) {
    if (type === 'follower') {
      $scope.tab = type
      $state.go('user.relation.follower', {slug: $stateParams.slug})
      return
    }

    if (type === 'following') {
      $scope.tab = type
      $state.go('user.relation.following', {slug: $stateParams.slug})
      return
    }
  }

  $scope.toggleFollow = function (slug) {
    User.toggleFollow(slug)
    .then(function(response) {
      const data = response.data || response
      $rootScope.userdata.following = data
      // Broadcast cambio relazioni utente
      $rootScope.$broadcast('ChangedUserRelationships')

      const shouldFollow = data.includes(slug)
      DataEventToggleFollow(shouldFollow, 'user-menu','user');
    })
    .catch(function() {
      Utility.enableButton()
    })
  }

  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
    if (toState.name === 'user.relation.follower') {
      $scope.tab = 'follower'
    } else if (toState.name === 'user.relation.following') {
      $scope.tab = 'following'
    }
  })
}])
.controller('privateEventUsersController', [function () {}])
.controller('collectionController', [function () {}])
.controller('countriesController', [function () {}])
