





















import { defineComponent } from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'
import useRoutes from '@f/composables/useRoutes'

// @ts-ignore
export default defineComponent({
  name: 'HUDPage',
  components: {
    Treecon,
  },
  props: {
    back: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { href, goBack } = useRoutes()

    return {
      href,
      goBack,
    }
  }
})
