const treeInfoQuery = (_treeId,_language) => {
    return `tree:Tree(id:"${_treeId}"){
        id
        treeId
        status
        lat
        lng
        location
        name
        timezone
        dayTime
        picture{
          small
          medium
          large
        }
        assignedOn
        user{
          id
          slug
          title
          firstName
          lastName
          avatar{
            small
            medium
            large
          }
          userType
        }
        initialUser{
          id
          slug
          title
          firstName
          lastName
          avatar{
            small
            medium
            large
          }
          userType
        }
        gift{
          uuid
          code
          locale
          owner {
            firstName
            lastName
          }
          message {
            ${_language}
          }
          giftTag
          deliveryType
          email
          sendAt {
            date
            timezone
          }
        }
        event{
          id
          slug
          background{
            large
            xlarge
          }
          avatar{
            small
            medium
            large
          }
          name
          published
          user{
            slug
            userType
          }
        }
        tankSize
        tankSizeLeft
        specie{
          id
          name{
            ${_language}
          }
          badges{
            icon{
              small
              medium
            }
            name{
              ${_language}
            }
            description{
              ${_language}
            }
          }
          nurseryImage{
            small
            medium
            large
          }
          icon{
            small
            medium
            large
          }
          scientificName{
            name
          }
          size
          sayDescription{
            ${_language}
          }
          sayTitle{
            ${_language}
          }
          co2Capture{
            ${_language}
          }
          whichTree{
            ${_language}
          }
          originator{
            id
            slug
            title
            firstName
            avatar{
              small
              medium
              large
            }
            nurseryLat
            nurseryLng
            location
            timezone
            dayTime
          }
          country{
            slug
            name{
              ${_language}
            }
            flag{
              small
              medium
            }
            identityCardBackground{
              medium
              large
              xlarge
            }
            image{
              medium
              large
              xlarge
            }
          }
          powers{
              foodsecurityPower
              co2Power
              incomePower
              soilPower
          }
        }
        limited{
          limitedName{
            ${_language}
          }
          limitedNameSubTitle{
            ${_language}
          }
          icon{
            small
            medium
            large
          }
          videoUrl{
            ${_language}
          }
          limitedTitle{
            ${_language}
          }
          limitedSubTitle{
            ${_language}
          }
          limitedDescription{
            ${_language}
          }
          color
          footerBackground
          limitedSerie
          limitedSerieSlug
          symbol{
            small
            medium
            large
          }
          
        }
        coupled{
          id
          treeId
          name
          gift{
            uuid
          }
          user{
            id
            slug
            title
            firstName
            lastName
            avatar{
              small
              medium
              large
            }
          }
          initialUser{
            id
            slug
            title
            firstName
            lastName
            avatar{
              small
              medium
              large
            }
          }
          specie{
            name{
              ${_language}
            }
            icon{
              small
              medium
              large
            }
          }
          limited{
            limitedName{
              ${_language}
            }
            limitedNameSubTitle{
              ${_language}
            }
            icon{
              small
              medium
              large
            }
          }
        }
        likes{
          id
          slug
          title
          firstName
          lastName
          avatar{
            small
            medium
            large
          }
        }
        actions{
          type
          data
          done
          isVibrating
          treeIcon
        }
        messages{
          id
          message
          isPrivate
          isReadable
          createdOn
          user{
            id
            title
            firstName
            lastName
            slug
            userType
            avatar{
              small
              medium
              large
            }
          }
          toUser{
            title
            firstName
            lastName
            userType
            avatar{
              small
              medium
              large
            }
          }
        }
        forecast{
          temperature
          time
          timezone
          windSpeed
          icon
          precipIntensity
        }
      }`;
};

const treeDiary = (_treeId, _language) => {
  return `Tree_Diary(id: "${_treeId}"){
    id
    items {
      type: __typename
      ... on TreeDiaryStatus {
        createdAt
        status
        tree {
          treeId
        }
      }
      ... on TreeDiaryLike {
        createdAt
        user {
          id
          slug
          title
          firstName
          lastName
          avatar {
            small
            medium
          }
        }
      }
      ... on TreeDiaryMessage {
        createdAt
        message {
          message
          isPrivate
          isReadable
          user {
            id
            slug
            title
            firstName
            lastName
            avatar {
              small
              medium
            }
          }
          toUser {
            id
            slug
            title
            firstName
            lastName
            avatar {
              small
              medium
            }
          }
        }
      }
      ... on TreeDiaryName {
        createdAt
        name
        user {
          id
          slug
          title
          firstName
          lastName
          avatar {
            small
            medium
          }
        }
      }
      ... on TreeDiaryGift {
        createdAt
        user {
          id
          slug
          title
          firstName
          lastName
          avatar {
            small
            medium
          }
        }
        initialUser {
          id
          slug
          title
          firstName
          lastName
          avatar {
            small
            medium
          }
        }
      }
    }
  }`;
};

const treeMessagesQuery = _treeId => {
    return `tree:Tree(id:"${_treeId}"){
        messages{
          id
          message
          isPrivate
          isReadable
          createdOn
          user{
            id
            title
            firstName
            lastName
            slug
            userType
            avatar{
              small
              medium
              large
            }
          }
          toUser{
            title
            firstName
            lastName
            userType
            avatar{
              small
              medium
              large
            }
          }
        }
      }`;
};

const treeLikesQuery = _treeId => {
    return `tree:Tree(id:"${_treeId}"){
        likes{
          id
          slug
          title
          firstName
          lastName
          avatar{
            small
            medium
            large
          }
        }
      }`;
};

const adjacentUserTreesQuery = (_treeId, _userId, _language, _mine, _gifted, _liked) => {
    return `adjacentTrees:Tree_Adjacent(userId: ${_userId}, currentTree: "${_treeId}", mine:${_mine?"true":"false"}, gifted:${_gifted?"true":"false"}, liked:${_liked?"true":"false"}){
                prev{
                 id
                 treeId
                 name
                 specie{
                   name{
                    ${_language}
                   }
                 }
                 limited{
                   limitedName{
                    ${_language}
                   }
                 }
               }
               next{
                 id
                 treeId
                 name
                 specie{
                   name{
                    ${_language}
                   }
                 }
                 limited{
                   limitedName{
                    ${_language}
                   }
                 }
               }
               total
               currentItem
            }`;

};

const adjacentEventTreesQuery = (_treeId, _eventId, _language) => {
    return `adjacentTrees:Tree_Adjacent(eventId: ${_eventId}, currentTree: "${_treeId}"){
        prev{
          id
          treeId
          name
          specie{
            name{
              ${_language}
            }
          }
          limited{
            limitedName{
              ${_language}
            }
          }
        }
        next{
          id
          treeId
          name
          specie{
            name{
              ${_language}
            }
          }
          limited{
            limitedName{
              ${_language}
            }
          }
        }
        total
        currentItem
      }`;
};

const treesNeighborsQuery = function(_treeId, _after, _language){
    return `treesNeighbors:Tree_Neighbor_page(id: "${_treeId}", after: ${(_after && _after.constructor === String ? `"${_after}"` : "null")}){
        edges{
          id
          distance
          treeId
          name
          user{
            title
            slug
          }
          specie{
            name{
              ${_language}
            }
            icon{
              small
            }
          }
          limited{
            limitedName{
              ${_language}
            }
            limitedNameSubTitle{
              ${_language}
            }
            icon{
              small
            }
          }
        }
        pageInfo{
          hasNextPage
          startCursor
          endCursor
        }
      }`;
};

const treesWidgetQuery = function(_treeId, _language){
    return `tree:Tree(id: "${_treeId}"){
          id
          treeId
          name
          user{
            id
            title
            slug
            userType
            avatar{
              small
            }
          }
          initialUser{
            id
            title
            slug
            userType
            avatar{
              small
            }
          }
          specie{
            name{
              ${_language}
            }
            country{
              name{
                ${_language}
              }
            }
            icon{
              medium
            }
          }
          limited{
            limitedName{
              ${_language}
            }
            limitedNameSubTitle{
              ${_language}
            }
            icon{
              medium
            }
          }
          event{
            id
            slug
            background{
              small
            }
            avatar{
              small
            }
            name
            published
            user{
              slug
              userType
            }
          }
      }`;
};

const treeLikeMutation = _treeId => {
    return `likes: Tree_Like_action(treeId: "${_treeId}"){
          id
          slug
          title
          firstName
          lastName
          avatar{
            small
            medium
            large
          }
    }`
};

const treeMessageAddMutation = _messsage => {
  return `messages:Tree_Message_add(message:{
    treeId: "${_messsage.treeId}",
    isPrivate: ${_messsage.private ? "true" : "false"},
    message: "${_messsage.message}"
  }){
      id
      message
      isPrivate
      isReadable
      createdOn
      user{
        id
        title
        firstName
        lastName
        slug
        userType
        avatar{
          small
          medium
          large
        }
      }
      toUser{
        title
        firstName
        lastName
        userType
        avatar{
          small
          medium
          large
        }
      }
  }`
};

const treeMessageUpdateMutation = _messsage => {
    return `messages:Tree_Message_update(message:{
    id: ${_messsage.id},
    isPrivate: ${_messsage.private ? "true" : "false"},
    message: "${_messsage.message}"
  }){
      id
      message
      isPrivate
      isReadable
      createdOn
      user{
        id
        title
        firstName
        lastName
        slug
        userType
        avatar{
          small
          medium
          large
        }
      }
      toUser{
        title
        firstName
        lastName
        userType
        avatar{
          small
          medium
          large
        }
      }
  }`
};


const treeNameMutation = (_treeId, _name) => {
    return `name: Tree_Name_set(treeId: "${_treeId}", name:"${_name}")`
};

export {
    treeInfoQuery,
    treeMessagesQuery,
    treeLikesQuery,
    adjacentUserTreesQuery,
    adjacentEventTreesQuery,
    treesNeighborsQuery,
    treesWidgetQuery,

    treeLikeMutation,
    treeMessageAddMutation,
    treeMessageUpdateMutation,
    treeNameMutation,

    treeDiary
}
