







































import { defineComponent, PropType, ref, computed } from '@vue/composition-api'
import Modal from '@f/components/Modal.vue'
import CopyInput from '@f/components/Form/CopyInput.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import { getMeta } from '@f/utils/dom/meta'

export default defineComponent({
  name: 'ShareNative',
  components: {
    CopyInput,
    Modal,
    Treecon,
    EmailShareIcon: () => import(/* webpackChunkName: "email-fill.svg" */ '@f/images/share/email-fill.svg'),
    FacebookShareIcon: () => import(/* webpackChunkName: "facebook-fill.svg" */ '@f/images/share/facebook-fill.svg'),
    LinkedInShareIcon: () => import(/* webpackChunkName: "linkedin-fill.svg" */ '@f/images/share/linkedin-fill.svg'),
    TwitterShareIcon: () => import(/* webpackChunkName: "twitter-fill.svg" */ '@f/images/share/twitter-fill.svg'),
  },
  props: {
    label: {
      type: [String, Boolean] as PropType<string | false>,
      default: 'generic.shareTitle'
    },
    shareImageUrl: {
      type: String,
      required: false
    },
    shareData: {
      type: [String, Object] as PropType<string | ShareData | undefined>,
      required: false
    },
    buttonClass: {
      type: [String, Array, Object] as PropType<any>,
      default: ''
    },
    line: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['shared'],
  setup(props, context) {
    const showFallbackShare = ref(false)

    const icon = computed(() => !!props.line ? 'share-line' : 'share-fill')
    const url = computed(() => {
      return typeof props.shareData === 'string'
        ? props.shareData
        : props.shareData?.url || window.location.href
    })
    const meta = computed(() => {
      return {
        url: url.value,
        title: getShareData().title
          || getMeta('title'),
        description: getShareData().text
          || getMeta('description'),
      }
    })
    const openGraph = computed(() => {
      return {
        url: url.value,
        title: getShareData().title
          || getMeta('og:title')
          || getMeta('title'),
        description: getShareData().text
          || getMeta('og:description')
          || getMeta('description'),
      }
    })
    const twitter = computed(() => {
      return {
        url: url.value,
        twitterUser: 'treedom',
        title: getShareData().title
          || getMeta('twitter:title')
          || getMeta('og:title')
          || getMeta('title'),
      }
    })

    const buttonClasses = computed(() => {
      return [
        {
          [props.buttonClass]: true,
          'share--disabled': props.disabled,
        }
      ]
    })

    const canShareNative = () => !!navigator.share
    const nativeShare = async () => {
      if (!props.shareImageUrl) {
        return navigator.share(openGraph.value)
      }
      const image = await fetch(props.shareImageUrl)
        .then(r => r.blob())
        .then((blob) => new File([blob], 'share.jpg', {
          type: blob.type,
        }))
      // @ts-ignore
      if (navigator.canShare && navigator.canShare({ files: [image] })) {
        return navigator.share({
          // @ts-ignore
          files: [image],
          title: openGraph.value.title,
          text: openGraph.value.description,
          url: openGraph.value.url,
        })
      }
      return navigator.share(openGraph.value)
    }
    function share() {
      if (canShareNative()) {
        nativeShare()
          .then(() => context.emit('shared'))
      } else {
        showFallbackShare.value = true
      }

      if ('click' in context.listeners) context.emit('click')
    }

    function getShareData() {
      if (typeof props.shareData === 'object') {
        return props.shareData
      }
      return { url: url.value }
    }
    
    return {
      icon,
      meta,
      openGraph,
      share,
      showFallbackShare,
      twitter,
      buttonClasses,
      url,
    }
  }
})
