import {computed, ref, Ref} from '@vue/composition-api'
import {Forest, ForestUser, UserInfo} from '@f/@types'
import {TrEventNumbers} from '@f/@types/graphql'
import {getRootScope, getStateService} from '@f/services/Angular'
import {translate} from '@f/services/Translation'
import {getImageAsset} from '@f/utils/media'
import useRoutes from '@f/composables/useRoutes'
import {MediaFormat} from '@f/@types/media'

export const defaultForestAvatar = require('@f/images/event/event_avatar_default.jpg')

export function getForestAvatar(
  avatarId: number | null | undefined | string,
  size: MediaFormat = 'medium'
) {
  return avatarId ? getImageAsset(String(avatarId), size) : defaultForestAvatar
}

/* Mappa per forzare un determinato URL quando si clicca su "Pianta in questa foresta"
 * nella pagina della foresta. Questo intervento deve essere ripetuto su treesite.
 * Qui la chiave e' lo slug, su treeside e' l'ID.
 * BFF si occupa di /organization/{organization}/event/{event_slug}
 * treeside si occupa di /forest/{event_slug}
 */
const forestMapForcedURLs: { [key: string] : string} = {
  'federazioneitgiuococalcio': 'holm-oak-tree_2078'
}

export default function useForestData(
  userInfo: Ref<UserInfo | null>,
  eventSlug: string
) {
  const forest = ref<Partial<Forest>>({})
  const forestUsers = ref<ForestUser[]>([])
  const forestUsersCount = ref<number>(0)
  const loading = ref<boolean>(false)
  const eventNumbers = ref<Partial<TrEventNumbers>>({})
  const stateService = getStateService()
  const { href } = useRoutes()

  const isMine = computed((): boolean => {
    const rootScope = getRootScope()
    return !!(forest.value.user?.slug === rootScope?.user)
  })

  const isForestPrivate = computed((): boolean => {
    return !!(forest.value.user?.type === 'Private')
  })

  const isUserPrivate = computed((): boolean => {
    return !!(userInfo.value?.usertype === 'Private')
  })

  const isForestPublished = computed((): boolean => {
    return !!forest.value.published
  })

  const coverImageUrl = computed((): string => {
    return forest.value.cover
      ? getImageAsset(String(forest.value.cover), 'xlarge', 100)
      : require('@f/images/event/event_cover_new.jpg')
  })

  const avatarImageUrl = computed((): string => {
    return getForestAvatar(forest.value.avatar, 'large')
  })

  const isFollowable = computed(() => !!forest.value.isFollowable)

  const ctaAction = computed((): string => {
    if (!isMine.value && forest.value.isOpen && !forest.value.hide_redeem) {
      return 'openRedeem'
    } else if (
      !isMine.value &&
      !forest.value.isOpen &&
      !forest.value.isRally &&
      !forest.value.hide_redeem
    ) {
      return 'redeem'
    } else if (!isMine.value && forest.value.isRally) {
      return 'plantHere'
    } else if (isMine.value) {
      return 'plant'
    } else {
      return 'none'
    }
  })

  const ctaLabel = computed((): string => {
    switch (ctaAction.value) {
      case 'openRedeem':
        return translate('forest.action.open')
      case 'plantHere':
        return translate('mytreeNew.button_plant_rally')
      case 'plant':
        return translate('mytreeNew.button_plant')
      case 'redeem':
        if (isForestPrivate.value) {
          return translate('treecode.widget.buttonText')
        }
        return translate('cta.redeem.noGiftWord')
      case 'none':
      default:
        return ''
    }
  })

  const isCtaVisible = computed((): boolean => {
    return ctaAction.value !== 'none'
  })

  const userProfileUrl = computed(() => {
    return href(isForestPrivate.value ? 'user' : 'organization')
  })

  const forestUrl = computed(() => {
    return href(isForestPrivate.value ? 'user.event' : 'organization.event', {
      eventslug: eventSlug,
    })
  })

  const forestTreesUrl = computed(() => {
    return href(
      isForestPrivate.value ? 'user.event.trees' : 'organization.event.trees',
      {
        eventslug: eventSlug,
      }
    )
  })

  function getSpeciesState() {
    const b2cState = 'species'
    const b2bState = 'business.plant-a-tree'
    if (!userInfo.value) {
      return b2cState
    }
    return isUserPrivate.value ? b2cState : b2bState
  }

  function goToCta(): void {
    const rootScope = getRootScope()
    if (!stateService || !rootScope) return

    if (ctaAction.value === 'plantHere' || ctaAction.value === 'plant') {
      rootScope.plantIn = forest.value as Forest

      if (eventSlug in forestMapForcedURLs) {
        stateService.go('species.tree', {
          treeId: forestMapForcedURLs[eventSlug]
        })

        return
      }

      stateService.go(getSpeciesState(), {
        type: 'rallyforest',
      })
    } else if (ctaAction.value === 'openRedeem') {
      stateService.go('openforest_redeem', {
        slug: forest.value?.user?.slug,
        eventslug: eventSlug,
      })
    } else if (ctaAction.value === 'redeem') {
      stateService.go('treecode')
    }
  }

  function back() {
    if (!stateService) return
    const state = isForestPrivate.value ? 'user.event' : 'organization.event'
    stateService.go(state)
  }

  return {
    forest,
    forestUsers,
    forestUsersCount,
    forestUrl,
    forestTreesUrl,
    userInfo,
    userProfileUrl,
    loading,
    eventNumbers,
    isMine,
    isForestPrivate,
    isUserPrivate,
    isForestPublished,
    ctaAction,
    ctaLabel,
    isCtaVisible,
    coverImageUrl,
    avatarImageUrl,
    isFollowable,
    goToCta,
    back,
  }
}
