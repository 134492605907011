import { JSONSchemaType } from 'ajv'
import {
  IQuizAction, IQuizBenefitChoice, IQuizCo2Choice, IQuizCountryChoice, IQuizDone, IQuizMeaningChoice, IQuizTreeChoice
} from '@f/validation/types/dataLayer'


export const QuizStartSchema: JSONSchemaType<IQuizAction> = {
  type: 'object',
  required: ['event'],
  properties: {
    event: {type: 'string'},
  }
}

export const QuizCountryChoiceSchema: JSONSchemaType<IQuizCountryChoice> = {
  type: 'object',
  required: ['event', 'location'],
  properties: {
    event: {type: 'string'},
    location: {type: 'string'}
  }
}

export const QuizBenefitChoiceSchema: JSONSchemaType<IQuizBenefitChoice> = {
  type: 'object',
  required: ['event', 'benefit'],
  properties: {
    event: {type: 'string'},
    benefit: {type: 'string'}
  }
}

export const QuizCo2ChoiceSchema: JSONSchemaType<IQuizCo2Choice> = {
  type: 'object',
  required: ['event', 'co2'],
  properties: {
    event: {type: 'string'},
    co2: {type: 'string'}
  }
}

export const QuizMeaningChoiceSchema: JSONSchemaType<IQuizMeaningChoice> = {
  type: 'object',
  required: ['event', 'meaning'],
  properties: {
    event: {type: 'string'},
    meaning: {type: 'string'}
  }
}

export const QuizTreeChoiceSchema: JSONSchemaType<IQuizTreeChoice> = {
  type: 'object',
  required: ['event', 'treeType'],
  properties: {
    event: {type: 'string'},
    treeType: {type: 'string'}
  }
}


export const QuizDoneSchema: JSONSchemaType<IQuizDone> = {
  type: 'object',
  required: ['treeTypeQuiz'],
  properties: {
    treeTypeQuiz: { type:'array', items:{type: 'string'}, nullable: true}
  }
}

