




















































import { computed, defineComponent, PropType } from '@vue/composition-api'
import ForestCard from '@f/components/Forest/ForestCard.vue'
import DotLoader from '@f/components/DotLoader.vue'
import TreeRow from '@f/components/Cards/TreeRow.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import useFormat from '@f/composables/useFormat'
import { Forest } from '@f/@types'
import useUserInfo from '@f/composables/useUserInfo'

export default defineComponent({
  name: 'ForestTreesBox',
  components: {
    DotLoader,
    ForestCard,
    TreeRow,
    Treecon,
  },
  props: {
    forestName: {
      type: String,
      default: '',
    },
    treesCount: {
      type: Number,
      default: 0,
    },
    forestTrees: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inner: {
      type: Boolean,
      default: false,
    },
    enableFetchMore: {
      type: Boolean,
      default: false,
    },
    forest: {
      type: Object as PropType<Partial<Forest>>,
      default: () => ({}),
    }
  },
  setup(props) {
    const { formatLocalNumbers } = useFormat()
    const { userInfo } = useUserInfo()

    const isMine = computed((): boolean => {
      return (props.forest?.user?.id === userInfo.value?.id)
    })

    return {
      isMine,
      formatLocalNumbers,
    }
  },
})
