




































































import { defineComponent, computed, ref } from '@vue/composition-api'
import { fetchForest, fetchForestNumbers } from '@f/services/Forest'
import { Event } from '@f/@types'
import ForestCompactHeader from '@f/components/Forest/ForestCompactHeader.vue'
import ForestTreesBox from '@f/components/Forest/ForestTreesBox.vue'
import {
  TrEventCountrySummary,
  TrEventNumbers,
  TrPageInfo,
  TrTranslation,
} from '@f/@types/graphql'
import { FOREST_COUNTRIES, FOREST_TREES } from '@f/graphql/queries'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import useForestData from '@f/composables/useForestData'
import useUserInfo from '@f/composables/useUserInfo'
import BaseDropdownSelect from '@f/components/Form/BaseDropdownSelect.vue'
import { getForestCountryNumbersFormatted } from '@f/services/Forest'
import { getStateService } from '@f/services/Angular'

interface ITreesQuery {
  forestTrees: ITreesQueryResult
}

interface ITreesQueryResult {
  pageInfo: TrPageInfo
  trees: any[]
}

export default defineComponent({
  name: 'ForestTreesPage',
  components: {
    ForestCompactHeader,
    ForestTreesBox,
    BaseDropdownSelect,
  },
  setup(props) {
    const mobileQuery = maxWidth('md')
    const isMobile = useMediaQuery(mobileQuery)
    const { userInfo } = useUserInfo()
    const {
      forest,
      loading,
      eventNumbers,
      isForestPrivate,
      isForestPublished,
      ctaAction,
      ctaLabel,
      isCtaVisible,
      goToCta,
      back,
      avatarImageUrl,
    } = useForestData(userInfo, props.eventSlug)

    const isUserPrivate = computed((): boolean => {
      return !!(userInfo.value?.usertype === 'Private')
    })

    const isMine = computed((): boolean => {
      return !!(forest.value.user?.id === userInfo.value?.id)
    })

    const forestCountries = ref<TrEventCountrySummary[]>([])

    function selectCountry(countryId: number | null, closeDropdown?: any) {
      selectedCountry.value = forestCountries.value.find(
        (c) => c.country?.id === countryId
      )
      forestTrees.value = forestTreesDefaultValue
      if (closeDropdown) {
        closeDropdown()
      }
    }

    function getFilterLabel(country: TrTranslation, count: number): string {
      return getForestCountryNumbersFormatted(country, count, true)
    }

    const selectedCountry = ref<TrEventCountrySummary | undefined>(undefined)

    const forestTreesDefaultValue: ITreesQueryResult = {
      pageInfo: {
        startCursor: '',
        endCursor: '',
        hasNextPage: false,
      },
      trees: [],
    }
    const forestTrees = ref<ITreesQueryResult>(forestTreesDefaultValue)

    return {
      isMobile,
      userInfo,
      forest,
      loading,
      eventNumbers,
      isMine,
      isForestPrivate,
      isUserPrivate,
      isForestPublished,
      ctaAction,
      ctaLabel,
      isCtaVisible,
      goToCta,
      back,
      avatarImageUrl,
      selectCountry,
      selectedCountry,
      forestCountries,
      getFilterLabel,
      forestTrees,
    }
  },
  props: {
    eventSlug: {
      type: String,
      required: true,
    },
  },
  computed: {
    variables(): any {
      const variables: any = {
        eventId: this.forest.id,
        limit: 10,
        after:
          (this.forestTrees.pageInfo && this.forestTrees.pageInfo.endCursor) ||
          null,
      }
      if (this.selectedCountry?.country?.id) {
        variables.countryId = this.selectedCountry.country.id
      }
      return variables
    },
    dropdownPlaceholder(): string {
      if (
        this.selectedCountry &&
        this.selectedCountry.country &&
        this.selectedCountry.country.name &&
        this.selectedCountry.count
      ) {
        return getForestCountryNumbersFormatted(
          this.selectedCountry?.country?.name,
          this.selectedCountry?.count,
          true
        )
      }
      return this.$t('forest.forestTrees.filter.allCountries')
    },
  },
  created() {
    this.$apollo.addSmartQuery('forestTrees', {
      query: FOREST_TREES,
      variables() {
        return this.variables
      },
      skip() {
        return (
          !this.forest.id ||
          !(this.forestCountries && this.forestCountries.length)
        )
      },
      update: (data) => {
        this.forestTrees = data.forestTrees
      },
    })
    this.$apollo.addSmartQuery('forestCountries', {
      query: FOREST_COUNTRIES,
      variables() {
        return { id: this.forest.id }
      },
      skip() {
        return !this.forest.id
      },
      update: (data: { Event_countries: TrEventCountrySummary[] }) => {
        this.forestCountries = [{}].concat(data.Event_countries)
        const stateService = getStateService()
        if (stateService?.params.countryId) {
          this.selectCountry(parseInt(stateService?.params.countryId))
        }
      },
    })
  },
  mounted() {
    this.getForestData()
  },
  methods: {
    getForestData(): void {
      this.loading = true
      fetchForest(this.eventSlug)
        .then((data: Event) => {
          this.forest = data

          if (!this.forest.published && !this.isMine) {
            this.$state.go('index')
          }

          fetchForestNumbers(this.eventSlug).then(
            (eventNumbers: TrEventNumbers) => {
              this.eventNumbers = eventNumbers
            }
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchMore() {
      if (this.forestTrees.pageInfo.hasNextPage) {
        this.$apollo.queries.forestTrees.fetchMore({
          variables() {
            return this.variables
          },
          updateQuery: (
            previousResult: ITreesQuery,
            { fetchMoreResult: { forestTrees } }: any
          ) => {
            return {
              forestTrees: {
                __typename: forestTrees.__typename,
                pageInfo: { ...forestTrees.pageInfo },
                trees: [
                  ...previousResult.forestTrees.trees,
                  ...forestTrees.trees,
                ],
              },
            }
          },
        })
      }
    },
  },
})
