import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import { LoggedEventCallback, onLoggedStatusChange } from '@f/services/Auth'

export function onLogged(handler: LoggedEventCallback, once = true) {
  const clearListener = ref<ReturnType<typeof onLoggedStatusChange>>()

  onMounted(() => {
    clearListener.value = onLoggedStatusChange(handler, once)
  })

  onUnmounted(() => {
    clearListener.value && clearListener.value()
  })
}
