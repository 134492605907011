import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
.controller('newMapController', ['$scope', '$rootScope', '$stateParams', '$state', 'Utility', 'Cart', 'User', '$timeout', '$filter', function ($scope, $rootScope, $stateParams, $state, Utility, Cart, User, $timeout, $filter) {
  window.mapboxgl.accessToken = 'pk.eyJ1IjoidG9tbWFzb3NwZXJvbmkiLCJhIjoiY2tnOTE3eW12MDJqazMybXNzOWV1YjloOSJ9.wtGsuDU7XIKjcv2cq8CiXw'
  var _ = window._
  var $ = window.$

  $scope.mapTrees = []
  $scope.markers = []
  $scope.markersPimp = []
  $scope.modalMarker = false
  $scope.swiper = false
  $scope.plantMode = false
  $scope.activeMapType = 'satellite'
  $scope.varTest = false
  $scope.varContainer = false
  $scope.mapIsExpanded = false
  $scope.hideHeader = false
  $scope.hideFilters = false
  $scope.countries = $rootScope.projects

  $scope.toggleTest = function () {
    $scope.varTest = !$scope.varTest
  }

  $scope.modalContainer = function () {
    $scope.varContainer = !$scope.varContainer
  }

  var geojson = { 'type': 'FeatureCollection', 'features': [] }
  var mapType = {
    street: 'mapbox://styles/tommasosperoni/cj78p16077drf2sshtkd6gjjf', // cambia lo stile in street - light - bright - basic - dark -satellite
    satellite: 'mapbox://styles/mapbox/satellite-v9'
  }

  $scope.types = {
    mine: true,
    gifted: true,
    loved: true,
    neighbors: true
  }

  var remove = function (markers, type, cb) {
    markers.forEach(function (entry) {
      entry.remove()
    })

    cb()
  }

  function moveCartCounter () {
    var cartCounter = document.getElementById('cartCounter')
    cartCounter.style.transform = 'scale(1.7)'
    $timeout(function () { cartCounter.style.transform = 'scale(1)' }, 250)
  }

  $scope.addToCart = function (idItem, isGift, goTo, quantity) {
    Utility.disableButton()

    var gift = isGift ? 1 : 0

    var goToUser = !$rootScope.giftToUser || typeof $rootScope.giftToUser === 'undefined'
      ? 0
      : $rootScope.giftToUser.id

    var hasEvent = (typeof $rootScope.plantIn === 'undefined' || !$rootScope.plantIn) || (typeof $rootScope.activeTree.idEvent === 'undefined' || !$rootScope.activeTree.idEvent)
      ? 0
      : $rootScope.plantIn.id

    // var isLimited = $rootScope.activeTree.limitedId #FIXME
    //   ? $rootScope.activeTree.limitedId
    //   : 0

    var isLimited = 0

    if (goToUser) gift = 1 // se comunque lo regalo ad un utento segno anche il fleg del regalo

    quantity = isUndefined(quantity)
      ? 1
      : quantity <= 0 ? 1 : quantity

    Cart.add(idItem, gift, goToUser, 0, isLimited, hasEvent, quantity)
    .then(function(data) {

      data = data.data || data
      // #GA
      /*Utility.gaAdd('addProduct', $rootScope.activeTree)
      Utility.gaAction('add', {})
      Utility.gaSend()*/

      Utility.enableButton()
      $rootScope.userdata.cart = data
      $scope.modalMarker = false

      moveCartCounter()
    })
  }
  /**
   * Inizializza la mappa
   */
  const createMap = function (type) {
    const container = 'map'
    if (!document.getElementById(container)) throw Error('No map container.')
    $scope.map = new window.mapboxgl.Map({
      container,
      style: type,
      center: [0, 0],
      zoom: 1,
      minZoom: 2,
      trackResize: true,
      interactive: true // Blocca le interazioni sulla mappa
    })

    $scope.map.addControl(new window.mapboxgl.NavigationControl())

    // disable map zoom when using scroll
    $scope.map.scrollZoom.disable();

    $scope.map.on('zoomend', function (e) {
      if ($rootScope.mapPimp) {
        $scope.markersPimp = $rootScope.mapPimp($scope.map, $scope.activeMapType === 'satellite', $scope.markersPimp)
      }

      if (!$scope.plantMode) $scope.rechargeMarkers()
    })
  }

  /*
   * funzione che fa volare da un paese all'altro
   */
  $scope.flyTo = function (country) {
    $scope.map.flyTo({center: [country.lng, country.lat], zoom: country.zoom})
  }

  /*
  * funzione Switch mappa satellitare mappa animata
  */
  var setMapStyle = function (type) {
    $scope.map.setStyle(type)
  }

  /*
  * funzione che setta la zoom  massimo in modo da farci entrare tutti i marker presenti nella mappa
  */
  var fitBounds = function (map, trees, firstZoom) {
    var bounds = [[0, 0], [0, 0]]
    trees.forEach(function (entry) {
      if (bounds[0][0] > entry.lng || bounds[0][0] === 0) bounds[0][0] = entry.lng
      if (bounds[1][0] < entry.lng || bounds[1][0] === 0) bounds[1][0] = entry.lng
      if (bounds[0][1] > entry.lat || bounds[0][1] === 0) bounds[0][1] = entry.lat
      if (bounds[1][1] < entry.lat || bounds[1][1] === 0) bounds[1][1] = entry.lat
    })

    var options = { padding: {top: 50, bottom: 50, left: 50, right: 50} }
    if (firstZoom) options.duration = 0

    map.fitBounds(bounds, options)

    if (firstZoom) {
      $timeout(function () { map.setZoom(2.1) }, 1000)
    }
  }

  /**
   * funzione che apre la modale in caso di click su marker
   */
  $scope.openModal = function (typeObj, idObj) {
    $scope.modalMarker = true
    $scope.modalType = typeObj
    if ($scope.modalType === 'tree') {
      $scope.markerInfo = geojson.features.find(feature => feature.properties.id === idObj)
    } else if ($scope.modalType === 'nursery') { // in caso di nursery prendo un marker con le coordinate e poi cerco tutti i marker con quelle coordinate
      $scope.markerInfo = geojson.features.find(feature => feature.properties.id === idObj)

      $scope.markersInfo = geojson.features
        .filter(feature => feature.geometry.coordinates[0] === $scope.markerInfo.geometry.coordinates[0])
    } else if ($scope.modalType === 'plant') {
      $scope.country = $scope.countries[idObj]
    }

    $scope.$apply()
  }

  /*
  * funzione che crea i div dei marker
  */
  $scope.createMarkerDiv = function (markers) {
    var iconNursery = $filter('asset')('bundles/treedomnewfrontend/images/map/nursery.svg')

    for (var key in markers) {
      var marker = markers[key]
      if (!marker.properties.isPlanted) {
        continue
      }
      var goOn = false
      marker.properties.type.forEach(function (entry) {
        if ($scope.types[entry]) {
          goOn = true
        }
      })

      if (!goOn) continue
      var classCss
      var markerTypeAttr

      if ($.inArray('gifted', marker.properties.type)) {
        classCss = 'markerGL' + marker.properties.type
        markerTypeAttr = marker.properties.type
      } else if ($.inArray('mine', marker.properties.type)) {
        classCss = 'markerGL' + marker.properties.type
        markerTypeAttr = marker.properties.type
      } else if ($.inArray('neighbors', marker.properties.type)) {
        classCss = 'markerGL' + marker.properties.type
        markerTypeAttr = marker.properties.type
      } else {
        classCss = 'markerGL' + 'loved'
        markerTypeAttr = marker.properties.type
      }

      var el = document.createElement('div')
      el.className = 'markerGL ' + classCss
      el.style.backgroundImage = $scope.activeMapType === 'street'
        ? 'url(https://s3-eu-west-1.amazonaws.com/' + $rootScope.public_bucket + '/images/' + 'medium' + '_' + marker.properties.iconPicture + ')'
        : 'url(https://s3-eu-west-1.amazonaws.com/' + $rootScope.public_bucket + '/images/' + 'medium' + '_' + marker.properties.treePicture + ')'
      el.setAttribute('marker-type', 'tree')
      el.setAttribute('marker-class', markerTypeAttr)
      el.setAttribute('marker-id', marker.properties.id)

      el.addEventListener('click', function (e) {
        var idObj = e.currentTarget.getAttribute('marker-id')
        var typeObj = e.currentTarget.getAttribute('marker-type')
        $scope.openModal(typeObj, idObj)
      })

      var userAvatar = document.createElement('div')
      userAvatar.className = 'markerGLUser ' + classCss
      userAvatar.style.backgroundImage = 'url(https://s3-eu-west-1.amazonaws.com/' + $rootScope.public_bucket + '/images/' + 'medium' + '_' + marker.properties.userAvatar + ')'
      $(el).append(userAvatar)

      // add marker to map
      $scope.mal = new window.mapboxgl.Marker(el, {offset: [-marker.properties.iize[0] / 2, -marker.properties.iize[1] / 2]})
      .setLngLat(marker.geometry.coordinates)
      .addTo($scope.map)

      $scope.markers.push($scope.mal)
    }

    for (key in markers) {
      marker = markers[key]
      if (marker.properties.isPlanted) {
        continue
      }

      var goOn = false
      marker.properties.type.forEach(function (entry) {
        if ($scope.types[entry]) {
          goOn = true
        }
      })

      if (!goOn) continue
      // #TODO Evitare che per stesso vivaio passi n volte
      // #TODO Gestione delle tipologie disabilitate anche per nursery (vedi number)

      marker.properties.iize[0] = 76

      el = document.createElement('div')
      el.className = 'markerGLNursery'
      el.style.backgroundImage = 'url(' + iconNursery + ')'
      el.setAttribute('marker-id', marker.properties.id)
      el.setAttribute('marker-type', 'nursery')
      el.addEventListener('click', function (e) {
        var idObj = e.currentTarget.getAttribute('marker-id')
        var typeObj = e.currentTarget.getAttribute('marker-type')
        $scope.openModal(typeObj, idObj)
      })

      // FIXME: Fix perchè il count della nursery è sbagliato.
      var markersFiltered = markers
        .filter(mark => mark.geometry.coordinates[0] === markers[key].geometry.coordinates[0])

      var userAvatar2 = document.createElement('div')
      userAvatar2.innerHTML = markersFiltered.length // marker.properties.number
      userAvatar2.className = 'markerGLNumber'
      userAvatar2.style.width = 30 + 'px'
      userAvatar2.style.height = 30 + 'px'
      $(el).append(userAvatar2)

      // add marker to map
      $scope.mal = new window.mapboxgl.Marker(el, {offset: [-marker.properties.iize[0] / 2, -marker.properties.iize[1] / 2]})
      .setLngLat(marker.geometry.coordinates)
      .addTo($scope.map)

      $scope.markers.push($scope.mal)
    }
  }

  /**
   * reset dei marker li cancella tutti e li ricrea
   */
  $scope.rechargeMarkers = function () {
    $scope.plantMode = false

    remove($scope.markers, false, function () {
      $scope.createMarkerDiv(geojson.features)
    })
  }

  /**
   * allo switch del tipo di mappa cambia lo stile della mappa, i marker e li ricarica
   */
  $scope.toggleSatelliteMap = function () {
    $scope.activeMapType = $scope.activeMapType === 'street' ? 'satellite' : 'street'
    $scope.markersPimp = $rootScope.mapPimp($scope.map, $scope.activeMapType === 'satellite', $scope.markersPimp)
    setMapStyle(mapType[$scope.activeMapType])
    $scope.rechargeMarkers()
  }

  /**
   * allo switch del tipo di mappa cambia lo stile della mappa, i marker e li ricarica
   */
  $scope.changeMapStatus = function (type) {
    // Se la mappa ha già il filtro cliccato non lo cambio
    if ($scope.activeMapType === type) return null

    $scope.activeMapType = type
    $scope.markersPimp = $rootScope.mapPimp($scope.map, $scope.activeMapType === 'satellite', $scope.markersPimp)
    setMapStyle(mapType[$scope.activeMapType])
    $scope.rechargeMarkers()
  }

  /**
   * cicla sugli alberi e con la funzione pushTree prepara gli oggetti per essere visualizzati sulla mappa
   */
  var chargeMyTrees = function () {
    for (var key in $scope.myTrees) {
      var obj = $scope.myTrees[key]
      $scope.mapTrees.push({lng: obj.lng, lat: obj.lat})
      pushTree(obj)
    }
  }

  /**
   * al clicca su pianta crea i div sulla mappa per la piantuamazione
   */
  $scope.plant = function () {
    var iconPlant = $filter('asset')('bundles/treedomnewfrontend/images/userPage/treedom-shovel-plant.svg')
    $scope.plantMode = !$scope.plantMode

    if ($scope.plantMode) {
      remove($scope.markers, false, function () {
        $scope.countries.map((marker, index) => {
          var markerSize = 55

          var el = document.createElement('div')
          el.className = 'markerGLPlant'
          el.style.width = markerSize + 'px'
          el.style.height = markerSize + 'px'
          el.setAttribute('marker-id', index)
          el.setAttribute('marker-type', 'plant')
          el.addEventListener('click', function (e) {
            var idObj = e.currentTarget.getAttribute('marker-id')
            var typeObj = e.currentTarget.getAttribute('marker-type')
            if ($scope.map.getZoom() < $scope.countries[idObj].zoom) {
              $scope.flyTo($scope.countries[idObj])
              $timeout(function () { $scope.openModal(typeObj, idObj) }, 1000)
            } else {
              $scope.openModal(typeObj, idObj)
            }
          })

          var elCircle = document.createElement('div')
          elCircle.className = 'markerGLPlantCircle'

          var elIcon = document.createElement('div')
          elIcon.className = 'markerGLPlantIcon'
          elIcon.style.backgroundImage = 'url(' + iconPlant + ')'

          el.append(elCircle)
          el.append(elIcon)

          // add marker to map
          $scope.mal = new window.mapboxgl.Marker(el, {offset: [-markerSize / 2, -markerSize / 2]})
          .setLngLat([marker.lng, marker.lat])
          .addTo($scope.map)

          $scope.markers.push($scope.mal)
        })

        $scope.mapTrees = []

        for (var key in $scope.countries) {
          var marker = $scope.countries[key]
          $scope.mapTrees.push({ lng: marker.lng, lat: marker.lat })
        }

        fitBounds($scope.map, $scope.mapTrees)
      })
    } else {
      $scope.rechargeMarkers()
    }
  }

  /**
   * prepara gli alberi in formato geoJson
   */
  var pushTree = function (obj) {
    geojson.features.push(
      {
        'type': 'Feature',
        'properties': {
          'id': obj.id,
          'iize': [60, 60],
          'specie': obj.specie,
          'iconPicture': obj.specieIcon,
          'userAvatar': obj.userAvatar,
          'userTitle': obj.userTitle,
          'userSlug': obj.userSlug,
          'country': obj.country,
          'initialUserAvatar': obj.initialUserAvatar,
          'status': obj.status,
          'initialUserTitle': obj.initialUserTitle,
          'initialUserSlug': obj.initialUserSlug,
          'event': obj.event,
          'eventSlug': obj.eventSlug,
          'eventBackground': obj.eventBackground,
          'isPlanted': obj.isPlanted,
          'treePicture': obj.status > 3 ? obj.treePicture : obj.specieIcon,
          'type': obj.type, // nursery o no
          'number': obj.number,
          'markerType': obj.markerType // se devo passare un parametro particolare
        },
        'geometry': {
          'coordinates': [
            obj.lng,
            obj.lat
          ]
        }
      }
    )
  }

  // Slider lista alberi da piantare per paese
  var Swiper = require('swiper')
  $scope.treesMapSlider = null

  $scope.renderTreesMapSwiper = function () {
    if ($scope.treesMapSlider) {
      !Array.isArray($scope.treesMapSlider)
        ? $scope.treesMapSlider.destroy(true, true)
        : $scope.treesMapSlider.forEach(function (s) { s.destroy(true, true) })
    }

    // if (!ctrl.trees.length) return

    $scope.treesMapSlider = new Swiper('#SwiperTreesMap', {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      nextButton: '#TreesMapButtonNextSlider',
      prevButton: '#TreesMapButtonPrevSlider',
      paginationClickable: true,
      grabCursor: true
    })
  }

  // Chiude la modale e distrugge lo Slider
  $scope.closeModal = function () {
    if ($scope.swiper) {
      !Array.isArray($scope.swiper)
        ? $scope.swiper.destroy(true, true)
        : $scope.swiper.forEach(function (s) { s.destroy(true, true) })
      $scope.swiper = false
    }

    $scope.markersInfo = false
    $scope.modalMarker = false
  }

  // Trova gli alberi in ecommerce per paese
  $scope.getTreesForCountry = function (countrySlug) {
    return $rootScope.trees.middle.filter(tree => tree.onHand
        && countrySlug === (
          tree.specie
          && tree.specie.country
          && tree.specie.country.slug)
    )
  }

  /**
   * Prendo le informazioni della mappa di un utente.
   */
  $scope.initMap = function (userId) {
    User.newMap(userId)
    .then(function(data) {

      data = data.data || data
      $scope.myTrees = data.trees
      chargeMyTrees()
      createMap(mapType[$scope.activeMapType])
      $scope.createMarkerDiv(geojson.features)
      fitBounds($scope.map, $scope.mapTrees, true)
      $scope.map.once('idle', () => {
        if(!$scope.$$phase) $scope.$apply();
        $timeout(() => {
          $scope.map.resize();
        });
      });
    })
  }

  if ($state.current.name === 'iframe') {
    $scope.initMap($stateParams.user, $rootScope.iframeMode )
    $scope.hideHeader = true
    $scope.mapIsExpanded = true
    $scope.hideFilters = true
  }

  // NOTE: usato solamente in sviluppo. Da elminare una volta integrata la mappa.
  if ($state.current.name === 'newMap' || $state.current.name === 'newMap.user') {
    if ($state.params.userId) {
      $scope.initMap($state.params.userId)
    } else {
      $scope.initMap(73651)
    }
  }

  /**
   * Remove all active markers
   */
  var removeMarkers = function () {
    var markersNumber = $scope.markers.length
    $scope.markers.forEach(function (marker, index) {
      if (marker) marker.remove()
      if (index + 1 === markersNumber) $scope.markers = []
    })
  }

  var ListenerStateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
    if (fromState.name === toState.name && toParams.slug !== fromParams.slug) {
      removeMarkers()
      $scope.map.remove()
    }
  })

  /**
   * Ascoltatore per inizializzare la mappa da altri controller
   * @param {string} data.userId - User ID
   * @param {boolean} data.expanded - Map is expanded
   */
  var ListenerLoadUserMap = $rootScope.$on('LoadUserMap', function (event, data) {
    if (!data || !data.userId) return
    $scope.initMap(data.userId)
    $scope.mapIsExpanded = data.expanded || false
    $scope.hideHeader = data.hideHeader || false
    $scope.hideFilters = data.hideFilters || false

    if ($scope.hideFilters) {
      $scope.types = {
        mine: true,
        gifted: false,
        loved: false,
        neighbors: false
      }
    }
  })

  /**
   * Ascoltatore per eseguire il resize della mappa da altri controller.
   */
  var ListenerResizeUserMap = $rootScope.$on('ResizeUserMap', function () {
    if ($scope.map) $scope.map.resize()
  })

  /**
   * Ascoltatore per visualizzare i comandi della mappa.
   */
  var ListenerExpandUserMap = $rootScope.$on('ExpandUserMap', function (event, expand) {
    $scope.mapIsExpanded = expand || false
  })

  // Destroy listeners when controller has been detached
  $scope.$on('$destroy', ListenerStateChangeStart)
  $scope.$on('$destroy', ListenerLoadUserMap)
  $scope.$on('$destroy', ListenerResizeUserMap)
  $scope.$on('$destroy', ListenerExpandUserMap)
}])
