
























































































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'
import { PrivateUserData } from '@f/composables/fetchUserData'
import { getModalService, getRootScope } from '@f/services/Angular'
import useRoutes from '@f/composables/useRoutes'
import useAuthentication from '@f/composables/useAuthentication'
import Avatar from '@f/components/Avatar.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Modal from '@f/components/Modal.vue'
import Popover from '@f/components/Popover.vue'
import ShareNative from '@f/components/ShareNative.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import { formatNeutralized } from '@f/utils'
import {
  fetchUserFriends,
  isFollowed,
  toggleFollowUser,
} from '@f/services/User'
import { UserFriend } from '@f/@types'
import { responsive } from '@f/composables/responsive'
import { DataEventToggleFollow } from '@f/services/TagManager/events'
import useUserInfo from '@f/composables/useUserInfo'

export default defineComponent({
  name: 'PrivateInfo',
  components: {
    Avatar,
    DotLoader,
    Modal,
    Popover,
    ShareNative,
    Treecon,
    Co2CapturedModal: () =>
      import(
        /* webpackChunkName: "Co2CapturedModal" */ '@f/components/_legacy/Co2CapturedModal.vue'
      ),
    UserFriends: () =>
      import(
        /* webpackChunkName: "UserFriends" */ '@f/pages/User/partials/UserFriends.vue'
      ),
  },
  props: {
    user: {
      type: Object as PropType<PrivateUserData>,
    },
    showSubscriber: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const rootScope = getRootScope() || undefined
    const lang = rootScope?.ln || 'it'
    const { isLoggedAs, isLogged } = useAuthentication()
    const { userInfo } = useUserInfo()
    const { breakpoints, windowWidth } = responsive()
    const { href, go } = useRoutes()

    const showFriendsModal = ref(false)
    const showCo2Modal = ref(false)
    const friendsTabName = ref('followers')
    const followersFriends = ref<UserFriend[]>([])
    const followingFriends = ref<UserFriend[]>([])
    const fetchState = ref({ friendsList: false, drivefollow: false })

    const avatar = computed(() => props.user?.info.avatar_id || '')
    const co2 = computed(() => {
      if (!!!props.user?.co2) return
      return (
        '-' + formatNeutralized(props.user?.numbers?.sumKilos || 0, lang) || 0
      )
    })

    const editUrl = computed(() => href('settings'))
    const gifts = computed(
      () => !!props.user?.summary && (props.user?.summary.gifted || 0)
    )
    const trees = computed(
      () => !!props.user?.summary && (props.user?.summary.mine || 0)
    )
    const followClass = computed((): string => {
      return isFollowed(followersFriends.value, userInfo.value?.slug)
        ? 'is-followed'
        : ''
    })
    const isAlreadyFollow = computed((): boolean => {
      return isFollowed(followersFriends.value, userInfo.value?.slug || '')
    })

    const isDesktop = computed(
      () => windowWidth.value > breakpoints.value.medium
    )

    function openFriendsModal(showList: string) {
      showFriendsModal.value = true
      friendsTabName.value = showList
    }

    function followHim() {
      if (!isLogged.value) {
        const ngModalService = getModalService()
        ngModalService &&
          ngModalService.open({
            templateUrl: 'login.html',
            easyClose: false,
            fitcontent: true,
          })
        return
      }

      if (!props.user?.info?.id) return

      fetchState.value.drivefollow = true
      toggleFollowUser(props.user.info.slug)
        .then(() => {
          DataEventToggleFollow(!isAlreadyFollow.value, 'user-profile', 'user')
          return fetchFriends(Number(props.user?.info.id))
        })
        .catch((error: Error) => {
          throw error
        })
        .finally(() => (fetchState.value.drivefollow = false))
    }

    function fetchFriends(userId: number) {
      if (userId) {
        fetchUserFriends(userId)
          .then(({ follower, following }) => {
            followersFriends.value = follower || []
            followingFriends.value = following || []
          })
          .finally(() => {
            fetchState.value.friendsList = true
          })
      }
    }

    const onSubscriberStarClick = () => {
      emit('subscriberClick')
    }

    // TODO matbe merge this data with the user/%user%/balancer endpoint instead to send another request
    watch(() => Number(props.user?.info.id), fetchFriends, { immediate: true })

    return {
      go,
      avatar,
      co2,
      editUrl,
      gifts,
      isLoggedAs,
      isLogged,
      openFriendsModal,
      showFriendsModal,
      showCo2Modal,
      friendsTabName,
      followersFriends,
      followingFriends,
      trees,
      followHim,
      followClass,
      fetchState,
      isAlreadyFollow,
      isDesktop,
      onSubscriberStarClick,
    }
  },
})
