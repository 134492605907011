















import { computed, defineComponent, ref } from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'
import { getRootScope } from '@f/services/Angular'

export default defineComponent({
  name: 'DownloadAppSnackBanner',
  components: { Treecon },
  setup() {
    const rootScope = getRootScope()
    const isSupportedLanguage = computed(() => ['it', 'en'].includes(rootScope?.ln!))
    const localStorageKey = `treedom:hideDownloadAppSnackBanner-${rootScope?.user}`
    const show = ref(localStorage.getItem(localStorageKey)  !== 'true')
    return {
      show,
      isSupportedLanguage,
      hideDownloadAppSnackBanner: () => {
        show.value = false
        localStorage.setItem(localStorageKey, 'true')
      }
    }
  }
})
