




































import { defineComponent, ref, computed, onMounted, onUnmounted, PropType, } from '@vue/composition-api'
import Popover from '@f/components/Popover.vue'
import { TrTranslation } from '@f/@types/graphql'

export default defineComponent({
  name: 'BaseDropdownSelect',
  components: { Popover },
  props: {
    value: {
      type: [String, Number, Object] as PropType<string | number | Record<string, any>>,
    },
    placeholder: {
      type: [String, Object] as PropType<string | TrTranslation>,
      default: ''
    },
    options: {
      type: [Array] as PropType<any[]>,
      default: () => []
    },
    active: {
      type: [Number, Function] as PropType<number | ((i: any) => boolean)>,
      default: -1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 500
    },
    compact: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { slots }) {
    const open = ref(false)
    const showEmptySlot = computed(() => !!slots.empty && props.options?.length)
    const componentRoot = ref<HTMLElement>()
    const popoverTrigger = ref<HTMLElement>()
    const closeListener = ref()

    function registerListeners(register = true) {
      const action = !!register ? 'addEventListener' : 'removeEventListener' as const
      componentRoot.value?.[action]('mousedown', checkOutsideClick)
      componentRoot.value?.[action]('mouseleave', closeDropdown)
      componentRoot.value?.[action]('mouseenter', abortClose)
    }

    function closeDropdown() {
      if (!!open.value) {
        closeListener.value = setTimeout(() => {
          open.value = false
        }, props.delay)
      }
    }

    function abortClose() {
      clearTimeout(closeListener.value)
    }

    function checkOutsideClick(ev: Event) {
      if (
        open.value
        && !ev.composedPath().includes(componentRoot.value as Element)
      ) {
        open.value = false
      }
    }

    function isActive(option: any, index: number) {
      return !!props.value && typeof props.active === 'function'
        ? props.active(option)
        : props.active === index
    }

    onMounted(registerListeners)
    onUnmounted(registerListeners.bind(undefined, false))
    
    return {
      closeDropdown,
      componentRoot,
      isActive,
      open,
      popoverTrigger,
      showEmptySlot,
    }
  }
})
