import tagManagerService from '@f/services/TagManager';
import { validateTreeRegistryInteraction, validateTreeRegistrySelection } from '@f/validation/datalayer';

export function DataEventTreeRegistry(
    treeId: string | null,
    country: string | null,
    userId: number | null,
    ) {
    tagManagerService.push({
        event: 'registerOfTrees',
        treeId,
        country,
        userId,
    }, validateTreeRegistryInteraction)
}


export function DataEventTreeRegistrySelection(
    treeId: string,
    tree: string,
    userId: number,
    ) {
    tagManagerService.push({
        event: 'registerOfTreesSelection',
        treeId,
        tree,
        userId,
    }, validateTreeRegistrySelection)
}