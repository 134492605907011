



































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import Modal from '@f/components/Modal.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import BaseInput from '@f/components/Form/BaseInput.vue'
import {useEditTreeName} from '@f/composables/useEditTree'
import {TrTree} from '@f/@types/graphql'
import useModel from '@f/composables/useModel'
import {baseStripTag, replaceHtmlAsciiCodes} from "@f/utils";

export default defineComponent({
  name: 'TreeEditNameModal',
  components: { BaseInput, Treecon, DotLoader, Modal },
  props: {
    tree: {
      type: Object as PropType<TrTree>,
      required: true,
    },
    value: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const maxNameLength = 15;
    const open = useModel(props, emit)
    const saving = ref(false)
    const {
      name: editedName,
      updateNameNext
    } = useEditTreeName({...props.tree, name: cleanTreeName(props.tree?.name)})


    const validation = computed(() => {
      const invalidLength = editedName.value.length > maxNameLength || !editedName.value.length
      const htmlTagsAndASCIICodesRegex = /<(?:[A-Za-z][A-Za-z0-9]*)\b[^>]*>|&#([0-9]{1,3}|x[0-9a-fA-F]{1,2});/;
      const invalidContent = htmlTagsAndASCIICodesRegex.test(editedName.value);
      return {
        hasErrors: invalidLength || invalidContent,
        invalidLength,
        invalidContent
      }
    })

    const treeName = computed(() => props.tree?.name ? cleanTreeName( props.tree.name) : '')
    const treeSpecie = computed(() => props.tree?.specie?.name || '')

    function saveName() {
      if(!validation.value.hasErrors) {
        saving.value = true
        updateNameNext()
          .finally(() => {
            open.value = false;
            emit('savedName')
            saving.value = false
          })
      }
    }

    function cleanTreeName(name: string | null | undefined) {
      if(name) {
        return baseStripTag(replaceHtmlAsciiCodes(name))
      }
      return ''
    }

    return {
      open,
      treeName,
      treeSpecie,
      saveName,
      editedName,
      saving,
      validation,
      maxNameLength,
    }
  }
})
