import { JSONSchemaType } from 'ajv'
import {
  IRansomChoice,
  IRansomOpen,
  IRansomRedeem,
  IRansomStart,
} from '@f/validation/types/dataLayer'

const userType = {
  type: 'string',
  enum: ['Private'],
  nullable: false,
}
const ransomCode = {
  type: 'string',
  nullable: false,
}
const position = {
  type: 'string',
  enum: ['treecode-redeem-flow', 'treecode-redeem-landing', 'treecode-toolbar'],
  nullable: false,
}
const ransomType = {
  type: 'string',
  enum: ['picker', 'virgin', 'simple'],
  nullable: false,
}

const numberOfTree = {
  type: 'number',
  nullable: false,
}

const logged = {
  type: 'boolean',
  nullable: false,
}

const treeType = {
  type: 'string',
  nullable: false,
}

export const RansomStartSchema: JSONSchemaType<IRansomStart> = {
  type: 'object',
  required: ['event', 'logged', 'ransomCode', 'position'],
  properties: {
    event: { type: 'string', const: 'startRansom' },
    logged: { type: 'boolean' },
    ransomCode: { type: 'string' },
    position: { type: 'string' },
    giftType: {type: 'string' }
  }
}

export const RansomStartConfirmedSchema: JSONSchemaType<IRansomStart> = {
  type: 'object',
  required: ['event', 'logged', 'ransomCode', 'position'],
  properties: {
    event: { type: 'string', const: 'startRansomConfirmed' },
    logged: { type: 'boolean' },
    ransomCode: { type: 'string' },
    position: { type: 'string' },
    giftType: {type: 'string' }
  }
}

export const RansomOpenSchema: JSONSchemaType<IRansomOpen> = {
  type: 'object',
  required: ['event', 'logged', 'ransomCode', 'position', 'ransomType', 'numberOfTree'],
  properties: {
    event: { type: 'string', const: 'openRansom' },
    logged: { type: 'boolean' },
    ransomCode: { type: 'string' },
    position: { type: 'string' },
    ransomType: { type: 'string' },
    numberOfTree: { type: 'number' },
    giftType: {type: 'string' }
  }
}

export const RansomChoiceSchema: JSONSchemaType<IRansomChoice> = {
  type: 'object',
  required: ['event', 'logged', 'ransomCode', 'position', 'ransomType', 'numberOfTree', 'treeType'],
  properties: {
    event: { type: 'string', const: 'choiceRansom' },
    logged: { type: 'boolean' },
    ransomCode: { type: 'string' },
    position: { type: 'string' },
    ransomType: { type: 'string' },
    numberOfTree: { type: 'number' },
    treeType: { type: 'string' },
    giftType: {type: 'string' }
  }
}

export const RansomRedeemSchema: JSONSchemaType<IRansomRedeem> = {
  type: 'object',
  required: ['event', 'logged', 'ransomCode', 'position', 'ransomType', 'numberOfTree', 'treeType'],
  properties: {
    event: { type: 'string', const: 'giftRansom' },
    logged: { type: 'boolean' },
    ransomCode: { type: 'string' },
    position: { type: 'string' },
    ransomType: { type: 'string' },
    numberOfTree: { type: 'number' },
    treeType: { type: 'string' },
    giftType: {type: 'string' }
  }
}
