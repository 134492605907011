










import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "Buckler"
})
export default class Buckler extends Vue {
  @Prop({ type: String, default: '' }) readonly textTop!: string;
  @Prop({ type: String, default: '' }) readonly textBottom!: string;
  @Prop({ type: String, default: null }) readonly centerImage!: string;
  @Prop({ type: Boolean, default: false }) readonly sprout!: boolean;
  
  // Computed
  get centerStyle() {
    return { backgroundImage: `url(${this.centerImage})` }
  }
}
