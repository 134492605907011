









































































































































































import { defineComponent, computed, ref, PropType } from '@vue/composition-api'
import {
  organizationFlags,
  OrganizationUserData
} from '@f/composables/fetchUserData'
import { getRootScope } from '@f/services/Angular'
import useRoutes from '@f/composables/useRoutes'
import useAuthentication from '@f/composables/useAuthentication'
import Avatar from '@f/components/Avatar.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Modal from '@f/components/Modal.vue'
import ShareNative from '@f/components/ShareNative.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import { formatNeutralized, formatNumber } from '@f/utils'
import { responsive } from '@f/composables/responsive'
import StatefullButton from '@f/components/StatefullButton.vue'
import TonneExplanation from '@f/components/Alerts/TonneExplanation.vue'

export default defineComponent({
  name: 'OrganizationInfo',
  components: {
    Avatar,
    DotLoader,
    Modal,
    ShareNative,
    Treecon,
    TonneExplanation,
    StatefullButton,
    ReadMoreModal: () =>
      import(
        /* webpackChunkName: "ReadMoreModal" */ '@f/components/Forest/ReadMoreModal.vue'
        ),
    CommitmentStar: () => import( /* webpackChunkName: "CommitmentStar.svg" */ '@f/images/commitment/CommitmentStar.svg'),
    SubscriberStar: () => import( /* webpackChunkName: "gift-colors.svg" */ '@f/images/b2bSubscribers/SubscriberStar.svg')

  },
  props: {
    user: {
      type: Object as PropType<OrganizationUserData>
    }
  },
  setup(props, { emit }) {
    const rootScope = getRootScope() || undefined
    const lang = rootScope?.ln || 'it'
    const { isLoggedAs, isLogged } = useAuthentication()
    const { breakpoints, windowWidth } = responsive()
    const { go } = useRoutes()
    const { hide_name, hide_emissions, hide_redeem, hide_countries, show_commitment, show_subscriber } =
      organizationFlags(computed(() => props.user))

    const showCo2Modal = ref(false)
    const showMoreModal = ref(false)

    const avatar = computed(() => props.user?.info.avatar_id || '')
    const rawEmissions = computed(() => props.user?.numbers?.sumKilos || 0)
    const co2Amount = computed(() => formatNeutralized(rawEmissions.value, lang) || 0)
    const co2 = computed(() => '-' + co2Amount.value)


    const communicationState = computed(() => {
      return {
        userType: 'organization',
        slug: props.user?.info.slug,
        tab: 'mediakit'
      }
    })

    const trees = computed(
      () =>
        !!props.user?.numbers &&
        formatNumber(props.user?.numbers?.numTrees || 0, rootScope?.ln || 'it')
    )
    const countriesCount = computed(
      () => props.user?.summary?.countries?.length || 0
    )

    const isDesktop = computed(
      () => windowWidth.value > breakpoints.value.medium
    )

    function openGiftComposer(ev: UIEvent): void {
      rootScope!.openGiftComposer(ev, null)
    }

    const onCommitmentStarClick = () => {
      emit('commitmentClick')
    }

    const onSubscriberStarClick = () => {
      emit('subscriberClick')
    }

    return {
      go,
      avatar,
      co2Amount,
      co2,
      isLoggedAs,
      isLogged,
      showCo2Modal,
      trees,
      isDesktop,
      showMoreModal,
      countriesCount,
      hide_countries,
      hide_name,
      hide_emissions,
      hide_redeem,
      show_commitment,
      show_subscriber,
      communicationState,
      openGiftComposer,
      rawEmissions,
      onCommitmentStarClick,
      onSubscriberStarClick
    }
  }
})
