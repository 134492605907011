import { UIRouterGlobals, StateService } from '@uirouter/angularjs'
import { ModalService, UserService } from '@f/@types/app/ngApp'
import { AppRootScope } from '@f/@types'

export function getRootScope(): AppRootScope | undefined {
  return angular.element(document.documentElement).scope()?.$root as AppRootScope
}

export function getAngularModule<T = any>(moduleName: string): T | undefined {
  return angular.element(document.documentElement).injector().get(moduleName) || undefined
}

export function getStateService() {
  return getAngularModule<StateService>('$state')
}

export function getEcommerceService() {
  return getAngularModule('Ecommerce')
}

export function getModalService() {
  return getAngularModule<ModalService>('Modal')
}

export function getUserService() {
  return getAngularModule<UserService>('User')
}

export function getUiRouterGlobals() {
  return getAngularModule<UIRouterGlobals>('$uiRouterGlobals')
}

export function getCartService() {
  return getAngularModule('Cart')
}

export function getEmissionService() {
  return getAngularModule('Emission')
}

export function onEventBroadcast<T extends any[]>(eventKey: string, listener: (...args: T) => void, once = true) {
  const rootRscope = getRootScope()
  if (!rootRscope) throw Error('Unable to retrieve ngApp $rootScope')
  const clearListener = rootRscope.$on<T>(eventKey, (_event, ...args) => {
    listener(...args)
    once && clearListener && clearListener()
  })
  return clearListener
}
