import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
.controller('previewCartController', ['$scope', '$rootScope', 'Routing', 'User', 'Cart', '$state', 'Utility', 'Modal', function ($scope, $rootScope, Routing, User, Cart, $state, Utility, Modal) {
  $scope.cart = {}
  $scope.voucherInfo = {}
  $scope.businessTrees = []

  // Modale info su regalo
  $scope.openGiftInfo = function () {
    Modal.open({templateUrl: 'modalGiftInfo.html'})
  }

  $scope.itemData = function (items) {
    $scope.cart.items = items
    if (!isUndefined($scope.cart.items)) {
      $scope.cart.items.forEach(function (item) {
        // TODO: analytics per pacchetti
        if (!isUndefined(item) && (item.type === 'tree')) {
          Utility.gaAdd('addProduct', {'id': item.specieId, 'name': item.specie, 'variant': (item.specialEdition) ? item.specialEdition + '' : 'normal'})
        }
      })
      $scope.showCart = true
      /*Utility.gaAction('checkout', {'step': 1})
      Utility.gaSend()*/
    }
  }

  // Aggiorna la variabile $scope.cart dove viene 'clonato' $rootScope.userdata.cart per evitare modifiche
  // dell'oggetto originale.
  var updateCartInfo = function (rootScopeCart) {
    //$rootScope.userdata.cart = rootScopeCart
    $scope.cart = { ...rootScopeCart }

    // Modifica la variabile voucherInfo
    // setVoucherInfo($scope.cart) // in cartController

    if (!$scope.$$phase) $scope.$apply()
  }

  /**
   * Watcher sul carrello utente
   */
  $scope.$watch(function () {
    return $rootScope.userdata.cart
  }, function (newVal) {
    if ($rootScope.userdata.cart) {
      updateCartInfo($rootScope.userdata.cart)

      // Parse business cart
      if (!isUndefined($rootScope.userdata.info)) {
        if ($rootScope.userdata.info.usertype === 'Business') {
          $scope.businessTrees = $scope.groupTrees($rootScope.userdata.cart.items)
        }
      }
    }
  })
  /**
   * Movimento/interazione bottone 'rimuovi albero dal carrello'
   */
  $scope.deleteButton = function (id) {
    $scope.deleteTree === id
      ? $scope.deleteTree = false
      : $scope.deleteTree = id
  }

  /**
   * Rimuovi albero dal carrello
   */
  $scope.deleteCartItem = function (itemID) {
    if (!$scope.cart.id || !itemID) {
      return console.error('Cart ID and Item ID are required field.')
    }

    Utility.disableButton()

    Cart.removeProduct($scope.cart.id, itemID)
    .then(function (res) {
        var cart = res.data || res
        Utility.enableButton()
        updateCartInfo(cart)
    })
    .catch(function (err) {
        Utility.enableButton()
        console.error('previewCartController -> $scope.deleteCartItem', err)
    })
  }

  /**
   * Raggruppa gli alberi per l'utente business
   */
  $scope.groupTrees = Cart.groupTreesByQuantity
}])
