import ajv from '@f/validation/ajv';
import * as UserSchemas from '@f/validation/schema/datalayer/user';
import {
    IAccountInfoSubmitted,
    IAuthentication,
    ICreateAccountStarted,
    IProfileInfoEdited,
    IRegistration,
    IProfileInfoCompleted,
    IProfileActivityInteraction
} from '@f/validation/types/dataLayer';

export const validateAuthentication = ajv.compile<IAuthentication>(UserSchemas.AuthenticationSchema)
export const validateCreateAccountStarted = ajv.compile<ICreateAccountStarted>(UserSchemas.CreateAccountStartedSchema)
export const validateAccountInfoSubmitted = ajv.compile<IAccountInfoSubmitted>(UserSchemas.AccountInfoSubmittedSchema)
export const validateRegitration = ajv.compile<IRegistration>(UserSchemas.RegistrationSchema)  
export const validateProfileInfoEdited = ajv.compile<IProfileInfoEdited>(UserSchemas.ProfileInfoEditedSchema)
export const validateProfileInfoCompleted = ajv.compile<IProfileInfoCompleted>(UserSchemas.ProfileInfoCompletedSchema);
export const validateProfileActivityInteraction = ajv.compile<IProfileActivityInteraction>(UserSchemas.ProfileActivityInteractionSchema)
