angular.module('projectService', [])
.factory('Project', ['$http', 'Utility', 'Routing', '$rootScope', function ($http, Utility, Routing, $rootScope) {
  var routing = Routing
  var routeJs = function (route, params) {
    var url = routing.route(route, params)

    // Funzione per pulire l'url dal' bug di fos_user che mantiene i parameters nelle varie chiamate
    if (url.indexOf('?') > -1) url = url.substring(0, url.indexOf('?'))

    return url
  }

  var projectFactory = {}

  projectFactory.projects = function (ln) {
    return $http.get(Utility.root() + 'json/country_' + ln + '.json')
    .then(function(response) {
      return response.data || response
    })
  }


  projectFactory.countryCoordinates = function () {
    return $http.get(Utility.root() + 'bundles/treedomnewfrontend/json/countries.json')
    .then(function(response) {
      return response.data || response
    })
  }

  projectFactory.trees = function (ln) {
    return $http.get(Utility.root() + 'json/trees_' + ln + '.json')
    .then(function(response) {
      return response.data || response
    })
  }

  projectFactory.selectTree = function (page, treeKey) {
    if (typeof treeKey !== 'undefined') {
      const treeSource = page === 'ecommerce.tree' ? $rootScope.newEcommerceTrees : $rootScope.trees
      const treeList = [ ...treeSource['top'], ...treeSource['middle'], ...treeSource['home'] ]
      $rootScope.activeTree = treeList.find(tree => tree.key === treeKey)
    }
  }

  projectFactory.getTreesNum = function (countrySlug) {
    const treesList = [ ...$rootScope.trees['top'], ...$rootScope.trees['middle'], ...$rootScope.trees['home'] ]
    return treesList.filter(tree => tree.countrySlug === countrySlug).length
  }

  projectFactory.users = function (id, limit, offset) {
    return $http.get(routeJs('treedom_get_project_users', { id: id, limit: limit, offset: offset }))
    .then(function(response) {
      return response.data || response
    })
  }

  // New project page API

  /**
   * Get project gallery by ID
   * @param {string|number} projectID
   * @param {number} offset
   */
  projectFactory.getGallery = function (projectID, offset) {
    return $http.get(routeJs('treedom_country_gallery', { countryId: projectID, offset: offset }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  }

  /**
   * Get blog articles by project ID
   * @param {string|number} projectID
   */
  projectFactory.getArticles = function (projectID) {
    return $http.get(routeJs('treedom_country_articles', { countryId: projectID }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  }

  /**
   * Get plantations model by project ID
   * @param {string|number} projectID
   */
  projectFactory.getPlantationsModel = function (projectID) {
    return $http.get(routeJs('treedom_country_plantations_model', { countryId: projectID }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  }

  /**
   * Get documents about a project by its ID
   * @param {string|number} projectID
   */
  projectFactory.getDocs = function (projectID) {
    return $http.get(routeJs('treedom_country_docs', { countryId: projectID }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  }

  /**
   * Get users that have almost one tree in this project by its ID
   * @param {string|number} projectID
   * @param {number} offset
   */
  projectFactory.getUsers = function (projectID, offset) {
    return $http.get(routeJs('treedom_country_users', { countryId: projectID, offset: offset }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  }

  /**
   * Get business users for this project by its ID
   * @param {string|number} projectID
   */
  projectFactory.getOrganizations = function (projectID) {
    return $http.get(routeJs('treedom_country_top_business', { countryId: projectID }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  }

  /**
   * Get last forests for this project by its ID
   * @param {string|number} projectID
   */
  projectFactory.getForests = function (projectID) {
    return $http.get(routeJs('treedom_country_last_forest', { countryId: projectID }))
    .then(function(response) {
      return response.data || response
    })
    .catch(function(err) {
      throw err.data || err
    })
  }

  return projectFactory
}])
