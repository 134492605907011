






















// TODO: refactoring DropdownSelect and FilteredSelect has a single component
import { defineComponent, ref, computed, watch, nextTick } from '@vue/composition-api'
import InputLikeComponentMixin from '@f/mixins/InputLikeComponent'
import BaseInput from '@f/components/Form/BaseInput.vue'
import Dropdown from '@f/components/Form/Elements/Dropdown.vue'
import { clampIndex } from '@f/utils/numbers'

export default defineComponent({
  name: 'FilteredSelect',
  mixins: [ InputLikeComponentMixin ],
  inheritAttrs: false,
  components: { BaseInput, Dropdown },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String
    },
    options: {
      type: Array,
      default: () => []
    },
    selectedLabel: {
      type: String,
      default: ''
    },
    // FIXME: find a way around passing bounded prop to Dropdown
    bounded: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const showOptions = ref(false)
    const optionIndex = ref(-1)

    const isOpen = computed({
      get: () => !!props.options.length && showOptions.value,
      set: (value) => {
        showOptions.value = value
      }
    })

    const activeIndex = computed({
      get: () => clampIndex(optionIndex.value, props.options.length),
      set: (indexValue) => {
        optionIndex.value = clampIndex(indexValue, props.options.length)
      }
    })

    const inputValue = computed(() => showOptions.value ? props.value : props.selectedLabel)

    watch(() => props.options, () => {
      activeIndex.value = props.options.length ? 0 : -1
    })

    function open(ev: Event) {
      isOpen.value = true
      context.emit('focus', ev)
    }

    function move(step?: number) {
      if (!isOpen.value) isOpen.value = true
      activeIndex.value += step || 0
    }

    function selectOption() {
      if (!!~activeIndex.value) {
        context.emit('select-option', activeIndex.value)
        close()
      }
    }

    function close() {
      (context.refs?.input as HTMLInputElement).blur()
      activeIndex.value = 0
      // TODO: find a better way to avoid dropdown close before click on item event
      setTimeout(() => {
        isOpen.value = false
        context.emit('input', '')
      }, 300)
    }

    return {
      activeIndex,
      close,
      isOpen,
      move,
      open,
      selectOption,
      inputValue,
    }
  }
})
