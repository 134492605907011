import { TransitionOptions } from '@uirouter/angularjs';
import { ref, onMounted, onUnmounted, computed } from '@vue/composition-api'
import { HrefOptions, RawParams, StateObject, StateOrName, StateService, UIRouterGlobals } from '@uirouter/core'
import { getRootScope, getStateService, getUiRouterGlobals } from '@f/services/Angular'
import { TrUser, TrEvent, TrTree } from '@f/@types/graphql'

export type ExactMatchOption = boolean | { state?: boolean, params?: boolean }

//FIXME: Improve (or rethink) the entire implementation as each component 
// that imports this function unnecessarily relaunches the same update functions via onMountd
export default function useRoutes() {
  let _stateService: StateService | void
  let _uiRouterGlobals: UIRouterGlobals | void

  const clearListener = ref<() => void>()
  const currentParams = ref<RawParams>()
  const currentState = ref<StateObject>()
  const previousParams = ref<RawParams>()
  const previousState = ref<StateObject>()

  const stateService = computed(() => _stateService || (_stateService = (getStateService() || undefined)))
  const uiRouterGlobals = computed(() => _uiRouterGlobals || (_uiRouterGlobals = (getUiRouterGlobals() || undefined)))

  function registerListener() {
    const rootScope = getRootScope()
    if (rootScope) {
      clearListener.value = rootScope.$on('$stateChangeSuccess', updateState)
    }
  }

  // HACK: removing absterhisc params to avoif conflict with binding params in vue component
  function sanitizeParams(params?: RawParams): RawParams | undefined {
    if(!params) return;
    const newParams: RawParams = {
      'anchor': params['#'],
      ...params
    }
    delete newParams['#']
    return newParams
  }

  function updateState() {
    const transition = uiRouterGlobals.value?.successfulTransitions.peekTail()
    currentState.value = transition?.$to()
    previousState.value = transition?.$from()
    currentParams.value = sanitizeParams(transition?.params('to'))
    previousParams.value = sanitizeParams(transition?.params('from'))
  }

  function isCurrentState(state: StateOrName, params?: RawParams, exact?: ExactMatchOption) {
    const exactState = typeof exact === 'object' ? !!exact.state : !!exact
    const exactParams = typeof exact === 'object' ? !!exact.params : !!exact
    const stateName = typeof state === 'string' ? state : state.name || ''
    const matchParams = typeof state === 'string' ? params : state.params
    return matchCurrentState(stateName, exactState)
      && (!matchParams || matchCurrentParams(matchParams, exactParams))
  }

  function matchCurrentState(state: string, exact: boolean) {
    return exact
      ? currentState.value?.name === state
      : !!currentState.value?.includes[state]
  }

  function matchCurrentParams(params: RawParams, exact: boolean) {
    return exact
      ? matchParams(currentParams.value, params)
      : matchParams(params, currentParams.value)
  }

  function matchParams(params?: RawParams, matchingParams?: RawParams) {
    return params
      && !Object.keys(params).some(paramKey => {
        return params
          && matchingParams
          && paramKey in params
          && params[paramKey] !== matchingParams[paramKey]
      })
  }

  function getParentRoute(ending: string, fallback = '.') {
    return Object.keys(uiRouterGlobals.value?.$current.includes || {})
      .find(routeName => {
        RegExp(`\.${ending}?`).test(routeName)
        && !!uiRouterGlobals.value?.$current.includes[routeName]
      }) || fallback
  }

  function href(name: string, params?: RawParams, options?: HrefOptions) {
    if (stateService.value) {
      return stateService.value.href(name, params, options)
    }
    return ''
  }

  function go(name: string, params?: RawParams, options?: TransitionOptions) {
    if (stateService.value) {
      return stateService.value.go(name, params, options)
    }
    return ''
  }

  function goBack() {
    window.history.back()
  }

  function parseUserState(
    user?: Pick<TrUser, 'userType'> | null,
    subState?: string | (string | void)[]
  ) {
    return ([user?.userType === 'Business' ? 'organization' : 'user'] as (string | void)[])
      .concat(subState)
      .filter(part => !!part).join('.')
  }

  function parseProfileUrl(
    user: Pick<TrUser, 'slug' | 'userType'>,
    subState?: string | (string | void)[],
    params?: RawParams,
    options?: HrefOptions
  ) {
    return href(parseUserState(user, subState), { ...params, slug: user.slug }, options)
  }

  function parseForestUrl(
    forest: Pick<TrEvent, 'slug' | 'user'>,
    subState?: string,
    params?: RawParams,
    options?: HrefOptions
  ) {
    return forest.user
      && parseProfileUrl(
        forest.user,
        ['event', subState],
        { ...params, eventslug: forest.slug },
        options
      )
      || ''
  }

  function parseTreeUrl(
    tree: Pick<TrTree, 'treeId' | 'user'>,
    subState?: string,
    params: RawParams = {},
    options?: HrefOptions
  ) {
    if (!tree.user) return ''
    return parseProfileUrl(
      tree.user,
      ['trees.item', subState].filter(Boolean),
      { ...params, item: tree.treeId },
      options
    )
  }

  onMounted(() => {
    updateState()
    registerListener()
  })

  onUnmounted(() => {
    clearListener.value && clearListener.value()
  })

  return {
    currentState,
    currentParams,
    parseForestUrl,
    getParentRoute,
    go,
    goBack,
    href,
    isCurrentState,
    previousState,
    previousParams,
    parseTreeUrl,
    parseProfileUrl,
    parseUserState
  }
}
