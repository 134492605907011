












import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { getRootScope, getStateService } from '@f/services/Angular'
import DotLoader from '@f/components/DotLoader.vue'
export default defineComponent({
  name: 'OrganizationHeader',
  components: { DotLoader },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  emits: ['share', 'settings', 'support-impacts', 'subscription-more-info', 'commitment-more-info', 'impacts-more-info', 'description'],
  setup(props, { emit }) {
    const root = ref<HTMLDivElement | null>(null)
    const iframe = ref<HTMLIFrameElement | null>(null)
    const locale = computed(() => (getRootScope() || null)?.ln || 'en')
    const iframeURL = computed(() => `/${locale.value}/bff/organization-profile-header/${props.slug}`)
    const loading = ref(true)
    const loadComplete = () => {
      setInterval(() => {
        if (iframe.value) {
          root.value!.style.height = Math.max(((iframe.value.contentWindow?.document.body.scrollHeight ?? 350)), 300) + 'px'
        }
        loading.value = false
      }, 300)
    }
    onMounted(() => {
      getRootScope()?.onMessage('share', () => {
        emit('share')
      })
      getRootScope()?.onMessage('settings', () => {
        emit('settings')
      })
      getRootScope()?.onMessage('support-impacts', () => {
        emit('support-impacts')
      })
      getRootScope()?.onMessage('subscription-more-info', () => {
        emit('subscription-more-info')
      })
      getRootScope()?.onMessage('commitment-more-info', () => {
        emit('commitment-more-info')
      })
      getRootScope()?.onMessage('impacts-more-info', () => {
        emit('impacts-more-info')
      })
      getRootScope()?.onMessage('description', () => {
        emit('description')
      })
    })
    return {
      root,
      iframe,
      iframeURL,
      loading,
      loadComplete,
    }
  }
})
