import { Vue, Component, Prop } from 'vue-property-decorator'
import orangeTranslations from '@f/_legacy/js-lib/orangeTranslations'
  
type OrangeLanguages = 'en' | 'fr' | 'nl'
type OrangeTranslationKey = keyof typeof orangeTranslations['nl']

@Component({
  name: 'ShowCustomTranslations'
})
export default class ShowCustomTranslations extends Vue {
  /*--- PROPS ---------*/
  @Prop({ type: String, default: 'nl' }) readonly language!: string

  /*--- COMPUTED ------*/
  get customLanguage() {
    return this.language
  }

  /*--- DATA ----------*/
  translations = orangeTranslations

  /*--- METHODS -------*/
  $tCustom(key: string, ...args: any) {
    const translation = this.translations[this.customLanguage as OrangeLanguages][key as OrangeTranslationKey]
    if (typeof translation === 'function') return translation(args)
    return translation || ''
  }
}
