const required = (val) =>  !!(val || val === 0)

const requiredWithout = (val, data, other) =>  required(val) || (data[other] && required(data[other]))

const alphanumeric = (val) =>  /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(val)

// HOTFIX check how should work
const atLeastOneDigit = (val) =>  true

const email = (val) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)

const validate = function(data, rules) {
  const validator = this
  if (data.constructor !== Object) throw new ValidationError([], 'Unable to validate data.')
  const errors = Object.keys(rules).filter(fieldKey => {
    return !rules[fieldKey].split('|').every(rawRule => validator.validateRule(data[fieldKey], rawRule, data))
  })
  if (errors && errors.length) throw new ValidationError(errors)
}

const validateRule = function(prop, rawRule, data) {
  const [func, rawArgs] = rawRule.split(':')
  const args = (rawArgs) ? rawArgs.split(',') : []
  return this[func](prop, data, ...args)
}

const isValid = function(data, rules) {
  return !(this.validate(data, rules) || []).length
}

export default {
  validate,
  isValid,
  validateRule,
  required,
  requiredWithout,
  email,
  alphanumeric,
  atLeastOneDigit
}

export class ValidationError extends Error {
  constructor(fields = [], message = 'Error validating data.') {
    super(message)
    this.name = 'ValidationError'
    this.fields = fields
  }
}
