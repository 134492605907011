import { Vue, Component, Prop } from 'vue-property-decorator'
import { GlideOptions } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  name: 'ShowSlider'
})
export default class ShowSlider extends Vue {
  /*--- PROPS ---------*/
  @Prop({ type: Object, default: () => {} }) readonly options!: GlideOptions
  @Prop({ type: [Boolean, String], default: false }) readonly arrows!: boolean | 'light' | 'dark'
  @Prop({ type: [Boolean, String], default: false }) readonly bullets!: boolean | 'light' | 'dark'

  /*--- DATA ----------*/
  defaultOptions: GlideOptions = {
    type: 'slider',
  }
}
