import * as clipboard from 'clipboard-polyfill'
import moment from 'moment-timezone'
import defer from 'lodash/defer'
import delay from 'lodash/delay'
import gql from 'graphql-tag'
import { DataEventTreeDiaryInteraction, DataEventToggleFollow } from '@f/services/TagManager/events';

var he = require("he");
angular.module('ngApp')
// Controller usato per l'albero singolo.
.controller('treeController', ['$scope', '$rootScope', '$stateParams', '$timeout', 'Tree', '$state', 'Modal', '$location', 'apollo', function ($scope, $rootScope, $stateParams, $timeout, Tree, $state, Modal, $location, apollo) {
  var $ = window.$

  $scope.hasPowers = false
  $scope.giftPostUrl = '' // TODO: assigned by calling apollo for GiftPost on gifted trees

  $scope.actualUserSlug = $stateParams.slug
  $scope.actualTreeId = parseInt($stateParams.item)
  $rootScope.userTreesId = false
  $scope.prevSingleTree = false
  $scope.nextSingleTree = false
  $scope.editMessage = {
    id: null,
    createdOn: new Date().getTime(),
    message: '',
    private: false
  }
  $scope.savingMessage = false
  $scope.lastMessage = false
  $scope.lastMessageIsMine = false
  $scope.enableEditMessage = false
  $scope.mapCollections = []
  $scope.messageMobileInput = false
  $scope.messageMobileModel = { text: '' }
  $scope.youLikeThisTree = false
  $scope.treeName = ''
  $scope.editTreeName = false
  $scope.userMessage = {}
  $scope.openReplicasBox = {}
  $scope.openCommentsBox = {}
  $scope.singleTreeMap = false
  $scope.allMessagesArePrivatesAndNotReadable = false
  $scope.activeFilter = 'all'
  $scope.now = new Date().getTime()
  $scope.copiedDiaryTabUrl = false
  $scope.totalFilteredTrees  = 0;
  $scope.currentItemTree = 0
  $scope.currentSection = 'none'
  $scope.likesUpdating = false

    $scope.copyToClipboard = function (textToCopy) {
    clipboard.writeText(textToCopy)
    .then(function () {
      $scope.copiedDiaryTabUrl = true
      if (!$scope.$$phase) $scope.$apply()

      $timeout(function () {
        $scope.copiedDiaryTabUrl = false
        if (!$scope.$$phase) $scope.$apply()
      }, 1500)
    })
  }




  /**
   * Trovo le informazioni dell'albero tramite il suo id.
   * @param {string|number} treeId - ID albero.
   */
  var getTreeInfo = function (treeId) {
      Tree.getTreeData(treeId)
      .then(function(_treedata){
          $scope.currentItemTree = 0;
          $rootScope.singleTree = _treedata;
          $scope.actualTreeId = $rootScope.singleTree.id

          if($rootScope.singleTree.user.slug === $rootScope.user && $rootScope.singleTree.actions && $rootScope.singleTree.actions.isVibrating){
              Tree.openTree($rootScope.singleTree.treeId)
          }

          if (_treedata.specie.powers) {
            $scope.hasPowers = Object.values(_treedata.specie.powers)
                                .reduce(function(sum, power) {
                                  return sum + (power || 0)
                                }, 0) > 0
          }


          // Setto le variabili per tutte le sezioni del sito (messaggi, nome albero, etc..)
          var type = $scope.getCurrentSection($scope.currentState, $rootScope.singleTree);
          $scope.currentSection = type;
          if(type != "Private"){
              $scope.totalFilteredTrees = 1;
          }
          if (!$scope.selectedEvent && type === 'Event') $scope.selectedEvent = $rootScope.singleTree.event
          var id = type === 'Event'
              ? $scope.selectedEvent
                  ? $scope.selectedEvent.id
                  : $rootScope.singleTree.event.id
              : type === 'Business'
                  ? $rootScope.singleTree.initialUser.id
                  : $rootScope.singleTree.user.id

          //getCarouselTrees(type, id)
          carouselTrees(type)
          $rootScope.$broadcast('TreeIsFound')

          setLikesVariables($rootScope.singleTree)
          getTreeName($rootScope.singleTree)
          setMessageVariables($rootScope.singleTree)
          getGiftPost($rootScope.singleTree)

          // Trovo i dati per la scheda mappa.
          $scope.singleTreeMap = getMapInfo($rootScope.singleTree)

          // NOTE apertura modale video aggiunta per la campagna di Michielin
          if ($rootScope.singleTree.actions && $rootScope.singleTree.actions.isVibrating && $rootScope.singleTree.limited && $rootScope.singleTree.limited.videoUrl && $rootScope.singleTree.limited.videoUrl[$rootScope.ln]) {
              $rootScope.videoUrlToShow = $rootScope.singleTree.limited.videoUrl[$rootScope.ln]
              Modal.open({ templateUrl: 'modalVideo.html', backgroundColor: 'transparent' })
          }

          if($rootScope.singleTree.status >= 3){
            Tree.getTreesNeighborsData($rootScope.singleTree.treeId, null)
              .then(function(_results){
                if(_results)
                  $scope.treeNeighbors=_results.edges;
              });
          }
      })
  }

  /**
   * Trova la sezione in cui mi trovo attualmente.
   * @param {string} currentState - Stato attuale dell'applicazione.
   * @param {Object} tree - Oggetto albero della pagina.
   * @return {string} Una della seguenti stringhe: ['Business', 'Private', 'Event']
   */
  $scope.getCurrentSection = function (currentState, tree) {
    if (!currentState || !tree) return 'Error'
    if (currentState.includes('event')) return 'Event'
    return $stateParams.slug === tree.initialUser.slug ? tree.initialUser.userType : tree.user.userType
  }

  /**
   * Torn alla pagina dell'utente o dell'evento.
   * @param {string} - Stato attuale dell'applicazione.
   */
  $scope.goBackToPage = function (currentState) {
    currentState = currentState || $scope.currentState
    var stateToGo = currentState.includes('organization') ? 'organization' : 'user'
    var params = { slug: $stateParams.slug }

    if (currentState.includes('event')) {
      stateToGo += '.event'
    }

    if(currentState.includes('trees')) {
      stateToGo += '.trees'
    }

    $state.go(stateToGo, params)
  }

  /**
   * Cambia la tab
   * @param {string} type - Tipo di tab ['', 'media', 'messages', 'map']
   * @param {string} treeId - ID dell'albero.
   */
  $scope.changeTab = function (type, treeId, scrollDown) {
    var currentSection = $scope.getCurrentSection($scope.currentState, $rootScope.singleTree)

    var state = currentSection === 'Event'
      ? 'user.event.' + 'trees.item' + (type ? '.' + type : '')
      : 'user.' + 'trees.item' + (type ? '.' + type : '')

    if (currentSection === 'Business' || $state.current.name.includes('organization')) {
      state = state.replace('user', 'organization')
    }

    var params = {slug: $stateParams.slug, item: treeId, eventslug: $stateParams.eventslug}

    if (scrollDown) {
      var treeStreamContainer = document.getElementById('treeStreamContainer')
      var toScroll = $(treeStreamContainer).offset().top - 200

      $timeout(function () {
        $('body').animate({ scrollTop: toScroll }, 500)
        $state.go(state, params)
      }, 1000)

      return
    }

    $state.go(state, params)

    if (type === 'map') startMapTab()
  }

  /**
   * Trova la tab attiva.
   * @param {string} currentState - Stato attuale dell'applicazione.
   */
  $scope.getActiveTab = function (currentState) {
    if (currentState.includes('media')) return 'media'
    if (currentState.includes('messages')) return 'messages'
    if (currentState.includes('map')) return 'map'
    if (currentState.includes('identity')) return 'identity'
    if (currentState.includes('co2')) return 'co2'
    return ''
  }

  /**
   * Ritorna il timestamp di una data.
   * @param {string} date - Date string
   */
  $scope.getTimestamp = function (date) {
    return moment(date).unix() * 1000
  }

  // SLIDER ALBERI

  /**
   * Get trees in a carousel, they can be mine, liked or gifted trees.
   * User will navigate through them.
   * @param {string} type - 'Private', 'Business' or 'Event'.
   * @param {string} id - User ID
   */
  /*var getCarouselTrees = function (type, id) {
    // Se non vengono passati gli argomenti non eseguo la chiamata.
    if (!type || !id) return

    // Se esiste il filtro 'gifted' o 'loved' e l'albero è di un privato,
    // chiama gli alberi filtrati
    var activeFilter = $location.search().filter || $stateParams.filter
    if ($rootScope.singleTree.user.userType === 'Private' && (activeFilter === 'liked' || activeFilter === 'gifted')) {
      getFilteredUserTrees($rootScope.showUser.info.id, activeFilter, type, id)
      $scope.activeFilter = activeFilter
      return
    }

    getUserTrees(type, id)
  }*/

  var carouselTrees = function(type){
      if ($rootScope.singleTree.user.userType === 'Private' && type != "Event"){
          var activeFilter = $location.search().filter || $stateParams.filter;
          var gifted = activeFilter === 'gifted';
          var liked = activeFilter === 'liked'
          var mine = !gifted && !liked;
          $scope.activeFilter = activeFilter;
          if(!$rootScope.showUser) return ;

          Tree.getAdjacentUserTreeData($rootScope.singleTree.treeId, $rootScope.showUser.info.id, mine, gifted, liked)
            .then(function(_treesdata){
                $scope.currentItemTree = _treesdata.currentItem;
                $scope.totalFilteredTrees = _treesdata.total;
                $scope.prevSingleTree = _treesdata.prev;
                $scope.nextSingleTree = _treesdata.next
            });
      }
      else if(type == "Event"){
          Tree.getAdjacentEventTreeData($rootScope.singleTree.treeId, $rootScope.singleTree.event.id)
              .then(function(_treesdata){
                  $scope.currentItemTree = _treesdata.currentItem;
                  $scope.totalFilteredTrees = _treesdata.total;
                  $scope.prevSingleTree = _treesdata.prev;
                  $scope.nextSingleTree = _treesdata.next
              });
      }
  }

  /**
   * Passa da un albero all'altro.
   */
  $scope.changeSingleTree = function (treeId) {
    $state.go($scope.currentState, {item: treeId})
  }

  // MESSAGGI

  /**
   * Setta le variabili iniziale che servono a controllare la scheda dei messaggi.
   * @param {Object} tree - Oggetto albero.
   */
  var setMessageVariables = function (tree) {

    $scope.lastMessage = (tree.messages.length) ? tree.messages.slice(-1).pop() : false

    if ($rootScope.userdata && $rootScope.userdata.info) {
      $scope.lastMessageIsMine = ($scope.lastMessage) ? $scope.lastMessage.user.id == $rootScope.userdata.info.id : false
      $scope.enableEditMessage = !$scope.lastMessageIsMine
      return
    }
    $scope.lastMessageIsMine = false
    $scope.enableEditMessage = true
  }

  /**
   * Check if string contain only blank spaces
   * @param {string} string
   * @return {boolean}
   */
  var containOnlyBlankSpaces = function (string) {
    if (!string) return true

    var splittedString = string.split('')
    var lettersButBlankSpaces = splittedString.filter(function (letter) { return letter !== ' ' })
    if (!lettersButBlankSpaces.length) return true

    return false
  }

  /**
   * Remove special characters like whatsapp emoji
   * @param {string} string
   */
  var removeSpecialCharacters = function (string) {
    return (string) ? he.encode(string) : string;
    //return string
      //.replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, '')
  }

  /**
   * Funzione che controlla se tutti i messaggi sono leggibili all'utente che
   ^ visualizza la pagina , se si, setta la variabile
   * '$scope.allMessagesArePrivatesAndNotReadable' a true
   */
  var checkIfAllMessagesAreReadable = function (postsList) {
    var messages = postsList.filter(function (post) { return post.title === 'message' })

    return !!messages.reduce(function (bool, message) {
      return message.isReadable ? bool * 1 : bool * 0
    }, 1)
  }

  /**
   * Salva il nuovo messaggio.
   * @param {string} data.message - Testo del messaggio.
   * @param {boolean} data.private - Messaggio privato.
   */
  $scope.saveMessage = function (data) {
    var encodedMessage = removeSpecialCharacters(data.message)
    if (containOnlyBlankSpaces(encodedMessage)) return

    const postData = (data.id) ? {
      id: data.id,
      message: encodedMessage,
      isPrivate: data.private
    } : {
      message: encodedMessage,
      isPrivate: data.private,
      treeId: $rootScope.singleTree.treeId,
    }
    $scope.savingMessage = true
    Tree.setMessage(postData)
    .then( response => {
      /*response = response.data || response
      Tree.getTreeMessages($stateParams.item)
      .then(function (data) {
        console.log(data)
        $scope.savingMessage = false
        $rootScope.singleTree.messages = data.messages
        setMessageVariables($rootScope.singleTree)
        $rootScope.$broadcast('treeWallUpdated', $stateParams.item)
      }).catch(function() {
        $scope.savingMessage = false
      })*/
      DataEventTreeDiaryInteraction('comment', 'save', null)
      $scope.savingMessage = false
      $rootScope.singleTree.messages = response.data.Tree_Message_add || response.data.Tree_Message_update;
      setMessageVariables($rootScope.singleTree);
      $rootScope.$broadcast('treeWallUpdated', $stateParams.item)
    })
    .catch(() =>{
      $scope.savingMessage = false
    })
  }

  /**
   * Apre o nasconde il box di inserimento messaggio.
   */
  $scope.toggleEditMessage = function () {
    if ($scope.enableEditMessage) {
      $scope.enableEditMessage = false
      $scope.editMessage.message = ''
      $scope.editMessage.private = false
    } else {
      $scope.enableEditMessage = true
      var previousMessage = $rootScope.singleTree.messages.find(function(message) {
        return message.user.slug === $rootScope.user
      }) || { message: '', isPrivate: false }
      $scope.editMessage.message = he.decode(previousMessage.message)
      $scope.editMessage.private = previousMessage.isPrivate
      $scope.editMessage.id = previousMessage ? previousMessage.id : null;
    }
  }

  // MESSAGGIO TIP

  /**
   * Listener per modificare o cancellare le modifiche del tip messaggio.
   * @param {Object} e - window.event
   */
  var CancelOrConfirmListener = function (e) {
    if (e.keyCode === 13) {
      var message = $scope.messageMobileModel.text
      $scope.editTipMessageMobile(message)
    }

    if (e.keyCode === 27) {
      $scope.cancelEditTipMobile()
    }
  }

  /**
   * Resetta le variabili che gestiscono il tip del messaggio sull'albero.
   */
  var resetTipVariables = function () {
    $scope.messageMobileInput = false
    $scope.messageMobileModel.text = ''
  }

  /**
   * Animazione apertura/chiusura tip messaggio.
   * @param {string} type - ['close', 'open']
   */
  $scope.toggleMessageTip = function (type) {
    var treeMessagePreview = document.getElementById('treeMessagePreview')
    var treeMessagePreviewIcon = document.getElementById('treeMessagePreviewIcon')
    var treeMessagePreviewText = document.getElementById('treeMessagePreviewText')

    if (type === 'close') {
      treeMessagePreviewText.style.opacity = 0
      defer(function () {
        treeMessagePreview.classList.remove('isOpen')
        delay(function () {
          treeMessagePreviewIcon.style.opacity = 1
          resetTipVariables()
        }, 500)
      })
    } else {
      treeMessagePreviewIcon.style.opacity = 0
      defer(function () {
        treeMessagePreview.classList.add('isOpen')
        delay(function () { treeMessagePreviewText.style.opacity = 1 }, 500)
      })
    }
  }

  /**
   * Visualizza la testarea per la modifica del messaggio
   * con focus attivo.
   */
  $scope.openMobileMessageTextarea = function () {
    $scope.messageMobileInput = true
    $scope.messageMobileModel.text = $scope.lastMessage
      ? he.decode($scope.lastMessage.message)
      : ''

    defer(function () {
      var messageMobileTextarea = document.getElementById('messageMobileTextarea')
      messageMobileTextarea.focus()
      messageMobileTextarea.addEventListener('keydown', CancelOrConfirmListener)
    })
  }

  /**
   * Annulla modifica: resetta le variabili del tip.
   */
  $scope.cancelEditTipMobile = function () {
    // Resetto le variabili.
    $scope.messageMobileModel.text = $rootScope.singleTree.messages[0] ? $rootScope.singleTree.messages[0].message : ''
    resetTipVariables()

    // Rimuovo event Listener.
    var messageMobileTextarea = document.getElementById('messageMobileTextarea')
    messageMobileTextarea.removeEventListener('keydown', CancelOrConfirmListener)
  }

  /**
   * Modifica rapida del messaggio sull'albero tramite il tip.
   * @param {string} newMessage - Nuovo messaggio albero.
   * @param {boolean} isPrivate - Di default prende il valore esistente.
   */
  $scope.editTipMessageMobile = function (message) {
    const isPrivate = ($scope.lastMessage) ? $scope.lastMessage.isPrivate : false
    const messageData = {
      message,
      private: isPrivate
    }
    if ($scope.lastMessage && $scope.lastMessage.id) messageData.id = $scope.lastMessage.id
    // Salvo il messaggio.
    $scope.saveMessage(messageData)

    // Resetto le variabili.
    resetTipVariables()

    // Rimuovo eventListener.
    var messageMobileTextarea = document.getElementById('messageMobileTextarea')
    messageMobileTextarea.removeEventListener('keydown', CancelOrConfirmListener)
  }

  // LIKE

  /**
   * Controllo se l'utente che visualizza la pagina ha messo like all'albero.
   * @param {Object} tree - Oggetto albero.
   */
  var setLikesVariables = function (tree) {
    if (!tree) return

    var myLike = tree.likes.filter(function (like) {
      return like.slug === $rootScope.user
    })

    $scope.youLikeThisTree = myLike.length > 0
  }

  /**
   * Aggiunge o rimuove il like all'albero visualizzato.
   * @param {string|number} treeId - ID albero.
   */
  $scope.toggleLike = function (treeId) {
    if (!$rootScope.user) {
      Modal.open({templateUrl: 'login.html', easyClose: false, fitcontent: true})
      return
    }

    // Instant feedback
    $scope.youLikeThisTree = !$scope.youLikeThisTree
    $scope.likesUpdating = true

    // Registro il like.
    Tree.toggleLike(treeId)
    .then(function(_likes) {
      $rootScope.singleTree.likes = _likes;
      setLikesVariables($rootScope.singleTree)

      DataEventToggleFollow($scope.youLikeThisTree, 'tree-page','tree');

      $rootScope.$broadcast('treeWallUpdated', $stateParams.item);
    })
    .catch(() => {
      // Feedback reverted
      $scope.youLikeThisTree = !$scope.youLikeThisTree
    })
    .finally(() => {
      $scope.likesUpdating = false
    })
  }

  // GALLERY MEDIA
  /**
   * Apre una gallery
   */
  $scope.openThisImage = function (index, section) {
    var data = {}

    if (section === 'diary') data = { images: $scope.treeWall, activeIndex: index }
    if (section === 'media') data = { images: $scope.treeMediaItems, activeIndex: index }

    $rootScope.modalImageInfo = data;
    Modal.open({ templateUrl: 'modalImage.html', backgroundColor: 'transparent', small: false, closeOnContent: true })
  }

  // NOME ALBERO PERSONALIZZATO

  /**
   * Setta nella variabile $scope.treeName il nome attuale dell'albero
   * @param {Object} tree - Oggetto albero.
   */
  var getTreeName = function (tree) {
    $scope.treeName = ($scope.treeName) ? he.decode($rootScope.singleTree.name) : ''
  }

  /**
   * Funzione che dati due nodi del dom, ritorna true se si tratta di un childNode
   * @param {Object} parent - Parent DOM Node
   * @param {Object} child - Child DOM Node
   */
  var isDescendant = function (parent, child) {
    var node = child.parentNode

    while (node !== null) {
      if (node === parent) {
        return true
      }
      node = node.parentNode
    }
    return false
  }

  /**
   * Event listener per chiudere l'input di modifica nome albero se clicco in
   * altri punti della pagina.
   * @param {Object} e - window.event
   */
  var ClickCloseEditTreeNameListener = function (e) {
    var treeInputNameBox = document.getElementById('treeInputNameBox')
    if (!isDescendant(treeInputNameBox, e.target)) {
      $scope.cancelEditTreeName()
    }
  }

  const getGiftPost = function(tree) {
    $scope.giftPostUrl = ''
    if ((tree.user && tree.user.id) !== (tree.initialUser && tree.initialUser.id)) {
      apollo.query({
        query: gql`
          query ($treeId: String!, $itemDiaryType: TreeDiaryItemType!){
            giftPost: Tree_Diary_Item(treeId: $treeId, itemDiaryType: $itemDiaryType) {
              ... on TreeDiaryGift {
                id,
                message {
                  it
                  en
                  de
                  fr
                  es
                }
              }
            }
          }
        `,
        variables: {
          treeId: tree.treeId,
          itemDiaryType: 'TREEGIFT'
        }
      }).then(({ data }) => {
        if (
          data
          && data.giftPost
          && data.giftPost.message
          && data.giftPost.message[$rootScope.ln]
        ) {
          $scope.giftPostUrl = $state.href('.', { share: data.giftPost.id })
          if (!$scope.$$phase) $scope.$apply()
        }
      })
    }
  }

  /**
   * Apre il form della modifica del nome albero.
   */
  $scope.openEditTreeName = function () {
    $scope.editTreeName = true
    getTreeName($rootScope.singleTree)

    // Aggiungo event listener
    window.addEventListener('click', ClickCloseEditTreeNameListener)

    // Seleziono l'input.
    $timeout(function () {
      document.getElementById('treeInputName').select()
    }, 1)
  }

  /**
   * Chiude il form della modifica del nome albero.
   */
  $scope.cancelEditTreeName = function () {
    // Resetto le variabili.
    $scope.editTreeName = false
    getTreeName($rootScope.singleTree)
    if (!$scope.$$phase) $scope.$apply()

    // Rimuovo event listener.
    window.removeEventListener('click', ClickCloseEditTreeNameListener)
  }

  /**
   * Salva il nuovo nome dell'albero.
   * @param {string} newName - Nuovo nome dell'albero.
   */
  $scope.saveEditTreeName = function (_name) {
    if (!_name) return

    // Rimuovo listener
    window.removeEventListener('click', ClickCloseEditTreeNameListener)

    // Salvo il nuovo nome
    const name = he.encode(_name)
    Tree.setName($rootScope.singleTree.treeId,  name)
    .then(function () {
      $rootScope.singleTree.name = name
      $scope.treeName = name
      $scope.editTreeName = false

      // Avviso il wall che ho aggiunto/modificato il nome dell'albero.
      $rootScope.$broadcast('treeWallUpdated', $rootScope.singleTree.id)
    })
  }

  // REGALA ALBERO

  /**
   * Vai alla pagina dell'evento.
   * @param {string} currentSection - Uno tra 'Event', 'Private', 'Business'
   */
  $scope.goToGiftPage = function (currentSection) {
    var userType = $rootScope.singleTree.user.userType === 'Business' ? 'organization' : 'user'
    var stateToGo = currentSection === 'Event'
      ? userType + '.event.trees.item.gift'
      : userType + '.trees.item.gift'

    $state.go(stateToGo, $stateParams)
  }

  $scope.giftCta = function(treeId, gift, giftInProgress ={}) {
    if (gift) {
      Modal.open({
        templateUrl: 'v-modal-gift_recap.html',
        id: `treecode-redeem-${gift.uuid}`,
        data: { gift: gift, treeId: treeId, giftInProgress },
      })
    } else if (treeId) {
      $rootScope.openGiftComposer(null, treeId, 'treedom-tree-page')
    }
  }

  // MAPPA

  /**
   * Trovo le informazioni della mappa dell'albero.
   * @param {Object} singleTree - Oggetto albero.
   */
  var getMapInfo = function (singleTree) {
    var lat
    var lng

    if (singleTree.status >= 3) {
      lat = Number(singleTree.lat)
      lng = Number(singleTree.lng)
    } else {
      lat = Number(singleTree.specie.originator.nurseryLat)
      lng = Number(singleTree.specie.originator.nurseryLng)
    }

    return {
      markers: {
        treeMarker: {
          lat: lat,
          lng: lng,
          layer: 'mapTrees',
          eventId: singleTree.event && singleTree.event.id || false,
          id: singleTree.id,
          type: 'p',
          specie: singleTree.specie.name
        }
      },
      layers: {
        baselayers: {
          mapbox_terrain: {
            name: 'Mapbox Terrain',
            url: 'https://api.tiles.mapbox.com/v4/{mapid}/{z}/{x}/{y}.png?access_token={apikey}',
            type: 'xyz',
            layerOptions: {
              apikey: 'pk.eyJ1IjoidG9tbWFzb3NwZXJvbmkiLCJhIjoiY2tnOTE3eW12MDJqazMybXNzOWV1YjloOSJ9.wtGsuDU7XIKjcv2cq8CiXw',
              mapid: 'tommasosperoni.kgbia49c',
              minZoom: 3
            }
          }
        },
        overlays: {
          mapTrees: {
            name: 'Map Trees',
            type: 'markercluster',
            visible: true
          }
        }
      },
      center: {
        lat: lat,
        lng: lng,
        zoom: 7
      },
      maxbounds: {
        southWest: {
          lat: -90,
          lng: -180
        },
        northEast: {
          lat: 90,
          lng: 180
        }
      },
      paths: {},
      mapOptions: {}
    }
  }

  /**
   * Start tree map on tab 'map'
   * @param {string|number} treeID - Tree id
   */
  var startMapTab = function () {
    $scope.$watch('singleTree', function (singleTree) {
      if (!singleTree) return

      if ($state.current.name.includes('.map')) {
        $timeout(function () {

          var elemID = 'mapDiary'
          var elem = document.getElementById(elemID)

          if (elem) {
            $rootScope.$broadcast('LoadSingleTreeMap', {
              treeId: singleTree.id,
              containerId: elemID,
              nursery: singleTree.status <= 2,
              fakeMap: $rootScope.oldBrowser || false
            })
          }
        }, 1000)
      }
    }, true)
  }

  // BOX UTENTE
  var MoveUserBox = function () {
    var ownerWidget = document.getElementById('ownerWidget')

    if (ownerWidget) {
      var treeWeather = document.getElementById('treeWeather')
      var treeWeatherHeight = treeWeather.getBoundingClientRect().height
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop

      treeWeatherHeight <= scrollTop
        ? ownerWidget.classList.add('isFixed')
        : ownerWidget.classList.remove('isFixed')
    }
  }

  // START CONTROLLER

  getTreeInfo($stateParams.item)

  /**
   * Se l'utente si logga richiedo le informazioni dell'albero.
   */
  $scope.$on('UserLoggedBroadcast', function (_, data) {
    if (data) getTreeInfo($stateParams.item)
  })

  /**
   * Se l'utente impacchetta un albero
   */
  $scope.$on('tree.giftUpdated', function (_, trees, gift) {
    const ids = typeof trees === 'string' ? [trees] : trees
    if (ids.includes($rootScope.singleTree.treeId)) {
      $rootScope.singleTree.gift = gift
      if (!$scope.$$phase) $scope.$apply()
    }
  })

  /**
   * Watcher per evento
   */
  $scope.$watch('showUser', function (showUser) {
    if (!showUser || !showUser.events) return
    var filteredEvents = showUser.events.filter(function (event) {
      return event.slug === $stateParams.eventslug
    })

    $scope.selectedEvent = filteredEvents.length ? filteredEvents[0] : null
    if ($scope.selectedEvent) {
      $scope.selectedEvent.userInfo = showUser.info ? showUser.info : null
    }
  })

  /**
   * Watcher sul cambio di stato.
   */
  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    $scope.currentState = toState.name
  })

  /**
   * Se entro con un link diretto alla pagina del regalo e non ho i permessi
   * ti reindirizzo alla pagina parente
   */
  if ($state.current.name.includes('.gift')) {
    var parentUrl = $state.current.name.replace('.gift', '')

    $scope.$watch('[singleTree, user]', function (updatedVariables) {
      var singleTree = updatedVariables[0]
      var user = updatedVariables[1]

      if (!singleTree || !user) return
      if (singleTree.user.slug !== user && singleTree.user.slug !== singleTree.initialuser.slug) {
        $state.go(parentUrl, $stateParams)
      }
    }, true)
  }

  /**
   * Repositioning tree box and add listener
   */
  MoveUserBox()
  startMapTab()
  window.addEventListener('scroll', MoveUserBox)


  // funzione che calcola la curva di regressione da applicare alla alle emissioni dell'albero nei 10 anni di vita
  $scope.calcCO = function (_year, _tanksize) {
    _tanksize = parseInt(_tanksize)
    _year = parseInt(_year)
    return ((-Math.abs(0.1933) * Math.pow(_year, 3) + 2.6171 * Math.pow(_year, 2) + 1.0983 * _year + -Math.abs(2.7333)) / 80 * _tanksize).toFixed(2)
  }

  // EMISSIONI ALBERO
  $scope.afterTreeEmissionNeutralizedIsFound = function () {
    $scope.treeTankSize = $rootScope.singleTree.tankSize
    $scope.dataComplete = [0]
    $scope.dataCurrentYear = [0]

    // calcolo assorbimento nei 10 anni di vita dell'albero
    for (var year = 1; year <= 10; year++) {
      $scope.dataComplete.push($scope.calcCO(year, $scope.treeTankSize))
    }

    // togliamo l'ultimo elemento dell'array e lo sostituiamo con il valore del tankSize dell'albero
    $scope.dataComplete.pop()
    $scope.dataComplete.push($scope.treeTankSize)

    // calcolo i 10 anni di vita dell'albero partendo dall'anno di nascita di opgni singolo albero
    $scope.teenYears = []
    var treeFirstYear = new Date($rootScope.singleTree.assignedOn).getFullYear()
    for (var year = 0; year <= 10; year++) {
      $scope.teenYears.push(year + treeFirstYear)
    }

    // assegno i valori della CO2 per ogni anno
    $scope.co2PerYear = $scope.teenYears.reduce(function (obj, year, index) {
      obj[year] = parseInt(Math.floor($scope.dataComplete[index] * 1000000))
      return obj
    }, {})


    // ciclo nella funzione e ricavo l'assorbimento dell'anno in corso
    for (var year = 1; year <= 10; year++) {
      if (treeFirstYear + year > new Date().getFullYear()) break
      $scope.dataCurrentYear.push($scope.calcCO(year, $scope.treeTankSize))
    }
  }

  $scope.$on('TreeIsFound', function (response) {
    $scope.afterTreeEmissionNeutralizedIsFound()
  })

  if ($rootScope.singleTree) $scope.afterTreeEmissionNeutralizedIsFound()
}])
