import template from './ecommerce-grid-template.html'
import controller from './ecommerce-grid-controller'

const bindings = {
  ecommerces: '@',
  title: '@',
  subTitle: '@',
  onItemClick: '&'
}

angular.module('ngApp')
  .component('ecommerceGrid', { template, controller, bindings })
