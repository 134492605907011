/* @ngInject */

export default function quantityInputController($scope, $element, $timeout) {

  /* bindings
  this.quantity: "="
  this.min: "<"
  this.max: "<"
  this.onChange: "&"
  this.color: "@"
  this.select: "="
  this.nullable: "<"
  */

  this.inputOptions = {
    updateOn: 'blur'
  }

  this.$onInit = () => {
    this.min = isNaN(this.min) ? 1 : this.min
    this.max = isNaN(this.max) ? 99 : this.max
    this.range = Array(this.max+1-this.min).fill(0).map((val, index) => val+this.min+index)
    $scope.$watch(
      () => this.quantity,
      data => {
        this.quantity = this.clamp(data)
      }
    )
  }

  this.increase = () => {
    this.change(1)
  }

  this.decrease = () => {
    this.change(-1)
  }

  this.change = mod => {
    this.quantity = this.clamp(this.quantity + mod)
    this.emitChange()
  }

  this.clamp = val => {
    val = Number(val)
    return this.nullable && val < this.min
      ? 0
      : Math.min(Math.max(this.min, val), this.max)
  }

  this.inputKeyPress = $event => {
    if ($event.keyCode === 13) {
      this.quantity = Number($element.find('input')[0].value)
      this.emitChange()
    }
  }

  this.emitChange = () => {
    $timeout(() => {
      this.onChange({ $qt: this.quantity })
    })
  }

}
