angular.module('ngApp')
.controller('iframeGeneratorController', ['$rootScope', '$scope', 'User', '$timeout', function ($rootScope, $scope, User, $timeout) {
  var $ = window.$

  $scope.init = false
  $scope.options = {
    title: true,
    user: true,
    tree: true,
    emission: true,
    project: true,
    farmers: true,
    generallink: true,
    event: true,
    code: true,
    href: true,
    idUser: '',
    slugUser: '',
    iframeType: '',
    langType: $rootScope.ln
  }

  var baseUrl = $rootScope.baseHtml5Url

  $scope.initFrame = function (firstTime) {
    if (firstTime) {
      $scope.options.iframeWidth = $scope.options.iframeType !== 'code' ? '100%' : '100%'
      $scope.options.iframeHeight = $scope.options.iframeType !== 'code' ? '600px' : '450px'
    }

    if ($scope.options.iframeType === 'numbersEvent') {
      $scope.options.titleColor = 'FFFFFF'
      $scope.backgroundColor = '08372B'
    } else {
      $scope.options.titleColor = '686868'
      $scope.backgroundColor = 'eeeeee'
    }

    var url
    $scope.init = true
    $('#iframeGeneratorAlert').html('')
    $('#containerFrame').html('')

    // console.log($scope.options)

    if ($scope.options.iframeType === 'user' || $scope.options.iframeType === 'event' ) {
      var mapType = $scope.options.iframeType === 'event' ? 'e' : 'm'
      url = baseUrl + $scope.options.langType + '/map/' + mapType + '/' + $scope.options.idUser + '?title=' + $scope.options.title + '&user=' + $scope.options.user + '&tree=' + $scope.options.tree + '&emission=' + $scope.options.emission + '&project=' + $scope.options.project + '&generallink=' + $scope.options.generallink + '&event=' + $scope.options.event + '&code=' + $scope.options.code + '&href=' + $scope.options.href
    } else if ($scope.options.iframeType === 'code') {
      url = baseUrl + $scope.options.langType + '/iframe/code' + '?titleColor=' + $scope.options.titleColor + '&backgroundColor=' + $scope.backgroundColor + '&generallink=' + $scope.options.generallink
    } else if ($scope.options.iframeType === 'numbersUser') {
      url = baseUrl + $scope.options.langType + '/iframe/numbers/user/' + $scope.options.idUser + '?titleColor=' + $scope.options.titleColor + '&backgroundColor=' + $scope.backgroundColor + '&generallink=' + $scope.options.generallink + '&emission=' + $scope.options.emission + '&project=' + $scope.options.project + '&farmers=' + $scope.options.farmers
    } else if ($scope.options.iframeType === 'numbersEvent') {
      url = baseUrl + $scope.options.langType + '/iframe/numbers/event/' +  $scope.options.idUser + '?titleColor=' + $scope.options.titleColor + '&backgroundColor=' + $scope.backgroundColor + '&generallink=' + $scope.options.generallink + '&emission=' + $scope.options.emission + '&project=' + $scope.options.project + '&farmers=' + $scope.options.farmers
    }

    var ifrm = document.createElement('IFRAME')
    ifrm.setAttribute('src', url)
    ifrm.setAttribute('frameborder', '0')
    ifrm.setAttribute('width', $scope.options.iframeWidth)
    ifrm.setAttribute('height', $scope.options.iframeHeight)
    $('#containerFrame').append(ifrm)

    $timeout(function () {
      var iframe = "<iframe src='" + url + "' frameborder='0' width='" + $scope.options.iframeWidth + "' height='" + $scope.options.iframeHeight + "'></iframe>"
      $scope.urlToPublish = iframe
    }, 300)
  }

  $scope.goBackFrame = function () {
    $scope.init = false
    $('#containerFrame').html('')
  }

  $scope.changeColorElement = function (hex) {
    if ($scope.isChanging === 'title') {
      $scope.options.titleColor = hex
    } else {
      $scope.backgroundColor = hex
    }

    $scope.initFrame()
  }

  $scope.changingColor = function (input) {
    $scope.isChanging = input
  }

  $scope.copyButton = function () {
    var $temp = $('<input>')
    $('body').append($temp)
    $temp.val($('#textToCopy').text()).select()
    document.execCommand('copy')
    $temp.remove()
  }

  $scope.idFromSlug = function () {

    var mapType = ($scope.options.iframeType === 'event' || $scope.options.iframeType === 'numbersEvent') ? 'e' : 'm'

    User.idFromSlug(mapType, $scope.options.slugUser)
      .then(function (data) {
        if (data.id) {
          $scope.options.idUser = data.id
          $scope.initFrame(true)
        } else {
          $('#iframeGeneratorAlert').html("<div class='alert alert-danger smaller' role='alert'>Slug not found!</div>")
        }
      })
  }
}])
