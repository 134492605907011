



























import { Vue, Component, Prop } from 'vue-property-decorator'
import Treecon from '@f/components/Treecons/Treecon.vue'

@Component({
  components: {
    Treecon,
  },
})
export default class NumberInput extends Vue {
  @Prop({ type: Number, default: 0 }) readonly value!: number
  @Prop({ type: Number }) readonly min!: number
  @Prop({ type: Number }) readonly max!: number
  @Prop({ type: Number, default: 1 }) readonly step!: number
  @Prop({ type: Boolean, default: false }) readonly select!: boolean
  @Prop({ type: String, default: '' }) readonly placeholder!: string
  @Prop({ type: Boolean, default: false }) readonly icons!: boolean
  @Prop({ type: Boolean, default: false }) readonly notEditable!: boolean

  // Methods
  increase(): void {
    if (!!this.$listeners.input) this.emitValue(this.value + this.step)
    if (!!this.$listeners.increase) this.$emit('increase')
  }

  decrease(): void {
    if (!!this.$listeners.input) this.emitValue(this.value - this.step)
    if (!!this.$listeners.decrease) this.$emit('decrease')
  }

  clampedValue(val: number | string): number {
    return Math.max(
      this.min,
      this.max ? Math.min(this.max, Number(val)) : Number(val)
    )
  }

  emitValue(ev: any): void {
    const val = (ev.target && ev.target.value) || ev
    this.$emit('input', this.clampedValue(val))
  }
}
