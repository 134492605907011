import {Experiment} from '@amplitude/experiment-js-client'
import {AmplitudeExperiment, UserInfo} from '@f/@types'
import {datadogRum} from '@datadog/browser-rum'
import {appCookies} from '@f/configs/app'

declare global {
  interface Window {
    experimentConfig?: string | undefined
  }
}

export const activeExperiments = [
  'client-map-animation',
  'link-share-widget',
  'new-header-texts-plant-a-tree',
  'free-tshirt',
  'user-quiz-homepage',
  'new-gift-a-tree-page',
  'why-register-login-signup-section',
  'login-signup-end-flow',
  'label-did-you-receive-a-gift',
  'purchase-thank-you-page',
  'ecommerce-subscriptions',
  'login-step-checkout-page',
  'social-proof-monthly-sign',
  'malawi-banner',
  'plant-gift-entry-point',
  'celebration-modal'
] as const

export enum ActiveExperiments {
  HeaderTextsPlantATree = 'new-header-texts-plant-a-tree',
  WhyRegisterLoginSignupSection = 'why-register-login-signup-section',
  LabelDidYouReceiveAGift = 'label-did-you-receive-a-gift',
  SocialProofMonthlySign = 'social-proof-monthly-sign',
  SocialProofRomantic = 'social-proof-romantic',
  SocialProofMostRequested = 'social-proof-most-requested',
  SocialProofNewProduct = 'social-proof-new-product',
  SocialProofBirthdayGift = 'social-proof-birthday-gift',
  MalawiBanner = 'malawi-banner',
  EcommerceProductsPriority = 'ecommerce-products-priority',
  CelebrationModal = 'celebration-modal',
}

/**
 * default experiment values
 */
export enum DefaultExperimentValues {
  Control = 'control',
  Treatment = 'treatment',
  On = 'on',
}

/**
 * for WhyRegisterLoginSignupSection experiment
 */
export enum WhyRegisterLoginSignupSectionValues {
  Control = 'control',
  On = 'on',
}

/**
 * for NewGiftATreePage experiment
 */

export enum NewGiftATreePage {
  Control = 'control',
  Accordion = 'accordion',
  Filter = 'filter',
}

/**
 * for LabelDidYouReceiveAGift experiment
 */
export enum LabelDidYouReceiveAGiftValues {
  Control = 'control',
  On = 'on',
}

/**
 * for HeaderTextsPlantATree experiment
 */
export enum HeaderTextsPlantATreeValues {
  Control = 'control',
  LongDescription = 'long-description',
  ShortDescription = 'short-description',
}

/**
 * @description Add your ActiveExperiments value to set the correct value type for the `experimentService`
 */
export type ActiveExperimentsValues = {
  [ActiveExperiments.HeaderTextsPlantATree]: {
    value: HeaderTextsPlantATreeValues
    payload: string // the payload is the translation key on lokalise
  }
  [ActiveExperiments.EcommerceProductsPriority]: {
    value: DefaultExperimentValues
    payload: number[]
  }
  [ActiveExperiments.CelebrationModal]: {
    value: DefaultExperimentValues
  }
}

export type ExperimentKey = typeof activeExperiments[number]

export type TypedAmplitudeExperiment<T extends ActiveExperiments> = {
  value: T extends keyof ActiveExperimentsValues
    ? ActiveExperimentsValues[T]['value']
    : never
  payload: T extends keyof ActiveExperimentsValues
    ? ActiveExperimentsValues[T] extends { payload: infer TPayload }
      ? TPayload
      : never
    : never
}

const client = Experiment.initialize(window.experimentConfig!)

const experimentService = {
  init(deploymentKey: string) {
    return Experiment.initialize(deploymentKey)
  },

  /**
   * @deprecated use `getExperimentV2` instead for a better ts intellisense
   */
  async getExperiment(
    userInfo: Partial<UserInfo>,
    key: ExperimentKey
  ): Promise<AmplitudeExperiment | undefined> {
    if (!activeExperiments.includes(key)) {
      throw new Error(`${key} is not a experiment valid key`)
    }
    const deviceId = appCookies.deviceId
    const userId = userInfo.id ? userInfo.id.toString() : undefined
    const experimentUser = client.getUser()
    if (
      !experimentUser ||
      experimentUser.user_id !== userInfo.id ||
      !experimentUser ||
      experimentUser.device_id !== deviceId
    ) {
      await client.fetch({
        user_id: userId,
        device_id: deviceId,
        user_properties: {
          country: String(userInfo.country),
          countryCode: String(userInfo.countryCode),
          premium: String(userInfo.premium),
          userType: String(userInfo.usertype)
        }
      })
    }
    const variant = client.variant(key)
    datadogRum.addAction(`User Expose Experiment "${key}"`, {
      key,
      variant
    })
    if (!variant.value) return
    return {
      key,
      value: variant
    }
  },

  /**
   * Load the experiment from Amplitude using the experiment key
   * @param userInfo - user info
   * @param key - experiment key
   * @returns Amplitude experiment
   */
  async getExperimentV2<T extends ActiveExperiments>(
    userInfo: Partial<UserInfo>,
    key: T
  ) {
    const deviceId = appCookies.deviceId
    const userId = userInfo.id?.toString()
    const experimentUser = client.getUser()
    if (
      !experimentUser ||
      experimentUser.user_id !== userInfo.id ||
      !experimentUser ||
      experimentUser.device_id !== deviceId
    ) {
      await client.fetch({
        user_id: userId,
        device_id: deviceId,
        user_properties: {
          country: String(userInfo.country),
          countryCode: String(userInfo.countryCode),
          premium: String(userInfo.premium),
          userType: String(userInfo.usertype)
        }
      })
    }
    const variant = client.variant(key)
    datadogRum.addAction(`User Expose Experiment "${key}"`, {
      key,
      variant
    })
    if (!variant.value) return

    return {
      value: variant.value,
      payload: variant.payload
    } as TypedAmplitudeExperiment<T>
  }
} as const

export default experimentService
