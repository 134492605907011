export enum UserType {
  Business = 'Business',
  Originator = 'Originator',
  Private = 'Private',
  Staff = 'Staff',
}

export enum UserGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}
