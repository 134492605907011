angular.module('ngApp')
.controller('selectedEmissionController', ['$rootScope', '$scope', 'Emission', '$stateParams', '$state', '$timeout', function ($rootScope, $scope, Emission, $stateParams, $state, $timeout) {
  $scope.emissionToCalculate = false

  // Faccio partire lo slider in 'ritardo'
  $scope.activeSlider = false
  $timeout(function () { $scope.activeSlider = true }, 500)

  $scope.slider = {
    value: 1,
    options: {
      floor: 1,
      ceil: 1000,
      logScale: true,
      translate: function (value) {
        var unit

        switch ($scope.emissionToCalculate.defaultUnit) {
          case 'hours':
            unit = 'h'
            break
          case 'minute':
            unit = 'min'
            break
          default:
            unit = $scope.emissionToCalculate.defaultUnit
        }

        return value + unit
      }
    }
  }

  $rootScope.$on('selectedEmissionToCalculate', function (event, data) {
    $scope.emissionToCalculate = data
    $scope.slider.options.ceil = $scope.emissionToCalculate.id === 'plane' ? 10000 : 1000
    $scope.slider.options.logScale = $scope.emissionToCalculate.id !== 'plane'
  })

  $scope.calculate = function () {
    var data = {
      emission: $scope.emissionToCalculate,
      value: $scope.slider.value
    }

    $rootScope.$broadcast('selectedSizeOfEmissionToCalculate', data)
    $rootScope.closeTreedomModal()
  }
}])
