
















































import { defineComponent, PropType } from '@vue/composition-api'
import DotLoader from '@f/components/DotLoader.vue'
import HUDPage from './HUDPage.vue'
import useRoutes from '@f/composables/useRoutes'
import { TrCountry } from '@f/@types/graphql'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import { DataEventMap } from '@f/services/TagManager/events'

// @ts-ignore
export default defineComponent({
  name: 'HUDEcommerceCountries',
  components: {
    HUDPage,
    DotLoader,
  },
  props: {
    userMapActionText: {
      type: String,
      required: true,
    },
    countries: {
      type: Array as PropType<TrCountry[]>
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const isMobile = useMediaQuery(maxWidth('md'))
    const { href } = useRoutes()

    const onCountryClick = (countrySelected: string) => {
      DataEventMap({ countrySelected })
    }

    return {
      href,
      onCountryClick,
      isMobile,
    }
  }
})
