angular.module('ngApp')
.controller('horoscopePageController', ['$scope', '$rootScope', 'Horoscope', '$filter', '$animate', function ($scope, $rootScope, Horoscope, $filter, $animate) {
  // Enable ngAnimate
  $animate.enabled(true)

  var getLastSign = function() {
    if ($rootScope.statusHistory && $rootScope.statusHistory.previous) {
      switch ($rootScope.statusHistory.previous.state.name) {
        case 'species.tree':
          return $rootScope.lastSlug || null
        case 'horoscope.sign':
          return $rootScope.statusHistory.previous.params.sign
        default:
          return Horoscope.findActiveSign()
      }
    }
    return null
  }

  $scope.horoscopeTrees = [];
  $scope.treesToArray = [];
  $scope.previewProduct = null
  $scope.activeTree = {};
  $scope.activeSign =  getLastSign()
  $scope.activeProduct = ($scope.activeSign) ? Horoscope.getIndex($scope.activeSign) : 0;
  $scope.horoscope = Horoscope.signs;

  $scope.setActive = function(id) {
    $scope.activeProduct = id
  }

  Horoscope.getHoroscope($scope.activeSign, true)
  .then(function (horoscope) {
      if (horoscope && horoscope.length) {
        $scope.horoscopeTrees = horoscope;
        $scope.previewProduct = horoscope[Horoscope.getIndex(Horoscope.findActiveSign())]
        $scope.$broadcast('hasHoroscopeSpecies', true)
      }
  });

  $scope.horoscopeFaqs = [
    {
      id: 1,
      question: $filter('trans')('horoscope.faq.01.question'),
      answer: $filter('trans')('horoscope.faq.01.answer')
    },
    {
      id: 2,
      question: $filter('trans')('horoscope.faq.02.question'),
      answer: $filter('trans')('horoscope.faq.02.answer')
    },
    {
      id: 3,
      question: $filter('trans')('horoscope.faq.03.question'),
      answer: $filter('trans')('horoscope.faq.03.answer')
    }
  ]

}])
