<template>
  <div>
    <h4 class="ntds-heading--4 extrabold-title">
      {{ $t(title) }}
    </h4>
    <p>
      {{ $t(content) }}
    </p>
    <div class="delete-actions-container">
      <button @click="$emit('close')" class="tr-button tr-button--secondary">
        {{ $t('forest.createFlow.step.first.cancel') }}
      </button>
      <button @click="$emit('confirm')" class="tr-button tr-button--danger">
        <Treecon icon="delete-line" size="normal" class="standard" />
        <Treecon icon="delete-fill" size="normal" class="hovered" />
        <span>{{ $t('forest.createFlow.deleteModal.action') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import Treecon from '@f/components/Treecons/Treecon.vue'

export default {
  name: 'DeleteForestConfirmModal',
  components: {
    Treecon,
  },
  props: {
    title: {
      type: String,
      default: 'forest.createFlow.deleteModal.title'
    },
    content: {
      type: String,
      default: 'forest.createFlow.deleteModal.content'
    }
  }
}
</script>

<style scoped>
@import '@f/scss/design-system/_variables.scss';
@import '@f/scss/design-system/typography.scss';

h4.ntds-heading--4.extrabold-title {
  text-align: left;
  font-weight: $ntds-weight-extrabold;
  color: $ntds-font-100;
}

.delete-actions-container {
    display: flex;
    margin-top: 20px;
    button:last-child {
        margin-left: 20px;
        span {
          margin-left: 5px;
          margin-top: 3px;
        }
        svg {
          &.hovered {
            display: none;
          }
        }
        &:hover {
          svg {
            &.hovered {
              display: block;
            }
            &.standard {
              display: none;
            }
          }
        }
    }
}

@media (max-width: $ntds-width-small) {
    .delete-actions-container {
        justify-content: space-between;
    }
}
</style>