










import {TrForecastTreeEnhanced, TrTree} from '@f/@types/graphql'
import {computed, defineComponent, onMounted, PropType, ref} from '@vue/composition-api'
import SunCalc from 'suncalc'
import * as PIXI from 'pixi.js'
import SunYellowImage from '@f/images/drawings/sun_yellow.png'
import SunRedImage from '@f/images/drawings/sun_red.png'
import MoonFirstQuarterImage from '@f/images/drawings/moon/first-quarter.png'
import MoonFullMoonImage from '@f/images/drawings/moon/full-moon.png'
import MoonLastQuarterImage from '@f/images/drawings/moon/last-quarter.png'
import MoonNewImage from '@f/images/drawings/moon/new.png'
import MoonWaningCrescentImage from '@f/images/drawings/moon/waning-crescent.png'
import MoonWaningGibbousImage from '@f/images/drawings/moon/waning-gibbous.png'
import MoonWaxingCrescentImage from '@f/images/drawings/moon/waxing-crescent.png'
import MoonWaxingGibbousImage from '@f/images/drawings/moon/waxing-gibbous.png'
import CloudImage from '@f/images/drawings/cloudB.png'
import StarsImage from '@f/images/drawings/stars.png'
import DropImage from '@f/images/drawings/drop.png'
import BudStatus1 from '@f/images/drawings/bud_status_1.png'
import BudStatus2 from '@f/images/drawings/bud_status_2.png'
import BudStatus3 from '@f/images/drawings/bud_status_3.png'
import moment from 'moment'

const BUD_STATUS = [BudStatus1, BudStatus2, BudStatus3] as const

const getTreeIconImage = (tree: TrTree): string => {
  if (tree.status && tree.status < 4) return BUD_STATUS[tree.status - 1]
  if (tree.limited?.icon) return tree.limited.icon.small || ''
  return tree.specie?.icon?.small || ''
}

const getCountryImage = (tree: TrTree) => tree.specie!.country!.image!.medium!

const getLoader = (tree: TrTree): PIXI.Loader => {
  const loader = new PIXI.Loader();
  return loader
      .add('sunYellow', SunYellowImage)
      .add('sunRed', SunRedImage)
      .add('moon-first-quarter', MoonFirstQuarterImage)
      .add('moon-full-moon', MoonFullMoonImage)
      .add('moon-last-quarter', MoonLastQuarterImage)
      .add('moon-new', MoonNewImage)
      .add('moon-waning-crescent', MoonWaningCrescentImage)
      .add('moon-waning-gibbous', MoonWaningGibbousImage)
      .add('moon-waxing-crescent', MoonWaxingCrescentImage)
      .add('moon-waxing-gibbous', MoonWaxingGibbousImage)
      .add('cloud', CloudImage)
      .add('stars', StarsImage)
      .add('drop', DropImage)
      .add('country', getCountryImage(tree), {
        loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
      })
}

const minutesOfDay = (time: moment.Moment) => {
  return time.minutes() + time.hours() * 60
};

const DAY_MINUTES = 24 * 60

type DayTimes = {
  timezone: string,
  time: moment.Moment,
  startSunriseTime: moment.Moment,
  startSunsetTime: moment.Moment,
  startNightTime: moment.Moment,
  startDayTime: moment.Moment,
  endNightTime: moment.Moment,
}
const getDayTimes = (time: moment.Moment, position: { lat: number, lng: number }): DayTimes => {
  const timezone = time.tz()!
  const times = SunCalc.getTimes(time.toDate(), position.lat, position.lng)
  const startSunriseTime = moment(times.sunrise).tz(timezone)
  const startSunsetTime = moment(times.sunset).tz(timezone)
  const startNightTime = moment(startSunsetTime).add(1, 'hours')
  const startDayTime = moment(startSunriseTime).add(1, 'hours')
  const endNightTime = moment(times.nightEnd).tz(timezone).add(1, 'day')
  return {
    time,
    timezone,
    startSunriseTime,
    startSunsetTime,
    startNightTime,
    startDayTime,
    endNightTime,
  }
}

type MoonPhase = 'new' | 'waxing-crescent'
    | 'first-quarter' | 'waxing-gibbous'
    | 'full-moon' | 'waning-gibbous'
    | 'last-quarter' | 'waning-crescent'
const getMoonPhase = (time: moment.Moment): MoonPhase => {
  const moonIllumination = SunCalc.getMoonIllumination(time.toDate());
  const moonPhase = Number(moonIllumination.phase.toFixed(3));
  if (moonPhase >= 0 && moonPhase < 0.125) {
    return 'new'
  }
  if (moonPhase >= 0.125 && moonPhase < 0.25) {
    return 'waxing-crescent'
  }
  if (moonPhase >= 0.25 && moonPhase < 0.375) {
    return 'first-quarter'
  }
  if (moonPhase >= 0.375 && moonPhase < 0.5) {
    return 'waxing-gibbous'
  }
  if (moonPhase >= 0.5 && moonPhase < 0.625) {
    return 'full-moon'
  }
  if (moonPhase >= 0.625 && moonPhase < 0.75) {
    return 'waning-gibbous'
  }
  if (moonPhase >= 0.75 && moonPhase < 0.875) {
    return 'last-quarter'
  }
  return 'waning-crescent'
}

type MomentOfDay = 'night' | 'sunrise' | 'day' | 'sunset'

const getMomentOfDay = (dayTimes: DayTimes): MomentOfDay | null => {
  const {
    time,
    startSunsetTime,
    startSunriseTime,
    startNightTime,
    startDayTime,
  } = dayTimes
  const timeValue = minutesOfDay(time)
  if (timeValue >= minutesOfDay(startNightTime) || timeValue < minutesOfDay(startSunriseTime)) {
    return 'night'
  }
  if (timeValue >= minutesOfDay(startSunriseTime) && timeValue < minutesOfDay(startDayTime)) {
    return 'sunrise'
  }
  if (timeValue >= minutesOfDay(startDayTime) && timeValue < minutesOfDay(startSunsetTime)) {
    return 'day'
  }
  if (timeValue >= minutesOfDay(startSunsetTime) && timeValue < minutesOfDay(startNightTime)) {
    return 'sunset'
  }
  return null
}

type AtmosphericAgentIntensity = 'light' | 'normal' | 'strong' | 'none'
type AtmosphericCondition = {
  rain: AtmosphericAgentIntensity,
  clouds: AtmosphericAgentIntensity,
}
const getAtmosphericCondition = (forecast: TrForecastTreeEnhanced): AtmosphericCondition => {
  const getRainIntensity = () => {
    if (forecast.precipIntensity! < 0.1) {
      return 'light'
    }
    if (forecast.precipIntensity! > 1) {
      return 'strong'
    }
    return 'normal'
  }
  switch (forecast.icon) {
    case 'clear-day':
    case 'clear-night':
    case 'windy':
      return {
        rain: 'none',
        clouds: 'none',
      }
    case 'fog':
    case 'cloudy':
    case 'partly-cloudy-day':
    case 'partly-cloudy-night':
      return {
        rain: 'none',
        clouds: 'light',
      }
    case 'rain':
    case 'snow':
    case 'sleet':
    case 'hail':
    case 'tornado':
    case 'thunderstorm':
      return {
        rain: getRainIntensity(),
        clouds: 'strong',
      }
    default:
      return {
        rain: 'none',
        clouds: 'none',
      }
  }
}


const getSunTexture = (momentOfDay: MomentOfDay | null, resources: PIXI.utils.Dict<PIXI.LoaderResource>) => {
  switch (momentOfDay) {
    case 'night':
    case 'sunrise':
    case 'sunset':
      return resources.sunRed.texture!
    case 'day':
    default:
      return resources.sunYellow.texture!
  }
}

const getMoonTexture = (phase: MoonPhase, resources: PIXI.utils.Dict<PIXI.LoaderResource>) =>
    resources[`moon-${phase}`].texture!

type WeatherState = {
  dayTimes: DayTimes,
  momentOfDay: MomentOfDay | null,
  moonPhase: MoonPhase,
  atmosphericCondition: AtmosphericCondition,
  forecast: TrForecastTreeEnhanced,
  sunPosition: SunCalc.GetSunPositionResult,
  moonPosition: SunCalc.GetSunPositionResult,
}
const getWeatherState = (tree: TrTree): WeatherState => {
  const forecast = tree.forecastEnhanced!
  const time = moment(Number(forecast.time)! * 1000)
      .tz(forecast.timezone!)
  const dayTimes = getDayTimes(time, {lat: tree.lat!, lng: tree.lng!})
  const momentOfDay = getMomentOfDay(dayTimes)
  const moonPhase = getMoonPhase(time)
  const atmosphericCondition = getAtmosphericCondition(forecast)
  const sunPosition = SunCalc.getPosition(time.toDate(), tree.lat!, tree.lng!)
  const moonPosition = SunCalc.getMoonPosition(time.toDate(), tree.lat!, tree.lng!)
  return {
    dayTimes,
    momentOfDay,
    moonPhase,
    atmosphericCondition,
    forecast,
    sunPosition,
    moonPosition,
  }
}

type Resources = Record<string, PIXI.LoaderResource>
type CreateActor = (
    resources: Resources,
    app: PIXI.Application,
    state: WeatherState,
) => PIXI.Container;

const Tree: CreateActor = (resources, app) => {
  const width = app.view.width
  const height = app.view.height
  const actor = new PIXI.Sprite(resources.tree.texture)
  const scale = Math.min(height, 130) / actor.height
  actor.scale.y = scale
  actor.scale.x = scale
  actor.x = width / 2 - actor.width / 2
  actor.y = height - actor.height
  return actor
}

const Country: CreateActor = (resources, app) => {
  const width = app.view.width
  const height = app.view.height
  const actor = new PIXI.Sprite(resources.country.texture)
  const scale = 800 * 0.9 / actor.width
  actor.scale.y = scale
  actor.scale.x = scale
  actor.x = width / 2 - actor.width / 2
  actor.y = height - actor.height
  return actor
}

const SUN_SIZE = 70
const Sun: CreateActor = (resources, app, state) => {
  const width = app.view.width
  const height = app.view.height
  const {sunPosition} = state;
  const actor = new PIXI.Sprite(getSunTexture(state.momentOfDay, resources))
  const scale = SUN_SIZE / actor.height
  actor.scale.y = scale
  actor.scale.x = scale
  actor.x = width * (sunPosition.azimuth / (Math.PI * 3 / 4))
  actor.y = height - height * (sunPosition.altitude / (Math.PI / 2)) - SUN_SIZE / 2
  return actor
}

const MOON_SIZE = 60
const Moon: CreateActor = (resources, app, state) => {
  const width = app.view.width
  const height = app.view.height
  const {moonPosition} = state;
  const actor = new PIXI.Sprite(getMoonTexture(state.moonPhase, resources))
  const scale = MOON_SIZE / actor.height
  actor.scale.y = scale
  actor.scale.x = scale
  actor.x = width * (moonPosition.azimuth / (Math.PI * 3 / 4))
  actor.y = height - height * (moonPosition.altitude / (Math.PI / 2)) - MOON_SIZE
  return actor
}

type Offset = {
  x: number,
  y: number,
}
const getCloudOffset = (condition: AtmosphericCondition): Offset => {
  switch (condition.clouds) {
    case 'light':
      return {
        x: 55,
        y: 25,
      }
    case 'normal':
      return {
        x: 35,
        y: 25
      }
    case 'strong':
      return {
        x: 25,
        y: 25,
      }
    default:
    case 'none':
      return {
        x: 1000,
        y: 1000,
      }
  }
}
const Clouds: CreateActor = (resources, app, state) => {
  const width = 800
  const texture = resources.cloud.texture!
  const actor: PIXI.Container = new PIXI.Container();
  const {
    forecast,
    atmosphericCondition,
  } = state;
  if (atmosphericCondition.clouds === 'none') return actor;
  const offset = getCloudOffset(atmosphericCondition)
  const cloudOffsetX = offset.x
  const cloudOffsetY = offset.y
  const cloudSpeed = (forecast.windSpeed || 1) / 500
  actor.y = cloudOffsetY / 3
  const numberOfClouds = Math.ceil(width / (texture.width + cloudOffsetX))
  const clouds = Array(numberOfClouds).fill(0).map((_, i) => {
    const cloud = new PIXI.Sprite(texture)
    cloud.x = cloudOffsetX + i * (texture.width + cloudOffsetX)
    cloud.y = cloudOffsetY * ((i + 1) % 2)
    const scale = Math.random() * 0.4 + 0.2
    cloud.scale.x = scale
    cloud.scale.y = scale
    return cloud
  })
  app.ticker.add((delta) => {
    clouds.forEach((cloud) => {
      if (cloud.x > width) {
        cloud.x = -cloud.texture.width
        return
      }
      cloud.x += delta * cloudSpeed
    })
  })
  actor.addChild(...clouds)
  return actor
}

type RainConfig = {
  drops: number,
  speedX: number,
  speedY: number,
}
const gerRainConfig = (condition: AtmosphericCondition): RainConfig => {
  switch (condition.rain) {
    case 'light':
      return {
        drops: 150,
        speedX: 0,
        speedY: 2,
      }
    case 'normal':
      return {
        drops: 200,
        speedX: 1,
        speedY: 3,
      }
    case 'strong':
      return {
        drops: 250,
        speedX: 2,
        speedY: 5,
      }
    case 'none':
    default:
      return {
        drops: 0,
        speedX: 0,
        speedY: 0,
      }
  }
}
const Rain: CreateActor = (resources, app, state) => {
  const width = 800
  const height = 200
  const texture = resources.drop.texture!
  const actor = new PIXI.Container()
  actor.width = width + 200
  actor.height = height
  actor.x = -100
  const {
    atmosphericCondition,
  } = state
  if (atmosphericCondition.rain === 'none') return actor;
  const config = gerRainConfig(atmosphericCondition)
  const drops = Array(config.drops).fill(0).map((_, i) => {
    const drop = new PIXI.Sprite(texture)
    drop.x = Math.random() * (width + 200) - 100
    drop.y = Math.random() * (height + 50)
    drop.scale.x = 0.15
    drop.scale.y = 0.15
    return drop
  })
  app.ticker.add((delta) => {
    drops.forEach((drop) => {
      if (drop.y > height) {
        drop.y = 0 - drop.texture.height * Math.random()
        drop.x = Math.random() * (width + 50)
        return
      }
      drop.y += delta * config.speedY
      drop.x += delta * config.speedX
    })
  })
  actor.addChild(...drops)
  return actor
}

const Stars: CreateActor = (resources, app, state) => {
  const {
    momentOfDay,
  } = state;
  if (momentOfDay === 'day') return new PIXI.Container();
  const texture = resources.stars.texture!
  const actor: PIXI.Container = new PIXI.Sprite(texture)
  const scale = 800 / actor.width
  actor.scale.y = scale
  actor.scale.x = scale
  return actor
}

const Stage: CreateActor = (resources, app, state) => {
  const container = new PIXI.Container()
  const country = Country(resources, app, state)
  const sun = Sun(resources, app, state)
  const moon = Moon(resources, app, state)
  const clouds = Clouds(resources, app, state)
  const stars = Stars(resources, app, state)
  const rain = Rain(resources, app, state)
  container.addChild(stars)
  container.addChild(sun)
  container.addChild(moon)
  container.addChild(country)
  container.addChild(clouds)
  container.addChild(rain)
  return container
}

export default defineComponent({
  name: 'TreeWeatherCanvas',
  props: {
    tree: {
      type: Object as PropType<TrTree>,
      required: true
    }
  },
  setup(props) {
    const root = ref<HTMLDivElement>()
    const tree = props.tree;
    const state = getWeatherState(tree)
    const loading = ref(true)
    const treeImage = computed(() => getTreeIconImage(tree))
    const {momentOfDay} = state
    onMounted(() => {
      if (!root.value) return
      requestAnimationFrame(() => {
        const app = new PIXI.Application({
          resizeTo: root.value,
          backgroundAlpha: 0,
          autoStart: true,
          sharedLoader: false,
        })
        root.value!.appendChild(app.view)
        getLoader(tree)
            .load((_, resources) => {
              const stage = Stage(resources, app, state)
              app.stage.addChild(stage)
              loading.value = false
            })
      })
    })
    return {
      root,
      momentOfDay,
      loading,
      treeImage,
    }
  }
})
