











import { defineComponent } from "@vue/composition-api";
import CopyInput from "@f/components/Form/CopyInput.vue";
import ShareNative from "@f/components/ShareNative.vue";
import { DataEventGameCopiedCode } from '@f/services/TagManager/events'

export default defineComponent({
  name: "ShareRewardByLink",
  components: {
    CopyInput,
    ShareNative,
  },
  props: {
    giftUrl: { type: String, required: true },
  },
  setup() {
    const useNativeShare = !!navigator.share;
    const sharingReward = () => {
      DataEventGameCopiedCode()
    }
    return {
      sharingReward,
      useNativeShare,
    };
  },
});
