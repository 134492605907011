import { AxiosInstance } from 'axios'
import { TreedomAuth } from '@f/auth'

const registerAxiosAuthorizationInterceptor = (client: AxiosInstance) => {
  client.interceptors.request.use(
    async (config) => {
      if (await TreedomAuth.isAuthenticated()) {
        config.headers.Authorization = `Bearer ${await TreedomAuth.getJwtToken()}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
}

export default registerAxiosAuthorizationInterceptor