import isString from 'lodash/isString'

angular.module('ngApp')
  .controller('productPowersCirclesController', ['$scope', 'Utility', function ($scope, Utility) {

    var ctrl = this;

    ctrl.powers = {};

    ctrl.width = $scope.attrs.width;
    ctrl.duration = $scope.attrs.duration || 1000;
    ctrl.background = $scope.attrs.background || '#ffffff';
    ctrl.max = $scope.attrs.max || 10;
    ctrl.powers =
      isString($scope.attrs.powers)
        && $scope.attrs.powers.indexOf('{') >= 0
          ? Utility.hydratePowers(JSON.parse($scope.attrs.powers))
          : Utility.getEmptyPowers()
  }]);
