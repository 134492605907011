












import { computed, defineComponent, ref, onMounted } from '@vue/composition-api'
import {getAngularModule, getRootScope, getStateService} from '@f/services/Angular'
import Icon from '@f/components/Icon.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Logo from '@f/images/logo-dynamic.svg'

export default defineComponent({
  name: 'CreateYourGiftModal',
  components: {
    DotLoader,
    Logo,
    Icon,
  },
  props: {
    userId: Number,
    treeId: String,
    source: String
  },
  setup(props) {
    const loaded = ref(false)
    const stateService = getStateService();

    const url = computed(() => {
      const lang = getRootScope()?.ln

      const url = new URL(`/${lang}/create-your-gift`, location.origin)

      url.searchParams.set('embedded', 'true')
      url.searchParams.set('position', props?.source ?? '')

      if (props.treeId) {
        url.searchParams.set('treeId', props.treeId)
      }
      return url.toString()
    })
    const close = () => {
      getAngularModule('Modal').close('create-your-gift')
    }
    onMounted(() => {
      getRootScope()?.onMessage('create-your-gift-close', ({ payload }= {}) => {
        const redirect = payload?.redirect

        close()

        if(redirect) { // if the iFrame has a redirect, I need to execute a redirect in the proper page
          stateService?.go(redirect)
        }
      })
    })
    return {
      url,
      loaded,
      close,
    }
  }
})
