




















import { Vue, Component } from 'vue-property-decorator'
import { CartWidgetNew } from '@f/components/Navbar/partials'
import Logo from '@f/images/logo-dynamic.svg'

@Component({
  name: 'SpecieHeader',
  components: { CartWidgetNew, Logo },
})
export default class SpecieHeader extends Vue {
  /*--- MODEL ---------*/

  /*--- PROPS ---------*/

  /*--- DATA ----------*/

  /*--- COMPUTED ------*/

  /*--- WATCHERS ------*/

  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
}
