





import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FacebookLoginButton',
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    }
  }
})
