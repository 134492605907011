















































































































































import {computed, defineComponent, PropType, ref,} from '@vue/composition-api'
import {useQuery, useResult} from '@vue/apollo-composable'
import useRoutes from '@f/composables/useRoutes'
import TreeInfoName from '@f/components/Tree/TreeInfoName.vue'
import TreeMessages from '@f/components/Tree/TreeMessages.vue'
import ShareNative from '@f/components/ShareNative.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import {TrTree, TrTreeDiaryGift} from '@f/@types/graphql'
import ForestAvatarDefault from '@f/images/avatars/event_avatar_default.png'
import PackageIcon from '@f/images/gift/gift-colors.svg?path'
import {translate} from '@f/services/Translation'
import {getModalService} from '@f/services/Angular'
import {maxWidth, useMediaQuery} from '@f/utils/breakpoints'
import {getVisibleImage} from '@f/services/Tree'
import useTreeLikes from '@f/composables/useTreeLikes'
import {isAuthenticated} from '@f/services/Auth'
import {TREE_GIFT_POST} from '@f/graphql/queries'
import useAuthentication from '@f/composables/useAuthentication'
import TreeCardStatusBadge from '@f/components/Cards/contents/TreeCardStatusBadge.vue'
import {DataEventTreeDiaryInteraction} from '@f/services/TagManager/events'
import GiftInDelivery from '@f/components/Gift/GiftInDelivery.vue'
import TreeInfoPicture from './TreeInfoPicture.vue'
import Popover from '@f/components/Popover.vue'
import TreeEditNameModal from "@f/components/Tree/TreeEditNameModal.vue";

interface TreeInfoItem {
  class?: string,
  image: string
  text: string
}

const handleDataEvent = (action: string) => {
  DataEventTreeDiaryInteraction('myDiary', action, null);
}

export default defineComponent({
  name: 'TreeInfoCard',
  components: {
    TreeEditNameModal,
    TreeCardStatusBadge,
    ShareNative,
    TreeInfoName,
    TreeMessages,
    Treecon,
    GiftInDelivery,
    TreeInfoPicture,
    Popover
  },
  props: {
    tree: {
      type: Object as PropType<TrTree>,
    },
    minInfo: {
      type: Number,
      default: 1,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['like-change', 'gift', 'gift-recap'],
  setup(props, {emit}) {
    const {isLoggedAs} = useAuthentication()
    const editing = ref(false)
    const isMobile = useMediaQuery(maxWidth('md'))
    const canEditName = computed(() => props.tree?.user && isLoggedAs(props.tree.user))
    const expanded = ref(false)
    const {parseProfileUrl, parseForestUrl, parseTreeUrl, href} = useRoutes()
    const status = computed(() => props.tree?.status)
    const image = computed(() => getVisibleImage(props.tree!)?.small)
    const scientificName = computed(() => props.tree?.specie?.scientificName?.name || '')
    const hasBeenGifted = computed(() => props.tree?.user?.id !== props.tree?.initialUser?.id)
    const isGifting = computed(() => !!props.tree?.gift && !hasBeenGifted.value)
    const isMine = computed(() => isLoggedAs(props.tree?.user!))
    const canBenGifted = computed(() => !isGifting.value && isMine.value && !hasBeenGifted.value)
    const {result} = useQuery(TREE_GIFT_POST,
        () => ({treeId: props.tree?.treeId}),
        () => ({enabled: !!props.tree?.treeId && hasBeenGifted.value})
    )
    const giftPost = useResult<TrTreeDiaryGift>(result)
    const userInfo = computed<TreeInfoItem | null>(() => {
      if (hasBeenGifted.value && props.tree?.user) {
        return {
          image: props.tree.user.avatar?.small || '',
          text: translate(
              'tree.infoCard.user',
              {url: parseProfileUrl(props.tree.user), name: props.tree.user.title}
          )
        }
      }
      return null
    })
    const initialUserInfo = computed<TreeInfoItem | null>(() => {
      if (props.tree?.initialUser) {
        return {
          name: props.tree.initialUser.firstName,
          image: props.tree.initialUser.avatar?.small || '',
          text: translate(
              'tree.infoCard.initialUser',
              {url: parseProfileUrl(props.tree.initialUser), name: props.tree.initialUser.title}
          )
        }
      }
      return null
    })
    const forestInfo = computed<TreeInfoItem | null>(() => {
      if (props.tree?.event) {
        return {
          image: props.tree.event.avatar?.small || ForestAvatarDefault,
          text: translate(
              'tree.infoCard.forest',
              {url: parseForestUrl(props.tree.event), name: props.tree.event.name}
          )
        }
      }
      return null
    })
    const giftInfo = computed<TreeInfoItem | null>(() => {
      if (hasBeenGifted.value && giftPost.value?.message) {
        return {
          image: PackageIcon,
          text: translate(
              'tree.infoCard.gift',
              {url: href('.', {share: giftPost.value?.id})}
          )
        }
      }
      return null
    })
    const coupledInfo = computed(() => {
      return props.tree?.coupled
          ? {
            class: 'coupled',
            image: props.tree.coupled.limited?.icon?.small
                || props.tree.coupled.specie?.icon?.small
                || '',
            text: translate(
                'tree.infoCard.coupled',
                {
                  url: parseTreeUrl(props.tree.coupled),
                  name: props.tree.coupled.name
                      || (props.tree.coupled.specie?.name && translate(props.tree.coupled.specie.name))
                      || ''
                }
            )
          }
          : null
    })
    const relationsData = computed<TreeInfoItem[]>(() => [
      userInfo.value,
      initialUserInfo.value,
      forestInfo.value,
      giftInfo.value,
      coupledInfo.value,
    ].filter(Boolean) as TreeInfoItem[])


    const country = computed(() => props.tree?.specie?.country)
    const countryFlag = computed(() => country.value?.flagImage || '')
    const countryName = computed(() => country.value?.name || '')
    const countryUrl = computed(() => href('country', {
      slug: country.value?.slug,
    }))
    const initialUserUrl = computed(() => href('user', {
      slug: props.tree?.initialUser?.slug,
    }))

    const {
      likeTree,
      likesCount,
      isLikedByLoggedUser,
    } = useTreeLikes(computed(() => props.tree!))

    const onLikeClick = () => {
      if (!isAuthenticated()) {
        getModalService()?.open({
          templateUrl: 'login.html',
          easyClose: false,
          fitcontent: true,
        })
        return;
      }
      handleDataEvent(isLikedByLoggedUser.value ? 'unlike' : 'like');
      likeTree()
    }
    const menuTrigger = ref<HTMLElement>()

    const showActions = ref(false)


    const onGift = () => {
      return emit('gift', props.tree)
    }

    return {
      country,
      countryFlag,
      countryName,
      countryUrl,
      expanded,
      status,
      image,
      relationsData,
      scientificName,
      likesCount,
      isLikedByLoggedUser,
      onLikeClick,
      showActions,
      isMobile,
      isMine,
      isGifting,
      canBenGifted,
      handleDataEvent,
      onGift,
      initialUserInfo,
      menuTrigger,
      canEditName,
      editing,
      initialUserUrl
    }
  }
})
