



















import { Vue, Component, Prop } from 'vue-property-decorator'
import { TREE_DIARY_COMMENT_ADD, TREE_DIARY_COMMENT_REPLY_ADD } from '@f/graphql/mutations'
import { ApolloCache } from '@apollo/client/cache'
import { TrUser } from '@f/@types/graphql'
import { getImageAsset } from '@f/utils'

type CommentTypename = '' | 'TreeDiaryComment' | 'TreeDiaryCommentReply'

@Component({
  name: "CommentInput"
})
export default class CommentInput extends Vue {
  @Prop({ type: [Number, String], default: null }) readonly postId!: number | string
  @Prop({ type: [Number, String], default: null }) readonly parentId!: number | string | null

  user = null
  editingComment: { message?: string } = {}
  savingComment = {}
  typename: CommentTypename = ''

  // Computed
  get avatarUrl(): string {
    return (
      this.$rootScope
      && this.$rootScope.userdata
      && this.$rootScope.userdata.info
      && this.$rootScope.userdata.info.picture
    ) ? `url(${getImageAsset(this.$rootScope.userdata.info.picture?.toString(), 'small')}` : 'none'
  }

  get saveMutation() {
    return this.typename === 'TreeDiaryComment' ? TREE_DIARY_COMMENT_ADD : TREE_DIARY_COMMENT_REPLY_ADD
  }

  get saveVariables() {
    return (this.typename === 'TreeDiaryComment') ? {
      comment: {
        treeDiaryItemId: this.postId,
        ...this.editingComment
      }
    } : {
      reply: {
        parentId: this.parentId,
        ...this.editingComment
      }
    }
  }

  // Events
  mounted() {
    this.typename = (this.postId) ? 'TreeDiaryComment' : 'TreeDiaryCommentReply'
  }

  // Methods
  saveComment(mutation: Function) {
    if (!this.$rootScope.user) return this.openLogin()
    this.savingComment = { ...this.editingComment }
    this.editingComment = {}
    mutation()
  }

  emitUpdate(store: ApolloCache<any>, response: any): void {
    this.$emit('update', store, response)
  }

  emitSaved(): void {
    this.$emit('saved', { ...this.savingComment })
    this.editingComment = {}
    this.savingComment = {}
  }

  emitError(): void {
    this.editingComment = { ...this.savingComment }
    this.savingComment = {}
    this.$emit('error')
  }

  // TODO: temp fix to access modal login via angularjs
  openLogin(): void {
    const ngModalService = this.ngService('Modal')
    if (ngModalService) ngModalService.open({templateUrl: 'login.html', easyClose: false, fitcontent: true})
  }
}
