



















































import { defineComponent, ref, computed, PropType } from '@vue/composition-api'
import BaseInput from '@f/components/Form/BaseInput.vue'
import DotLoader from '@f/components/DotLoader.vue'
import Modal from '@f/components/Modal.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import { useEditTreeMessages } from '@f/composables/useEditTree'
import useAuthentication from '@f/composables/useAuthentication'
import { TrTree } from '@f/@types/graphql'
import TreeEditMessageModal from '@f/components/Tree/TreeEditMessageModal.vue'
import ReadMoreModal from '@f/components/Forest/ReadMoreModal.vue'

export default defineComponent({
  name: 'TreeMessages',
  components: {
    TreeEditMessageModal,
    BaseInput,
    DotLoader,
    Modal,
    Treecon,
    ReadMoreModal,
  },
  props: {
    tree: Object as PropType<TrTree>,
  },
  setup(props) {
    const openEditModal = ref(false)
    const { visibleMessage } = useEditTreeMessages(props.tree)
    const message = computed(() => visibleMessage(props.tree?.messages || []))
    const { isLoggedAs } = useAuthentication()
    const canRead = computed(() => {
      const isMine = isLoggedAs(props.tree?.user?.id!)
      const iAmTheInitialUser = isLoggedAs(props.tree?.initialUser?.id!)
      const isPrivate = message.value?.isPrivate
      if (isMine || iAmTheInitialUser) return true
      return !isPrivate
    })
    const canWriteMessages = computed(
      () => props.tree?.user && isLoggedAs(props.tree.user?.id)
    )

    const enableReadMore = computed(() => {
      return (
        message.value &&
        message.value.message &&
        message.value.message.length > 180
      )
    })

    return {
      canWriteMessages,
      message,
      openEditModal,
      canRead,
      enableReadMore,
    }
  },
  data(): {
    showMessageReadMore: boolean
  } {
    return {
      showMessageReadMore: false,
    }
  },
})
