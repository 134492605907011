import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  isProductDiscounted,
  isOriginalPriceVisible,
  isPriceHighlighted,
  isProductSoldOut,
  isProductNew,
  price,
  productFullPrice,
  productName,
} from '@f/utils/product'
import { Product } from '@f/@types'
import { Price, Product as NewProduct } from '../services/NewEcommerce/types'

import { formatCurrency, formatDate } from '@f/utils'
import { extractPrice } from '../utils/newEcommerce'

@Component
export default class ShowProducts extends Vue {
  @Prop({ type: Object, required: true }) readonly product!: Product

  get iconUrl(): string {
    const iconSource = this.product?.limited || this.product?.package || this.product?.specie || null
    return iconSource?.icon ? iconSource.icon?.medium || '' : ''
  }

  get title(): string {
    return this.product && this.$t(productName(this.product))
  }

  get isDiscounted(): boolean {
    return isProductDiscounted(this.product)
  }

  get showsOriginalPrice(): boolean {
    return isOriginalPriceVisible(this.product, this.$rootScope.userType === "Business")
  }

  get highlightPrice(): boolean {
    return isPriceHighlighted(this.product, this.$rootScope.userType === "Business")
  }

  get isSoldOut(): boolean {
    return isProductSoldOut(this.product)
  }

  get isNew(): boolean {
    return isProductNew(this.product)
  }

  get price(): string {
    return formatCurrency(extractPrice((this.product as unknown as NewProduct).prices as Price[]), this.$rootScope.ln)
  }

  get fullPrice(): string {
    return productFullPrice(this.product, this.$rootScope.ln)
  }

  get badge(): string {
    return this.product.badge ? this.$t(`specie.badge.${this.product.badge}`, {
      numberTrees: this.product.onHand, // used by showAvailability badge label
      percentage: this.product.badgeDiscount, // used by discount badge label
      date: this.product.badgeDate && formatDate(this.product.badgeDate, this.$rootScope.ln, 'L'), // used by comingSoon and until badge labels
    }) : ''
  }

  get packageProductQuantity(): number {
    const count = this.product.package
      && (this.product.package.products || [])
        .reduce((total: number, product: any) =>  {
          return total + Number(product.quantity)
        }, 0) || 1
    return count
  }
}
