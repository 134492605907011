import { apiClient as gqlClient } from '@f/apollo'
import { Product, SubscriptionPlan } from '../types'
import { CANCEL_SUBSCRIPTION, EDIT_SUBSCRIPTION_PAYMENT } from './mutations'
import { GET_SUBSCRIPTIONS } from './queries'

interface ICancelSubscriptionArgs {
    subscriptionId: string
}

interface IEditSubscriptionPaymentArgs {
    subscriptionId: string
    paymentMethodNonce: string
}

interface SubscriptionsService {
    cancelSubscription: ({subscriptionId}: ICancelSubscriptionArgs) => PromiseLike<boolean>
    editSubscriptionPayment: ({subscriptionId, paymentMethodNonce}: IEditSubscriptionPaymentArgs) => PromiseLike<{id: string}>
    getSubscriptions: () => PromiseLike<Product[]>
}

const subscriptionsService = (
): SubscriptionsService => {
  return {
    cancelSubscription: async (cancelSubscriptionParams) => {
        const response = await gqlClient.mutate<boolean>({
            mutation: CANCEL_SUBSCRIPTION,
            variables: { ...cancelSubscriptionParams },
          })

          return response.data!
    },
    editSubscriptionPayment:  async (editSubscriptionPaymentParams) => {
        const response = await gqlClient.mutate<{id: string}>({
            mutation: EDIT_SUBSCRIPTION_PAYMENT,
            variables: { ...editSubscriptionPaymentParams },
          })

          return response.data!
    },
    getSubscriptions: async () => {
        const response = await gqlClient.query<{products_collections: {
            products: Product[]
        }[]}>({
            query: GET_SUBSCRIPTIONS,
            variables: {
                collections: ['treedom_plans']
            }
          })

          return response.data!.products_collections[0].products
    },
  }
}

export { subscriptionsService }
