


















































































import { computed, defineComponent, provide, ref, watch } from '@vue/composition-api'
import DotLoader from '@f/components/DotLoader.vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apiClient } from '@f/apollo'
import Treecon from '@f/components/Treecons/Treecon.vue'
import useRoutes from '@f/composables/useRoutes'
import Map from '@f/components/Map/Map.vue'
import HUDNavigator from '@f/components/Map/HUD/HUDNavigator.vue'
import fillPage from '@f/composables/fillPage'
import { fetchOrganizationData } from '@f/composables/fetchUserData'
import HUDForestTrees from '@f/components/Map/HUD/HUDForestTrees.vue'
import HUDUserForests from '@f/components/Map/HUD/HUDUserForests.vue'
import { Event } from '@f/@types'
import HUDUserForestTrees from '@f/components/Map/HUD/HUDUserForestTrees.vue'
import MapClusterMarkers from '@f/components/Map/MapClusterMarkers.vue'
import { fetchForest } from '@f/services/Forest'
import HUDTreeCard from '@f/components/Map/HUD/HUDTreeCard.vue'
import { FlyToOptions, Map as Mapbox } from 'mapbox-gl'
import TextTruncate from '@f/components/TextTruncate.vue'
import MapTreeMarker from '@f/components/Map/MapTreeMarker.vue'
import { Maybe, TrCountry, TrUserCountrySummary } from '@f/@types/graphql'
import HUDOrganizationCountries from '@f/components/Map/HUD/HUDOrganizationCountries.vue'
import { translate } from '@f/services/Translation'
import { isLoggedInAs } from '@f/services/Auth'
import HUDOrganizationCountryTrees from '@f/components/Map/HUD/HUDOrganizationCountryTrees.vue'
import OrganizationMapHUDForestTree from '@f/pages/Organization/partials/OrganizationMapHUDForestTree.vue'
import OrganizationMapHUDCountryTree from '@f/pages/Organization/partials/OrganizationMapHUDCountryTree.vue'

export default defineComponent({
  name: 'OrganizationMapPage',
  components: {
    OrganizationMapHUDCountryTree,
    OrganizationMapHUDForestTree,
    HUDOrganizationCountryTrees,
    HUDOrganizationCountries,
    MapTreeMarker,
    TextTruncate,
    HUDTreeCard,
    MapClusterMarkers,
    HUDUserForestTrees,
    HUDUserForests,
    HUDForestTrees,
    HUDNavigator,
    Map,
    Treecon,
    DotLoader,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    provide(DefaultApolloClient as any, apiClient)
    const { container } = fillPage()
    const mapRef = ref<Mapbox>()
    const { href, go, isCurrentState, currentParams, currentState } = useRoutes()
    const isMapEventsList = computed(() => isCurrentState("organization.map.events", undefined, true))
    const isMapEvent = computed(() => isCurrentState("organization.map.events.item", undefined, true))
    const isMapEventTree = computed(() => isCurrentState("organization.map.events.item.tree", undefined, true))
    const isMapCountriesList = computed(() => isCurrentState("organization.map.countries", undefined, true))
    const isMapCountry = computed(() => isCurrentState("organization.map.countries.item", undefined, true))
    const isMapCountryTree = computed(() => isCurrentState("organization.map.countries.item.tree", undefined, true))
    const { user, forests } = fetchOrganizationData(props.slug)
    const userCountries = computed<Maybe<TrUserCountrySummary>[]>(() => user.value?.summary?.countries || [])
    const userId = computed<number| undefined>(() => Number(user.value?.info?.id) || undefined)
    const loading = computed(() => !user.value || !forests.value)
    const userName = computed(() => user.value?.info.firstname)
    const eventSlug = computed(() => currentParams.value?.eventSlug)
    const countrySlug = computed(() => currentParams.value?.countrySlug)
    const event = ref<Event>()
    const eventId = computed(() => Number(event.value?.id) || null)
    const treeId = computed(() => currentParams.value?.treeId)
    const isMine = computed(() => isLoggedInAs(user.value!.info))
    watch(eventSlug, async (forestSlug) => {
      if (forestSlug) {
        event.value = await fetchForest(forestSlug, true)
        return
      }
      event.value = undefined
    }, {
      immediate: true,
    })
    watch(currentState, () => {
      mapRef.value?.resize()
    }, {
      immediate: true,
    })
    const country = computed<TrCountry | null>(() => {
      if (!countrySlug.value) return null;
      return userCountries.value?.find((summary) => summary?.country?.slug === countrySlug.value)?.country! as TrCountry
    })
    const title = computed(() => translate('organization.map.forests.title', {
      userName: userName.value,
    }))
    const countriesTitle =  computed(() => {
      if (!user.value?.info) return ''
      return translate(
        'map.user.countries.title',
        {
          name: user.value!.info.firstname,
        },
        Number(user.value!.info && isLoggedInAs(user.value!.info))
      )
    })
    const treesCountryTitle = computed(() => {
      if (!user.value?.info) return ''
      return translate(
        'map.user.country.title',
        {
          name: user.value!.info.firstname,
          country: translate(country.value?.name!)
        },
        Number(user.value!.info && isLoggedInAs(user.value!.info))
      )
    })
    const recenter = (options: FlyToOptions) => {
      mapRef.value?.flyTo(options)
    }
    const clusterCenterOptions = computed(() => {
      if (isMapCountry.value) {
        return {
          zoom: Math.max(4, mapRef.value?.getZoom() || 0),
          center: [country.value?.lng, country.value?.lat],
        }
      }
      return {
        zoom: 0,
        center: [-30, 0],
      }
    })
    const goToTree = (id: string) => go('.tree', { treeId: id })
    return {
      loading,
      href,
      isCurrentState,
      container,
      userId,
      userName,
      eventId,
      eventSlug,
      event,
      forests,
      title,
      countriesTitle,
      treesCountryTitle,
      country,
      treeId,
      mapRef,
      recenter,
      isMapEventsList,
      isMapEvent,
      isMapEventTree,
      isMapCountriesList,
      isMapCountry,
      isMapCountryTree,
      userCountries,
      clusterCenterOptions,
      isMine,
      goToTree,
    }
  }
})
