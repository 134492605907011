angular.module('ngApp')
  .controller('openForestsPresentationController', ['$scope', '$rootScope', '$state', '$stateParams', 'Event', 'User', function($scope, $rootScope, $state, $stateParams, Event, User) {

    User.getUserEventInfo($stateParams.eventslug)
    .then(function (response) {
      $scope.event = response
      $scope.getInfoTrees()
      var userType = response.user.type === 'Business' ? 'organization' : 'user'
      $scope.link = $rootScope.baseHtml5Url + $rootScope.ln + '/' + userType + '/' + response.user.slug + '/event/' + response.slug + '/redeem'
      window.QRCode.toCanvas(canvas, $scope.link, function (error) {
        if (error) console.error(error)
      })
    })

    $scope.getInfoTrees = function () {
      Event.getInfoOpenForest($stateParams.eventslug)
      .then(function (response) {
        $scope.infoTrees = response
      })
    }


    $scope.close = function() {
      if ($rootScope.statusHistory && $rootScope.statusHistory.previous) {
        $rootScope.goBack()
      } else {
        $state.go('index')
      }
    }
  }])
