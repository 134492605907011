import { DataEventDownload } from '@f/services/TagManager/events';
import Swiper from 'swiper'

angular.module('ngApp')
.controller('projectsController', ['$scope', '$rootScope', '$timeout', '$filter', 'Modal', function ($scope, $rootScope, $timeout, $filter, Modal) {
  var ctrl = this;

  const registryLink = $rootScope.state.href('page.register');

  ctrl.projects = $rootScope.projects;
  ctrl.numProjects = $rootScope.projects ? $rootScope.projects.length : 0;
  ctrl.countriesSlider = null;
  ctrl.chooseCountrySlider = null;
  ctrl.benefitsEnvironment = [
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/02_benefici/benefici-04.png'),
      text: $filter('trans')('farmers.benefits.environment.box_01')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/02_benefici/benefici-05.png'),
      text: $filter('trans')('farmers.benefits.environment.box_02')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/02_benefici/benefici-06.png'),
      text: $filter('trans')('farmers.benefits.environment.box_03')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/02_benefici/benefici-07.png'),
      text: $filter('trans')('farmers.benefits.environment.box_04')
    }
  ];
  ctrl.benefitsEconomic = [
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/02_benefici/benefici-09.png'),
      text: $filter('trans')('farmers.benefits.economic.box_02')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/02_benefici/benefici-10.png'),
      text: $filter('trans')('farmers.benefits.economic.box_03')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/02_benefici/benefici-11.png'),
      text: $filter('trans')('farmers.benefits.economic.box_04')
    }
  ];
  ctrl.SustainableDevelopmentGoals = [
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_1.svg')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_2.svg')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_3.svg')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_4.svg')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_5.svg')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_6.svg')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_7.svg')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_8.svg')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_9.svg')
    },
    {
      image: $filter('asset')('bundles/treedomnewfrontend/images/project/03_goals/goals_10.svg')
    }
  ];
  ctrl.valueChain = [
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-01.svg'),
      text: $filter('trans')('farmers.valueChain.step01')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-02.svg'),
      text: $filter('trans')('farmers.valueChain.step02')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-03.svg'),
      text: $filter('trans')('farmers.valueChain.step03')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-04.svg'),
      text: $filter('trans')('farmers.valueChain.step04')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-05.svg'),
      text: $filter('trans')('farmers.valueChain.step05')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-06.svg'),
      text: $filter('trans')('farmers.valueChain.step06')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-07.svg'),
      text: $filter('trans')('farmers.valueChain.step07')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-08.svg'),
      text: $filter('trans')('farmers.valueChain.step08')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-09.svg'),
      text: $filter('trans')('farmers.valueChain.step09')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-10.svg'),
      text: $filter('trans')('farmers.valueChain.step10')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-11.svg'),
      text: $filter('trans')('farmers.valueChain.step11')
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-12.svg'),
      text: $filter('trans')('farmers.valueChain.step12', {
        url: registryLink
      }),
    },
    {
      icon: $filter('asset')('bundles/treedomnewfrontend/images/project/04_prezzo/valueChainStep-13.svg'),
      text: $filter('trans')('farmers.valueChain.step13')
    }
  ];
  ctrl.benefitsEnvironmentSlider = null;
  ctrl.benefitsEconomicSlider = null;
  ctrl.SustainableDevelopmentGoalsSlider = null;
  ctrl.valueChainSlider = null;

  // Swiper countries
  ctrl.renderCountriesSwiper = function () {
    if (ctrl.countriesSlider) {
      !Array.isArray(ctrl.countriesSlider)
        ? ctrl.countriesSlider.destroy(true, true)
        : ctrl.countriesSlider.forEach(function (s) { s.destroy(true, true) })
    }

    if (!Object.keys(ctrl.projects).length) return;

    ctrl.countriesSlider = new Swiper('#SwiperCountries', {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 30,
      nextButton: '#CountriesButtonNextSlider',
      prevButton: '#CountriesButtonPrevSlider',
      pagination: '#CountriesSliderPagination',
      paginationClickable: true,
      autoplay: 3000,
      autoplayStopOnLast: false,
      autoplayDisableOnInteraction: false,
      grabCursor: true
    })
  };

  // Stop swiper autoplay
  ctrl.stopAutoPlay = function () {
    if (!ctrl.countriesSlider) return;
    ctrl.countriesSlider.stopAutoplay()
  };

  // Start swiper autoplay
  ctrl.startAutoPlay = function () {
    if (!ctrl.countriesSlider) return;
    ctrl.countriesSlider.startAutoplay()
  };

  // Swiper originators
  ctrl.renderChooseCountrySwiper = function () {
    if (ctrl.chooseCountrySlider) {
      !Array.isArray(ctrl.chooseCountrySlider)
        ? ctrl.chooseCountrySlider.destroy(true, true)
        : ctrl.chooseCountrySlider.forEach(function (s) { s.destroy(true, true) })
    }

    if (!Object.keys(ctrl.projects).length) return;

    ctrl.chooseCountrySlider = new Swiper('#SwiperChooseCountry', {
      slidesPerView: 3,
      // centeredSlides: true,
      spaceBetween: 30,
      slidesPerGroup: 3,
      nextButton: '#ChooseCountryButtonNextSlider',
      prevButton: '#ChooseCountryButtonPrevSlider',
      pagination: '#ChooseCountrySliderPagination',
      paginationClickable: true,
      grabCursor: true,
      breakpoints: {
        // when window width is <= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 10,
          slidesPerGroup: 1,
          centeredSlides: true
        },
        // when window width is <= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1,
          centeredSlides: true
        },
        991: {
          slidesPerView: 1,
          spaceBetween: 30,
          slidesPerGroup: 1,
          centeredSlides: true
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 30,
          slidesPerGroup: 1,
          centeredSlides: true
        }
      }
    })
  };
  $timeout(function () {
    ctrl.renderChooseCountrySwiper()
  });


  // Swiper benefitsEnvironment
  ctrl.renderBenefitsEnvironmentSwiper = function () {
    if (ctrl.benefitsEnvironmentSlider) {
      !Array.isArray(ctrl.benefitsEnvironmentSlider)
        ? ctrl.benefitsEnvironmentSlider.destroy(true, true)
        : ctrl.benefitsEnvironmentSlider.forEach(function (s) { s.destroy(true, true) })
    }

    ctrl.benefitsEnvironmentSlider = new Swiper('#SwiperBenefitsEnvironment', {
      slidesPerView: 2,
      // centeredSlides: true,
      spaceBetween: 30,
      slidesPerGroup: 1,
      centeredSlides: true,
      nextButton: '#BenefitsEnvironmentButtonNextSlider',
      prevButton: '#BenefitsEnvironmentButtonPrevSlider',
      pagination: '#BenefitsEnvironmentSliderPagination',
      paginationClickable: true,
      grabCursor: true,
      // Responsive breakpoints
      breakpoints: {
        // when window width is <= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1,
          centeredSlides: true
        }
      }
    })
  };
  $timeout(function () {
    ctrl.renderBenefitsEnvironmentSwiper()
  });

  // Swiper benefitsEnvironment
  ctrl.renderBenefitsEconomicSwiper = function () {
    if (ctrl.benefitsEconomicSlider) {
      !Array.isArray(ctrl.benefitsEconomicSlider)
        ? ctrl.benefitsEconomicSlider.destroy(true, true)
        : ctrl.benefitsEconomicSlider.forEach(function (s) { s.destroy(true, true) })
    }

    ctrl.benefitsEconomicSlider = new Swiper('#SwiperBenefitsEconomic', {
      slidesPerView: 2,
      // centeredSlides: true,
      spaceBetween: 30,
      slidesPerGroup: 1,
      centeredSlides: true,
      nextButton: '#BenefitsEconomicButtonNextSlider',
      prevButton: '#BenefitsEconomicButtonPrevSlider',
      pagination: '#BenefitsEconomicSliderPagination',
      paginationClickable: true,
      grabCursor: true,
      // Responsive breakpoints
      breakpoints: {
        // when window width is <= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1,
          centeredSlides: true
        }
      }
    })
  };
  $timeout(function () {
    ctrl.renderBenefitsEconomicSwiper()
  });

  // Swiper valueChain
  ctrl.renderValueChainSwiper = function () {
    if (ctrl.valueChainSlider) {
      !Array.isArray(ctrl.valueChainSlider)
        ? ctrl.valueChainSlider.destroy(true, true)
        : ctrl.valueChainSlider.forEach(function (s) { s.destroy(true, true) })
    }

    ctrl.valueChainSlider = new Swiper('#SwiperValueChain', {
      slidesPerView: 1,
      // centeredSlides: true,
      spaceBetween: 30,
      slidesPerGroup: 1,
      nextButton: '#ValueChainButtonNextSlider',
      prevButton: '#ValueChainButtonPrevSlider',
      pagination: '#ValueChainSliderPagination',
      paginationClickable: true,
      grabCursor: true,
      // Responsive breakpoints
      breakpoints: {
        // when window width is <= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 10,
          slidesPerGroup: 1,
          centeredSlides: true
        },
        // when window width is <= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1,
          centeredSlides: false
        }
      }
    })
  };
  $timeout(function () {
    ctrl.renderValueChainSwiper()
  });

  // Filter number for compile
  ctrl.numberFilter = function (number) {
    return $filter('numberFormat')(number)
  };

  // Watcher on variables $rootScope.projects
  $rootScope.$watch('projects', function (projects) {
    ctrl.projects = projects;
    ctrl.numProjects = $rootScope.projects ? $rootScope.projects.length : 0
  })

  $scope.openProposalDisclaimerModal = function(event) {
    event && event.preventDefault && event.preventDefault();
    Modal.open({ templateUrl: "modalProposalDisclaimer.html" });
  }

  $scope.downloadDoc = function(type, fileName) {
    DataEventDownload(type, fileName, window.location.pathname)
  }
}])
