import { ValidateFunction } from 'ajv'
import Logger from '@f/services/Logger'
import { validateAsync } from '@f/validation'
import { datadogRum } from '@datadog/browser-rum'

// TODO: Evaluate the chance for a stricter typing that still don't "fool the code" assuming false presumptions
interface DataLayerData extends Record<string, any> {
  event?: string
}

class TagManagerService {

  constructor() {
    // TODO: Evaluate the possibility to handle tag manager snippet injection via the service
  }

  get dl() {
    return Array.isArray(window.dataLayer)
      ? window.dataLayer
      : (window.dataLayer = [])
  }

  get anonymize() {
    return !!window.gaAnonymize
  }

  handlePush(data: DataLayerData) {
    if (data.event) {
      datadogRum.addAction(`Push GTM Event: ${data.event}`, data)
    }
    this.dl.push(data)
  }

  validate(data: DataLayerData, validation: ValidateFunction) {
    validateAsync(validation, data).catch(errors => {
      this.logErrors(errors);
    })
  }

  logErrors(err: any) {
    Logger.error('DataLayer push error', err)
    datadogRum.addError('DataLayer push error', err)
  }

  /**
   * @deprecated Legacy method used previously through AngularJS TagManager service
   */
  legacyPush(eventObj: DataLayerData) {
    const dataToPush: any[] = [];
    if (typeof eventObj.contents !== 'undefined') {
      dataToPush.push({contents: eventObj.contents})
    }
    if (typeof eventObj.contentType !== 'undefined') {
      dataToPush.push({contentType: eventObj.contentType})
    }
    if (typeof eventObj.purchaseValue !== 'undefined') {
      dataToPush.push({purchaseValue: eventObj.purchaseValue})
    }
    if (typeof eventObj.cartId !== 'undefined') {
      dataToPush.push({'cartId': eventObj.cartId})
    }
    if (typeof eventObj.subscription !== 'undefined') {
      dataToPush.push({'subscription': undefined})
      dataToPush.push({'subscription': eventObj.subscription})
    }
    else{
      dataToPush.push(eventObj)
    }
    window.consentPromise.then(() => {
      if (eventObj.event) {
        datadogRum.addAction(`Push GTM Event: ${eventObj.event}`, eventObj)
      }
      this.dl.push(...dataToPush)
    })
  }

  push(data: DataLayerData | (() => DataLayerData), validation?: ValidateFunction) {
    try {
      window.consentPromise.then(() => {
        const dataToPush = typeof data === 'function' ? data() : data;
        this.handlePush(dataToPush);
        if (validation) {
          this.validate(dataToPush, validation)
         }
      })
      
    } catch(err) {
      this.logErrors(err)
    }
  }
}

const tagManagerService = new TagManagerService()

export default tagManagerService;