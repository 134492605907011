angular.module('ngApp')
  .controller('cartPowersController', ['$scope', '$rootScope', '$timeout', 'Utility', function ($scope, $rootScope, $timeout, Utility) {

    $scope.powers = {};

    $scope.width = $scope.attrs.width;
    $scope.duration = $scope.attrs.duration || 1000;
    $scope.background = $scope.attrs.background || '#ffffff';
    $scope.max = $scope.attrs.max || 10;
    $scope.radius = $scope.width / 2 + 'px';

    $scope.barWrapperStyle = {
      borderRadius: $scope.radius,
      backgroundColor: $scope.background,
      padding: '0 ' + $scope.radius,
      width: 'calc(100% - ' + $scope.width + 'px)'
    };

    $timeout(function() {
      updatePowers()
    });

    $rootScope.$on('userdata.cart', function() {
      updatePowers()
    });

    $rootScope.$on('userdata.cart.update', function() {
      updatePowers()
    });

    var updatePowers = function() {
      var cartHasItems = ($rootScope.userdata.cart && $rootScope.userdata.cart.items);
      $scope.powers = (cartHasItems)
              ? Utility.getPowers($rootScope.userdata.cart.items)
              : Utility.getEmptyPowers()
    }

  }]);
