



import { Vue, Component, Prop } from 'vue-property-decorator'
import VimeoPlayer from '@vimeo/player'
import { getRootScope } from '@f/services/Angular'
import { initYTPlayer } from '@f/services/YouTube'
import { DataEventVideo } from '@f/services/TagManager/events'

const VIDEO_PROVIDERS = {
  vimeo: {
    test(url: string) {
      return /^https?:\/\/(w{3}\.)?vimeo\.com\/(\d+)/.test(url)
    },
    initPlayer(el: Element, url: string, options: Record<string, any> = {}) {
      const defaultSettings = { title: false, byline: false, portrait: false }
      const VPPlayer = new VimeoPlayer(el as HTMLElement, {
        ...defaultSettings,
        ...options,
        url,
      })

      VPPlayer.on('play', () => DataEventVideo('1', url))
      VPPlayer.on('ended', () => DataEventVideo('0', url))
      VPPlayer.on('bufferend', () => DataEventVideo('3', url))
      VPPlayer.on('pause', () => DataEventVideo('2', url))
      return VPPlayer
    },
  },
  youtube: {
    test(url: string) {
      return /^https?:\/\/(w{3}\.)?youtube\.com\/.*v=\w*/.test(url)
    },
    initPlayer(el: Element, url: string, options: Record<string, any> = {}) {
      const rootScope = getRootScope() || null
      const playerOptions = {
        ...options,
        playerVars: {
          hl: rootScope?.ln || 'en',
        },
        events: {
          onStateChange: ({ data }: any) => {
            DataEventVideo(String(data), url)
          },
        },
      }
      initYTPlayer(el as HTMLElement, url, playerOptions)
    },
  },
}
type Provider = keyof typeof VIDEO_PROVIDERS

@Component({
  name: 'VideoPlayer',
})
export default class VideoPlayer extends Vue {
  @Prop({ type: String, required: true }) readonly url!: string

  provider: Provider | null = null
  settings: any = null

  // Computed
  get containerRef(): string {
    return `video-container-${this._uid}`
  }

  // Events
  mounted() {
    this.provider = this.findProvider(this.url)
    if (this.provider)
      VIDEO_PROVIDERS[this.provider].initPlayer(
        this.$refs[this.containerRef] as Element,
        this.url
      )
  }

  // Methods
  findProvider(url: string): any {
    return Object.keys(VIDEO_PROVIDERS).find((provider) =>
      VIDEO_PROVIDERS[provider as Provider].test(url)
    )
  }
}
