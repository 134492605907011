import { IProfileInfoEdited, IProfileInfoCompleted, IDLProfileInteractionContent } from '@f/validation/types/dataLayer';
import { validateProfileInfoEdited, validateProfileInfoCompleted, validateProfileActivityInteraction } from '@f/validation/datalayer'
import tagManagerService from '@f/services/TagManager'
import { getKeyObjectDiff } from '../eventUtils';

export function DataEventProfileInfoEdited(
    fieldEdited: Record<string,any>[] | String[] | string,
    profileCategory: string, 
    profileAction: string,
) {

  const fields = Array.isArray(fieldEdited) 
    ? getKeyObjectDiff(fieldEdited[0], fieldEdited[1])
    : fieldEdited || ''

  tagManagerService.push(<Omit<IProfileInfoEdited, 'event'>>{
    event: 'profileInfoEdited',
    fieldEdited: fields,
    profileCategory,
    profileAction,
  }, validateProfileInfoEdited)
}

export function DataEventProfileInfoCompleted(submitted: boolean) {
    tagManagerService.push(<Omit<IProfileInfoCompleted, 'event'>>{
        event: 'profileInfoCompleted',
        profileAction: submitted ? 'submitted' : 'skip'
    }, validateProfileInfoCompleted )
}

export function DataEventProfileActivity(content: IDLProfileInteractionContent, action: string | null, element: string | null, isOrganization: boolean = false) {
  tagManagerService.push({
      event: 'profileActivity',
      content,
      action,
      element,
      isOrganization
  }, validateProfileActivityInteraction)
}