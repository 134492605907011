import { IForestInfoEdited, ISelectionForest, ISelectionTrees } from '@f/validation/types/dataLayer';
import tagManagerService from '@f/services/TagManager';
import { validateBeginForest, validateForestInfoEdited, validateSelectionForest, validateSelectionTrees } from '@f/validation/datalayer';


export function DataEventBeginForest() {
    tagManagerService.push({
        event: 'beginForest',
    }, validateBeginForest)
}

export function DataEventRestartForest(idForest: number) {
    tagManagerService.push({
        event: 'restartForestCreation',
        idForest
    })
}

export function DataEventSelectionForest(forestEventData: ISelectionForest) {
    tagManagerService.push({
        event: 'selectionForest',
        ...forestEventData
    }, validateSelectionForest)
}

export function DataEventSelectionTrees(forestEventData: ISelectionTrees) {
    tagManagerService.push({
        event: 'selectionTrees',
        ...forestEventData
    }, validateSelectionTrees)
}

export function DataEventForestInfoEdited(forestEventData: IForestInfoEdited) {
    tagManagerService.push({
        event: 'forestInfoEdited',
        ...forestEventData
    }, validateForestInfoEdited)
} 

export function DataEventPublishedForest(idForest: number) {
    tagManagerService.push({
        event: 'forestPublished',
        idForest
    })
}

export function DataEventStandByForest(idForest: number) {
    tagManagerService.push({
        event: 'standByForest',
        idForest
    })
}

export function DataEventDeletedForest(idForest: number) {
    tagManagerService.push({
        event: 'forestDeleted',
        idForest
    })
}

export function DataEventEditForest(idForest: number) {
    tagManagerService.push({
        event: 'forestEdited',
        idForest
    })
}

export function DataEventEditForestPersonalization(idForest: number) {
    tagManagerService.push({
        event: 'forestEditedPersonalization',
        idForest
    })
}