import template from './product-qt-card-template.html'
import controller from './product-qt-card-controller'

const bindings = {
  product: '<',
  onClick: '&',
  onChange: '&',
  template: '@',
  quantity: '<'
}

angular.module('ngApp')
  .component('productQtCard', {
    template,
    controller,
    bindings
  })
