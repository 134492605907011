import Swiper from 'swiper'

angular.module('ngApp')
.controller('redeemCouponFlowController', ['$rootScope', '$scope', '$timeout', '$state', 'Modal', 'Tree', 'User', 'Utility', '$filter', 'ipCookie', 'Routing', '$location', 'Ecommerce', function ($rootScope, $scope, $timeout, $state, Modal, Tree, User, Utility, $filter, ipCookie, Routing, $location, Ecommerce) {
  var $ = window.$

  // Tutti gli steps, attivi o non attivi, passati da backend
  $scope.initialSteps = false
  // Steps da effettuare
  $scope.steps = false
  $scope.code = ''
  // Step attuale
  $scope.currentStep = false
  // Errori
  $scope.error = false
  $scope.errorMessage = false
  // Oggetto con le informazioni del coupon attivo
  $scope.infoCoupon = false
  $scope.showCheckboxAuthorizePersonalData = true
  // Impostazioni per login/signup
  $rootScope.loginType = 'sign-up'
  $rootScope.redirectState = $state.current.name
  $rootScope.authorizePersonalData = undefined
  $rootScope.closeModalAfterLogin = true
  // Albero attivo in caso di giftpicker
  $scope.giftpickerActiveTree = undefined
  $scope.giftPickerTreesSlider = null
  // Nasconde il pulsante di riscatto una volta cliccato e mostra il loading
  $scope.redeeming = false
  $scope.analyzing = false

  /**
   * Ascoltatore sulla variabile $scope.steps. Quandi gli step vengono settati,
   * questo watcher imposta automaticamente (la prima volta) il primo step
   * come 'active'
   */
  $scope.$watch('steps', function (updatedSteps) {
    if (updatedSteps && !$scope.currentStep) {
      $scope.currentStep = updatedSteps[0]
    }
  })

  /**
   * Ascoltatore sulla variabile $rootScope.steps. L'utente di trova nella tab di
   * login/signup, se esegue l'accesso o si registra, vado al prossimo step
   */
  $rootScope.$watch('userdata.info', function (updatedUser) {

    if ($scope.currentStep.name === 'login') {
      if (updatedUser) {

        $scope.nextStep()
        startRedeemCouponFlow ($state.params.id || $scope.code)
        // Show or hide checkbox
        if (updatedUser.authorizeDataProcessing) {
          $scope.showCheckboxAuthorizePersonalData = false
        }
      }
    }
  })

  var startRedeemUpdateSteps = function () {
      updateStepsObject($scope.initialSteps)
      if (!$scope.steps) activeStep($scope.initialSteps)
      calculateSizeButtons()
  };

  /**
   * Funzione che calcola gli step del riscatto
   * @param {string} code - Codice del coupon, se presente.
   */
  function startRedeemCouponFlow (code, userSlug, eventSlug) {
    $scope.analyzing = true
    Tree.redeemCouponFlow(code, userSlug, eventSlug)
    .then(function (response) {
      $scope.initialSteps = response
      // Check if organization want user data
      $rootScope.authorizePersonalData = checkAuthorizePersonalData($scope.initialSteps)
      var redeemStepIndex = $scope.initialSteps.findIndex(step => step.name === 'redeem');
      if(redeemStepIndex != -1 && $scope.initialSteps[redeemStepIndex].info && $scope.initialSteps[redeemStepIndex].info.trees && $scope.initialSteps[redeemStepIndex].info.trees[0].productId){
          var products =  $scope.initialSteps[redeemStepIndex].info.trees.map(function (_product) {
              return parseInt(_product.productId)
          });
          Ecommerce.getProducts(products, true)
          .then(function (_products) {
              $scope.initialSteps[redeemStepIndex].info.trees = Object.values(_products);
              startRedeemUpdateSteps();
          })

      }
      else {

        startRedeemUpdateSteps();
      }
    })
  }

  /**
   * Check if organization is looking for user personal data.
   * if true, return organization name, else return false
   */
  function checkAuthorizePersonalData (steps) {

    // Show or hide checkbox
    if ($rootScope.userdata) {
      if ($rootScope.userdata.info) {
        if ($rootScope.userdata.info.authorizeDataProcessing) {
          $scope.showCheckboxAuthorizePersonalData = false
        }
      }
    }

    var redeemStepIndex = steps.findIndex(step => step.name === 'redeem')

    if (redeemStepIndex < 0) return undefined

    var redeemStep = steps[redeemStepIndex]

    if (redeemStep.info) {
      return redeemStep.info.giver
        ? redeemStep.info.giver.requestUsersData
          ? { name: redeemStep.info.giver.name, value: false, link: redeemStep.info.giver.privacyPolicyLink }
          : undefined
        : undefined
    }

    return undefined
  }

  var blockClick = false
  $scope.toggleAuthorizePersonalData = function (blockToggle) {
    if (blockClick) return
    if (!$rootScope.user) return

    blockClick = true
    User.userEditProfile({ authorize_data_processing: $rootScope.authorizePersonalData.value })
    .then(function (response) {
      $rootScope.userdata.info.authorizeDataProcessing = response.authorizeDataProcessing
      blockClick = false
    })
  }

  /**
   * Attiva uno step, settando come status 'todo'
   * @param {string} initialSteps - Nome dello step da attivare
   */
  function activeStep (initialSteps) {
    $scope.steps = initialSteps.filter(step => {
      step.status = 'todo'
      return step.isActive
    })
  }

  /**
   * Aggiorna gli oggetti con le informazioni degli steps e/o gli errori.
   * @param {Array} steps - Array degli step da compiere per completare il riscatto.
   */
  function updateStepsObject (steps) {
    steps.forEach(step => {
      // Aggiorno le informazioni del coupon
      if (step.name === 'redeem') {
        $scope.infoCoupon = step.info

      // Controllo gli eventuali errori dell'inserimento del codice
      } else if (step.name === 'insertCode') {
        if (step.info) {
          if (step.info.error) {
            $scope.error = true
            $scope.errorMessage = step.info.error
          }
        } else {
          // Se inserisco il codice dalla tab 'insertCode' e non ho errori vai avanti
          if ($scope.currentStep.name === step.name) {
            $scope.nextStep()
          }
        }
      } else if (step.name === 'intro') {
        if (step.background) {
          var modal = document.getElementById('treedomModalBackground')
          if (modal) {
            modal.style.backgroundImage = 'url("' + $filter('s3')(step.background, 'large') + '")'
            modal.classList.add('treedomModalBackgroundSponsored')
          }
        }
      }
      $scope.analyzing = false
    })
  }

  /**
   * Calcola lo spazio occupato dai titoli degli steps.
   */
  function calculateSizeButtons () {
    $timeout(function () {
      var buttons = document.getElementsByClassName('rc-tab-button')
      buttons.forEach(button => {
        button.style.width = (100 / buttons.length) + '%'
      })
    }, 10)
  }

  /**
   * Vai al prossimo step.
   */
  $scope.nextStep = function () {
    var index = $scope.steps.findIndex(step => step.name === $scope.currentStep.name)

    $scope.steps[index].status = 'done'

    if (index + 1 <= $scope.steps.length) {
      var scrolledTab = document.getElementsByClassName('redeemCouponWrap')[0]
      scrolledTab.scrollTop = 0
      $scope.currentStep = $scope.steps[index + 1]
      $timeout(() => {
        switch ($scope.currentStep.name) {
          case 'insertCode':
            $('#code-input').focus();
            break;
          case 'redeem':
            if ($scope.giftPickerTreesSlider) {
              $scope.giftPickerTreesSlider.update()
              $scope.giftPickerTreesSlider.slideTo(0)
              $scope.giftPickerTreesSlider.startAutoplay()
            }
            break;
        }
      })
    }
  }

  /**
   * Valida i codici inseriti dall'utente.
   * @param {string} code - Codice coupon inserito dall'utente.
   */
  $scope.validateGift = function (code) {
    $scope.error = false
    $scope.code = code

    if (code === '#12345' || code === '12345') {
      activeError('coupon.error.no_rimbo')
      return
    }

    startRedeemCouponFlow(code)
  }

  /**
   * Attiva gli errori da frontend.
   * @param {string} message - Stringa di errore (del traduttore).
   */
  function activeError (message) {
    $scope.error = true
    $scope.errorMessage = message
    if (!$scope.$$phase) $scope.$apply()
  }

  /**
   * Call to action per riscattare l'albero
   * @param {number} specieId - Opzionale. Presente solo se si tratta di un
   * riscatto di tipo 'giftPicker'
   */
  $scope.redeemCoupon = function (productId) {
    // Utility.disableButton()
    $scope.redeeming = true

    var postData = { code: $scope.infoCoupon.code }
    if (productId) postData.product = productId

    Tree.redeemTreeGift(postData)
    .then(function (data) {
      if (data.status) {
        // Se riscatto l'albero nella mia pagina utente, chiudo la modale 'manualmente'.
        if (($state.current.name === 'user' && $rootScope.userdata.info.usertype === 'Private') ||
          ($state.current.name === 'organization' && $rootScope.userdata.info.usertype === 'Business')
        ) {
          $rootScope.closeTreedomModal()
        }

        var stateToGo = $rootScope.userdata.info.usertype === 'Private' ? 'user' : 'organization'
        $rootScope.preventCloseToStatusChange = false
        $state.go(stateToGo, {slug: $rootScope.userdata.info.slug})
        $scope.disabledButton = false
        $rootScope.$broadcast('UpdateUserTrees')
      } else {
        activeError((data.error === 'userAlone') ? 'coupon.error.user_alone' : data.error)
      }

      // Reset global variables
      $rootScope.authorizePersonalData = undefined
      $scope.redeeming = false
    })
    .catch(function (data) {
      Utility.enableButton()
      $scope.disabledButton = false
      $scope.redeeming = false
    })
  }

  /**
   * Apertura login/registrazione
   */
  $scope.openLoginModal = function () {
    Modal.open({templateUrl: 'login.html', assignBackgroundClass: 'treedomModalLoginBackground', easyClose: false, fitcontent: true, preventCloseToStatusChange: true })
  }



  /**
   * Seleziona un albero tra quelli del giftpicker
   * @param {number} treeId - ID della specie.
   */


  $scope.selectGiftpickerTree = function (treeId) {
    if (($scope.giftpickerActiveTree && $scope.giftpickerActiveTree.id) === treeId) {
      $scope.giftpickerActiveTree = null
    } else {
      $scope.giftpickerActiveTree = $scope.infoCoupon.trees.find(tree => tree.id === treeId)
    }
    if (!$scope.giftPickerTreesSlider) {
      return
    }
    if ($scope.giftpickerActiveTree) {
      $scope.giftPickerTreesSlider.stopAutoplay()
    } else {
      $scope.giftPickerTreesSlider.startAutoplay()
    }
  }



  // Swiper giftPickerTrees
  $scope.renderGiftPickerTreesSwiper = function () {
    if ($scope.giftPickerTreesSlider) {
      !Array.isArray($scope.giftPickerTreesSlider)
        ? $scope.giftPickerTreesSlider.destroy(true, true)
        : $scope.giftPickerTreesSlider.forEach(function (s) {
          s.destroy(true, true)
        })
    }

    $scope.giftPickerTreesSlider = new Swiper('#SwiperGiftPickerTrees', {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 10,
      nextButton: '#GiftPickerTreesButtonNextSlider',
      prevButton: '#GiftPickerTreesButtonPrevSlider',
      pagination: '#GiftPickerTreesSliderPagination',
      paginationClickable: true,
      grabCursor: true,
      autoplay: 3000,
      // Responsive breakpoints
      breakpoints: {
        // when window width is <= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        // when window width is <= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      }
    })
  }

  /**
   * Watcher sugli alberi presenti in caso di giftpicker, di default seleziono il
   * primo
   */
  $scope.$watch('infoCoupon', function (updatedInfo) {
    if (updatedInfo) {
      if (updatedInfo.trees) {
        // $scope.giftpickerActiveTree = updatedInfo.trees[0]

        $timeout(function(){
            $scope.renderGiftPickerTreesSwiper();
        })
      }
    }
  })

  $scope.changeLang = function (lng) {
    if (lng !== ipCookie('hl')) {
      // var urlToRedirect = $state.current.name !== 'coupon' && $state.current.name !== 'gift'
      //   ? $state.href('coupon', {})
      //   : $state.href($state.current.name, $state.current.params)

      var url = Routing.route('treedom_user_switch_ln')
      var form = $('<form action="' + url + '" method="post">' + '<input type="hidden" name="pathname" value="' + window.location.pathname + '" />' + '<input type="hidden" name="ln" value="' + lng + '" />' + '<input type="hidden" name="params" value="' + window.location.search + '" />' + '</form>')
      $('body').append(form)
      form.submit()
    }
  }

  /**
   * Capire se l'utente arriva da un link esterno o se deve inserire il codice manualmente
   */
  $scope.comingFromLink = $state.current.name === 'gift'

  var ClosedModalListener = $rootScope.$on('closedTreedomModal', function (event, modalInfo) {
    // Happens only when user have direct link (so modal will be open)
    // but immediately close modal on first step
    if (modalInfo.closedModalId.includes('redeem-coupon') && $rootScope.authorizePersonalData) {
      $rootScope.authorizePersonalData = undefined
    }
  })

  function startRedeemCouponFlowWithParams () {
    var code = null
    var userSlug = null
    var eventSlug = null

    // Code
    if ($state.current.name === 'gift' || $location.search().code) {
      code = $state.params.id || $location.search().code
    }

    // Event slug
    if ($state.current.name.includes('event')) {
      eventSlug = $state.params.eventslug || null
    }

    // Organization slug
    if ($state.current.name.includes('organization')) {
      userSlug = $state.params.slug || null
    }

    startRedeemCouponFlow(code, userSlug, eventSlug)
  }

  startRedeemCouponFlowWithParams()

  $scope.$on('$destroy', ClosedModalListener)
}])
