import { CartItem, ProductPlan, UserCart } from '@f/@types';
import tagManagerService from '@f/services/TagManager';
import { validateRemoveToCart } from '@f/validation/datalayer';

import { cartPartialInfo, clearPositionFromSubscriptions, getPositionFromSubscriptions, productPartial } from '../eventUtils';

export function DataEventRemoveFromCart(cart: UserCart, type: { productID?: number, itemID?: number }) {
    const { items } = cart;
    if (!items) return
    let removedItems = [];
    if( type.productID ) {
      removedItems = items.filter(item => item.product.id === type.productID)
        .map((cartItem: CartItem ) =>  
          productPartial(cartItem.product, { isGift: !!cartItem.gift, quantity: cartItem.quantity }))
    } else if(type.itemID) {
      const  { product, gift} = cart.items.find(cartItem=> cartItem.id === type.itemID) as CartItem; 
      removedItems.push(productPartial(product, { isGift: !!gift } ));
    }

    tagManagerService.push({ ecommerce: null })
    tagManagerService.push({
      event: 'removeFromCart',
      ecommerce: {
        ...cartPartialInfo(cart),
        remove: {
          products: removedItems
        }
      }
    }, validateRemoveToCart)
}

export function DataEventRemoveFromCartSubs(cart: UserCart, plan: ProductPlan) {
  tagManagerService.push({ ecommerce: null })
  tagManagerService.push({
    event: 'removeFromCart',
    ecommerce: {
      ...cartPartialInfo(cart),
      position: getPositionFromSubscriptions(),
      remove: {
        products: Array.of(productPartial(plan, {isGift: false }))
      }
    }
  }, validateRemoveToCart)
  clearPositionFromSubscriptions()
}

export function DataEventEmptyCart(cart: UserCart) {
  const { items } = cart;
  if (!items) return
  let removedItems = items.map((cartItem: CartItem ) =>  
  productPartial(cartItem.product, { isGift: !!cartItem.gift, quantity: cartItem.quantity }))

  tagManagerService.push({ ecommerce: null })
  tagManagerService.push({
    event: 'removeFromCart',
    ecommerce: {
      ...cartPartialInfo(cart),
      remove: {
        products: removedItems,
        allRemoved: true
      }
    }
  }, validateRemoveToCart)
}