import { Component, Mixins } from 'vue-property-decorator'
import axios, { AxiosResponse, AxiosPromise } from 'axios'
import JWT from 'jsonwebtoken'
import { UserType } from '@f/models'
import { api } from '@f/configs/app'

// import BroadcastChannel from 'broadcast-channel'

import { TransitionOptions, StateOrName, RawParams } from '@uirouter/angularjs';
import {
  ExistingUserPayload,
  HomonymyLoginPayload,
  JwtDecoded,
  SignInCredentials,
  SignUpData,
  SocialHomonymyErrorData,
  UserData,
} from '@f/@types'
import { IsoCode } from '@f/@types/localization'

// Validations
import ValidateMixin from '@f/mixins/Validate'
import { validateUserCheck, validateSignIn, validateSignUp, validateHomonymySignIn, validateHomonymySignUp } from '@f/validation/auth'
import { FederatedSignInOptions, FederatedSignInResponse, TreedomAuth } from '@f/auth'
import { apiClient } from '@f/apollo'
import { USER_IMPORTED } from '../graphql/queries'

export class SocialHomonymyError extends Error {
  data : any

  constructor(data: SocialHomonymyErrorData, ...params: any) {
    super(...params)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SocialHomonymyError)
    }
    this.name = 'SocialHomonymyError'
    this.data = data
  }
}

@Component
export default class AuthMixin extends Mixins(ValidateMixin) {

  password: string | null = ''
  confirmPassword: string | null = ''
  email: string | null = ''
  confirmEmail: string | null = ''
  firstName: string | null = ''
  lastName: string | null = ''
  userData: ExistingUserPayload | undefined | null = null
  acceptTerms: boolean = true
  allowCommunications: boolean = true

  onCheckout: boolean = false

  locale: IsoCode = 'it'

  // Temporary use angular service to avoid multiple instances
  // authBoradcastChannel: BroadcastChannel = new BroadcastChannel('auth');

  // TODO: AngularJS integration to be removed
  $graphQl: any = null

  // Events
  mounted(): void {
    this.$graphQl = this.ngService('$graph')
    this.locale = this.$rootScope.ln
  }

  checkUserExistence(email: string): Promise<ExistingUserPayload | undefined> {
    if (!api.authUri) return Promise.reject(new Error('No auth URI available.'))
    return this.validate(validateUserCheck, { email })
    .then(() => axios.get(`${api.authUri}/user/exists/${encodeURIComponent(email)}`))
      .then((response: AxiosResponse) => {
        return response.data as ExistingUserPayload
      })
  }

  async postAuthenticationSteps(userSlug?: string) {
    const slug = userSlug || await TreedomAuth.currentUserInfo().then((info) => info.slug)!
    // Set GraphQL headers
    if (this.$apolloProvider) this.$apolloProvider.defaultClient.resetStore()
    const broadcasted = this.broadcastAuth(slug)
    if (broadcasted) {
      /* Delegated to AngularJS UserService.me method */
      // Set info, events and following on $rootScope
      // Verify business profile fullfill state
      // Send tag manager event with user.id
      // Fetch cart
      // Attempt twice Braintree client
      /* /End Delegated to AngularJS UserService.me method */
      return this.fetchUserData(slug).then((userData: UserData) => {
        const { info: { id, usertype, authorizeDataProcessing, dateOfBirth, gender } } = userData
        // Fetch new badges to show in modal
        this.fetchNewBadges(id)
        // Evaluate redirect path
        this.backCompatiblePostLoginRedirect()
        // Set pending rootscope actions to be executed
        setTimeout(this.checkRootScopePendingActions, 2500)
        // Send alert call if pending
        if (this.$rootScope.openAlertMeModal) {
          const productId = this.$rootScope.openAlertMeModal && this.$rootScope.openAlertMeModal.productId
          setTimeout( this.alertMeWhenTreeIsAvailable.bind( null, productId) , 1000)
        }
        // ?
        if (this.$rootScope.authorizePersonalData) {
          if (usertype === UserType.Private && !authorizeDataProcessing) {
            const ngUserService = this.ngService('User')
            if (ngUserService) {
              ngUserService.userEditProfile({ authorize_data_processing: this.$rootScope.authorizePersonalData })
              .then((userProfileResponse: any) => {
                const userProfile = userProfileResponse.data || userProfileResponse
                this.$rootScope.userdata.info.authorizeDataProcessing = userProfile.authorizeDataProcessing;
                this.$rootScope.authorizePersonalData = undefined
              })
            }
          }
        }
      })
    }
  }

  async signIn(credentials: SignInCredentials) {
    await this.validate(validateSignIn, credentials)

    const response = await apiClient.query({
      query: USER_IMPORTED,
      variables: { email: credentials.username },
    })

    const { migrated, imported } = response.data.userImported
    const firstLoginAfterImport = imported && !migrated

    await TreedomAuth.signIn(credentials, firstLoginAfterImport)

    await this.postAuthenticationSteps()
  }

  signUp(userData: SignUpData): Promise<any> {
    const locale = this.$rootScope.ln
    return this.validate(validateSignUp, userData)
      .then(() => TreedomAuth.signUp({
        username: userData.email,
        password: userData.password,
        attributes: {
          locale,
          email: userData.email,
          firstName:userData.firstName,
          lastName:userData.lastName,
          country: userData.country,
          countryCode: userData.countryCode,
          allowPromotion: userData.allowPromotion,
          allowNewsletter: userData.allowNewsletter,
        },
        validationData: {
          confirmPassword: userData.confirmPassword,
          confirmEmail: userData.confirmEmail,
        }
      }))
      .then(async response => {
        await this.postAuthenticationSteps()
        return response
      })
  }

  federatedSignIn(options: FederatedSignInOptions): Promise<FederatedSignInResponse> {
    return TreedomAuth.federatedSignIn(options)
      .then(async (response) => {
        await this.postAuthenticationSteps()
        return response
      })
  }

  homonymyLogin(payload : HomonymyLoginPayload): AxiosPromise<any> {
    payload = { ...payload, locale: this.locale }
    const validationSchema = payload.id && payload.password
      ? validateHomonymySignIn
      : validateHomonymySignUp
    return this.validate(validationSchema, payload)
      .then(() => axios.post(`${api.authUri}/${payload.social}/homonymy`, payload, { withCredentials: true }))
      .then(async response => {
        await this.postAuthenticationSteps()
        return response
      })
  }

  broadcastAuth(slug: string): boolean {
    // TODO: move $rootscope data to apollo local state
    // TODO: move broadcast channels to framework agnostic module importable from anywhere
    let broadcasted = false
    if (!this.$rootScope) return broadcasted
    if (this.$rootScope.user !== slug) {
      broadcasted = true
      const ngUserService = this.ngService('User') as any
      if (ngUserService) {
        ngUserService.authBroadcastUpdate({
          merge: {
            user: slug
          }
        })
      }
      this.$rootScope.user = slug;
      this.$rootScope.$broadcast('UserLoggedBroadcast', true);
    }
    this.$rootScope.logged = true
    return broadcasted;
  }

  fetchUserData(slug: string): Promise<any> {
    const ngUserService = this.ngService('User')
    if (!!this.$rootScope && !!ngUserService) {
      return ngUserService.me(slug, this.onCheckout)
    }
    return Promise.reject()
  }

  fetchNewBadges(id: Number | String): void {
    const ngBadgeService = this.ngService('Badge')
    if (ngBadgeService && id) {
      ngBadgeService.getNewUserBadge(id).then((response: any) => {
        const badges = response.data || response
        const newBadges: [] = badges.filter((badge: { slug: string }) => {
          return !this.$rootScope.badges.some((rootScopeBadge: { slug: string }) => rootScopeBadge.slug === badge.slug)
        })
        this.$rootScope.badges = [ ...this.$rootScope.badges, ...newBadges ]
        if (!!newBadges.length && (!this.$rootScope.modalBadgeReceivedShowed || typeof this.$rootScope.modalBadgeReceivedShowed === 'undefined')) {
          this.$rootScope.$broadcast('BadgeAssigned', id);
          this.$rootScope.modalBadgeReceivedShowed = true;
          const ngModalService = this.ngService('Modal')
          if (ngModalService) ngModalService.open({ templateUrl: 'modalBadgeReceived.html' })
        }
      })
    }
  }

  backCompatiblePostLoginRedirect(): void {
    const redirectState: StateOrName | undefined = this.$rootScope.redirectState;
    const redirectParams: RawParams = this.$rootScope.redirectParams || {};
    let urlVars

    switch (this.$state.current.name) {
      case 'login':
        redirectState
          ? this.$state.go(redirectState, redirectParams)
          : this.$state.go('user', { slug: this.$rootScope.user });
        break;

      case 'globalLogin':
        urlVars = this.$rootScope.getUrlVars();
        !(typeof urlVars.redirect === 'undefined')
          ? window.location.href = decodeURIComponent(urlVars.redirect)
          : this.$state.go('user', { slug: this.$rootScope.user });
        break;

      case 'index':
        if(!this.$rootScope.userdata.info) return;
        if (redirectState === 'treecode') {
          this.$state.go( redirectState, redirectParams)
        } else {
          const state = this.$rootScope.userdata.info.usertype === UserType.Private ? 'user' : 'organization';
          this.$state.go(state, { slug: this.$rootScope.user });
        }
        break;

      default:
        if (redirectState) {
          const options: TransitionOptions = { reload: true }
          this.$state.go( redirectState, redirectParams, options)
        }
    }
  }

  checkRootScopePendingActions(): void {
    if (!this.$rootScope.actionAfterLogin) return;
    let ngOverlayLoaderService: any
    let ngUserService: any
    let ngSubscriptionCartService: any

    switch (this.$rootScope.actionAfterLogin) {
      case 'openEventCreation':
          if (this.$rootScope.userdata.info.usertype === UserType.Private) {
            this.$rootScope.createEventFlow()
          }

          this.$rootScope.actionAfterLogin = undefined;
        break;

      case 'openSubscriptionModal':
        this.$rootScope.subscriptionIdToOpen && this.$rootScope.openSubscribeModal(this.$rootScope.subscriptionIdToOpen);
        this.$rootScope.actionAfterLogin = undefined;
        break;
      default:
    }
  }

  alertMeWhenTreeIsAvailable(productId?: string | number): void {
    if (!productId) return
    const ngEcommerceService = this.ngService('Ecommerce')
    if (ngEcommerceService) {
      ngEcommerceService.letMeKnow(productId)
    }
  }

  decodeJwt(token: string): JwtDecoded {
    if (!token) {
      return {} as JwtDecoded
    }
    const {
      id,
      firstName: firstname,
      lastName: lastname,
      gender,
      avatarURL: picture,
      locale,
      slug,
      roles,
      userType: usertype
    } = JWT.decode(token) as { [key: string]: any }
    return {
      id,
      slug,
      picture,
      usertype,
      firstname,
      lastname,
      title: `${firstname} ${lastname}`,
      gender,
      locale,
      permissions: (roles || []).reduce(
        (permissions: object, role: string) =>  permissions = { ...permissions, [role]: true }, {}
      ),
    } as JwtDecoded
  }
}
