
import { DataEventUnsubscribe } from '@f/services/TagManager/events'


angular.module('ngApp')
.controller('deletePrivateAccountController', ['$rootScope', '$scope', 'Utility', 'User', function ($rootScope, $scope, Utility, User) {
  var ctrl = this
  ctrl.reasons = undefined
  ctrl.selectedReason = null
  ctrl.selectedReasonMessage = null
  ctrl.confirm = null

  ctrl.confirmAccountRemoval = function () {
    if (!ctrl.selectedReason) return
    ctrl.confirm = true
  }

  ctrl.closeDeleteModal = function () {
    $rootScope.closeTreedomModal()
  }

  ctrl.removeAccount = function () {
    ctrl.reasons = undefined

    User.disable($rootScope.userdata.info.id, ctrl.selectedReason, ctrl.selectedReasonMessage)
    .then(function () {
      DataEventUnsubscribe();
      $rootScope.$broadcast('UserLoggedBroadcast', false)
      $rootScope.logout()
    })
  }

  Utility.getUserDisableReasons()
  .then(function (reasons) {
    ctrl.reasons = reasons
  })
}])
