




















import {defineComponent} from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'

export default defineComponent({
  name: 'TreeCardStatusBadge',
  components: { Treecon },
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
})
