import { TrTree } from '@f/@types/graphql'
import { computed, Ref } from '@vue/composition-api'
import { useMutation } from '@vue/apollo-composable'
import { TREE_LIKE } from '@f/graphql/mutations'
import { ApolloCache } from '@apollo/client/cache'
import gql from 'graphql-tag'
import useAuthentication from '@f/composables/useAuthentication'

const useTreeLikes = (tree: Ref<TrTree>) => {
  const { authentication, isLogged } = useAuthentication()
  const likes = computed(() => tree.value?.likes)
  const likesCount = computed(() => tree.value.likes?.length)
  const isLikedByLoggedUser = computed(() => {
    if (!isLogged.value) return false
    return likes.value?.some((like) => like?.id === Number(authentication.value?.id))
  })
  const updateTreeCache = (cache: ApolloCache<any>, { data: { likes } }: any) => {
    cache.writeFragment({
      id: cache.identify(tree.value || {}),
      fragment: gql`
          fragment CachedTreeLikes on Tree {
              likes {
                  id,
              }
          }
      `,
      data: { likes },
      overwrite: true,
    })
  }
  const { mutate: likeTree, loading } = useMutation(
    TREE_LIKE,
    () => {
      return {
        variables: {
          treeId: tree.value?.treeId,
        },
        update: updateTreeCache,
      }
    }
  )

  return {
    loading,
    likes,
    likesCount,
    isLikedByLoggedUser,
    likeTree,
  }
}

export default useTreeLikes