angular.module('ngApp')
.directive('autofocus', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    link: function ($scope, $element) {
      $timeout(function () {
        $element[0].focus()
      })
    }
  }
}])
.directive('ngThumb', ['$window', function ($window) {
  var helper = {
    support: !!($window.FileReader && $window.CanvasRenderingContext2D),
    isFile: function (item) {
      return angular.isObject(item) && item instanceof $window.File
    },
    isImage: function (file) {
      var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|'
      return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1
    }
  }

  return {
    restrict: 'A',
    template: '<canvas/>',
    link: function (scope, element, attributes) {
      if (!helper.support) return

      var params = scope.$eval(attributes.ngThumb)

      if (!helper.isFile(params.file)) return
      if (!helper.isImage(params.file)) return

      var canvas = element.find('canvas')
      var reader = new FileReader()

      reader.onload = onLoadFile
      reader.readAsDataURL(params.file)

      function onLoadFile (event) {
        var img = new Image()
        img.onload = onLoadImage
        img.src = event.target.result
      }

      function onLoadImage () {
        canvas.attr({ width: 120, height: 120 })
        var canvas2 = canvas[0].getContext('2d')
        drawImageProp(canvas2, this, 0, 0, 120, 120)
      }
    }
  }
}])
.directive('onFinishRender', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, element, attr) {
      if (scope.$last === true) {
        $timeout(function () {
          scope.$emit('ngRepeatFinished')
        })
      }
    }
  }
}])
.directive('bsSwitch', ['$parse', '$timeout', function ($parse, $timeout) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function link (scope, element, attrs, controller) {
      var isInit = false

      /**
      * Return the true value for this specific checkbox.
      * @returns {Object} representing the true view value if undefined, returns true.
      */
      var getTrueValue = function () {
        var trueValue = $parse(attrs.ngTrueValue)(scope)
        if (!angular.isString(trueValue)) {
          trueValue = true
        }
        return trueValue
      }

      /**
      * Get a boolean value from a boolean-like string, evaluating it on the current scope.
      * @param value The input object
      * @returns {boolean} A boolean value
      */
      var getBooleanFromString = function (value) {
        return scope.$eval(value) === true
      }

      /**
      * Get a boolean value from a boolean-like string, defaulting to true if undefined.
      * @param value The input object
      * @returns {boolean} A boolean value
      */
      var getBooleanFromStringDefTrue = function (value) {
        return (value === true || value === 'true' || !value)
      }

      /**
      * Returns the value if it is truthy, or undefined.
      *
      * @param value The value to check.
      * @returns the original value if it is truthy, {@link undefined} otherwise.
      */
      var getValueOrUndefined = function (value) {
        return value || undefined
      }

      /**
      * Get the value of the angular-bound attribute, given its name.
      * The returned value may or may not equal the attribute value, as it may be transformed by a function.
      *
      * @param attrName  The angular-bound attribute name to get the value for
      * @returns {*}     The attribute value
      */
      var getSwitchAttrValue = function (attrName) {
        var map = {
          'switchRadioOff': getBooleanFromStringDefTrue,
          'switchActive': function (value) {
            return !getBooleanFromStringDefTrue(value)
          },
          'switchAnimate': getBooleanFromStringDefTrue,
          'switchLabel': function (value) {
            return value || '&nbsp;'
          },
          'switchIcon': function (value) {
            if (value) {
              return '<span class=\'' + value + '\'></span>'
            }
          },
          'switchWrapper': function (value) {
            return value || 'wrapper'
          },
          'switchInverse': getBooleanFromString,
          'switchReadonly': getBooleanFromString
        }
        var transFn = map[attrName] || getValueOrUndefined
        return transFn(attrs[attrName])
      }

      /**
      * Set a bootstrapSwitch parameter according to the angular-bound attribute.
      * The parameter will be changed only if the switch has already been initialized
      * (to avoid creating it before the model is ready).
      *
      * @param element   The switch to apply the parameter modification to
      * @param attr      The name of the switch parameter
      * @param modelAttr The name of the angular-bound parameter
      */
      var setSwitchParamMaybe = function (element, attr, modelAttr) {
        if (!isInit) return
        var newValue = getSwitchAttrValue(modelAttr)
        element.bootstrapSwitch(attr, newValue)
      }

      var setActive = function () {
        setSwitchParamMaybe(element, 'disabled', 'switchActive')
      }

      /**
      * If the directive has not been initialized yet, do so.
      */
      var initMaybe = function () {
        // if it's the first initialization
        if (!isInit) {
          var viewValue = (controller.$modelValue === getTrueValue())
          isInit = !isInit
          // Bootstrap the switch plugin
          element.bootstrapSwitch({
            radioAllOff: getSwitchAttrValue('switchRadioOff'),
            disabled: getSwitchAttrValue('switchActive'),
            state: viewValue,
            onText: getSwitchAttrValue('switchOnText'),
            offText: getSwitchAttrValue('switchOffText'),
            onColor: getSwitchAttrValue('switchOnColor'),
            offColor: getSwitchAttrValue('switchOffColor'),
            animate: getSwitchAttrValue('switchAnimate'),
            size: getSwitchAttrValue('switchSize'),
            labelText: attrs.switchLabel ? getSwitchAttrValue('switchLabel') : getSwitchAttrValue('switchIcon'),
            wrapperClass: getSwitchAttrValue('switchWrapper'),
            handleWidth: getSwitchAttrValue('switchHandleWidth'),
            labelWidth: getSwitchAttrValue('switchLabelWidth'),
            inverse: getSwitchAttrValue('switchInverse'),
            readonly: getSwitchAttrValue('switchReadonly')
          })
          controller.$setViewValue(viewValue)
        }
      }

      /**
      * Listen to model changes.
      */
      var listenToModel = function () {
        attrs.$observe('switchActive', function (newValue) {
          var active = getBooleanFromStringDefTrue(newValue)
          // if we are disabling the switch, delay the deactivation so that the toggle can be switched
          if (!active) {
            $timeout(function () {
              setActive(active)
            })
          } else {
            // if we are enabling the switch, set active right away
            setActive(active)
          }
        })

        function modelValue () {
          return controller.$modelValue
        }

        // When the model changes
        scope.$watch(modelValue, function (newValue) {
          initMaybe()
          if (newValue !== undefined) {
            element.bootstrapSwitch('state', newValue === getTrueValue(), true)
          }
        }, true)

        // angular attribute to switch property bindings
        var bindings = {
          'switchRadioOff': 'radioAllOff',
          'switchOnText': 'onText',
          'switchOffText': 'offText',
          'switchOnColor': 'onColor',
          'switchOffColor': 'offColor',
          'switchAnimate': 'animate',
          'switchSize': 'size',
          'switchLabel': 'labelText',
          'switchIcon': 'labelText',
          'switchWrapper': 'wrapperClass',
          'switchHandleWidth': 'handleWidth',
          'switchLabelWidth': 'labelWidth',
          'switchInverse': 'inverse',
          'switchReadonly': 'readonly'
        }

        var observeProp = function (prop, bindings) {
          return function () {
            attrs.$observe(prop, function () {
              setSwitchParamMaybe(element, bindings[prop], prop)
            })
          }
        }

        // for every angular-bound attribute, observe it and trigger the appropriate switch function
        for (var prop in bindings) {
          attrs.$observe(prop, observeProp(prop, bindings))
        }
      }

      /**
      * Listen to view changes.
      */
      var listenToView = function () {
        // When the switch is clicked, set its value into the ngModel
        element.on('switchChange.bootstrapSwitch', function (e, data) {
          // $setViewValue --> $viewValue --> $parsers --> $modelValue
          controller.$setViewValue(data)
        })
      }

      // Listen and respond to view changes
      listenToView()

      // Listen and respond to model changes
      listenToModel()

      // On destroy, collect ya garbage
      scope.$on('$destroy', function () {
        element.bootstrapSwitch('destroy')
      })
    }
  }
}])
.directive('bsSwitch', function () {
  return {
    restrict: 'E',
    require: 'ngModel',
    template: '<input bs-switch>',
    replace: true
  }
})
.directive('ui-sref-disable', ['$parse', '$rootScope',
  function ($parse, $rootScope) {
    return {
      // this ensure eatClickIf be compiled before ngClick
      priority: 100,
      restrict: 'A',
      compile: function ($element, attr) {
        var fn = $parse(attr.ui-sref-disable)
        return {
          pre: function link (scope, element) {
            var eventName = 'click'
            element.on(eventName, function (event) {
              var callback = function () {
                if (fn(scope, {$event: event})) {
                  // prevents ng-click to be executed
                  event.stopImmediatePropagation()
                  // prevents href
                  event.preventDefault()
                  return false
                }
              }
              if ($rootScope.$$phase) {
                scope.$evalAsync(callback)
              } else {
                scope.$apply(callback)
              }
            })
          },
          post: function () {}
        }
      }
    }
  }
])
.directive('tooltip', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.tooltip({
        html: true
      })
    }
  }
})
.directive('popover', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.popover({
        html: true,
        content: function () {
          return $('#popover_content_wrapper').html()
        }
      })
    }
  }
})
.directive('jqdatepicker', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ngModelCtrl) {
      element.datetimepicker({
        lang: document.documentElement.lang,
        minDate: 0,
        onSelect: function (date) {
          scope.date = date
          scope.$apply()
        },
        setDefaults: $.datetimepicker.setLocale(document.documentElement.lang),
        beforeShow: function () {
          setTimeout(function () {
            $('.ui-datepicker').css('z-index', 99999999999999)
            minDate: new Date()
          }, 0)
        }
      })
    }
  }
})
.directive('jqdatepickerstatistic', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: { callbackFn: '&' },
    link: function (scope, element, attrs, ngModelCtrl) {
      element.datetimepicker({
        format: 'Y/m/d',
        maxDate: 0,
        timepicker: false,
        onSelectDate: function (ct, $i) {
          scope.callbackFn({data: ct, type: attrs.model})
        }
      })
    }
  }
})
.directive('compile', ['$compile', function ($compile) {
  return function (scope, element, attrs) {
    scope.$watch(function (scope) {
      return scope.$eval(attrs.compile)
    }, function (value) {
      element.html(value)
      $compile(element.contents())(scope)
    })
  }
}])
.directive('myMaxlength', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModelCtrl) {
      var maxlength = Number(attrs.myMaxlength)
      function fromUser (text) {
        if (text.length > maxlength) {
          var transformedInput = text.substring(0, maxlength)
          ngModelCtrl.$setViewValue(transformedInput)
          ngModelCtrl.$render()
          return transformedInput
        }
        return text
      }
      ngModelCtrl.$parsers.push(fromUser)
    }
  }
})
.directive('numericOnly', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      modelCtrl.$parsers.push(function (inputValue) {
        var transformedInput = inputValue ? inputValue.replace(/[^\d.-]/g,'') : null

        if (transformedInput !== inputValue) {
          modelCtrl.$setViewValue(transformedInput)
          modelCtrl.$render()
        }

        return transformedInput
      })
    }
  }
})
.directive('colpick', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.colpick({
        layout: 'hex',
        color: '000000',
        onSubmit: function (hsb, hex, rgb, el) {
          scope.changeColorElement(hex)
          $(el).colpickHide()
        }
      })
    }
  }
})
.directive('scrolldetector', ['$window', function ($window) {
  /**
   *  DOCS scrolldetector directive
   *
   *  @param {number} trigger
   *  @desc Percentage of the element in page when trigger start
   *
   *  @param {boolean} loop
   *  @desc If true trigger start more than once
   *
   *  @return {boolean} scrollTriggered
   *  @desc True when triggered
   */
  return function (scope, element, attrs) {
    var triggerScroll = attrs.sdTrigger ? attrs.sdTrigger * 1 : 50
    var loop = attrs.sdLoop ? !!attrs.sdLoop : false
    var hasTriggered = false

    angular.element($window).bind('scroll', function () {
      var actualPercentage
      var _elemOffset = getCoords(element[0]).top - window.innerHeight
      var pageScrollTop = document.body.scrollTop
      var windowHeight = window.innerHeight

      pageScrollTop >= _elemOffset && (pageScrollTop - _elemOffset) <= windowHeight
        ? actualPercentage = parseInt((pageScrollTop - _elemOffset) * 100 / windowHeight)
        : pageScrollTop < _elemOffset
          ? actualPercentage = 0
          : (pageScrollTop - _elemOffset) > windowHeight
            ? actualPercentage = 100
            : null

      if (loop) {
        if (actualPercentage >= triggerScroll) {
          scope[attrs.sdVariable] = true
          hasTriggered = true
        } else {
          scope[attrs.sdVariable] = false
          hasTriggered = false
        }
      } else {
        if (!hasTriggered) {
          if (actualPercentage >= triggerScroll) {
            scope[attrs.sdVariable] = true
            hasTriggered = true
          } else {
            scope[attrs.sdVariable] = false
          }
        }
      }

      scope.$apply()
    })
  }
}])

function getCoords (elem) {
  var box = elem.getBoundingClientRect()

  var body = document.body
  var docEl = document.documentElement

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  var clientTop = docEl.clientTop || body.clientTop || 0
  var clientLeft = docEl.clientLeft || body.clientLeft || 0

  var top = box.top + scrollTop - clientTop
  var left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

function drawImageProp (ctx, img, x, y, w, h, offsetX, offsetY) {
  if (arguments.length === 2) {
    x = y = 0
    w = ctx.canvas.width
    h = ctx.canvas.height
  }

  // default offset is center
  offsetX = offsetX || 0.5
  offsetY = offsetY || 0.5

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0
  if (offsetY < 0) offsetY = 0
  if (offsetX > 1) offsetX = 1
  if (offsetY > 1) offsetY = 1

  var iw = img.width
  var ih = img.height
  var r = Math.min(w / iw, h / ih)
  var nw = iw * r   // new prop. width
  var nh = ih * r   // new prop. height
  var cx = 1
  var cy = 1
  var cw = 1
  var ch = 1
  var ar = 1

  // decide which gap to fill
  if (nw < w) ar = w / nw
  if (nh < h) ar = h / nh
  nw *= ar
  nh *= ar

  // calc source rectangle
  cw = iw / (nw / w)
  ch = ih / (nh / h)

  cx = (iw - cw) * offsetX
  cy = (ih - ch) * offsetY

  // make sure source rectangle is valid
  if (cx < 0) cx = 0
  if (cy < 0) cy = 0
  if (cw > iw) cw = iw
  if (ch > ih) ch = ih

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h)
}
