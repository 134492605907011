import tagManagerService from '@f/services/TagManager';
import {
  IRansomChoice,
  IRansomOpen,
  IRansomRedeem,
  IRansomStart,
  IRansomStartConfirmed,
} from '@f/validation/types/dataLayer'
import {
  validateRansomChoice,
  validateRansomOpen,
  validateRansomRedeem,
  validateRansomStart,
  validateRansomStartConfirmed,
} from '@f/validation/datalayer'

export type DataEventRansomStartData = Omit<IRansomStart, 'event'>
export function DataEventRansomStart(data: DataEventRansomStartData) {
  tagManagerService.push({
    event: "startRansom",
    ...data,
  }, validateRansomStart)
}

export type DataEventRansomStartConfirmedData = Omit<IRansomStartConfirmed, 'event'>
export function DataEventRansomStartConfirmed(data: DataEventRansomStartConfirmedData) {
  tagManagerService.push({
    event: "startRansomConfirmed",
    ...data,
  }, validateRansomStartConfirmed)
}

export type DataEventRansomOpenData = Omit<IRansomOpen, 'event'>
export function DataEventRansomOpen(data: DataEventRansomOpenData) {
  tagManagerService.push({
    event: "openRansom",
    ...data,
  }, validateRansomOpen)
}

export type DataEventRansomChoiceData = Omit<IRansomChoice, 'event'>
export function DataEventRansomChoice(data: DataEventRansomChoiceData) {
  tagManagerService.push({
    event: "choiceRansom",
    ...data,
  }, validateRansomChoice)
}

export type DataEventRansomRedeemData = Omit<IRansomRedeem, 'event'>
export function DataEventRansomRedeem(data: DataEventRansomRedeemData) {
  tagManagerService.push({
    event: "giftRansom",
    ...data,
  }, validateRansomRedeem)
}