angular.module('ngApp')
.controller('ecommercePackController', ['$scope', '$rootScope', 'Utility', '$state', 'Ecommerce', function ($scope, $rootScope, Utility, $state, Ecommerce) {
  var ctrl = this;

  var powerKeys = ['foodsecurityPower', 'co2Power', 'incomePower', 'soilPower'];

  ctrl.id = $scope.attrs.id;
  ctrl.key = $scope.attrs.key;
  ctrl.icon = $scope.attrs.icon;
  ctrl.price = Number($scope.attrs.price);
  ctrl.priceCurrency = $scope.attrs.priceCurrency;
  ctrl.onHand = $scope.attrs.onHand * 1;
  ctrl.tankSize = $scope.attrs.tankSize * 1;
  ctrl.meaning = $scope.attrs.meaning;
  ctrl.name = $scope.attrs.name;
  ctrl.type = $scope.attrs.type;
  ctrl.enable = $scope.attrs.enable !== 'false';
  ctrl.badge = (!ctrl.onHand || !ctrl.enable) ? 'soldOut' : $scope.attrs.badge;
  ctrl.badgeDate = $scope.attrs.badgeDate;
  ctrl.badgeDiscount = $scope.attrs.badgeDiscount;
  ctrl.priceDiscount = $scope.attrs.priceDiscount;
  ctrl.originalPackagePrice = $scope.attrs.originalPackagePrice && Number($scope.attrs.originalPackagePrice);
  ctrl.originalPackagePriceCurrency = $scope.attrs.originalPackagePriceCurrency;
  ctrl.limitedSerieSlug = $scope.attrs.limitedSerieSlug;
  ctrl.color = $scope.attrs.color;
  ctrl.footerBackground = $scope.attrs.footerBackground;
  ctrl.isGiftable = $scope.attrs.isGiftable === 'true';
  ctrl.coupled = $scope.attrs.coupled === 'true';
  ctrl.goTo = $scope.attrs.goTo;
  ctrl.products = JSON.parse($scope.attrs.products);
  ctrl.navbar = $scope.attrs.navbar ? $scope.attrs.navbar === 'false' ? false : true : true;

  ctrl.treesQuantity = function () {
    if (!ctrl.products) return 0;

    return ctrl.products.reduce((total, item) => total += item.quantity, 0)
  };

  ctrl.totalTankSize = function () {
    if (!ctrl.products) return 0;

    return ctrl.products
      .reduce((total, item) => total += (item.specie.tankSize * 1 || 0) * (item.quantity * 1 || 1), 0)
  };

  ctrl.powers = Utility.reducePowers(ctrl.products);
  ctrl.badges = Utility.reducePackageProductsBadges(ctrl.products);

  ctrl.showThisProduct = function () {
    if ($scope.attrs.onClick) return $scope[$scope.attrs.onClick](ctrl.id)
    if (ctrl.badge === 'comingSoon') return;

    // TODO: Cosa si deve passare con il nuovo ecommerce?
    // Utility.gaAdd('addProduct', ctrl.tree)
    // Utility.gaAction('click', {})
    // Utility.gaSend()

    $rootScope.sliderHistory = {
      ecommerce: $scope.attrs.ecommerce,
      productId: $scope.attrs.id
    };

    $rootScope.lastSlug = $scope.attrs.limitedSerieSlug || null

    switch (ctrl.goTo) {
      case 'project':
        $state.go('project.tree', { treeId: ctrl.key });
        break;
      default:
        $state.go('species.tree', { treeId: ctrl.key });
    }
  }

  // Alert me when tree is available
  ctrl.letMeKnow = function () {
    Ecommerce.letMeKnow(ctrl.id)
  }
}]);
