import { cartService } from '@f/services/NewEcommerce/CartService';
import { datadogRum } from '@datadog/browser-rum';
import { api, appCookies } from '@f/configs/app.ts';
import { DataEventRemoveFromCart, DataEventEmptyCart } from '@f/services/TagManager/events';
import moment from 'moment';

const { cartUri } = api;

angular.module('cartService', [])
  .factory('Cart', ['$http', 'Ecommerce', '$q', '$rootScope', 'TagManager', '$broadcastChannel', 'SubscriptionCart', function ($http, Ecommerce, $q, $rootScope, TagManager, $broadcastChannel) {

    var cartBroadcast = $broadcastChannel.channel("cart");

    cartBroadcast.onmessage = function (_cart) {
      var updatedCart = _cart && JSON.parse(_cart) || null;
      $rootScope.userdata.cart = updatedCart;
      $rootScope.$broadcast("userdata.cart", updatedCart);
    };

    var cartBroadcastUpdate = function (_cart) {
      var updatedCart = _cart && JSON.stringify(_cart) || null;
      cartBroadcast.postMessage(updatedCart);
    };

    var countProducts = function (items) {
      return items.reduce((qt, item) => {
        return qt += (item.product && item.product.package) ? countProducts(item.product.package.products) : (item.product && item.product.quantity) || item.quantity || 1;
      }, 0);
    };

    var setVoucherInfo = function (cart) {
      if (!cart || !cart.voucher) return;
      if (cart.voucherInfo) return cart.voucherInfo;

      const voucherValidations = {
        cartDiscount: () => true,
        productDiscountWithMinimumSize: (info) => info.inCart && !info.missingCash,
        productDiscount: (info) => info.inCart,
        freeProductWithMinimumSize: (info) => !info.missingCash,
        freeProductWithMinimumCartItems: (info) => !info.missingItems,
        cartDiscountWithMinimumSize: (info) => !info.missingCash,
        targetProductFreeProduct: (info) => info.inCart,
        cartDiscountAmount: () => true,
        productDiscountAmount: (info) => info.inCart,
      };

      const {
        type: { name: type },
        product,
        freeProduct,
        percentage,
        amount,
        minimumCartSize: minCash,
        minimumCartItems: minItems
      } = cart.voucher;
      const inCart = product && (cart.items || []).some(({ product: cartProduct }) => !!cartProduct && cartProduct.id === product.id);
      const missingCash = Math.max(0, minCash - cart.totalWithoutVoucher);
      const missingItems = Math.max(0, minItems - (cart.items || []).length);

      const voucherInfo = {
        type,
        product,
        freeProduct,
        inCart,
        percentage,
        amount,
        minCash,
        minItems,
        missingCash,
        missingItems,
      };

      const expiryDate = cart.voucher.expiryDate ? moment(cart.voucher.expiryDate) : null;

      voucherInfo.expired = expiryDate ? moment(cart.voucher.expiryDate).isBefore(moment().utc().toDate()) : false;
      voucherInfo.expiryDate = Number(expiryDate ? expiryDate.format('x') : 0);

      voucherInfo.isValid = !voucherInfo.expired && voucherValidations[type] ? voucherValidations[type](voucherInfo, cart) : false;
      return voucherInfo;
    };

    var setVoucherUniqueInfo = function (cart) {
      if (!cart || !cart.voucherUnique) return;
      if (cart.voucherUniqueInfo) return cart.voucherUniqueInfo;

      let expiryDate = cart.voucherUnique.expiresAt ? moment(cart.voucherUnique.expiresAt) : null;

      const expired = expiryDate ? moment(cart.voucherUnique.expiresAt).isBefore(moment().utc().toDate()) : false;
      const showDate = expiryDate && expiryDate.isBefore(moment().add(3, 'y').utc().toDate());

      const voucherUniqueInfo = {
        expired,
        showDate
      };

      return voucherUniqueInfo;
    };

    var resolveProducts = function (cart) {
      var deferred = $q.defer();
      var products = cart.items.map(function (_item) {
        return _item.product.id;
      });
      if (cart.voucher) {
        if (cart.voucher.product) products.push(cart.voucher.product.id);
        if (cart.voucher.freeProduct) products.push(cart.voucher.freeProduct.id);
      }
      cart.itemsCount = cart.items.length;
      Ecommerce.getProducts(products, true)
        .then(function (_produtcs) {
          if (_produtcs) {
            cart.items = cart.items.map(function (_item) {
              _item.product = _produtcs[_item.product.id];
              return _item;
            });
            cart.itemsQtCount = countProducts(cart.items);
            if (cart.voucher) {
              if (cart.voucher.product) cart.voucher.product = _produtcs[cart.voucher.product.id];
              if (cart.voucher.freeProduct) cart.voucher.freeProduct = _produtcs[cart.voucher.freeProduct.id];
              cart.voucherInfo = setVoucherInfo(cart);
              cart.voucherUniqueInfo = setVoucherUniqueInfo(cart);
              const hasFreeProduct = cart.voucherInfo.freeProduct && cart.voucherInfo.isValid;
              cart.itemsCount += hasFreeProduct;
              cart.itemsQtCount += hasFreeProduct;
            }
          }
          deferred.resolve(cart);
        })
        .catch(function () {
          deferred.resolve(cart);
        });
      return deferred.promise;
    };

    var cartHeaders = function () {
      return {
        "X-User-tmp": appCookies.tempCart,
        "X-User-referral": appCookies.referral
      };
    };


    const validateBusinessCart = function (cart) {
      return cart && cart.itemsQtCount && cart.itemsQtCount >= 15;
    };

    var CartServiceFactory = {
      /**
       * Fetch cart only when user is authenticated or anonymous cart cookie is available
       *
       * @param   {boolean}  force  force fetching when previous request still pending
       * @param   {boolean=}  merge  merge previous carts with actual
       * @param   {boolean=}  put  use "put" verb to force cart items no longer available to be removed
       *
       * @return  {Object|undefined}         user/anonymous cart or undefined
       */
      attemptFetch(force, merge) {
        if (!appCookies.tempCart && !$rootScope.logged) return $q.resolve();
        return CartServiceFactory.fetch(force, merge);
      },
      /**
       * Find (or create) cart by User ID or Cookie ID.
       * @param {string} userID - If undefined, cart will be created with Cookie.
       */

      fetch: async () => {
        const cart = await cartService.fetchCart();
        $rootScope.userdata.cart = cart;
        $rootScope.$broadcast('userdata.cart', cart);
        cartBroadcastUpdate(cart);
        return Promise.resolve(cart);
      },


      /**
       * Close cart and prepare it for payment.
       * @param {string} cartID - Required.
       */
      close: function (cartID) {
        if (!cartID) return;
        var deferred = $q.defer();
        $http.get(cartUri + '/cart/' + cartID + '/close', { headers: cartHeaders() })
          .then(function (result) {
            result = result.data || result;
            return resolveProducts(result.cart);
          })
          .then(function (_cart) {
            $rootScope.userdata.cart = _cart;
            $rootScope.$broadcast("userdata.cart", _cart);
            cartBroadcastUpdate(_cart);
            deferred.resolve(_cart);
          })
          .catch(function (_error) { deferred.reject(_error); });

        return deferred.promise;
      },

      /**
       * Open cart if it's closed
       * @param {string} cartID - Required.
       */
      open: function (cartID) {
        if (!cartID) return;
        var deferred = this.openDeferred = $q.defer();
        $http.get(cartUri + '/cart/' + cartID + '/open', { headers: cartHeaders() })
          .then(function (cart) {

            cart = cart.data || cart;
            resolveProducts(cart)
              .then(function (_cart) { deferred.resolve(_cart); })
              .catch(function (_error) { deferred.reject(_error); });
          })
          .catch(function (_error) {

            deferred.reject(_error);
          });
        return this.openDeferred.promise;
      },

      /**
       * Call cart and check for assignation status.
       * NOTE: long time polling.
       * @param {string} cartId - Required.
       */
      checkAssignationStatus: function (cartID) {
        return $http.get(cartUri + '/kue/cart/' + cartID + '/status', { headers: cartHeaders() })
          .then(function (result) {
            result = result.data || result;
            return result;
          })
          .catch(function (err) {

            throw err.data || err;
          });
      },

      /**
       * Assign product to cart.
       * @param {string} cartID - Required.
       * @param {string} productID - Required.
       * @param {string} gift - If undefined, this will be set as false.
       * @param {string} forest - If undefined, this will be set as null.
       * @param {string} toUser - If undefined, this will be set as null.
       */

      assignProduct: async ({
        productId,
        forestId,
        quantity,
        isGift,
      }) => {
        const cart = await cartService.addProduct({
          productId,
          forestId,
          quantity,
          isGift,
        });
        $rootScope.userdata.cart = cart;
        $rootScope.$broadcast("userdata.cart.update", { cart, action: 'productAssigned' });
        cartBroadcastUpdate(cart);
        return Promise.resolve(cart);
      },

      /**
       * Edit product item info in a cart.
       * @param {string} cartID - Required.
       * @param {string} itemID - Required.
       * @param {string} gift - If undefined, this will be set as false.
       * @param {string} forest - If undefined, this will be set as null.
       * @param {string} toUser - If undefined, this will be set as null.
       */
      editProduct: function (cartID, itemID, gift, forest, toUser) {
        var deferred = $q.defer();
        $http.put(cartUri + '/cart/' + cartID + '/product/' + itemID, {
          gift: gift,
          forest: forest,
          toUser: toUser
        }, { headers: cartHeaders() })
          .then(function (cart) {

            cart = cart.data || cart;
            resolveProducts(cart)
              .then(function (_cart) {
                $rootScope.userdata.cart = _cart;
                $rootScope.$broadcast("userdata.cart.update", { cart: _cart, action: 'productEdited' });
                deferred.resolve(_cart);
              })
              .catch(function (_error) { deferred.reject(_error); });
          })
          .catch(function (_error) {

            if (_error.name === "CartClosed") {
              CartServiceFactory.fetch(null)
                .then(function (_cart) {
                  $rootScope.userdata.cart = _cart;
                  $rootScope.$broadcast("userdata.cart.update", { cart: _cart, action: 'productEdited' });
                  cartBroadcastUpdate(_cart);
                  deferred.resolve(_cart);
                })
                .catch(function (_error) {
                  deferred.reject(_error);
                });
            }
            else {
              deferred.reject(_error);
            }
          });
        return deferred.promise;
      },

      /**
       * Remove product item from cart.
       * @param {string} cartID - Required.
       * @param {string} itemID - Required.
       */

      removeProduct: async ({
        lineItemId,
      }) => {
        const cart = await cartService.removeLineItem({
          lineItemId,
        });
        $rootScope.userdata.cart = cart;
        $rootScope.$broadcast("userdata.cart.update", { cart, action: 'productRemoved' });
        cartBroadcastUpdate(cart);
        return Promise.resolve(cart);
      },

      /**
       * Remove all items from cart.
       * @param {string} cartID - Required.
       */
      emptyCart: function (cartID) {
        const oldCart = Object.assign({}, $rootScope.userdata.cart);

        return $http.delete(cartUri + '/cart/' + cartID + '/items/', { headers: cartHeaders() })
          .then(cart => {
            cart = cart.data || cart;
            resolveProducts(cart)
              .then(resolvedCart => {

                DataEventEmptyCart(oldCart);

                $rootScope.userdata.cart = resolvedCart;
                $rootScope.$broadcast("userdata.cart.update", { cart: resolvedCart, action: 'productsRemoved' });
                cartBroadcastUpdate(resolvedCart);
                return resolvedCart;
              })
              .catch(error => {
                throw error;
              });
          })
          .catch(error => {
            if (error.name === "CartClosed") {
              CartServiceFactory.fetch(null)
                .then(cart => cart)
                .catch(error => { throw error; });
            } else {
              throw error;
            }
          });
      },

      // TODO: unused until refactorized using ids array, internally used as unique call also from removeProduct
      /**
       * Remove products with a product_id from cart.
       * @param {string} cartID - Required.
       * @param {string} productID - Required.
       */
      removeProducts: function (cartID, productID) {
        const oldCart = Object.assign({}, $rootScope.userdata.cart);

        return $http.delete(cartUri + '/cart/' + cartID + '/products/' + productID, { headers: cartHeaders() })
          .then(function (cart) {
            cart = cart.data || cart;

            resolveProducts(cart)
              .then(resolvedCart => {

                DataEventRemoveFromCart(oldCart, { productID });

                $rootScope.userdata.cart = resolvedCart;
                $rootScope.$broadcast("userdata.cart.update", { cart: resolvedCart, action: 'productsRemoved' });
                cartBroadcastUpdate(resolvedCart);
                return resolvedCart;
              })
              .catch(error => { throw error; });
          })
          .catch(error => {
            if (error.name === "CartClosed") {
              CartServiceFactory.fetch(null)
                .then(cart => cart)
                .catch(error => { throw error; });
            } else {
              throw error;
            }
          });
      },

      /**
       * Assign voucher to cart.
       * @param {string} voucherID - Required. Can be voucher ID or CODE.
       */
      assignVoucher: function (voucherID) {
        let cartID = $rootScope && $rootScope.userdata && $rootScope.userdata.cart && $rootScope.userdata.cart.id;
        var deferred = $q.defer();
        if (!cartID) {
          deferred.resolve(
            CartServiceFactory.fetch().then(cart => {
              if (cart && cart.id) return CartServiceFactory.assignVoucher(voucherID);
              throw Error();
            }).catch(() => 'No cart available')
          );
          return deferred.promise;
        }
        $http.post(cartUri + '/cart/' + cartID + '/voucher/' + voucherID, null, { headers: cartHeaders() })
          .then(function (cart) {

            cart = cart.data || cart;
            resolveProducts(cart)
              .then(function (_cart) {
                deferred.resolve(_cart);
                $rootScope.userdata.cart = _cart;
                $rootScope.$broadcast("userdata.cart.update", { cart: _cart, action: 'voucherAssigned' });
                cartBroadcastUpdate(_cart);
              })
              .catch(function (_error) { deferred.reject(_error); });
          })
          .catch(function (_error) {

            if (_error.name === "CartClosed") {
              CartServiceFactory.fetch(null)
                .then(function (_cart) {
                  deferred.resolve(_cart);
                })
                .catch(function (_error) {
                  deferred.reject(_error);
                });
            }
            else {
              deferred.reject(_error);
            }
          });
        return deferred.promise;
      },

      /**
       * Remove voucher from cart.
       * @param {string} cartID - Required.
       * @param {string} voucherID - Required.
       */
      removeVoucher: function (cartID, voucherID) {
        var deferred = $q.defer();
        $http.delete(cartUri + '/cart/' + cartID + '/voucher/' + voucherID, { headers: cartHeaders() })
          .then(function (cart) {

            cart = cart.data || cart;
            resolveProducts(cart)
              .then(function (_cart) {
                $rootScope.userdata.cart = _cart;
                $rootScope.$broadcast("userdata.cart.update", { cart: _cart, action: 'voucherRemoved' });
                cartBroadcastUpdate(_cart);
                deferred.resolve(_cart);
              })
              .catch(function (_error) { deferred.reject(_error); });
          })
          .catch(function (_error) {

            if (_error.name === "CartClosed") {
              CartServiceFactory.fetch(null)
                .then(function (_cart) {
                  deferred.resolve(_cart);
                })
                .catch(function (_error) {
                  deferred.reject(_error);
                });
            }
            else {
              deferred.reject(_error);
            }
          });
        return deferred.promise;
      },

      isValid: function (cart, userType = "Private") {
        if (!(cart && userType)) return false;
        switch (userType) {
          case 'Business':
            return validateBusinessCart(cart);
          default:
            return true;
        }
      },

      getVoucherInfo: setVoucherInfo,

      getVoucherUniqueInfo: setVoucherUniqueInfo,

      pay: function ({ cartId, payment_method_nonce, amount, locale, tshirtRequest = false }) {
        if (!payment_method_nonce || isNaN(amount)) return Promise.reject('checkout.pay.unauthorized');
        const payload = { amount: Number(amount), payment_method_nonce, locale };
        if (tshirtRequest) {
          payload.tshirtRequest = tshirtRequest;
        }
        return $http.post(`${cartUri}/cart/${cartId}/pay`, payload, { headers: cartHeaders() })
          .then(({ data }) => {
            return data && data.cart && data.transactionId ? { cartId: data.cart, transactionId: data.transactionId } : null;
          })
          .catch(error => {
            datadogRum.addError('Checkout Pay Error', error);
            datadogRum.addError(error);
            throw new Error('checkout.pay.error');
          });
      },

      payForest: function ({ eventId, packageId, payment_method_nonce, amount }) {
        if (!payment_method_nonce || isNaN(amount)) return Promise.reject('checkout.pay.unauthorized');
        const payload = {
          amount: Number(amount),
          payment_method_nonce,
          forestId: eventId,
          userId: $rootScope.userdata.info.id,
          productId: packageId
        };
        const url = cartUri + '/forest/pay';

        return $http.post(url, payload, { headers: cartHeaders() })
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            datadogRum.addError('Checkout PayForest Error', error);
            datadogRum.addError(error);
            throw new Error('checkout.pay.error');
          });
      },

      groupTreesByQuantity: function (items) {
        return items.reduce((groupedList, item) => {
          const quantity = Number(item.quantity) || 1;
          const price = Number(item.price);
          const inlistItem = groupedList.find(i => i.product.id === item.product.id);
          if (inlistItem) {
            inlistItem.quantity += quantity;
            inlistItem.price += price;
            return groupedList;
          }
          return [
            ...groupedList,
            { ...item, quantity, price }
          ];
        }, []);
      }
    };
    return CartServiceFactory;
  }]);
