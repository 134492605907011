angular.module('ngApp')
.controller('redeemCouponFlowOrangeController', ['$rootScope', '$scope', '$timeout', '$state', '$stateParams', 'Modal', 'Tree', 'User', 'Utility', function ($rootScope, $scope, $timeout, $state, $stateParams, Modal, Tree, User, Utility) {
  $rootScope.orangeActiveLn = 'nl'

  if ($stateParams.language) {
    if ($stateParams.language === 'nl' || $stateParams.language === 'fr') {
      $rootScope.orangeActiveLn = $stateParams.language
    }
  }

  $scope.text = require('../../../js-lib/orangeTranslations.js')

  // Tutti gli steps, attivi o non attivi, passati da backend
  $scope.initialSteps = false
  // Steps da effettuare
  $scope.steps = false
  // Step attuale
  $scope.currentStep = false
  // Errori
  $scope.error = false
  $scope.errorMessage = false
  // Oggetto con le informazioni del coupon attivo
  $scope.infoCoupon = false
  $scope.showCheckboxAuthorizePersonalData = true
  // Impostazioni per login/signup
  $rootScope.loginType = 'sign-up'
  $rootScope.redirectState = $state.current.name
  $rootScope.authorizePersonalData = undefined
  $rootScope.closeModalAfterLogin = true
  // Albero attivo in caso di giftpicker
  $scope.giftpickerActiveTree = undefined

  /**
   * Ascoltatore sulla variabile $scope.steps. Quandi gli step vengono settati,
   * questo watcher imposta automaticamente (la prima volta) il primo step
   * come 'active'
   */
  $scope.$watch('steps', function (updatedSteps) {
    if (updatedSteps && !$scope.currentStep) {
      $scope.currentStep = updatedSteps[0]
    }
  })

  /**
   * Ascoltatore sulla variabile $rootScope.steps. L'utente di trova nella tab di
   * login/signup, se esegue l'accesso o si registra, vado al prossimo step
   */
  $rootScope.$watch('userdata.info', function (updatedUser) {
    if ($scope.currentStep.name === 'login') {
      if (updatedUser) {
        $scope.nextStep()

        // Show or hide checkbox
        if (updatedUser.authorizeDataProcessing) {
          $scope.showCheckboxAuthorizePersonalData = false
        }
      }
    }
  })

  /**
   * Funzione che calcola gli step del riscatto
   * @param {string} code - Codice del coupon, se presente.
   */
  function startRedeemCouponFlow (code) {
    Tree.redeemCouponFlow(code)
    .then(function (response) {
      $scope.initialSteps = response

      // Check if organization want user data
      $rootScope.authorizePersonalData = checkAuthorizePersonalData($scope.initialSteps)

      updateStepsObject($scope.initialSteps)
      if (!$scope.steps) activeStep($scope.initialSteps)
      calculateSizeButtons()
    })
  }

  /**
   * Check if organization is looking for user personal data.
   * if true, return organization name, else return false
   */
  function checkAuthorizePersonalData (steps) {
    // Show or hide checkbox
    if ($rootScope.userdata) {
      if ($rootScope.userdata.info) {
        if ($rootScope.userdata.info.authorizeDataProcessing) {
          $scope.showCheckboxAuthorizePersonalData = false
        }
      }
    }

    var redeemStepIndex = steps.findIndex(step => step.name === 'redeem')

    if (redeemStepIndex < 0) return undefined

    var redeemStep = steps[redeemStepIndex]

    if (redeemStep.info) {
      return redeemStep.info.giver
        ? redeemStep.info.giver.requestUsersData
          ? { name: redeemStep.info.giver.name, value: false, link: redeemStep.info.giver.privacyPolicyLink }
          : undefined
        : undefined
    }

    return undefined
  }

  var blockClick = false
  $scope.toggleAuthorizePersonalData = function (blockToggle) {
    if (blockClick) return
    if (!$rootScope.user) return

    blockClick = true
    User.userEditProfile({ authorize_data_processing: $rootScope.authorizePersonalData.value })
    .then(function (response) {
      $rootScope.userdata.info.authorizeDataProcessing = response.authorizeDataProcessing
      blockClick = false
    })
  }

  $state.current.name === 'orange.code'
    ? startRedeemCouponFlow($state.params.code)
    : startRedeemCouponFlow()

  /**
   * Attiva uno step, settando come status 'todo'
   * @param {string} initialSteps - Nome dello step da attivare
   */
  function activeStep (initialSteps) {
    $scope.steps = initialSteps.filter(step => {
      step.status = 'todo'
      return step.isActive
    })
  }

  /**
   * Aggiorna gli oggetti con le informazioni degli steps e/o gli errori.
   * @param {Array} steps - Array degli step da compiere per completare il riscatto.
   */
  function updateStepsObject (steps) {
    steps.forEach(step => {
      // Aggiorno le informazioni del coupon
      if (step.name === 'redeem') {
        $scope.infoCoupon = step.info

      // Controllo gli eventuali errori dell'inserimento del codice
      } else if (step.name === 'insertCode') {
        if (step.info) {
          if (step.info.error) {
            $scope.error = true
            $scope.errorMessage = step.info.error
          }
        } else {
          // Se inserisco il codice dalla tab 'insertCode' e non ho errori vai avanti
          if ($scope.currentStep.name === step.name) {
            $scope.nextStep()
          }
        }
      }
    })
  }

  /**
   * Calcola lo spazio occupato dai titoli degli steps.
   */
  function calculateSizeButtons () {
    $timeout(function () {
      var buttons = document.getElementsByClassName('rc-tab-button')
      buttons.forEach(button => {
        button.style.width = (100 / buttons.length) + '%'
      })
    }, 10)
  }

  /**
   * Vai al prossimo step.
   */
  $scope.nextStep = function () {
    var index = $scope.steps.findIndex(step => step.name === $scope.currentStep.name)
    $scope.steps[index].status = 'done'

    if (index + 1 <= $scope.steps.length) {
      var scrolledTab = document.getElementsByClassName('rc-tab-content')[0]
      scrolledTab.scrollTop = 0
      $scope.currentStep = $scope.steps[index + 1]
    }
  }

  /**
   * Valida i codici inseriti dall'utente.
   * @param {string} code - Codice coupon inserito dall'utente.
   */
  $scope.validateGift = function (code) {
    $scope.error = false

    if (code === '#12345' || code === '12345') {
      activeError('coupon.error.no_rimbo')
      return
    }

    startRedeemCouponFlow(code)
  }

  /**
   * Attiva gli errori da frontend.
   * @param {string} message - Stringa di errore (del traduttore).
   */
  function activeError (message) {
    $scope.error = true
    $scope.errorMessage = message
    if (!$scope.$$phase) $scope.$apply()
  }

  /**
   * Call to action per riscattare l'albero
   * @param {number} specieId - Opzionale. Presente solo se si tratta di un
   * riscatto di tipo 'giftPicker'
   */
  $scope.redeemCoupon = function (specieId) {
    Utility.disableButton()

    var postData = {code: $scope.infoCoupon.code}
    if (specieId) postData.specie = specieId

    Tree.redeemTreeGift(postData).then(function (data) {
      if (data.status) {
        // Se riscatto l'albero nella mia pagina utente, chiudo la modale 'manualmente'.
        if (($state.current.name === 'user' && $rootScope.userdata.info.usertype === 'Private') ||
          ($state.current.name === 'organization' && $rootScope.userdata.info.usertype === 'Business')
        ) {
          $rootScope.closeTreedomModal()
        }

        var stateToGo = $rootScope.userdata.info.usertype === 'Private' ? 'user' : 'organization'
        $state.go(stateToGo, {slug: $rootScope.userdata.info.slug})
        $scope.disabledButton = false
        $rootScope.$broadcast('UpdateUserTrees')
      } else {
        if (data.error === 'userAlone') activeError('coupon.error.user_alone')
      }

      // Reset global variables
      $rootScope.authorizePersonalData = undefined
    }).catch(function (data) {
      Utility.enableButton()
      $scope.disabledButton = false
    })
  }

  /**
   * Apertura login/registrazione
   */
  $scope.openLoginModal = function () {
    Modal.open({templateUrl: 'login-orange.html', preventCloseToStatusChange: true})
  }

  /**
   * Seleziona un albero tra quelli del giftpicker
   * @param {number} treeId - ID della specie.
   */
  $scope.selectGiftpickerTree = function (treeId) {
    $scope.giftpickerActiveTree = $scope.infoCoupon.trees.filter(tree => tree.id === treeId)[0]
  }

  /**
   * Watcher sugli alberi presenti in caso di giftpicker, di default seleziono il
   * primo
   */
  $scope.$watch('infoCoupon', function (updatedInfo) {
    if (updatedInfo) {
      if (updatedInfo.trees) {
        $scope.giftpickerActiveTree = updatedInfo.trees[0]
      }
    }
  })

  $scope.changeLang = function (lng) {
    $rootScope.orangeActiveLn = lng
  }

  /**
   * Capire se l'utente arriva da un link esterno o se deve inserire il codice manualmente
   */
  $scope.comingFromLink = $state.current.name === 'gift'

  var ClosedModalListener = $rootScope.$on('closedTreedomModal', function (_event, modalInfo) {
    // Happens only when user have direct link (so modal will be open)
    // but immediately close modal on first step
    if (modalInfo.closedModalId.includes('redeem-coupon') && $rootScope.authorizePersonalData) {
      $rootScope.authorizePersonalData = undefined
    }
  })

  $scope.$on('$destroy', ClosedModalListener)
}])
