import { DataEventToggleFollow } from '@f/services/TagManager/events';
import * as clipboard from 'clipboard-polyfill'
import Swiper from 'swiper'

angular.module('ngApp')
.controller('organizationController', ['$rootScope', '$scope', '$state', '$stateParams', '$uiRouterGlobals', '$filter', 'User', 'Utility', 'Modal', '$timeout', function ($rootScope, $scope, $state, $stateParams, $uiRouterGlobals, $filter, User, Utility, Modal, $timeout) {

  $scope.requestUserSlug = $stateParams.slug
  // Pagina alberi.
  $scope.treesOffset = 1
  $scope.otherTreesLoad = false
  $scope.noMoreTrees = false
  // Pagina utenti.
  $scope.usersOffset = 1
  $scope.otherUsersLoading = false
  $scope.noMoreUsers = false
  // Gestione commenti.
  $scope.openReplicasBox = {}
  $scope.userMessage = {}
  $scope.openCommentsBox = {}
  // Mappa.
  $scope.mapIsExpanded = false
  // Swiper trees
  $scope.userTreeSlider = null
  $scope.businessPageIsLoading = true;
  $scope.copiedBusinessWallUrl = false;
  $scope.busy = true;
  $scope.webkitUrl = window.sbwkUrl && window.sbwkUrl[$rootScope.ln] || false;


  $scope.copyToClipboard = function (textToCopy) {
    clipboard.writeText(textToCopy)
    .then(function () {
      $scope.copiedBusinessWallUrl = true
      if (!$scope.$$phase) $scope.$apply()

      $timeout(function () {
        $scope.copiedBusinessWallUrl = false
        if (!$scope.$$phase) $scope.$apply()
      }, 1500)
    })

  }

  // Swiper trees
  $scope.renderUserTreesSwiper = function () {
    if ($scope.userTreeSlider) {
      !Array.isArray($scope.userTreeSlider)
        ? $scope.userTreeSlider.destroy(true, true)
        : $scope.userTreeSlider.forEach(function (s) { s.destroy(true, true) })
    }

    if (!$rootScope.pageUser.trees.length) {
      $scope.userTreeSlider = null
      return
    }
    $timeout(function () {
      $scope.userTreeSlider = new Swiper('#SwiperUserTrees', {
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 30,
        nextButton: '#UserTreesButtonNextSlider',
        prevButton: '#UserTreesButtonPrevSlider',
        pagination: '#UserTreesPagination',
        dynamicBullets: true,
        paginationClickable: true,
        grabCursor: true,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        }
      })
    })
  }

  // Filter preview trees
  $scope.getPreviewTrees = function() {
    return ($rootScope.pageUser.trees)
          ? $rootScope.pageUser.trees.slice(0, ($rootScope.pageUser.trees.length > 6) ? 5 : 6)
          : []
  }

  $scope.$on('tree.giftUpdated', function(_, trees, gift) {
    const ids = typeof trees === 'string' ? [trees] : trees
    $rootScope.pageUser.trees.forEach(tree => {
      if (ids.includes(tree.treeId)) {
        tree.gift = gift
      }
    })
    if (!$scope.$$phase) $scope.$apply()
  })

  /**
   * Converto le proprietà che contengono 'hide_' in booleani.
   * @param {Object} userObject - $rootScope.pageUser
   */
  var convertPropsToBoolean = function (userObject) {
    Object.keys(userObject.info).map(key => {
      if (key.includes('hide_')) {
        userObject.info[key] = userObject.info[key] === '0' ? false : Boolean(userObject.info[key])
      }
    })

    return userObject
  }

  /**
   * Funzione che ottiene il numero della sezione co2
   * @param {number} co2Captured
   */
  $scope.comparisonNumber = function (co2Captured) {
    var totComparison = 0

    if (co2Captured < 20000) {
      totComparison = co2Captured / 0.13
      $scope.comparisonType = 'businessPage.comparison.small'
    } else if (co2Captured >= 20000 && co2Captured < 2000000) {
      totComparison = co2Captured / 192
      $scope.comparisonType = 'businessPage.comparison.medium'
    } else {
      totComparison = co2Captured / 4708
      $scope.comparisonType = 'businessPage.comparison.large'
    }

    return $filter('number')(totComparison, 0)
  }

  /**
   * Apre la scheda albero
   * @param {string} treeId - ID dell'albero (NON DELLA SPECIE)
   * @param {boolean} gift - Se l'albero è in fase di regalo o no
   */
  $scope.openSingleTree = function (treeId, gift) {
    if ($scope.eventSelected) {
      return $state.href('organization.event.trees.item', {item: treeId}, { absolute: true })
    }

    return $state.href('organization.trees.item', {item: treeId}, { absolute: true })
  }

  // PAGINA TREES

  /**
   * Trova altri alberi dell'utente.
   * @param {string|number} userId - ID utente business.
   * @param {number} actualOffset - Offset attuale.
   */
  $scope.otherTreesOLD = function (userId, actualOffset) {
    $scope.otherTreesLoading = true

    User.getBusinessTrees(userId, actualOffset)
    .then(function(response) {

      response = response.data || response
      $scope.noMoreTrees = response.length < 100
      $scope.treesOffset++
      $scope.otherTreesLoading = false
      $rootScope.pageUser.trees = $rootScope.pageUser.trees.concat(response)
      if (!$scope.$$phase) $scope.$apply()
    })
  }

  // USERS / FOLLOWING / FOLLOWER
  /**
   * Segui/Smetti di seguire l'utente della pagina.
   * @param {string} userSlug - Slug dell'utente che si vuole seguire.
   */
  $scope.toggleFollow = function (slug) {
    if (!$rootScope.user) {
      Modal.open({templateUrl: 'login.html', easyClose: false, fitcontent: true})
      return
    }

    Utility.disableButton()
    var userToFollow = slug || $rootScope.pageUser.info.slug

    User.toggleFollow(userToFollow)
    .then(function(response) {
      const data = response.data || response
      $rootScope.userdata.following = data
      $scope.following = $rootScope.userdata.following.indexOf($rootScope.pageUser.info.slug) > -1
      getUserInfo($rootScope.pageUser.info.slug)

      const shouldFollow = data.includes(userToFollow)
      DataEventToggleFollow(shouldFollow, 'user-card-business','user');
      Utility.enableButton()
    })
    .catch(function() {

      Utility.enableButton()
    })
  }

  /**
   * Trova altri utenti che custodiscono alberi di quest'azienda.
   * @param {string|number} userId - ID utente business.
   * @param {number} actualOffset - Offset attuale.
   */
  $scope.otherUsers = function (userId, actualOffset) {
    $scope.otherUsersLoading = true

    User.getBusinessReceivers(userId, actualOffset)
    .then(function(response) {

      response = response.data || response
      $scope.noMoreUsers = response.users.length < 100
      $scope.usersOffset++
      $scope.otherUsersLoading = false
      $rootScope.pageUser.users = $rootScope.pageUser.users.concat(response.users)
      if (!$scope.$$phase) $scope.$apply()
    })
  }

  // UTILITIES

  // Eseguo determinate azioni in base allo stato in cui mi trovo
  if ($state.current.name === 'user.trees') {
    // toggleLoadMoreTreesListener(true)
  }

  // Watchet cambio di stato
  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    // Caricamento altri alberi.
    // if (toState.name === 'user.trees') toggleLoadMoreTreesListener(true)
    // if (fromState.name === 'user.trees') toggleLoadMoreTreesListener(false)
    if (!!$rootScope.pageUser.info) {
      redirectOnHiddenWalls($rootScope.pageUser.info)
    }
    // Chiusura mappa se cambio stato
    if (fromState.name === 'organization.map' && toState.name !== fromState.name) {
      $scope.collapseMap(true)
    }
  })

  $scope.$on('plantingTreesSucceeded', function () {
    getUserInfo($scope.requestUserSlug);
  })

  /**
   * Trova le informazioni dell'utente business.
   * @param {string} userSlug - Slug dell'utente.
   */
  var getUserInfo = function (userSlug) {
    User.getBusinessUserInfo(userSlug)
    .then(function(response) {

      response = response.data || response
      parseUserInfo(response)
    })
  }

  var parseUserInfo = function (data, _treesdata) {
    // Redirect in caso l'utente sia di tipo privato
    if (data.info.user_type === 'Private' && $state.current.name.includes('organization')) {
      var stateToRedirect = $state.current.name.replace('organization', 'user')
      $state.go(stateToRedirect, $state.params)
      return
    }
    // Avvio la mappa
    $timeout(function () {
        $rootScope.$broadcast('LoadOrganizationMap', {
            id: data.info.id,
            type: 'Business',
            expanded: false
        })
    }, 1)

    $rootScope.pageUser = convertPropsToBoolean(data)
    redirectOnHiddenWalls($rootScope.pageUser.info)
    if ($rootScope.pageUser.users.length < 100) $scope.noMoreUsers = true

    $scope.sponsoreBackgroundId = $rootScope.pageUser.info.redeem_background

    User.getInitalsBusinessUserTrees(parseInt($rootScope.pageUser.info.id)).then(function(_treesdata) {
      $rootScope.pageUser.trees = _treesdata.trees.edges
      $rootScope.pageUser.countries = _treesdata.userSummary.countries
      $scope.businessPageIsLoading = false
      $scope.pageUser.pages = {
        hasNextPage: _treesdata.trees.pageInfo.hasNextPage,
        nextCursor: _treesdata.trees.pageInfo.endCursor
      }
      $scope.busy = false
    })
    // Se l'azienda ha meno di 100 alberi o meno di 100 utenti nascondo i pulsanti
    // per caricare altri.
  };

  function redirectOnHiddenWalls({ hide_wall, slug }) {
    const [_, rootState] = $uiRouterGlobals.current.name.match(/^(.*)\..*\.?wall/) || []
    if (!!rootState && hide_wall) {
      $state.go(rootState, { slug }, { notify: false })
    }
  }

    var inifinityLock = false;
    var infinityStop = 100;
    $scope.nextPage = function () {
      if ($scope.busy || !$scope.pageUser.pages || inifinityLock) return
      var pageIndex = $scope.pageUser.pages
      if(!pageIndex.hasNextPage) return

      $scope.busy = true;
      User.getPageBusinessUserTrees(parseInt($rootScope.pageUser.info.id), pageIndex.nextCursor).then(function (_data) {
        var trees = _data.trees
        $scope.pageUser.pages.hasNextPage = trees.pageInfo.hasNextPage
        $scope.pageUser.pages.nextCursor = trees.pageInfo.endCursor
        for (var i = 0; i < trees.edges.length; i++) {
          $rootScope.pageUser.trees.push(trees.edges[i])
        }
        if ($rootScope.pageUser.trees.length > infinityStop) {
          inifinityLock = true
          $scope.otherTreesLoad = true
          infinityStop = infinityStop + 100
        }
        $scope.busy = false
      })
    }
    $scope.otherTrees = function(){
        inifinityLock = false;
        $scope.otherTreesLoad = false;
        $scope.nextPage();
    }
    //otherTreesLoad

  // Se non ho l'utente allora eseguo la chiamata, altrimenti parso l'utente che ho già
  if ($rootScope.showUser) {
    $rootScope.showUser.info.slug === $stateParams.slug
      ? parseUserInfo($rootScope.showUser)
      : getUserInfo($scope.requestUserSlug)
  } else {
    getUserInfo($scope.requestUserSlug)
  }

  /**
   * Anima il box dell'utente lungo tutta la pagina.
   */
  // var userWrapRect
  var shotScrollTopAddedClass
  function animateUserWrap () {
    var userWrap = document.getElementById('userWrap')
    if (!userWrap) return

    if (!$state.current.name.includes('organization')) {
      userWrap.classList
       ? userWrap.classList.remove('newPrivateUserInfoFixed')
       : userWrap.className.replace(' newPrivateUserInfoFixed', '')
      return
    }

    var scrollTop = document.body.scrollTop || document.documentElement.scrollTop

    if (scrollTop >= 85) {
      userWrap.classList
        ? userWrap.classList.add('newPrivateUserInfoFixed')
        : userWrap.className += ' newPrivateUserInfoFixed'
    } else {
      userWrap.classList
        ? userWrap.classList.remove('newPrivateUserInfoFixed')
        : userWrap.className.replace(' newPrivateUserInfoFixed', '')
    }

    var footer = document.getElementById('footer')
    var footerOffsetTop = footer.offsetTop
    var userWrapRect = userWrap.getBoundingClientRect()
    var userWrapSpace = footerOffsetTop - (userWrapRect.top + userWrapRect.height + 17.5)

    if (scrollTop >= userWrapSpace) {
      if (!shotScrollTopAddedClass) shotScrollTopAddedClass = scrollTop
      userWrap.classList
        ? userWrap.classList.add('newPrivateUserInfoBottom')
        : userWrap.className += ' newPrivateUserInfoBottom'
    }

    if (shotScrollTopAddedClass) {
      if (scrollTop <= shotScrollTopAddedClass) {
        // shotScrollTopAddedClass = undefined
        userWrap.classList
          ? userWrap.classList.remove('newPrivateUserInfoBottom')
          : userWrap.className = userWrap.className.replace(' newPrivateUserInfoBottom', '')
      }
    }
  }

  // Start funzione toggleUserNavbar ed eventlisteners
  $timeout(function () {
    animateUserWrap()
    window.addEventListener('scroll', animateUserWrap)
  }, 1000)

  // MAP

  $scope.expandMap = function () {
    $scope.mapIsExpanded = true
    var mapContainer = document.getElementById('MapContainer')
    if (!mapContainer) return
    let mapHeight = window.innerHeight - (mapContainer.offsetTop + mapContainer.offsetParent.offsetTop)

    var toolbar = document.getElementById('toolbar')
    if (toolbar) {
      mapHeight -= toolbar.offsetHeight
    }

    mapContainer.style.transition = '0.3s ease all'
    mapContainer.style.height = mapHeight + 'px'

    var userBox = document.getElementById('userWrap')
    if (userBox) userBox.style.top = '0px'

    $timeout(function () {
      Utility.blockScroll()
      $rootScope.$broadcast('ResizeOrganizationMap')
      $rootScope.$broadcast('ExpandOrganizationMap', true)

      window.addEventListener('resize', ResizeMapListener)
    }, 300)

    $state.go('organization.map')
  }

  $scope.collapseMap = function (blockChangeStatus) {
    $scope.mapIsExpanded = false
    var mapContainer = document.getElementById('MapContainer')
    mapContainer.style.transition = '0.3s ease all'
    mapContainer.style.height = null
    $rootScope.$broadcast('ExpandOrganizationMap', false)

    var userBox = document.getElementById('userWrap')
    if (userBox) userBox.style.top = null

    $timeout(function () {
      $rootScope.$broadcast('ResizeOrganizationMap')
      window.removeEventListener('resize', ResizeMapListener)
      Utility.allowScroll()
    }, 300)

    if (!blockChangeStatus) {
      $state.go('organization')
    }
  }

  var ResizeMapListener = function () {
    if (!$scope.mapIsExpanded) return
    var navbar = document.getElementById('navbar')
    var heightOffset = navbar ? navbar.offsetHeight : 75

    var mapContainer = document.getElementById('MapContainer')
    mapContainer.style.height = (window.innerHeight - heightOffset) + 'px'
    $rootScope.$broadcast('ResizeOrganizationMap')
  }

  if ($rootScope.currentState === 'organization.map') {
    $timeout(function () { $scope.expandMap() }, 1000)
  }

  // Destroy listeners when controller has been detached
  $scope.$on('$destroy', function () {
    window.removeEventListener('scroll', animateUserWrap)
  })
}])
