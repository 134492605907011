import { Vue, Component } from 'vue-property-decorator'

export type AuthErrorMessage =
  | 'EmailEmpty'
  | 'PasswordEmpty'
  | 'PasswordNoMatch'
  | 'ResetTokenInvalid'
  | 'SocialTokenInvalid'
  | 'UserAlreadyRegistered'
  | 'UserNotFound'

export interface AuthErrorResponse {
  name: AuthErrorMessage
  message: AuthErrorMessage
}

@Component({
  name: 'ShowAuthErrors'
})
export default class ShowAuthErrors extends Vue {
  getErrorLabelKey(message?: string): string {
    if (!message) return ''
    if (message === 'PasswordNoMatch') return 'error.signup.password_match'
    if (message.includes('LegacyTreedomProvider Error: missing access token'))
    return 'error.signup.password_match'
    if (message === 'UserAlreadyRegistered') return 'error.signup.other_user'
    // TODO: currently not used and translations unavailable
    if (message === 'EmailEmpty') return 'error.generic'
    if (message === 'PasswordEmpty') return 'error.generic'
    if (message === 'ResetTokenInvalid') return 'error.generic'
    if (message === 'SocialTokenInvalid') return 'error.generic'
    if (message === 'UserNotFound') return 'error.generic'
    // Cognito errors messages
    if (message === 'User does not exist.') return 'error.signin.user-does-not-exist'
    if (message === 'Username/client id combination not found.') return 'error.password_recovery.email_recovery'
    if (message.includes('there+is+already+an+account+with+this+email')) return 'error.signin.email-already-exists'
    if (message.toLowerCase().includes("an account with the given email already exists")) return 'error.signup.email-already-exists'
    return 'error.generic'
  }

  getErrorLabel(errorResponse: AuthErrorResponse): string {
    return this.$t(this.getErrorLabelKey(errorResponse.message))
  }

  getErrorLabelByMessage(message?: string) {
    return this.$t(this.getErrorLabelKey(message))
  }
}
