var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.$t('game.question.puzzle'))}}),_vm._v(" "),_c('div',{ref:"puzzleContainer",staticClass:"puzzleContainer"},[_c('div',{staticClass:"skeletonPieces",style:({width: _vm.dimensions.totalWidth, height: _vm.dimensions.totalHeight})},[_vm._l((_vm.styledSkeletonPieces),function(ref){
var src = ref.piece.src;
var style = ref.style;
var id = ref.id;
return _c('div',{key:src,staticClass:"dropzone skeleton",style:(style),attrs:{"data-dropzone":id,"data-target":"skeleton"}},[_c('img',{style:(style),attrs:{"src":src}})])}),_vm._v(" "),_c('a',{staticClass:"skip",attrs:{"href":"#"},on:{"click":_vm.skipThePuzzle}},[_vm._v("Skip")])],2),_vm._v(" "),((!_vm.puzzleCompleted || _vm.isDragging) && !_vm.skipped)?_c('div',{staticClass:"puzzlePieces"},_vm._l((_vm.styledPuzzlePieces),function(ref){
var src = ref.piece.src;
var style = ref.style;
var id = ref.id;
return _c('div',{key:src,staticClass:"dropzone puzzle",attrs:{"data-dropzone":id,"data-target":"puzzle"}},[_c('div',{staticClass:"draggable"},[_c('img',{style:({width: style.width, height: style.height}),attrs:{"src":src}})])])}),0):_c('div',{staticClass:"compliments"},[_c('Treecon',{staticClass:"compliments__icon",attrs:{"icon":"success-circle-fill","size":"big"}}),_vm._v(" "),_c('div',{staticClass:"compliments__text",domProps:{"innerHTML":_vm._s(_vm.$t('game.question.puzzle.compliments.title'))}}),_vm._v(" "),_c('div',{staticClass:"compliments__description",domProps:{"innerHTML":_vm._s(_vm.$t('game.question.puzzle.compliments.description'))}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }