
angular.module('ngApp')
    .controller('ecommercePlansController', ['$rootScope', 'Ecommerce', 'Modal', 'User', '$timeout', 'SubscriptionCart', 'OverlayLoader', function ($rootScope, Ecommerce, Modal, User, $timeout, SubscriptionCart, OverlayLoader) {
        var Swiper = require('swiper');
        var ctrl = this;
        ctrl.plans = null;
        ctrl.activeUserPlanId = $rootScope.user ? null : 'noplan';
        ctrl.subscriptionId = null;
        ctrl.currentBillingCycle = null;
        ctrl.trialUser = false;
        ctrl.paymentMethodId = null;
        ctrl.plansSlider = null;
        ctrl.userWatcher = null;
        ctrl.queryPlanProductId = null;

        ctrl.choosePlan = function (planProductId) {
          const activePlanProductId = (!ctrl.activeUserPlanId || ctrl.activeUserPlanId === 'noplan') ? null : ctrl.activeUserPlanId
          SubscriptionCart.choosePlan(planProductId, activePlanProductId, ctrl.subscriptionId, ctrl.currentBillingCycle, ctrl.paymentMethodId, ctrl.trialUsed)
        };

        // Modale info su piani abbonamento
        ctrl.openPlansInfo = function () {
            Modal.open({templateUrl: 'modalPlansInfo.html'})
        };

        ctrl.isPendingSubscription = function() {
          return !!User.pendingGetSubscription
        }

        Ecommerce.getEcommerces(["treedom_plans"])
          .then(function(res) {
              var ecomm = res.data || res
              ctrl.plans = ecomm["treedom_plans"] || []
              if (!ctrl.isPendingSubscription() && !ctrl.userWatcher) {
                CheckSubscriptionQueryParam()
              }
              $timeout(() => {
                renderPlansSwiper()
              })
          })
          .catch(function(){
              ctrl.plans = [];
          });

        const CheckSubscriptionQueryParam = function () {
          ctrl.queryPlanProductId = $rootScope.getUrlVars().subscribe
          if (ctrl.queryPlanProductId) OverlayLoader.show()
          if (!ctrl.queryPlanProductId || !ctrl.plans || ctrl.isPendingSubscription()) return
          OverlayLoader.hide()
          const planProductIsAvailable = ctrl.plans.some(plan => Number(plan.id) === Number(ctrl.queryPlanProductId))
          if (planProductIsAvailable) ctrl.choosePlan(Number(ctrl.queryPlanProductId))
        }

        CheckSubscriptionQueryParam()

        // Swiper plans
        var renderPlansSwiper = function () {
          if (ctrl.plansSlider) {
            !Array.isArray(ctrl.plansSlider)
              ? ctrl.plansSlider.destroy(true, true)
              : ctrl.plansSlider.forEach(function (s) { s.destroy(true, true) })
          }

          ctrl.plansSlider = new Swiper('#SwiperPlans', {
            slidesPerView: 3,
            // centeredSlides: true,
            spaceBetween: 30,
            slidesPerGroup: 3,
            nextButton: '#PlansButtonNextSlider',
            prevButton: '#PlansButtonPrevSlider',
            pagination: '#PlansSliderPagination',
            paginationClickable: true,
            grabCursor: true,
            // Responsive breakpoints
            breakpoints: {
              // when window width is <= 480px
              480: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesPerGroup: 1,
                centeredSlides: true
              },
              // when window width is <= 640px
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
                slidesPerGroup: 1,
                centeredSlides: true
              },
              991: {
                slidesPerView: 1,
                spaceBetween: 30,
                slidesPerGroup: 1,
                centeredSlides: true
              },
              1200: {
                slidesPerView: 2,
                spaceBetween: 30,
                slidesPerGroup: 1,
                centeredSlides: true
              }
            }
          })
        }
    }]);
