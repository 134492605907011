
































































import { computed, defineComponent, provide, ref, watch } from '@vue/composition-api'
import OrganizationInfo from '@f/components/Organization/OrganizationInfo.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import { fetchOrganizationData, fetUserImpacts, getMeActiveSubscriptionPlan } from '@f/composables/fetchUserData'
import useRoutes, { ExactMatchOption } from '@f/composables/useRoutes'
import { RawParams } from '@uirouter/angularjs'
import DotLoader from '@f/components/DotLoader.vue'
import useAuthentication from '@f/composables/useAuthentication'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import OrganizationMapPreviewBig from '@f/pages/Organization/partials/OrganizationMapPreviewBig.vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apiClient } from '@f/apollo'
import OrganizationCommitmentModal from '@f/pages/Organization/partials/OrganizationCommitmentModal.vue'
import OrganizationSubscriberModal from '@f/pages/Organization/partials/OrganizationSubscriberModal.vue'
import OrganizationDescriptionModal from '@f/pages/Organization/partials/OrganizationDescriptionModal.vue'
import OrganizationMenu from '@f/pages/Organization/partials/OrganizationMenu.vue'
import OrganizationHeader from '@f/pages/Organization/partials/OrganizationHeader.vue'
import { share } from 'rxjs/operators'
import ShareFallbackModal from '@f/components/ShareFallbackModal.vue'
import { UserProfileImpactStatus } from '@f/@types/treedom-next'

// @ts-ignore
export default defineComponent({
  name: 'OrganizationPage',
  methods: { share },
  components: {
    ShareFallbackModal,
    OrganizationHeader,
    OrganizationMenu,
    OrganizationCommitmentModal,
    OrganizationSubscriberModal,
    OrganizationDescriptionModal,
    OrganizationMapPreviewBig,
    OrganizationInfo,
    Treecon,
    DotLoader,
    // Pages
    OrganizationOverview: () =>
      import(
        /* webpackChunkName: "OrganizationOverview" */ '@f/pages/Organization/partials/OrganizationOverview.vue'
      ),
    OrganizationJournal: () =>
      import(
        /* webpackChunkName: "OrganizationJournal" */ '@f/pages/Organization/partials/OrganizationJournal.vue'
      ),
    OrganizationTrees: () =>
      import(
        /* webpackChunkName: "UserTrees" */ '@f/pages/Organization/partials/OrganizationTrees.vue'
      ),
    UserBadges: () =>
      import(
        /* webpackChunkName: "UserBadges" */ '@f/pages/User/partials/UserBadges.vue'
      ),
    UserForests: () =>
      import(
        /* webpackChunkName: "UserForests" */ '@f/pages/User/partials/UserForests.vue'
      ),
    OrganizationCommunicationKits: () =>
      import(
        /* webpackChunkName: "UserForests" */ '@f/pages/Organization/partials/OrganizationCommunicationKits.vue'
      ),
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    provide(DefaultApolloClient as any, apiClient)
    const { currentState, currentParams, href, go, isCurrentState } =
      useRoutes()
    const {
      user,
      forests,
      hide_forests,
      hide_trees,
      hide_wall,
      show_commitment,
      show_subscriber,
      commitment_start_at,
    } = fetchOrganizationData(props.slug)
    const forestsCount = computed(() => forests.value?.length || 0)
    const userType = computed(() => user.value?.info?.user_type)
    const userId = computed(
      () => (!!user.value?.info.id && Number(user.value.info.id)) || undefined
    )
    const { isLoggedAs } = useAuthentication()

    const mobileQuery = maxWidth('md')
    const isMobile = useMediaQuery(mobileQuery)
    const commitmentBadge = ref<{ onDiscoverMoreClick: () => void } | null>(
      null
    )
    const subscriberBadge = ref<{ onDiscoverMoreClick: () => void } | null>(
      null
    )
    const canCreateForest = ref<boolean>(false)

    const descriptionModalOpen = ref(false)
    const commitmentModalOpen = ref(false)
    const subscriptionModalOpen = ref(false)
    const shareModalOpen = ref(false)

    const showImpacts = ref(false)
    watch(userId, async (userId) => {
      if (!userId) return
      const impacts = await fetUserImpacts(String(userId))
      showImpacts.value = impacts.some((impact) => impact.status === UserProfileImpactStatus.Active)
    })

    watch(user, async (user) => {
      if (user && isLoggedAs(user.info)) {
        const activePlan = await getMeActiveSubscriptionPlan()
        if (activePlan) {
          canCreateForest.value = [
            'un-albero-al-giorno',
            'un-albero-al-giorno-yearly',
            'subscription-business-professional',
            'subscription-business-professional-yearly',
          ].includes(activePlan.node.product.identifier)
        }
      }
    })

    const onCommitmentClick = () => {
      commitmentBadge.value?.onDiscoverMoreClick()
    }

    const onSubscriberClick = () => {
      subscriberBadge.value?.onDiscoverMoreClick()
    }

    const routeComponent = computed(() => {
      if (isCurrentState('organization', undefined, { state: true }))
        return 'OrganizationOverview'
      if (isCurrentState('organization.trees')) return 'OrganizationTrees'
      if (isCurrentState('organization.wall')) return 'OrganizationJournal'
      if (isCurrentState('organization.forests')) return 'UserForests'
      if (isCurrentState('organization.kit'))
        return 'OrganizationCommunicationKits'
      return 'OrganizationOverview'
    })

    function routeIcon(
      icon: string,
      routeName: string,
      routeParams?: RawParams,
      exact?: ExactMatchOption
    ) {
      return (
        icon +
        '-' +
        (isCurrentState(routeName, routeParams, exact) ? 'fill' : 'line')
      )
    }

    function goToMap() {
      if (Number(user.value?.info?.premium)) {
        go('organization.map.events')
        return
      }
      go('organization.map.countries')
    }

    // redirect private users to user/:slug
    watch(userType, (type) => {
      if (type === 'Private') {
        go(
          'user',
          {
            slug: props.slug,
          },
          { location: 'replace' }
        )
      }
    })

    return {
      go,
      currentParams,
      currentState,
      href,
      isCurrentState,
      routeComponent,
      routeIcon,
      user,
      userType,
      userId,
      scrollTo,
      forestsCount,
      forests,
      isLoggedAs,
      isMobile,
      hide_wall,
      hide_forests,
      hide_trees,
      goToMap,
      show_commitment,
      show_subscriber,
      commitment_start_at,
      commitmentBadge,
      subscriberBadge,
      onCommitmentClick,
      onSubscriberClick,
      canCreateForest,
      descriptionModalOpen,
      commitmentModalOpen,
      subscriptionModalOpen,
      shareModalOpen,
      showImpacts,
    }
  },
})
