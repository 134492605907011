import {getStateService} from "@f/services/Angular";
import {activeExperiments} from "@f/services/experimentService";

/**
 *   A Centralized solution to handle the Entrypoint AB test on the
 *   plant a tree entry point. In order rollback the changes once the test is done,
 *   the AB test's key can be safely removed from the "active experiments" array
 */
export const abTestEcommerceEntrypoint = {
    getState(): string {
        if(activeExperiments.includes('plant-gift-entry-point')) {
            return 'ecommerce-switch'
        }
        return 'species';
    },

    getUrl(): string {
        const state = getStateService()
        return state?.target(this.getState()).state().url?.slice(1,-1) || 'plant-a-tree';
    }
}