angular.module('ngApp')
.controller('treeUsesController', ['$scope', '$rootScope', '$timeout', 'Modal', function ($scope, $rootScope, $timeout, Modal) {
  $scope.badges = $scope.attrs.badges ? JSON.parse($scope.attrs.badges) : null;
  $scope.visibleBadge = false;

  $scope.$watch(function () {
    return $scope.attrs.badges
  }, function (badges) {
    $scope.badges = badges ? JSON.parse(badges) : null;
    // if ($scope.badges && $scope.badges.length > 1) $scope.visibleBadge = $scope.badges[0]
    if (!$scope.$$phase) $scope.$apply();

  });

  $scope.showSelectedBadge = function (badge) {
    $scope.visibleBadge = $scope.visibleBadge === badge ? false : badge
  };

  // Modale tutti usi alberi
  $scope.openModalTreeBadges = function () {
    Modal.open({templateUrl: 'modalTreeBadges.html'})
  };

}]);
