import OutsideClick from './outsideClick'
import FocusInput from './focusInput';
import ScrollFocus from './scrollFocus'
import Hidden from './hidden'
import { ObserveVisibility } from 'vue-observe-visibility';

export default {
  'click-outside': OutsideClick,
  'focus' : FocusInput,
  'scroll-focus': ScrollFocus,
  'observe-visibility': ObserveVisibility,
  'hidden': Hidden
}
