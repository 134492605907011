
import { InMemoryCache } from '@apollo/client/core'
import { default as possibleTypes } from '@f/graphql/possibleTypes.json'
import { typePolicies as apiTypePolicies } from '@f/apollo/clients/api/cache'

const cache = new InMemoryCache({
  typePolicies: { ...apiTypePolicies },
  possibleTypes,
})

export default cache
