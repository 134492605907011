const graphql = require('graphql.js');

angular.module("GraphQL", [])
  .provider('$graph', function GraphQLProvider(){
      let endpoint = null;
      let getJWT = function () {
          return Promise.resolve()
      };
      let options = {
          debug: true,
          alwaysAutodeclare: true,
          method: "POST"
      }
      this.endpoint = function(_url){
          endpoint = _url;
      }

      this.jwtFactory = function(getJWTFactory){
          getJWT = getJWTFactory;
      }

      this.setOptions = function (_options) {
          var defaults = {
              alwaysAutodeclare: true,
              method: "POST"
          }
          options = _.assign({}, defaults, options);
      }

      this.$get = ['$cookies', function GraphQLFactory($cookies){
          const client = graphql(endpoint, options)
          const updateHeaders = async () => {
            const jwt = await getJWT()
            if(jwt) {
              client.headers({
                Authorization: function () {
                  return `Bearer ${jwt}`
                }
              })
            }
          }
          return {
            query(...args) {
              return async () => {
                await updateHeaders()
                return client.query(...args)()
              }
            },
            mutate(...args) {
              return async () => {
                await updateHeaders()
                return client.mutate(...args)()
              }
            }
          }
      }];
  })
