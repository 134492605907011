import axios, { AxiosError } from 'axios'
import { api } from '@f/configs/app'
import {
  TreecodeCard,
  TreecodeGiftCreate,
  TreecodeGift,
  TreecodeCheck,
  TreecodeRedeemData,
  TreecodeRedeemResult,
  TreecodeRedeemQueueResult,
  TreecodeError,
  TreecodeGiftDeliveryType,
} from './types'
import { getRootScope, getUserService } from '../Angular'
import { registerAxiosAuthorizationInterceptor } from '@f/auth/helpers'
import { DataEventRansomRedeem } from '../TagManager/events'
import { TreecodeRedeemContext } from '@f/fsm/flows/TreecodeRedeem'
import { GiftType, IGiftType } from '@f/validation/types/dataLayer'
import { TrProduct } from '@f/@types/graphql'

const errorMessagesMap = {
  'Owner cannot redeem gift self': 'coupon.error.user_alone',
  'TreeCode Not Found': 'treecode.widget.error.generic',
  'Code exhausted or not enabled': 'treecode.widget.error.generic',
  'Code exhausted': 'redeemcoupon.done.closed.title',
  'Code already Redeemed': 'redeemcoupon.done.alreadyredeem.title',
  'No trees': 'redeemcoupon.done.notrees.title', // Frontend managed case
  'Product non selectable': 'redeemcoupon.done.closed.title',
  'None tree available': 'redeemcoupon.done.notrees.title',
}

const baseURL = `${api.treecodesUri.replace(/\/+$/, '')}/REST/Gifts`
const treecodeClient = axios.create({
  baseURL,
})
registerAxiosAuthorizationInterceptor(treecodeClient)

export const getNumberOfTree = (gift: TreecodeCheck) => {
  if (gift!.pickOne) return 1
  if (gift!.isVirgin) return gift!.products.length
  return gift!.products.length
}

export const getRansomType = (gift: TreecodeCheck) => {
  if (gift!.pickOne) return 'picker'
  if (gift!.isVirgin) return 'virgin'
  return 'simple'
}

export const getGiftType = () => {
  const params = new URLSearchParams(window.location.search)
  const giftType: IGiftType = GiftType.fromTreecodeGiftDeliveryType(
    params.get('intcmp') as TreecodeGiftDeliveryType
  )

  return giftType
}

export const getRedeemProduct = (
  context: TreecodeRedeemContext,
  products: TrProduct[]
): TrProduct => {
  if (context.gift?.pickOne) {
    return products.find((product) => product.id === context.picked)!
  }
  return products[0]
}

function handleTreecodeError({
  message,
  response,
}: AxiosError<TreecodeError>): never {
  const errorMessage = (response?.data?.message ||
    message) as keyof typeof errorMessagesMap
  throw Error(errorMessagesMap[errorMessage] || errorMessage)
}

function treecodeIsValid(treecode: unknown): boolean {
  return typeof treecode === 'string' && /^[\w\d]{6,10}$/.test(treecode)
}

export function createGift(gift: TreecodeGiftCreate): Promise<TreecodeGift> {
  return treecodeClient
    .post<TreecodeGift>('/gift/', gift)
    .then(({ data: gift }) => gift)
}

export function deleteGift(giftUUId: string) {
  if (!giftUUId) return Promise.reject()
  return treecodeClient.delete<{ delete: boolean }>('/gift/' + giftUUId)
}

export function removeTreeFromGift(giftUUId: string, treeId: string) {
  if (!giftUUId || !treeId) return Promise.reject()
  return treecodeClient.delete<{ delete: boolean }>(
    `/gift/${giftUUId}/${treeId}`,
  )
}

export function getCards() {
  return treecodeClient
    .get<TreecodeCard[]>('/giftCard/types')
    .then(({ data: cards }) => cards)
}

export function checkTreecode(
  treecode?: string
): Promise<TreecodeCheck | void> {
  if (!treecodeIsValid(treecode))
    return Promise.reject('treecode.widget.error.generic')
  return treecodeClient
    .get<TreecodeCheck>('/verify/' + treecode)
    .then(({ data }) => {
      if (!data.products.length) throw Error('No trees')
      return data
    })
    .catch(handleTreecodeError)
}

export function redeemTreecode(
  payload: TreecodeRedeemData
): Promise<TreecodeRedeemResult> {
  return treecodeClient
    .post<TreecodeRedeemResult>('/redeem/redeem', payload)
    .then(({ data }) => data)
    .catch(handleTreecodeError)
}

export async function waitRedeemResult(
  context: TreecodeRedeemContext
): Promise<TreecodeRedeemQueueResult> {
  try {
    const { data } = await treecodeClient.get<TreecodeRedeemQueueResult>(
      `/redeem/redeem/${context.redeemResult!.redeemQueueId}/status`,
    )
    if (data.success) {
      const userService = getUserService()
      const userSlug = getRootScope()?.userdata.info.slug
      if (userSlug && userService) {
        const treeType = getRedeemProduct(
          context,
          context.redeemResult!.products
        ).specie!.scientificName!.name
        DataEventRansomRedeem({
          logged: getRootScope()?.logged!,
          ransomCode: context.treecode!,
          position: 'treecode-redeem-flow',
          numberOfTree: getNumberOfTree(context.gift!),
          ransomType: getRansomType(context.gift!),
          giftType: getGiftType(),
          treeType,
        })
        userService.fetchAndUpdateDataLayerVariables(userSlug)
      }
    }
    return data
  } catch (e) {
    handleTreecodeError(e as AxiosError<TreecodeError>)
  }
}

export function needsPicking(treecodeData?: TreecodeCheck) {
  return !!(treecodeData?.pickOne && treecodeData?.products.length > 1)
}

export function sendResumeEmail(uuid: string | string[]) {
  return treecodeClient.post<unknown>('/gift/sendResume', { uuid })
}

export function getGiftCardUrl(uuid: string) {
  if (!uuid) return ''
  return `${baseURL}/giftCard/${uuid}`
}
