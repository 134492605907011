<template>
  <div class="flex">
    <LinkList :title="data.mission.label" :items="data.mission.items" />
    <LinkList :title="data.treedom.label" :items="data.treedom.items" />
    <div class="container">
      <h3 class="title">{{ $t('nav.whoWeAre.banner.title') }}</h3>
      <a :href="goToBlog" class="blog">
        <h5 class="title">Tree<strong>Blog</strong></h5>
        <img class="backgroundImage" :src="require('@f/images/navbar/blog.jpg')"  alt="blog"/>
      </a>
      <a :href="goToBlog" class="link">
        {{ $t('nav.whoWeAre.banner.link') }}
      </a>
    </div>
  </div>
</template>
<script>
import {defineComponent} from '@vue/composition-api'
import {getStateService} from "@f/services/Angular";

export default defineComponent({
  name: 'WhoWeAreMenu',
  components: { LinkList: () => import('./LinkList.vue') },
  data() {
    const state = getStateService()
    const reportLink = () => {
      if(this.$rootScope.ln === 'es' || this.$rootScope.ln === 'fr') {
        return `https://static.treedom.net/reports/01_Impact_Report_2022_EN_Ver1.pdf`
      }
      return `https://static.treedom.net/reports/01_Impact_Report_2022_${this.$rootScope.ln.toUpperCase()}_Ver1.pdf`
    }
    const goToBlog = () => state.href('blog')
    return {
      data: {
        mission: {
          label: 'nav.whoWeAre.mission.title',
          items: [
            {
              link: state.href('manifesto'),
              label: 'nav.whoWeAre.mission.manifesto',
            },
            {
              link: state.href('projects'),
              label: 'Il nostro metodo',
            },
            {
              link: state.href('countries'),
              label: 'nav.whoWeAre.mission.ourMethod',
            },
            {
              link: state.href('agroforestry'),
              label: 'nav.whoWeAre.mission.countries',
            }
          ]
        },
        treedom: {
          label: "nav.whoWeAre.company.title",
          items: [
            {
              link: state.href('aboutUs'),
              label: 'Treedom',
            },
            {
              link: state.href('team'),
              label: 'nav.whoWeAre.company.team',
            },
            {
              link: reportLink(),
              label: 'nav.whoWeAre.company.impactReport',
            },
            {
              link: state.href('page.register'),
              label: 'nav.whoWeAre.company.treesRegister',
            }
          ]
        }
      },
      goToBlog
    }
  }})

</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: stretch;
  width: min(100vw, 1364px);
  justify-content: center;
  & > * {
    flex: 1;
    padding: 41px;
    position: relative;
    &:not(:last-child):before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left:0;
      border-right: 1px solid var(--Neutral-dark, #D1D7E0);
      pointer-events: none;
    }
  }
  .blog {
    border-radius: 10px;
    min-width: max(297px, 100%);
    width: 100%;
    min-height: 217px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      color: var(--Neutral-base, #FFF);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 35px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 80% */
    }
    .backgroundImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: -1;
      inset: 0;
      translate: 30px 0;
      margin-left: -30px;
    }
  }


  .container {
    padding: var(--spacing-5);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-3);
    height: 100%;
    box-sizing: border-box;
    min-width: 400px;

    .title {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  .link {
    text-decoration: none;
    position: relative;
    color: #30A853;
    width: fit-content;
    &:before {
      content: '>';
      color: inherit;
      position: absolute;
      right: 0;
    }
    * {
      padding: 0 !important;
    }
  }

  .blog {
    border-radius: 10px;
    min-width: max(297px, 100%);
    width: 100%;
    min-height: 217px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    .title {
      color: var(--Neutral-base, #FFF);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 35px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 80% */
      span {
        color: var(--Neutral-base, #FFF) !important;
        font-weight: 800 !important;
      }
    }
    .backgroundImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: -1;
      inset: 0;
      translate: 30px 0;
      margin-left: -30px;
    }
  }
}
</style>
