import { IsoCode } from '@f/@types/localization'

declare global {
  interface Window {
    _iub: any // TODO: Declare _iub global
  }
}

type IubendaPolicyRef = string | Record<IsoCode, string | number>

interface IubendaServiceOptions {
  siteId?: string | number,
  lang?: IsoCode
  policyId?: IubendaPolicyRef
  configuration?: IubendaServiceConfiguration
  banner?: IubendaBannerStyle
}

type IubendaScriptsMap = Record<string, Partial<HTMLScriptElement>>

interface IubendaLink {
  href: string
  class?: string
}

type IubendaEvent =
  | 'preference_update'
  | 'consent_not_needed'
  | 'consent_given'
  | 'consent_rejected'
  | 'consent_purposes'

type IubendaCallback =
  | 'onActivationDone'
  | 'onBannerClosed'
  | 'onBannerShown'
  | 'onBeforePreload'
  | 'onCcpaAcknowledged'
  | 'onCcpaFirstAcknowledged'
  | 'onCcpaFirstOptOut'
  | 'onCcpaOptOut'
  | 'onConsentFirstGiven'
  | 'onConsentFirstRejected'
  | 'onConsentGiven'
  | 'onConsentRead'
  | 'onConsentRejected'
  | 'onCookiePolicyShown'
  | 'onError'
  | 'onFatalError'
  | 'onPreferenceExpressed'
  | 'onPreferenceExpressedOrNotNeeded'
  | 'onPreferenceFirstExpressed'
  | 'onPreferenceNotNeeded'
  | 'onReady'
  | 'onStartupFailed'

interface IubendaConfiguration {
  siteId: string | number
  cookiePolicyId: string | number
  lang: IsoCode
  enableCcpa?: boolean
  countryDetection?: boolean
  enableRemoteConsent?: boolean
  askConsentAtCookiePolicyUpdate?: boolean
  consentOnContinuedBrowsing?: boolean
  perPurposeConsent?: boolean
  ccpaAcknowledgeOnDisplay?: boolean
  floatingPreferencesButtonDisplay?: boolean | string
  invalidateConsentWithoutLog?: boolean
  reloadOnConsent?: boolean
  banner?: IubendaBannerStyle
  callback?: Partial<Record<IubendaCallback, (...args: any) => any>>
}

type IubendaServiceConfiguration = Partial<Omit<IubendaConfiguration, 'siteId' | 'policyId' | 'lang' | 'banner' | 'callback'>>

interface IubendaBannerStyle {
  closeButtonDisplay?: boolean
  acceptButtonDisplay?: boolean
  customizeButtonDisplay?: boolean
  acceptButtonColor?: string
  acceptButtonCaptionColor?: string
  customizeButtonColor?: string
  customizeButtonCaptionColor?: string
  rejectButtonDisplay?: boolean
  rejectButtonColor?: string
  rejectButtonCaptionColor?: string
  position?: string
  backgroundOverlay?: boolean
  textColor?: string
  backgroundColor?: string
  fontSize?: string
  listPurposes?: boolean
  explicitWithdrawal?: boolean
}

interface IubendaPreference {
  consent?: boolean
  purposes?: {
    1: boolean
    2: boolean
    3: boolean
    4: boolean
    5: boolean
  }
}

class IubendaService {
  siteId?: string
  lang?: IsoCode
  policyId?: string
  configuration?: IubendaServiceConfiguration
  banner?: IubendaBannerStyle
  scriptsMap?: IubendaScriptsMap

  private defaultConfiguration: IubendaServiceConfiguration = {
    enableCcpa: true,
    countryDetection: true,
    invalidateConsentWithoutLog: true,
    reloadOnConsent: false,
    enableRemoteConsent: true,
    askConsentAtCookiePolicyUpdate: true,
    consentOnContinuedBrowsing: false,
    perPurposeConsent: true,
    ccpaAcknowledgeOnDisplay: false,
    floatingPreferencesButtonDisplay: false,
  }

  private defaultBannerStyle: IubendaBannerStyle = {
    closeButtonDisplay: false,
    acceptButtonDisplay: true,
    customizeButtonDisplay: true,
    acceptButtonColor: '#30A853',
    acceptButtonCaptionColor: 'white',
    customizeButtonColor: '#212121',
    customizeButtonCaptionColor: 'white',
    rejectButtonDisplay: true,
    rejectButtonColor: '#000000',
    rejectButtonCaptionColor: 'white',
    listPurposes: true,
    explicitWithdrawal: true,
    position: 'float-bottom-center',
    backgroundOverlay: true,
    textColor: 'white',
    backgroundColor: '#000001',
    fontSize: '9pt'
  }

  private eventsListeners: Partial<Record<IubendaEvent, ((args?: any) => void)[]>> = {}

  constructor(
    options: IubendaServiceOptions,
    scripts?: IubendaScriptsMap,
    mountList?: string[],
    mountElement?: HTMLElement
  ) {
    if (!scripts) return
    this.scriptsMap = scripts
    this.setOptions(options)
    this.injectScripts(mountElement, mountList)
  }

  get urls(): Record<string, IubendaLink> | undefined {
    return this.policyId
      && {
        privacy: {
          href: 'https://www.iubenda.com/privacy-policy/' + this.policyId,
          class: 'iubenda-nostyle no-brand iubenda-embed'
        },
        cookie: {
          href: `https://www.iubenda.com/privacy-policy/${this.policyId}/cookie-policy`,
          class: 'iubenda-nostyle no-brand iubenda-embed'
        }
      } || undefined
  }

  setOptions(
    { siteId, lang, policyId, configuration, banner }: IubendaServiceOptions,
    scripts?: IubendaScriptsMap,
    mountList?: string[],
    mountElement?: HTMLElement
  ): IubendaService {
    this.siteId = String(siteId)
    this.lang = lang
    this.policyId = this.resolvePolicyId(policyId)
    this.configuration = configuration
    this.banner = banner
    this.setGlobalConfiguration()
    if (scripts) {
      this.scriptsMap = scripts
      this.injectScripts(mountElement, mountList)
    }
    return this
  }

  mountScript(scriptId: string, mount?: HTMLElement) {
    if (!this.scriptsMap || !(scriptId in this.scriptsMap)) throw Error(`The script ${scriptId} is not available`)
    const { src, charset, async } = this.scriptsMap[scriptId]
    if (!src) throw Error(`The script ${scriptId} has no source url`)
    const mountedScript = document.getElementById(scriptId)
    if (mountedScript) mountedScript.remove()
    const el = document.createElement('script')
    el.id = scriptId
    el.src = src
    if (charset) el.charset = charset
    if (async) el.async = true
    if (process.env.NODE_ENV === 'development') {
      el.addEventListener('load', this.scriptLoadSuccess(el))
      el.addEventListener('error', this.scriptLoadError(el))
    }
    if (mount) {
      mount.appendChild(el)
    } else {
      const firstScript = document.getElementsByTagName('script')[0]
      if (firstScript?.parentElement) {
        firstScript.parentElement.insertBefore(el, firstScript)
      } else {
        document.head.appendChild(el)
      }
    }
  }

  on(event: 'preference_update', callback: (iub: typeof window['_iub']) => void): void
  on(event: 'consent_not_needed', callback: () => void): void
  on(event: 'consent_given', callback: () => void): void
  on(event: 'consent_rejected', callback: () => void): void
  on(event: 'consent_purposes', callback: (purposes: IubendaPreference['purposes']) => void): void
  on(event: IubendaEvent, callback: (...args: any) => void): void {
    this.eventsListeners = {
      ...this.eventsListeners,
      [event]: this.eventsListeners[event]?.length ? [...this.eventsListeners[event], callback] : [callback]
    }
  }

  private resolvePolicyId(policyId?: IubendaPolicyRef): string | undefined {
    if (!policyId) return undefined
    return typeof policyId === 'string' || typeof policyId === 'number'
      ? String(policyId)
      : this.lang && String(policyId[this.lang]) || undefined
  }

  private injectScripts(mountElement?: HTMLElement, mountList?: string[]) {
    if (!this.scriptsMap) return
    const scriptsList = mountList?.length
      ? mountList
      : Object.keys(this.scriptsMap)
    if (!scriptsList?.length) throw Error('No scripts to be mounted')
    for (const scriptKey of scriptsList) {
      this.mountScript(scriptKey, mountElement)
    }
  }

  private setGlobalConfiguration() {
    if (!(this.siteId && this.policyId)) return
    const configuration: IubendaConfiguration = {
      siteId: this.siteId,
      cookiePolicyId: this.policyId,
      lang: this.lang || 'en',
      ...this.defaultConfiguration,
      ...this.configuration,
      banner: { ...this.defaultBannerStyle, ...this.banner },
      callback: {
        onPreferenceExpressedOrNotNeeded: this.onPreferenceUpdated.bind(this)
      }
    }
    window._iub = window._iub || []
    window._iub.csConfiguration = configuration
  }

  private onPreferenceUpdated(preference: IubendaPreference) {
    this.emitEvent('preference_update', window._iub)
    if (!preference) {
      this.emitEvent('consent_not_needed')
    } else {
      if (preference.consent === true) {
        this.emitEvent('consent_given')
      } else if (preference.consent === false) {
        this.emitEvent('consent_rejected')
      } else if (preference.purposes) {
        this.emitEvent('consent_purposes', preference.purposes)
      }
    }
  }

  private emitEvent(event: 'preference_update', iub: any): void
  private emitEvent(event: 'consent_not_needed'): void
  private emitEvent(event: 'consent_given'): void
  private emitEvent(event: 'consent_rejected'): void
  private emitEvent(event: 'consent_purposes', purposes: IubendaPreference['purposes']): void
  private emitEvent(event: IubendaEvent, args?: any): void {
    for (const listener of this.eventsListeners[event] || []) {
      listener(args)
    }
  }

  private scriptLoadSuccess(element: HTMLScriptElement) {
    return (event: Event) => {
      if (event.target) {
        console.log(`${(event.target as HTMLElement).id} script has been successfully loaded.`)
      }
      element.removeEventListener('load', this.scriptLoadSuccess(element))
    }
  }

  private scriptLoadError(element: HTMLScriptElement) {
    return (error: ErrorEvent) => {
      if (error.target) {
        console.log(`Error while loading ${(error.target as HTMLElement).id} script.`)
      }
      element.removeEventListener('error', this.scriptLoadError(element))
    }
  }
}

let iubendaService: IubendaService

export function getIubendaService(options: IubendaServiceOptions): IubendaService
export function getIubendaService(): IubendaService | undefined
export function getIubendaService(options?: IubendaServiceOptions, scripts?: Record<string, Partial<HTMLScriptElement>>, scriptsList?: string[]): IubendaService | undefined {
  return !!iubendaService
    ? !!options ? iubendaService.setOptions(options, scripts, scriptsList) : iubendaService
    : !!options ? (iubendaService = new IubendaService(options, scripts, scriptsList)) : undefined
}
