
import Vue from 'vue'
import { lockPageScroll, unlockPageScroll } from '@f/utils/dom/scroll'

export default Vue.extend({
  name: 'Overlay',
  functional: true,
  props: {
    top: { type: Boolean || String, default: false },
    right: { type: Boolean || String, default: false },
    bottom: { type: Boolean || String, default: false },
    left: { type: Boolean || String, default: false },
    center: { type: Boolean || String, default: false },
    white: { type: Boolean || String, default: false },
    black: { type: Boolean || String, default: false },
    page: { type: Boolean || String, default: false },
    legacy: { type: Boolean || String, default: false },
    toolbar: { type: Boolean || String, default: false },
    absolute: { type: Boolean || String, default: false },
  },
  render(h, { data, props, children, listeners }) {
    function isNotFalse(prop: unknown): boolean {
      return prop !== undefined && prop !== false && prop !== "false"
    }

    const {
      class: contentClass,
      staticClass: contentStaticClass,
      style: contentStyle,
      key,
      ...contextRootData
    } = data

    const overlayListeners: Record<string, (ev: Event) => void> = {}

    if (listeners.click) {
      overlayListeners.click = ev => {
        if (ev.target !== ev.currentTarget) return
        Array.isArray(listeners.click)
          ? listeners.click.map(list => list(ev))
          : listeners.click(ev)
      }
    }

    const transitionData = {
      key,
      props: {
        name: 'overlay_slide',
        css: true,
        duration: 600,
        appear: true,
      },
      on: {
        beforeEnter: lockPageScroll,
        afterLeave: unlockPageScroll,
      }
    }

    const rootClass = {
      'overlay--top': isNotFalse(props.top),
      'overlay--right': isNotFalse(props.right),
      'overlay--bottom': isNotFalse(props.bottom),
      'overlay--left': isNotFalse(props.left),
      'overlay--center': isNotFalse(props.center),
      'overlay--page': isNotFalse(props.page),
      'overlay--legacy-modal': isNotFalse(props.legacy),
      'overlay--toolbar': isNotFalse(props.toolbar),
      'overlay--absolute': isNotFalse(props.absolute),
    }

    const rootData = {
      ...contextRootData,
      staticClass: 'overlay',
      class: rootClass, // FIXME: modal hack
      on: overlayListeners,
    }

    const contentPositionClass = {
      'overlay__content--top': isNotFalse(props.top),
      'overlay__content--right': isNotFalse(props.right),
      'overlay__content--bottom': isNotFalse(props.bottom),
      'overlay__content--left': isNotFalse(props.left),
      'overlay__content--center': isNotFalse(props.center),
    }

    const contentClassComposed = Array.isArray(contentClass)
      ? [ ...contentClass, contentStaticClass, contentPositionClass ]
      : [ contentClass, contentStaticClass, contentPositionClass ]

    const overlayChildren = [ h('div', { class: contentClassComposed, style: contentStyle }, children) ]

    if (isNotFalse(props.white) || isNotFalse(props.black)) {
      const overlayClass = isNotFalse(props.white)
        ? 'overlay__layer--white'
        : 'overlay__layer--black'
      const overlayData = {
        staticClass: overlayClass,
        on: overlayListeners
      }
      overlayChildren.unshift(h('div', overlayData))
    }

    return h(
      'transition',
      transitionData,
      [ h('div', rootData, overlayChildren) ]
    )
  }
})
