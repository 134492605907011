angular.module('emissionService', [])
.factory('Emission', ['$http', 'Utility', 'Routing', function ($http, Utility, Routing) {
  var routing = Routing
  var routeJs = function (route, params) {
    var url = routing.route(route, params)

    // funzione per pulire l'url dal' bug di fos_user che mantiene i parameters nelle varie chiamate
    if (url.indexOf('?') > -1) url = url.substring(0, url.indexOf('?'))

    return url
  }

  var emissionFactory = {}

  // API Calls
  emissionFactory.co2 = function () {
    return $http.get(Utility.root() + 'json/co2_cached.json')
    .then(function(response) {
      return response.data || response
    })
  }

  emissionFactory.calculateDistance = function (type, post) {
    return $http.post(routeJs('treedom_emmission_calculate_distance', {type: type}), post)
    .then(function(response) {
      return response.data || response
    })
  }

  emissionFactory.saveEmissions = function (post) {
    return $http.post(routeJs('treedom_emmissions_save'), post)
    .then(function(response) {
      return response.data || response
    })
  }

  emissionFactory.neutralize = function (emissionId) {
    return $http.post(routeJs('treedom_user_neutralize_emission', {emissionId: emissionId}))
    .then(function(response) {
      return response.data || response
    })
  }

  emissionFactory.neutralizeAll = function (emissionId) {
    return $http.post(routeJs('treedom_user_neutralize_all_emission'))
    .then(function(response) {
      return response.data || response
    })
  }

  // Utility methods
  const specialMultiplierCases = {
    steak: 0.15,
    rice: 0.1,
    pasta: 0.1,
    hoover: 0.5
  }
  class Emission {
    constructor(emission) {
      Object.assign(this, emission || {});

      this.defaultUnit = (emission.id === 'tv')
        ? 'minute'
        : emission.defaultValue || emission.unit || Object.keys(emission.co2).find(key => key === 'kg' || key === 'km' || key === '€');
      
      this.defaultCo2PerUnit = ((emission.id === 'tv')
        ? Number(emission.co2['hours']) / 60
        : emission.co2[this.defaultUnit] || 1000) / 1000;
      
      this.multiplier = specialMultiplierCases[emission.id] || 1
    }
  }
  
  class Emissions {
    constructor(emissionsList) {
      Object.assign(this, Object.keys(emissionsList || {}).reduce((list, emission) => ({ ...list, [emission]: new Emission(emissionsList[emission]) }),{}))
    }
  }
  Object.defineProperty(emissionFactory, 'emissions', {
    get() {
      return {
        get() {
          return new Emissions(window.treedomEmissions && window.treedomEmissions.emission || {})
        },
        toArray() {
          return Object.values(this.get())
        },
        sorted(direction) {
          return this.toArray().sort((a, b) => {
            return (direction === 'desc') ? b.defaultCo2PerUnit - a.defaultCo2PerUnit : a.defaultCo2PerUnit - b.defaultCo2PerUnit
          })
        },
        random(min = 0, max = 99999, units = ['km', 'kg', 'number', 'quantity']) {
          const emissions = this.toArray()
          const filtered = emissions.filter(emission => {
            return emission.defaultCo2PerUnit >= min && emission.defaultCo2PerUnit <= max && units.includes(emission.defaultUnit)
          })
          return filtered[Math.floor(filtered.length * Math.random())]
        },
        randomExample(co2, units = ['km', 'kg', 'number', 'quantity']) {
          const { id, category, defaultUnit, defaultCo2PerUnit } = this.random(co2/1000, co2, units)
          return {
            id,
            category,
            unit: (['km', 'kg'].includes(defaultUnit)) ? defaultUnit : '',
            qt: Math.floor(co2 / defaultCo2PerUnit)
          }
        }
      }
    }
  })

  return emissionFactory
}])
