
































































import { defineComponent, ref, computed, PropType } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import HUDPage from '@f/components/Map/HUD/HUDPage.vue'
import DotLoader from '@f/components/DotLoader.vue'
import { USER_TREES } from '@f/graphql/queries'
import useRoutes from '@f/composables/useRoutes'
import Treecon from '@f/components/Treecons/Treecon.vue'
import {
  TrCountry,
  TrTree,
  TrTreeRowFragmentFragment,
  TrUserTreesQuery,
  TrUserTreesQueryVariables
} from '@f/@types/graphql'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import useUserInfo from '@f/composables/useUserInfo'
import { DataEventMap } from '@f/services/TagManager/events'
import { getVisibleImage } from '@f/services/Tree'

// @ts-ignore
export default defineComponent({
  name: 'HUDOrganizationCountryTrees',
  components: {
    HUDPage,
    DotLoader,
    Treecon
  },
  props: {
    country: Object as PropType<TrCountry>,
    userId: Number,
    perPage: {
      type: Number,
      default: 30,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { href } = useRoutes()

    const countryName = computed(() => props.country?.name)
    const countryFlag = computed(() => props.country?.flagImage)
    const countryBackground = computed(() => props.country?.projectBackground?.medium)

    const treesVariables = computed(() => {
      return {
        userId: Number(props.userId),
        countryId: Number(props.country?.id),
        mine: true,
        limit: props.perPage,
        after: ''
      }
    })
    const { result, loading } = useQuery<TrUserTreesQuery, TrUserTreesQueryVariables>(
      USER_TREES,
      () => treesVariables.value,
      () => ({
        enabled: !!(props.userId && props.country?.id),
        notifyOnNetworkStatusChange: true,
      }) as any,
    )
    const trees = computed(() => {
      return result.value?.userTrees?.trees
    })

    const hasMore = computed(() => {
      return result.value?.userTrees?.pageInfo?.hasNextPage
    })

    const getTreeItemIcon = (tree: TrTree) => {
      return getVisibleImage(tree)?.small
    }

    const isMobile = useMediaQuery(maxWidth('md'))

    const onTreeSelect = (treeSelected: string ) => {
      DataEventMap({ treeSelected })
    }

    return {
      countryFlag,
      countryName,
      countryBackground,
      loading,
      trees,
      href,
      getTreeItemIcon,
      isMobile,
      onTreeSelect,
      hasMore,
    }
  }
})
