import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import JWT from 'jsonwebtoken'
import { Hub, HubCapsule } from '@aws-amplify/core'

import {
  AuthProvider,
  AuthUserInfo,
  FederatedSignInOptions,
  FederatedSignInResponse,
  IdentityProvider,
  ListenerDisposer,
  OnAuthStateChangeListener,
  SignInCredentials,
  SignUpParams
} from '.'

// TODO remove when https://github.com/aws-amplify/amplify-js/pull/9370 is merged
// @ts-ignore
const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth)
// @ts-ignore
Auth._handleAuthResponse = (url) => {
  const configuration = Auth.configure()
  // @ts-ignore
  if (!url.includes(configuration.oauth.redirectSignIn)) return
  return _handleAuthResponse(url)
}


const lastSocialLoginIdentityProvider = {
  get(): IdentityProvider {
    return localStorage.getItem('Cognito.Custom.LastSocialLogin') as unknown as IdentityProvider
  },
  set(value: IdentityProvider) {
    localStorage.setItem('Cognito.Custom.LastSocialLogin', value)
  },
}

const retrySocialLogin = async () => {
  const identityProvider = lastSocialLoginIdentityProvider.get()
  const cognitoProvider = retrieveSocialCognitoProvider(identityProvider)

  if (!cognitoProvider) return null

  return Auth.federatedSignIn({
    provider: cognitoProvider,
  })
}

const onAuthStateChange = (listener: OnAuthStateChangeListener): ListenerDisposer => {
  const authListener = (data: HubCapsule) => {
    switch (data.payload.event) {
      case 'customFederatedSignIn':
      case 'signIn':
        listener({ logged: true })
        break;
      case 'signUp':
        listener({ logged: true })
        break;
      case 'signOut':
        listener({ logged: false })
        break;
      case 'signIn_failure':
        break;
      case 'configured':
        break;
    }
  }
  Hub.listen('auth', authListener);
  return () => Hub.remove('auth', authListener)
}

type SignOutSuccessAuthMessage = {
  name: 'signOut_success'
}
type SignInSuccessAuthMessage = {
  name: 'signIn_success'
}
type SignInFailedAuthMessage = {
  name: 'signIn_failed'
  message: string
}
type SignInAbortAuthMessage = {
  name: 'signIn_abort'
}
type AuthMessage = SignInSuccessAuthMessage | SignInFailedAuthMessage | SignInAbortAuthMessage | SignOutSuccessAuthMessage
const waitAuthStatusAndSignal = (target: Window) => {
  Hub.listen('auth', ({ payload: { event, data }}) => {
    switch (event) {
      case 'signIn':
        target.postMessage({
          name: 'signIn_success',
        }, location.origin)
        window.close()
        return
      case 'signIn_failure':
        if (isFirstSocialLoginMessage(data.message)) {
          retrySocialLogin()
          return
        }
        target.postMessage({
          name: 'signIn_failed',
          message: data.message,
        }, location.origin)
        window.close()
        return
      case 'signOut':
        target.postMessage({
          name: 'signOut_success',
        }, location.origin)
        window.close()
        return
    }
  })
}

export class SignInFailedError extends Error {}
export class SignInAbortError extends Error {}
const waitForSignInCompleted = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const listener = (event: MessageEvent) => {
      if (!event.data) return
      const data = event.data as AuthMessage
      switch (data.name) {
        case 'signIn_success':
          resolve()
          window.removeEventListener('message', listener)
          Hub.dispatch('auth', {
            event: 'customFederatedSignIn',
          })
          return
        case 'signIn_failed':
          console.error('CognitoProvider event error signIn_failed', data)
          reject(new SignInFailedError(data.message))
          window.removeEventListener('message', listener)
          return
        case 'signIn_abort':
          console.error('CognitoProvider event error signIn_abort')
          reject(new SignInAbortError("SignIn Aborted by user"))
          window.removeEventListener('message', listener)
          return
        default:
          console.log('CognitoProvider event received', data)
          return
      }
    }
    window.addEventListener('message', listener, false);
  })
}

const retrieveSocialCognitoProvider = (provider: IdentityProvider) => {
  switch (provider) {
    case 'google':
      return CognitoHostedUIIdentityProvider.Google
    case 'facebook':
      return CognitoHostedUIIdentityProvider.Facebook
    case 'apple':
      return CognitoHostedUIIdentityProvider.Apple
    default:
      console.error(
          `cognito-provider: missing identity provider for ${provider}`
      )
      return null
  }
}

const waitForSignOutCompleted = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const listener = (event: MessageEvent) => {
      if (!event.data) return
      const data = event.data as AuthMessage
      switch (data.name) {
        case 'signOut_success':
          resolve()
          break
      }
      window.removeEventListener('message', listener)
    }
    window.addEventListener('message', listener, false);
  })
}

const isFirstSocialLoginMessage = (message: string) => {
  if (!message) return false
  return message.includes('Already+found+an+entry+for+username');
}
const isFirstSocialLoginError = (error?: Error) => {
  if (!error) return false
  return isFirstSocialLoginMessage(error.message)
}

type TokenDecoded = {
  preferred_username: string
  email: string
  email_verified: boolean
  locale: string
  name: string
  picture: string
} & { [key: string]: string }

export type CognitoStorage = {
  setItem(key: string, value: string): void
  getItem(key: string): string | null
  removeItem(key: string): void
  clear(): void
}
export type CognitoProviderConfig = {
  region: string
  userPoolId: string
  userPoolWebClientId: string
  mandatorySignIn: boolean
  storage: CognitoStorage
  authenticationFlowType: string
  oauth: {
    domain: string
    scope: string[]
    responseType: string
    redirectSignIn: string
    redirectSignOut: string
    urlOpener: (url: string, redirectUrl: string) => Promise<any>
  }
}

export class CognitoProvider implements AuthProvider {
  instance = Auth

  constructor(config: CognitoProviderConfig) {
    if (window.opener) {
      waitAuthStatusAndSignal(window.opener)
    }
    this.instance.configure(config)
  }

  getProviderName(): string {
    return 'cognito'
  }

  async currentUserInfo(): Promise<AuthUserInfo> {
    const jwt = await this.getJwtToken()
    if (!jwt) {
      throw new Error('CognitoProvider.currentUserInfo: user not authenticated')
    }
    const attributes = JWT.decode(jwt) as TokenDecoded
    return {
      id: Number(attributes['custom:treedom_user_id']),
      slug: attributes.preferred_username,
      email: attributes.email,
      email_verified: attributes.email_verified,
      locale: attributes.locale,
      name: attributes.name,
      roles: attributes['custom:roles']?.split('|') || [],
      userType: attributes['custom:user_type'],
      avatarURL: {
        small: attributes.picture,
        medium: attributes.picture,
        large: attributes.picture,
        xlarge: attributes.picture
      }
    } as AuthUserInfo
  }

  async signIn(credentials: SignInCredentials, firstLoginAfterImport?: boolean): Promise<void> {
    if (firstLoginAfterImport) {
      return this.firstLoginAfterImportSignIn(credentials)
    }
    return this.defaultSignIn(credentials)
  }

  private async defaultSignIn(credentials: SignInCredentials): Promise<void> {
    await this.instance.signIn({
      username: credentials.username,
      password: credentials.password
    })
  }

  private async firstLoginAfterImportSignIn(credentials: SignInCredentials): Promise<void> {
    this.instance.configure({
      ...this.instance.configure(),
      authenticationFlowType: 'CUSTOM_AUTH',
    })
    const user = await this.instance.signIn(credentials.username, '')
    if (user.challengeName === 'CUSTOM_CHALLENGE') {
      await this.instance.sendCustomChallengeAnswer(user, JSON.stringify({
         // TODO add challenge info
        type: 'imported_login',
        password: credentials.password,
      }))
    }
  }

  async federatedSignIn(options: FederatedSignInOptions): Promise<FederatedSignInResponse> {
    const provider = retrieveSocialCognitoProvider(options.identityProvider)

    if (provider) {
      await this.instance.federatedSignIn({ provider })
    }

    lastSocialLoginIdentityProvider.set(options.identityProvider)
    await waitForSignInCompleted()
    return {
      isRegistration: await this.isFirstLogin(),
    }
  }

  async isFirstLogin(): Promise<boolean> {
    const jwt = await this.getJwtToken()
    if (!jwt) return false
    return Boolean((JWT.decode(jwt) as TokenDecoded)['custom:is_first_login'])
  }

  async signUp(params: SignUpParams) {
    await this.instance.signUp({
      username: params.username,
      password: params.password,
      attributes: {
        email: params.username,
        locale: params.attributes.locale,
        given_name: params.attributes.firstName,
        family_name: params.attributes.lastName,
        'custom:user_type': 'Private',
      },
      clientMetadata: {
        countryCode: params.attributes.countryCode || '',
        acceptCommunications: String(params.attributes.allowNewsletter && params.attributes.allowPromotion),
      },
    })
    await this.instance.signIn({
      username: params.username,
      password: params.password,
    })
  }

  async impersonate(username: string): Promise<void> {
    this.instance.configure({
      ...this.instance.configure(),
      authenticationFlowType: 'CUSTOM_AUTH',
    })
    const impersonatorToken = await this.getJwtToken()
    await this.signOut()
    const user = await this.instance.signIn(username, '')
    if (user.challengeName === 'CUSTOM_CHALLENGE') {
      await this.instance.sendCustomChallengeAnswer(user, JSON.stringify({
        type: 'impersonate',
        impersonatorToken,
      }))
    }
  }

  async getJwtToken(): Promise<string | null> {
    if (!(await this.isAuthenticated())) return null
    const session = await this.instance.currentSession()
    const token = session.getIdToken()
    return token.getJwtToken()
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await this.instance.currentSession()
      return true
    } catch (error) {
      console.warn(error)
    }
    return false
  }

  async signOut(): Promise<void> {
    try {
      await this.instance.signOut()
    } catch (e) {
      // needed to avoid social signOut to timeout
      if (e.message === 'Signout timeout fail') {
        return
      }
      console.error(e)
    }
  }

  onStateChange(listener: OnAuthStateChangeListener): ListenerDisposer {
    return onAuthStateChange(listener);
  }

  async forgotPassword(username: string): Promise<void> {
    await this.instance.forgotPassword(username)
  }

  async forgotPasswordConfirm(username: string, token: string, password: string): Promise<void> {
    await this.instance.forgotPasswordSubmit(username, token, password)
    await this.instance.signIn({
      username,
      password,
    })
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const user = await this.instance.currentAuthenticatedUser()
    await this.instance.changePassword(user, oldPassword, newPassword)
  }
}
