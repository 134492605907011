import { DataEventToggleFollow } from '@f/services/TagManager/events';

angular.module('ngApp')
.controller('viewPeopleCardController', ['$scope', '$rootScope', 'Utility', 'User', 'Modal', function ($scope, $rootScope, Utility, User, Modal) {
  // Controller variables
  var ctrl = this
  ctrl.slug = $scope.attrs.slug || ''
  ctrl.avatar = $scope.attrs.avatar || ''
  ctrl.firstname = $scope.attrs.firstname || ''
  ctrl.lastname = $scope.attrs.lastname || ''
  ctrl.userType = $scope.attrs.userType || 'Private'

  /**
   * Segui/Smetti di seguire l'utente della pagina.
   * @param {string} userSlug - Slug dell'utente che si vuole seguire.
   */
  ctrl.toggleFollow = function () {
    if (!ctrl.slug) return

    if (!$rootScope.user) {
      Modal.open({templateUrl: 'login.html', easyClose: false, loginBackground: true })
      return
    }

    Utility.disableButton()

    User.toggleFollow(ctrl.slug)
    .then(function(res) {
      const data = res.data || res
      $rootScope.userdata.following = data

      const shouldFollow = data.includes(ctrl.slug)
      DataEventToggleFollow(shouldFollow, 'user-card','user');

      Utility.enableButton()
    })
    .catch(function() {
      Utility.enableButton()
    })
  }
}])
