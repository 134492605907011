


















































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { IsoCode } from '@f/@types/localization'
import ShowLanguages from '@f/mixins/ShowLanguages'
import Icon from '@f/components/Icon.vue'
import Popover from "@f/components/Popover.vue";
import { DataEventDownload } from '@f/services/TagManager/events';

@Component({
  name: 'MediaKit',
  inheritAttrs: false,
  components: {
    Icon,
    Popover,
  }
})
export default class MediaKit extends Mixins(ShowLanguages) {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  @Prop({ type: [ String, Object ]}) readonly url!: string | Record<IsoCode, string>
  @Prop({ type: [ String, Object ], required: false }) readonly label!: string | Record<IsoCode, string>
  @Prop({ type: Object, required: false }) readonly description!:  string | Record<IsoCode, string>
  @Prop({ type: Boolean, required: false  }) readonly archived!: boolean
  @Prop({ type: Object, required: false }) readonly validityDate!: Record<string, string>
  @Prop({ type: String }) readonly icon!: string
  @Prop({ type: String, default: "settings" }) readonly location!: "settings" | "profile"

  /*--- DATA ----------*/
  dropdown = false
  
  /*--- COMPUTED ------*/

  get disabled() {
    return !this.url || (typeof this.url === 'object' && !Object.values(this.url).join(''))
  }

  get activeUrl() {
    return typeof this.url === 'object'
      ? this.$t(this.url)
      : this.url
  }

  get hasAdditionalUrls() {
    return false // FIXME: activate language selector when legacy styles will be dismissed
    // return !!(this.additionalUrls && Object.keys(this.additionalUrls).length)
  }

  get cardStyle() {
    return {
      backgroundImage: `url(${this.icon})`
    }
  }

  get additionalUrls(): Record<IsoCode, string> | void | null {
    if (!this.url || typeof this.url === 'string') return null;
    return (Object.keys(this.url) as IsoCode[])
      .reduce((urlMap, language) => {
        return language !== this.activeLanguage
          && !!(this.url as Record<IsoCode, string>)[language]
          ? { ...urlMap, [language]: (this.url as Record<IsoCode, string>)[language] }
          : urlMap
      }, {} as Record<IsoCode, string>)
  }

  get dataAttributes() {
    return Object.keys(this.$attrs)
      .reduce((dataMap, attr) => {
        const key = `data-mk-${attr}`
        return {
          ...dataMap,
          [key]: this.$attrs[attr]
        }
      }, {})
  }
  
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  
  /*--- METHODS -------*/
  urlCheckGuard(event: MouseEvent) {
    const anchorElement = event.target! as HTMLElement
    if (!anchorElement.getAttribute('href')) {
      event.preventDefault();
      this.$state.go('business.plant-a-tree');
    } else if(this.activeUrl) {
      DataEventDownload('business-media-kit', this.activeUrl, window.location.pathname);
    }
  }
}
