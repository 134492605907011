
const treeQuery = _language => {
    return `pageInfo{
      hasNextPage
      startCursor
      endCursor
    }
    edges{
      id
      treeId
      status
      lat
      lng
      location
      name
      timezone
      dayTime
      user{
        id
        slug
        title
        avatar{
          small
        }
        userType
      }
      initialUser{
        id
        slug
        title
        avatar{
          small
        }
        userType
      }
      gift{
        uuid
        code
        locale
        owner {
          firstName
          lastName
        }
        message {
          ${_language}
        }
        giftTag
        deliveryType
        email
        sendAt {
          date
          timezone
        }
      }
      event{
        id
        slug
        avatar{
          small
        }
        name
        published
        user{
          slug
          userType
        }
      }
      tankSize
      specie{
        id
        name{
          ${_language}
        }
        icon{
          medium
        }
        scientificName{
          name
        }
        size
        originator{
          id
          slug
          title
          avatar{
            small
          }
          nurseryLat
          nurseryLng
          location
          timezone
          dayTime
        }
        country{
          slug
          name{
            ${_language}
          }
          flag{
            small
            medium
          }
        }
      }
      limited{
        limitedName{
          ${_language}
        }
        limitedNameSubTitle{
          ${_language}
        }
        icon{
          medium
        }
        symbol{
          medium
        }
      }
      coupled{
        id
        treeId
        name
        gift{ uuid }      
        user{
          id
          slug
          title
          avatar{
            small
          }
        }
        initialUser{
          id
          slug
          title
          avatar{
            small
          }
        }      
        specie{
          name{
            ${_language}
          }
          icon{
            small
          }
        }
        limited{
          limitedName{
            ${_language}
          }
          limitedNameSubTitle{
            ${_language}
          }
          icon{
            small
          }
        }
      }
      likes{
        id
        slug
        title
        avatar{
          small
          medium
          large
        }
      }    
      actions{
        type
        data
        done
        isVibrating
        treeIcon
      }
    }`;
}

const userSummaryQuery = (_userId, _language) => {
    return `userSummary: User_Summary(id:${_userId}){
    mine
    gifted
    liked
    products{
      id
      specie{
        id
        name{
            ${_language}
        }
        scientificName{
          id
          name
        }
        icon{
          small
          medium
        }
      }
      limited{
        limitedName{
          ${_language}
        }
        limitedNameSubTitle{
          ${_language}
        }
        icon{
          small
          medium
        }
      }
    }
    countries{
      country{
        slug
        name{
          ${_language}
        }
        flag{
          medium
        }
      }
      count
    }
  }`;
};


const userInitialTreesQuery = (_userId, _language) => {
    const _treeQuery = treeQuery(_language);
    return `mine: Tree_page(userId: ${_userId}, mine:true, limit: 11){${_treeQuery}}
            gifted: Tree_page(userId: ${_userId}, gifted:true, limit: 11){${_treeQuery}}
            liked: Tree_page(userId: ${_userId}, liked:true, limit: 11){${_treeQuery}}
            ${userSummaryQuery(_userId, _language)}`;

};

const iframePagedTreesQuery =  (_userId, _after, _language) => {
    const _treeQuery = treeQuery(_language);
    return `trees:Tree_page(userId: ${_userId}, ${ _after ? `after: "${_after}" ,` : ""} limit: 20){${_treeQuery}}`;

};

const iframePagedEventTreesQuery = (_eventId, _after, _language) => {
    const _treeQuery = treeQuery(_language);
    return `trees:Tree_page(eventId: ${_eventId}, ${ _after ? ` after: "${_after}", ` : ""} limit: 20){${_treeQuery}}`;

};

const userPageTreesQuery = (_userId, _filter, _after, _language) => {
    const _treeQuery = treeQuery(_language);
    const filter = (_filter == "gifted" ?  "gifted:true" : (
        _filter == "liked" ? "liked:true" : "mine:true"
    ));
    return `trees:Tree_page(userId: ${_userId}, ${filter}, ${ _after ? ` after: "${_after}", ` : ""}  limit: 12){${_treeQuery}}`;

};

const businessUserInitialTreesQuery = (_userId, _language) => {
    const _treeQuery = treeQuery(_language);
    return `trees:Tree_page(userId:  ${_userId}, limit:12){${_treeQuery}}
            ${userSummaryQuery(_userId, _language)}`;
};

const businessUserPageTreesQuery = (_userId, _after, _language) => {
    const _treeQuery = treeQuery(_language);
    return `trees:Tree_page(userId: ${_userId}, ${ _after ? ` after: "${_after}", ` : ""}  limit: 12){${_treeQuery}}`;
};

const eventInitialTreesQuery = (_eventId, _limit, _language) => {
    const _treeQuery = treeQuery(_language);
    return `trees:Tree_page(eventId: ${_eventId}, limit: ${_limit}){${_treeQuery}}`;
};

const eventPageTreesQuery = (_eventId, _after, _language) => {
    const _treeQuery = treeQuery(_language);
    return `trees:Tree_page(eventId: ${_eventId}, ${ _after ? ` after: "${_after}", ` : ""} limit: 12){${_treeQuery}}`;
};

export {
    userInitialTreesQuery,
    iframePagedTreesQuery,
    iframePagedEventTreesQuery,
    userPageTreesQuery,
    businessUserInitialTreesQuery,
    businessUserPageTreesQuery,
    eventInitialTreesQuery,
    eventPageTreesQuery
}
