import { Component, Mixins, Prop } from 'vue-property-decorator'
import ManageQueues, { JobCallback, Job } from '@f/mixins/ManageQueues'
import { NoProductError } from '@f/models/errors'
import { Product } from '@f/@types'
import { UNAVAILABLE_BADGES_NEW_ECOMMERCE } from '../utils/newEcommerce'
import { Product as NewProduct } from '../services/NewEcommerce/types'
@Component
export default class AssignProducts extends Mixins(ManageQueues) {
  @Prop({ type: Object }) readonly product!: any // TODO: declare type manually or through GraphQL

  cachedCartService: any = null // TODO: declare cartService
  cachedEcommerceService: any = null // TODO: declare ecommerceService
  cartAssignQueueKey: string = 'cartAssignment' // TODO:? declare Queue Keys Enum

  // Computed
  get cartService(): any {
    return (
      this.cachedCartService ||
      (this.cachedCartService = this.ngService('Cart'))
    )
  }

  get ecommerceService(): any {
    return (
      this.cachedEcommerceService ||
      (this.cachedEcommerceService = this.ngService('Ecommerce'))
    )
  }

  // Methods
  isAvailable(product?: any): boolean | void {
    product = product || this.product
    if (!product) {
      throw new NoProductError()
    }
      if(
        product.badge === UNAVAILABLE_BADGES_NEW_ECOMMERCE.soldOut ||
        product.badge === UNAVAILABLE_BADGES_NEW_ECOMMERCE.comingSoon && !product.isSellable ||
        product.badge === UNAVAILABLE_BADGES_NEW_ECOMMERCE.until && !product.isSellable
        ) {
        return false
      }
      return true
    
  }

  isUntilAndNotSellable(product: NewProduct) {
    return product.badge === UNAVAILABLE_BADGES_NEW_ECOMMERCE.until && product.isSellable === false
  }

  isCoupled(product: any): boolean {
    return !!product?.package?.coupled
  }

  isGiftable(product?: any): boolean | void {
    product = product || this.product
    if (!product) {
      throw new NoProductError()
    }
  }

  notifyUser(product: Product): void {
    this.ecommerceService && this.ecommerceService.letMeKnow(product)
  }

  attemptCartAssignment(
    product: any,
    callback?: JobCallback,
    isGift?: boolean,
    position?: number
  ) {
    const eventId =
      product.limited?.limitedEvent?.id || this.$rootScope.plantIn?.id || null

      const task = () =>
        this.cartService.assignProduct({
          productId: product.id,
          forestId: eventId,
          quantity: 1,
          isGift,
        })

    this.addJobToQueue(
      this.cartAssignQueueKey,
      new Job(product.id, task, callback)
    )
  }
}
