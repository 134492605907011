import {
  validateRegitration,
  validateCreateAccountStarted,
} from '@f/validation/datalayer'
import tagManagerService from '@f/services/TagManager'

export function DataEventRegistration({
  accountType = 'email',
  disableSurvey = false,
} = {}) {
  tagManagerService.push(
    {
      event: 'registration',
      accountType,
      disableSurvey,
    },
    validateRegitration
  )
}

export function DataEventCreateAccount() {
  tagManagerService.push(
    {
      event: 'createAccountStarted',
    },
    validateCreateAccountStarted
  )
}
