















































import { defineComponent } from '@vue/composition-api'
import ForestCard from '@f/components/Forest/ForestCard.vue'
import DotLoader from '@f/components/DotLoader.vue'
import UserRow from '@f/components/Cards/UserRow.vue'
import { getImageAsset } from '@f/utils/media'
import useRoutes from '@f/composables/useRoutes'
import useFormat from '@f/composables/useFormat'

export default defineComponent({
  name: 'ForestUsersBox',
  components: {
    DotLoader,
    ForestCard,
    UserRow,
  },
  props: {
    usersCount: {
      type: Number,
      default: 0,
    },
    forestUsers: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inner: {
      type: Boolean,
      default: false,
    },
    enableFetchMore: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { parseProfileUrl } = useRoutes()

    function userUrl(slug: string) {
      return parseProfileUrl({ slug, userType: 'Private' })
    }

    function userAvatar(avatarId: string) {
      return getImageAsset(avatarId, 'small')
    }

    const { formatLocalNumbers } = useFormat();

    return {
      userUrl,
      userAvatar,
      formatLocalNumbers,
    }
  },
})
