











import {defineComponent} from "@vue/composition-api";
import SlideDownTransition from "@f/transitions/SlideDown";

export default defineComponent({
  name: "QuestionHeader",
  components: {
    SlideDownTransition,
  },
  props: {
    mediaFullscreen: {
      type: Boolean,
      value: false,
    },
  }
});
