import template from './package-qt-card-template.html'
import controller from './package-qt-card-controller'

const bindings = {
  product: '<',
  onClick: '&',
  onChange: '&',
  template: '@',
  quantity: '<'
}

angular.module('ngApp')
  .component('packageQtCard', {
    template,
    controller,
    bindings
  })
