import { onError } from '@apollo/client/link/error'
import { datadogRum } from '@datadog/browser-rum'

const logErrorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      datadogRum.addError(`GraphQL Error for operation: ${operation.operationName}`, error)
    })
  }

  if (networkError) {
    datadogRum.addError(`GraphQL Network Error for operation: ${operation.operationName}`, networkError)
  }
});

export default logErrorLink