import { Vue, Component } from 'vue-property-decorator'
import { MenuItem } from '@f/@types/navigation'

@Component({
  name: 'ShowMenu'
})
export default class ShowMenu extends Vue {
  /*--- METHODS -------*/
  resolveItemUrl(item: MenuItem): string | null {
    return item.url || (item.state && this.$state.href(item.state, item.params)) || null
  }
}
