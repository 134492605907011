export default {
  facebook: {
    icon: 'facebook-round',
    url: {
      it: 'https://www.facebook.com/treedom',
      en: 'https://www.facebook.com/treedomtrees',
      de: 'https://www.facebook.com/Treedom_de-113028135151483/',
      fr: 'https://www.facebook.com/treedomtrees',
      es: 'https://www.facebook.com/treedomtrees'
    }
  },
  instagram: {
    icon: 'instagram-round',
    url: {
      it: 'https://www.instagram.com/treedom_trees',
      en: 'https://www.instagram.com/treedom_trees',
      de: 'https://www.instagram.com/treedom_de',
      fr: 'https://www.instagram.com/treedom_trees',
      es: 'https://www.instagram.com/treedom_trees'
    }
  },
  youtube: {
    icon: 'youtube-round',
    url: 'https://www.youtube.com/channel/UC4LB9MIGsvGknvcSzA9Affg'
  },
  linkedin: {
    icon: 'linkedin-round',
    url: 'https://www.linkedin.com/company/treedom'
  }
}
