import shuffle from "lodash/shuffle"

angular.module('ngApp')
  .controller('commentsComponentController', ['$rootScope', '$scope', '$state', '$timeout', 'Utility', function ($rootScope, $scope, $state, $timeout, Utility) {
    if ($rootScope.treedomEnv === 'DEV') $rootScope.public_bucket = 'www-treedom-net'

    var Swiper = require('swiper')
    var ctrl = this
    var limit = 30
    var offset = 0

    ctrl.comments = null
    ctrl.treedomCommentsSlider = null
    ctrl.sliderLoaded = null

    ctrl.goToUserPage = function (commentId, userType, userSlug) {
      var stateToGo = userType === 'Business' ? 'organization' : 'user'
      $state.go(stateToGo, { slug: userSlug })
    }

    var getComments = function (limit, offset) {
      var ln = ($rootScope.ln !== 'fr' && $rootScope.ln !== 'es') ? $rootScope.ln : 'all'

      Utility.getAllWallComments(limit, offset, ln)
        .then(function(res) {
          res = res.data || res
          ctrl.comments = pickRandomComments(filterByCharacters(res))
          $timeout(renderTreedomCommentsSwiper, 100)
        })
    }

    var filterByCharacters = function (comments) {
      return comments.filter(comment => comment.message.length <= 133)
    }

    var pickRandomComments = function (comments) {
      return shuffle(comments).filter((_comment, index) => index < 6)
    }

    var renderTreedomCommentsSwiper = function () {
      if (ctrl.treedomCommentsSlider) {
        !Array.isArray(ctrl.treedomCommentsSlider)
          ? ctrl.treedomCommentsSlider.destroy(true, true)
          : ctrl.treedomCommentsSlider.forEach(function (s) { s.destroy(true, true) })
      }

      ctrl.treedomCommentsSlider = new Swiper('#SwiperTreedomComments', {
        slidesPerView: $scope.attrs.sidebar ? 1 : 3,
        spaceBetween: 15,
        nextButton: '#TreedomCommentsButtonNextSlider',
        prevButton: '#TreedomCommentsButtonPrevSlider',
        pagination: '#TreedomCommentsSliderPagination',
        paginationClickable: true,
        grabCursor: true,
        autoplay: 15000,
        breakpoints: {
          600: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          870: {
            slidesPerView: 2,
            spaceBetween: 15
          }
        }
      })
    }

    getComments(limit, offset)
  }])
