


















































































import { defineComponent, ref, computed, PropType, watch } from '@vue/composition-api'
import { useQuery, useResult } from '@vue/apollo-composable'
import HUDPage from '@f/components/Map/HUD/HUDPage.vue'
import DotLoader from '@f/components/DotLoader.vue'
import { FOREST_TREES, USER_TREES } from '@f/graphql/queries'
import useRoutes from '@f/composables/useRoutes'
import Treecon from '@f/components/Treecons/Treecon.vue'
import {
  TrForestTreesQuery,
  TrForestTreesQueryVariables, TrTree,
  TrTreeRowFragmentFragment
} from '@f/@types/graphql'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import useUserInfo from '@f/composables/useUserInfo'
import { DataEventMap } from '@f/services/TagManager/events'
import { Event } from '@f/@types'
import useForestData from '@f/composables/useForestData'
import StatefullButton from '@f/components/StatefullButton.vue'
import { getVisibleImage } from '@f/services/Tree'

// @ts-ignore
export default defineComponent({
  name: 'HUDUserForestTrees',
  components: {
    StatefullButton,
    HUDPage,
    DotLoader,
    Treecon
  },
  props: {
    forest: {
      type: Object as PropType<Event>,
      required: true,
    },
    navigation: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 30,
    },
  },
  setup(props) {
    const isMobile = useMediaQuery(maxWidth('md'))
    const { href } = useRoutes()
    const { userInfo } = useUserInfo()
    const {
      forest,
      userProfileUrl,
      forestUrl,
      forestTreesUrl,
      isForestPrivate,
      isForestPublished,
      ctaAction,
      ctaLabel,
      isCtaVisible,
      goToCta,
      avatarImageUrl,
    } = useForestData(userInfo, props.forest?.slug!)

    const forestSlug = computed(() => forest.value?.slug)

    watch(() => props.forest, (current) => {
      if (current) {
        forest.value = current
      }
    }, {
      immediate: true,
    })

    const userName = computed(() => forest.value?.user?.title)
    const forestName = computed(() => forest?.value?.name)
    const forestAvatar = avatarImageUrl

    const treesVariables = computed(() => ({
      eventId: Number(forest.value?.id),
      limit: props.perPage,
    }))
    const { result, loading } = useQuery<TrForestTreesQuery, TrForestTreesQueryVariables>(
      FOREST_TREES,
      treesVariables,
      () => ({
        enabled: !!forest?.value?.id,
      }),
    )
    const trees = computed(() => {
      return result.value?.forestTrees?.trees
    })
    const hasMore = computed(() => {
      return result.value?.forestTrees?.pageInfo?.hasNextPage
    })
    const getTreeItemIcon = (tree: TrTree) => {
      return getVisibleImage(tree)?.medium
    }

    const onTreeSelect = (treeSelected: string ) => {
      DataEventMap({ treeSelected })
    }

    return {
      userProfileUrl,
      userName,
      forestName,
      forestSlug,
      forestUrl,
      forestTreesUrl,
      loading,
      trees,
      href,
      getTreeItemIcon,
      isMobile,
      onTreeSelect,
      forestAvatar,
      isForestPrivate,
      isForestPublished,
      ctaAction,
      ctaLabel,
      isCtaVisible,
      goToCta,
      avatarImageUrl,
      hasMore,
    }
  }
})
