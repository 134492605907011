import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
.controller('cartController', ['$scope', '$rootScope', 'Cart', '$state', 'Utility', 'Modal', 'TagManager', function ($scope, $rootScope, Cart, $state, Utility, Modal, TagManager) {

  $scope.cart = {};
  $scope.voucherInfo = {};
  $scope.businessTrees = [];
  $scope.loading = isUndefined($rootScope.userdata.cart);
  $scope.cartPowers = {};
  $scope.removing = [];

  var ctrl = this;

  // Trova il carrello dell'utente attuale, se non viene passato lo User ID prende il cookie di sessione.
  var getCart = function (force) {
    Cart.attemptFetch(force).then(function(_cart){
        $scope.loading = false;
        updateCartInfo(_cart)
    })
  };

  // Aggiorna la variabile $scope.cart dove viene 'clonato' $rootScope.userdata.cart per evitare modifiche
  // dell'oggetto originale.
  // TODO: duplicated on payController
  var updateCartInfo = function (rootScopeCart) {
    //$rootScope.userdata.cart = rootScopeCart
    $scope.cart = { ...rootScopeCart };
    $scope.cart.items = $scope.cart.items.map(item => {
        item.gift = item.gift == 1;
        return item;
    }).sort((a, b) => Number(a.product.id) - Number(b.product.id))
    // Modifica la variabile voucherInfo
    $scope.voucherInfo = Cart.getVoucherInfo($scope.cart)
    $scope.voucherUniqueInfo = Cart.getVoucherUniqueInfo($scope.cart)

    //  Aggiorna i poteri
    // updatePowers()
    $scope.loading = false;
    if (!$scope.$$phase) $scope.$apply()
  };

  // Apre la modale informativa sul regalo.
  $scope.openGiftInfo = function () {
    Modal.open({templateUrl: 'modalGiftInfo.html'})
  };

  $scope.removeVoucher = function (voucherID) {
    if (!$scope.cart.id || !voucherID) {
      return console.error('Cart ID and Voucher ID are required field.')
    }

    /*Cart.removeVoucher($scope.cart.id, voucherID)
    .then(function(cart) {

      cart = cart.data || cart
      updateCartInfo(cart)
    })
    .catch(function(err) {

      console.error('cartController -> $scope.removeVoucher', err)
    })*/
    Cart.removeVoucher($scope.cart.id, voucherID)
    .then(function (res) {
        var cart = res.data || res
        updateCartInfo(cart)
    })
    .catch(function (err) {
        console.error('cartController -> $scope.removeVoucher', err)
    })
  };

  $scope.removeProduct = function (itemID) {
    if (!$scope.cart.id || !itemID) {
      return console.error('Cart ID and Item ID are required field.')
    }
    $scope.removing.push(itemID);
    Cart.removeProduct($scope.cart.id, itemID)
        .then(function (res) {
            var cart = res.data || res
            updateCartInfo(cart)
        })
        .catch(function (err) {
            err = err.data || err
            console.error('cartController -> $scope.removeProduct', err)
        })
        .then(function() {
          $scope.removing.pop(itemID)
        })
  };

  $scope.quantity = 1;
  $scope.assignToCart = function ({ product, forest, toUser, gift = false }) {
    if (!product) return
    const productId = product.id
    const forestId = forest && forest.id || null
    const toUserId = toUser && toUser.id || null

    Cart.assignProduct(productId, gift, forestId, toUserId, $scope.quantity)
    .then(function (res) {
        var cart = res.data || res
        $rootScope.userdata.cart = cart
        $scope.quantity = 1
    })
  };

  $scope.changeGiftOption = function (item) {
    if (!item) {
      return console.error('Item is a required field.')
    }

    Cart.editProduct($scope.cart.id, item.id, item.gift , item.forest && item.forest.id, item.toUser)
    .catch(function (err) {
      console.error('cartController -> $scope.changeGiftOption', err)
    })
  };


  // Watcher sul carrello dell'utente
  $scope.$watch(function () {
    return $rootScope.userdata.cart
  }, function (newVal) {
    if ($rootScope.userdata.cart) {
      updateCartInfo($rootScope.userdata.cart);

      // Parse business cart
      if (!isUndefined($rootScope.userType)) {
        if ($rootScope.userType === 'Business') {
          $scope.businessTrees = $scope.groupTrees($rootScope.userdata.cart.items)
        }
      }
    }
  });

  window.onfocus = function() {
    if (!$scope.$$phase) $scope.$apply()
  }

  $scope.loading = true;
  if($state.current.name === 'checkout' || $rootScope.needMerge){
    getCart(true, $rootScope.needMerge);
  }

  /**
   * Raggruppa gli alberi per l'utente business
   */
  $scope.groupTrees = Cart.groupTreesByQuantity

  $scope.openVoucherModal = function () {
    Modal.open({templateUrl: 'modalVoucherForm.html'})
  };

    $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
        if (toState.name === 'pay') {
            if(!onpay) {
              event.preventDefault();
            }
            ctrl.pay();
        }
    });

  $scope.isRemoving = function(itemId) {
    return $scope.removing.includes(itemId)
  };

  var onpay = false;

}]);
