











































import {defineComponent} from '@vue/composition-api'
import Treecon, {TreeconIcon} from '@f/components/Treecons/Treecon.vue'
import {getRootScope} from '@f/services/Angular'
import {StateService} from '@uirouter/angularjs'


const icons: Record<string, TreeconIcon> = {
  forest: 'forest-line',
  airc: 'airc-line',
  business: 'business-line',
  tree: 'tree-line',
  gift: 'gift-line',
  co2: 'cloud-line',
  subscription: 'calendar-check-line'
}

export default defineComponent({
  name: 'CustomPlantATreeSection',
  components: { Treecon },
  data() {

    const state = (getRootScope() as any).state as StateService

    return {
      data: {
        description: 'nav.plant.description',
        private: {
          label: 'nav.plant.private',
          items: [
            {
              link: state.href('species'),
              icon: icons.tree,
              label: 'nav.plant.title',
              description: 'nav.plant.description'
            },
            {
              link: state.href('subscriptions'),
              icon: icons.subscription,
              label: 'nav.subscription.title',
              description: 'nav.subscription.description'
            },
            {
              link: state.href('landing-forest'),
              icon: icons.forest,
              label: 'nav.forest.title',
              description: 'nav.forest.description'
            },
            {
              link: state.href('airc-landing'),
              icon: icons.airc,
              label: "nav.airc.title",
              description: "nav.airc.description",
            },
          ]
        },
        business: {
          label: 'nav.plant.business',
          items: [
            {
              link: state.href('business.plant-a-tree'),
              icon: icons.tree,
              label: 'nav.plant.business.title',
              description: 'nav.plant.business.description'
            },
            {
              link: state.href('business.subscriptions'),
              icon: icons.subscription,
              label: 'nav.subscription.business.title',
              description: 'nav.subscription.business.description'
            },
            {
              link: state.href('business'),
              icon: icons.business,
              label: 'nav.enterprise.title',
              description: 'nav.enterprise.description'
            }
          ]
        }
      }
    }
  }
})
