







import { computed, defineComponent, PropType } from '@vue/composition-api'
import Treecons from '@f/components/Treecons'

export type TreeconIcon = keyof typeof Treecons;
export type TreeconRotate = 45 | 90 | 135 | 180 | 225 | 270 | 315 |
  '45' | '90' | '135' | '180' | '225' | '270' | '315';
export type TreeconSize = 'small' | 'normal' | 'large' | 'big' | 'extralarge' | 'extraextralarge';

export type TreeconProps = {
  icon?: TreeconIcon,
  rotate?: TreeconRotate,
  size?: TreeconSize,
};
export default defineComponent<TreeconProps>({
  name: 'Treecon',
  // FIXME: any needed waiting for PR #10253 [https://github.com/vuejs/vue/pull/10253/files/3be9e28746724320121534e0de0bb607276d8b67]
  // @ts-ignore
  components: {
    ...Treecons,
  },
  props: {
    icon: {
      type: String as PropType<keyof typeof Treecons>,
      required: true,
      default: 'treecon-placeholder',
    },
    size: {
      type: String as PropType<TreeconSize>,
      default: 'normal',
    },
    rotate: {
      type: [String, Number] as PropType<TreeconRotate>,
      default: 0,
    },
  },
  setup(props: TreeconProps) {
    const classes = computed(() => [
      'ntds-treecon--rotate-'+props.rotate,
      'ntds-treecon--size-'+props.size,
    ])
    return { classes }
  }
})
