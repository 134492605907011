angular.module('ngApp')
.controller('mapController', ['$scope', '$window', '$rootScope', 'leafletData', '$state', function ($scope, $window, $rootScope, leafletData, $state) {
  var $ = window.$

  $scope.mapProjects = []
  $scope.myMarkers = {}
  $scope.mapProjectCharged = false
  $scope.heightScrean = $window.innerHeight - 100
  $scope.eventSelected = false

  $scope.mapOptions = {
    zoomControl: false
  }

  angular.extend($scope, {
    layers: {
      baselayers: {
        mapbox_terrain: {
          name: 'Mapbox Terrain',
          url: 'https://api.tiles.mapbox.com/v4/{mapid}/{z}/{x}/{y}.png?access_token={apikey}',
          type: 'xyz',
          layerOptions: {
            apikey: 'pk.eyJ1IjoidG9tbWFzb3NwZXJvbmkiLCJhIjoiY2tnOTE3eW12MDJqazMybXNzOWV1YjloOSJ9.wtGsuDU7XIKjcv2cq8CiXw',
            mapid: 'tommasosperoni.kgbia49c',
            minZoom: 3
          }
        }
      },
      overlays: {
        mapTrees: {
          name: 'Map Trees',
          type: 'markercluster',
          visible: true
        }
      }
    },
    center: {
      lat: 10.3155,
      lng: 8.9059211,
      zoom: 7
    },
    maxbounds: {
      southWest: {
        lat: -90,
        lng: -180
      },
      northEast: {
        lat: 90,
        lng: 180
      }
    },
    markers: {},
    paths: {}
  })

  $scope.gotToUser = function (id) {
    $rootScope.watchUser = id
  }

  $scope.$on('leafletDirectiveMarker.click', function (e, args) {
    // Args will contain the marker name and other relevant information
    var marker = $scope.markers[args.markerName]
    if (marker.layer === 'mapProjects') {
      $state.go('project', { slug: marker.slug })
    } else {
      $state.go('user.trees.item.diary', { item: args.markerName })
    }
  })

  $scope.$on('leafletDirectivePath.click', function (e, args) {
    // Args will contain the marker name and other relevant information
    var countryName = args.pathName.replace('-', '_')
    var res = args.pathName.replace('-', '_')
    $state.go('project', {slug: countryName})
  })

  // rimango a controllare gli eventi
  $scope.$watch(function () {
    return $rootScope.selectedUserEvent
  }, function (newVal) {
    $scope.eventSelected = newVal
    $scope.addMarkers()
    $scope.makeFit()
  })

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    if (toState.name !== 'user.events.event' && !$rootScope.goToMarker) {
      $scope.markers = {}
      $scope.eventSelected = false
      $scope.addMarkers()
      // $scope.makeFit()
    } else {
      $rootScope.goToMarker = false
    }
  })

  $scope.addMarkers = function () {
    if ($scope.eventSelected !== false) {
      $scope.markers = {}
    }

    var trees = $rootScope.showUser.trees

    $scope.markers = trees.reduce((obj, item) => {
      var tree = item.tree

      // Mi assicuro che lat e lng siano dei numeri.
      tree.lat = Number(tree.lat)
      tree.lng = Number(tree.lng)

      !$scope.eventSelected
        ? obj[tree.id] = tree
        : tree.eventId === $scope.eventSelected
          ? obj[tree.id] = tree
          : null

      return obj
    }, {})

    // questo serve per lo show hide sulla mappa
    $scope.myMarkers = trees
    $scope.makeFit()
  }

  $scope.makeFit = function () {
    $scope.bounds = []
    $.each($scope.markers, function (index, value) {
      $scope.bounds.push([value.lat, value.lng])
    })

    leafletData.getMap().then(function (map) {
      if ($scope.bounds.length === 1) {
        map.fitBounds($scope.bounds)
        map.setZoom(10)
      } else if ($scope.bounds.length > 1) {
        map.fitBounds($scope.bounds)
      }
    })
  }

  $scope.centerMap = function (lat, lng) {
    $scope.bounds = []
    leafletData.getMap().then(function (map) {
      map.panTo([lat, lng])
      map.setZoom(12)
    })
  }

  $scope.$on('leafletDirectiveMap.moveend', function (event) {
    leafletData.getMap().then(function (map) {
      if (map.getZoom() > 3) {
        $scope.paths = {}
      } else {
        if ($scope.mapProjectCharged) {
          $scope.paths = $scope.mapProjects
        }
      }
    })
  })

  $rootScope.$watch(function () {
    return $rootScope.treeCycle
  }, function (newVal) {
    if (newVal) {
      $scope.addMarkers()
    }
  })

  $rootScope.$watch(function () {
    return $rootScope.goToMarker
  }, function (newVal) {
    if (newVal) {
      for (var key in $scope.markers) {
        if ($scope.markers.hasOwnProperty(key) && key === newVal) {
          $state.go('user')
          leafletData.getMap().then(function (map) {
            map.setView(new window.L.LatLng($scope.markers[newVal].lat, $scope.markers[newVal].lng), 18)
          })
        }
      }
    }
  })
}])
