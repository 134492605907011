import tagManagerService from '@f/services/TagManager';
import { validateDeactivateSubscription, validateUnsubscribe } from '@f/validation/datalayer';

export function DataEventDeactivateSubscription(name: string) {
    tagManagerService.push({
        event: 'deactivateSubscription',
        name
    }, validateDeactivateSubscription)
}

export function DataEventUnsubscribe() {
    tagManagerService.push({
        event: 'unsubscription',
    }, validateUnsubscribe)
}