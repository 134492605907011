































































import { defineComponent, PropType } from '@vue/composition-api'
import { getImageAsset } from '@f/utils/media'
import Modal from '@f/components/Modal.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import DeleteForestConfirmModal from '@f/components/Forest/DeleteForestConfirmModal.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

interface SwiperInterface {
  // eslint-disable-next-line no-unused-vars
  slideTo(index: number): void
  slidePrev(): void
  slideNext(): void
}

export default defineComponent({
  name: 'ForestGallery',
  components: {
    Modal,
    Treecon,
    DeleteForestConfirmModal,
    Swiper,
    SwiperSlide,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  data(): {
    showGalleryModal: boolean
    showConfirmDeleteModal: boolean
    currentlySelected: null | number
    options: any
    swiper: SwiperInterface | null
  } {
    return {
      showGalleryModal: false,
      showConfirmDeleteModal: false,
      currentlySelected: null,
      options: {
        slidesPerView: 1,
        spaceBetween: 0,
        freeMode: false,
        centeredSlides: false,
        autoplay: false,
      },
      swiper: null,
    }
  },
  methods: {
    getImageAsset,
    deleteImage() {
      this.showConfirmDeleteModal = false
      this.$emit('delete', this.currentlySelected)
    },
    showImage(image: number) {
      this.currentlySelected = image
      this.showGalleryModal = true
    },
    opendeleteConfirmModal(image: number) {
      this.showConfirmDeleteModal = true
      this.currentlySelected = image
    },
    swiperReady(swiper: any) {
      this.swiper = swiper
      const slideTo = this.currentlySelected
        ? this.images.findIndex((value) => value === this.currentlySelected)
        : 0
      swiper.slideTo(slideTo)
    },
    slidePrev() {
      this.swiper!.slidePrev()
    },
    slideNext() {
      this.swiper!.slideNext()
    },
  },
})
