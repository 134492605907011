// NOTE: usato per il tourbook mobile.
angular.module('ngApp')
.controller('listProjectMobileController', ['$scope', '$rootScope', '$state', 'Project', '$filter', function ($scope, $rootScope, $state, Project, $filter) {
  // Per la parte mobile
  $scope.menuOpen = false;
  var $ = window.$;

  $('.menu_dropdown').click(function () {
    // se il menu è già aperto ed è uguale a quello che ho cliccato
    if ($scope.menuOpen === $(this).attr('data-attr')) {
      // nascondilo
      $('#submenu_dropdown_' + $scope.menuOpen).hide();
      // setta menuOpen come false e fermati qui
      $scope.menuOpen = false;
      return $scope.menuOpen
    }

    // In caso contrario: non sto cliccando sullo stesso aperto
    $scope.menuOpen = $(this).attr('data-attr');

    // Cicla su tutti i submenu dropdown
    $('.submenu_dropdown').each(function () {
      // se il suo attributo data-attr è uguale a menuOpen
      if ($(this).attr('data-attr') === $scope.menuOpen) {
        // mostralo
        $(this).show()
      } else {
        // se no nascondilo
        $(this).hide()
      }
    })
  });

  $scope.selectProject = function (slug) {
    $rootScope.selectedProject = slug;
    $state.go('project', { slug: slug })
  }
}]);
