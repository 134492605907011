import { PluginObject } from 'vue'
import { default as UAParser } from 'ua-parser-js'
import { ITranslation } from '@f/@types/localization'
import { RawParams, StateOrName, StateService } from '@uirouter/angularjs'
import { TrProduct, TrTranslation } from '@f/@types/graphql'
import { MediaFormat } from '@f/@types/media'
import { isFeatureActive } from '@f/utils'
import { getRootScope, getStateService, getAngularModule } from '@f/services/Angular'
import { getTranslator, translate } from '@f/services/Translation'

interface PluginsMap {
  [key: string]: PluginObject<any>
}

const envPlugin: PluginObject<undefined> = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.$env = window?.treedomEnv || 'PROD'
      }
    })
  }
}
const featureCheckPlugin: PluginObject<undefined> = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.$isFeatureActive = isFeatureActive
      }
    })
  }
}
function cleanDataRecursively(data: any, baseProps?: string[] | null, extraProps?: string[]): any {
  if (!data) return data

  const defaultStripeProps = ['__typename']
  const stripeProps: string[] = [
    ...(baseProps || defaultStripeProps),
    ...(extraProps || [])
  ]

  switch (data.constructor) {
    case Object:
      return Object.keys(data).reduce((acc: any, key: string): any => {
        if (!stripeProps.includes(key)) {
          acc[key] = (
            !data[key] || ![Object, Array].includes(data[key].constructor)
              ? data[key]
              : cleanDataRecursively(data[key], stripeProps)
          )
        }
        return acc
      }, {})
    case Array:
      return data.map((val: any) => cleanDataRecursively(val, stripeProps))
    default:
      return data
  }
}

function resolveProductIcon(product: TrProduct | void | null, size: MediaFormat = "small"): string {
  const productIcon = product && (product.limited || product.package || product.specie)?.icon
  return productIcon && productIcon[size] || ""
}

function resolveProductName(product?: TrProduct): TrTranslation | string {
  return !!product && product.productName
    && (product.limited?.limitedName
      || (product.package || product.specie)?.name)
    || ""
}

const plugins: PluginsMap = {

  envPlugin,
  featureCheckPlugin,

  jwtCookie: {
    install: Vue => {
      Vue.mixin({
        beforeCreate() {
          this.$_jwt = false
          Object.defineProperty(this, '$jwt', {
            get: () => {
              if (this.$_jwt !== false) return this.$_jwt
              try {
                this.$_jwt = document.cookie.split(';').map(str => str.trim()).filter(str => /^TREEDOMGL=/.test(str))[0].split('=')[1]
              } catch (err) {
                this.$_jwt = false
              }
              return this.$_jwt
            }
          })
        }
      })
    }
  },

  cleanData: {
    install: Vue => {
      Vue.prototype.$cleanData = cleanDataRecursively
    }
  },

  // TODO: plugin di integrazione con AngularJS
  rootscopeAccess: {
    install: Vue => {
      Vue.prototype.$_rootScope = null
      Object.defineProperty(
        Vue.prototype,
        "$rootScope",
        {
          get: () => Vue.prototype.$_rootScope
            || (Vue.prototype.$_rootScope = getRootScope())
        }
      )
    }
  },

  // TODO: plugin di integrazione con AngularJS
  rootscopeApplyMethod: {
    install: Vue => {
      Vue.prototype.$ngApply = () => {
        if (Vue.prototype.$_rootScope && !Vue.prototype.$_rootScope.$$phase)
          Vue.prototype.$_rootScope.$apply()
      }
    }
  },

  // TODO: plugin di integrazione con AngularJS
  translatorIntegration: {
    install: Vue => {

      Vue.prototype.translate = Vue.prototype.$t = translate // $t for vue-i18n compatibility

      Vue.mixin({
        beforeCreate() {
          // Object.defineProperty(this, $)
          Object.defineProperty(this, '$translator', { get: getTranslator })
        }
      })

    }
  },

  // TODO: plugin di integrazione con AngularJS
  uiRouterIntegration: {
    install: Vue => {

      Vue.prototype.parseState = function(this: Vue, state: StateOrName, params?: RawParams) {
        if (!this.$state) throw Error('UI Router $state not available')
        return this.$state.href(state, params)
      }

      Vue.prototype.goToState = function(this: Vue, state: StateOrName, params?: RawParams) {
        if (!this.$state) throw Error('UI Router $state not available')
        return this.$state.go(state, params)
      }

      const stateProxyHandler: ProxyHandler<StateService> = {
        get: (target, name: keyof StateService) => {
          return name === 'href'
            ? (state: StateOrName, params: Record<string, any> = {}) => target.href.bind(target, state, params, { inherit: false })()
            : target[name]
        }
      }

      Vue.mixin({
        beforeCreate() {
          Object.defineProperty(this, '$state', {
            get: () => {
              const stateService = getStateService()
              if (!stateService) return
              return !!window.Proxy ? (new Proxy(stateService, stateProxyHandler)) : stateService
            }
          })
        }
      })
    }
  },

  // TODO: plugin di integrazione con AngularJS
  angularServicesIntegration: {
    install: Vue => {
      Vue.mixin({
        beforeCreate() {
          this.ngService = getAngularModule
        }
      })
    }
  },
  
  // TODO: plugin di integrazione con AngularJS
  mobileCheck: {
    install: Vue => {

      Vue.mixin({
        beforeCreate() {
          this.$isMobile = !!(new UAParser()).getDevice().model
        }
      })

    }
  },

  resolveProductIcon: {
    install: Vue => {
      Vue.mixin({
        beforeCreate() {
          this.$resolveProductIcon = resolveProductIcon
        }
      })
    }
  },

  resolveProductName: {
    install: Vue => {
      Vue.mixin({
        beforeCreate() {
          this.$resolveProductName = resolveProductName
        }
      })
    }
  },
}

export default plugins;
