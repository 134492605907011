import Validator, { ValidationError } from '../utils/Validator'

/* @ngInject */
angular.module('businessService', [])
.factory('Business', function ($rootScope, Countries) {

  const BUSINESS_TYPES = {
    // it no-profit
    NO_PROFIT: { value: 0, label: 'forBusiness.signup.typeTitle_01', type: '' },
    // it & eu company
    COMPANY: { value: 1, label: 'forBusiness.signup.typeTitle_02', type: '' },
    // non-eu company
    NONEU_COMPANY: { value: 2, label: 'forBusiness.signup.typeTitle_03', type: '' }
  }
  Object.freeze(BUSINESS_TYPES)

  const basicRules = {
    businessName: 'required',
    address: 'required',
    city: 'required',
    country: 'required',
    type: 'required'
  }

  const BUSINESS_COUNTRY_REGIONS = {
    'IT': {
      rules(profileType) {
        return {
          ...basicRules,
          fiscalCode: 'required|atLeastOneDigit',
          recipientCode: 'requiredWithout:certifiedEmail',
          certifiedEmail: 'requiredWithout:recipientCode',
          vatNumber: profileType && profileType === BUSINESS_TYPES.COMPANY.value ? 'required|atLeastOneDigit' : 'atLeastOneDigit',
        }
      },
      validate(profile, withErrors) {
        const rules = BUSINESS_COUNTRY_REGIONS['IT'].rules(profile.type)
        if (!profile.vatNumber && rules.vatNumber === 'atLeastOneDigit') delete rules.vatNumber
        return Validator[withErrors ? 'validate' : 'isValid'](
          profile,
          rules
        )
      },
      types: [BUSINESS_TYPES.NO_PROFIT, BUSINESS_TYPES.COMPANY]
    },
    'EU': {
      rules(profileType) {
        return {
          ...basicRules,
          [(profileType && profileType === BUSINESS_TYPES.COMPANY.value) ? 'vatNumber' : 'fiscalCode']: (profileType && profileType === BUSINESS_TYPES.COMPANY.value) ? 'required|alphanumeric' : 'required|atLeastOneDigit'
        }
      },
      validate(profile, withErrors) {
        const rules = BUSINESS_COUNTRY_REGIONS['EU'].rules(profile.type)
        return Validator[withErrors ? 'validate' : 'isValid'](
          profile,
          rules
        )
      },
      types: [BUSINESS_TYPES.NO_PROFIT, BUSINESS_TYPES.COMPANY]
    },
    'EX': {
      rules(profileType){
        return {
          ...basicRules,
          fiscalCode: 'required|atLeastOneDigit'
        }
      },
      validate(profile, withErrors) {
        const rules = BUSINESS_COUNTRY_REGIONS['EX'].rules(profile.type)
        return Validator[withErrors ? 'validate' : 'isValid'](
          profile,
          rules
        )
      },
      types: [BUSINESS_TYPES.NONEU_COMPANY]
    }
  }
  Object.freeze(BUSINESS_COUNTRY_REGIONS)

  const businessService = {

    profileIsComplete(user) {
      if (!(user && user.country)) return Promise.resolve(false)
      if (user.usertype !== 'Business') return Promise.resolve(true)
      return this.getCountryRegion(user.country)
        .then(region => {
          return region && BUSINESS_COUNTRY_REGIONS[region] && BUSINESS_COUNTRY_REGIONS[region].validate(user)
        })
        .catch(() => false)
    },

    validateProfile(user) {
      if (!user.country) return Promise.reject(new ValidationError(['country']))
      return this.getCountryRegion(user.country).then(region => {
        return region && BUSINESS_COUNTRY_REGIONS[region] && BUSINESS_COUNTRY_REGIONS[region].validate(user, true)
      })
    },

    countryAvailableTypes(country) {
      if (!country) return Promise.resolve([])
      return this.getCountryRegion(country).then(region => {
        return region && BUSINESS_COUNTRY_REGIONS[region] && BUSINESS_COUNTRY_REGIONS[region].types
      })
    },

    getCountryRegion(country) {
      if (!country) return Promise.resolve(null)
      if (country === 'Italia' || country === 'Italy') return Promise.resolve('IT')
      return this.isEU(country)
        .then(isEU => isEU ? 'EU' : 'EX')
        .catch(() => 'EX')
    },

    isEU(country) {
      return Countries.getBlocCountries('EU')
        .then(euCountries => euCountries.some(({ name, nativeName, altSpellings, translations }) => {
          return [ name, nativeName, ...altSpellings, ...Object.values(translations) ].includes(country)
        }))
    },

    resolveType(typeString) {
      if (!typeString) return null
      return (Object.values(BUSINESS_TYPES).find(type => type.label === typeString) || { value: null }).value
    },

    getRequiredFields(country, type) {
      if (!country) return Promise.resolve([])
      return this.getCountryRegion(country).then(region => {
        return region && 
        BUSINESS_COUNTRY_REGIONS[region] && 
        Object.keys(BUSINESS_COUNTRY_REGIONS[region].rules(type))
        .filter(key => {
          const rule = BUSINESS_COUNTRY_REGIONS[region].rules(type)[key];
          return rule.split('|').filter(rawRule => rawRule.includes("required"))?.length > 0
        })
      }).catch(({ fields }) => fields)
    }

  }

  return businessService

})
