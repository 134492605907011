














































import { defineComponent, onMounted, ref, computed, onUnmounted, PropType, nextTick, watch } from '@vue/composition-api'
import DotLoader from '@f/components/DotLoader.vue'
import Overlay from '@f/components/Overlay.vue'
import Icon from '@f/components/Icon.vue'
import { IsoCode } from '@f/@types/localization'
import { onResize } from '@f/composables/hooks/resize'
import { responsive } from '@f/composables/responsive'
import { DataEventVideo } from '@f/services/TagManager/events'

declare global {
  export interface Window {
    onYouTubeIframeAPIReady?: () => void
    youTubeAPIReadyQueue?: (() => void)[]
    YT: any
  }

  export namespace YT {
    export interface PlayerVars {
      widget_referrer?: string
    }
  }
}

type YouTubeThumbnailResolution =
  | ''
  | 'sd'
  | 'mq'
  | 'hq'
  | 'maxres'

type YouTubeThumbnailShot = 'default' | 1 | 2 | 3

const scriptSource = 'https://www.youtube.com/iframe_api'
const scriptTagId = 'yt-iframe-api'
function runPlayersQueue() {
  if (!window.youTubeAPIReadyQueue?.length) return
  for (const init of window.youTubeAPIReadyQueue) {
    init()
  }
}

export default defineComponent({
  name: 'EmbedYoutubeVideo',
  components: { DotLoader, Overlay, Icon },
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: Object as PropType<{ width: number, height: number }>,
      default: () => ({ width: 1920, height: 1080 }),
    },
    language: {
      type: String as PropType<IsoCode>,
      default: 'en',
    },
    resizable: {
      type: [Boolean, Number],
      default: false,
    },
    thumbnailResolution: {
      type: String as PropType<YouTubeThumbnailResolution>,
      default: 'maxres'
    },
    thumbnailShot: {
      type: [String, Number] as PropType<YouTubeThumbnailShot>,
      default: 'default'
    },
    showCaptions: {
      type: Boolean,
      default: false
    },
    captionsLanguage: {
      type: String,
      required: false
    },
    zoom: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const playerMount = ref<HTMLElement|null>(null)
    const player = ref<YT.Player|null>(null)
    const ready = ref(false)
    const started = ref(false)
    const playerState = ref(0)
    const thumbnail = ref<HTMLElement|null>(null)
    const thumbnailRatio = ref(1)
    const { isDesktop } = responsive()

    const sanitizedVideoId = computed(() => {
      return props.id?.match(/(?:https:\/\/(?:www.)*youtu(?:\.be\/|be\.com\/.*v=))*([\w-_]*)(?:&|$)/)?.[1] || ''
    })

    const playerRatio = computed(() => props.size.width/props.size.height)

    const componentStyle = computed(() => {
      return {
        width: props.size.width+'px'
      }
    })

    const componentClass = computed(() => {
      return {
        'youtube-player--ready': ready.value,
        'youtube-player--started': started.value,
        'youtube-player--desktop': isDesktop.value,
        'youtube-player--dark': props.dark,
      }
    })

    const thumbStyle = computed(() => {
      const ratio = Math.max(1, playerRatio.value/thumbnailRatio.value)
      const backgroundSize = Math.ceil(100*ratio)+'%'
      return {
        backgroundImage: `url(https://img.youtube.com/vi/${sanitizedVideoId.value}/${props.thumbnailResolution}${props.thumbnailShot}.jpg)`,
        backgroundSize
      }
    })

    const overlay = computed(() => props.zoom && isDesktop.value)

    function onYouTubeAPIReady() {
      if (process.env.NODE_ENV === 'development') console.log('Player mounting: '+playerMount.value)
      if (!playerMount.value) return
      player.value = new window.YT.Player(playerMount.value, {
        width: props.size.width,
        height: props.size.height,
        videoId: sanitizedVideoId.value,
        playerVars: {
          enablejsapi: 1,
          hl: props.language,
          cc_lang_pref: props.captionsLanguage || props.language,
          cc_load_policy: Number(props.showCaptions),
          modestbranding: 1,
          origin: window.location.origin,
          rel: 0
        },
        events: {
          onReady: () => {
            if (process.env.NODE_ENV === 'development') console.log('Player Ready: '+sanitizedVideoId.value)
            ready.value = true
          },
          onError: (ev: any) => {
            console.log(ev)
          },
          onStateChange: ({ data }: any) => {
            if (process.env.NODE_ENV === 'development') console.log('Player: '+sanitizedVideoId.value+', State: '+data)
            playerState.value = data;
            started.value = playerState.value === YT.PlayerState.PLAYING
              || playerState.value === YT.PlayerState.PAUSED
              || playerState.value === YT.PlayerState.BUFFERING
          }
        }
      })
    }

    function mountScriptTag() {
      const scriptTag = document.createElement('script') as HTMLScriptElement
      scriptTag.src = scriptSource
      scriptTag.id = scriptTagId
      const firstSibling = document.getElementsByTagName('script')[0]
      if (firstSibling) {
        firstSibling.parentNode?.insertBefore(scriptTag, firstSibling)
        if (!window.onYouTubeIframeAPIReady) {
          window.onYouTubeIframeAPIReady = runPlayersQueue
        }
        window.youTubeAPIReadyQueue = window.youTubeAPIReadyQueue
          ? [...window.youTubeAPIReadyQueue, onYouTubeAPIReady]
          : [onYouTubeAPIReady]
      }
    }

    function updateThumbnailRatio() {
      if (thumbnail.value?.offsetWidth && thumbnail.value?.offsetWidth) {
        thumbnailRatio.value = thumbnail.value.offsetWidth/thumbnail.value.offsetHeight
      } else {
        nextTick(updateThumbnailRatio)
      }
    }

    function play() {
      player.value?.playVideo()
    }

    function stop() {
      player.value?.stopVideo()
    }

    onMounted(() => {
      if (window.YT) {
        nextTick(onYouTubeAPIReady)
      } else {
        mountScriptTag()
      }
      updateThumbnailRatio()
    })

    onUnmounted(() => {
      player.value?.destroy()
      if (!window.youTubeAPIReadyQueue?.length) return
      window.youTubeAPIReadyQueue = window.youTubeAPIReadyQueue.filter(cb => cb !== onYouTubeAPIReady)
    })

    if (props.resizable) {
      const options = typeof props.resizable === 'number'
        ? { throttle: props.resizable }
        : undefined
      onResize(updateThumbnailRatio, options)
    }

    watch(() => playerState.value, (state) => DataEventVideo(String(state), player.value?.getVideoUrl()))

    return {
      playerMount,
      thumbnail,
      playerRatio,
      thumbnailRatio,
      isDesktop,
      overlay,
      player,
      ready,
      started,
      componentStyle,
      componentClass,
      thumbStyle,
      play,
      stop,
    }
  }
})
