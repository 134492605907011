





































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import { Portal } from '@linusborg/vue-simple-portal'
import Overlay from '@f/components/Overlay.vue'
import Icon from '@f/components/Icon.vue'

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    _scopeId?: string;
  }
}

@Component({
  name: "Modal",
  components: {
    Overlay,
    Portal,
    Icon,
  }
})
export default class Modal extends Vue {
  /*--- MODEL ---------*/
  @Model('change', { type: Boolean, required: true, default: false }) readonly open!: boolean
  /*--- PROPS ---------*/
  @Prop({ type: Boolean, default: false }) readonly explicitClose!: boolean
  @Prop({ type: Boolean, default: false }) readonly lightbox!: boolean
  @Prop({ type: Boolean, default: false }) readonly fullscreen!: boolean
  @Prop({ type: Boolean, default: true }) readonly responsive!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideClose!: boolean
  @Prop({ type: String, default: '' }) readonly overlay!: string
  @Prop({ type: String, required: false }) readonly maxHeight!: string;
  @Prop({ type: String, required: false }) readonly maxWidth!: string;
  @Prop({ type: Boolean, required: false, default: false }) readonly overlayClose!: boolean;
  @Prop({ type: String, required: false, default: 'modal-id' }) readonly modalId!: string

  /*--- COMPUTED ------*/
  get inheritedClass(): string {
    return this.$vnode.data?.staticClass || ''
  }

  get layer() {
    return {
      page: this.overlay === 'page',
      toolbar: this.overlay === 'toolbar',
      legacy: this.overlay === 'legacy',
    }
  }

  /*--- METHODS -------*/
  emitToggle(state?: boolean) {
    const newState = state !== undefined ? state : !this.open
    this.$emit('change', newState)
    this.$emit(newState ? 'open' : 'close')
  }

  rapidClose() {
    !this.explicitClose && this.emitToggle(false)
  }

  // FIXME: Portal scoped style "hack"
  getScopeAttrs() {
    // Get the scope attribute name (undefined if none)
    const scopeAttr = this.$parent.$options._scopeId
    return scopeAttr ? { [scopeAttr]: '' } : {}
  }
}
