




































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { searchCountry } from '@f/composables/useCountries'
import SplitDatePicker from '@f/components/Form/SplitDatePicker.vue'
import BaseSelect from '@f/components/Form/BaseSelect.vue'
import FilteredSelect from '@f/components/Form/FilteredSelect.vue'
import StatefullButton from '@f/components/StatefullButton.vue'
import SlideDownTransition from '@f/transitions/SlideDown'
import Alert from '@f/components/Alert.vue'
import { genders } from '@f/configs/options'
import { getRootScope } from '@f/services/Angular'
import { fetchProfile, updateProfile } from '@f/services/User'
import { UserProfile } from '@f/@types'
import Cookie from 'js-cookie'
import { cookieKeys } from '@f/configs/app'
import { DataEventProfileInfoCompleted } from '@f/services/TagManager/events'

export default defineComponent({
  name: 'ProfileCompletionForm',
  components: {
    Alert,
    BaseSelect,
    FilteredSelect,
    SplitDatePicker,
    StatefullButton,
    SlideDownTransition,
    TreedomSymbol: () => import(/* webpackChunkName: 'treedom-symbol.svg' */ '@f/images/treedom-symbol.svg'),
  },
  setup() {
    const {
      search: countrySearch,
      filteredCountries: countries,
      countryName,
      findCountry
    } = searchCountry({ minSearchLength: 1 })
    const loading = ref(false)
    const genderOptions = ref(genders)
    const profile = ref<Partial<UserProfile> | null>(null)
    const error = ref('')

    // FIXME: handles php data variable typings
    const gender = computed({
      get: () => profile.value?.gender || '',
      set: (val) => {
        if (profile.value)
          profile.value.gender = val
      }
    })

    const birthdate = computed({
      get: () => profile.value?.date_of_birth || undefined,
      set: (val) => {
        if (profile.value)
          profile.value.date_of_birth = val
      }
    })

    const userCountry = computed({
      get: () => findCountry(profile.value?.countryCode || profile.value?.country || undefined),
      set: country => {
        if (!profile.value) return
        profile.value.countryCode = country?.alpha2Code || null
        profile.value.country = country?.name || null
        // FIXME: Hack to avoid unfiltered list popping up just before closing
        setTimeout(() => countrySearch.value = '', 500)
      }
    })

    const selectedCountryLabel = computed(() => {
      return userCountry.value && countryName(userCountry.value) || ''
    })

    onMounted(() => {
      loading.value = true
      fetchProfile()
        .then(data => loadProfile(data))
        .finally(() => loading.value = false)
    })

    function loadProfile(data: UserProfile) {
      if (!data.countryCode) {
        data.country = null
        data.countryCode = null
      }
      profile.value = data
    }

    function selectCountryAtIndex(index: number) {
      userCountry.value = !!~index && index < countries.value.length
        ? countries.value?.[index]
        : undefined
    }

    function setSkipCookie() {
      Cookie.set(cookieKeys.skipProfileCheckKey, '1', { domain: location.hostname, expires: 7 })
      DataEventProfileInfoCompleted(false);
      const rootScope = getRootScope()
      rootScope && rootScope.closeTreedomModal()
    }

    function save(feedbackCallback?: Function) {
      if (!profile.value) return
      loading.value = true
      updateProfile(profile.value)
        .then(() => {
          feedbackCallback && feedbackCallback(true)
          DataEventProfileInfoCompleted(true);
          const rootScope = getRootScope()
          if (rootScope) rootScope.closeTreedomModal()
        })
        .catch(error => {
          feedbackCallback && feedbackCallback()
          error.value = error?.message
        })
        .finally(() => loading.value = false)
    }

    return {
      birthdate,
      countries,
      countryName,
      countrySearch,
      error,
      gender,
      genderOptions,
      loading,
      profile,
      save,
      selectCountryAtIndex,
      selectedCountryLabel,
      setSkipCookie,
      userCountry,
    }
  }
})
