angular.module('ngApp')
.controller('bannersController', ['$scope', '$interval', 'Utility', function ($scope, $interval, Utility) {
  var ctrl = this
  var interval = null

  ctrl.banners = []
  ctrl.activeBanner = null

  var getBanners = function () {
    Utility.getBanners('rightcolumn')
    .then(function(response) {
      ctrl.banners = response.data || response
      if (ctrl.banners.length) ctrl.activeBanner = 0
      bannerInterval()
    })
  }

  var bannerInterval = function () {
    if (!ctrl.banners || (ctrl.banners && ctrl.banners.length <= 1)) return
    if (interval) return

    interval = $interval(function () {
      ctrl.activeBanner = ctrl.activeBanner === (ctrl.banners.length - 1) ? 0 : (ctrl.activeBanner + 1)
      if (!$scope.$$phase) $scope.$apply()
    }, 7000)
  }

  getBanners()

  ctrl.bannerClicked = function () {
    window.open(ctrl.banners[ctrl.activeBanner].link, '_blank')
  }

  $scope.$on('$destroy', function () {
    if (interval) $interval.cancel(interval)
  })
}])
