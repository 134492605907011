





























import { Component, Mixins } from 'vue-property-decorator'
import TreeDiaryGift from '../post_types/TreeDiaryGift.vue'

@Component({
  name: 'TreeDiaryGiftModal',
})
export default class TreeDiaryGiftModal extends Mixins(TreeDiaryGift) {}
