angular.module('ngApp')
.controller('menuController', ['$scope', '$rootScope', 'User', 'Routing', '$state', 'ipCookie', 'Utility', 'Modal', function ($scope, $rootScope, User, Routing, $state, ipCookie, Utility, Modal) {
  var $ = window.$

  this.cartUrl = window.location.protocol + "//" + window.location.hostname.replace("www", "cart");

  this.myProfile = function () {
    var stateToGo = $rootScope.userdata.info.usertype === 'Business' ? 'organization' : 'user'
    $state.go(stateToGo, { slug: $rootScope.userdata.info.slug })
  }

  this.stopImpersonate = function () {
    window.location.href = window.location.href + '?_switch_user=_exit'
  }

  this.canImpersonate = function () {
    return $state.current.name.indexOf('user') >= 0 || $state.current.name.indexOf('organization') >= 0
  }

  this.startImpersonate = function (userSlug) {
    window.location.href = $rootScope.baseHtml5Url + $rootScope.ln + '/impersonate/' + userSlug
  }

  // Per la parte mobile
  $scope.menuOpen = false

  $('body').on('click', '.menu_dropdown', function () {
    // se il menu è già aperto ed è uguale a quello che ho cliccato
    if ($scope.menuOpen === $(this).attr('data-attr')) {
      // nascondilo
      $('#submenu_dropdown_' + $scope.menuOpen).hide()
      // setta menuOpen come false e fermati qui
      $scope.menuOpen = false
      return $scope.menuOpen
    }

    // in caso contrario: non sto cliccando sullo stesso aperto
    $scope.menuOpen = $(this).attr('data-attr')

    // cicla su tutti i submenu dropdown
    $('.submenu_dropdown').each(function () {
      // se il suo attributo data-attr è uguale a menuOpen
      if ($(this).attr('data-attr') === $scope.menuOpen) {
        // mostralo
        $(this).show()
      } else {
        // se no nascondilo
        $(this).hide()
      }
    })
  })

  this.logout = function () {
    $rootScope.$broadcast('UserLoggedBroadcast', false)
    $rootScope.logout()
  }

  this.edit = function () {
    // var state = $rootScope.userdata.info.usertype === 'Business'
    //   ? 'edit'
    //   : 'edit'

    $state.go('settings')
  }

  this.invoices = function () {
    $state.go('settings.invoices')
  }


  this.customerCare = function () {
    $state.go('customercare')
  }

  $scope.changeLanguage = function (lng) {
    if (lng !== ipCookie('hl')) {
      var url = Routing.route('treedom_user_switch_ln')
      var form = $('<form action="' + url + '" method="post">' +
      '<input type="hidden" name="pathname" value="' + window.location.pathname + '" />' +
      '<input type="hidden" name="ln" value="' + lng + '" />' +
      '</form>')
      $('body').append(form)
      form.submit()
    }
  }
}])
