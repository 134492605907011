var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"private"},[(_vm.user && _vm.user.info && _vm.fetchState.friendsList)?_c('div',{staticClass:"private__info"},[_c('div',{staticClass:"avatar-container"},[_c('Avatar',{staticClass:"private__avatar",class:{
        avatar_is_subscriber: _vm.showSubscriber,
      },attrs:{"src":_vm.avatar,"size":"medium","format":"large"},nativeOn:{"click":function($event){return _vm.onSubscriberStarClick.apply(null, arguments)}}}),_vm._v(" "),(_vm.showSubscriber)?_c('div',{staticClass:"subscriber-star",on:{"click":_vm.onSubscriberStarClick}},[_c('Treecon',{attrs:{"icon":"treedom-fill"}})],1):_vm._e()],1),_vm._v(" "),_c('h1',{staticClass:"private__name ntds-heading--3",domProps:{"innerHTML":_vm._s(_vm.user.info.title)}}),_vm._v(" "),_c('div',{staticClass:"private__summary"},[_c('dl',[(_vm.trees !== false)?_c('div',{key:"trees",on:{"click":function($event){return _vm.go('user.trees', { tab: 'mine' })}}},[_c('dt',{domProps:{"innerHTML":_vm._s(_vm.$t('user.info.trees', undefined, _vm.trees))}}),_vm._v(" "),_c('dd',{staticClass:"ntds-heading--5"},[_vm._v(_vm._s(_vm.trees))])]):_vm._e(),_vm._v(" "),(_vm.gifts !== false)?_c('div',{key:"gifts",on:{"click":function($event){return _vm.go('user.trees', { tab: 'gifted' })}}},[_c('dt',{domProps:{"innerHTML":_vm._s(_vm.$t('user.info.gifts', undefined, _vm.gifts))}}),_vm._v(" "),_c('dd',{staticClass:"ntds-heading--5"},[_vm._v(_vm._s(_vm.gifts))])]):_vm._e(),_vm._v(" "),(_vm.co2 !== false)?_c('div',{key:"co2",on:{"click":function($event){_vm.showCo2Modal = !_vm.showCo2Modal}}},[_c('dt',{domProps:{"innerHTML":_vm._s(_vm.$t('user.info.co2'))}}),_vm._v(" "),_c('dd',{staticClass:"ntds-heading--5",domProps:{"textContent":_vm._s(_vm.co2)}}),_vm._v(" "),_c('Modal',{attrs:{"lightbox":"","maxHeight":'90%',"responsive":false},model:{value:(_vm.showCo2Modal),callback:function ($$v) {_vm.showCo2Modal=$$v},expression:"showCo2Modal"}},[_c('Co2CapturedModal',{attrs:{"user":_vm.user}})],1)],1):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"private__interact"},[_c('ul',{directives:[{name:"hidden",rawName:"v-hidden",value:(!_vm.fetchState.friendsList),expression:"!fetchState.friendsList"}],staticClass:"private__info--personal"},[_c('li',[_c('a',{attrs:{"href":"#"},domProps:{"textContent":_vm._s(
              _vm.$t('user.info.followers', { total: _vm.followersFriends.length })
            )},on:{"click":function($event){return _vm.openFriendsModal('followers')}}})]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"#"},domProps:{"textContent":_vm._s(
              _vm.$t('user.info.following', { total: _vm.followingFriends.length })
            )},on:{"click":function($event){return _vm.openFriendsModal('following')}}})])]),_vm._v(" "),(_vm.isDesktop)?_c('div',{staticClass:"private__actions"},[_c('ul',{staticClass:"private__actions__menu"},[(_vm.isLogged && _vm.isLoggedAs(_vm.user.info))?_c('li',[_c('a',{staticClass:"tr-button--icon tr-button--bordered tr-button--compact",attrs:{"href":_vm.editUrl,"title":_vm.$t('user.actions.edit')}},[_c('Treecon',{attrs:{"icon":"pencil-line"}})],1)]):_c('li',[_c('button',{staticClass:"tr-button--icon tr-button--compact tr-button--bordered tr-button--followhim",class:{
                'is-follow': _vm.isAlreadyFollow,
                'try-follow': _vm.fetchState.drivefollow,
              },on:{"click":_vm.followHim}},[_c('i',[_c('Treecon',{attrs:{"icon":_vm.isAlreadyFollow ? 'heart-fill' : 'heart-line'}})],1)])]),_vm._v(" "),_c('li',[_c('ShareNative',{attrs:{"button-class":'tr-button--primary tr-button--compact'}})],1)])]):_c('div',{staticClass:"private__actions"},[_c('ul',{staticClass:"private__actions__menu"},[_c('li',[_c('ShareNative',{attrs:{"button-class":'tr-button--primary tr-button--icon tr-button--compact',"label":false}})],1),_vm._v(" "),_c('li',[_c('button',{staticClass:"tr-button--icon private__actions__toggle tr-button--compact",on:{"click":function($event){_vm.showFriendsModal = !_vm.showFriendsModal}}},[_c('Treecon',{attrs:{"icon":"more-fill-fill","size":"big","rotate":90}})],1)])])])]),_vm._v(" "),_c('Modal',{staticClass:"private__friends-modal",attrs:{"lightbox":""},scopedSlots:_vm._u([(!_vm.isDesktop)?{key:"header",fn:function(){return [_c('div',{staticClass:"private__friends-modal--useraction"},[(_vm.isLoggedAs(_vm.user.info))?_c('a',{staticClass:"tr-button--secondary tr-button--compact",attrs:{"href":_vm.editUrl,"title":_vm.$t('user.actions.edit')}},[_c('Treecon',{attrs:{"icon":"pencil-line"}}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('user.actions.edit-modal'))}})],1):_c('button',{staticClass:"tr-button--compact tr-button--bordered tr-button--followhim tr-button--followhim-text",class:{
              'is-follow tr-button--primary': _vm.isAlreadyFollow,
              'tr-button--secondary': !_vm.isAlreadyFollow,
              'try-follow': _vm.fetchState.drivefollow,
            },on:{"click":_vm.followHim}},[_c('i',[_c('Treecon',{attrs:{"icon":_vm.isAlreadyFollow ? 'heart-fill' : 'heart-line'}})],1),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(
                _vm.$t(
                  _vm.isAlreadyFollow
                    ? 'user.actions.unfollow-modal'
                    : 'user.actions.follow-modal'
                )
              )}})])])]},proxy:true}:null],null,true),model:{value:(_vm.showFriendsModal),callback:function ($$v) {_vm.showFriendsModal=$$v},expression:"showFriendsModal"}},[_vm._v(" "),_c('UserFriends',{attrs:{"user":_vm.user,"show-list":_vm.friendsTabName,"show-title":_vm.isDesktop}})],1)],1):_c('DotLoader',{staticClass:"private__info--loader",attrs:{"size":2}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }