












import { computed, defineComponent, PropType } from '@vue/composition-api'

export type Size = 'small' | 'normal' | 'large' | 'big' | 'extralarge' | 'extraextralarge';

export default defineComponent({
  name: 'Spinner',
  props: {
    size: {
      type: String as PropType<Size>,
      default: 'normal',
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const sizeClass = computed( () => `ntds-treecon--size-${props.size}`)
    return {
      sizeClass,
    }
  }
})
