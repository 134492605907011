


























import { Vue, Component, Prop } from 'vue-property-decorator'

const bargainsImages = {
  graduation: require('@f/images/giftTree/graduation.png'),
  christmas: require('@f/images/giftTree/christmas.png'),
  horoscope: require('@f/images/giftTree/horoscope.png'),
  favors: require('@f/images/giftTree/favors.png'),
  birth: require('@f/images/giftTree/birth.png'),
  recurrence: require('@f/images/giftTree/recurrence.png'),
  birthday: require('@f/images/giftTree/birthday.png'),
  love: require('@f/images/giftTree/love.png'),
  wedding: require('@f/images/giftTree/wedding.png'),
  solidarity: require('@f/images/giftTree/solidarity.png'),
  family: require('@f/images/giftTree/family.png'),
  anniversary: require('@f/images/giftTree/anniversary.png'),
}

@Component({
  name: 'GiftATreeBargain'
})
export default class GiftATreeBargain extends Vue {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  @Prop({ type: String, required: false }) readonly context?: string
  
  /*--- DATA ----------*/
  images = bargainsImages
  
  /*--- COMPUTED ------*/
  
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  
  /*--- METHODS -------*/
  
}
