import { TrUser } from '@f/@types/graphql'
import { AuthUserInfo, TreedomAuth } from '@f/auth'
import { ModalServiceCloseData } from '@f/@types/app/ngApp'
import { getModalService, onEventBroadcast } from '@f/services/Angular'

export interface LoggedEventCallback {
  (logged: boolean): void
}
interface RestUserData {
  id?: string | number
  slug?: string
}

type LooseUserData = RestUserData | Pick<TrUser, 'id' | 'slug'>

type UserReference = LooseUserData | string | number

const loginModalOptions = {
  templateUrl: "login.html",
  easyClose: false,
  fitcontent: true,
}

let authState: AuthUserInfo | null = null
export async function initAuthService() {
  TreedomAuth.onStateChange(async ({ logged }) => {
    if (logged) {
      authState = await TreedomAuth.currentUserInfo()
      return
    }
    authState = null
  })
  if (await TreedomAuth.isAuthenticated()) {
    authState = await TreedomAuth.currentUserInfo()
  }
}

export function getAuthState() {
  return authState
}

function matchUserData(user: LooseUserData, authState: AuthUserInfo) {
  if ('id' in user && 'id' in authState) return Number(user.id) === Number(authState.id)
  if ('slug' in user && 'slug' in authState) return user.slug === authState.slug
  return false
}

export function isLoggedInAs(userReference?: UserReference) {
  if (!userReference) return false

  const authState = getAuthState()
  if (!authState) return false

  if (typeof userReference === 'object') return matchUserData(userReference, authState)
  return typeof userReference === 'number' || !!Number(userReference)
    ? Number(userReference) === Number(authState.id)
    : userReference === authState.slug
}

export function isAuthenticated(userReference?: UserReference) {
  if (userReference) {
    return isLoggedInAs(userReference)
  }
  return !!getAuthState()
}

export type AuthenticateOptions =  {
  actionType?: 'login' | 'redeem' | 'checkout',
  /**
   * per disabilitare la survey lato B2B
   */
   disableSurvey?: boolean
}

export async function authenticate(data: AuthenticateOptions = {}) {
  if (await TreedomAuth.isAuthenticated()) return Promise.reject()
  const modalService = getModalService()
  if (!modalService) return Promise.reject()

  return new Promise<void>((resolve, reject) => {
    const loginModalId = modalService.open({...loginModalOptions, data})
    const modalCloseListener = onEventBroadcast<[ModalServiceCloseData]>(modalService.closeEventKey, ({ closedModalId }) => {
      if (closedModalId === loginModalId) {
        clearModalListeners()
        reject()
      }
    })
    const loginListener = onLoggedStatusChange(logged => {
      clearModalListeners()
      logged && resolve() || reject()
    })

    function clearModalListeners() {
      loginListener && loginListener()
      modalCloseListener && modalCloseListener()
    }
  })
}

export function onLoggedStatusChange(handler: LoggedEventCallback, once = true) {
  return onEventBroadcast<[boolean]>('UserLoggedBroadcast', handler, once)
}
