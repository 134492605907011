import { Vue, Component } from 'vue-property-decorator'
import { ValidateFunction } from 'ajv'
import { ErrorBag } from '@f/validation/errors'

@Component({
  name: 'Validate'
})
export default class Validate extends Vue {
  /*--- DATA ----------*/
  errors: ErrorBag = new ErrorBag()

  /*--- METHODS -------*/
  validate(validator: ValidateFunction, data: any) {
    const validated = validator(data)
    if (!validated) {
      this.errors = new ErrorBag(validator.errors)
      return Promise.reject()
    }
    return Promise.resolve()
  }

  clearValidationErrors() {
    this.errors = new ErrorBag()
  }
}
