import { TrStandardMedia, TrTree } from '@f/@types/graphql'

export const getIcon = (tree: TrTree): TrStandardMedia | null | undefined => {
  if (tree.limited?.limitedName) return tree.limited.icon ?? tree.specie?.icon
  return tree.specie?.icon
}

export const getVisibleImage = (
  tree: TrTree
): TrStandardMedia | null | undefined => {
  switch (tree.status) {
    case 1:
    case 2:
    case 3:
    default:
      return getIcon(tree)
    case 4:
      return tree.picture
  }
}


export const isInNursery = (tree: TrTree): boolean => {
  return tree.status! <= 2
}

export const getVisibleCoordinates = (tree: TrTree): [number, number] => {
  if (isInNursery(tree))
    return [
      tree.specie?.originator?.nurseryLng!,
      tree.specie?.originator?.nurseryLat!,
    ]
  return [tree.lng!, tree.lat!]
}
