angular.module('routingService', [])
.factory('Routing', ['$http', '$routing', function ($http, $routing) {
  // Create a new object
  var routingFactory = {}

  routingFactory.route = function (myroute, parameters) {
    return document.location.origin + $routing.generate(myroute, parameters)
  }

  // return our entire routingFactory object
  return routingFactory
}])
