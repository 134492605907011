angular.module('ngApp')
.controller('modalCommunityStreamController', ['$scope', '$rootScope', 'User', function ($scope, $rootScope, User) {
  var streamList = document.getElementById('modalStreamList')
  var loadMore = document.getElementById('modalLoadMore')
  var modalStream = document.getElementById('modalStream')
  $scope.offset = 0
  $rootScope.streamList = []
  $scope.noMore = false

  var getStream = function () {
    User.stream($scope.offset)
    .then(function (response) {
      $rootScope.streamList = response
      $scope.offset++
      $scope.pendingRequest = false
    })
  }

  if (!$rootScope.streamList || !$rootScope.streamList.length) {
    $scope.pendingRequest = true
    getStream()
  }

  streamList.addEventListener('scroll', function () {
    var inView = loadMore.offsetTop <= (streamList.scrollTop + modalStream.offsetHeight)

    if (inView) {
      if ($scope.pendingRequest || $scope.noMore) return

      $scope.pendingRequest = true
      User.stream($scope.offset)
      .then(function (response) {
        $scope.pendingRequest = false
        if (!response.length) {
          $scope.noMore = true
        }
        $scope.offset++
        $rootScope.streamList = [ ...$rootScope.streamList, ...response ]
      })
    }
  })

  $scope.getStreamLink = function (stream) {
    switch (stream.type) {
      case 'order_created':
        return $rootScope.getUrl('user', {'slug': stream.userSlug})
      case 'gift_redeemed':
        return $rootScope.getUrl('user', {'slug': stream.toUserSlug})
      case 'forest_published':
        return $rootScope.getUrl('user.event', {'slug': stream.userSlug, 'eventslug': stream.eventSlug})
      case 'emission_created':
        return $rootScope.getUrl('emission')
      case 'emission_neutralized':
        return $rootScope.getUrl('user', {'slug': stream.userSlug})
      case 'tree_message_created':
        return $rootScope.getUrl('user.trees.item', {'slug': stream.userSlug, 'item': stream.treeId})
      case 'tree_named':
        return $rootScope.getUrl('user.trees.item', {'slug': stream.userSlug, 'item': stream.treeId})
      case 'user_following':
        return $rootScope.getUrl('user', {'slug': stream.toUserSlug})
      case 'tree_followed':
        return $rootScope.getUrl('user.trees.item', {'slug': stream.userSlug, 'item': stream.treeId})
      case 'user_badge_assigned':
        return $rootScope.getUrl('user', {'slug': stream.userSlug})
      case 'order_created':
        return $rootScope.getUrl('user', {'slug': stream.userSlug})
    }
  }
}])
