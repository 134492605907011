<template>
  <transition name="bounce">
    <div class="treedomModalBackground" v-if="open" @click.stop="close">
      <div class="closeModalDesktop" @click.stop="close">
        <span class="closeDarkMiddle">×</span>
      </div>
      <div class="treedomModalTable">
        <div class="treedomModalCell">
          <div class="closeMobile">
            <span class="closeDarkMiddle">×</span>
          </div>
          <div ref="content" class="treedomModalContentWrapper" :class="modalClasses">
            <div style="background-color: transparent; box-shadow: none;" class="treedomModalContent">
              <div class="closeModalButtonWrap" :class="{ 'closeModalButtonWrapOnContent': borderless }">
                <span @click.stop="close" class="closeDarkMiddle">×</span>
              </div>
              <div class="treedomModalContentInside" @click.stop="() => {}">
                <div :class="{ 'treeStreamContentWrap': !borderless }">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'large',
      validator: val => ['large', 'small'].indexOf(val.toLowerCase()) !== -1
    }
  },
  data() {
    return {
      isClosing: false
    }
  },
  computed: {
    modalClasses() {
      return {
        'treedomModalClosing': this.isClosing,
        'treedomModalOpened': !this.isClosing,
        'treedomModalContent-Large': this.size.toLowerCase() === 'large',
        'treedomModalContent-Small': this.size.toLowerCase() === 'small',
      }
    }
  },
  methods: {
    close() {
      this.isClosing = true
      this.$refs.content.addEventListener('animationend', () => {
        this.isClosing = false
        this.$emit('close')
      }, { once: true })
    }
  }
};
</script>
