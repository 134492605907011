import axios from 'axios'
import { clientApp } from '@f/configs/app'

export type CountriesSourceData = typeof import('@f/_legacy/json/countriesV2.min.json')
export type CountryTranslationsKey = keyof CountriesSourceData[number]['translations']

const countriesDataPath = 'bundles/treedomnewfrontend/json/countriesV2.min.json'

export function fetchCountries() {
  const sourceUri = (clientApp.baseUri?.replace(/\/$/, '') || '') + '/' + countriesDataPath
  return axios.get<CountriesSourceData>(sourceUri)
    .then(({ data }) => data)
    .catch(() => [])
}


export function getLocalizedCountryName(country: CountriesSourceData[number], language = 'it') {
  return language in country.translations
      ? country.translations[language as CountryTranslationsKey]
      : country.name
}
