



import {Component, Prop, Vue} from 'vue-property-decorator'

// Icons
  const ICONS = {
    'account': () => ({
      component: import(/* webpackChunkName: 'account.svg' */ '@f/images/icons/account.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'search': () => ({
      component: import(/* webpackChunkName: 'search.svg' */ '@f/images/icons/search.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'cart-new': () => ({
      component: import(/* webpackChunkName: 'cart-new.svg' */ '@f/images/icons/cart-new.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    // Not actually an icon from the set
    'treedom-symbol': () => ({
      component: import(/* webpackChunkName: 'treedom-symbol.svg' */ '@f/images/treedom-symbol.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'bell-fill': () => ({
      component: import(/* webpackChunkName: 'bell-fill.svg' */ '@f/images/icons/bell-fill.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'bell': () => ({
      component: import(/* webpackChunkName: 'bell.svg' */ '@f/images/icons/bell.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'business-fill': () => ({
      component: import(/* webpackChunkName: 'business-fill.svg' */ '@f/images/icons/business-fill.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'card-fill': () => ({
      component: import(/* webpackChunkName: 'card-fill.svg' */ '@f/images/icons/card-fill.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'card-line': () => ({
      component: import(/* webpackChunkName: 'card-line.svg' */ '@f/images/icons/card-line.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'cart': () => ({
      component: import(/* webpackChunkName: 'cart.svg' */ '@f/images/icons/cart.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'check-fill': () => ({
      component: import(/* webpackChunkName: 'check-fill.svg' */ '@f/images/icons/check-fill.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'close': () => ({
      component: import(/* webpackChunkName: 'close.svg' */ '@f/images/icons/close.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'cloud': () => ({
      component: import(/* webpackChunkName: 'cloud.svg' */ '@f/images/icons/cloud.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'cog': () => ({
      component: import(/* webpackChunkName: 'cog.svg' */ '@f/images/icons/cog.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'envelope': () => ({
      component: import(/* webpackChunkName: 'envelope.svg' */ '@f/images/icons/envelope.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'error': () => ({
      component: import(/* webpackChunkName: 'error.svg' */ '@f/images/icons/error.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa-chart-line': () => ({
      component: import(/* webpackChunkName: 'fa-chart-line.svg' */ '@f/images/icons/fa-chart-line.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa-code': () => ({
      component: import(/* webpackChunkName: 'fa-code.svg' */ '@f/images/icons/fa-code.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa-map': () => ({
      component: import(/* webpackChunkName: 'fa-map.svg' */ '@f/images/icons/fa-map.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa-paste': () => ({
      component: import(/* webpackChunkName: 'fa-paste.svg' */ '@f/images/icons/fa-paste.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa-shopping-cart': () => ({
      component: import(/* webpackChunkName: 'fa-shopping-cart.svg' */ '@f/images/icons/fa-shopping-cart.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa-sliders-h': () => ({
      component: import(/* webpackChunkName: 'fa-sliders-h.svg' */ '@f/images/icons/fa-sliders-h.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa-tachometer-alt': () => ({
      component: import(/* webpackChunkName: 'fa-tachometer-alt.svg' */ '@f/images/icons/fa-tachometer-alt.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa4-calendar': () => ({
      component: import(/* webpackChunkName: 'fa4-calendar.svg' */ '@f/images/icons/fa4-calendar.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa4-clock-o': () => ({
      component: import(/* webpackChunkName: 'fa4-clock-o.svg' */ '@f/images/icons/fa4-clock-o.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa4-copy': () => ({
      component: import(/* webpackChunkName: 'fa4-copy.svg' */ '@f/images/icons/fa4-copy.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa4-planet': () => ({
      component: import(/* webpackChunkName: 'fa4-planet.svg' */ '@f/images/icons/fa4-planet.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'fa-user-md': () => ({
      component: import(/* webpackChunkName: 'fa-user-md.svg' */ '@f/images/icons/fa-user-md.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'facebook': () => ({
      component: import(/* webpackChunkName: 'facebook.svg' */ '@f/images/socials/facebook.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'facebook-round': () => ({
      component: import(/* webpackChunkName: 'facebook-round.svg' */ '@f/images/socials/facebook-round.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'flag-stripes-sls': () => ({
      component: import(/* webpackChunkName: 'flag-stripes-sls.svg' */ '@f/images/flags/flag-stripes-sls.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'flag-stripes-mmm': () => ({
      component: import(/* webpackChunkName: 'flag-stripes-mmm.svg' */ '@f/images/flags/flag-stripes-mmm.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'flag-union-jack': () => ({
      component: import(/* webpackChunkName: 'flag-union-jack.svg' */ '@f/images/flags/flag-union-jack.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'gift-delivery-code': () => ({
      component: import(/* webpackChunkName: 'gift-delivery-code.svg' */ '@f/images/icons/gift-delivery-code.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'gift-delivery-email': () => ({
      component: import(/* webpackChunkName: 'gift-delivery-email.svg' */ '@f/images/icons/gift-delivery-email.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'gift-delivery-print': () => ({
      component: import(/* webpackChunkName: 'gift-delivery-print.svg' */ '@f/images/icons/gift-delivery-print.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'gift-fill': () => ({
      component: import(/* webpackChunkName: 'gift-fill.svg' */ '@f/images/icons/gift-fill.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'gift-line': () => ({
      component: import(/* webpackChunkName: 'gift-line.svg' */ '@f/images/icons/gift-line.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'google': () => ({
      component: import(/* webpackChunkName: 'google.svg' */ '@f/images/socials/google.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'hamburger': () => ({
      component: import(/* webpackChunkName: 'hamburger.svg' */ '@f/images/icons/hamburger.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'home': () => ({
      component: import(/* webpackChunkName: 'home.svg' */ '@f/images/icons/home.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'icon-error': () => ({
      component: import(/* webpackChunkName: 'icon-error.svg' */ '@f/images/icons/icon-error.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'info': () => ({
      component: import(/* webpackChunkName: 'info.svg' */ '@f/images/treecons/info-line.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'pencil-line': () => ({
      component: import(/* webpackChunkName: 'info.svg' */ '@f/images/treecons/pencil-line.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'instagram-round': () => ({
      component: import(/* webpackChunkName: 'instagram-round.svg' */ '@f/images/socials/instagram-round.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'invoices': () => ({
      component: import(/* webpackChunkName: 'invoices.svg' */ '@f/images/icons/invoices.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'linkedin-round': () => ({
      component: import(/* webpackChunkName: 'linkedin-round.svg' */ '@f/images/socials/linkedin-round.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'magnifier': () => ({
      component: import(/* webpackChunkName: 'magnifier.svg' */ '@f/images/icons/magnifier.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'phone-fill': () => ({
      component: import(/* webpackChunkName: 'phone-fill.svg' */ '@f/images/icons/phone-fill.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'plant-toolbar': () => ({
      component: import(/* webpackChunkName: 'plant-toolbar.svg' */ '@f/images/icons/plant-toolbar.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'profile-large': () => ({
      component: import(/* webpackChunkName: 'profile-large.svg' */ '@f/images/icons/profile-large.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'profile-line': () => ({
      component: import(/* webpackChunkName: 'profile-line.svg' */ '@f/images/icons/profile-line.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'profile': () => ({
      component: import(/* webpackChunkName: 'profile.svg' */ '@f/images/icons/profile.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'questionmark': () => ({
      component: import(/* webpackChunkName: 'questionmark.svg' */ '@f/images/icons/questionmark.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'quote-icon': () => ({
      component: import(/* webpackChunkName: 'quote-icon.svg' */ '@f/images/icons/quote-icon.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'signout': () => ({
      component: import(/* webpackChunkName: 'signout.svg' */ '@f/images/icons/signout.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'sprout': () => ({
      component: import(/* webpackChunkName: 'sprout.svg' */ '@f/images/icons/sprout.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'success': () => ({
      component: import(/* webpackChunkName: 'success.svg' */ '@f/images/icons/success.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'success-fill': () => ({
      component: import(/* webpackChunkName: 'success-fill.svg' */ '@f/images/icons/success-fill.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'tree': () => ({
      component: import(/* webpackChunkName: 'tree.svg' */ '@f/images/icons/tree.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'treecode-lock': () => ({
      component: import(/* webpackChunkName: 'treecode-lock.svg' */ '@f/images/icons/treecode-lock.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'voucher': () => ({
      component: import(/* webpackChunkName: 'voucher.svg' */ '@f/images/icons/voucher.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'warning': () => ({
      component: import(/* webpackChunkName: 'warning.svg' */ '@f/images/icons/warning.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'wheelbarrow-dynamic': () => ({
      component: import(/* webpackChunkName: 'wheelbarrow-dynamic.svg' */ '@f/images/icons/wheelbarrow-dynamic.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'wheelbarrow-line': () => ({
      component: import(/* webpackChunkName: 'wheelbarrow-line.svg' */ '@f/images/icons/wheelbarrow-line.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'wheelbarrow': () => ({
      component: import(/* webpackChunkName: 'wheelbarrow.svg' */ '@f/images/icons/wheelbarrow.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'world-cog': () => ({
      component: import(/* webpackChunkName: 'world-cog.svg' */ '@f/images/icons/world-cog.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
    'youtube-round': () => ({
      component: import(/* webpackChunkName: 'youtube-round.svg' */ '@f/images/socials/youtube-round.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
  }

  const DS_ICONS = {
    'leaf-fill': () => ({
      component: import(/* webpackChunkName: 'leaf-fill.svg' */ '@f/images/icons/leaf-fill.svg'),
      loading: IconPlaceholder,
      delay: 0,
    }),
  }

  // Functional placeholder
  const IconPlaceholder = Vue.extend({
    name: 'IconPlaceholder',
    functional: true,
    render(createElement) {
      return createElement(
        'svg',
        {
          attrs: {
            viewBox: '0 0 40 40',
            xmlns: 'http://www.w3.org/2000/svg'
          }
        }
      )
    }
  })

  // FIXME: any needed waiting for PR #10253 [https://github.com/vuejs/vue/pull/10253/files/3be9e28746724320121534e0de0bb607276d8b67]
  @Component<any>({
    name: 'Icon',
    components: {
      ...ICONS,
      ...DS_ICONS,
      'icon-placeholder': IconPlaceholder
    },
  })
  export default class Icon extends Vue {
    /*--- PROPS ---------*/
    @Prop({ type: String, required: true, default: 'icon-placeholder' }) readonly name!: keyof typeof ICONS
  }
