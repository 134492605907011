import { IMapInteraction } from '@f/validation/types/dataLayer';
import { validateMapInteraction } from '@f/validation/datalayer/misc';
import tagManagerService from '@f/services/TagManager'


export function DataEventMap(DataEventArgs: Omit<IMapInteraction, "event">) {
  tagManagerService.push({
    event: 'map',
    ...DataEventArgs
  }, validateMapInteraction)
}
