import axios from 'axios'
import gql from 'graphql-tag'
import { api } from '@f/configs/app'
import tagManagerService from '@f/services/TagManager'
import { validateAccountInfoSubmitted } from '@f/validation/datalayer/user'
import { apiClient } from '@f/apollo'
import { s3ToStandardMedia } from '@f/utils/media'
import { stringToTranslation } from '@f/utils/strings'
import { TrEvent } from '@f/@types/graphql'
import { RestUserEvent, RestUserMap } from '@f/@types/rest'
import { UserBalancer, UserCO2, UserProfile, UserBadge, UserFriend, OrganizationBalancer, BusinessUsersPage } from '@f/@types'
import { FORESTS_USER_PLANTED_IN, USER_FORESTS }  from '@f/graphql/queries'
import { registerAxiosAuthorizationInterceptor } from '@f/auth/helpers'

const client = axios.create({
  baseURL: api.baseUri,
})
registerAxiosAuthorizationInterceptor(client)

function restEventsToGraphQl(forests: RestUserEvent[]): TrEvent[] {
  return forests.map(forest => {
    return {
      id: Number(forest.id),
      name: forest.name,
      slug: forest.slug,
      description: forest.description && stringToTranslation(forest.description) || null,
      published: !!Number(forest.published),
      avatar: s3ToStandardMedia(forest.avatar_id),
      background: s3ToStandardMedia(forest.background_id),
    }
  })
}

export function fetchProfile() {
  return client.get<UserProfile>('user/edit/info')
    .then(({ data }) => data)
}

export function updateProfile(user: Partial<UserProfile>) {
  // TODO: add validation once merged with auth calls branch
  return client.post('user/edit/info', user)
    .then(data => {
      tagManagerService.push({
        event: 'accountInfoSubmitted'
      }, validateAccountInfoSubmitted)
      return data
    })
}

export function fetchUserInfo<T = UserBalancer | OrganizationBalancer>(slug: string) {
  return client.get<T>(`user/${slug}/balancer`)
    .then(({ data }) => { return { ...data } })
}

export function fetchUserCO2(id: number) {
  return client.get<UserCO2>(`user/${id}/co2`)
    .then(({ data }) => data)
}

export function fetchUserSummary(id: number) {
  return apiClient.query({
    query: gql`
      query UserSummary ($id: Int!){
        User_Summary(id: $id) {
          mine
          gifted
          countries {
            country {
              id
              code
              name {
                it
                en
                de
                fr
                es
              }
              slug
              flag {
                small
                medium
              }
              flagImage
              projectBackground {
                medium
              } 
              lat
              lng  
            }
            count
          }
        }
      }
    `,
    variables: { id: Number(id) },
  }).then(({ data: { User_Summary } }) => User_Summary )
}

export function fetchUserBadges(id: number) {
  return client.get<UserBadge[]>('badge/'+id)
    .then(({ data }) => data)
}

export function fetchUserForests(userId: number) {
  return apiClient.query({
    query: USER_FORESTS,
    variables: { userId }
  }).then(({ data: { Event_byUser_List_public } }) => Event_byUser_List_public)
}

export function fetchForestsUserPlanted(userId: number, includeGifted: boolean = false) {
  return apiClient.query({
    query: FORESTS_USER_PLANTED_IN,
    variables: { userId, includeGifted }
  }).then(({ data: { Event_byUser_Planted_list } }) => Event_byUser_Planted_list)
}

export function fetchPrivateMap(id: number) {
  return client.get('map/user/'+id)
    .then(({ data }) => data)
}

interface FollowResponse {
  follower?: UserFriend[]
  following?: UserFriend[]
}

export function fetchRestUserMap(id: number): Promise<RestUserMap> {
  return client.get<RestUserMap>('map/user/'+id)
    .then(({ data }) => data)
}

export function fetchUserFriends(userId: number) {
  return client.get<FollowResponse>(`user/${userId}/follow`)
    .then(({ data }) => data)
}

export function toggleFollowUser(slug: string) {
  return client.post('/user/relation/togglefollow/'+slug, undefined)
    .then(({ data }) => data)
}

export function isFollowed(followers: UserFriend[], friendSlug?: string): boolean {
  if(!friendSlug || !followers.length) return false;
  return !!followers?.some(friend => friend.slug === friendSlug)
}

export function fetchBusinessUsers(userId?: string, page = 0, limit = 10) {
  if (!userId) return Promise.reject('No user id provided')
  return client.get<BusinessUsersPage>(`business/${userId}/${page}/users?perPage=${limit}`)
    .then(({ data }) => data)
}