import { AsyncValidateFunction, ValidateFunction } from 'ajv'
import { ErrorBag } from '@f/validation/errors'

export async function validateAsync<T>(validator: ValidateFunction<T> | AsyncValidateFunction<T>, data: T) {
  const validated = validator(data)
  if (typeof validated === 'boolean') {
    return validated ? Promise.resolve(data) : Promise.reject(validator.errors)
  }
  return validated
}
