import { Vue, Component } from "vue-property-decorator";
import { UserInfo } from "@f/@types";
import { DataEventLogout } from '@f/services/TagManager/events'

@Component
export default class UserMixin extends Vue {
  modalService: any;
  userInfo: UserInfo | null = null;
  userInfoListener: (() => void) | null = null;
  loginListener: (() => void) | null = null;
  userService: any = null;

  loggedIn: boolean = false;

  get userTypeState(): "organization" | "user" {
    return this.userInfo?.usertype === "Business" ? "organization" : "user";
  }

  get userSlug(): string {
    return this.userInfo?.slug || "";
  }

  get userFullName(): string {
    return this.userInfo
      && (this.userInfo.title || this.userInfo.firstname+' '+this.userInfo.lastname)
      || ''
  }

  get profileUrl(): string {
    return this.userInfo
      ? this.$state.href(this.userTypeState, { slug: this.userSlug })
      : "#";
  }

  get editProfileUrl(): string {
    return this.userInfo
      ? this.$state.href("settings")
      : "#";
  }

  mounted(): void {
    this.modalService = this.ngService("Modal");
    this.userService = this.ngService("User");

    if (this.$rootScope.userdata?.info) {
      this.updateUserInfo(undefined, this.$rootScope.userdata.info);
    } else {
      // Listener for userData Info
      this.userInfoListener = this.$rootScope.$on(
        "userdata.info",
        this.updateUserInfo
      );
    }

    // Listener for login/logout state
    this.loginListener = this.$rootScope.$on(
      "UserLoggedBroadcast",
      this.loginChange
    );
  }

  destroyed(): void {
    this.userInfoListener && this.userInfoListener();
    this.loginListener && this.loginListener();
  }

  updateUserInfo(_: any | void, info: UserInfo) {
    this.userInfo = info
    this.loggedIn = !!info;
    this.userInfoListener && this.userInfoListener();
    this.onUserInfo && this.onUserInfo();
  }

  loginChange(_: any, userIsLoggedIn: boolean) {
    if (!userIsLoggedIn) {
      this.userInfo = null;
    }
    this.loggedIn = userIsLoggedIn;
    this.userInfoListener = this.$rootScope.$on(
      "userdata.info",
      (e, info) => this.updateUserInfo(e, info),
    );
  }

  openLoginModal() {
    // location.href = '/en/auth/login'
    this.$state.go('auth-login', {
      next: window.location.href,
    })
  }

  logout(redirect = true) {
    DataEventLogout();

    this.$rootScope.$broadcast("UserLoggedBroadcast", false);
    return this.$rootScope.logout(redirect);
  }

  async switchUser() {
    await this.logout(false)
    this.openLoginModal()
  }

  onUserInfo() {}
}
