





















import { Vue, Component, Prop } from "vue-property-decorator"

@Component({
  name: "TreeDiaryItemHeader"
})
export default class TreeDiaryItemHeader extends Vue {
  @Prop({ type: String, required: true }) readonly picture!: string
  @Prop({ type: String, required: true }) readonly title!: string
  @Prop({ type: String, default: "" }) readonly subtitle!: string
  @Prop({ type: Boolean, default: true }) readonly fromTree!: boolean
}
