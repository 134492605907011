angular.module('ngApp')
  .controller('treeComponentController', ['$scope', '$rootScope', 'Utility', '$state', 'Ecommerce', function ($scope, $rootScope, Utility, $state, Ecommerce) {
    var ctrl = this;

    ctrl.id = $scope.attrs.id;
    ctrl.key = $scope.attrs.key;
    ctrl.icon = $scope.attrs.icon;
    ctrl.price = $scope.attrs.price;
    ctrl.priceCurrency = $scope.attrs.priceCurrency;
    ctrl.onHand = $scope.attrs.onHand * 1;
    ctrl.tankSize = $scope.attrs.tankSize * 1;
    ctrl.meaning = $scope.attrs.meaning;
    ctrl.name = $scope.attrs.name;
    ctrl.limitedNameSubTitle = $scope.attrs.limitedNameSubTitle || '';
    ctrl.country = $scope.attrs.country; // TODO: inserire country nella migrazione degli alberi
    ctrl.type = $scope.attrs.type;
    ctrl.enable = $scope.attrs.enable !== 'false';
    ctrl.badge = $scope.attrs.badge;
    ctrl.badgeDate = $scope.attrs.badgeDate;
    ctrl.badgeDiscount = $scope.attrs.badgeDiscount;
    ctrl.priceDiscount = $scope.attrs.priceDiscount;
    ctrl.limitedSerieSlug = $scope.attrs.limitedSerieSlug;
    ctrl.color = $scope.attrs.color;
    ctrl.footerBackground = $scope.attrs.footerBackground;
    ctrl.goTo = $scope.attrs.goTo;
    ctrl.navbar = $scope.attrs.navbar
      ? $scope.attrs.navbar === 'false' ? false : true
      : true;
    ctrl.powers = $scope.attrs.powers;
    ctrl.customClick = $scope.attrs.customClick === 'true';
    
    $scope.showQuantity = $scope.attrs.showQuantity === 'true';
    $scope.quantity = 0;
    $scope.qtSteps = [5, 10, 50, 100, 200]

    $scope.selected = function(qt) {
      if ($scope.showQuantity) $scope.onQuantityChange($scope.attrs, qt)
    }

    ctrl.showThisProduct = function () {
      if (ctrl.customClick) return $scope.onTreeClick($scope.attrs)
      if (ctrl.badge === 'comingSoon') return;

      // TODO: Cosa si deve passare con il nuovo ecommerce?
      // Utility.gaAdd('addProduct', ctrl.tree)
      // Utility.gaAction('click', {})
      // Utility.gaSend()

      $rootScope.sliderHistory = {
        ecommerce: $scope.attrs.ecommerce,
        productId: $scope.attrs.id
      };

      $rootScope.lastSlug = $scope.attrs.limitedSerieSlug || null

      switch (ctrl.goTo) {
        case 'project':
          $state.go('project.tree', { treeId: ctrl.key });
          break;
        default:
          $state.go('species.tree', { treeId: ctrl.key });
      }
    };

    // Alert me when tree is available
    ctrl.letMeKnow = function () {
      Ecommerce.letMeKnow(ctrl.id)
    }
  }]);
