import { CURRENT_CART, GET_CLIENT_TOKEN, GET_USER_PAYMENT_METHODS } from './queries'
import { ADD_PRODUCT, DELETE_PAYMENT_METHOD, REMOVE_LINE_ITEM, UPDATE_CART_CURRENCY } from './mutations'

import { apiClient as gqlClient } from '@f/apollo'
import { Cart, PaymentMethodBraintree } from '../types'
import Cookies from 'js-cookie'
import { cookieKeys, ShopCurrency } from '@f/configs/app'
import { DataEventChangeCurrency } from '../../TagManager/events'

export interface IAddProduct {
  productId: string
  forestId?: string
  quantity: number
  isGift?: boolean
}

export interface IRemoveLineItem {
  lineItemId: string
}

export interface IDeletePaymentMethodArgs {
  paymentMethodToken: string
}

export interface IUserBraintreePaymentMethod {
  braintree: PaymentMethodBraintree
  token: string
}

interface IUpdateCartCurrency {
  currency: ShopCurrency
}
interface CartService {
  fetchCart: () => Promise<Cart>
  addProduct: ({
    productId,
    forestId,
    quantity,
    isGift,
  }: IAddProduct) => Promise<Cart>
  removeLineItem: ({ lineItemId }: IRemoveLineItem) => Promise<Cart | null>
  loadClientToken: () => Promise<{clientToken: string}>
  deletePaymentMethod: ({ paymentMethodToken }: IDeletePaymentMethodArgs) => Promise<boolean>
  getUserPaymentMethods: () => PromiseLike<IUserBraintreePaymentMethod[]>
  updateCartCurrency: ({ currency }: IUpdateCartCurrency) => PromiseLike<Cart>

}

export const manageCookie = (cookieName: string) => ({
  get: () => {
    return Cookies.get(cookieName)
  },
  set: (value: string) => {
    return Cookies.set(cookieName, value)
  },
  remove: () => {
    return Cookies.remove(cookieName)
  },
})

const cartIdManageCookie = manageCookie(cookieKeys.tempCartKey)
const currencyManageCookie = manageCookie(cookieKeys.currency)

const getCartId = () => {
  const cartId = cartIdManageCookie.get()
  return cartId
}

const getCurrrency = () => {
  const currency = currencyManageCookie.get() ?? ShopCurrency.Eur
  return currency
}

const handleCartId = (responseData: Cart | null) => {
  if (responseData && responseData.id) {
    cartIdManageCookie.set(responseData.id.toString())
  } else {
    cartIdManageCookie.remove()
  }
}

const changeCurrency = async (currency: ShopCurrency) => {
  if(Cookies.get(cookieKeys.tempCartKey)) {
    await cartService.updateCartCurrency({currency})
  }
  Cookies.set(cookieKeys.currency, currency, {expires: 365})
  DataEventChangeCurrency(currency)
  window.location.reload()
}

const cartService: CartService = {
  fetchCart: async () => {
    const response = await gqlClient.query<{ cart: Cart }>({
      query: CURRENT_CART,
      variables: { cartId: getCartId() },
    })

    handleCartId(response.data!.cart)

    return response.data.cart
  },
  addProduct: async (addProductParams) => {
    const response = await gqlClient.mutate<{ addProduct: Cart }>({
      mutation: ADD_PRODUCT,
      variables: {
        ...addProductParams,
        currency: getCurrrency(),
        cartId: getCartId(),
      },
    })

    handleCartId(response.data!.addProduct)

    return response.data!.addProduct
  },
  removeLineItem: async (removeLineItemParams) => {
    const response = await gqlClient.mutate<{ removeProduct: Cart } | null>({
      mutation: REMOVE_LINE_ITEM,
      variables: {
        ...removeLineItemParams,
        cartId: getCartId(),
      },
    })

    handleCartId(response.data!.removeProduct ?? null)

    return response.data!.removeProduct ?? null
  },
  loadClientToken: async () => {
    const result = await gqlClient.query<{getClientToken: {clientToken: string}}>({query: GET_CLIENT_TOKEN, variables: { currency: 'EUR' }})
    return result.data.getClientToken!
  },
  deletePaymentMethod: async (deletePaymentMethodParams) => {
    const response = await gqlClient.mutate<boolean>({
      mutation: DELETE_PAYMENT_METHOD,
      variables: {
        ...deletePaymentMethodParams,
      },
    })
    return response.data!
  },
  getUserPaymentMethods: async () => {
    const result = await gqlClient.query<{userPaymentMethods: IUserBraintreePaymentMethod[]}>({query: GET_USER_PAYMENT_METHODS})
    return result.data.userPaymentMethods
  },
  updateCartCurrency:  async (updateCartCurrencyParams) => {
    const response = 
      await gqlClient.mutate<{cart: Cart}>({mutation: UPDATE_CART_CURRENCY, variables: {
        ...updateCartCurrencyParams,
        cartId: await getCartId(),
      }})    

    handleCartId(response.data!.cart)

    return response.data!.cart
  }
}

export { cartService, changeCurrency }
