angular.module('ngApp')
  .controller('talesOfUsController', ['$scope', '$rootScope', '$state', '$stateParams', '$filter', 'Utility', '$interval', '$timeout', function ($scope, $rootScope, $state, $stateParams, $filter, Utility, $interval, $timeout) {
    var ctrl = this

    ctrl.talesOfUsList = [
      {
        id: 1,
        name: 'ilsole24ore',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-08-sole-24-ore.svg'),
        link: '',
        language: 'it'
      },
      {
        id: 2,
        name: 'Corriere della sera',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-corriere-della-sera.svg'),
        link: '',
        language: 'it'
      },
      {
        id: 3,
        name: 'La Repubblica',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-repubblica.svg'),
        link: '',
        language: 'it'
      },
      {
        id: 4,
        name: 'Wired',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-wired.svg'),
        link: '',
        language: 'it'
      },
      {
        id: 5,
        name: 'Radio Deejay',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-deejay.svg'),
        link: '',
        language: 'it'
      },
      {
        id: 6,
        name: 'Huffingtonpost',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-huffpost.svg'),
        link: '',
        language: 'it'
      },
      {
        id: 7,
        name: 'Utopia',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-utopia.svg'),
        link: '',
        language: 'de'
      },
      {
        id: 8,
        name: 'Horizont',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-horizont.svg'),
        link: '',
        language: 'de'
      },
      {
        id: 9,
        name: 'W & V',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-w-&-v.svg'),
        link: '',
        language: 'de'
      },
      {
        id: 10,
        name: 'Ego fm',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-ego-fm.svg'),
        link: '',
        language: 'de'
      },
      {
        id: 11,
        name: 'Mit Vergnügen München',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-Mit-Vergnugen-Munchen.svg'),
        link: '',
        language: 'de'
      },
      {
        id: 12,
        name: 'Forum Nachhaltig Wirtschaften',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-Forum-Nachhaltig-Wirtschaften.svg'),
        link: '',
        language: 'de'
      },
      {
        id: 13,
        name: 'Vogue',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-vogue.svg'),
        link: '',
        language: 'en'
      },
      {
        id: 14,
        name: 'Huffingtonpost',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-huffpost.svg'),
        link: '',
        language: 'en'
      },
      {
        id: 15,
        name: 'Wired',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-wired.svg'),
        link: '',
        language: 'en'
      },
      {
        id: 16,
        name: 'Vogue',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-vogue.svg'),
        link: '',
        language: 'fr'
      },
      {
        id: 17,
        name: 'Huffingtonpost',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-huffpost.svg'),
        link: '',
        language: 'fr'
      },
      {
        id: 18,
        name: 'Wired',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-wired.svg'),
        link: '',
        language: 'fr'
      },
      {
        id: 19,
        name: 'Vogue',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-vogue.svg'),
        link: '',
        language: 'es'
      },
      {
        id: 20,
        name: 'Huffingtonpost',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-huffpost.svg'),
        link: '',
        language: 'es'
      },
      {
        id: 21,
        name: 'Wired',
        src: $filter('asset')('bundles/treedomnewfrontend/images/talesOfUs/talesOfUs_logos-wired.svg'),
        link: '',
        language: 'es'
      }
    ]
  }])
