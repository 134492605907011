



















import { defineComponent, ref } from "@vue/composition-api";
import QuestionLayout from "./../../Layout/Question/QuestionLayout.vue";
import QuestionHeader from "./../../Layout/Question/QuestionHeader.vue";
import QuestionVideoPlayer from "./../../Layout/Question/QuestionVideoPlayer.vue";
import AnswerList, { Answer } from "../../Layout/Question/AnswerList.vue";
import DotLoader from "@f/components/DotLoader.vue";

export default defineComponent({
  name: "CountriesQuestion",
  components: {
    QuestionLayout,
    QuestionHeader,
    AnswerList,
    DotLoader,
    QuestionVideoPlayer,
  },
  emits: ["complete"],
  setup: (_, { emit }) => {
    const expandedPlayer = ref(true);
    const canPlay = ref(true);

    const onAnswer = (isCorrect: boolean) => {
      emit("complete", { result: isCorrect });
      canPlay.value = false;
    };

    const questionText = "game.question.country";
    const answers: Answer[] = [
      {
        description: "game.question.country.option1",
        explanation: {
          correct: "game.question.country.option1.explanation.correct",
        },
        isCorrect: true,
      },
      {
        description: "game.question.country.option2",
        explanation: {
        },
        isCorrect: false,
      },
      {
        description: "game.question.country.option3",
        explanation: {
        },
        isCorrect: false,
      },
      {
        description: "game.question.country.option4",
        explanation: {
        },
        isCorrect: false,
      },
    ];
    return {
      questionText,
      answers,
      onAnswer,
      expandedPlayer,
      canPlay
    };
  },
});
