import range from 'lodash/range'

/**
 * Years
 */
// TODO: refactor needed
const pastYearsCount = 100
const futureYearsCount = 3

const currentYear = new Date().getFullYear()
const years = Array(pastYearsCount).fill().map((_, i) => currentYear - i)
const futureYears = Array(futureYearsCount).fill().map((_, i) => currentYear + i)

/**
 * Months
 */
const months = range(12)

const monthsName = {
  0: {
    it: 'Gennaio',
    en: 'January',
    es: 'enero',
    fr: 'janvier',
    de: 'Januar'
  },
  1: {
    it: 'Febbraio',
    en: 'February',
    es: 'febrero',
    fr: 'février',
    de: 'Februar'
  },
  2: {
    it: 'Marzo',
    en: 'March',
    es: 'marzo',
    fr: 'mars',
    de: 'März'
  },
  3: {
    it: 'Aprile',
    en: 'April',
    es: 'abril',
    fr: 'avril',
    de: 'April'
  },
  4: {
    it: 'Maggio',
    en: 'May',
    es: 'mayo',
    fr: 'mai',
    de: 'Mai'
  },
  5: {
    it: 'Giugno',
    en: 'June',
    es: 'junio',
    fr: 'juin',
    de: 'Juni'
  },
  6: {
    it: 'Luglio',
    en: 'July',
    es: 'julio',
    fr: 'juillet',
    de: 'Juli'
  },
  7: {
    it: 'Agosto',
    en: 'August',
    es: 'agosto',
    fr: 'août',
    de: 'August'
  },
  8: {
    it: 'Settembre',
    en: 'September',
    es: 'septiembre',
    fr: 'septembre',
    de: 'September'
  },
  9: {
    it: 'Ottobre',
    en: 'October',
    es: 'octubre',
    fr: 'octobre',
    de: 'Oktober'
  },
  10: {
    it: 'Novembre',
    en: 'November',
    es: 'noviembre',
    fr: 'novembre',
    de: 'November'
  },
  11: {
    it: 'Dicembre',
    en: 'December',
    es: 'diciembre',
    fr: 'décembre',
    de: 'Dezember'
  }
}

/**
 * Days
 * @param month = {Number}. From 0 to 11
 * @param year = {Number}
 */
const getDays = function (month, year) {
  if (year) year = year * 1

  switch (month) {
    case 1:
      if (year) return year % 4 ? range(1, 29) : range(1, 30)
      return range(1, 29)
    case 3:
    case 5:
    case 8:
    case 10:
      return range(1, 31)
    default:
      return range(1, 32)
  }
}

const hours = range(24)

const hoursName = {
  1: '1am',
  2: '2am',
  3: '3am',
  4: '4am',
  5: '5am',
  6: '6am',
  7: '7am',
  8: '8am',
  9: '9am',
  10: '10am',
  11: '11am',
  12: '12am',
  13: '1pm',
  14: '2pm',
  15: '3pm',
  16: '4pm',
  17: '5pm',
  18: '6pm',
  19: '7pm',
  20: '8pm',
  21: '9pm',
  22: '10pm',
  23: '11pm',
  0: '12pm'
}

export default {
  hours: hours,
  hoursName: hoursName,
  days: getDays,
  years: years,
  futureYears: futureYears,
  months: months,
  monthsName: monthsName
}
