import moment from 'moment'

angular.module('trackingService', [])
.factory('Tracking', ['$rootScope', '$filter', 'User', function($rootScope, $filter, User) {

  const woopra = window.woopra

  function updateUserInfo() {
    if (!woopra) return
    User.userEditInfo().then(info => {
      if (info) {
        const user = {
          id: info.id,
          gender: info.gender,
          slug: info.slug,
          name: info.title || [info.firstname, info.lastname].filter(i => !!i).join(' '),
          email: info.mail,
          type: info.usertype,
          facebook: info.facebookUid,
          google: info.googleUid,
          confirmed: info.hasConfirmedMail,
          language: info.locale,
        }
        if (info.picture) {
          user.avatar = $filter('s3')(info.picture)
        }
        if (info.date_of_birth) {
          user.birthday = info.date_of_birth
          user.age = moment().diff(info.date_of_birth, 'years')
        }
        woopra.identify(user).push()
      }
    })
  }

  const trackingService = {
    init() {
      if (!woopra) return
      // Send identifications
      if (!!$rootScope.user) updateUserInfo()
      $rootScope.$on(
        'UserLoggedBroadcast',
        (_ev, logged) => { logged && updateUserInfo() })
      // Track pageViews
      $rootScope.$on(
        '$stateChangeSuccess',
        () => { woopra && woopra.track() })
    }
  }

  return trackingService

}])
