<template>
  <div>
    <TreeMarker
      v-if='!loading'
      :icon='icon'
      :lat='lat'
      :lng='lng'
      :id='treeId'
    />
  </div>
</template>
<script>
import { computed, defineComponent, inject, watch  } from '@vue/composition-api'
import { MAP_INSTANCE } from '@f/services/Map'
import { TREE_MARKER } from '@f/graphql/queries'
import { useQuery, useResult } from '@vue/apollo-composable'
import { getVisibleCoordinates, getVisibleImage, } from '@f/services/Tree'
import TreeMarker from '@f/components/Map/Markers/TreeMarker'

export default defineComponent({
  name: 'MapTreeMarker',
  components: { TreeMarker },
  props: {
    treeId: {
      type: String,
      required: true,
    },
    autoCenterOnChange: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const map = inject(MAP_INSTANCE)
    const {
      result,
      loading,
    } = useQuery(TREE_MARKER, () => ({
      id: props.treeId,
    }))
    const tree = useResult(result)
    const coordinates = computed(() => {
      if (!tree.value) return null
      return getVisibleCoordinates(tree.value)
    })
    const lat = computed(() => coordinates.value?.[1])
    const lng = computed(() => coordinates.value?.[0])
    const icon = computed(() => {
      if (!tree.value) return null
      return getVisibleImage(tree.value)?.small
    })
    watch(tree, () => {
      if (!props.autoCenterOnChange) return
      if (!tree.value) return
      const coordinates = getVisibleCoordinates(tree.value)
      map.value.flyTo({
        zoom: Math.max(8, map.value.getZoom()),
        center: coordinates,
      })
    })
    return {
      loading,
      coordinates,
      icon,
      lat,
      lng,
    }
  }
})
</script>