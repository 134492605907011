









































import { defineComponent, computed, ref } from '@vue/composition-api'
import moment, { Moment, unitOfTime } from 'moment'
import BaseSelect from '@f/components/Form/BaseSelect.vue'
import { clamp } from '@f/utils/numbers'
import { preferences } from '@f/configs/app'

moment.locale(preferences.language)

type timeUnit = Extract<unitOfTime.Base, 'year' | 'month' | 'day'>

interface DateSelection {
  year: number | undefined
  month: number | undefined
  day: number | undefined
}

export default defineComponent({
  name: 'SplitDatePicker',
  components: { BaseSelect },
  props: {
    value: {
      type: [String, Date]
    },
    format: {
      type: String
    },
    min: {
      type: [String, Date],
      default: () => moment.utc().startOf('year').toDate()
    },
    max: {
      type: [String, Date],
      default: () => moment.utc().endOf('year').toDate()
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    }
  },
  setup(props, context) {
    const selected = ref<DateSelection>({
      year: undefined,
      month: undefined,
      day: undefined,
    })

    const selectedDay = computed({
      get: () => selected.value.day
        && clamp(selected.value.day, 1, daysCount.value)
        || undefined,
      set: (value) => {
        emitUpdate('day', value && clamp(value, 1, daysCount.value))
      }
    })

    const daysCount = computed(() => {
      const referenceDate = moment.utc([
        selectedYear.value || 1972, // Leap year
        selectedMonth.value || 0,
        1
      ])
      return referenceDate.daysInMonth()
    })

    const selectedMonth = computed({
      get: () => selected.value.month,
      set: emitUpdate.bind(undefined, 'month')
    })

    const months = computed(() => moment.months())
    
    const selectedYear = computed({
      get: () => selected.value.year,
      set: emitUpdate.bind(undefined, 'year')
    })

    const yearsCount = computed(() => maxDate.value.year() - minDate.value.year() + 1)

    const firstYear = computed(() => minDate.value.year())

    const valueDate = computed(() => {
      if (props.value) {
        const propDate = moment(props.value, props.format).utc(true)
        if (!propDate.isValid()) return
        selected.value = {
          year: propDate.year(),
          month: propDate.month(),
          day: propDate.date()
        }
        return propDate
      }
    })

    const minDate = computed(() => moment(props.min, props.format).utc(true))

    const maxDate = computed(() => moment(props.max, props.format).utc(true))

    function emitUpdate(unit: timeUnit, value?: string | number) {
      if (value === undefined || value === null || isNaN(Number(value))) {
        context.emit('input', undefined)
      } 
      selected.value[unit] = Number(value)
      let emitDate = parseSelectedDate()
      if (emitDate?.isValid()) {
        emitDate = clampDate(emitDate)
        context.emit(
          'input',
          props.format
            ? emitDate.format(props.format)
            : emitDate.toISOString()
        )
      }
    }

    function clampDate(value: Moment) {
      return moment.max(minDate.value, moment.min(maxDate.value, value))
    }

    function parseSelectedDate() {
      if (
        !!selected.value.day
        && typeof selected.value.month === 'number'
        && !!selected.value.year) {
          try {
            return moment.utc([1972, 0, 1]) // Default leap year
              ?.date(selected.value.day!)
              ?.month(selected.value.month!)
              ?.year(selected.value.year!)
          } catch {}
        }
    }

    return {
      daysCount,
      firstYear,
      months,
      selected,
      selectedDay,
      selectedMonth,
      selectedYear,
      valueDate,
      yearsCount,
    }
  }
})
