// TODO: Temporary fake logger

const logger = {
  log: (message: string, context: any) => {
    console.dir({
      type: 'log',
      message,
      context
    })
  },
  debug: (message: string, context: any) => {
    console.dir({
      type: 'debug',
      message,
      context
    })
  },
  info: (message: string, context: any) => {
    console.dir({
      type: 'info',
      message,
      context
    })
  },
  warn: (message: string, context: any) => {
    console.dir({
      type: 'warn',
      message,
      context
    })
  },
  error: (message: string, context: any) => {
    console.dir({
      type: 'error',
      message,
      context
    })
  },
}

export default logger
