




























import { Vue, Component, Prop } from 'vue-property-decorator'
import Popover from '@f/components/Popover.vue'
import PopCounter from '@f/components/PopCounter.vue'

import Icon from '@f/components/Icon.vue'
import { IAngularEvent } from 'angular'
import { Product } from '@f/@types'
import CartPreview from '@f/components/Ecommerce/CartPreviewNewEcommerce.vue'

import {
  DataEventCartViewedNewEcommerce,
} from '@f/services/TagManager/events'
import { Cart as NewCart } from '../../../services/NewEcommerce/types'

interface CartUpdateBroadcast {
  cart: Cart
  action:
    | 'productAssigned'
    | 'productEdited'
    | 'productRemoved'
    | 'voucherAssigned'
    | 'voucherRemoved'
}

export type Price = {
  centAmount: number
  currencyCode: string
  fractionDigits: number
  type: string
}

export type VoucherInfo = {
  amount: number
  code: string
  id: number
  percentage: number
}

/** Cart object */
export type Cart = {
  id: string
  isAnonymous?: boolean
  isBusiness?: boolean
  lineItems: CartLineItem[]
  totalPrice: Price
  discountedTotalPrice: Price | null
  voucher?: VoucherInfo
}

export interface CartLineItem {
  forest?: Event
  id: string
  isGift: boolean
  price: Price
  product: Product
  quantity: number
  totalPrice: Price
}

@Component({
  name: 'CartWidget',
  components: {
    Popover,
    PopCounter,
    CartPreview,
    Icon,
  },
})
export default class CartWidgetNewEcommerce extends Vue {
  /*--- MODEL ---------*/

  /*--- PROPS ---------*/
  @Prop({ type: Number, default: 3000 }) readonly closeDelay!: number

  /*--- DATA ----------*/
  cart: Cart | null = null
  cartListener: (() => void) | null = null
  cartUpdateListener: (() => void) | null = null
  closeDelayHandler: ReturnType<typeof setTimeout> | null = null
  openPopover: boolean = false
  showFeedback: boolean = false

  /*--- COMPUTED ------*/
  get groupCartItems(): boolean {
    return (
      (this.$rootScope?.userType ||
        this.$rootScope?.userdata?.info?.usertype) === 'Business'
    )
  }

  get cartItemsCount(): number {
    if (!!this.cart?.lineItems) {
      return this.cart.lineItems.reduce((acc, item) => {
        return acc + item.quantity
      }, 0)
    }
    return 0
  }

  get isCartEmpty(): boolean {
    return !this.cartItemsCount
  }

  /*--- WATCHERS ------*/

  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  created() {
    if (this.$rootScope.userdata?.cart) {
      this.cart = this.$rootScope.userdata?.cart as unknown as Cart
    }
    this.cartListener = this.$rootScope.$on(
      'userdata.cart',
      (_: IAngularEvent, cart: Cart) => {
        this.cart = cart
      }
    )
    this.cartUpdateListener = this.$rootScope.$on(
      'userdata.cart.update',
      (_: IAngularEvent, { cart, action }: CartUpdateBroadcast) => {
        this.cart = cart
        if (action === 'productAssigned') this.openFeedback()
      }
    )
  }

  destroyed() {
    this.cartListener && this.cartListener()
    this.cartUpdateListener && this.cartUpdateListener()
  }

  onClick() {
    this.openPopover = !this.openPopover

    // TagManager
    if (this.openPopover) {
      DataEventCartViewedNewEcommerce(this.cart as unknown as NewCart)
    }
  }

  /*--- METHODS -------*/
  openFeedback(): void {
    if (!this.openPopover) this.openPopover = true
    if (!this.showFeedback) this.showFeedback = true
    if (this.closeDelayHandler) clearTimeout(this.closeDelayHandler)
    if (this.closeDelay) {
      this.closeDelayHandler = setTimeout(() => {
        this.openPopover = false
        this.showFeedback = false
      }, this.closeDelay)
    }
  }

  clearCloseDelay(): void {
    if (this.showFeedback) this.showFeedback = false
    if (this.closeDelayHandler) clearTimeout(this.closeDelayHandler)
  }
}
