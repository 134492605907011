






    import { defineComponent } from '@vue/composition-api'
    import Treecon from '@f/components/Treecons/Treecon.vue'

export default defineComponent({
    name: 'TonneExplanation',
	components: {
    	Treecon
  	}
})
