require("./widgetcart.js");
require("./widgetcountry.js");
require("./widgetevent.js");
require("./widgetmobilespecie.js");
require("./widgetmobiletree.js");
require("./widgetnotifications.js");
require("./widgetspecie.js");
require("./widgetstream.js");
require("./widgettree.js");
require("./widgetuser.js");
