angular.module('ngApp')
.controller('menuMobileController', ['$scope', '$rootScope', 'Routing', '$state', 'ipCookie', 'Modal', '$timeout', 'MenuMobile', function ($scope, $rootScope, Routing, $state, ipCookie, Modal, $timeout, MenuMobile) {
  var $ = window.$
  var _ = window._

  // var openModal = null
  //
  // $scope.menuIsOpen = function (modal) {
  //   return openModal === modal
  // }
  //
  // $scope.openMobileMenuModal = function (modal) {
  //   openModal = (modal === openModal ? null : modal)
  //   $timeout(function () {
  //     $scope.$apply()
  //   })
  //
  //   return true
  // }

  $scope.languages = ['it', 'en', 'fr', 'es', 'de']
  $scope.flags = {
    it: 'bundles/treedomnewfrontend/images/flags/flags_italy.svg',
    en: 'bundles/treedomnewfrontend/images/flags/flags_UK.svg',
    fr: 'bundles/treedomnewfrontend/images/flags/flags_france.svg',
    es: 'bundles/treedomnewfrontend/images/flags/flags_spain.svg',
    de: 'bundles/treedomnewfrontend/images/flags/flags_germany.svg',
  }

  $scope.openMobileMenuModal = function (_menu) {
    MenuMobile.openMobileMenuModal(_menu)
    $timeout(function() {
      $scope.$apply()
    })
  }

  $scope.goToSettings = function () {
    $state.go('settings')
  }

  $scope.menuIsOpen = function (_menu) {
    return $state.params.tab === _menu
    // return MenuMobile.menuIsOpen(_menu)
  }

  // Open modal with notifications
  $scope.openNotificationsModal = function () {
    Modal.open({ templateUrl: 'modalNotifications.html' })
  }

  /**
  * Apre la modale di login.
  */
  $scope.openModalLogin = function () {
    Modal.open({ templateUrl: 'login.html', easyClose: false, fitcontent: true })
  }

  this.logout = function () {
    $rootScope.$broadcast('UserLoggedBroadcast', false)
  }

  this.edit = function () {
    // var state = $rootScope.userdata.info.usertype === 'Business'
    //   ? 'edit'
    //   : 'edit'

    $state.go('settings')
  }

  // NOTE: preso fa footer controller
  $scope.routesThatExcludeFooter = [
    'checkout',
    'checkout.business',
    'checkout.payment',
    'checkout.planting',
    'checkout.gift',
    'subscription',
    'subscription.subscribe',
  ]

  $scope.footerState = $state.current.name
  $scope.hideFooter = $scope.routesThatExcludeFooter.includes($scope.footerState)

  $rootScope.$on('$stateChangeSuccess', function (_event, toState, _toParams, _fromState, _fromParams) {
    $scope.footerState = toState.name
    $scope.hideFooter = $scope.routesThatExcludeFooter.includes($scope.footerState)
  })

  // NOTE: cose prese da valutare se servono
  this.myProfile = function () {
    var stateToGo = $rootScope.userdata.info.usertype === 'Business' ? 'organization' : 'user'
    $state.go(stateToGo, { slug: $rootScope.userdata.info.slug })
  }

  this.stopImpersonate = function () {
    window.location.href = window.location.href + '?_switch_user=_exit'
  }

  this.canImpersonate = function () {
    return $state.current.name.indexOf('user') >= 0 || $state.current.name.indexOf('organization') >= 0
  }

  this.startImpersonate = function (userSlug) {
    window.location.href = $rootScope.baseHtml5Url + $rootScope.ln + '/impersonate/' + userSlug
  }

  this.logout = function () {
    $rootScope.$broadcast('UserLoggedBroadcast', false)
    $rootScope.logout()
  }

  this.edit = function () {
    // var state = $rootScope.userdata.info.usertype === 'Business'
    //   ? 'edit'
    //   : 'edit'

    $state.go('settings')
  }

  $scope.openCouponModal = function () {
    MenuMobile.forceClose()
    Modal.open({ templateUrl: 'modalRedeemCoupon.html', id: 'modalRedeemCoupon', assignBackgroundClass: 'treedomModalRedeemBackground', small: false, easyClose: false })
  }

  $scope.changeLanguage = function (lng) {
    if (lng !== ipCookie('hl')) {
      var url = Routing.route('treedom_user_switch_ln')
      var form = $('<form action="' + url + '" method="post">' +
      '<input type="hidden" name="pathname" value="' + window.location.pathname + '" />' +
      '<input type="hidden" name="ln" value="' + lng + '" />' +
      '</form>')
      $('body').append(form)
      form.submit()
    }
  }
}])
