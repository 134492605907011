const BROWSERS = {
  messenger: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
  facebook: /\bFB[\w_]+\//,
  twitter: /\bTwitter/i,
  line: /\bLine\//i,
  wechat: /\bMicroMessenger\//i,
  puffin: /\bPuffin/i,
  miui: /\bMiuiBrowser\//i,
  instagram: /\bInstagram/i,
  chrome: /\bCrMo\b|CriOS|Android.*Chrome\/[.0-9]* (Mobile)?/, // FIXME: regex mobile only
  safari: /Version.*Mobile.*Safari|Safari.*Mobile|MobileSafari/,
  ie: /IEMobile|MSIEMobile/,
  firefox: /fennec|firefox.*maemo|(Mobile|Tablet).*Firefox|Firefox.*Mobile|FxiOS/,
}

export class UserAgent {
  private static get ua() {
    return navigator.userAgent
      // FIXME: check possible values. browser method not working
      // || navigator.vendor
      // || window.opera
      || ''
  }

  static get browser(): string {
    return Object.keys(BROWSERS).find(browser => {
      return BROWSERS[browser as keyof typeof BROWSERS].test(this.ua)
    }) || 'other'
  }

  static get isMobile(): boolean {
    return /(iPad|iPhone|Android|Mobile)/i.test(this.ua) || false;
  }

  static get isDesktop(): boolean {
    return !this.isMobile;
  }

  static get isInApp(): boolean {
    const rules = [
      'WebView',
      '(iPhone|iPod|iPad)(?!.*Safari\/)',
      'Android.*(wv|\.0\.0\.0)',
    ];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    return Boolean(this.ua.match(regex));
  }
}
