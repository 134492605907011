import template from './ecommerce-picker-template.html'
import controller from './ecommerce-picker-controller'

const bindings = {
  ecommerces: '@',
  title: '@',
  subTitle: '@',
  onItemClick: '&',
  onQuantityChange: '&',
  listData: '='
}

angular.module('ngApp')
  .component('ecommercePicker', { template, controller, bindings })
