import tagManagerService from '@f/services/TagManager';
import {
    validateGiftStart,
    validateGiftCanceled,
    validateGiftShared,
    validateOpenGiftFlow
} from '@f/validation/datalayer'
import {
    IDLGiftedTree,
    IDLGiftPosition,
    IGiftType,
  } from '@f/validation/types/dataLayer'

export function DataEventOpenGiftFlow(
  position: IDLGiftPosition,
) {
    tagManagerService.push({
        event: 'openGiftFlow',
        position,
    }, validateOpenGiftFlow)
}

export function DataEventGiftStart(
    position: IDLGiftPosition,
    trees: Array<IDLGiftedTree> = [],
    idGift: string | null = null,
    giftCode: string | null = null,
    cartId: number | null = null,
    ) {
    tagManagerService.push({
        event: 'startGift',
        trees,
        idGift,
        giftCode,
        cartId,
        position,
        numberoftree: trees.length
    }, validateGiftStart)
}

export function DataEventGiftSend(
    position: IDLGiftPosition,
    trees: Array<IDLGiftedTree> = [],
    idGift: string | null = null,
    giftCode: string | null = null,
    giftType: IGiftType
    ) {
    tagManagerService.push({
        event: 'sendGift',
        trees,
        idGift,
        giftCode,
        position,
        numberoftree: trees.length,
        giftType
    }, validateGiftStart)
}


export function DataEventGiftCanceled(
    idGift: string | null = null,
    giftCode: string | null = null
    ) {
    tagManagerService.push({
        event: 'canceledGift',
        idGift,
        giftCode,
    }, validateGiftCanceled)
}

export function DataEventGiftShared(
    idGift: string | null = null,
    giftCode: string | null = null
    ) {
    tagManagerService.push({
        event: 'giftLinkShared',
        idGift,
        giftCode,
    }, validateGiftShared)
}