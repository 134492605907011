angular.module('gameService', [])
.factory('Game', ['$http', '$q', '$routing', 'Routing', '$rootScope', function ($http, $q, $routing, Routing, $rootScope) {
  var routing = Routing
  var routeJs = function (route, params) {
    var url = routing.route(route, params)

    // funzione per pulire l'url dal' bug di fos_user che mantiene i parameters nelle varie chiamate
    if (url.indexOf('?') > -1) {
      url = url.substring(0, url.indexOf('?'))
    }
    return url
  }

  var gameFactory = {}

  gameFactory.getTopPlanter = function () {
    return $http.get(routeJs('treedom_GET_ranking_planter'))
    .then(function(response) {
      return response.data || response
    })
  }

  gameFactory.getTopPlanterPerMonth = function (year, month) {
    return $http.get(routeJs('treedom_GET_ranking_planter_month', {month: month, year: year}))
    .then(function(response) {
      return response.data || response
    })
  }

  gameFactory.getLastRegistered = function (type, offset, limit) {
    return $http.get(routeJs('treedom_last_users', {type: type, limit: limit, offset: offset}))
    .then(function(response) {
      return response.data || response
    })
  }

  gameFactory.totalNums = function () {
    return $http.get(routeJs('treedom_GET_total_numbers'))
    .then(function(response) {
      return response.data || response
    })
  }

  gameFactory.getSoldSpecies = function () {
    return $http.get(routeJs('treedom_get_sold_species'))
    .then(function(response) {
      return response.data || response
    })
  }

  return gameFactory
}])
