angular.module('badgeService', [])
.factory('Badge', ['$http', 'Routing', function ($http, Routing) {
  var routing = Routing

  var routeJs = function (route, params) {
    var url = routing.route(route, params)

    // Funzione per pulire l'url dal' bug di fos_user che mantiene i parameters nelle varie chiamate
    if (url.indexOf('?') > -1) url = url.substring(0, url.indexOf('?'))

    return url
  }

  var badgeFactory = {}

  badgeFactory.all = function () {
    return $http.get(routeJs('treedom_get_badge'))
    .then(function(response) {
      return response.data || response
    })
  }

  badgeFactory.getUserBadges = function (userId) {
    return $http.get(routeJs('treedom_get_user_badge', {userId: userId}))
    .then(function(response) {
      return response.data || response
    })
  }

  badgeFactory.getNewUserBadge = function (userId) {
    return $http.get(routeJs('treedom_get_new_user_badge', {userId: userId}))
    .then(function(response) {
      return response.data || response
    })
  }

  badgeFactory.postNotifiedBadges = function (badges) {
    return $http.post(routeJs('treedom_post_notified_badges'), badges)
    .then(function(response) {
      return response.data || response
    })
  }

  return badgeFactory
}])
