











import {defineComponent, ref} from '@vue/composition-api';
import {getStateService} from "@f/services/Angular";
import {NavbarBusinessMenuItem} from "@f/components/Navbar/partials/BusinessMenu/types";

export default defineComponent({
  name: 'BusinessMenu',
  components: {
    BusinessPlantMenu: () => import('./BusinessPlantMenu.vue'),
    MenuPanel: () => import('./MenuPanel.vue'),
    Solutions: () => import('./Solutions.vue'),
  },
  setup() {
    const menu: NavbarBusinessMenuItem = {
        items: [
          {
            label: 'nav.business.solutions',
            customComponentName: 'Solutions'
          },
          {
            label: 'nav.business.plant',
            customComponentName: 'BusinessPlantMenu'
          },
          {
            label: 'nav.business.services',
            items: [
              {
                label: 'nav.business.services.reduction',
                items: [
                  {
                    label: 'nav.business.services.reduction.onlineCalculator',
                    state: 'business.landing',
                    stateSlug: 'co2-calculator'
                  },
                  {
                    label: 'nav.business.services.reduction.footprint',
                    state: 'business.landing',
                    stateSlug: 'carbon-footprint-calculation'
                  },
                  {
                    label: 'nav.business.services.reduction.strategy',
                    state: 'business.landing',
                    stateSlug: 'reduction-strategy'
                  },
                  {
                    label: 'nav.business.services.reduction.sustainableEvents',
                    state: 'business.landing',
                    stateSlug: 'sustainable-events'
                  },
                ]
              },
              {
                label: 'nav.business.services.compensation',
                items: [
                  {
                    label: 'nav.business.services.compensation.carbonCredit',
                    state: 'business.landing',
                    stateSlug: 'offsetting-emissions'
                  },
                  {
                    label: 'nav.business.services.compensation.plantTrees',
                    state: 'business.landing',
                    stateSlug: 'business-plant-a-tree'
                  },
                  {
                    label: 'nav.business.services.compensation.csr',
                    state: 'business.landing',
                    stateSlug: 'local-projects'
                  }
                ]
              },
              {
                label: 'nav.business.services.involvement',
                items: [
                  {
                    label: 'nav.business.services.involvement.teamBuilding',
                    state: 'business.landing',
                    stateSlug: 'corporate-team-building'
                  },
                  {
                    label: 'nav.business.services.involvement.giftTree',
                    state: 'business.landing',
                    stateSlug: 'business-gift-tree'
                  },
                  {
                    label: 'nav.business.services.involvement.formation',
                    state: 'business.landing',
                    stateSlug: 'corporate-training'
                  },
                  {
                    label: 'nav.business.services.involvement.coffeeLucero',
                    state: 'business.landing',
                    stateSlug: 'coffee-dona-lucero'
                  }
                ]
              },
              {
                label: 'nav.business.services.reporting',
                items: [
                  {
                    label: 'nav.business.services.reporting.carbonCredit',
                    state: 'business.landing',
                    stateSlug: 'sustainability-report'
                  },
                  {
                    label: 'nav.business.services.reporting.plantTrees',
                    state: 'business.landing',
                    stateSlug: 'business-communication-kit'
                  },
                  {
                    label: 'nav.business.services.reporting.csr',
                    state: 'business.landing',
                    stateSlug: 'sustainable-certifications'
                  }
                ]
              },
            ]
          },

        ]
    }

    const stateService = getStateService();
    const secondLayerMenu = ref<NavbarBusinessMenuItem[] | undefined>(menu.items?.[0].items);
    const thirdLayerMenu = ref<NavbarBusinessMenuItem[] | undefined>(menu.items?.[0].items?.[0].items);
    const layerContent = ref<string | undefined>(menu?.items?.[0].customComponentName);

    const activateFirstColumn = ({ state, customComponentName, items }: NavbarBusinessMenuItem) => {
      secondLayerMenu.value = items ?? [];
      const secondLayerEntry = items?.[0]
      secondLayerEntry && activateSecondColumn(secondLayerEntry );

      if (stateService && state) {
        stateService.go(state);
      }
      layerContent.value = customComponentName;
    };

    const activateSecondColumn = ({ state, customComponentName, items }: NavbarBusinessMenuItem) => {
      thirdLayerMenu.value = items ?? [];
      layerContent.value = customComponentName;
      const thirdLayerEntry = items?.[0]
      thirdLayerEntry && activateThirdColumn(thirdLayerEntry );
      if (stateService  && state) {
        return stateService.go(state);
      }
    };


    const activateThirdColumn = ({ state, customComponentName, items }: NavbarBusinessMenuItem) => {
      layerContent.value = customComponentName;
      if (stateService  && state) {
        return stateService.go(state);
      }
    };


    return {
      menu,
      secondLayerMenu,
      thirdLayerMenu,
      layerContent,
      activateFirstColumn,
      activateSecondColumn,
      activateThirdColumn
    };
  }
});
