
angular.module( "treedomCarousel", [])
.directive('myRepeatDirective', function() {
  return function(scope, element, attrs) {
    angular.element(element).css('color','blue');
  };
})
  .directive('treedomCarousel', ['$window','$compile', '$rootScope', '$timeout', '$state', '$swipe', function($window, $compile,$rootScope,$timeout, $state, $swipe) {
    return {
      restrict: 'AE', // come puo venire definita es E:element <angular-tip>/  A:attribute <div angular-tip> / C:classe etc...
        replace: false, // se viene rimpiazzato con il template sotto
        link: function(scope, elem, attrs) {
          

          var options = {
            height: attrs.height || '100',
          }


          $swipe.bind(elem, {
                    'start': function(coords) {
                       updateScrollPos(coords.x, 'start')
                    },
                    'move': function(coords) {
                     updateScrollPos(coords.x, 'move');
                     
                    },
                    'end': function(coords){
                       updateScrollPos(10, 'end')
                    },
                });
          
          // gli do lo stile di base          
          elem.css('width', '100%');
          elem.css('height', options.height+'px');                          
          elem.css('overflow', 'hidden');                              
          
          
          containerDiv =  angular.element(document.getElementById("carouselContainer"));
          containerDiv.css('height', options.height+'px');
          containerDiv.css('width','2000000px');        
          containerDiv.css('overflow','hidden');                  
          containerDiv.css('margin-right','-1000000px');                            
          widthContainer = containerDiv.parent().outerWidth();


          var start = 0;
          var updateScrollPos = function(x, type) {

            widthContainer = containerDiv.parent().outerWidth();
              

            var items = $window.document.getElementsByClassName('carousel-item').length;

            var item =  angular.element($window.document.getElementsByClassName('carousel-item')[0]);
            var item2 =  angular.element($window.document.getElementsByClassName('carousel-item')[1]);
            var width = item.outerWidth();
            var margin = ((item2.offset().left - item.offset().left)-width);
            var halfMargin = margin/2;
            
            var totalMargin = margin*items;
            
            var maxMargin = ((items)*width)-widthContainer+width+totalMargin-halfMargin;
            
            if (type == 'start') {
              actual = 1*containerDiv.css('margin-left').replace('px','');
              start =  1*x-actual;
            } else if (type == 'move') {
              var move = -1*(start - 1*x);
            }

            if (move > 0) {
              move = 0;
            } else if (move < -1*maxMargin) {
              
              move = -1*maxMargin;
            }

            
            containerDiv.css('margin-left', move+'px');
            

          }


      }
    }
}]);    
