angular.module('eventService', [])
.factory('Event', ['$http', 'Routing', '$rootScope', 'ipCookie', 'angularGridInstance', '$timeout', function ($http, Routing, $rootScope, ipCookie, angularGridInstance, $timeout) {
  var eventFactory = {}
  var routing = Routing

  var routeJs = function (route, params) {
    var url = routing.route(route, params)
    if (url.indexOf('?') > -1) {
      url = url.substring(0, url.indexOf('?'))
    }
    return url
  }

  eventFactory.getUsers = function (id, offset) {
    if (!id) return
    offset = offset || 0
    return $http.get(routeJs('treedom_event_users', { eventId: id, offset: offset }))
    .then(function(res) {
      $timeout(function() {
        if (angularGridInstance.eventGallery) angularGridInstance.eventGallery.refresh()
      })
      res = res.data || res
      return res
    })
    .catch(function(err) {
      throw err.data || err
    })
  }

  eventFactory.getCreateInfo = function (slug) {
    var query = slug ? '?eventSlug=' + slug : ''
    return $http.get(routeJs('treedom_event_private_start') + query)
    .then(function(res) {

      res = res.data || res
      return res
    })
    .catch(function(err) {

      throw err.data || err
    })
  }

  eventFactory.saveInfo = function (eventData) {
    return $http.post(routeJs('treedom_event_private_save'), eventData)
    .then(function(res) {

      res = res.data || res
      return res
    })
    .catch(function(err) {

      throw err.data || err
    })
  }

  eventFactory.publishEvent = function (id) {
    return $http.post(routeJs('treedom_event_private_publish'), { id: id })
    .then(function(res) {

      res = res.data || res
      return res
    })
    .catch(function(err) {

      throw err.data || err
    })
  }

  eventFactory.deleteDraftEvent = function (id) {
    return $http.post(routeJs('treedom_event_private_delete'), { id: id })
    .then(function(res) {

      res = res.data || res
      return res
    })
    .catch(function(err) {

      throw err.data || err
    })
  }

  eventFactory.addGalleryImage = function (eventId, imageId) {
    return $http.post(routeJs('treedom_event_private_add_gallery_image'), {
      event: eventId,
      image: imageId
    })
    .then(function(res) {

      res = res.data || res
      return res
    })
    .catch(function(res) {

      return res
    })
  }

  eventFactory.removeGalleryImage = function (eventId, imageId) {
    return $http.post(routeJs('treedom_event_private_remove_gallery_image'), {
      event: eventId,
      image: imageId
    })
    .then(function(res) {

      res = res.data || res
      return res
    })
    .catch(function(res) {

      return res
    })
  }

  eventFactory.openForests = function (eventSlug) {
    var path = routeJs('treedom_event_open_forests',{ slug: eventSlug})
    return $http.get(path)
      .then(function (res) {
        return res.data || res
      })
      .catch(function (err) {
        return err
      })
  }

  eventFactory.redeemOpenForests = function (eventSlug, treeId) {
    var path = routeJs('treedom_event_redeem_open_forests', {
      slug: eventSlug,
      productID: treeId
    })
    return $http.get(path)
      .then(function (res) {
        return res.data || res
      })
      .catch(function (err) {
        return err
      })
  }

  eventFactory.getInfoOpenForest = function (eventSlug) {
    var path = routeJs('treedom_event_open_forests_info',{ slug: eventSlug})
    return $http.get(path)
      .then(function (res) {
        return res.data || res
      })
      .catch(function (err) {
        return err
      })
  }

  return eventFactory
}])
