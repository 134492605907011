import chunk from 'lodash/chunk'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
.controller('communityController', ['$scope', '$state', '$rootScope', 'Game', 'User', 'Modal', '$filter', '$timeout', '$location', function ($scope, $state, $rootScope, Game, User, Modal, $filter, $timeout, $location) {
  // Variabili del controller
  $scope.twitterMessage = $filter('trans')('seo.community.facebookTitle')
  $scope.userInView = $rootScope.user
  $scope.topPlanter = $rootScope.getTopPlanter
  $scope.businessUsers = []
  $scope.businessUsersActive = 0
  $scope.originators = [
    {
      title: 'Ernest Kénéké',
      country: 'Kenya',
      picture: '1'
    },
    {
      title: 'Soileymane Mane',
      country: 'Senegal',
      picture: '2'
    },
    {
      title: 'Velardez Francisco',
      country: 'Camerun',
      picture: '3'
    },
    {
      title: 'Younousse Diémé',
      country: 'Kenya',
      picture: '4'
    },
    {
      title: 'Rosalita Afvk',
      country: 'Malawi',
      picture: '5'
    },
    {
      title: 'Wip Afk',
      country: 'Malawi',
      picture: '6'
    }
  ]

  /**
   * Se l'url continene un hash valido, scrolla alla sezione richiesta
   */
  // $scope.checkHash = function () {
  //   var arrayOfGetVariables = Object.keys($location.$$search)
  //   if (arrayOfGetVariables.length) {
  //     var firstVariable = arrayOfGetVariables[0]
  //     const variables = ['users', 'business', 'projects', 'trees']
  //     if (variables.includes(firstVariable)) {
  //       $scope.goToSection(firstVariable)
  //     }
  //   }
  // }

  // $timeout(function () {
  //   $scope.checkHash()
  // }, 2000)

  /**
   * I 5 paesi in cui treedom pianta alberi
   */
   $scope.topProjects = [ ...$rootScope.projects ]
   .sort((a, b) => Number(b.totalTrees) - Number(a.totalTrees))
   .slice(0, 5)

  $scope.projects = [ ...$rootScope.projects ]
  $scope.numProjects = $scope.projects.length

  /**
   * Se non ci sono i numeri (es: utenti totali, aziende totali, alberti piantati)
   * allora chiedili al server!
   */
  if (typeof $rootScope.totalNums === 'undefined') {
    // questo provare a mettere sempre #TODO
    Game.totalNums()
    .then(function(res) {
      var data = res.data || res
      $rootScope.totalNums = data
    })
  }

  /**
   * Frecce slider business
   */
  $scope.businessUsersPrev = function () {
    if ($scope.businessUsersActive <= 0) return
    $scope.businessUsersActive--
  }

  $scope.businessUsersNext = function () {
    if ($scope.businessUsersActive >= $scope.businessUsers.length - 1) return
    $scope.businessUsersActive++
  }

  /**
   * Watcher sul click delle frecce
   */
  $scope.showBusinessArrowPrev = false
  $scope.showBusinessArrowNext = true
  $scope.$watch(function () {
    return $scope.businessUsersActive
  }, function (newVal) {
    $scope.showBusinessArrowPrev = newVal > 0
    $scope.showBusinessArrowNext = !isUndefined($scope.businessUsers)
      ? newVal < $scope.businessUsers.length - 1
      : true
  })

  /**
   * Trova la mia posizione tra i TopPlanter
   */
  if ($scope.topPlanter) {
    $scope.topPlanter.map(function (user, index) {
      if (user.slug === $scope.userInView) {
        $scope.minePositionTopPlanter = index
      }
    })
  }

  /**
   * Funzione che apre il menù di login/registrazione
   */
  $scope.openMenuLogin = function () {
    $rootScope.slideMenuType = 'login'
    $rootScope.login = true
    $rootScope.loginType = 'sign-up'
  }

  /**
   * Funzione che apre la modale di signup.
   */
  $scope.signUp = function () {
    $rootScope.loginType = 'sign-up'
    Modal.open({templateUrl: 'login.html', easyClose: false, fitcontent: true})
  }

  /**
   * Se non ci sono i TopPlanter, trovali!
   */
  if (typeof $rootScope.getTopPlanter === 'undefined') {
    Game.getTopPlanter()
    .then(function(res) {
      var data = res.data || res
      $rootScope.getTopPlanter = data
      $scope.topPlanter = $rootScope.getTopPlanter
      $scope.topPlanter.map(function (user, index) {
        if (user.slug === $scope.userInView) {
          $scope.minePositionTopPlanter = index
        }
      })
    })
  }

  /**
   * Inivita un amico con facebook
   */
  $scope.inviteFacebook = function () {
    var link = $rootScope.baseHtml5Url + 'community'
    $scope.error = false
    window.FB.ui({
      method: 'share',
      href: link
    }, function (response) {
      if (response && !response.error_code) {
        // response = {success: true}
      } else {
        $scope.error = true
      }
    })
  }

  /**
   * Funzione per aprire il search al click su un bottone
   * INFO: Aggiungere la classe 'openSearch' ad un elemento quando si vuole
   * bloccare, al click di questo, la chiusura della barra di ricerca
   * @param: array di default => ['emissions', 'species', 'privates', 'businesses', 'events']
   */
  $scope.openSearch = function (array) {
    $rootScope.$broadcast('searchBroadcast', 'open')
    $rootScope.$broadcast('selectCategoriesToSearch', array)
  }

  /**
   * Funzione che, dato uno slug, se esiste l'utente di reindirizza alla sua pagina
   * altrimenti apre la barra di ricerca con filtro ['privates']
   */
  $scope.goToUserPage = function (slug) {
    if (slug !== 'undefined') {
      $state.go('user', {slug: slug})
    } else {
      $scope.openSearch(['privates'])
    }
  }

  /**
   * Vai alla pagina di un progetto
   */
  $scope.goToProject = function (slug) {
    if (!slug || !isString(slug)) return
    var countrySlug = slug.toLowerCase()
    $state.go('project', {slug: countrySlug})
  }

  /**
   * Trova le specie più vendute
   */
  if (isUndefined($scope.species)) {
    Game.getSoldSpecies()
    .then(function(res) {
      var species = res.data || res
      $scope.species = species
    })
  }

  /**
   * Pulsanti per scorrere la classifica delle specie più piantate
   */
  $scope.counterSlider = 5
  $scope.slideSpeciesRank = function (type) {
    var element = document.getElementById('simulateScroll')
    var oldTopValue = parseInt(element.style.top) || 0

    if (type === 'up') {
      if ($scope.counterSlider >= 10) return
      $scope.counterSlider++
      element.style.top = (oldTopValue - 60) + 'px'
    }

    if (type === 'down') {
      if ($scope.counterSlider <= 5) return
      $scope.counterSlider--
      element.style.top = (oldTopValue + 60) + 'px'
    }
  }

  $scope.goToSection = function (sectionId) {
    if (!sectionId) return
    var element = document.getElementById(sectionId)

    function animate () {
      var animateId = window.requestAnimationFrame(animate)
      document.body.scrollTop += 0.05 * element.offsetTop

      if (document.body.scrollTop >= element.offsetTop) {
        document.body.scrollTop = element.offsetTop
        window.cancelAnimationFrame(animateId)
      }
    }

    window.requestAnimationFrame(animate)
  }
}])
