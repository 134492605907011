import Player from '@vimeo/player'

const play = function(ev) {

}

angular.module('ngApp')
.directive('vimeoPlayer', ['$timeout', function($timeout) {
  let innerScope = {}

  const play = function(ev) {
    ev.preventDefault()
    if (innerScope.isHidden) {
      if (innerScope.iframe) $(innerScope.iframe).css('display', 'block')
      if (innerScope.cover) $(innerScope.cover).css('display', 'none')
      innerScope.isHidden = false
    }
    innerScope.player.play()
  }

  const ended = function() {
    if (innerScope.isHidden === false) {
      if (innerScope.iframe) $(innerScope.iframe).css('display', 'none')
      if (innerScope.cover) $(innerScope.cover).css('display', 'block')
      innerScope.isHidden = true
    }
  }


  return {
    type: 'A',
    link: function(scope, elements, attrs, modelCtrl) {
      innerScope = scope
      elements.each(function(idx, el) {
        $timeout(function() {
          scope.iframe = el.querySelector('#player1');
          if (scope.iframe) {
            scope.player = new Player(scope.iframe)
            scope.cover = el.querySelector('#sectionIndex')
            scope.isHidden = $(scope.iframe).css('display') === 'none'
            if (scope.isHidden) {
              scope.player.on('ended', ended)
            }
            scope.playButton = el.querySelector('#play')
            if (scope.playButton) {
              scope.playButton.addEventListener('click', play)
            }
          }
        })
      })
    }
  }
}])
