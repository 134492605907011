




















































import { Component, Mixins } from 'vue-property-decorator'
import TreeDiaryItem from '../mixins/TreeDiaryItem'

@Component({
  name: 'TreeDiaryName',
})
export default class TreeDiaryName extends Mixins(TreeDiaryItem) {
  // Computed
  get text(): string {
    return this.translate('pageTree.diary.name.text', {
      user: this.post.user.firstName,
      userSlug: this.post.user.slug,
      treename: this.tree.name,
      link: this.parseState(
        this.post.user.userType === 'Private' ? 'user' : 'organization',
        { slug: this.post.user.slug }
      ),
    })
  }
}
