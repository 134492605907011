











































































import Treecon from '@f/components/Treecons/Treecon.vue'
import StatefullButton from '@f/components/StatefullButton.vue'
import ShareNative from '@f/components/ShareNative.vue'
import { defineComponent } from '@vue/composition-api'
import useFormat from '@f/composables/useFormat'

export default defineComponent({
  name: 'ForestHeader',
  components: {
    Treecon,
    StatefullButton,
    ShareNative,
  },
  props: {
    title: {
      type: String,
      defualt: '',
    },
    countTrees: {
      type: Number,
      default: 0,
    },
    countUsers: {
      type: Number,
      default: 0,
    },
    isForestPublished: {
      type: Boolean,
      default: false,
    },
    isMine: {
      type: Boolean,
      default: false,
    },
    isCtaVisible: {
      type: Boolean,
      default: false,
    },
    ctaLabel: {
      type: String,
      default: '',
    },
    ctaAction: {
      type: String,
      default: 'none',
    },
    forestName: {
      type: String,
      default: '',
    },
    hide_people: {
      type: Boolean,
      default: false,
    },
    avatarImageUrl: {
      type: String,
      default: '',
    },
    coverImageUrl: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { formatLocalNumbers } = useFormat();

    return {
      formatLocalNumbers,
    }
  },
})
