import ajv from '@f/validation/ajv';
import * as ProductSchemas from '@f/validation/schema/datalayer/product';
import { INotifyMe, IImpressions, IProductPackageTreeInfo, IProductPageView, IProductSetGift } from '@f/validation/types/dataLayer';


export const validateProductPage = ajv.compile<IProductPageView>(ProductSchemas.ProductPageViewSchema)
export const validateProductSetGift = ajv.compile<IProductSetGift>(ProductSchemas.ProductSetGiftSchema)
export const validateProductPackageTreeInfo = ajv.compile<IProductPackageTreeInfo>(ProductSchemas.ProductPackageTreeInfo)
export const validateImpressions = ajv.compile<IImpressions>(ProductSchemas.ImpressionsSchema)
export const validateNotifyMe = ajv.compile<INotifyMe>(ProductSchemas.NotifySchema)
