





























import { Mixins, Component, Prop } from 'vue-property-decorator'
import InputLikeComponentMixin from '@f/mixins/InputLikeComponent'
import PasswordInput from '@f/components/Form/InputTypes/PasswordInput.vue'
import TextAreaInput from '@f/components/Form/InputTypes/TextAreaInput.vue'

const INPUT_COMPONENT_TYPES_MAP = {
  'password': 'PasswordInput',
  'textarea': 'TextAreaInput',
}

@Component({
  name: 'BaseInput',
  components: { PasswordInput, TextAreaInput, },
  inheritAttrs: false,
})
export default class BaseInput extends Mixins(InputLikeComponentMixin) {
  /*--- PROPS ---------*/
  @Prop({ required: true }) readonly value!: string | number
  @Prop({ type: String, default: null }) readonly error!: string | null
  @Prop({ type: Boolean, default: false }) readonly hideValidationError!: boolean
  @Prop({ type: String, default: null }) readonly label!: string | null

  /*--- COMPUTED ------*/
  get inputComponent() {
    return this.$attrs.type && this.$attrs.type in INPUT_COMPONENT_TYPES_MAP
      ? INPUT_COMPONENT_TYPES_MAP[this.$attrs.type as keyof typeof INPUT_COMPONENT_TYPES_MAP]
      : ''
  }
}
