import {TrMapPoint} from '@f/@types/graphql'
import {InjectionKey, Ref} from '@vue/composition-api'
import {Map as Mapbox} from 'mapbox-gl'
import {mapBox} from "@f/configs/app";

export interface MapMarkers {
    lat: number
    lng: number
    el: HTMLElement
}

export interface MapBounds {
    zoom: number
    NE: TrMapPoint
    SW: TrMapPoint
}

export interface FlyToOptions {
    lat: number
    lng: number
    zoom?: number
    maxDuration?: number
    animationType?: 'fly' | 'jump'
    preserveZoom?: boolean,
}

export const getMapBoxStaticPreview = (
    markerUrl: string,
    coords: {
        lat: number
        lng: number
    },
    dimensions: {
        width: number
        height: number
    }
) => {
    const {lng, lat} = coords
    const {width, height} = dimensions
    const additionalMarkerLat = 16 // Approx 1/6 of image height. Needed to center marker vertically
    return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/url-${encodeURIComponent(
        markerUrl
    )}(${lng},${
        lat + additionalMarkerLat
    })/${lng},${lat},0/${width}x${height}@2x?access_token=${
        mapBox.accessToken
    }&logo=false&attribution=false`
}
export const MAP_INSTANCE: InjectionKey<Ref<Mapbox>> = Symbol('map-instance')