














import {defineComponent, ref} from '@vue/composition-api'
import Icon from '@f/components/Icon.vue'
import useRoutes from '@f/composables/useRoutes'
import {DataEventGiftStart} from "@f/services/TagManager/events/gift";
import {isLoggedInAs} from "@f/services/Auth";


export default defineComponent({
  name: 'UserGiftUpselling',
  components: {Icon},
  setup: () => {
    const {currentParams, go} = useRoutes()
    const show = ref(() => {
      if (currentParams.value && currentParams.value['redeemed']) {
        window.localStorage.setItem('showUpsellingBanner', 'true')
        if (window.history.replaceState) {
          window.history.replaceState({}, '', window.location.toString().replace(window.location.search, ''))
        }
      }
      return isLoggedInAs(currentParams.value?.slug) && window.localStorage.getItem('showUpsellingBanner') === 'true'
    })

    const onGiftCtaClick = () => {
      window.localStorage.setItem('showUpsellingBanner', 'false')
      DataEventGiftStart('treedom-user-profile-banner')
      go('species')
    }

    const close = () => {
      window.localStorage.setItem('showUpsellingBanner', 'false')
      show.value = () => false
    }

    return {
      show,
      onGiftCtaClick,
      close
    }
  }
})
