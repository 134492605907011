import Cookies from 'js-cookie'

export type RemoveLegacyAuthCookiesOptions = {
  cookieName: string
}
const removeLegacyAuthCookies = (options: RemoveLegacyAuthCookiesOptions) => {
  Cookies.remove(options.cookieName, {
    domain: location.href.replace('www', ''),
    expires: -99999,
  })
}

export default removeLegacyAuthCookies