
























import { TrEventCountrySummary } from '@/public_src/@types/graphql'
import { FOREST_COUNTRIES } from '@f/graphql/queries'
import { defineComponent } from '@vue/composition-api'
import { getForestTreesCountFormatted } from '@f/services/Forest'

export default defineComponent({
  name: 'ForestCountryBoxes',
  props: {
    forestId: {
      type: Number,
    },
  },
  setup() {
    function treesCountFormatted(count: number): string {
      return getForestTreesCountFormatted(count)
    }
    
    return {
      treesCountFormatted
    }
  },
  data(): {
    forestCountries: TrEventCountrySummary[]
  } {
    return {
      forestCountries: [],
    }
  },
  created() {
    this.$apollo.addSmartQuery('forestCountries', {
      query: FOREST_COUNTRIES,
      variables() {
        return { id: this.forestId }
      },
      skip() {
        return !this.forestId
      },
      update: (data: { Event_countries: TrEventCountrySummary[] }) => {
        return data.Event_countries
      },
    })
  },
})
