/* @ngInject */
export default function ecommercePickerController(Ecommerce) {

  this.products = []
  this.loading = false
  this.wrapClasses = []
  this.listData = null

  this.$onInit = () => {
    if (this.ecommerces && this.ecommerces.length) {
      this.loading = true
      let ecommercesList = this.wrapClasses = this.ecommerces.split(',')
      Ecommerce.getEcommerces(ecommercesList).then(ecommercesResponse => {
        ecommercesList.forEach(ecommerceName => {
          this.products = this.products.concat(ecommercesResponse[ecommerceName])
        });
        this.loading = false
      });
    }
  }

  this.getQuantity = (id) => {
    return this.listData && this.listData[id] && this.listData[id].qt
  }

  this.itemClickCallback = (product) => {
    this.onItemClick({ product })
  }

  this.quantityChangeCallback = (product, qt) => {
    this.onQuantityChange({ product, qt })
  }
}
