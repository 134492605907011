

















import { defineComponent, inject } from '@vue/composition-api'
import useMarker from '@f/components/Map/Markers/useMarker'
import icon from '@f/components/Map/graphics/tree-cluster-pin.png'
import { MAP_INSTANCE } from '@f/services/Map'

export default defineComponent({
  name: 'TreeClusterPin',
  props: {
    count: {
      type: Number,
      required: true,
    },
    lat: {
      type: Number,
      required: true,
    },
    lng: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const map = inject(MAP_INSTANCE)
    const root = useMarker(() => [props.lng, props.lat], map?.value!)
    const onMarkerClick = () => {
      emit("marker-click")
    }
    return {
      root,
      icon,
      onMarkerClick,
    }
  },
})
