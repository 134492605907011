




















import { defineComponent } from '@vue/composition-api'
import Modal from '@f/components/Modal.vue'
import ReadMoreModal from '@f/components/Forest/ReadMoreModal.vue'

export default defineComponent({
  name: 'ForestDescription',
  components: {
    Modal,
    ReadMoreModal,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  data(): {
    showDescriptionReadMore: boolean
  } {
    return {
      showDescriptionReadMore: false,
    }
  },
  computed: {
    enableReadMore(): boolean {
      return this.description.length > 180;
    },
    shortDescription():string {
      return this.description.replace(/(<([^>]+)>)/gi, "");
    }
  }
})
