
import {CreateElement, VNode} from 'vue'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Glide, {
  Anchors,
  Autoplay,
  Breakpoints,
  ComponentInterface as GlideComponentInterface,
  Controls,
  GlideOptions,
  Swipe,
} from '@glidejs/glide/dist/glide.modular.esm'

interface GlideExtensions extends Record<string, GlideComponentInterface | void> {
  Anchors: typeof Anchors;
  Autoplay: typeof Autoplay;
  Breakpoints?: typeof Breakpoints;
  Controls: typeof Controls;
  Swipe: typeof Swipe;
}

@Component({
  name: 'BaseSlider'
})
export default class BaseSlider extends Vue {
  /*--- MODEL ---------*/

  /*--- PROPS ---------*/
  @Prop({type: String, default: "carousel"}) readonly type!: "carousel" | "slider"
  @Prop({type: Number, default: 1}) readonly perView!: number
  @Prop({type: [Boolean, String], default: false}) readonly arrows!: boolean | 'light' | 'dark'
  @Prop({type: [Boolean, String], default: false}) readonly bullets!: boolean | 'light' | 'dark'
  @Prop({type: Boolean, default: true}) readonly hoverpause!: boolean
  @Prop({type: Boolean, default: false}) readonly bound!: boolean
  @Prop({type: Number, default: 0}) readonly autoplay!: number
  @Prop({type: Number, default: 400}) readonly animationDuration!: number
  @Prop({type: Number, default: 0}) readonly peek!: number
  @Prop({type: Number, default: 0}) readonly gap!: number
  @Prop({type: Number, default: 0}) readonly startAt!: number
  @Prop({type: [Number, String], default: 0}) readonly focusAt!: number | 'center'
  @Prop({type: Object, default: null}) readonly breakpoints!: Record<number, number | GlideOptions> | null
  @Prop({type: String, default: ''}) readonly emptyMessage!: string
  @Prop({type: Boolean, default: false}) readonly disable!: boolean;

  @Prop({type: Number, default: 120}) readonly dragThreshold!: number | boolean
  @Prop({type: Number, default: 80}) readonly swipeThreshold!: number | boolean

  /*--- DATA ----------*/
  glide: Glide | null = null
  visibleSlides: number = 0
  activeIndex: number = -1
  windowSize: number = window.innerWidth
  requestFrameHandler: number | null = null
  updateSliderHandler: ReturnType<typeof setTimeout> | null = null
  updateSliderDelay: number = 1000

  /*--- COMPUTED ------*/
  get glideOptions(): GlideOptions {
    return {
      focusAt: this.focusAt,
      gap: this.gap,
      peek: this.peek,
      perView: this.perView,
      bound: this.bound,
      hoverpause: this.hoverpause,
      animationDuration: this.animationDuration,
      ...this.contextualOptions(),
      ...this.breakpointsOptions,
      dragThreshold: this.dragThreshold,
      swipeThreshold: this.swipeThreshold,
      // test
      rewind: false
    }
  }

  get breakpointsOptions(): { breakpoints?: Record<number, { perView: number }> } {
    return this.breakpoints
        ? Object.keys(this.breakpoints).reduce((acc, step) => {
          const options = this.breakpoints![Number(step)]
          return {
            breakpoints: {
              ...acc.breakpoints,
              [step]: typeof options === 'number'
                  ? {perView: options}
                  : {...options, ...this.contextualOptions(options)}
            }
          }
        }, {breakpoints: {}})
        : {}
  }

  get glideExtensions(): GlideExtensions {
    const extensions: GlideExtensions = {Anchors, Autoplay, Controls, Swipe}
    if (!!this.breakpoints) extensions.Breakpoints = Breakpoints
    return extensions
  }

  get slideSlots(): VNode[] {
    return this.$slots?.default?.filter(s => !!s.tag)
        || (this.$scopedSlots?.default && this.$scopedSlots.default({
          activeIndex: this.activeIndex,
          windowSize: this.windowSize
        })?.filter(s => !!s.tag))
        || []
  }

  get showControls(): boolean {
    return (this.glide?.settings?.perView || this.perView) < this.slideSlots.length
  }

  get glideComponents(): Partial<GlideExtensions> {
    return this.glide?._c || {}
  }

  /*--- WATCHERS ------*/

  /*--- REFS ----------*/
  $refs!: Vue["$refs"] & {
    glide: Element
  }

  /*--- EVENTS --------*/
  mounted(): void {
    this.instantiateGlide();
    if (this.disable) {
      this.$nextTick().then(() => this.disableSlider())
    }
  }

  destroyed(): void {
    if (this.glide) this.glide.destroy()
  }

  /*--- METHODS -------*/
  instantiateGlide(): void {
    if (!this.slideSlots) return
    this.$nextTick(() => {
      if (this.glide || !this.$refs.glide) return
      this.glide = new Glide(this.$refs.glide, this.glideOptions)
      if (this.glide) {
        this.registerEventsHandlers(this.glide)
        this.glide.mount(this.glideExtensions)
      }
    })

  }

  registerEventsHandlers(glide: Glide): void {
    glide.on('resize', () => {
      this.windowSize = window.innerWidth
      this.$forceUpdate()
      if (this.visibleSlides !== this.glide!.settings.perView) {
        this.visibleSlides = this.glide!.settings.perView || this.perView
        this.onResize()
      }
    })
    if (!!this.$listeners['move']) {
      glide.on('move', () => {
        this.$emit('move', glide.index)
      })
    }

    if (!!this.$listeners['move.after'] || this.$scopedSlots.default) {
      glide.on('move.after', () => {
        this.activeIndex = glide.index
        this.$emit('move-after', glide.index)
      })
    }

    if (!!this.$listeners['run']) {
      glide.on('run', ev => {
        this.$emit('run', ev)
      })
    }
  }

  go(pattern: string) {
    this.glide && this.glide.go(pattern);
  }

  onResize(): void {
    this.glideComponents?.Autoplay?.stop()
    this.glideComponents?.Swipe?.disable()
    this.requestFrameHandler && cancelAnimationFrame(this.requestFrameHandler)
    this.requestFrameHandler = requestAnimationFrame(() => {
      const autoplay = this.glide?.settings?.autoplay || this.autoplay
      const focusAt = this.glide?.settings?.focusAt || this.focusAt
      const perView = this.glide?.settings?.perView || this.perView
      if (this.slideSlots.length > perView) {
        autoplay && this.glideComponents?.Autoplay?.start()
        this.glideComponents?.Swipe?.enable()
      }
      if (focusAt === 'center') {
        this.updateSliderHandler && clearTimeout(this.updateSliderHandler)
        this.updateSliderHandler = setTimeout(
            this.glide!.go.bind(this.glide, '=' + this.centerSlideIndex(perView)),
            this.updateSliderDelay
        )
      }
    })
  }

  contextualOptions(options?: GlideOptions, slidesCount?: number): GlideOptions {
    slidesCount = slidesCount || this.slideSlots.length
    const type = options?.type || this.type
    const perView = options?.perView || this.perView
    const startAt = options?.startAt || this.startAt
    const focusAt = options?.focusAt || this.focusAt
    const autoplay = options?.autoplay || this.autoplay

    return {
      type: slidesCount > perView ? type : 'slider',
      startAt: focusAt === 'center' ? this.centerSlideIndex(perView) : startAt,
      autoplay: slidesCount > perView ? autoplay : 0,
    }
  }

  centerSlideIndex(perView: number): number {
    return Math.floor(Math.min(perView, this.slideSlots.length) / 2)
  }

  createSlides(createElement: CreateElement, slots: VNode[]): VNode[] {
    return slots.filter(({tag, text}) => !!(tag || text?.trim())).map((slot, index) => {
      return createElement(
          'li',
          {
            class: 'glide__slide',
            attrs: {'data-glide-index': index},
          },
          [slot]
      )
    })
  }

  arrowData(arrow: 'left' | 'right', dir: '<' | '>'): object {
    return {
      class: 'glide__arrow glide__arrow--' + arrow,
      attrs: {
        'data-glide-dir': dir,
        'aria-label': arrow === 'left'
            ? this.$t('generic.previous')
            : this.$t('generic.next')
      }
    }
  }

  createArrows(createElement: CreateElement, dark: boolean = false): VNode {
    const arrowElements = [
      createElement('button', this.arrowData('left', '<')),
      createElement('button', this.arrowData('right', '>')),
    ]
    const arrowsData = {
      class: ['glide__arrows', {'glide__arrows--dark': dark}],
      attrs: {'data-glide-el': 'controls'},
      directives: [
        {name: 'show', value: this.showControls}
      ],
      ref: 'arrows',
    }
    return createElement(
        'div',
        arrowsData,
        arrowElements,
    )
  }

  bulletData(index: number): object {
    return {
      class: 'glide__bullet tr-visible-sm',
      attrs: {
        'data-glide-dir': '=' + index,
        'aria-label': '#' + index,
      },
      directives: [
        {name: 'show', value: this.showControls}
      ],
      ref: 'bullets',
    }
  }

  createBullets(createElement: CreateElement, slots: VNode[], dark: boolean = false): VNode {
    const bullets = []
    for (let i = 0; i < slots.length; i++) {
      bullets.push(createElement('button', this.bulletData(i)))
    }
    const bulletsData = {
      class: ['glide__bullets', {'glide__bullets--dark': dark}],
      attrs: {'data-glide-el': 'controls[nav]'}
    }
    return createElement(
        'div',
        bulletsData,
        bullets
    )
  }

  /*--- RENDER --------*/
  render(createElement: CreateElement): VNode {
    if (!(this.slideSlots && this.slideSlots.length)) {
      return createElement('div', {class: 'glide__empty'}, [this.emptyMessage])
    }
    const slidesWrapperData = {class: 'glide__slides'}
    const slidesWrapperElement = createElement(
        'ul',
        slidesWrapperData,
        this.createSlides(createElement, this.slideSlots)
    )
    const trackData = {class: 'glide__track', attrs: {'data-glide-el': 'track'}}
    const trackElement = createElement('div', trackData, [slidesWrapperElement])
    const rootData = {class: 'glide', ref: 'glide'}
    const rootChildren = [trackElement]
    if (!!this.arrows) {
      rootChildren.push(this.createArrows(createElement, this.arrows === 'dark'))
    }
    if (!!this.bullets) {
      rootChildren.push(this.createBullets(createElement, this.slideSlots, this.bullets === 'dark'))
    }
    return createElement('div', rootData, rootChildren)
  }

  disableSlider() {
    if (this.glide) {
      this.glide.go('<<');
      this.glide.disable();
    }
  }

  enableSlider() {
    if (this.glide)
      this.glide.enable();
  }

  @Watch('disable')
  onDisableSlider(status: boolean) {
    status ? this.disableSlider() : this.enableSlider()
  }
}
