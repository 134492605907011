var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"commentSection"},[_c('CommentInput',{attrs:{"post-id":_vm.postId},on:{"saved":function($event){return _vm.$emit('saved')},"update":_vm.updateComments}}),_vm._v(" "),_c('ApolloQuery',{ref:"commentsQueryComponent",attrs:{"query":_vm.commentsQuery,"variables":_vm.commentsVariables},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
var query = ref.query;
return [(data && data.commentList)?_c('div',[_c('div',{staticClass:"commentBox isOpen"},_vm._l((_vm.paginated(data.commentList.comments)),function(comment){return _c('div',{key:comment.id,staticClass:"commentImageMessageBox"},[_c('Comment',{attrs:{"comment":comment},on:{"toggle-children":_vm.toggleRepliesShow,"deleted":_vm.commentDeleted}}),_vm._v(" "),_c('div',{staticClass:"commentImageReplyBox"},[(_vm.showingRepliesId === comment.id)?_c('div',{staticClass:"replicas"},[_c('ApolloQuery',{ref:"repliesQueryComponent",refInFor:true,attrs:{"query":_vm.repliesQuery,"variables":{ id: _vm.showingRepliesId, limit: comment.repliesCount }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(data && data.replyList)?_c('div',_vm._l((data.replyList.replies),function(reply){return _c('Comment',{key:reply.id,attrs:{"comment":reply},on:{"deleted":_vm.replyDeleted}})}),1):_vm._e(),_vm._v(" "),(error)?_c('div',{staticClass:"error apollo"},[_vm._v("An error occured")]):(isLoading)?_c('Loader',{attrs:{"show-text":true}}):_vm._e()]}}],null,true)}),_vm._v(" "),_c('CommentInput',{attrs:{"parent-id":comment.id},on:{"saved":function($event){return _vm.$emit('saved')},"update":_vm.updateReplies}})],1):_vm._e()])],1)}),0),_vm._v(" "),(_vm.hasMoreComments(data.commentList.comments, data.commentList.pageInfo))?_c('div',{staticClass:"commentRecap"},[_c('p',{staticClass:"no-margin pointer smallest",on:{"click":function($event){return _vm.fetchMoreComments(query, data.commentList.comments, data.commentList.pageInfo)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('pageTree.viewMoreComments', { numberComments: _vm.remainingCount }))}})])]):_vm._e()]):_vm._e(),_vm._v(" "),(error)?_c('div',{staticClass:"error apollo"},[_vm._v("An error occured")]):(isLoading)?_c('Loader',{attrs:{"show-text":true,"inline":true}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }