
const ecommercesQuery = (_ecommerces, _nofilter, _language) => {
    const ecommerces = _ecommerces ? `${_ecommerces.map(_e => `"${_e}"`).join(", ")}` : "";
    return `ecommerces:Ecommerce_list(names: [${ecommerces}], avoidFilter:${_nofilter? "true": "false"}){
                products{
                    ${productFieldsQuery(_language)}
                }
            }`;
};


const productsQuery = (_products, _nofilter, _language) => {
    return `products:Product_list(ids: [${_products.join(", ")}], avoidFilter:${(_nofilter? "true": "false")}){
            ${productFieldsQuery(_language)}
        }`;
};

const productsEcommerceQuery = (_productsTypes, _ecommerces, _nofilter, _language) => {
    const types = _productsTypes.map(_t => `"${_t}"`).join(", ");
    const ecommerces = _ecommerces ? `names: [${_ecommerces.map(_e => `"${_e}"`).join(", ")}], ` : "";
    return `ecommerceProducts:Ecommerce_Product_list(types: [${types}],${ecommerces} avoidFilter:${_nofilter? "true": "false"}){
                ${productFieldsQuery(_language)}
            }`;
};

const productFieldsQuery = _language => {
    const defaultLang = _language != "en" ? " en" : "";
    return `id
    name
    productName {
      ${_language}
      ${defaultLang}
    } 
    icon {
      small
      medium
      large
      xlarge
    }
    price
    badge
    badgeDate
    type
    giftable
    inForest
    background{
      xlarge
    }
    productSubTitle {
      ${_language}
      ${defaultLang}
    }
    productInfo {
      ${_language}
      ${defaultLang}
    }
    specie{
      tankSize
      name{
        ${_language}
        ${defaultLang}
      }
      scientificName{
        name
        id
      }
      icon{
        small
        medium
      }
      originator{
        nurseryLat
        nurseryLng
      }
      country{
        slug
        flag{
          small
        }
        name{
          ${_language}
        }
        image{
          small
          medium
          large
        }
      }
      badges{
        icon{
          small
          medium
        }
        name{
          ${_language}
        }
        description{
          ${_language}
        }
      }
      whichTree{
        ${_language}
      }
      sayTitle{
        ${_language}
      }
      sayDescription{
        ${_language}
      }
      co2Capture{
        ${_language}
      }
      curiosity{
        ${_language}
      }
    }
    limited{
      limitedName{
      ${_language}
      ${defaultLang}
      }
      limitedNameSubTitle{
        ${_language}
      }
      limitedSubTitle{
        ${_language}
      }
      limitedTitle{
        ${_language}
      }
      limitedDescription{
        ${_language}
      }
      limitedEvent{
        id
        name
        avatar{
          small
        }
      }
      icon{
        small
        medium
      }
      limitedSerieSlug
      limitedSerie
    }
    package{
      name{
        ${_language}
        ${defaultLang}
      }
      description{
        ${_language}
      }
      icon{
        small
        medium
      }
      products{
        id
        quantity
        price
        specie{
          tankSize
          name{
            ${_language}
          }
          scientificName{
            name
          }
          icon{
            small
            medium
          }
          originator{
            nurseryLat
            nurseryLng
          }
          country{
              slug
              flag{
                small
              }
            name{
              ${_language}
            }
            image{
              small
              medium
              large
            }
          }
          badges{
            icon{
              small
              medium
            }
            name{
              ${_language}
            }
            description{
              ${_language}
              ${defaultLang}
            }
          }
          whichTree{
            ${_language}
          }
          sayTitle{
            ${_language}
          }
          sayDescription{
            ${_language}
          }
          co2Capture{
            ${_language}
          }
          curiosity{
            ${_language}
          }
          gallery{
            medias{
              picture{
                medium
                large
              }
            }
          }
          powers{
            foodsecurityPower
            co2Power
            incomePower
            soilPower
          }
        }
        limited{
          limitedName{
            ${_language}
          }
          limitedNameSubTitle{
            ${_language}
           }
           limitedSubTitle{
             ${_language}
           }
           limitedTitle{
            ${_language}
           }
           limitedDescription{
            ${_language}
           }
           icon{
             small
             medium
           }
           limitedSerieSlug
           limitedSerie
        }
      }
    }`;
}


const treeBadgesQuery = _language => {
    return `treeBadges:Tree_Badge_list {
        name {
           ${_language}
        }
        description {
           ${_language}
        }
        icon {
          small
          medium
          large
          large
        }
      }`;
}

export {
    ecommercesQuery,
    productsQuery,
    productsEcommerceQuery,
    treeBadgesQuery
}
