



















import { defineComponent, ref } from "@vue/composition-api";
import QuestionLayout from "./../../Layout/Question/QuestionLayout.vue";
import QuestionHeader from "./../../Layout/Question/QuestionHeader.vue";
import QuestionVideoPlayer from "./../../Layout/Question/QuestionVideoPlayer.vue";
import AnswerList, { Answer } from "./../../Layout/Question/AnswerList.vue";
import DotLoader from "@f/components/DotLoader.vue";

export default defineComponent({
  name: "MulchQuestion",
  components: {
    QuestionLayout,
    QuestionHeader,
    AnswerList,
    DotLoader,
    QuestionVideoPlayer,
  },
  emits: ["complete"],
  setup: (_, { emit }) => {
    const expandedPlayer = ref(true);
    const canPlay = ref(true);

    const onAnswer = (isCorrect: boolean) => {
      emit("complete", { result: isCorrect });
      canPlay.value = false;
    };

    const questionText = "game.question.mulch";
    const answers: Answer[] = [
      {
        description: "game.question.mulch.option1",
        wrong: "game.question.mulch.option1.wrong",
        explanation: {
          wrong: "game.question.mulch.option1.explanation.wrong",
        },
        isCorrect: false,
      },
      {
        description: "game.question.mulch.option2",
        wrong: "game.question.mulch.wrong",
        explanation: {
          wrong: "game.question.mulch.option2.explanation.wrong",
        },
        isCorrect: false,
      },
      {
        description: "game.question.mulch.option3",
        correct: "game.question.mulch.correct",
        explanation: {
          correct: "game.question.mulch.option3.explanation.correct",
        },
        isCorrect: true,
      },
      {
        description: "game.question.mulch.option4",
        wrong: "game.question.mulch.wrong",
        explanation: {
          wrong: "game.question.mulch.option4.explanation.wrong",
        },
        isCorrect: false,
      },
    ];
    return {
      questionText,
      answers,
      onAnswer,
      expandedPlayer,
      canPlay,
    };
  },
});
