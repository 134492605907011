angular.module('ngApp')
.controller('modalBadgeController', ['$rootScope', '$scope', '$stateParams', '$state', function ($rootScope, $scope, $stateParams, $state) {
  var ctrl = this
  ctrl.shareUrl = window.location.origin + window.location.pathname

  ctrl.findSelected = function () {
    if (!$stateParams.badgeSlug) return
    ctrl.selected = $rootScope.badgesAvailable.findIndex(badge => badge.slug ===$stateParams.badgeSlug)
    if (ctrl.selected === -1) $state.go('user.badges')
  }

  if ($rootScope.badgesAvailable) ctrl.findSelected()

  var userBadgeListener = $rootScope.$on('UserBadgeCharged', function (event, user) {
    ctrl.findSelected()
  })

  var closedModalListener = $rootScope.$on('closedTreedomModal', function (event, modalInfo) {
    // $state.go('user.badges')
    $state.go('user')
  })

  $scope.$on('$destroy', closedModalListener)
  $scope.$on('$destroy', userBadgeListener)

}])
