
import Vue, { VNode, PropOptions } from 'vue'
import SlideDownTransition from '@f/transitions/SlideDown'

export default Vue.extend({
  components: { SlideDownTransition },
  props: {
    open: {
      type: [String, Array, Boolean],
      default: false
    } as PropOptions<string | string[] | boolean>,
    toggle: {
      type: Boolean,
      default: true
    } as PropOptions<boolean>,
    headerOpenClass: {
      type: String,
      default: 'active tr-caret--up'
    } as PropOptions<string>,
    headerCloseClass: {
      type: String,
      default: 'tr-caret--down'
    } as PropOptions<string>,
  },
  data() {
    return {
      openItems: null as string[] | null
    }
  },
  methods: {
    isOpen(itemName: string): boolean {
      return !!this.openItems?.includes(itemName)
    },
    toggleItem(itemName: string): void {
      if (this.isOpen(itemName)) {
        this.openItems = this.toggle
          ? []
          : [ ...this.openItems!.slice(this.openItems!.indexOf(itemName)) ]
      } else {
        this.openItems = this.toggle
        ? [itemName]
        : [ ...(this.openItems || []), itemName ]
      }
    }
  },
  render(createElement) {
    const accordionItems: VNode[] = []
    
    for (const slotName in this.$slots) {
      if (!this.$slots[slotName]?.length) continue
      const slotNodes = this.$slots[slotName]!.length > 1
        ? this.$slots[slotName]!
        : this.$slots[slotName]![0].children || []
      const [ headerNode, ...contentNodes] = slotNodes.map(node => node.text
            ? { ...node, text: node.text.trim() }
            : node)
      if (!contentNodes || !contentNodes.length) {
        console.warn(
          `Accordion slot named %c"${slotName}"%c has less then 2 children.\n`,
          'font-style: italic',
          'font-style: regular',
          'An accordion item slot must provide at least 2 children: first one as the header and the remaining as content.',
        )
        continue
      }

      if (!this.openItems && this.open) {
        this.openItems = Array.isArray(this.open)
          ? this.open
          : typeof this.open === 'string'
            ? [this.open]
            : [slotName]
      }

      const headerData = {
        class: [
          'accordion__item__header',
          {
            [this.headerOpenClass]: this.isOpen(slotName),
            [this.headerCloseClass]: !this.isOpen(slotName),
          }
        ],
        on: {
          click: this.toggleItem.bind(this, slotName)
        }
      }

      const contentData = {
        class: 'accordion__item__content',
        // FIXME: fix SlideDownTransition v-if delay
        directives: [
          {
            name: 'show',
            value: this.isOpen(slotName),
          }
        ],
      }

      accordionItems.push(
        createElement('dt', headerData, [headerNode]),
        createElement(
          'SlideDownTransition',
          { props: { key: `content-${slotName}` } },
          [ createElement('dd', contentData, contentNodes) ]
        )
      )
    }

    const accordionData = {
      class: 'accordion'
    }
    return createElement('dl', accordionData, accordionItems)
  }
  
})
