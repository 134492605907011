import { JSONSchemaType } from 'ajv'
import { ITshirtChoice } from '@f/validation/types/dataLayer'

export const TshirtChoice: JSONSchemaType<ITshirtChoice> = {
  type: 'object',
  required: ['tshirtFlag'],
  properties: {
    tshirtFlag: {type: 'boolean'},
  }
}

