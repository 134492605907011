








import { defineComponent } from '@vue/composition-api'
import Map from '@f/components/Map/Map.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import MapClusterMarkers from '@f/components/Map/MapClusterMarkers.vue'

export default defineComponent({
  name: 'OrganizationMapPreviewBig',
  components: { MapClusterMarkers, Treecon, Map },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
})
