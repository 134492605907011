import { Price } from '../services/NewEcommerce/types'
import { getRootScope } from "@f/services/Angular";

export const UNAVAILABLE_BADGES_NEW_ECOMMERCE = {soldOut: 'soldOut', comingSoon: 'comingSoon', until: 'until'}

export function extractPrice(prices: Array<Price>) {
  return extractPriceByCurrency(prices)!.centAmount! / 100
}

export function extractPriceByCurrency(prices: Array<Price>): Price {
  const currency = getRootScope()?.currency ?? 'EUR'
  return prices.find((p) => p.currencyCode === currency)!

}

export function getDiscountFromPrices(
  finalPrices: Array<Price>,
  fullPrices: Array<Price>,
): number | undefined {
  const finalPrice = extractPriceByCurrency(finalPrices)
  const fullPrice = extractPriceByCurrency(fullPrices)

  if (
    fullPrice?.centAmount !== finalPrice?.centAmount && fullPrice?.centAmount !== 0
  ) {
    const discount = Math.round(
      (100 * (fullPrice!.centAmount! - finalPrice!.centAmount!)) /
        fullPrice!.centAmount!
    )

    return discount
  }

  return undefined
}
