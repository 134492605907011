














import { defineComponent, PropType, computed } from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'

const redeemReasons = [
  'whyRegister.redeemReason1.description',
  'whyRegister.redeemReason2.description',
  'whyRegister.redeemReason3.description',
  'whyRegister.redeemReason4.description',
] as const

const checkOutReasons = [
  'whyRegister.checkOutReason1.description',
  'whyRegister.checkOutReason2.description',
  'whyRegister.checkOutReason3.description',
] as const

export default defineComponent({
  name: "SignInFormWhyRegister",
  components: {
    Treecon
  },

  props: {
    actionType: {
      type: String as PropType<'redeem' | 'checkOut' | 'login' | null>,
      required: false,
      default: () => 'login',
    },
  },

  setup(props) {

    const reasons = computed(() => {
      if (props.actionType === 'redeem') {
        return redeemReasons
      }
      if (props.actionType === 'checkOut') {
        return checkOutReasons
      }

      return []
    })
    return {
      reasons
    }
  },
})

