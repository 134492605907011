import {SocialAction, SocialType} from '@f/models/misc'
import {ProductType} from '@f/@types'
import {TreecodeGiftDeliveryType} from '@f/services/Treecodes/types'

export type IDLEventName =
    | 'treeInfo'
    | 'impressions'
    | 'detail'
    | 'giftFlag'
    | 'addToCart'
    | 'removeFromCart'
    | 'checkout'
    | 'voucherTransaction'
    | 'transaction'
    | 'cartViewed'
    | 'registration'
    | 'socialButton'
    | 'startGift'
    | 'sendGift'
    | 'orderEdited'
    | 'deactivateSubscription'
    | 'unsubscription'
    | 'paymentInfoAdded'
    | 'paymentInfoAddedSub'
    | 'filterSelection'
    | 'download'
    | 'registerOfTrees'
    | 'registerOfTreesSelection'
    | 'confirmName'
    | 'notifyProducts'
    | 'diary'
    | 'beginForest'
    | 'selectionForest'
    | 'selectionTrees'
    | 'forestPayed'
    | 'forestCustomized'
    | 'forestInfoEdited'
    | 'follow'
    | 'unfollow'
    | 'notification'
    | 'boxInfo'
    | 'video'
    | 'map'
    | 'paymentInfoEdited'
    | 'paymentSent'
    | 'paymentInfoEditedSub'
    | 'paymentSentSub'
    | 'profileInfoEdited'
    | 'profileInfoCompleted'
    | 'form'
    | 'profileActivity'
    | 'startRansom'
    | 'startRansomConfirmed'
    | 'openRansom'
    | 'choiceRansom'
    | 'giftRansom'
    | 'giftLinkShared'
    | 'tshirtChoice'
    | 'startQuiz'
    | 'locationQuizChoice'
    | 'benefitQuizChoice'
    | 'co2QuizChoice'
    | 'meaningQuizChoice'
    | 'treeChoice'
    | 'userIntrestInEvent'
    | 'startGame'
    | 'gameQuestion1'
    | 'gameQuestion2'
    | 'gameQuestion3'
    | 'gameQuestion4'
    | 'gameQuestion5'
    | 'gameCopiedCode'
    | 'gameSentGift'
    | 'gameCtaUnderstand'

export type IDLGiftPosition =
    | 'treedom-checkout-flow'
    | 'treedom-user-profile'
    | 'treedom-tree-page'
    | 'treedom-navbar-user-menu'
    | 'treedom-user-tree'
    | 'treedom-user-profile-banner'

export type IDLTreeDiaryInteractionContent =
    | 'myDiary'
    | 'identity'
    | 'map'
    | 'co2'
    | 'message'

export type IDLProfileInteractionContent =
    | 'diary'
    | 'overview'
    | 'trees'
    | 'badges'
    | 'forests'
    | 'kits'

export type IDLFilterCategory =
    | 'sort'
    | 'types'
    | 'filterBadges'
    | 'filterCountries'
    | 'filterSpecie'
    | 'filterSpecie_say'
    | 'filterTags'

export type IElementSelected = 'user' | 'tree'

export type IGiftType = 'link' | 'qrCode' | 'email' | ''

export type SubscriptionFromPosition =
    | 'menu-flow'
    | 'ecommerce-flow'
    | 'settings-sub-flow'
    | 'home-flow'
    | 'sub-landing-flow'
export namespace GiftType {
    export const fromTreecodeGiftDeliveryType = (
        giftType: TreecodeGiftDeliveryType
    ): IGiftType => {
        switch (giftType) {
            case 'code':
            case 'CODE':
                return 'link'
            case 'email':
            case 'EMAIL':
                return 'email'
            case 'print':
            case 'PRINT':
                return 'qrCode'
            default:
                return 'link'
        }
    }
}

export interface IDlProduct {
    id: number
    name: string
    price: number
    tax: number
    brand: 'Treedom'
    category: ProductType
    position?: number
    discount: number
}

export interface IDLGiftedTree {
    treeId: string
    treeName: string | null
    treeType: string
}

export interface IDlProductImpression extends IDlProduct {
    list?: string
}

export interface IDlProductInteraction extends IDlProduct {
    quantity: number
    dimension1?: boolean
}

export interface IProductPackageTreeInfo {
    event: IDLEventName
    treeName: string
}

export interface IProductPageView {
    event: IDLEventName
    ecommerce: {
        detail: {
            products: IDlProductImpression[]
        }
    }
}

export interface IProductSetGift {
    event: IDLEventName
    flag: 'yes' | 'no'
    treeType: string
}

export interface IAddToCart {
    event: IDLEventName
    ecommerce: {
        currencyCode: 'EUR'
        cartId: number
        add: {
            products: IDlProductInteraction[]
        }
    }
}

export interface IRemoveFromCart {
    event: IDLEventName
    ecommerce: {
        currencyCode: 'EUR'
        cartId: number
        remove: {
            products: IDlProductInteraction[]
        }
    }
}

export interface IImpressions {
    event: IDLEventName
    ecommerce: {
        currencyCode: 'EUR'
        cartId: number
        impressions: IDlProductInteraction[]
    }
}

export interface ICheckout {
    event: IDLEventName
    ecommerce: {
        currencyCode: 'EUR'
        cartId: number
        checkout: {
            activeField: { step: number }
            products: IDlProductInteraction[]
        }
    }
}

export interface IVoucherTransaction {
    event: IDLEventName
    voucher: string
    voucherId: number
    cartSize: number
    cartValue: number
    cartId: number
}

export interface IOrderEdited {
    event: IDLEventName
    coupon: string | null
    voucherId: number | null
    cartSize: number
    cartValue: number
    cartId: number
    products: IDlProductInteraction[]
}

export interface ITransaction {
    event: IDLEventName
    userRegisteredToday: boolean
    ecommerce: {
        currencyCode: 'EUR'
        cartId: number
        totalDiscount: number
        purchase: {
            actionField: {
                id: number | string
                revenue: number
                coupon: string
            }
            products: IDlProductInteraction[]
        }
    }
    tshirtFlag: boolean
}

export interface IDetail {
    ecommerce: {
        detail: {
            products: IDlProduct[]
        }
    }
}

export interface ICartViewed {
    event: IDLEventName
    cartSize: number
    cartValue: number
    cartId: string
    totalDiscount: number
}

export interface IPaymentInfo {
    event: IDLEventName
    cartSize: number
    cartValue: number
    cartId: number
    paymentMethod: string
    totalDiscount?: number
}

export interface IPaymentInfoSub {
    event: IDLEventName
    name: string
    paymentMethod: string
}

export interface IRegistration {
    event: IDLEventName
    accountType: string
}

export interface IAuthentication {
    event: 'logIn' | 'logOut'
    accountType?: 'email' | 'facebook' | 'google'
}

export interface IUnsubscribe {
    event: IDLEventName
}

export interface IDeactivateSubscription {
    event: IDLEventName
    name: string
}

export interface ICreateAccountStarted {
    event: 'createAccountStarted'
}

export interface IAccountInfoSubmitted {
    event: 'accountInfoSubmitted'
}

export interface ISocialButton {
    event: IDLEventName
    socialType: SocialType
    socialAction: SocialAction
}

export interface IGiftAction {
    event: IDLEventName
    trees: Array<IDLGiftedTree>
    idGift: string | null
    giftCode: string | null
    position: IDLGiftPosition
    numberoftree: number
    cartId: number | null
    giftType: IGiftType
}

export interface IGiftShared {
    event: IDLEventName
    idGift: string
    giftCode: string
}

export interface IGiftCanceled {
    event: IDLEventName
    idGift: string
    giftCode: string
}

export type RansomPosition =
    'treecode-redeem-landing'
    | 'treecode-redeem-flow'
    | 'treecode-toolbar'
    | 'treecode-sidebar'
    | 'treecode-widget'
    | 'homepage'
export type RansomType = 'picker' | 'virgin' | 'simple'

export interface IRansomStart {
    event: IDLEventName
    logged: boolean
    ransomCode: string
    position: RansomPosition
    giftType: IGiftType
}

export interface IRansomStartConfirmed {
    event: IDLEventName
    logged: boolean
    ransomCode: string
    position: RansomPosition
    giftType: IGiftType
}

export interface IRansomOpen {
    event: IDLEventName
    logged: boolean
    ransomCode: string
    position: RansomPosition
    ransomType: RansomType
    numberOfTree: number
    giftType: IGiftType
}

export interface IRansomChoice {
    event: IDLEventName
    logged: boolean
    ransomCode: string
    position: RansomPosition
    ransomType: RansomType
    numberOfTree: number
    giftType: IGiftType
    // the picked tree type
    treeType: string
}

export interface IRansomRedeem {
    event: IDLEventName
    logged: boolean
    ransomCode: string
    position: RansomPosition
    ransomType: RansomType
    numberOfTree: number
    giftType: IGiftType
    // the picked tree type
    treeType: string
}

export interface IFilterSelection {
    event: IDLEventName
    filterCategory: string
    filterSubCategory: string
}

export interface IFilterSubcategory {
    filter: string
    label: string
    value: string | number
}

export interface IDownload {
    event: IDLEventName
    downloadType: string
    docName: string
    pageUrl: string
}

export interface ITreeRegistryInteraction {
    event: IDLEventName
    treeId?: string
    country?: string
    userId?: number
}

export interface ITreeRegistrySelection {
    event: IDLEventName
    treeId: string
    tree: string
    userId: number
}

export interface ITreeNameConfirmed {
    event: IDLEventName
    nameConfirmed: string
}

export interface INotifyMe {
    event: IDLEventName
    productName: string
    productAvailability: string
}

export interface ITreeDiaryInteraction {
    event: IDLEventName
    content: IDLTreeDiaryInteractionContent
    action: string | null
    element: string | null
}

export interface IProfileActivityInteraction {
    event: IDLEventName
    content: IDLProfileInteractionContent
    action: string | null
    element: string | null
    isOrganization: boolean
}

export interface ISimpleEventForest {
    event: IDLEventName
}

export interface ISelectionForest {
    idForest: number | null
    festivityType: string
    forestType: string
}

export interface IEventSelectionForest extends ISelectionForest {
    event: IDLEventName
}

export interface TreesPackage {
    id: number
    name: string
    price: number
    brand: string
    category: string
    position: number
    dimension1: boolean
    quantity: number
}

export interface ISelectionTrees {
    idForest: number
    packageType: string
    forest: {
        add: {
            products: TreesPackage[] | null
        }
    }
}

export interface IEventSelectionTrees extends ISelectionTrees {
    event: IDLEventName
}

export interface IForestInfoEdited {
    idForest: number
    festivityType: string
    forestType: string
    packageType: string | null
    forest: {
        add: {
            products: TreesPackage[] | null
        }
    }
}

export interface IEventForestInfoEdited extends IForestInfoEdited {
    event: IDLEventName
}

export interface IToggleFollow {
    event: IDLEventName
    position: string
    elementSelected: IElementSelected
}

export interface IOpenNotification {
    event: IDLEventName
    notificationType: string
    notificationId: number
}

export interface IBoxInfo {
    event: IDLEventName
    infoLabel: string
    pageUrl: string
}

export interface IVideo {
    event: IDLEventName
    videoUrl: string
}

export interface IMapInteraction {
    event: IDLEventName
    countrySelected?: string
    treeSelected?: string
    forestSelected?: string
    cluster?: {
        lat: number
        lon: number
    }
}

export interface IProfileInfoEdited {
    event: IDLEventName
    profileCategory: string
    fieldEdited?: string[] | string
    profileAction?: string[] | string
}

export interface IProfileInfoCompleted {
    event: IDLEventName
    profileAction: 'submitted' | 'skip'
}

export interface IForm {
    event: IDLEventName
    formType: string
    location: string
}

export interface IDataLayerVariables {
    userId: number | null
    market: string | null
    country: string | null
    language: string | null
    userStatus: string
    userType: string
    userSpecification: string
    deviceId: string | null
    treeRedeemer?: string
    numberOfTreesOwned: number
    treeOwner: string
}

export interface ITshirtChoice {
    tshirtFlag: boolean
}

export interface IQuizAction {
    event: IDLEventName
}

export type QuizChoice = 'location' | 'benefit' | 'co2' | 'meaning'

export interface IQuizCountryChoice {
    event: IDLEventName
    location: string
}

export interface IQuizBenefitChoice {
    event: IDLEventName
    benefit: string
}

export interface IQuizCo2Choice {
    event: IDLEventName
    co2: string
}

export interface IQuizMeaningChoice {
    event: IDLEventName
    meaning: string
}

export interface IQuizTreeChoice {
    event: IDLEventName
    treeType: string
}

export interface IQuizDone {
    treeTypeQuiz: string[]
}

export interface IUserIntrestInEvent {
    event: IDLEventName
    eventName: string
}

export interface IStartGame {
    event: IDLEventName
}

export interface IGameQuestion1 {
    event: IDLEventName
}

export interface IGameQuestion2 {
    event: IDLEventName
}

export interface IGameQuestion3 {
    event: IDLEventName
}

export interface IGameQuestion4 {
    event: IDLEventName
}

export interface IGameQuestion5 {
    event: IDLEventName
}

export interface IGameCopiedCode {
    event: IDLEventName
}

export interface IGameSentGift {
    event: IDLEventName
}

export interface IGameCtaUnderstand {
    event: IDLEventName
}