<template>
  <div>
    <div class="file-uploader-container">
      <img
        v-show="showPreview"
        :id="fileKey + '_image'"
        class="preview"
        :class="{
          'rectangular-preview': bigPreview,
          'square-preview': !bigPreview,
        }"
      />
      <label :for="fileKey">
        <Treecon :icon="icon" />
        <span>{{ $t('generic.upload.label') }}</span>
      </label>
    </div>
    <span v-if="error" class="error"> {{ error }} </span>
    <span v-else-if="info" class="info"> {{ info }} </span>
    <input
      :id="fileKey"
      class="tr-file-upload"
      type="file"
      :accept="accept"
      @change="loadFile"
      hidden
    >
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'

export default defineComponent({
  name: 'FileUploader',
  components: {
    Treecon,
  },
  props: {
    fileKey: {
      type: String,
      default: 'fileUploader',
    },
    info: {
      type: String,
      default: null,
    },
    bigPreview: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: 'image/jpeg'
    },
    max: {
      type: Number,
      default: 2097152 //2MB
    },
    showPreview: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'image-line'
    }
  },
  data() {
    return {
      error: null,
    }
  },
  methods: {
    loadFile(event) {
      this.error = null;
      const file = event.target.files[0];
      const output = document.getElementById(this.fileKey + '_image');
      if(file.size > this.max){
        output.classList.remove('visible');
        this.$emit('change', null);
        this.error = "File is too big!";
        return;
      }
      output.classList.add('visible');
      output.src = URL.createObjectURL(file)
      this.$emit('change', file);
      output.onload = () => {
        URL.revokeObjectURL(output.src); // free memory
      }
    },
  },
})
</script>

<style scoped>
@import '@f/scss/design-system/_variables.scss';

.file-uploader-container {
  display: flex;
  align-items: center;

  .preview {
    object-fit: cover;
    border-radius: 10px;

    &.square-preview {
      width: 108px;
      height: 108px;
    }

    &.rectangular-preview {
      width: 250px;
      height: 108px;
    }
  }
  img {
    display: none;
    margin-right: 20px;

    &.visible {
      display: block;
    }
  }

  label {
    font-family: "Montserrat";
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 230px;
    height: 40px;
    border: 2px solid $ntds-primary-500;
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;
    span {
      padding-left: 6px;
      margin-top: 2px;
    }
    &:hover {
      color: white;
      background-color: $ntds-primary-500;
    }
  }
}

.tr-file-upload {
  display: none;
}

.info {
  display: block;
  margin-top: 9px;
  margin-bottom: 9px;
  font-size: 12px;
}

.error {
  color: $ntds-red-300;
  display: block;
  margin-top: 9px;
  margin-bottom: 9px;
  font-size: 12px;
}

@media (max-width: $ntds-width-small) {
  .file-uploader-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .preview {
      order: 1;

      label {
        order: 0;
      }
    }
  }
}
</style>
