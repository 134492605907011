import { Component, Mixins } from 'vue-property-decorator'
import axios, { AxiosResponse } from 'axios'

import FetchApiMixin from '@f/mixins/FetchApi'
import ValidateMixin from '@f/mixins/Validate'
import { validatePasswordResetRequest, validatePasswordReset } from '../validation/auth'
import { TreedomAuth } from '@f/auth'


interface ApiResponse {
  error?: string | null
  success?: boolean
}
interface ResetTokenCheckResponse extends ApiResponse {
  user?: string
}

interface PasswordResetResponse extends ApiResponse {
  slug?: string
}

function catchApiError<T extends ApiResponse>({ data }: AxiosResponse<T>): T {
  if (data.error) throw Error(data.error)
  return data
}
@Component
export default class PasswordResetRequest extends Mixins(FetchApiMixin, ValidateMixin) {

  requestReset(email?: string | null) {
    return this.validate(validatePasswordResetRequest, { email })
      .then(() => TreedomAuth.forgotPassword(email!))
  }

  resetPassword(email: string, token: string, password: string, passwordConfirm?: string) {
    return this.validate(validatePasswordReset, { email, password, passwordConfirm })
      .then(() => TreedomAuth.forgotPasswordConfirm(email, token, password))
  }

}
