








































import {computed, defineComponent, PropType, ref, watch} from "@vue/composition-api";
import {TrTree} from "@f/@types/graphql";
import {maxWidth, useMediaQuery} from "@f/utils/breakpoints";
import TreeWeatherCanvas from "@f/components/Tree/TreeWeatherCanvas.vue";
import TreeInfoPicture from "@f/components/Cards/TreeInfoPicture.vue";
import TreeCardStatusBadge from "@f/components/Cards/contents/TreeCardStatusBadge.vue";
import {getVisibleCoordinates} from "@f/services/Tree";
import {getMapBoxStaticPreview} from "@f/services/Map";
import treeNurseryPin from "@f/components/Map/graphics/tree-cluster-pin-small.png";

export default defineComponent({
  name: "MobileHeaderTreePage",
  components: {
    TreeInfoPicture,
    TreeWeatherCanvas,
    TreeCardStatusBadge
  },
  props: {
    tree: {
      type: Object as PropType<TrTree>,
      required: true
    }
  },
  setup(props: { tree: TrTree }) {
    const isMobile = useMediaQuery(maxWidth('md'))
    const activeSlide = ref(0)
    const slider = ref<HTMLElement | null>(null)
    const pictureRef = ref<{showTreeImage: boolean}>()

    const setActiveSlide = (index: number) => {
      activeSlide.value = index
      scrollSliderTo(index)
    }

    const onMobileImageClick = () => {
      if(!pictureRef.value) return
      // perdoname madre por mi vida loca
      pictureRef.value.showTreeImage = true
    }

    const scrollSliderTo = (index: number) => {
      const _slider = slider.value
      if (_slider) {
        _slider.scrollTo({
          left: _slider.clientWidth * index,
          behavior: 'smooth',
        })
      }
    }

    const onScroll = () => {
      const _slider = slider.value
      if (_slider) {
        const slideWidth = _slider.clientWidth;
        const scrolledPixels = _slider.scrollLeft;
        activeSlide.value = Number.parseInt(`${(scrolledPixels + slideWidth / 2) / slideWidth}`)
      }
    }

    const slides = ref()

    watch(() => slider.value, () => {
      const _slider = slider.value
      if (_slider) {
        slides.value = _slider.children
        slider.value?.addEventListener('scroll', () => {
          onScroll()
        })
      }
    })

    const mapPreview = computed(() => {
      if (props.tree) {
        const [lng, lat] = getVisibleCoordinates(props.tree)
        return getMapBoxStaticPreview(
            `${window.origin}${treeNurseryPin}`,
            {
              lat,
              lng,
            },
            {
              width: 250,
              height: 166,
            }
        )
      }
    })

    return {
      isMobile,
      activeSlide,
      setActiveSlide,
      onMobileImageClick,
      pictureRef,
      mapPreview,
      slides,
      slider
    }
  }
})
