























import { defineComponent, ref } from "@vue/composition-api";
import Popover from "@f/components/Popover.vue";
import { getRootScope } from "@f/services/Angular";
import { ShopCurrency } from "@f/configs/app";
import { changeCurrency } from '@f/services/NewEcommerce/CartService';



export default defineComponent({
  name: 'CurrencySelector',
  components: {
    Popover,
  },
  setup() {
    const openPopover = ref(false);
    const rootScope = getRootScope();
    const selectedCurrency = ref(rootScope!.currency);

    return {
      openPopover,
      selectedCurrency,
      changeCurrency,
      ShopCurrency
    }
  },
})
