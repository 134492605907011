































import { Component, Mixins } from 'vue-property-decorator'
import TreeDiaryStatus from '../post_types/TreeDiaryStatus.vue'

@Component({
  name: 'TreeDiaryStatusModal',
})
export default class TreeDiaryStatusModal extends Mixins(TreeDiaryStatus) {
  // Computed
  get image(): string {
    return this.getStatusImage()
  }
}
