import isUndefined from 'lodash/isUndefined'

angular.module('ngApp')
.controller('totalNumsController', ['$rootScope', 'Game', function ($rootScope, Game) {

  /**
   * Se non ci sono i numeri (es: utenti totali, aziende totali, alberti piantati)
   * allora chiedili al server!
   */
  if (isUndefined($rootScope.numbers)) { // TODO: try to remove lodash
    Game.totalNums()
    .then(function(response) {
      $rootScope.numbers = response.data || response
    })
  }
}])
