









































































































import { defineComponent, computed, PropType } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import HUDPage from '@f/components/Map/HUD/HUDPage.vue'
import DotLoader from '@f/components/DotLoader.vue'
import {
  generateKey,
  isAvailable,
  isPriceHighlighted,
  price,
  productFullPrice,
} from '@f/utils'
import { ECOMMERCE_PRODUCT_PAGE } from '@f/graphql/queries'
import useRoutes from '@f/composables/useRoutes'
import { getLanguage, translate } from '@f/services/Translation'
import {
  TrCountry,
  TrEcommerceProductPageQuery,
  TrEcommerceProductPageQueryVariables,
  TrProduct,
} from '@f/@types/graphql'
import AssignProducts from '@f/mixins/AssignProducts'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import { Product } from '@f/@types'
import AddToCartIconButton from './AddToCartIconButton.vue'
import useUserInfo from '@f/composables/useUserInfo'

// @ts-ignore
export default defineComponent({
  name: 'HUDEcommerceCountryProducts',
  mixins: [AssignProducts],
  components: {
    HUDPage,
    DotLoader,
    AddToCartIconButton,
  },
  props: {
    state: String,
    country: Object as PropType<TrCountry>,
  },
  setup(props) {
    const isMobile = useMediaQuery(maxWidth('md'))
    const { userInfo } = useUserInfo()
    const { href } = useRoutes()
    const productPrice = (product: TrProduct) =>
      price(product.price!, getLanguage())
    const productOriginalPrice = (product: TrProduct) =>
      productFullPrice(product as unknown as Product, getLanguage())
    const isProductDiscounted = (product: TrProduct) =>
      isPriceHighlighted(
        product as unknown as Product,
        userInfo.value?.usertype === 'Business'
      )
    const isProductAvailable = (product: TrProduct) =>
      isAvailable(product as unknown as Product)
    const { go } = useRoutes()
    const goToProductPage = (product: TrProduct) =>
      go('species.tree', {
        treeId: generateKey(product.id, product.type as any, product.name!),
      })

    const countryName = computed(
      () => props.country && translate(props.country?.name as any)
    )
    const countryFlag = computed(() => props.country?.flagImage)

    const headerStyle = computed(() => {
      return !!props.country?.projectBackground
        ? { backgroundImage: `url(${props.country.projectBackground.medium}` }
        : {}
    })

    const productsVariables = computed<TrEcommerceProductPageQueryVariables>(
      () => ({
        avoidDefaultFilter: false,
        filterCountries: [Number(props.country?.id)],
        types: ['tree_limited', 'tree'],
        limit: 30,
      })
    )
    const { result, loading } = useQuery<
      TrEcommerceProductPageQuery,
      TrEcommerceProductPageQueryVariables
    >(
      ECOMMERCE_PRODUCT_PAGE,
      () => productsVariables.value,
      () => ({
        enabled: !!props.country?.id,
      })
    )

    const products = computed(() => result.value?.product_page?.products ?? [])

    return {
      countryFlag,
      countryName,
      headerStyle,
      loading,
      products,
      goToProductPage,
      isMobile,
      productOriginalPrice,
      productPrice,
      isProductDiscounted,
      isProductAvailable,
      href,
    }
  },
})
