// import { useInAppBrowserFix } from './patches/forceExitInAppBrowser'

// useInAppBrowserFix()

fetch(window.translationURL)
  .then((response) => response.json())
  .catch((error) => {
    console.error('error loading tranlsations', error)
    return {}
  })
  .then((translations) => {
    Object.entries(translations).forEach(([key, value]) => {
      Translator.add(key, value as string, 'messages', window.lang as string)
    })
  })
  .then(() => {
    return require('@f/_legacy/js/ngApp')
  })
