











import { Vue, Component, Prop } from "vue-property-decorator"
import * as clipboard from 'clipboard-polyfill'
import ShareNative from '@f/components/ShareNative.vue'

@Component({
  name: "TreeDiaryItemShare",
  components: {
    ShareNative
  }
})
export default class TreeDiaryItemShare extends Vue {
  @Prop({ type: String, required: true }) readonly url!: string

  copied = false
  
  // Methods
  copyToClipboard(): void {
    clipboard.writeText(this.url)
    this.copied = true
    setTimeout(() => { this.copied = false }, 1500)
  }
}
