angular.module('ngApp')
  .directive('switch', function() {
    return {
      restrict: 'E',
      require: 'ngModel',
      scope: {
        color: '@',
        size: '@',
        checked: '<ngModel'
      },
      template: require('./switch.html'),
      link : function(scope, element, attrs, modelCtrl){
        modelCtrl.$viewChangeListeners.push(function() {
          return scope.$eval(attrs.ngChange)
        });
        element.on('click', function(ev) {
          modelCtrl.$setViewValue(!modelCtrl.$viewValue);
        })
      }
    }
  })
