


























































import { defineComponent, PropType } from '@vue/composition-api'
import HUDPage from './HUDPage.vue'
import DotLoader from '@f/components/DotLoader.vue'
import useRoutes from '@f/composables/useRoutes'
import { TrUserCountrySummary } from '@f/@types/graphql'
import { maxWidth, useMediaQuery } from '@f/utils/breakpoints'
import { DataEventMap } from '@f/services/TagManager/events';
import Treecon from '@f/components/Treecons/Treecon.vue'

export default defineComponent({
  name: 'HUDOrganizationCountries',
  components: {
    Treecon,
    HUDPage,
    DotLoader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    countrySummaries: {
      type: Array as PropType<TrUserCountrySummary[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMine: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { href, go } = useRoutes()
    const isMobile = useMediaQuery(maxWidth('md'))
    const onCountrySelect = (country: string) => {
      DataEventMap({ countrySelected: country  })
    }
    const goToEcommerce = () => {
      go('species')
    }
    return {
      href,
      isMobile,
      onCountrySelect,
      goToEcommerce,
    }
  }
})
