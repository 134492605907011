import {TrTranslation} from '@f/@types/graphql'
import {IsoCode} from '@f/@types/localization'
import {sanitizePatternMap} from './const'

export function sanitize(text: string, caseSensitive = false): string {
  let sanitized = text || ''
  for (const test in sanitizePatternMap) {
    if (caseSensitive) {
      sanitized = sanitized.replace(
        RegExp(test, 'g'),
        sanitizePatternMap[test as keyof typeof sanitizePatternMap]) // Thanks Typescript :(
      sanitized = sanitized.replace(
        RegExp(test.toUpperCase(), 'g'),
        sanitizePatternMap[test as keyof typeof sanitizePatternMap].toUpperCase()) // Thanks Typescript :(
    } else {
      sanitized = sanitized.replace(
        RegExp(test, 'gi'),
        sanitizePatternMap[test as keyof typeof sanitizePatternMap]) // Thanks Typescript :(
    }
  }
  return sanitized
}

export function legacySlugify(text: string): string {

  return sanitize(text.toString().toLowerCase().trim())
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
};

export function legacyToCamelCase(text: string): string {
  return text.replace(/-|_/g, " ").replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index == 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

// First revision
export function normalize(value: unknown, sanitizeText = true, caseSensitive = false): string {
  let normalized = ''
  switch (typeof value) {
    case 'string':
      normalized = value
      break
    case 'number':
    case 'boolean':
      normalized = value.toString()
      break
    default:
      return normalized
  }

  return sanitizeText ? sanitize(normalized, caseSensitive) : normalized
}

export function slugify(text: unknown): string {
  return sanitize(normalize(text).toLowerCase().trim())
    .replace(/[-|\s]+/g, '-') // Replace spaces and multiple dash with single dash
    .replace(/^-+|-+$|[\W-]+/g, '') // Remove non-word characters and trim dashes
}

export function toCamelCase(text: unknown) {
  return normalize(text)
    .toLowerCase()
    .replace(/(?!^)[-_\s]+(\w)/g, (_, char) => char.toUpperCase())
}

export function toPascalCase(text: unknown) {
  return normalize(text)
    .toLowerCase()
    .replace(/(?=\b)[-_\s]*(\w)/g, (_, char) => char.toUpperCase())
}

export function toSnakeCase(text: unknown) {
  return splitOnUppercase(text, '_')
}

export function toKebabCase(text: unknown) {
  return splitOnUppercase(text, '-')
}

export function splitOnUppercase(text: unknown, separator: string) {
  return normalize(text, true)
    .replace(/[-_\s]*([A-Z])/g, (_, char) => separator+char)
    .toLowerCase()
}

export function capitalize(text: unknown) {
  return normalize(text).toLowerCase().replace(/^\w/gi, char => char.toUpperCase())
}

export function fill(text: string, length: number, filler: string = '0', truncate = false) {
  const maxLength = truncate ? length : Math.max(length, text.length)
  return (
      new Array(maxLength)
      .fill(filler)
      .join('')+text
    ).slice(-maxLength)
}

export function co2Html(text?: string) {
  return text?.length && text.replace(/\bco2\b/gi, 'CO<sub>2</sub>') || text || ''
}

export function baseStripTag(text?: string) {
  return text?.replace(/<\/?[^>]+>/ig, " ") || ''
}

export function replaceHtmlAsciiCodes(text: string) {
  return text.replace(/&#([0-9]{1,3}|x[0-9a-fA-F]{1,2});/g, (m, code) => String.fromCharCode(+('0' + code)));
}


export function stringToTranslation(text?: string, languages: IsoCode[] = ['it', 'en', 'de', 'fr', 'es']): TrTranslation {
  return languages.reduce((translation, language) => {
    return {
      ...translation,
      [language]: text
    }
  }, {} as TrTranslation)
}