import { valdiateVideo } from '@f/validation/datalayer';
import tagManagerService from '@f/services/TagManager';

const videoStatusToString = {
    "-1": 'unstarted',
    "0": "ended",
    "1": "playing",
    "2": "paused",
    "3": "buffering",
    "5": "video cued",
} as Record<string,string>

export function DataEventVideo(videoStatus: string, videoUrl?: string) {
    if(videoStatus === '1' || videoStatus === '2' || videoStatus === '0' )
    tagManagerService.push({
        event: 'video',
        videoStatus: videoStatusToString[videoStatus],
        videoUrl
    }, valdiateVideo)
}