







import { defineComponent } from '@vue/composition-api'
import ReadMoreModal from '@f/components/Forest/ReadMoreModal.vue'
import Modal from '@f/components/Modal.vue'

export default defineComponent({
  name: 'OrganizationDescriptionModal',
  components: { Modal, ReadMoreModal },
  props: {
    description: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const close = () => {
      emit('close')
    }
    return {
      close
    }
  }
})
