/* @ngInject */
export default function ecommerceGridController(Ecommerce) {

  this.products = []
  this.loading = false
  this.wrapClasses = []

  this.$onInit = () => {
    if (this.ecommerces && this.ecommerces.length) {
      this.loading = true
      let ecommercesList = this.wrapClasses = this.ecommerces.split(',')
      Ecommerce.getEcommerces(ecommercesList).then(ecommercesResponse => {
        ecommercesList.forEach(ecommerceName => {
          this.products = this.products.concat(ecommercesResponse[ecommerceName])
        });
        this.loading = false
        this.columnClasses = this.getColumnClasses()
      });
    }
  }

  this.getColumnClasses = () => {
    return [ 'col-xs', 'col-sm', 'col-md' ].map(
      (step, index) => `${step}-${index + 1 + (this.template === 'preview')}`
    )

  }

  this.itemClickCallback = (product) => {
    this.onItemClick({ product })
  }
}
