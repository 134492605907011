





























import { Component, Mixins } from 'vue-property-decorator'
import TreeDiaryMessage from '../post_types/TreeDiaryMessage.vue'

@Component({
  name: 'TreeDiaryMessageModal',
})
export default class TreeDiaryMessageModal extends Mixins(TreeDiaryMessage) {}
