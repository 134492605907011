


































import { Vue, Component, Prop } from "vue-property-decorator"
import { VueConstructor } from "vue"

import Loader from '../Loader.vue'
import CommentList from './partials/CommentList.vue'
import { TrPageInfo } from '@f/@types/graphql'
// Post Types Components
import {
  TreeDiaryGift,
  TreeDiaryLike,
  TreeDiaryMessage,
  TreeDiaryName,
  TreeDiaryPost,
  TreeDiaryStatus  } from './post_types'

const postsComponents = {
  TreeDiaryGift,
  TreeDiaryLike,
  TreeDiaryMessage,
  TreeDiaryName,
  TreeDiaryPost,
  TreeDiaryStatus
}

@Component({
  name: 'UserWall',
  components: {
    Loader,
    CommentList,
    ...postsComponents
  }
})
export default class UserWall extends Vue {
  @Prop({ type: String, required: true }) readonly id!: string
  @Prop({ type: String, required: true }) readonly slug!: string
  @Prop({ type: Number, default: 5 }) readonly paginationLimit!: number
  @Prop({ type: Number, validator: val => val >= 0 }) readonly preview!: number
  
  visibileItems = 5

  // Methods
  type2component (post: any, layoutSuffix = ''): VueConstructor {
    const componentName = post.__typename+layoutSuffix as keyof typeof postsComponents
    return postsComponents[componentName]
  }

  fetchMore(query: any, cachedList: any, pageInfo: TrPageInfo) {
    this.visibileItems += this.paginationLimit
    if (this.visibileItems > cachedList.length && pageInfo.hasNextPage) {
      query.fetchMore({
        variables: {
          id: this.id,
          after: pageInfo.endCursor,
          limit: this.paginationLimit
        },
        updateQuery: (previousResult: any, { fetchMoreResult: { wall } }: any) => {
          return {
            wall: {
              posts: [...previousResult.wall.posts, ...wall.posts],
              pageInfo: { ...wall.pageInfo },
              __typename: wall.__typename
            }
          }
        }
      })
    }
  }

  paginated(cachedList: any[]): any[] {
    return cachedList.slice(0, this.preview || this.visibileItems)
  }

  hasNextPage({ wall: { posts: cachedList, pageInfo } }: any): boolean {
    if (!cachedList || !pageInfo) return false;
    return this.visibileItems < cachedList.length || pageInfo.hasNextPage
  }
}
