













































































































import { defineComponent, PropType } from '@vue/composition-api'
import { Forest, RadioGroupOption } from '@f/@types'
import ForestEditHeader from '@f/components/Forest/ForestEditHeader.vue'
import StatefullButton from '@f/components/StatefullButton.vue'
import { deleteForestGalleryImage, startForest } from '@f/services/Forest'
import ForestGallery from '@f/components/Forest/ForestGallery.vue'
import FileUploader from '@f/components/Form/FileUploader.vue'
import DotLoader from '@f/components/DotLoader.vue'
import {
  saveForestGalleryImage,
  associateForestImage,
} from '@f/services/Forest'

export default defineComponent({
  name: 'EditForest',
  components: {
    ForestEditHeader,
    StatefullButton,
    ForestGallery,
    FileUploader,
    DotLoader,
  },
  props: {
    eventSlug: {
      type: String,
      required: true,
    },
    userSlug: {
      type: String,
      required: true,
    },
    avatarImageUrl: {
      type: String,
      default: '',
    },
    coverImageUrl: {
      type: String,
      default: '',
    },
    galleryImages: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  data(): {
    sectionErrors: string[]
    editData: Partial<Forest>
    forestTypes: RadioGroupOption[]
    uploadedImages: {
      avatar: null | File
      cover: null | File
    }
    loading: boolean
  } {
    return {
      sectionErrors: [],
      editData: {
        slug: null,
        name: null,
        description: '',
        avatar: null,
        background: null,
        published: true,
        isRally: false,
      },
      forestTypes: [
        {
          title: this.$t('forest.createFlow.step.first.form.third.rally'),
          value: true,
        },
        {
          title: this.$t('forest.createFlow.step.first.form.third.notRally'),
          value: false,
        },
      ],
      uploadedImages: {
        avatar: null,
        cover: null,
      },
      loading: false,
    }
  },
  computed: {
    // virtualSlug(): string {
    //   const location = window.location
    //   return (
    //     location.host +
    //     '/' +
    //     location.pathname.substr(1, location.pathname.lastIndexOf('/')) +
    //     this.editData.slug
    //   )
    // },
    descriptionCharCount(): number {
      return this.editData.description ? this.editData.description.length : 0
    },
    descriptionError(): boolean {
      return this.descriptionCharCount >= 1500
    },
    nameError(): boolean {
      return this.editData.name === ''
    },
  },
  mounted() {
    this.loading = true
    startForest(this.eventSlug)
      .then(({ data }) => {
        this.editData = data.event
      })
      .catch(() => {
        this.$emit('close')
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    changedImage(image: { file: File; fileKey: string } | null) {
      const { file, fileKey } = image ?? {}

      if(!file) return

      this.uploadedImages[fileKey as 'avatar' | 'cover'] = file
    },
    save(publish: boolean) {
      this.loading = true
      this.$emit('save', {
        editedForest: this.editData,
        uploadedImages: this.uploadedImages,
        publish,
      })
    },
  },
})
