import template from './quantity-input-template.html'
import controller from './quantity-input-controller'
const bindings = {
  quantity: '<',
  min: '<',
  max: '<',
  color: '@',
  select: '=',
  onChange: '&',
  nullable: '<'
}

angular.module('ngApp')
  .component('quantityInput', {
    template,
    controller,
    bindings
  })
