
























import { Vue, Mixins, Component, Watch } from 'vue-property-decorator'
import UserMixin from '@f/mixins/UserMixin'
import ShowAuthErrors from '@f/mixins/ShowAuthErrors'
import DotLoader from '@f/components/DotLoader.vue'
import Icon from '@f/components/Icon.vue'
import Cookie from 'js-cookie'
import UrlSearchParams from '@ungap/url-search-params'
import { emailConfirmQueryParam, cookieKeys } from '@f/configs/app'
import { CONFIRM_EMAIL_REQUEST } from '@f/graphql/mutations'
import { TreedomAuth } from '@f/auth'

@Component({
  name: 'EmailConfirm',
  components: { DotLoader, Icon }
})
export default class EmailConfirm extends Mixins(UserMixin, ShowAuthErrors) {
  /*--- MODEL ---------*/
  
  /*--- PROPS ---------*/
  
  /*--- DATA ----------*/
  loading = false
  confirmed: boolean | null = null
  sent: boolean | null = null
  email = ''
  errorMessage = ''
  collapse = false
  delayedCollapse: ReturnType<typeof setTimeout> | null = null
  blacklistedStates = [
    /^checkout/,
    /^error/,
  ]
  
  /*--- COMPUTED ------*/
  get stateClass() {
    return {
      'email-confirm-alert--success': this.confirmed === true || this.sent === true,
      'email-confirm-alert--warning': this.showAlert,
      'email-confirm-alert--error': this.confirmed === false || this.sent === false,
    }
  }

  get showAlert() {
    return this.$rootScope.logged
      && !this.blacklistedStates.some(state => ~(this.$state.current.name||'').search(state))
      && (
        this.hasFeedback
        || (!this.collapse && !this.userInfo?.hasConfirmedMail)
      )
      && this.$rootScope.showEmailConfirmAlert
  }

  get hasFeedback() {
    return !!this.confirmed || !!this.errorMessage
  }
  
  /*--- WATCHERS ------*/
  
  /*--- REFS ----------*/
  $refs!: Vue['$refs'] & {}
  /*--- EVENTS --------*/
  created() {
    const params = new UrlSearchParams(window.location.search)
    const confirmParam = params.get(emailConfirmQueryParam)
    this.collapse = !!Cookie.get(cookieKeys.skipEmailConfirmKey)
    if (confirmParam) {
      this.confirmed = confirmParam === 'OK'
      this.errorMessage = this.$t(this.getErrorLabelKey(confirmParam)) || ''
    }
  }
  
  /*--- METHODS -------*/
  async resendConfirmationEmail() {
    this.loading = true
    try {
      await this.$apollo.mutate({ mutation: CONFIRM_EMAIL_REQUEST })
      this.email = (await TreedomAuth.currentUserInfo()).email
      this.sent = true
      this.collapseAlert(6 * 1000)
    } catch (error) {
      this.sent = false
      this.errorMessage = error.message && this.getErrorLabel(error.message) || error.message
    } finally {
      this.loading = false
    }
  }

  collapseAlert(delay: number = 0) {
    if (this.delayedCollapse) clearTimeout(this.delayedCollapse)
    this.delayedCollapse = setTimeout(this.setCollapseCookie, delay)
  }

  setCollapseCookie() {
    Cookie.set(cookieKeys.skipEmailConfirmKey, '1', { domain: location.hostname, expires: 1/24 })
    this.collapse = true
    this.errorMessage = ''
    this.sent = null
    this.confirmed = null
  }

  @Watch('showAlert')
  onCollapse() {
    this.$nextTick(() => this.$emit('update'))
  }

  
}
