

















































import { defineComponent } from '@vue/composition-api'
import Treecon from '@f/components/Treecons/Treecon.vue'

export default defineComponent({
  name: 'ForestEditHeader',
  components: {
    Treecon,
  },
  props: {
    avatarImageUrl: {
      type: String,
      default: ''
    },
    coverImageUrl: {
      type: String,
      default: ''
    }
  },
  data(): {
    error: {
      avatar: null | string
      cover: null | string
    }
  } {
    return {
      error: {
        avatar: null,
        cover: null,
      },
    }
  },
  methods: {
    loadFile(event: any, fileKey: string) {
      this.error[fileKey as 'avatar' | 'cover'] = null
      const file = event.target?.files?.[0]

      if(!file) return;

      const output = document.getElementById(
        fileKey + '-image'
      ) as HTMLImageElement
      if (file.size > 2097152) {
        output!.classList.remove('visible')
        this.$emit('change', null)
        this.error[fileKey as 'avatar' | 'cover'] = 'File is too big!'
        return
      }
      output!.classList.add('visible')
      output!.src = URL.createObjectURL(file)
      this.$emit('change', {file, fileKey})
      output!.onload = () => {
        URL.revokeObjectURL(output!.src)
      }
    },
  },
})
