import { JSONSchemaType } from 'ajv'
import {
  IGiftAction,
  IDLGiftedTree,
  IGiftCanceled,
  IGiftShared
} from '@f/validation/types/dataLayer'

const GiftedTreeSchema: JSONSchemaType<IDLGiftedTree> = {
  type: 'object',
  required: ['treeId', 'treeName'],
  properties: {
    treeId: { type: 'string', nonEmpty: true },
    treeName: { type: 'string', nullable: true },
    treeType: { type: 'string' },
  }
}

export const OpenGiftFlowSchema: JSONSchemaType<IGiftAction> = {
  type: 'object',
  required: ['event', 'position'],
  properties: {
    event: {type: 'string'},
    position: { type: 'string' },
  }
}

export const GiftStartSchema: JSONSchemaType<IGiftAction> = {
  type: 'object',
  required: ['event', 'trees', 'position', 'numberoftree'],
  properties: {
    event: {type: 'string'},
    trees: {
      type: 'array',
      minItems: 1,
      items: GiftedTreeSchema
    },
    position: { type: 'string' },
    numberoftree: { type: 'integer' },
    idGift: { type: 'string', nullable: true },
    giftCode: { type: 'string', nullable: true },
    cartId: { type: 'number', nullable: true },
    giftType: { type: 'string'}
  }
}

export const GiftCanceledSchema: JSONSchemaType<IGiftCanceled> = {
  type: 'object',
  required: ['event', 'idGift', 'giftCode'],
  properties: {
    event: {type: 'string'},
    idGift: { type: 'string' },
    giftCode: { type: 'string' },
  }
}

export const GiftSharedSchema: JSONSchemaType<IGiftShared> = {
  type: 'object',
  required: ['event', 'idGift', 'giftCode'],
  properties: {
    event: {type: 'string'},
    idGift: { type: 'string' },
    giftCode: { type: 'string' },
  }
}
