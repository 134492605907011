import { TrStandardMedia } from '@f/@types/graphql'
import { MediaFormat } from '@f/@types/media'

export type MediaRawReference = number | string | Record<MediaFormat, string>

export const MEDIA_FORMATS = ["xlarge", "medium", "large", "xlarge"] as const

export function getMedia(checksum: string, size: string, ext: string) {
  return window.mediaPublicUrl
    ? `${window.mediaPublicUrl}/images/${size}/${checksum}.${ext}`
    : ''
}

export function getImageAssetLegacy(path: string, size: MediaFormat = 'medium'): string {
  if ((/^http/).test(path)) return path
  if (window.treedomEnv === 'DEV') {
    return `https://www.treedom.net/images/${size}_${path}`
  }
  return `/images/${size}_${path}`
}

export function getImageAsset(path: string | number, size: MediaFormat = 'medium', quality = 80): string {
  if (!path) return ''
  const stringPath = String(path)
  if (stringPath.startsWith('http')) return stringPath
  return `/api/image/${path}?size=${size}&quality=${quality}`
}

export function getS3Asset(path: string, size: MediaFormat = 'medium'): string {
  // if ((/^http/).test(path)) return path
  // return `${window.mediaPublicUrl}/images/${size}_${path}`
  return getImageAsset(path, size)
}

export function s3ToStandardMedia(path: string | number): Omit<TrStandardMedia, '__typename'> {
  return {
    small: getS3Asset(String(path), 'small'),
    medium: getS3Asset(String(path), 'medium'),
    large: getS3Asset(String(path), 'large'),
    xlarge: getS3Asset(String(path), 'xlarge'),
  }
}

export function getAsset(path: string): string {
  if (!path) return ''
  const baseUrl = document.querySelector('html')?.getAttribute('data-url') || "/"
  return baseUrl.replace(/\/$/,'')+'/'+path.replace(/^\//, '')
}

export function resolveMedia(reference: MediaRawReference, size: MediaFormat = 'medium', ext = 'jpg'): string {
  if (!reference) return ''
  if (typeof reference === 'object' && size in reference) return resolveMedia(reference[size])
  if (isNaN(Number(reference))) return getMedia(String(reference), size, ext)
  return getS3Asset(String(reference), size)
}

// Type Guard
export function isMedia(val: unknown): val is TrStandardMedia {
  return (typeof val === "object")
    && val !== null
    && !MEDIA_FORMATS.some(format => !val.hasOwnProperty(format))
}
