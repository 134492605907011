


















import {computed, defineComponent, onMounted, ref, watch} from '@vue/composition-api'
import useRoutes from "@f/composables/useRoutes";
import {getRootScope} from "@f/services/Angular";
import Modal from "@f/components/Modal.vue";
import experimentService from "@f/services/experimentService";
import DotLoader from "@f/components/DotLoader.vue";
import tagManagerService from '@f/services/TagManager'


export default defineComponent({
  name: 'CelebrationModalTest',
  components: {DotLoader, Modal},
  setup: () => {
    const {currentParams} = useRoutes()
    const rootScope = getRootScope()
    const locale = computed(() => rootScope?.ln || 'it')
    const celebrationModal = ref(window.localStorage.getItem('celebrationModal'))
    const iframeURL = computed(() => `/${locale.value}/celebration-modal/${celebrationModal.value}`);
    const open = ref(!!celebrationModal.value);
    const isIframeLoading = ref(true)
    const ready = ref(false)
    const iframe = ref<HTMLIFrameElement | null>(null)
    const root = ref<HTMLDivElement | null>(null)

    watch(celebrationModal, () => {
      if (celebrationModal.value) {
        window.localStorage.setItem('celebrationModal', celebrationModal.value)
        open.value = true
      } else {
        window.localStorage.removeItem('celebrationModal')
      }
    })

    const loadComplete = () => {
      isIframeLoading.value = false

      setInterval(() => {
        if (iframe.value && root.value) {
          root.value.style.height =
              (iframe.value.contentWindow?.document?.body?.scrollHeight ?? 128) + 'px'
        }
      }, 300)

      setTimeout(() => {
        ready.value = true
      }, 300)
    }
    onMounted(() => {
      if (!!rootScope?.userdata && !!currentParams.value?.['redeemed'] && locale.value === 'it' && !celebrationModal.value) {
        const experimentKey = 'celebration-modal';
        experimentService.getExperiment(rootScope.userdata.info, experimentKey)
          .then((data) => {
            if(data?.value?.value === 'treatment') {
              celebrationModal.value = (currentParams.value!).item
              open.value = true
              tagManagerService.push({ event: 'celebration-modal-view' })
            }
          });
      }
    })

    const close = () => {
      celebrationModal.value = null
      open.value = false
      tagManagerService.push({ event: 'celebration-modal-close' })
    }
    return {
      open,
      close,
      iframeURL,
      root,
      iframe,
      isIframeLoading,
      ready,
      loadComplete
    }
  }
})
