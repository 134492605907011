angular.module('ngApp')
.controller('emissionController', ['$state', '$scope', '$filter', '$timeout', '$rootScope', 'Emission', 'Utility', 'Modal', function ($state, $scope, $filter, $timeout, $rootScope, Emission, Utility, Modal) {
  if ($state.current.name === 'emission.type') {
    $state.go('emission')
  }
  var $ = window.$
  // Variabili del controller
  $scope.emissionToBeSaved = []
  $scope.total = 0
  $scope.selectedCategory = ''
  $scope.isItalian = $rootScope.ln === 'it'

  $scope.goToQuiz = function () {
    $state.go('holidayQuiz')
  }

  $scope.availableEmissions = Emission.emissions.toArray()

  /**
   * Scelta dell'emissione da calcolare.
   */
  $scope.choose = function (emission) {
    if (emission.system !== 'direct') {
      Modal.open({ templateUrl: 'modalEmissionToCalculate.html' })
      $timeout(function () { $rootScope.$broadcast('selectedEmissionToCalculate', emission) }, 100)
    } else {
      $scope.pushEmission(emission)
    }
  }

  /**
   * Scelta dell'emissione da calcolare da modale.
   */
  $rootScope.$on('selectedSizeOfEmissionToCalculate', function (event, data) {
    var emission = data.emission
    emission.multiplier = data.value
    $scope.pushEmission(emission, true)
  })

  /**
   * Invia l'emissione scelta nella lista di quelle calcolate da salvare.
   */
  $scope.pushEmission = function (emission, comeFromModal) {
    // Sovrascrivo la voce 'unit' con l'unità che ho effettivamente usato (defaultUnit)
    emission.unit = emission.defaultUnit
    $scope.emissionToBeSaved.unshift(emission)

    // Scroll automatico
    var timeout = comeFromModal ? 600 : 0
    $timeout(function () {
      var scrollTo = $('#emissionAnchor').offset().top
      doScrolling(scrollTo, 500)
    }, timeout)

    // Calcolo il totale
    $scope.calculateTotal()
    // Aggiorno lo slider
    $scope.resetSlider()
    $scope.sliderListToSave()
  }

  var doScrolling = function (elementY, duration) {
    var startingY = window.pageYOffset
    var diff = elementY - startingY
    var start

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step (timestamp) {
      if (!start) start = timestamp
      // Elapsed miliseconds since start of scrolling.
      var time = timestamp - start
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1)

      window.scrollTo(0, startingY + diff * percent)

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step)
      }
    })
  }

  /**
   * Calcola il numero totale della co2 prodotta dalle tue emissioni da salvare
   */
  $scope.calculateTotal = function () {
    $scope.total = 0
    $scope.emissionToBeSaved.forEach(function (value) {
      $scope.total += value.defaultCo2PerUnit * value.multiplier
    })
  }

  /**
   * Cancella emissione
   */
  $scope.cancelEmission = function (index) {
    $scope.selectedEmission = false
    $scope.emissionToBeSaved.splice(index, 1)
    // Ricalcolo il totale
    $scope.calculateTotal()
    // Slider
    $scope.prevListToSave()
    $scope.sliderListToSave()
  }

  /**
   * Salvo le emissioni
   */
  $scope.save = function () {
    Utility.disableButton()

    // Faccio partire le animazioni
    var consoleSquares = document.querySelectorAll('.emissionConsoleSquare')
    var clockAnimated = document.querySelectorAll('.emissionClockin')
    consoleSquares.forEach((elem, idx) => { elem.classList.add('changeColorFactory' + idx) })
    clockAnimated.forEach(elem => { elem.classList.add('speedUpClock') })

    // Mappo le emissioni per trasformare i minuti della tv in ore
    $scope.emissionToBeSaved = $scope.emissionToBeSaved.map(function (emission) {
      if (emission.id === 'tv') {
        emission.unit = 'hours'
        emission.defaultUnit = 'hours'
        emission.multiplier = emission.multiplier / 60
      }
      return emission
    })

    Emission.saveEmissions($scope.emissionToBeSaved)
    .then(function(res) {
      var data = res.data || res
      Utility.enableButton()
      $rootScope.$broadcast('NewEmissionSavedBroadcast', true)
      $scope.emissionToBeSaved = []
      $scope.total = 0

      // Rimuovo le animazioni
      consoleSquares.forEach((elem, idx) => { elem.classList.remove('changeColorFactory' + idx) })
      clockAnimated.forEach(elem => { elem.classList.remove('speedUpClock') })

      // Scrollo fino alla sezione della neutralizzazione
      var scrollTo = $('#neutralizationAnchor').offset().top
      doScrolling(scrollTo, 500)
    })
    .catch(function() {
      Utility.enableButton()
      // Rimuovo le animazioni
      consoleSquares.forEach((elem, idx) => { elem.classList.remove('changeColorFactory' + idx) })
      clockAnimated.forEach(elem => { elem.classList.remove('speedUpClock') })
    })
  }

  /**
   * Riduce a 16 caratteri i titoli delle singole emissioni
   */
  $scope.cutText = function (string) {
    var str = $filter('trans')(string)
    return str.length > 17 ? str.substring(0, 17) : str
  }

  /**
   * Variabili sliders
   */
  $scope.itemWidth = 0
  $scope.marginRight = 0
  $scope.itemsLength = 0
  $scope.capacityItemsInSlider = 0
  $scope.sliderMove = 0
  $scope.count = 0

  /**
   * Render slider delle emissoni da salvare
   */
  $scope.sliderListToSave = function () {
    var slider = document.getElementById('emissionListToSaveSliderContent')

    if (!slider) return

    var sliderWidth = slider.offsetWidth
    var items = document.querySelectorAll('.emissionListToSave-item')
    $scope.itemsLength = items.length
    $scope.itemWidth = ($scope.itemsLength ? items[0].offsetWidth : 0) + 10
    $scope.capacityItemsInSlider = Math.floor(sliderWidth / $scope.itemWidth)
    $scope.sliderMove = $scope.itemsLength < $scope.capacityItemsInSlider ? 0 : $scope.itemsLength - $scope.capacityItemsInSlider
    $scope.marginRight = (sliderWidth - ($scope.itemWidth * $scope.capacityItemsInSlider)) / $scope.capacityItemsInSlider

    items.forEach((item, key) => {
      item.style.left = (($scope.itemWidth + $scope.marginRight) * key) + 'px'
      return item
    })
  }

  /**
   * Movimento 'indietro' slider delle emissioni da salvare
   */
  $scope.prevListToSave = function () {
    if ($scope.count <= 0) return
    var sliderScroller = document.getElementById('emissionListToSaveSliderScroller')
    var oldValue = sliderScroller.style.left ? parseInt(sliderScroller.style.left) : 0
    sliderScroller.style.left = (oldValue + ($scope.itemWidth + $scope.marginRight)) + 'px'
    $scope.count--
  }

  /**
   * Movimento 'avanti' slider delle emissioni da salvare
   */
  $scope.nextListToSave = function () {
    if ($scope.count >= $scope.sliderMove) return
    var sliderScroller = document.getElementById('emissionListToSaveSliderScroller')
    var oldValue = sliderScroller.style.left ? parseInt(sliderScroller.style.left) : 0
    sliderScroller.style.left = (oldValue - ($scope.itemWidth + $scope.marginRight)) + 'px'
    $scope.count++
  }

  /**
   * Reset slider: usato in caso di resize
   */
  $scope.resetSlider = function () {
    var sliderScroller = document.getElementById('emissionListToSaveSliderScroller')
    if (sliderScroller) {
      sliderScroller.style.left = '0px'
      $scope.count = 0
    }
  }

  /**
   * Dopo aver completato l'ng-repeat, faccio partire lo slider
   */
  $scope.$on('ngRepeatFinished', function (ngRepeatFinished) {
    $scope.sliderListToSave()
  })

  /**
   * Al resize azzero lo slider e ricalcolo le misure
   */
  window.onresize = function (event) {
    $scope.resetSlider()
    $scope.sliderListToSave()
    $scope.$apply()
  }
}])
