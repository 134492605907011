import { Vue, Component, Prop } from "vue-property-decorator";

import TreeDiaryItemHeader from '../partials/TreeDiaryItemHeader.vue'
import TreeDiaryItemShare from '../partials/TreeDiaryItemShare.vue'
import CommentList from '../partials/CommentList.vue'

@Component({
  name: 'TreeDiaryDefaultItem',
  components: {
    TreeDiaryItemHeader,
    TreeDiaryItemShare,
    CommentList
  }
})
export default class TreeDiaryDefaultItem extends Vue {
  @Prop({ type: Object, required: true }) readonly post!: any;
  @Prop({ type: Object, required: true }) readonly tree!: any;
  
  // Computed
  get authorName(): string {
    switch (this.post.__typename) {
      case 'TreeDiaryPost':
        return this.post.editor && this.post.editor.name && this.translate(this.post.editor.name) || 'Treedom'
      default:
        return this.tree.name || this.translate(this.tree.specie.name)
    }
  }

  get icon(): string {
    switch (this.post.__typename) {
      case 'TreeDiaryPost':
        return this.post.editor && this.post.editor.avatar && this.post.editor.avatar.small || 'none'
      default:
        return (this.tree.limited && this.tree.limited.icon && this.tree.limited.icon.small) || this.tree.specie.icon.small
    }
  }

  get shareUrl(): string {
    return `${window.location.origin+window.location.pathname}?share=${this.post.id}`
  }

  get visibilityText(): string {
    let stringKey = 'pageTree.visibility.text'
    // TODO: verificare le regole da type=stream a GraphQL
    switch (this.post.__typename) {
      case 'TreeDiaryStatus':
        stringKey = (this.post.status === 'NURSERY') ?'pageTree.visibility.textStatusNursery' : 'pageTree.visibility.text'
        break
      case 'TreeDiaryPost':
        const postType = (this.post.postType.toUpperCase() !== 'DIARY')
          ? this.post.postType.toLowerCase() : 'post'
        stringKey = `pageTree.visibility.${postType}.text`
        break
      default:
        stringKey = 'pageTree.visibility.text'
    }
    const translatePayload = (this.tree) ? {
      specie: this.translate(this.tree.specie.name),
      country: this.translate (this.tree.specie.country.name)
    } : {}
    return this.translate(stringKey, translatePayload)
  }
}
