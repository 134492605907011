import { UserCart } from '@f/@types'
import tagManagerService from '@f/services/TagManager'
import { validateCartViewed } from '@f/validation/datalayer'
import { Cart } from '../../NewEcommerce/types'

export function DataEventCartViewed(cart: UserCart) {
  let totalDiscount = 0

  if (cart && cart.items.length) {
    cart.items.map(({ product }) => {
      if (product.badgeDiscount)
        totalDiscount += ((product.price / 100) * product.badgeDiscount) / 100
    })

    if (cart.totalWithoutVoucher !== cart.total) {
      totalDiscount += (cart.totalWithoutVoucher - cart.total) / 100
    }
  }

  const cartViewedData = {
    cartSize: (cart && cart.itemsQtCount) || 0,
    cartValue: (cart && cart.total / 100) || 0,
    cartId: (cart && cart.id) || null,
    totalDiscount,
  }

  tagManagerService.push(
    {
      event: 'cartViewed',
      ...cartViewedData,
    },
    validateCartViewed
  )
}

export function DataEventCartViewedNewEcommerce(cart: Cart) {
  // TODO review in base al nuovo modello del cart
  const cartViewedData = {
    cartSize:
      (cart &&
        cart.lineItems?.reduce((acc, item) => {
          return item!.quantity! + acc
        }, 0)) ||
      0,
    cartValue: cart?.totalPrice?.centAmount
      ? cart?.totalPrice?.centAmount / 100
      : 0,
    cartId: (cart && cart.id) || null,
    totalDiscount:
      cart?.discountedTotalPrice?.centAmount && cart?.totalPrice?.centAmount
        ? cart.discountedTotalPrice.centAmount / 100 -
          cart.totalPrice.centAmount / 100
        : 0,
  }

  tagManagerService.push(
    {
      event: 'cartViewed',
      ...cartViewedData,
    },
    validateCartViewed
  )
}
