










import { defineComponent, ref } from '@vue/composition-api'
import * as clipboard from 'clipboard-polyfill'
import Treecon from '@f/components/Treecons/Treecon.vue'

export default defineComponent({
  name: 'CopyInput',
  components: {
    Treecon,
    CopyIcon: () => import(/* webpackChunkName: "copy-line.svg" */ '@f/images/share/copy-line.svg')
  },
  props: {
    text: {
      type: String,
      required: true
    },
    feedbackDuration: {
      type: Number,
      default: 2000
    }
  },
  setup(props) {
    const success = ref(false)
    let feedbackTimeout = ref<ReturnType<typeof setTimeout>>()

    function copy() {
      if (feedbackTimeout.value) {
        clearTimeout(feedbackTimeout.value)
        feedbackTimeout.value = undefined
      }
      clipboard.writeText(props.text)
      success.value = true
      feedbackTimeout.value = setTimeout(() => success.value = false, props.feedbackDuration)
    }
    
    return {
      copy,
      success
    }
  }
})
